import React, { useEffect } from 'react';

import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GrapichFinished = () => {
  const [
    budgetWeekTransactionsData,
    ,
    loadingbudgetWeekTransactionsData,
    refetchBudgetWeekTransactionsData,
  ] = useQuery(() => serviceOrderService.listAprovedWithPartLabourServiceBudgetWeek(), []);

  const seg = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumseg);
  });

  const ter = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumter);
  });

  const qua = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumqua);
  });

  const qui = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumqui);
  });

  const sex = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumsex);
  });

  const sab = budgetWeekTransactionsData?.map((week) => {
    return Number(week.sumsab);
  });

  const data = [
    {
      name: 'Seg' + ' ' + seg ?? 0,
      pv: seg ? seg : 0,
    },
    {
      name: 'Ter' + ' ' + ter ?? 0,
      pv: ter ? ter : 0,
    },
    {
      name: 'Qua' + ' ' + qua ?? 0,
      pv: qua ? qua : 0,
    },
    {
      name: 'Qui' + ' ' + qui ?? 0,
      pv: qui ? qui : 0,
    },
    {
      name: 'Sex' + ' ' + sex ?? 0,
      pv: sex ? sex : 0,
    },
    {
      name: 'Sab' + ' ' + sab ?? 0,
      pv: sab ? sab : 0,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchBudgetWeekTransactionsData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchBudgetWeekTransactionsData]);

  console.log(data);
  return (
    <ResponsiveContainer width='100%' height='90%'>
      <BarChart
        width={80}
        height={30}
        data={data}
        margin={{
          left: -25,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />

        <Bar barSize={30} dataKey='pv' stackId='a' fill='#e94a13' />
      </BarChart>
    </ResponsiveContainer>
  );
};
