import React from 'react';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Spacer, Dialog, FormatMoney } from 'components';
import { Tabs } from 'components/Tabs';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

export const PartInformationDialog = ({
  open,
  handleClose,
  service,
  stockRequisition,
  reloadStatuses,
}) => {
  if (!open) return null;

  const [component, , loadingComponent, refetchComponents] = useQuery(
    () => componentService.getComponentbyId(stockRequisition?.component_id),
    [],
  );

  console.log('Pegou o componente: ', component);

  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Detalhes do orçamento service: ( {service.number})
      </Dialog.Title>

      <Dialog.Content>
        <Tabs gutterBottom>
          <Tabs.Content label='Detalhes do Componente' disablePadding>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PART-NUMBER:</Typography>
                <Typography>{component?.cprod}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DESCRIÇÃO:</Typography>
                <Typography>{component?.xprod ?? 'SEM DESCRIÇÃO'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>QUANTIDADE REQUISITADA:</Typography>
                <Typography>{stockRequisition?.amount}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR DE COMPRA:</Typography>
                <Typography>
                  <FormatMoney>{component?.purchase_price}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR DE VENDA:</Typography>
                <Typography>
                  <FormatMoney>{component?.component_acessory_price ?? 0}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR DE VENDA VAREJO:</Typography>
                <Typography>
                  <FormatMoney>{component?.component_acessory_price_wholesale ?? 0}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>TIPO DE ESTOQUE:</Typography>
                <Typography>{component?.component_level_location?.description}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>LOCAL DE ESTOQUE:</Typography>
                <Typography>{component?.stock_location?.location_name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>NFe:</Typography>
                <Typography>{component?.infnfe}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>QUANTIDADE EM ESTOQUE DISPONÍVEL:</Typography>
                <Typography>{component?.quantity}</Typography>
              </Grid>
            </Grid>
          </Tabs.Content>
        </Tabs>
        <Spacer axis='vertical' size={15} />
        <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
          <Button onClick={handleClose} color='primary'>
            Fechar
          </Button>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
