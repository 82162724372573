import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as Trash } from 'assets/images/trash.svg';
import { Button, Select, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const CancelBudget = () => {
  const classes = useStyles();

  const [cancelBudgetReasonsData, , loadingCancelBudgetReasons] = useQuery(
    () => api.get('/budgets/cancel/reasons'),
    [],
  );

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await budgetService.cancelBudget(values);

      toast.success('Orçamento cancelado com sucesso');

      resetForm();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  const cancelBudgetReasonsOptions = (cancelBudgetReasonsData || []).map((reason) => ({
    value: reason.id,
    label: reason.title,
  }));

  return (
    <>
      <PageTitle>Cancelar Orçamento</PageTitle>
      <Formik
        initialValues={{ service_order_number: '', cancel_budget_reason_id: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    name='service_order_number'
                    size='small'
                    label='Service'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='cancel_budget_reason_id'
                    label='Motivo do cancelamento'
                    options={cancelBudgetReasonsOptions}
                    loading={loadingCancelBudgetReasons}
                    component={Select}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Grid className={classes.containerImg} container>
        <Trash className={classes.trashImage} />
      </Grid>
    </>
  );
};
