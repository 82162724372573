import React from 'react';

import { Tabs } from 'components';

import { AcessoryAvailable } from './AcessoryAvailable';
import { AcessorySold } from './AcessorySold';
import { BudgetStatus } from './BudgetStatus';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Disponíveis' disablePadding>
        <AcessoryAvailable />
      </Tabs.Content>
      <Tabs.Content label='Vendidos' disablePadding>
        <AcessorySold />
      </Tabs.Content>
      <Tabs.Content label='Status da encomenda' disablePadding>
        <BudgetStatus />
      </Tabs.Content>
    </Tabs>
  );
};
