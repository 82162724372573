/* eslint-disable react/no-unknown-property */
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as StockDevolutionImg } from 'assets/images/return-stock.svg';
import { Button } from 'components';
import { Text } from 'components/Form/Text';
import { FormatDate } from 'components/FormatDate';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import { logsService } from 'services/logs';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Service' },
  { label: 'Data', align: 'left' },
  { label: 'Status', width: 150, align: 'center' },
];

const layout = ['Service'];

export const StockDevolution = () => {
  const classes = useStyles();

  const [billingData, setBillingData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [importing, setImporting] = useState(false);

  const FieldBilling = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (logs?.billing_id) {
        setFieldValue('billing', logs?.billing_id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [logs?.billing_id, setFieldValue]);

    return (
      <Grid item xs={12} md={4} lg={3}>
        <Field
          size='small'
          variant='outlined'
          name='billing'
          label='Billing'
          fullWidth
          component={Text}
          inputProps={{ maxLength: 10, minlength: 10 }}
        />
      </Grid>
    );
  };

  console.log('Logs: ', logs);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await logsService.listBillingLogByBillingId(values?.billing);

      console.log('Data: ', data);

      setLogs(data);

      resetForm();
      setBillingData(data);

      toast.success('Billing consultada com sucesso');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao consultar a billing.');
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {}, []);

  const handleDownloadLayoutDevolutionComponents = async () => {
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet([{ S: 'PartNumber', h: 'Billing', j: 'Quantidade' }], {
      skipHeader: 1,
    });

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(
      new Blob([wbout], { type: 'application/octet-stream' }),
      'LayoutDevolutionComponents.xlsx',
    );
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const devolutions = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              part_number: obj.split(';')[0],
              billing_id: Number(obj.split(';')[1]),
              quantity: Number(obj.split(';')[2]),
            };

            return dados;
          }
        });

        const createDataByFile = await componentService.updateComponentsDevolution({
          componentsDevolution: devolutions,
        });

        console.log('Dados: ', createDataByFile);

        if (createDataByFile.data.length != 0) {
          toast.success(
            `${createDataByFile.data.length} componentes da billing debitados com sucesso.`,
          );
          setImporting(false);
        } else {
          toast.error(
            'Occoreu um erro ao realizar a importação ou todos os componentes da importação já foram anteriormente cadastrados',
          );
          setImporting(false);
        }
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Devolução de componentes</PageTitle>
      <Formik
        initialValues={{
          part_number: '',
          billing_id: '',
          quantity: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.formContainer} container spacing={2}>
                <FieldBilling />
                <Grid item xs={12} md={4} lg={2}>
                  <Button
                    color='primary'
                    type='submit'
                    disabled={!props.isValid || !props.dirty}
                    fullWidth
                  >
                    PESQUISAR
                  </Button>
                </Grid>
              </Grid>

              <Paper className={classes.gridPaper} variant='outlined'>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={6}>
                    <Button
                      fullWidth
                      onClick={handleImportClick}
                      type='button'
                      disabled={importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Importar planilha
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Button
                      fullWidth
                      variant='outlined'
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadLayoutDevolutionComponents}
                      disabled={importing}
                    >
                      Baixar planilha
                    </Button>
                  </Grid>

                  {importing ? (
                    <Grid xs={6} lg={12} className={classes.loading}>
                      <Box sx={{ width: '90%' }}>
                        <LinearProgress color='secondary' />
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                        </Grid>
                        <StockDevolutionImg className={classes.imgTracker} />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid className={classes.gridImages} xs={6} lg={12}>
                      {' '}
                      <StockDevolutionImg className={classes.imgTracker} />
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <WarningIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Máximo de importação por vez - 1.000
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Esperar carregamento completo da importação.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>

      <TableContainer>
        <Table
          className={classes.table}
          size='medium'
          aria-label='a dense table'
          stripped
          emptyMessage='Nenhum registro encontrado.'
        >
          <TableHead>
            <TableRow>
              <TableCell>Billing</TableCell>
              <TableCell>Quantidade Debitada</TableCell>
              <TableCell>Part-Number</TableCell>
              <TableCell>Mensagem</TableCell>
              <TableCell>Data Billing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs?.map((log) => {
              return (
                <TableRow key={log?.id}>
                  <TableCell>{log?.billing_id}</TableCell>
                  <TableCell>{log?.qntd_debited}</TableCell>
                  <TableCell>{log?.part_numbers}</TableCell>
                  <TableCell>{log?.msg}</TableCell>
                  <TableCell>
                    {<FormatDate format='dd/MM/yyyy HH:mm:ss'>{log?.created_at}</FormatDate>}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
