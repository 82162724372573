import React, { useState } from 'react';
import { IoIosBarcode } from 'react-icons/io';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as TrackerImg } from 'assets/images/trackerImg.svg';
import { Button, Text, Tutorial } from 'components';
import { Formik, Form, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService, componentService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';
import { tutorialSteps } from './tutorial';

export const PnTracker = () => {
  const userViewedTutorials = useContextSelector(AuthContext, ({ user }) => user.viewed_tutorials);
  let tutorialName = '';

  const classes = useStyles();

  const [partNumber, setPartNumber] = useState('');
  const [billing, setBilling] = useState('');
  const [description, setDescription] = useState('');
  const [registration, setRegistrationDate] = useState('');
  const [componentValue, setComponentValue] = useState('');
  const [expirationBillingDate, setExpirationBillingDate] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const onSubmit = async (values, { setSubmitting, resetForm, handleClean }) => {
    try {
      const { id } = values;
      const { data } = await componentService.listPnTracker(id);

      setPartNumber(data.part_number);
      setBilling(data.billing_id);
      setDescription(data.description);
      setRegistrationDate(data.created_at);
      setComponentValue(data.purchase_price);
      setExpirationBillingDate(data.billing.expires_at);

      toast.success('PartNumber trackeado com sucesso!');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao consultar');
    } finally {
      setSubmitting(false);
    }
  };

  const [listedTutorial, , loadinglistedTutorial] = useQuery(
    () => accountService.ListViewedTutorial(),
    [],
  );

  if (listedTutorial) {
    const { viewed_tutorials } = listedTutorial[0];
    console.log(viewed_tutorials);
  }

  const cleanTableMessages = () => {
    setPartNumber('');
    setBilling('');
    setDescription('');
    setRegistrationDate('');
    setComponentValue('');
    setExpirationBillingDate('');
  };

  const onTutorialFinish = () => {
    accountService.addViewedTutorial('PN Tracker');
  };

  return (
    <>
      {userViewedTutorials?.map((tutorial) => {
        if (tutorial == 'PN Tracker') {
          tutorialName = 'PN Tracker';
        }
      })}
      {tutorialName == 'PN Tracker' ? (
        <></>
      ) : (
        <Tutorial steps={tutorialSteps} onFinish={onTutorialFinish} />
      )}
      <PageTitle>PN-Tracker</PageTitle>
      <Formik onSubmit={onSubmit} initialValues={{ id: '' }}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  id='scan'
                  label='Escanear'
                  variant='outlined'
                  size='small'
                  name='id'
                  component={Text}
                  endAdornment={<IoIosBarcode />}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Button id='button-search' color='primary' type='submit' fullWidth>
                  PESQUISAR
                </Button>
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Button variant='text' type='reset' onClick={() => cleanTableMessages()} fullWidth>
                  LIMPAR
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.gridPaper} variant='outlined'>
            <Typography gutterbottom variant='h6'>
              Informações
            </Typography>
            <Grid className={classes.contextPaper} container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Text
                  label='PartNumber'
                  variant='outlined'
                  size='small'
                  inputProps={{ editable: false }}
                  value={partNumber}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Text
                  label='Descrição'
                  variant='outlined'
                  value={description}
                  size='small'
                  inputProps={{ editable: false }}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <Text
                  label='Registro'
                  variant='outlined'
                  value={registration}
                  size='small'
                  inputProps={{ editable: false }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Text
                  label='Billing'
                  variant='outlined'
                  value={billing}
                  size='small'
                  inputProps={{ editable: false }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Text
                  label='Valor Componente'
                  variant='outlined'
                  value={componentValue}
                  size='small'
                  inputProps={{ editable: false }}
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <Text
                  label='Expiração da Billing'
                  variant='outlined'
                  size='small'
                  value={expirationBillingDate}
                  inputProps={{ editable: false }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Text
                  label='Último log do componente'
                  variant='outlined'
                  size='small'
                  value={'Em desenvolvimento'}
                  inputProps={{ editable: false }}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Text
                  label='Data último log'
                  variant='outlined'
                  size='small'
                  value={'Em desenvolvimento'}
                  inputProps={{ editable: false }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid className={classes.gridImg} container>
        {/* <Grid  item xs={8} lg={12}> */}
        <TrackerImg className={classes.imgTracker} />
        {/* </Grid> */}
      </Grid>
    </>
  );
};
