import React, { useEffect } from 'react';

// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GraphicPerformance = () => {
  const [agingData, , loadingagingData, refetchAgingData] = useQuery(
    () => serviceOrderService.listQaInspector(),
    [],
  );

  console.log('teste', agingData);

  const total = agingData?.map((age) => {
    return age.total;
  });

  const nome = agingData?.map((age) => {
    return age.substring;
  });

  const data = [
    {
      name: (nome?.[0] ?? '') + ' ' + (total?.[0] ?? ''),
      pv: total?.[0] ?? 0,
    },
    {
      name: (nome?.[1] ?? '') + ' ' + (total?.[1] ?? ''),
      pv: total?.[1] ?? 0,
    },
    {
      name: (nome?.[2] ?? '') + ' ' + (total?.[2] ?? ''),
      pv: total?.[2] ?? 0,
    },
    {
      name: (nome?.[3] ?? '') + ' ' + (total?.[3] ?? ''),
      pv: total?.[3] ?? 0,
    },
    {
      name: (nome?.[4] ?? '') + ' ' + (total?.[4] ?? ''),
      pv: total?.[4] ?? 0,
    },
    {
      name: (nome?.[5] ?? '') + ' ' + (total?.[5] ?? ''),
      pv: total?.[5] ?? 0,
    },
    // {
    //   name: nome?.[6],
    //   pv: total?.[6] ?? 0,
    // },
    // {
    //   name: nome?.[7],
    //   pv: total?.[7] ?? 0,
    // },
    // {
    //   name: nome?.[7],
    //   pv: total?.[7] ?? 0,
    // },

    // {
    //   name: nome?.[8],
    //   pv: total?.[8] ?? 0,
    // },
    // {
    //   name: nome?.[9],
    //   pv: total?.[9] ?? 0,
    // },
    // {
    //   name: nome?.[10],
    //   pv: total?.[10] ?? 0,
    // },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchAgingData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchAgingData]);

  console.log(data);
  return (
    <ResponsiveContainer width='100%' height='90%'>
      <BarChart
        width={80}
        height={30}
        data={data}
        margin={{
          left: -25,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />

        <Bar barSize={30} dataKey='pv' stackId='a' fill='#f542d7' />
      </BarChart>
    </ResponsiveContainer>
  );
};
