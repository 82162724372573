import React from 'react';

import Typography from '@material-ui/core/Typography';

import Chip from '@mui/material/Chip';
import { Button, Dialog, Tabs } from 'components';

import { CosmeticScreeningDetail } from './CosmeticScreeningDetail';
import { FuntionalScreeningDetails } from './FuntionalScreeningDetails';

export function MoreInfoDialog({ isOpen = false, handleClose, sinister, gradeResult, reset }) {
  if (!isOpen) {
    return null;
  }

  function getGradeColor(gradeId) {
    switch (gradeId) {
      case 1:
        return {
          label: 'LIKE NEW',
          color: '#FFFFFF', // White for LIKE NEW
          textColor: '#000000',
          border: '1px solid #000000',
        };
      case 2:
        return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Blue for EXCELENTE
      case 3:
        return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Green for MUITO BOM
      case 4:
        return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Yellow for BOM+
      case 5:
        return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Red for BOM
      case 6:
        return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Black for QUEBRADO
      default:
        return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Default (gray)
    }
  }

  const grade = getGradeColor(gradeResult);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <Dialog.Title onClose={handleClose}>Resultados</Dialog.Title>
      <Dialog.Content>
        <Typography variant='h5'>
          Grade final:{' '}
          <Chip
            label={grade.label}
            style={{
              backgroundColor: grade.color,
              color: grade.textColor,
              border: grade.border || 'none',
            }}
          />
        </Typography>
        <Typography variant='h5'>Proxima etapa: Aguardando alocação</Typography>
      </Dialog.Content>
      <Dialog.Content>
        <Tabs>
          <Tabs.Content label='Triagem Funcional' disablePadding>
            <FuntionalScreeningDetails sinister={sinister} />
          </Tabs.Content>
          <Tabs.Content label='Triagem Cosmética' disablePadding>
            <CosmeticScreeningDetail sinister={sinister} />
          </Tabs.Content>
        </Tabs>
      </Dialog.Content>

      <Dialog.Actions>
        <Button fullWidth onClick={() => reset()}>
          Confirmar
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
}
