import React from 'react';
import Barcode from 'react-barcode';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';

export function WaitingOracleBarcodeDialog({ isOpen = false, handleClose, data }) {
  if (!isOpen) {
    return <></>;
  }
  console.log('data ==>', data);
  return (
    <Dialog open={isOpen} onClose={() => handleClose()} maxWidth='sm'>
      <Dialog.Title onClose={() => handleClose()}>Código de barras</Dialog.Title>
      <Dialog.Content>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Grid container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item sm={12} textAlign='center'>
              <Typography variant='h6'>RI</Typography>
              <Barcode
                value={data?.ri}
                displayValue='RI'
                format='CODE128'
                width={2.5}
                height={50}
              />
            </Grid>
            <Grid item sm={12} textAlign='center'>
              <Typography variant='h6'>GRADE</Typography>
              <Barcode
                value={data?.grade?.alterDescription}
                displayValue='RI'
                format='CODE128'
                width={2.5}
                height={50}
              />
            </Grid>
            <Grid item sm={12} textAlign='center'>
              <Typography variant='h6'>Nota fiscal</Typography>
              <Barcode value={data?.invoice} format='CODE128' width={2.5} height={50} />
            </Grid>
            <Grid item sm={12} textAlign='center'>
              <Typography variant='h6'>IMEI</Typography>
              <Barcode value={data?.imei} format='CODE128' width={2.5} height={50} />
            </Grid>
          </Grid>
        </Box>
      </Dialog.Content>
    </Dialog>
  );
}
