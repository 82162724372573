import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as LocaleImg } from 'assets/images/locale.svg';
import { BackNavButton, Button, Text, Spacer, Table } from 'components';
import { Field, Formik, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { OrderDialog } from './OrderDialog';

const columns = [{ label: 'Local Registrado' }, { label: <VisibilityIcon />, align: 'center' }];

const validationSchema = Yup.object().shape({
  description: Yup.string().required(),
});

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

const RegisterLocal = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [serviceOrder, setServiceOrder] = useState({});

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setServiceOrder(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  //
  const [locationsData, , loadingLocations, refetchLocationsData] = useQuery(
    () => api.get('/components/locations'),
    [],
  );

  const [locations, setLocations] = useState([]);
  const [name, setName] = useState('');

  useEffect(() => {
    setLocations(locationsData);
  }, [locationsData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { description } = values;

      const { data } = await api.post('/components/locations', { description });

      setLocations((prevState) => [
        ...prevState,
        {
          ...data,
        },
      ]);

      refetchLocationsData();
      toast.success('Local cadastrado com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao cadastrar local.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <BackNavButton />
      <Spacer axis='vertical' size={16} />
      <PageTitle>Cadastrar Local</PageTitle>
      <Formik
        initialValues={{ description: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} lg={3}>
                  <Field
                    label='Local'
                    name='description'
                    variant='outlined'
                    size='small'
                    fullWidth
                    component={Text}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button
                    type='submit'
                    disabled={!props.isValid || !props.dirty}
                    loading={props.isSubmitting}
                    onClick={props.handleSubmit}
                    fullWidth
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>

              <Spacer axis='vertical' size={8} />

              <Grid container>
                <Grid item xs={12} md={8} lg={5}>
                  <Table
                    headers={columns}
                    title='Lista de locais registrados'
                    striped
                    disableNumeration
                    loading={loadingLocations}
                    // maxHeight={400}
                    data={locationsData}
                    emptyMessage='Nenhum registro encontrado.'
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                  >
                    {locations?.map((location) => (
                      <TableRow key={location.id}>
                        <TableCell>{location.description}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, location)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'center' }} item xs={12} lg={7}>
                  <LocaleImg style={{ height: '300px' }} />
                </Grid>
              </Grid>
              {userRole === 1 ? (
                <OrderDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  serviceOrder={serviceOrder}
                  reloadComponents={refetchLocationsData}
                />
              ) : (
                <></>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default RegisterLocal;
