import { React, useEffect } from 'react';

import Button from '@material-ui/core/Button';

import { Dialog } from 'components';
import { Tabs } from 'components/Tabs';

import { ClientInfo } from './TableClientInfos';
import { ServiceOrder } from './TableServiceOrder';
import { Sorting } from './TableSorting';
export const LinkInformationDialog = ({ open, handleClose, prebudget }) => {
  useEffect(() => {}, [prebudget]);
  console.log('prebudget', prebudget);
  return (
    <Dialog maxWidth='sm' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Detalhes service ({prebudget?.number})</Dialog.Title>

      <Dialog.Content>
        <Tabs>
          <Tabs.Content label='Cliente informações'>
            <ClientInfo clientInfo={prebudget} />
          </Tabs.Content>
          {/* <Tabs.Content label='Part-Numbers'>
            <PartNumbers partNumbers={prebudget?.gspn_pre_budget} />
          </Tabs.Content> */}
          <Tabs.Content label='Service'>
            <ServiceOrder serviceOrder={prebudget} />
          </Tabs.Content>

          <Tabs.Content label='Triagem'>
            <Sorting serviceOrder={prebudget} />
          </Tabs.Content>
        </Tabs>
      </Dialog.Content>

      <Dialog.Actions>
        <Button onClick={handleClose} color='primary'>
          Fechar
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
