import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Select, Spacer, Tabs, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

import { MovementStockContext } from '../..';
import { validationSchema } from './validation';

export const ConfirmDialog = ({ open, onClose, selectedOrdering, dialogProps }) => {
  if (!open) return null;

  console.log('ABRIU IN WARRANTY');
  const [levelLocation, setLevelLocation] = useState();

  const [levelLocations, , loadingLevelLocations] = useQuery(
    () => componentService.listComponentLevelLocations(),
    [],
  );

  const levelLocationsOptions = (levelLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.description,
  }));

  const [stockLocations, , loadingStockLocations] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(levelLocation),
    [levelLocation],
  );

  const levelStockLocationsOptions = (stockLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.location_name,
  }));

  const { refetchComponentsInWarranty, refetchComponentsOutOfWarranty } =
    useContext(MovementStockContext);

  const onSubmit = async (values) => {
    try {
      await componentService.createStockMovement(values);

      await refetchComponentsInWarranty();
      await refetchComponentsOutOfWarranty();

      toast.success('Componente transferido com sucesso');
      onClose();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <Formik
        initialValues={{
          amount_handled: 0,
          part_number: selectedOrdering?.part_number,
          from_location_level_id: selectedOrdering?.component_level_location_id,
          from_stock_location_id: selectedOrdering?.stock_location?.id,
          to_level_location_id: '',
          to_stock_location_level: '',
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Dialog.Title onClose={onClose}>{dialogProps.title}</Dialog.Title>
            <Dialog.Content>
              <Tabs gutterButtom>
                <Tabs.Content label='Componente'>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2'>CÓDIGO DO PRODUTO:</Typography>
                      <Typography>{selectedOrdering?.part_number}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2'>DESCRIÇÃO:</Typography>
                      <Typography>{selectedOrdering?.description}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>TIPO DE ESTOQUE:</Typography>
                      <Typography>
                        {selectedOrdering?.component_level_location?.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>LOCAL DE ESTOQUE:</Typography>
                      <Typography>{selectedOrdering?.stock_location?.location_name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='subtitle2'>
                        QUANTIDADE DISPONÍVEL EM ESTOQUE:{' '}
                        <span style={{ fontSize: 16 }}>{selectedOrdering?.quantity}</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider variant='fullWidth' />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>Tranferência</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>SELECIONE O TIPO ESTOQUE:</Typography>
                      <Spacer size={12} />
                      <Field
                        size='small'
                        fullWidth
                        name='to_level_location_id'
                        variant='outlined'
                        label='Tipo de estoque'
                        emptyOption='Selecione'
                        loading={loadingLevelLocations}
                        options={levelLocationsOptions}
                        component={Select}
                        onChange={(e) => setLevelLocation(e)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>SELECIONE O LOCAL DE ESTOQUE:</Typography>
                      <Spacer size={12} />
                      <Field
                        name='to_stock_location_level'
                        variant='outlined'
                        label='Estoque a ser enviado:'
                        size='small'
                        value={selectedOrdering?.stock_location?.location_name}
                        loading={loadingStockLocations}
                        options={levelStockLocationsOptions}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant='subtitle2'>TRANSFERIR QUANTIDADE:</Typography>
                      <Spacer size={12} />
                      <Field
                        name='amount_handled'
                        variant='outlined'
                        label='Quantidade a ser transferida:'
                        size='small'
                        inputProps={{
                          min: 1,
                          max: selectedOrdering?.quantity,
                        }}
                        mask='number'
                        component={Text}
                      />
                    </Grid>
                  </Grid>
                </Tabs.Content>
              </Tabs>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onClick={onClose} variant='text' color='default'>
                Não
              </Button>
              <Button
                type='submit'
                disabled={
                  Object.values(props.values).indexOf('') === -1 && props.values.amount_handled <= 0
                }
                onClick={() => onSubmit(props.values)}
              >
                Sim
              </Button>
            </Dialog.Actions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
