import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { DatePicker, Select, Text } from 'components';
import { Field } from 'formik';

import { useInvoice } from '../context';

const InvoiceData = () => {
  // const [isWarnDialogOpen, setIsWarnDialogOpen] = useState(false);
  const {
    loadingProviders,
    loadingInputPurposes,
    loadingLocations,
    locations,
    levelLocations,
    loadingLevelLocations,
    providers,
    inputPurposes,
    disabledFields,
    setDisabledFields,
  } = useInvoice();

  // useEffect(() => {
  //   const noLocationsFound = !locations?.length && !loadingLocations;

  //   if (noLocationsFound) {
  //     setIsWarnDialogOpen(true);
  //   }
  // }, [locations, loadingLocations]);

  const inputPurposesOptions = (inputPurposes || []).map((purpose) => ({
    value: purpose.id,
    label: purpose.description,
  }));

  const providersOptions = (providers || []).map((provider) => ({
    value: provider.id,
    label: `${provider.company_name} (${provider.cnpj})`,
  }));

  const locationsOptions = (locations || []).map((location) => ({
    value: location.id,
    label: location.description,
  }));

  const levelLocationsOptions = (levelLocations || []).map((location) => ({
    value: location.id,
    label: location.description,
  }));

  // const navigateToRegisterLocation = () => {
  //   browserHistory.push('/stock/register-location');
  // };

  // const handleWarnDialogClose = () => {
  //   setIsWarnDialogOpen(false);
  // };

  const handleEmptyStockLocation = () => {
    const indexObjectRegister = invoice_items.map((item) => {
      invoice_items.location_id = '';
    });
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Dados Nota
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            fullWidth
            name='invoice_key'
            variant='outlined'
            label='Chave Nota Fiscal'
            component={Text}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Field
            size='small'
            fullWidth
            name='provider_id'
            variant='outlined'
            label='Fornecedor'
            loading={loadingProviders}
            options={providersOptions}
            component={Select}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={4}>
          <Field
            size='small'
            fullWidth
            name='invoice_number'
            variant='outlined'
            label='Nº Nota'
            component={Text}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={1}>
          <Field
            size='small'
            fullWidth
            name='serial_number'
            variant='outlined'
            label='Série'
            component={Text}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Field
            size='small'
            name='emission_date'
            variant='inline'
            inputVariant='outlined'
            label='Data Emissão'
            component={DatePicker}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Field
            size='small'
            name='receipt_date'
            variant='inline'
            inputVariant='outlined'
            label='Data Recebimento'
            component={DatePicker}
            disabled={disabledFields}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Field
            size='small'
            fullWidth
            name='input_purpose_id'
            variant='outlined'
            label='Finalidade de Entrada'
            emptyOption='Selecione'
            loading={loadingInputPurposes}
            options={inputPurposesOptions}
            component={Select}
          />
        </Grid>
        <Grid item xs={6} md={6} lg={4}>
          <Field
            size='small'
            fullWidth
            name='component_level_location_id'
            variant='outlined'
            label='Tipo de estoque'
            emptyOption='Selecione'
            loading={loadingLevelLocations}
            options={levelLocationsOptions}
            component={Select}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceData;
