import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Button, Table, Spacer } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService, serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { Cart } from './Cart';
import { addToCartAction } from './Cart/store/actions';
import { cartReducer } from './Cart/store/reducer';
import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  service_order: yup.string().required(),
});

const columns = [
  { label: '' },
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Processo' },
  { label: 'Quantidade' },
  { label: 'Ação', align: 'center', width: 180 },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const StockRequisitionContext = createContext();
export const initialStockCartState = [];

export const StockRequisition = () => {
  const classes = useStyles();

  const user = useContextSelector(AuthContext, (context) => context.user);

  const stateLocation = useLocation();

  console.log('State Location: ', stateLocation);

  const [components, setComponents] = useState([]);
  const [openCart, setOpenCart] = useBoolean();
  const [selectedComponent, setSelectedComponent] = useState(0);
  const [totalCart, setTotalCart] = useState(0);
  const [state, dispatch] = useReducer(cartReducer, initialStockCartState);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [serviceOrder, setServiceOrder] = useState();
  const [processType, setProcessType] = useState();

  const [search, setSearch] = useState('');

  const [componentsData, , loadingComponentsData, refetchComponents] = useQuery(
    () => componentService.listStockComponentsByLevelLocation(processType),
    [processType],
  );

  const [componentsDataByPns, , loadingComponentsByPnsData, refetchComponentsByPns] = useQuery(
    () => componentService.listStockComponentsByLevelLocationAndPns(processType, serviceOrder),
    [processType, serviceOrder],
  );

  // console.log('components data: ', componentsData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalItens = useCallback(
    (setTotalCart) => {
      const total = state?.reduce((total, { sub_total }) => {
        return total + sub_total;
      }, 0);

      if (!state?.length) {
        return setTotalCart(0);
      }

      setTotalCart(total);
    },
    [state],
  );

  useEffect(() => {
    setComponents(componentsData);
    totalItens(setTotalCart);
  }, [componentsData, totalItens]);

  const onSelectRowClick = (row) => {
    setSelectedComponent(row);
  };

  const formattedComponents = (componentsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedComponentsByPns = (componentsDataByPns || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredComponents = (formattedComponents || []).filter((components) => {
    const lowerPartNumber = components?.part_number?.toLowerCase();
    const upperPartNumber = components?.part_number?.toUpperCase();
    const lowerDescription =
      components?.description?.toLowerCase() ?? 'SEM DESCRIÇÃO'.toLowerCase();
    const upperDescription =
      components?.description?.toUpperCase() ?? 'SEM DESCRIÇÃO'.toUpperCase();
    const searchTerms = search.toLowerCase().split(' ');

    return searchTerms.every(
      (term) =>
        lowerDescription.includes(term) ||
        upperDescription.includes(term) ||
        lowerPartNumber.includes(term) ||
        upperPartNumber.includes(term),
    );
  });

  const filteredComponentsByPns = (formattedComponentsByPns || []).filter((components) => {
    const lowerPartNumber = components?.part_number?.toLowerCase();
    const upperPartNumber = components?.part_number?.toUpperCase();
    const lowerDescription =
      components?.description?.toLowerCase() ?? 'SEM DESCRIÇÃO'.toLowerCase();
    const upperDescription =
      components?.description?.toUpperCase() ?? 'SEM DESCRIÇÃO'.toUpperCase();

    const searchTerms = search.toLowerCase().split(' ');

    return searchTerms.every(
      (term) =>
        lowerDescription?.includes(term) ||
        upperDescription?.includes(term) ||
        lowerPartNumber?.includes(term) ||
        upperPartNumber?.includes(term),
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleServiceOrder = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await serviceOrderService.getByNumberWithNoRequisition(
        values?.service_order,
      );

      setServiceOrder(data?.[0]?.number);
      console.log('data', data);
      if (data?.[0]?.warranty_type === 'Out of Warranty') {
        setProcessType(3);
      } else {
        setProcessType(1);
      }

      if (data.length > 0) {
        toast.success('Ordem de serviço encontrada com sucesso.');
      } else {
        toast.info('O processo de requisição encontra-se indispoível para essa ordem de serviço.');
      }
      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          'Erro ao encontrar a ordem de serviço com o código informado.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  console.log('filtered components: ', filteredComponents);

  return (
    <>
      <PageTitle>Requisição de Estoque</PageTitle>
      <Formik
        initialValues={{ service_order: stateLocation?.state?.service_order_number ?? '' }}
        validationSchema={validationSchema}
        onSubmit={handleServiceOrder}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4}>
                <Field
                  label='Ordem de Serviço'
                  name='service_order'
                  variant='outlined'
                  size='small'
                  component={Text}
                  //  onInput={toInputUppercase}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button fullWidth type='submit'>
                  Consultar
                </Button>
              </Grid>
              {serviceOrder ? (
                <Grid item xs={12} lg={6}>
                  <Typography color='primary' variant='h5' align='right'>
                    Requisição para a OS: {serviceOrder}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Form>
        )}
      </Formik>

      <Spacer size={12} />

      {serviceOrder ? (
        <Formik enableReinitialize={true} initialValues={{ heatmaps: '' }}>
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <Text
                  label='Pesquisar'
                  size='small'
                  variant='outlined'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  onChange={handleSearchChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={setOpenCart.toTrue}
                  fullWidth
                  size='small'
                  className={classes.buttonOpenCart}
                >
                  <div className={state.length ? classes.cartCount : classes.cartEmpty}>
                    {state.length}
                  </div>
                  <FaCartPlus size={30} />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <StockRequisitionContext.Provider
                  value={{
                    state,
                    dispatch,
                    totalCart,
                    serviceOrder,
                    refetchComponents,
                  }}
                >
                  <Cart open={openCart} onClose={setOpenCart.toFalse} />
                  {processType === 2 ? (
                    <Table
                      headers={columns}
                      emptyMessage='Nenhum registro encontrado.'
                      striped
                      disableNumeration
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    >
                      {filteredComponentsByPns?.map((accessory) => (
                        <TableRow key={accessory.id}>
                          <TableCell>
                            <Radio
                              color='primary'
                              size='small'
                              checked={selectedComponent?.id === accessory.id}
                              onChange={() => onSelectRowClick(accessory)}
                            />
                          </TableCell>
                          <TableCell>{accessory?.part_number.toUpperCase()}</TableCell>
                          <TableCell>{accessory.description ?? 'SEM DESCRIÇÃO'}</TableCell>
                          <TableCell>
                            {accessory.component_level_location_id === 3
                              ? 'Fora de Garantia'
                              : 'Em Garantia'}
                          </TableCell>
                          <TableCell align='center'>{accessory.quantity}</TableCell>
                          <TableCell>
                            <Button
                              variant='outlined'
                              disabled={accessory.quantity > 0 ? false : true}
                              onClick={() => addToCartAction(accessory, dispatch)}
                              className={classes.buttonAddToCart}
                            >
                              Adicionar para requisição
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  ) : (
                    <Table
                      headers={columns}
                      emptyMessage='Nenhum registro encontrado.'
                      striped
                      disableNumeration
                      page={page}
                      rowsPerPage={rowsPerPage}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    >
                      {filteredComponents?.map((accessory) => (
                        <TableRow key={accessory.id}>
                          <TableCell>
                            <Radio
                              color='primary'
                              size='small'
                              checked={selectedComponent?.id === accessory.id}
                              onChange={() => onSelectRowClick(accessory)}
                            />
                          </TableCell>
                          <TableCell>{accessory?.part_number.toUpperCase()}</TableCell>
                          <TableCell>{accessory.description ?? 'SEM DESCRIÇÃO'}</TableCell>
                          <TableCell>
                            {accessory.component_level_location_id === 3
                              ? 'Fora de Garantia'
                              : 'Em Garantia'}
                          </TableCell>
                          <TableCell align='center'>{accessory.quantity}</TableCell>
                          <TableCell>
                            <Button
                              variant='outlined'
                              disabled={accessory.quantity > 0 ? false : true}
                              onClick={() => addToCartAction(accessory, dispatch)}
                              className={classes.buttonAddToCart}
                            >
                              Adicionar para requisição
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  )}
                </StockRequisitionContext.Provider>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
};
