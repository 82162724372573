import React from 'react';
import { useRouteMatch } from 'react-router';

import { BudgetKit } from 'pages/Private/SAC/BudgetKit';
import { BudgetTemplate } from 'pages/Private/SAC/BudgetTemplate';
import { CalcFare } from 'pages/Private/SAC/calcFare';
import { CancelBudget } from 'pages/Private/SAC/CancelBudget';
import { CancelLinkSafe2pay } from 'pages/Private/SAC/CancelPaymentLink';
import Checkout from 'pages/Private/SAC/Checkout';
import { BudgetRetail } from 'pages/Private/SAC/CreatePaymentForRetail';
import { IssueInvoiceBudget } from 'pages/Private/SAC/IssueInvoiceBudget';
import { IssueInvoiceNewKit } from 'pages/Private/SAC/IssueInvoiceBudget copy';
import { FinalizedSwap } from 'pages/Private/SAC/ListFinalizedSwap';
import { PreBudgetTecCheck } from 'pages/Private/SAC/PreBudgetTecCheck';
import { RegisterSWAP } from 'pages/Private/SAC/RegisterBatchItems';

import { Route } from '../Route';

export const SacRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/checkout`} component={Checkout} />
      <Route isPrivate path={`${path}/budget-kit`} component={BudgetKit} />
      <Route isPrivate path={`${path}/cancel-budget`} component={CancelBudget} />
      <Route isPrivate path={`${path}/cancel-linksafe2pay`} component={CancelLinkSafe2pay} />
      <Route isPrivate path={`${path}/pre-budget-tec-check`} component={PreBudgetTecCheck} />
      <Route isPrivate path={`${path}/issue-invoice-budget`} component={IssueInvoiceBudget} />
      <Route isPrivate path={`${path}/issue-invoice-newkit`} component={IssueInvoiceNewKit} />

      <Route isPrivate path={`${path}/create-budgetretail`} component={BudgetRetail} />
      <Route isPrivate path={`${path}/pending-swap`} component={RegisterSWAP} />
      <Route isPrivate path={`${path}/swap-finalized`} component={FinalizedSwap} />
      <Route isPrivate path={`${path}/template`} component={BudgetTemplate} />
      <Route isPrivate path={`${path}/calc-fare`} component={CalcFare} />
    </>
  );
};
