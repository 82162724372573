import React from 'react';
import { toast } from 'react-toastify';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  serial_number: Yup.string().length(24).matches(/^\d+$/).required(),
  invoice_number: Yup.string().required(),
});

export const UpdateRequestTagDialog = ({ open, handleClose, itemRequest, setArrayItens }) => {
  const handleAddItens = () => {
    setArrayItens((oldState) => [...oldState, itemRequest[0]?.nnf]);
    toast.success('Nota adicionada com sucesso.');
    handleClose();
  };

  console.log('aaa', itemRequest[0]?.chave_saida);

  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Número de nota de entrada: {itemRequest[0]?.nnf}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            invoice_number: undefined,
            serial_number: undefined,
            supply_id: undefined,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Typography variant='h6'>Dados para validação de NF</Typography>
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      label='Chave nota de saída'
                      name='invoice_number'
                      component={Text}
                      variant='outlined'
                      fullWidth
                      size='small'
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      label='Número de ordem de serviço'
                      name='serial_number'
                      component={Text}
                      variant='outlined'
                      fullWidth
                      disabled
                      value={itemRequest[0]?.number}
                      size='small'
                    />
                  </Grid>
                </Grid>

                <Spacer size={32} />

                <DialogActions>
                  <Button
                    onClick={() => handleAddItens()}
                    color='primary'
                    disabled={
                      props.values.invoice_number === '' ||
                      props.values.invoice_number !== itemRequest[0]?.chave_saida
                    }
                    fullWidth
                  >
                    VALIDAR ROMANEIO
                  </Button>
                  <Button onClick={handleClose} color='secondary' fullWidth>
                    Fechar
                  </Button>
                </DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
