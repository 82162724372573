import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { TableTabs } from './TableTabs';

export const IssueInvoiceNewKit = () => {
  return (
    <>
      <PageTitle>Emitir nota de KIT NOVO</PageTitle>
      <TableTabs />
    </>
  );
};
