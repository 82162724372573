import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginBottom: 5,
    },
    gridSendBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 10,
    },
    checkinHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    mailObjectTag: {
      background: theme.palette.primary.main,
      color: '#fff',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      borderRadius: 20,
      fontWeight: 700,
      fontSize: 18,

      display: 'flex',
      alignItems: 'center',

      '& > .box-icon': {
        marginRight: theme.spacing(1),
      },
    },
  };
});

export default useStyles;
