import React from 'react';

import { Tabs } from 'components';

import { Report } from './Report';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Laudo'>
        <Report />
      </Tabs.Content>
      <Tabs.Content label='Os Multimarcas'></Tabs.Content>
      <Tabs.Content label='Estado de Conservação'></Tabs.Content>
      <Tabs.Content label='Os Anteriores'></Tabs.Content>
    </Tabs>
  );
};
