import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  part_number: yup.string().required(),
  quantity: yup.number().required(),
  observation: yup.string().required(),
});

export const RequestComponentDebit = () => {
  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);
      await componentService.updateComponentsDebit(
        values.part_number,
        values.quantity,
        values.observation,
      );

      toast.success('Débito de componentes realizado com sucesso.');
      refetchRole();
    } catch (error) {
      toast.error(error.response.data?.message || 'Erro ao realizar débito de componentes.');
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Debitar Componentes</PageTitle>
      <Formik
        initialValues={{ part_number: '', quantity: 0, observation: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Part Number'
                  name='part_number'
                  variant='outlined'
                  size='small'
                  component={Text}
                  disabled={props.isSubmitting}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Quantidade'
                  name='quantity'
                  variant='outlined'
                  size='small'
                  mask='number'
                  component={Text}
                  disabled={props.isSubmitting}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button
                  type='submit'
                  fullWidth
                  disabled={!props.isValid || !props.dirty || props.isSubmitting}
                >
                  Cadastrar
                </Button>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Field
                  label='Motivo'
                  name='observation'
                  variant='outlined'
                  size='small'
                  component={Text}
                  multiline
                  rows={4}
                  disabled={props.isSubmitting}
                />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
