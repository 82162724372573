import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as ProductImg } from 'assets/images/product.svg';
import { Button, Text, FormatDate, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
// import { api } from 'services/api';
import { quoteService } from 'services';
import * as Yup from 'yup';

import { AlterQuoteSmartphoneBrand } from './AlterQuoteSmartphoneBrand';
import { useStyles } from './styles';

const columns = [
  { label: 'Nome' },
  { label: 'Data de cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const validationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
});

export const CreateQuoteSmartphoneBrand = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [name, setName] = useState('');
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [brand, setBrand] = useState({});

  const [smartphoneBrands, , loadingSmartphoneBrands, smartphoneBrandsRefetch] = useQuery(
    () => quoteService.listSmartphoneBrands(),
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBrand(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await quoteService.createSmartphoneBrand(values);

      smartphoneBrandsRefetch();
      toast.success('Marca cadastrada com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao cadastrar marca');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Marca para Smartphone</PageTitle>
      <Formik
        initialValues={{ description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Digite o nome da marca'
                    name='description'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    loading={loadingSmartphoneBrands}
                    emptyMessage='Sem marcas cadastrados.'
                    data={smartphoneBrands}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {smartphoneBrands?.map((brand) => (
                      <TableRow key={brand.id}>
                        <TableCell>{brand.description}</TableCell>
                        <TableCell>
                          <FormatDate>{brand.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, brand)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterQuoteSmartphoneBrand
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  brand={brand}
                  reloadBrands={smartphoneBrandsRefetch}
                />
                <Grid className={classes.imgContainer} item xs={12} lg={6}>
                  <ProductImg className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
