import React, { createContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation } from '@apollo/client';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ReactComponent as Trash } from 'assets/images/undraw_reminder_re_fe15.svg';
import { Button, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { RECEIVE_SERVICE_ORDER } from 'services/graphql/query/service-orders';

import { Header } from './components/Header';
import { ImeiForm } from './components/ImeiForm';
import { NextStepDialog } from './components/NextStepDialog';
import { ProductForm } from './components/ProductForm';
import { Questions } from './components/Questions';
import { useStyles } from './styles';

export const FunctionalScreeningContext = createContext();

export function FunctionalScreening() {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [data, setData] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const formikRef = useRef();
  const [isOpenNextStepDialog, setIsOpenNextStepDialog] = useState(false);
  const [nextStep, setNextStep] = useState('');

  const [submit, { loading, error }] = useMutation(RECEIVE_SERVICE_ORDER, {
    onCompleted: (data) => {
      setData(data);
      // toast.info('Produto recebido. Iniciando triagem funcional...');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar receber o produto';
      toast.error(`Error: ${errorMessage}`);
    },
    fetchPolicy: 'network-only',
  });

  if (error) {
    console.log('Mutation error', error);
  }

  if (loading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const handleSearch = (e) => {
    setSearch(e?.target?.value);
  };

  async function handleReceiveService() {
    if (!search) {
      toast.error('Por favor, insira um valor de pesquisa válido.');
      return;
    }
    try {
      await submit({
        variables: {
          sinister: search,
          imei: search,
        },
      });
    } catch (error) {
      console.log('error ==>', error);
      toast.error(`Erro ao pesquisar o produto`);
    }
  }

  async function handleSubmitSearch(values, props) {
    setActiveStep(0);
    handleReceiveService(props);
  }

  const handleReset = () => {
    formikRef?.current?.resetForm();
    setSearch('');
    setData('');
  };

  const handleOpenNextStepDialog = () => {
    setIsOpenNextStepDialog(true);
  };

  const handleCloseNextStepDialog = () => {
    setIsOpenNextStepDialog(false);
  };

  const getStepContent = (activeStep) => {
    const steps = {
      0: <ProductForm />,
      1: <ImeiForm />,
      2: <Questions />,
    };
    return steps[activeStep];
  };

  return (
    <>
      <PageTitle>Triagem Funcional</PageTitle>

      <Formik initialValues={{ search: '' }} innerRef={formikRef} onSubmit={handleSubmitSearch}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Field
                  name='search'
                  label='Pesquisar Voucher'
                  size='small'
                  variant='outlined'
                  fullWidth
                  endAdornment={<QrCodeIcon />}
                  onChange={handleSearch}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button fullWidth startIcon={<SearchIcon />} type='submit'>
                  Consultar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      {data && (
        <FunctionalScreeningContext.Provider
          value={{
            data: data?.receiveServiceOrder,
            sinister: data?.receiveServiceOrder?.sinister,
            imei: data?.receiveServiceOrder?.imei,
            reset: handleReceiveService,
            handleReset: handleReset,
            currentProduct: data?.receiveServiceOrder?.sku,
            navigationStep: setActiveStep,
            handleOpenNextStepDialog: handleOpenNextStepDialog,
            setNextStep: setNextStep,
          }}
        >
          <Header />
          <Spacer size={64} />
          {getStepContent(activeStep)}
        </FunctionalScreeningContext.Provider>
      )}

      {!data && (
        <Grid className={classes.containerImg} container>
          <Trash className={classes.trashImage} />
        </Grid>
      )}

      <NextStepDialog
        handleClose={handleCloseNextStepDialog}
        isOpen={isOpenNextStepDialog}
        nextStep={nextStep}
      />
    </>
  );
}
