import {
  addComponentToCart,
  removeComponentFromCart,
  updateComponentAmount,
  cleanCart,
} from './reducerFunctions';

export const cartComponentReducer = (state, action) => {
  switch (action.type) {
    case '@ADD_COMPONENT_TO_CART':
      return addComponentToCart(state, action);

    case '@REMOVE_COMPONENT_FROM_CART':
      return removeComponentFromCart(state, action);

    case '@UPDATE_COMPONENT_AMOUNT':
      return updateComponentAmount(state, action);

    case '@CLEAN_CART':
      return cleanCart(state, action);

    default:
      break;
  }
};
