import React, { useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FormatDate } from 'components/FormatDate';

import { ServiceOrderContext } from '../..';
import { useStyles } from './styles';

function createData(tipoLog, dataLog, obs, user) {
  return { tipoLog, dataLog, obs, user };
}

export default function DenseTable() {
  const classes = useStyles();
  const { logs } = useContext(ServiceOrderContext);

  return (
    <TableContainer>
      <Table
        // className={classes.table}
        size='medium'
        aria-label='a dense table'
        stripped
        emptyMessage='Nenhum registro encontrado.'
      >
        <TableHead>
          <TableRow>
            <TableCell>Tipo Log</TableCell>
            <TableCell>Data Log</TableCell>
            <TableCell>Obs</TableCell>
            <TableCell>Usuário</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logs?.map((log) => {
            return (
              <TableRow key={log?.id}>
                <TableCell>{log?.title}</TableCell>
                <TableCell>
                  {<FormatDate format='dd/MM/yyyy HH:mm:ss'>{log?.created_at}</FormatDate>}
                </TableCell>
                <TableCell>{log?.identifier}</TableCell>
                <TableCell>{log?.name}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
