import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';

export function NextStepDialog({ isOpen = false, handleClose, nextStep }) {
  if (!isOpen) {
    return <></>;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Próxima etapa</Dialog.Title>
      <Dialog.Content>
        <Typography variant='h5' align='center' color='secondary'>
          {nextStep}
        </Typography>
      </Dialog.Content>
    </Dialog>
  );
}
