import { React, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Checkbox } from 'components';
import { Button } from 'components/Button';
import { Select } from 'components/Form/Select';
import { Text } from 'components/Form/Text';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { invoiceService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validationSchema';

export const IssueAnalysisNote = () => {
  const classes = useStyles();

  const [disableBills, setDisableBills] = useState(false);

  // const disableBill = () => {
  //   if (disableBills) {
  //     setHasBilling(false);
  //   } else {
  //     setHasBilling(true);
  //   }
  // };

  useEffect(() => {}, []);

  const handleDownloadTagsClick = async (service_order_number) => {
    const { data } = await invoiceService.downloadCashierSalesTag(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  const [laborsData, , loadingLabors] = useQuery(() => api.get('/cashier/labor'), []);

  const additionallabor = (laborsData || []).map((laborValue) => ({
    value: laborValue.id,
    label: laborValue.description,
  }));

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.description,
    }),
  );

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    console.log('Value: ', values.issueNoteOrNot);
    try {
      await invoiceService.analysisIssueNote(
        values.type_of_analysis_id,
        values.service_number,
        values.issueNoteOrNot,
      );

      resetForm();
      setSubmitting(true);
      handleDownloadTagsClick(values.service_number);
      toast.success('Nota de analise emitida com sucesso');
    } catch (err) {
      console.log(err);
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{
        type_of_analysis_id: 0,
        service_number: '',
        issueNoteOrNot: false,
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(props) => (
        <>
          <Form onSubmit={props.handleSubmit}>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={12} md={3}>
                <Field
                  label='Service'
                  name='service_number'
                  size='small'
                  variant='outlined'
                  inputProps={{ maxLength: 10 }}
                  fullWidth
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  label='Tipo da análise'
                  name='type_of_analysis_id'
                  size='small'
                  variant='outlined'
                  component={Select}
                  options={additionallabor}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Button type='submit' fullWidth>
                  EMITIR
                </Button>
              </Grid>
              <Grid item xs={12} lg={12} style={{ fontSize: 18 }}>
                <Field
                  component={Checkbox}
                  name='issueNoteOrNot'
                  label='EMITIR NOTA COM VALOR FISCAL'
                  onClick={setDisableBills}
                  checked={disableBills === true ? true : false}
                />
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
};
