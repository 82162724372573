import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { GeneralTabs } from '../../PcpRH/GeneralTabs';
import { useStyles } from './styles';

export const PcpRh = () => {
  const classes = useStyles();

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log(values);
    setTimeout(() => {
      setSubmitting(false);
      toast.success('Atribuídas com sucesso!');
    }, 2000);
  };

  return (
    <>
      <PageTitle>Listagem de Services RH</PageTitle>
      <GeneralTabs />
    </>
  );
};
