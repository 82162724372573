import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import Chip from '@mui/material/Chip';
import { Table } from 'components';
import { Text } from 'components';
import { Formik, Form } from 'formik';

const columns = [
  { label: 'Endereço', align: 'center' },
  { label: 'Rua', align: 'center' },
  { label: 'Bloco', align: 'center' },
  { label: 'Andar', align: 'center' },
  { label: 'Local', align: 'center' },
  { label: 'Quantidade Máxima', align: 'center' },
  { label: 'Quantidade Alocada', align: 'center' },
  { label: 'Grade', align: 'center' },
];

export function StockLocationTable({ data, loading }) {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const formattedData = (data?.listStockAddress || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredData = formattedData.filter((item) => {
    const searchText = search?.toLowerCase();

    const isString = (value) => typeof value === 'string';

    return (
      (isString(item?.description) && item?.description?.toLowerCase()?.includes(searchText)) ||
      (isString(item?.street?.description) &&
        item?.street?.description?.toLowerCase()?.includes(searchText)) ||
      (isString(item?.block?.description) &&
        item?.block?.description?.toLowerCase()?.includes(searchText)) ||
      (isString(item?.floor?.description) &&
        item?.floor?.description?.toLowerCase()?.includes(searchText)) ||
      (isString(item?.location?.description) &&
        item?.location?.description?.toLowerCase()?.includes(searchText))
    );
  });

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  function getGradeColor(gradeId) {
    switch (gradeId) {
      case 1:
        return {
          label: 'LIKE NEW',
          color: '#FFFFFF', // Branco para LIKE NEW
          textColor: '#000000',
          border: '1px solid #000000',
        };
      case 2:
        return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
      case 3:
        return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
      case 4:
        return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
      case 5:
        return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
      case 6:
        return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
      default:
        return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
    }
  }

  return (
    <>
      <Formik initialValues={{ search: '' }} onSubmit={() => {}}>
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    loading={loading}
                    emptyMessage='Sem locais cadastrados.'
                    data={filteredData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredData?.map((item) => {
                      const grade = getGradeColor(item?.block?.grade?.id);
                      return (
                        <TableRow key={item?.id}>
                          <TableCell align='center'>{item?.description}</TableCell>
                          <TableCell align='center'>{item?.street?.description}</TableCell>
                          <TableCell align='center'>{item?.block?.description}</TableCell>
                          <TableCell align='center'>{item?.floor?.description}</TableCell>
                          <TableCell align='center'>{item?.location?.description}</TableCell>
                          <TableCell align='center'>{item?.maxAmount}</TableCell>
                          <TableCell align='center'>{item?.allocatedAmount}</TableCell>
                          <TableCell align='center'>
                            <Chip
                              label={grade.label}
                              style={{
                                backgroundColor: grade.color,
                                color: grade.textColor,
                                border: grade.border || 'none',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
