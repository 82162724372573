import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Button } from 'components';
import { Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import { api } from 'services/api';
import XLSX from 'xlsx';

import { validationSchema } from './validation';

export const ImportXmlInvoiceRepair = () => {
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const [ComponentsCatalog, , loadingComponentsCatalog] = useQuery(
    componentService.listcatalogBase,
    [],
  );

  useEffect(() => {}, []);

  const handleDownloadComponentUnitPrice = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(ComponentsCatalog));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatorioNotasDeReparo${now}.xlsx`);
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.type !== 'text/xml') {
        toast.error('Adicione um arquivo do tipo XML');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = event.target.result;

        console.log('result: ', result);

        api
          .post('/invoices/repair/xml', result, {
            headers: {
              'Content-Type': 'application/xml',
            },
          })
          .then((response) => {
            const { data } = response;

            const { newProvider, ...importValues } = data;

            if (newProvider) {
              refetchProviders();
            }

            toast.success('Importação realizada com sucesso');
          })
          .catch((err) => {
            console.log('ERROOO:', err?.response?.data?.message);
            toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
          });

        setTimeout(() => {
          toast.warn('Acompanhe na tabela de itens após a importação');
        }, 2000);
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message | 'Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Importar XML de Nota de Reparo</PageTitle>
      <Formik
        initialValues={{
          service_order_number: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Button
                    fullWidth
                    onClick={handleImportClick}
                    disabled={importing}
                    startIcon={<FileUploadIcon />}
                  >
                    Importar XML
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    fullWidth
                    variant='outlined'
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadComponentUnitPrice}
                    disabled={importing}
                  >
                    Baixar planilha
                  </Button>
                </Grid>

                {importing ? (
                  <Grid xs={6} lg={12}>
                    <Box sx={{ width: '90%' }}>
                      <LinearProgress color='secondary' />
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <div
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      alignItems: 'center',
                      background: '#f2f2f2',
                      borderRadius: '40px 40px 40px 40px',
                      border: '0.5% solid ',
                      height: '50px',
                      width: 250,
                      padding: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' style={{ marginLeft: '10px', fontSize: '0.9rem' }}>
                      Atenção! A consulta pode demorar alguns minutos.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
