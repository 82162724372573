import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

// eslint-disable-next-line import-helpers/order-imports
import { Button } from 'components';
//import { useQuery } from 'hooks/useQuery';

import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';

import { ServiceOrderContext } from '../../..';
import useStyles from '../styles';
import { DialogConfirmShippingQa } from './DialogConfirmShippingQa';

export const Shipping = () => {
  const classes = useStyles();

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsServicesByServiceId(serviceFromGaia?.id),
    [],
  );

  const [budgetParts, , loadingBudgetParts, refetchBudgetsParts] = useQuery(
    () => budgetService.listBudgetsServicesByServiceIdToAplicatedComponents(serviceFromGaia?.id),
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
  };

  // const [requests, , loadingRequests] = useQuery(
  //   serviceOrderService.listStockRequests(serviceFromGaia?.id),
  //   [],
  // );

  // console.log('requests', requests);

  return (
    <>
      {/* <Typography variant='h6' gutterBottom>
        Transporte
      </Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={2}>
          <Button
            color='secondary'
            onClick={() => handleOpenInformation()}
            fullWidth
            disabled={
              serviceFromGaia?.status.id !== 6
              //(serviceFromGaia?.status_id === 6 && budget?.additional_services_total_price !== 0)
            }
            // loading={loadingallServices}
          >
            LIBERAR PARA QA
          </Button>
        </Grid>

        <DialogConfirmShippingQa
          open={isDetailsOpen}
          handleClose={handleCloseInformation}
          service={serviceFromGaia?.number}
        />
      </Grid>
    </>
  );
};
