import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    containerImg: {
      display: 'flex',
      marginTop: 'auto',
    },
    img: {
      height: '200px',
      maxWidth: '450px',
      marginTop: '25%',
    },
  };
});
