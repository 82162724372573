import React from 'react';

import Paper from '@material-ui/core/Paper';

import { CreateAccountForm } from './Form';
import { useStyles } from './styles';

export const CreateAccount = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={4} className={classes.container}>
        <h1 className={classes.title}>Criar conta</h1>

        <CreateAccountForm />
      </Paper>
    </div>
  );
};
