import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  cnpj: yup.number().required(),
  razao_social: yup.string().required(),
  atualizado_em: yup.string().required(),
  capital_social: yup.number().required(),
  inscricao_estadual: yup.number().required(),
  pais_id: yup.number().required(),
  country: yup.string().required(),
  zipcode: yup.number().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  street: yup.string().required(),
  number: yup.number().required(),
  complement: yup.string().required(),
  county_code: yup.number().required(),
});
