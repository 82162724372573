import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { IN_OUT_REPORT } from 'services/graphql/query/reports';
import XLSX from 'xlsx';

export function InOutReport() {
  const [isLoading, setIsLoading] = useState(false);
  const [downloadReport] = useMutation(IN_OUT_REPORT, {
    onCompleted: (data) => {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data?.inOutReport));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          warehouse: worksheet,
        },
        SheetNames: ['warehouse'],
      });
      XLSX.writeFile(workbook, `Relatório_IN_OUT.xlsx`);
      toast.success('Relatório baixado com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Erro ao baixar o relatório`);
    },
  });

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await downloadReport();
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de IN OUT</PageTitle>

      <Grid>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={() => handleSubmit()} loading={isLoading}>
            Baixar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
