import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Text, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';

import { RegisterBudgetStatusDialog } from './RegisterBudgetStatusDialog';
import { validationSchema } from './validation';

const columns = [{ label: 'Descrição' }, { label: <VisibilityIcon />, align: 'center' }];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const RegisterBudgetStatus = () => {
  const [name, setName] = useState([]);

  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDialogChangeInformations, setIsDialogChangeInformations] = useBoolean();

  //
  const [budgetStatusDescription, setBudgetStatusDescription] = useState({});
  const [budgetStatusDescriptionProcess, setBudgetStatusDescriptionProcess] = useState([]);
  //
  const [budgetStatusData, , loadingBudgetStatus, refetchBudgetStatus] = useQuery(
    () => budgetService.listStatuses(),
    [],
  );

  useEffect(() => {
    setBudgetStatusDescriptionProcess(budgetStatusData);
  }, [budgetStatusData]);

  const handleCloseDialog = () => {
    setIsDialogChangeInformations.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDialogChangeInformations.toTrue();

    setBudgetStatusDescription(item);
  };

  const [search, setSearch] = useState('');

  const filteredComponents = (budgetStatusData || []).filter((component) => {
    const loweredSearch = search.toLowerCase();

    return (
      component.description.toLowerCase().includes(loweredSearch) ||
      component.initials.toLowerCase().includes(loweredSearch)
    );
  });

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/budgets/statuses-create', values);

      setName((prevState) => [...prevState, data]);

      refetchBudgetStatus();
      toast.success('Serviço cadastrado com sucesso');
      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar serviço.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Status de orçamento</PageTitle>
      <Formik
        initialValues={{ description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Descrição'
                    name='description'
                    size='small'
                    variant='outlined'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button disabled={!props.isValid || !props.dirty} type='submit' fullWidth>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid container></Grid>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingBudgetStatus}
                    data={budgetStatusDescriptionProcess}
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredComponents.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.description}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, row)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                {userRole === 1 ? (
                  <RegisterBudgetStatusDialog
                    open={isDialogChangeInformations}
                    handleClose={handleCloseDialog}
                    budgetStatus={budgetStatusDescription}
                    refreshBudgetStatus={refetchBudgetStatus}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
