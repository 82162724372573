import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    legend: {
      fontWeight: 'bold',
    },
    containerLegend: {
      display: 'flex',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '40px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    p: {
      marginLeft: '10px',
      fontSize: '1rem',
    },
  };
});
