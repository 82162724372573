import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Table, FormatDate, FormatMoney } from 'components';
import { Formik, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';

import { TableBudgetsReprovedDialog } from './TableBudgetsReprovedDialog';

const columns = [
  { label: 'Service' },
  { label: 'Sinistro' },
  { label: 'Seguradora' },
  { label: 'Total em componentes' },
  { label: 'Total em Serviços' },
  { label: 'Total do orçamento' },
  { label: 'Data de criação/atribuição', align: 'center' },
  { label: 'Último status', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const TableBudgetsReproved = () => {
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [budget, setBudget] = useState({});

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBudget(item);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const [budgetsReproved, , loadingBudgetsReproved] = useQuery(
    () => budgetService.getBudgetsReproved(),
    [],
  );

  const formattedBudgetsReproved = (budgetsReproved || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredBudgetsReproved = formattedBudgetsReproved.filter((budget) => {
    const upperService = budget.service_order?.number?.toUpperCase();
    const lowerService = budget.service_order?.number?.toLowerCase();

    return upperService?.includes(search) || lowerService?.includes(search);
  });

  console.log('Approved: ', budgetsReproved);

  return (
    <>
      <Formik initialValues={{ search_os: '' }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Field
                size='small'
                fullWidth
                endAdornment={<SearchIcon />}
                name='search_os'
                variant='outlined'
                label='Consultar OS'
                component={Text}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                headers={columns}
                loading={loadingBudgetsReproved}
                emptyMessage='Nenhum registro encontrado.'
                striped
                maxHeight
                data={filteredBudgetsReproved}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                disableNumeration
              >
                {filteredBudgetsReproved?.map((budget) => {
                  return (
                    <TableRow key={budget?.id}>
                      <TableCell>{budget?.service_order?.number}</TableCell>
                      <TableCell>
                        {budget?.service_order?.sinister == '0' ||
                        budget?.service_order?.sinister == 'undefined'
                          ? 'Não possui'
                          : budget?.service_order?.sinister}
                      </TableCell>

                      <TableCell>{budget?.service_order?.add_info}</TableCell>

                      <TableCell>
                        <FormatMoney>{budget?.components_total_price}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <FormatMoney>{budget?.additional_services_total_price}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <FormatMoney>{budget?.components_total_price}</FormatMoney>
                      </TableCell>
                      <TableCell align='center'>
                        <FormatDate format='dd/MM/yyyy HH:mm:ss'>{budget?.created_at}</FormatDate>
                      </TableCell>
                      <TableCell align='center'>
                        <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                          {budget?.last_status_updated_date}
                        </FormatDate>
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton
                          color='primary'
                          onClick={(e) => handleOpenInformation(e, budget)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid>
            <TableBudgetsReprovedDialog
              open={isDetailsOpen}
              handleClose={handleCloseInformation}
              budget={budget}
            />
          </Grid>
        </>
      </Formik>
    </>
  );
};
