import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import { budgetService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export const RegisterSubDeviceDefectDialog = ({
  open,
  handleClose,
  subDeviceDefect = {},
  reloadSubDevice,
}) => {
  if (!open) return null;

  console.log('Service Order: ', subDeviceDefect);

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await budgetService.updateSalesComponentePrice(subDeviceDefect.id, values.sell_price);

      reloadSubDevice();
      toast.success('Preço do componente alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o preço do componente.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>
        Defeito do Dispositivo:
        {subDeviceDefect.description}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            description: subDeviceDefect.description,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Defeito'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                </Grid>

                <div container>
                  <Button onClick={handleClose} color='primary' variant='text'>
                    Fechar
                  </Button>
                  <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
