import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Clipboard, Spacer, Dialog, Select } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Mask } from 'components/Mask';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';
import { Form, Field, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  status_id: yup.string(),
  contactAttemptOne: yup.boolean(),
});

export const LinkInformationDialog = ({ open, handleClose, budget, reloadStatuses }) => {
  if (!open) return null;

  const [statuses, , loadingStatuses] = useQuery(() => budgetService.listStatuses(), []);
  const classes = useStyles();
  const [triedOne, setHasTriedOne] = useState(false);
  const [triedTwo, setHasTriedTwo] = useState(false);
  const [triedThree, setHasTriedThree] = useState(false);

  console.log('triedOne: ', triedOne);
  console.log('triedTwo: ', triedTwo);
  console.log('triedThree: ', triedThree);

  const setTriedOne = () => {
    if (triedOne) {
      setHasTriedOne(false);
    } else {
      setHasTriedOne(true);
    }
  };

  const setTriedTwo = () => {
    if (triedTwo) {
      setHasTriedTwo(false);
    } else {
      setHasTriedTwo(true);
    }
  };

  const setTriedThree = () => {
    if (triedThree) {
      setHasTriedThree(false);
    } else {
      setHasTriedThree(true);
    }
  };

  useEffect(() => {
    // if (!triedOne) {
    //   setHasTriedOne(true);
    // }
    // console.log('triedOne Effect: ', triedOne);
    // if (!triedTwo) {
    //   setHasTriedTwo(true);
    // }
    // console.log('triedTwo Effect: ', triedTwo);
    // if (!triedThree) {
    //   setHasTriedThree(true);
    // }
    // console.log('triedThree Effect: ', triedThree);
  }, [triedOne, triedTwo, triedThree]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const id = budget.id;

      await budgetService.updateBudgetStatus(id, values?.status_id, triedOne, triedTwo, triedThree);

      reloadStatuses();
      toast.success('Status do orçamento alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Erro ao realizar a alteração de status do orçamento.',
      );
    } finally {
      handleClose();
      resetForm();
      setSubmitting(false);
    }
  };

  const statusesOptions = [
    {
      value: 1,
      label: 'Pendente',
    },
    {
      value: 2,
      label: 'Processamento',
    },

    {
      value: 3,
      label: 'Autorizado',
    },
    {
      value: 4,
      label: 'Disponível',
    },
    {
      value: 5,
      label: 'Em disputa',
    },
    {
      value: 6,
      label: 'Devolvido',
    },
    {
      value: 7,
      label: 'Baixado',
    },
    {
      value: 8,
      label: 'Recusado',
    },
    {
      value: 10,
      label: 'Cancelado',
    },
    {
      value: 11,
      label: 'Liberado',
    },
    {
      value: 12,
      label: 'Em cancelamento',
    },
    {
      value: 13,
      label: 'Cancelado',
    },
    {
      value: 14,
      label: 'Retornar ao SAC',
    },
    {
      value: 15,
      label: 'Autorizado varejo',
    },
  ];

  const columns = [
    { label: 'Descrição do serviço', align: 'center' },
    { label: 'Preço do serviço', align: 'center' },
    { label: 'Produto', align: 'center' },
  ];

  const columnsSalesComponent = [
    { label: 'Part-number', align: 'center' },
    { label: 'Preço de venda componente', align: 'center' },
  ];

  return (
    <Dialog maxWidth='sm' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Detalhes do orçamento service: ( {budget?.service_order?.number})
      </Dialog.Title>

      <Dialog.Content>
        <Formik
          initialValues={{
            status_id: budget?.status_id,
            contactAttemptOne: budget?.contactAttemptOne,
            contactAttemptTwo: budget?.contactAttemptTwo,
            contactAttemptThree: budget?.contactAttemptThree,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Tabs gutterBottom>
                  <Tabs.Content label='Dados cliente' disablePadding>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <p className={classes.detailKey}>Nome do cliente:</p>
                        <p className={classes.detailValue}>{budget?.service_order?.client?.name}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className={classes.detailKey}>Email do cliente:</p>
                        <p className={classes.detailValue}>
                          {budget?.service_order?.client?.email}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className={classes.detailKey}>CPF ou CNPJ:</p>
                        <p className={classes.detailValue}>{budget?.service_order?.client?.cgc}</p>
                      </Grid>
                      <Grid item xs={6}>
                        <p className={classes.detailKey}>Telefone:</p>
                        <p className={classes.detailValue}>
                          <Mask type='phone'>
                            {budget?.service_order?.client?.phone_number
                              ? budget?.service_order?.client?.residential_phone_number
                              : 'Sem telefone cadastrado'}
                          </Mask>
                        </p>
                      </Grid>
                    </Grid>
                  </Tabs.Content>
                  <Tabs.Content label='Serviços adicionais' disablePadding>
                    <Table
                      headers={columns}
                      emptyMessage='Nenhum registro encontrado.'
                      disableNumeration
                      striped
                      maxHeight={400}
                    >
                      {budget?.additional_services?.map((additional_service) => {
                        return (
                          <TableRow key={additional_service?.id}>
                            <TableCell align='center'>{additional_service?.description}</TableCell>
                            <TableCell align='center'>
                              <FormatMoney>{additional_service?.service_price}</FormatMoney>
                            </TableCell>
                            <TableCell align='center'>
                              {additional_service?.additional_service_product?.description}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </Tabs.Content>
                  <Tabs.Content label='Componentes usados' disablePadding>
                    <Table
                      headers={columnsSalesComponent}
                      emptyMessage='Nenhum registro encontrado.'
                      disableNumeration
                      striped
                      maxHeight={400}
                    >
                      {budget?.sales_components?.map((saleComponent) => {
                        return (
                          <TableRow key={saleComponent?.id}>
                            <TableCell align='center'>{saleComponent?.part_number}</TableCell>
                            <TableCell align='center'>
                              <FormatMoney>{saleComponent?.sell_price}</FormatMoney>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </Tabs.Content>
                </Tabs>
                <Spacer axis='vertical' size={15} />
                <Divider variant='fullWidth' />
                <p className={classes.detailKey}>
                  Link gerado para pagamento pela plataforma Safe2Pay:
                </p>
                <Spacer axis='vertical' size={1} />
                <Clipboard value={budget.payment_url} disabled />

                {budget.payment_url2 ? (
                  <>
                    <Spacer axis='vertical' size={8} />
                    <Clipboard value={budget.payment_url2} disabled />
                  </>
                ) : (
                  <></>
                )}

                {budget.payment_url3 ? (
                  <>
                    <Spacer axis='vertical' size={8} />
                    <Clipboard value={budget.payment_url3} disabled />
                  </>
                ) : (
                  <></>
                )}
                <Spacer axis='vertical' size={15} />
                <Divider variant='fullWidth' />
                <p className={classes.detailKey}>Tentativas de Contato:</p>
                <Spacer axis='vertical' size={1} />
                <Grid container>
                  <Grid item xs={12}>
                    <Checkbox
                      color='primary'
                      name='contactAttemptOne'
                      onClick={setTriedOne}
                      checked={budget?.contactAttemptOne == true ? true : triedOne}
                      disabled={budget?.contactAttemptOne}
                    />
                    1°Contato
                    <Checkbox
                      color='primary'
                      name='contactAttemptTwo'
                      onClick={setTriedTwo}
                      checked={budget?.contactAttemptTwo == true ? true : triedTwo}
                      disabled={budget?.contactAttemptTwo}
                    />{' '}
                    2°Contato
                    <Checkbox
                      color='primary'
                      name='contactAttemptThree'
                      onClick={setTriedThree}
                      checked={budget?.contactAttemptThree == true ? true : triedThree}
                      disabled={budget?.contactAttemptThree}
                    />{' '}
                    3°Contato
                    <p className={classes.detailKey}>Status do orçamento:</p>
                    <Grid item xs={8}>
                      <Field
                        variant='outlined'
                        name='status_id'
                        label='Selecionar'
                        size='small'
                        value={budget.status_id}
                        component={Select}
                        options={statusesOptions}
                        loading={loadingStatuses}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
                  <Button onClick={handleClose} color='primary'>
                    Fechar
                  </Button>
                  <Button disabled={!props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
