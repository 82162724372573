import React from 'react';
import { useState } from 'react';

import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import XLSX from 'xlsx';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do GAIA',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportLocations({ handleImportClick, loading }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleDownloadLayout = async () => {
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'RUA ex(RA01)',
          b: 'BLOCO ex(BL01)',
          c: 'ANDAR ex(AD01)',
          d: 'LOCAL ex(A01)',
          e: 'GRADE ex(LIKE NEW, EXCELENTE, MUITO BOM, BOM+, BOM, QUEBRADO)',
          f: 'QUANTIDADE MÁXIMA DE PRODUTOS ex(4)',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Layout_locais.xlsx');
  };

  return (
    <>
      <Button
        fullWidth
        color='primary'
        variant='outlined'
        onClick={() => handleOpenDialog()}
        loading={loading}
      >
        Importar Locais
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
        downloadLayout={handleDownloadLayout}
      />
    </>
  );
}
