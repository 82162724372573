import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Select, Text, Table } from 'components';
import { Field, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const columns = [
  {
    label: '#',
    width: 48,
    align: 'center',
  },
  {
    label: 'Part Number',
  },
  {
    label: 'Quantidade',
  },
  {
    label: 'Sub Categoria',
  },
  {
    label: 'Peso',
  },
  {
    label: 'Peso Total',
  },
];

const rows = [
  {
    id: 1,
    part_number: 541548,
    quantity: 125,
    sub_category: 'A',
    weight: '155KG',
    weight_total: '200kg',
  },
];

export const Report = () => {
  const classes = useStyles();

  const userName = useContextSelector(AuthContext, (context) => context.user.name);

  return (
    <Formik initialValues={{ name_tecnichal: userName, service_number: '' }}>
      <>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Field
              variant='outlined'
              label='Service'
              name='service_number'
              size='small'
              inputProps={{ maxLength: 10, minlength: 10 }}
              component={Text}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Field
              variant='outlined'
              label='Nome técnico'
              name='name_tecnichal'
              disabled
              size='small'
              component={Text}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Field
              variant='outlined'
              label='Componentes'
              name='components'
              size='small'
              component={Select}
            />
          </Grid>
          <Grid item xs={12} lg={4}>
            <Field
              variant='outlined'
              label='Pesquisar'
              name='search'
              size='small'
              component={Text}
            />
          </Grid>
          <Grid item xs={4} lg={2}>
            <Button fullWidth>Gravar</Button>
          </Grid>
          <Grid item xs={4} lg={2}>
            <Button color='inherit' fullWidth>
              Excluir
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Table headers={columns} striped disableNumeration>
              {rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell padding='checkbox' size='small'>
                    <Checkbox color='primary' />
                  </TableCell>
                  <TableCell>{row.part_number}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                  <TableCell>{row.sub_category}</TableCell>
                  <TableCell>{row.weight}</TableCell>
                  <TableCell>{row.weight_total}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
          <Grid item xs={12} lg={2}>
            <Button fullWidth>reparo</Button>
          </Grid>
        </Grid>
      </>
    </Formik>
  );
};
