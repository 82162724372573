/* eslint-disable no-restricted-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';

import { CircularProgress, Grid, Typography } from '@material-ui/core';

import { useMutation, useQuery } from '@apollo/client';
import { TextField, Autocomplete } from '@mui/material';
import { Button, Spacer } from 'components';
import { Field, Form, Formik } from 'formik';
import { LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR } from 'services/graphql/query/product';
import { UPDATE_SERVICE_ORDER_PRODUCT } from 'services/graphql/query/service-orders';

import { validationSchema } from './validation';

import { FunctionalScreeningContext } from '..';

export function ProductForm() {
  const { currentProduct, sinister, navigationStep, reset } = useContext(
    FunctionalScreeningContext,
  );

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [storages, setStorages] = useState([]);
  const [colors, setColors] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const { data, loading } = useQuery(LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR);

  const [updateProduct] = useMutation(UPDATE_SERVICE_ORDER_PRODUCT, {
    onCompleted: () => {
      toast.success('Produto atualizado com sucesso');
      reset();
      navigationStep(1);
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar o produto';
      toast.error(`Error: ${errorMessage}`);
    },
  });

  useEffect(() => {
    if (data && currentProduct) {
      const { brand, model } = currentProduct;

      if (brand) {
        setSelectedBrand({
          id: brand.id,
          description: brand.description,
        });
      }

      if (model) {
        setSelectedModel({
          id: model.id,
          description: model.description,
        });
      }
    }
  }, [data, currentProduct]);

  useEffect(() => {
    if (data) {
      const uniqueBrands = Array.from(new Set(data.listProduct.map((p) => p.brandId))).map((id) => {
        const brand = data.listProduct.find((p) => p.brandId === id)?.brand;
        return { id, description: brand?.description || 'Unknown' };
      });
      setBrands(uniqueBrands);
    }
  }, [data]);

  useEffect(() => {
    if (selectedModel) {
      const filteredStorages = Array.from(
        new Set(
          data.listProduct.filter((p) => p.modelId === selectedModel.id).map((p) => p.storageId),
        ),
      ).map((id) => {
        const storage = data.listProduct.find((p) => p.storageId === id)?.storage;
        return { id, description: storage?.description || 'Unknown' };
      });
      setStorages(filteredStorages);
      setSelectedStorage(null);
      setColors([]);
      setSelectedColor(null);
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedStorage) {
      const filteredColors = Array.from(
        new Set(
          data.listProduct
            .filter(
              (p) =>
                p.brandId === selectedBrand.id &&
                p.modelId === selectedModel.id &&
                p.storageId === selectedStorage.id,
            )
            .map((p) => p.colorId),
        ),
      ).map((id) => {
        const color = data.listProduct.find((p) => p.colorId === id)?.color;
        return { id, description: color?.description || 'Unknown' };
      });
      setColors(filteredColors);
      setSelectedColor(null);
    }
  }, [selectedStorage, selectedBrand, selectedModel]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await updateProduct({
        variables: {
          data: { ...values, sinister: sinister },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Typography variant='h5'>Valide as informações do produto</Typography>

      <Spacer size={32} />

      <Formik
        initialValues={{
          brandId: selectedBrand?.id ?? '',
          modelId: selectedModel?.id ?? '',
          storageId: selectedStorage?.id ?? '',
          colorId: selectedColor?.id ?? '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={3}>
                <Field
                  name='brandId'
                  component={Autocomplete}
                  options={brands}
                  getOptionLabel={(option) => option.description}
                  value={selectedBrand || null}
                  onChange={(event, newValue) => {
                    setSelectedBrand(newValue);
                    props.setFieldValue('brandId', newValue?.id || '');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Marca' variant='outlined' />
                  )}
                  disabled
                />
              </Grid>

              <Grid item xs={12} xl={3}>
                <Field
                  name='modelId'
                  component={Autocomplete}
                  options={models}
                  getOptionLabel={(option) => option.description}
                  value={selectedModel || null}
                  onChange={(event, newValue) => {
                    setSelectedModel(newValue);
                    props.setFieldValue('modelId', newValue?.id || '');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Modelo' variant='outlined' />
                  )}
                  disabled
                />
              </Grid>

              <Grid item xs={12} xl={3}>
                <Field
                  name='storageId'
                  component={Autocomplete}
                  options={storages}
                  getOptionLabel={(option) => option.description}
                  value={selectedStorage}
                  onChange={(event, newValue) => {
                    setSelectedStorage(newValue);
                    props.setFieldValue('storageId', newValue?.id || '');
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label='Armazenamento' variant='outlined' />
                  )}
                  disabled={!selectedModel}
                />
              </Grid>

              <Grid item xs={12} xl={3}>
                <Field
                  name='colorId'
                  component={Autocomplete}
                  options={colors}
                  getOptionLabel={(option) => option.description}
                  value={selectedColor}
                  onChange={(event, newValue) => {
                    setSelectedColor(newValue);
                    props.setFieldValue('colorId', newValue?.id || '');
                  }}
                  renderInput={(params) => <TextField {...params} label='Cor' variant='outlined' />}
                  disabled={!selectedStorage}
                />
              </Grid>

              <Grid item xs={12}>
                <Button
                  fullWidth
                  type='submit'
                  color='secondary'
                  disabled={!props.isValid || props.isSubmitting}
                >
                  Salvar Alterações e Continuar
                </Button>
              </Grid>

              <Grid item xs={12} xl={6}></Grid>

              <Grid item xs={12} xl={6}>
                <Button
                  fullWidth
                  color='primary'
                  variant='outlined'
                  disabled={props.isSubmitting}
                  onClick={() => navigationStep(1)}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
