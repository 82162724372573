import React, { createContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { warehouseService } from 'services';

import { RequestFormDialog } from './components/RequestFormDialog';
import { RequestTables } from './components/RequestTables';

export const WarehouseRequestsContext = createContext();

export function WarehouseRequest() {
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [isOpenNewRequestDialog, setIsOpenNewRequestDialog] = useState(false);

  const [requests, , loadingRequests, refetchRequests] = useQuery(
    () => warehouseService.listRequests(page, rowsPerPage, search, status, false),
    [search, page, status],
  );

  const [requestStatus, , loadingRequestStatus] = useQuery(
    () => warehouseService.listRequestStatus(),
    [],
  );

  const requestStatusOptions = (requestStatus || []).map((item) => {
    return { label: item.description, value: item.id };
  });

  requestStatusOptions.push({ label: 'Todos os status', value: 0 });

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleSearchChange(event) {
    const value = event.target.value;
    setSearch(value.trim());
  }

  function handleCloseNewRequestDialog() {
    setIsOpenNewRequestDialog(false);
  }

  function handleOpenNewRequestDialog() {
    setIsOpenNewRequestDialog(true);
  }

  return (
    <>
      <PageTitle>Pedidos Warehouse</PageTitle>

      <Grid container spacing={2}>
        <Grid item xs={12} md={3} lg={3}>
          <Button
            fullWidth
            startIcon={<AddIcon />}
            variant='contained'
            onClick={handleOpenNewRequestDialog}
          >
            Novo pedido
          </Button>
        </Grid>
      </Grid>

      <Spacer size={64} />
      <Formik initialValues={{ search: '', status: status }}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                name='search'
                label='Pesquisar'
                size='small'
                variant='outlined'
                fullWidth
                endAdornment={<SearchIcon />}
                onChange={handleSearchChange}
                component={Text}
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Field
                name='status'
                label='Status'
                size='small'
                variant='outlined'
                fullWidth
                options={requestStatusOptions}
                loading={loadingRequestStatus}
                onChange={setStatus}
                component={Select}
              />
            </Grid>
          </Grid>
        </Form>
      </Formik>

      <WarehouseRequestsContext.Provider
        value={{ requests, loadingRequests, handleChangePage, page, refetchRequests }}
      >
        <Spacer size={24} />
        <RequestTables />

        <RequestFormDialog
          isOpen={isOpenNewRequestDialog}
          handleClose={handleCloseNewRequestDialog}
        />
      </WarehouseRequestsContext.Provider>
    </>
  );
}
