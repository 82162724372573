import React, { useEffect } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { FormatMoney, Spacer, Table } from 'components';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { budgetService, invoiceService } from 'services';
import { technicianService } from 'services/technician';

import { useStyles } from './styles';

const columns = [
  { label: '#', width: 48, align: 'center' },
  {
    label: 'Descrição',
  },
  {
    label: 'Preço',
  },
];

const columnsPn = [
  {
    label: 'Part Numbers',
  },
  {
    label: 'Quantidade',
  },
  {
    label: 'Descrição',
  },
  {
    label: 'Preço de Venda',
  },
  {
    label: 'Cortesia',
  },
];

export const TableAdditionalServices = ({ budget }) => {
  const classes = useStyles();

  console.log('vbataa', budget);

  const [additionalServices, , loadingAdditionalServices] = useQuery(
    () => technicianService.listAdditionalServices(),
    [],
  );

  const [includedInvoiceData, , loadingIncludedInvoiceData] = useQuery(
    () => invoiceService.listIncludedInvoiceByServiceNumber(budget?.service_order?.number),
    [],
  );

  const courtesyIncluded = String(budget?.courtesy_part_numbers)?.split(',');

  const [budgetSalesComponents, , loadingBudgetSalesComponents] = useQuery(
    () => budgetService.listBudgetSalesComponentsByBudgetId(budget?.id),
    [],
  );

  // const [salesQuantity, , loadingsalesQuantity] = useQuery(
  //   () => budgetService.listPartsQuantity(budget?.id),
  //   [],
  // );

  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    const discountmaster =
      ((budget?.components_total_price + values.additional_services_total_price) *
        values.discount) /
      100;

    const totalPriceBudget =
      budget?.components_total_price + values.additional_services_total_price - discountmaster;
    const additionalServicesTotalPrice = additionalServices
      ?.filter((item) => values.additional_services_id.includes(item.id))
      .reduce((acc, item) => (acc += item.service_price), 0);
    setFieldValue('additional_services_total_price', additionalServicesTotalPrice);
    // setFieldValue('installment_price', totalPriceBudget / values.installments);
    setFieldValue('final_budget_price', parseFloat(totalPriceBudget.toFixed(2)));
  }, [
    setFieldValue,
    additionalServices,
    values.additional_services_id,
    values.installment,
    values.components_total_price,
    values.min_value_components,
    values.additional_services_total_price,
    values.installments,
    values.installments2,
    values.installments3,
    values.final_budget_price,
    values.discount,
    budget,
  ]);

  const handleCheckboxClick = (itemId) => {
    const { additional_services_id } = values;
    const isSelected = additional_services_id.includes(itemId);

    if (isSelected) {
      const filteredAdditionalServicesId = additional_services_id.filter((id) => id !== itemId);

      return setFieldValue('additional_services_id', filteredAdditionalServicesId);
    }

    setFieldValue('additional_services_id', [...additional_services_id, itemId]);
  };

  const isCheckboxChecked = (additionalServiceId) =>
    values.additional_services_id.includes(additionalServiceId);

  return (
    <>
      <Table
        headers={columns}
        loading={loadingAdditionalServices}
        maxHeight={300}
        emptyMessage='Nenhum registro encontrado.'
        striped
        disableNumeration
      >
        {additionalServices?.map((additionalService) => (
          <TableRow key={additionalService.id}>
            <TableCell padding='checkbox' size='small'>
              <Checkbox
                onChange={() => handleCheckboxClick(additionalService.id)}
                color='primary'
                checked={isCheckboxChecked(additionalService.id)}
                disabled={
                  budget?.negociation_price === null || budget?.negociation_price === undefined
                    ? false
                    : true
                }
              />
            </TableCell>
            <TableCell>{additionalService.description}</TableCell>
            <TableCell>
              <FormatMoney>{additionalService.service_price}</FormatMoney>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <Spacer size={24} />
      <Typography variant='h6'>Componentes</Typography>
      <Spacer size={24} />

      <Table
        headers={columnsPn}
        loading={loadingBudgetSalesComponents}
        maxHeight={300}
        emptyMessage='Nenhum registro encontrado.'
        striped
      >
        {budgetSalesComponents?.map((part_number) => (
          <TableRow key={part_number?.id}>
            <TableCell>{part_number?.sales_component?.part_number}</TableCell>
            <TableCell>{part_number?.amount ?? '-'}</TableCell>
            <TableCell>{part_number?.sales_component?.description}</TableCell>
            <TableCell>
              <FormatMoney>{part_number?.sell_price}</FormatMoney>
            </TableCell>
            <TableCell>
              {courtesyIncluded?.includes(part_number?.sales_component?.part_number)
                ? 'Sim'
                : 'Não'}
            </TableCell>
          </TableRow>
        ))}
      </Table>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6}>
          <Typography className={classes.toInitial}>
            Valor de Taxa de Análise:
            {includedInvoiceData ? (
              <FormatMoney>{includedInvoiceData?.price}</FormatMoney>
            ) : (
              'NÃO CONSTA'
            )}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Typography className={classes.totalPrice} style={{ color: 'red' }}>
            Preço minimo para componentes :{' '}
            <FormatMoney>{budget?.min_components_value}</FormatMoney>
          </Typography>
          <Typography className={classes.totalPrice}>
            Total em componentes : <FormatMoney>{budget?.components_total_price}</FormatMoney>
          </Typography>
          <Typography className={classes.totalPrice}>
            Total em serviços : <FormatMoney>{values?.additional_services_total_price}</FormatMoney>
          </Typography>
          <Typography className={classes.totalPrice}>
            Total a cobrar : <FormatMoney>{values.final_budget_price}</FormatMoney>
          </Typography>
          <Typography className={classes.totalPrice} style={{ color: 'blue' }}>
            Preço de negociação : <FormatMoney>{budget?.negociation_price}</FormatMoney>
          </Typography>
        </Grid>
      </Grid>

      {/* {console.log('totalPriceBudget: ', values.final_budget_price)} */}
    </>
  );
};
