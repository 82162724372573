import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    gridPaper: {
      padding: '10px',
      marginTop: '20px',
      marginBottom: '10px',
    },
    contextPaper: {
      position: 'relative',
    },
    gridImg: {
      display: 'flex',
      margin: 'auto',
    },
    imgTracker: {
      height: '200px',
    },
  };
});
