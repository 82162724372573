import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Formik } from 'formik';
import { componentService } from 'services';
import * as yup from 'yup';

export const OsPartsRemoveFromOsDialog = ({
  open,
  handleClose,
  service,
  stockRequisition,
  reloadBudgets,
}) => {
  if (!open) return null;

  console.log('handleSubmit', stockRequisition);

  const validationSchema = yup.object().shape({
    confirmation: yup.string().typeError('Digite DELETAR').required('Obrigatório').min(7).max(7),
  });

  const initialValues = {
    confirmation: '',
  };
  const [confirmations, setConfirm] = useState({});

  console.log(confirmations);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setConfirm(values.confirmation);
    console.log('entrei');
    try {
      await componentService.partDelete(
        stockRequisition.components_id,
        stockRequisition.service_order_id,
      );
      toast.success('Requisição de componentes e estoque atualizado com sucesso.');
      resetForm();
      handleClose();
      window.location.reload();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Erro ao remover o componente da requisição da OS.',
      );
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };
  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, dirty, isValid }) => (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
          <Dialog.Title onClose={handleClose}>
            Deseja remover a requisição da peça: {stockRequisition?.part_number}
          </Dialog.Title>
          <Dialog.Content>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>Confirme abaixo digitando DELETAR :</Typography>
                <Field
                  size='small'
                  variant='outlined'
                  name='confirmation'
                  label='Confirmação'
                  fullWidth
                  component={Text}
                  inputProps={{ maxLength: 7 }}
                  onInput={toInputUppercase}
                />
              </Grid>
            </Grid>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={handleClose} variant='text' color='default'>
              Fechar
            </Button>
            <Button
              color='secondary'
              onClick={handleSubmit}
              type='submit'
              disabled={!dirty || !isValid}
            >
              Confirmar
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  );
};
