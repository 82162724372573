import React from 'react';

import { Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { TableTabs } from './TableTabs';

export const BudgetKit = () => {
  return (
    <>
      <PageTitle>Orçamento de Kit</PageTitle>
      <Formik initialValues={{}}>
        <TableTabs />
      </Formik>
    </>
  );
};
