import React from 'react';

import TabelaGeneral from './tableLog';

export const OsLog = () => {
  return (
    <div>
      <TabelaGeneral />
    </div>
  );
};
