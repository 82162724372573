import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { TableWainting } from './Table';

export const WaitingWithdrawal = () => {
  return (
    <>
      <PageTitle>Aguardando Coleta VAREJO</PageTitle>
      <TableWainting />
    </>
  );
};
