import React, { Fragment, useContext, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { FormatDate } from 'components';
import { SimpleTable } from 'components/SimpleTable';

import { WarehouseRequestsContext } from '..';

import { SubTitle } from 'pages/Layout/SubTitle';

import { RequestDetails } from './RequestDetails';

const columns = [
  { label: 'ID', align: 'center' },
  { label: 'Modelo', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'CPF', align: 'center' },
  { label: 'Status', align: 'center' },
  { label: 'Criado em', align: 'center' },
  { label: 'Atualizado em', align: 'center' },
  { label: 'Ver mais', align: 'center' },
];

export function RequestTables() {
  const { requests, loadingRequests, handleChangePage, page } =
    useContext(WarehouseRequestsContext);

  const [isOpenRequestDetailsDialog, setIsOpenRequestDetailsDialog] = useState(false);
  const [request, setRequest] = useState();

  function handleCloseRequestDetailsDialog() {
    setIsOpenRequestDetailsDialog(false);
  }

  function handleOpenRequestDetailsDialog(data) {
    setRequest(data);
    setIsOpenRequestDetailsDialog(true);
  }
  return (
    <>
      <SubTitle>Meus pedidos</SubTitle>
      <SimpleTable
        headers={columns}
        totalCount={requests?.totalCount ?? 1}
        isLoading={loadingRequests}
        handleChangePage={handleChangePage}
        page={page}
      >
        {requests?.request?.map((row, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell align='center'>{row?.id}</TableCell>
              <TableCell align='center'>{row?.model}</TableCell>
              <TableCell align='center'>{row?.client?.name}</TableCell>
              <TableCell align='center'>{row?.client?.cgc}</TableCell>
              <TableCell
                align='center'
                style={{
                  fontWeight: 'bold',
                  color:
                    row?.status_id === 1
                      ? 'yellow'
                      : row?.status_id === 2
                      ? 'green'
                      : row.status_id === 3
                      ? 'red'
                      : 'blue',
                }}
              >
                {row?.status?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.created_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.updated_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <IconButton onClick={() => handleOpenRequestDetailsDialog(row)}>
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </SimpleTable>
      <RequestDetails
        request={request}
        handleClose={handleCloseRequestDetailsDialog}
        isOpen={isOpenRequestDetailsDialog}
      />
    </>
  );
}
