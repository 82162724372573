import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  toInitial: {
    textAlign: 'initial',
  },
  totalPrice: {
    textAlign: 'end',
  },
}));
