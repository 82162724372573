import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as Trash } from 'assets/images/updateService.svg';
import { Button, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const UpdateServiceGSPN = () => {
  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log(values);
      await serviceOrderService.updateServiceGspn(values);

      toast.success('Ordem de serviço atualizada com sucesso.');

      resetForm();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Atualização de service manual GSPN</PageTitle>
      <Formik
        initialValues={{ service_order_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    name='service_order_number'
                    size='small'
                    label='Service'
                    inputProps={{ maxLength: 10, minlength: 1 }}
                    component={Text}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Grid className={classes.containerImg} container>
        <Trash className={classes.trashImage} />
      </Grid>
    </>
  );
};
