import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  object_type_id: yup.number().required(),

  code: yup.string().required(),

  // search_website_value: yup.number().when('object_type_id', {
  //   is: 3,
  //   then: yup.number().required('Field is required'),
  //   otherwise: yup.number(),
  // }),

  // .test('validateCode', 'Formato do código inválido.', (value) => {
  //   return value ? !!value.match(/^[A-Z]{2}[1-9]{9}[A-Z]{2}$/) : true;
  // }),
  receipt_date: yup.date().required(),
  resend: yup.boolean().required(),
});

export const initialValues = {
  object_type_id: 1,
  insurance_company_id: 0,
  search_website_value: 0,
  code: '',
  receipt_date: new Date(),
  resend: false,
  warranty_type: '',
};
