import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

import { Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { budgetService, providerService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { Actions } from './Actions';
import { ConfirmBudgetTec } from './StepFourConfirmBudgetTec';
import { StepIconComponent } from './StepIcon';
import { CartItems } from './StepOneComponents';
import { validationSchema } from './StepThreeClientInfo/validation';
import { AdditionalServices } from './StepTwoAdditionalServices';

import { BudgetCartContext } from '..';

import { cleanCartAction } from './store/actions';

const steps = [
  {
    label: 'Carrinho de Componentes',
  },
  {
    label: 'Serviços Adicionais',
  },
  {
    label: 'Confirmar',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const ClientContext = createContext();

export const Cart = ({ open, onClose, selectedComponent = {} }) => {
  const [activeStep, setActiveStep] = useState(0);

  const user = useContextSelector(AuthContext, (context) => context.user);

  const {
    state,
    totalCart,
    dispatch,
    stateService,
    totalCartService,
    dispatchService,
    serviceInHome,
  } = useContext(BudgetCartContext);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const {
        client_id,
        total_items,
        description,
        service_number,
        payment_method,
        installments,
        installment_price,
        payment_method2,
        installments2,
        installment_price2,
        payment_method3,
        installments3,
        installment_price3,
        reason_refuse,
        approved,
      } = values;

      const salesComponents = state.map(({ id, amount }) => {
        return { amount, component_id: id };
      });

      const additionalServices = stateService.map(({ id, amount }) => {
        return { amount, additionalService_id: id };
      });

      const preBudgetTec = await budgetService.createPreBudgetTec({
        client_id,
        salesComponents,
        additionalServices,
        total_items,
        description,
        service_number,
        payment_method,
        installments,
        installment_price,
        payment_method2,
        installments2,
        installment_price2,
        payment_method3,
        installments3,
        installment_price3,
        reason_refuse,
        approved,
      });

      if (approved === true) {
        await providerService.createETicket({
          service_number,
          serviceCode: '03247',
          card: '0077288440',
        });
        toast.success('E-Ticket gerado com sucesso.');
      }

      cleanCartAction(dispatch);
      cleanCartAction(dispatchService);

      resetForm();
      setActiveStep(0);
      onClose();
      toast.success('Pré Orçamento Técnico realizado com sucesso');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao criar o pré orçamento técnico.');
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = useCallback((activeStep, formProps) => {
    const steps = {
      0: <CartItems FormProps={formProps} />,
      1: <AdditionalServices FormProps={formProps} />,
      2: <ConfirmBudgetTec FormProps={formProps} />,
    };

    return steps[activeStep];
  }, []);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xl'>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Formik
          initialValues={{
            cgc: serviceInHome?.state?.serviceInHome?.cgc ?? '',
            name: serviceInHome?.state?.serviceInHome?.name ?? '',
            zipcode: serviceInHome?.state?.serviceInHome?.zipcode ?? '',
            city: serviceInHome?.state?.serviceInHome?.city ?? '',
            state: serviceInHome?.state?.serviceInHome?.state ?? '',
            district: serviceInHome?.state?.serviceInHome?.district ?? '',
            street: serviceInHome?.state?.serviceInHome?.street ?? '',
            street_number: serviceInHome?.state?.serviceInHome?.street_number ?? '',
            complement: serviceInHome?.state?.serviceInHome?.complement ?? '',
            residential_phone_number:
              serviceInHome?.state?.serviceInHome?.residential_phone_number ?? '',
            phone_number: serviceInHome?.state?.serviceInHome?.phone_number ?? '',
            description: '',
            tec_name: user?.name,
            total_items: 0,
            total_items_tec: 0,
            payment_type: 1,
            payment_method1: 1,
            payment_method2: null,
            payment_method3: null,
            installments: 1,
            installments2: 1,
            installments3: 1,
            installment_price: 0,
            installment_price2: 0,
            installment_price3: 0,
            total_price_card1: 0,
            total_price_card2: 0,
            total_price_card3: 0,
            discount: 0,
            reason_refuse: '',
            service_number: serviceInHome?.state?.serviceInHome?.number,
            client_id: serviceInHome?.state?.serviceInHome?.client_id,
            approved: true,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              {getStepContent(activeStep, props)}
              <Actions
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsSize={steps.length}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
