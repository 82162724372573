import { api } from 'services/api';

export class InvoiceService {
  async createInvoice(data) {
    return api.post('/invoices', data);
  }

  async downloadComponentsTags(invoiceId) {
    return api.get(`/invoices/${invoiceId}/components-tags`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadComponentsTagsByInvoiceNumber(invoice_number) {
    return api.get(`/invoices/invoice/${invoice_number}/components-tags`, {
      responseType: 'arraybuffer',
    });
  }

  async listBillingType() {
    return api.get(`/invoices/billing/type`);
  }

  async downloadCashierSalesTag(service_order_number) {
    return api.get(`/invoices/${service_order_number}/impress-sales-document`, {
      responseType: 'arraybuffer',
    });
  }

  async listBillingTypeOnly() {
    return api.get(`/invoices/billing/type/only`);
  }

  async retailInboundOutbound(input_date, output_date) {
    return api.get(`/invoices/list/retailInboundOutboundReport/${input_date}/${output_date}`);
  }

  async listIncludedInvoices() {
    return api.get('/invoices/included-invoices');
  }

  async includedInvoiceValidation(service_number, isValidate) {
    return api.put(`/invoices/included-invoices-validation`, { service_number, isValidate });
  }

  async downloadDanfe(nfId) {
    return api.post(`/invoices/included-invoice/danfe/${nfId}`);
  }

  async downloadDanfeAnaliseNF(nfId) {
    return api.post(`/invoices/included-invoice/analiseNF/${nfId}`);
  }

  async listBillingExpirationDays() {
    return api.get('/invoices/billing/days/expiration');
  }

  async listIncludedInvoicesWithoutValidation() {
    return api.get(`/invoices/included-invoices-without`);
  }

  async listIncludedInvoicesValidated() {
    return api.get(`/invoices/included-invoices-validated`);
  }

  async listIncludedInvoicesRefused() {
    return api.get(`/invoices/included-invoices-refused`);
  }

  async listIncludedInvoiceByServiceNumber(number) {
    return api.get(`/invoices/included-invoices/${number}`);
  }

  async analysisIssueNote(type_of_analysis_id, service_number, issueNoteOrNot) {
    return api.post('/invoices/invoice/analisys/issue', {
      type_of_analysis_id,
      service_number,
      issueNoteOrNot,
    });
  }

  async downloadRepairNote(service_number) {
    return api.get(`/invoices/repair/${service_number}`, {
      responseType: 'arraybuffer',
    });
  }

  async DownloadOpeningTag(service_order_number) {
    return api.get(`/service-orders/${service_order_number}/download-service-tag`, {
      responseType: 'arraybuffer',
    });
  }

  async listConvertedXmls() {
    return api.get(`/invoices/convert/many/xml`);
  }

  async listtodoConference() {
    return api.get(`/invoices/invoicesrepair-todoconference`);
  }

  async listtodoOpening() {
    return api.get(`/invoices/invoicesrepair-todoopening`);
  }

  async truncateConvertedXmls() {
    return api.put(`/invoices/convert/many/xml`);
  }

  async listInvoicesRepairXmls(infnfe) {
    return api.post('/invoices/repair/xml/list', { infnfe });
  }

  async listBasicAccessories() {
    return api.get(`/invoices/basic-accessories`);
  }

  async listInvoicesRepairReflect(infnfe) {
    return api.post(`/invoices/repair/reflect`, { infnfe });
  }

  async createReflectInvoice(data) {
    return api.post(`/invoices/repair/finish`, data);
  }

  async updatedInvoiceRepairProductType(data) {
    return api.post(`/invoices/repair/product`, data);
  }

  async createConferenceProduct(conference_products, conference_products_missing) {
    return api.post('/invoices/conference/products', {
      conference_products,
      conference_products_missing,
    });
  }

  async createConferenceAccessories(
    conference_accessories,
    conference_accessories_missing,
    checkedPns,
  ) {
    return api.post('/invoices/conference/accessories', {
      conference_accessories,
      conference_accessories_missing,
      checkedPns,
    });
  }

  async downloadConferenceInvoiceTags(infnfe) {
    return api.get(`/invoices/conference/tags/${infnfe}`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadConferenceInvoiceTagsReprint(nnf) {
    return api.get(`/invoices/conference/reprint-tags/${nnf}`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadConferenceInvoicePackingList(infnfe, cubagem) {
    return api.get(`/invoices/conference/packing-list/${infnfe}/${cubagem}`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadConferenceInvoicePackingListDiversos(infnfe, cubagem) {
    return api.get(`/invoices/conference/packing-diversos/${infnfe}/${cubagem}`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadConferenceInvoicePackingListDevol(infnfe, cubagem) {
    return api.get(`/invoices/conference/packing-devol/${infnfe}/${cubagem}`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadConferenceInvoicePackingListAll(arrayNnfs) {
    console.log('Is it cuming: ', arrayNnfs);
    return api.post(`/invoices/conference/packing-list-all`, arrayNnfs, {
      responseType: 'arraybuffer',
    });
  }

  async conferenceConsult(infnfe) {
    return api.post(`/invoices/conference/consult`, { infnfe });
  }

  async packingListValidation(infnfe) {
    return api.post(`/invoices/packing-list-validation`, { infnfe });
  }

  async packingListValidationMagazine(infnfe) {
    return api.post(`/invoices/packing-list-validation-magazine`, { infnfe });
  }

  async packingListValidationMagazineDiversos(infnfe) {
    return api.post(`/invoices/packing-list-validation-magazine-diversos`, { infnfe });
  }

  async packingListValidationMagazineDevol(infnfe) {
    return api.post(`/invoices/packing-list-validation-magazine-devol`, { infnfe });
  }

  async conferenceConsultInf(infnfe) {
    return api.post(`/invoices/conference/consultinf`, { infnfe });
  }

  async getReportConference(infnfe, imei) {
    return api.post(`/invoices/conference/reportConference`, { infnfe });
  }

  async getReportConferenceForConsult(infnfe, imei) {
    return api.post(`/invoices/conference/reportConferenceForConsult`, { infnfe, imei });
  }

  async updateConference(values) {
    return api.post(`/invoices/updateConference`, { values });
  }
  async getReportConferenceAll(input_date, output_date) {
    return api.get(`/invoices/conference/reportConferenceAll/${input_date}/${output_date}`);
  }

  async getReportInsurance(input_date, output_date) {
    return api.post(`/invoices/conference/reportInsurance/${input_date}/${output_date}`);
  }

  async createStockByFile(data) {
    return api.post('/invoices/invoice/file', data);
  }

  async updateStockByFile(data) {
    return api.put('/invoices/components/file', data);
  }

  async findReceiveNFReport(input_date, output_date) {
    return api.get(`/invoices//repair/report/${input_date}/${output_date}`);
  }

  async getInvoiceByOsIdAndInvoiceTypeId(id, invoice_type_id) {
    return api.get(`/invoices/included-invoices/${id}/${invoice_type_id}`);
  }
}

export const invoiceService = new InvoiceService();
