import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Spacer } from 'components';

import { FunctionalScreeningContext } from '..';
export function Header() {
  const { data } = useContext(FunctionalScreeningContext);
  return (
    <>
      <Spacer size={64} />
      <Paper elevation={5}>
        <Grid container>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom color='secondary'>
              Voucher: {data?.sinister}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom>
              IMEI: {data?.imei}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom color='secondary'>
              Marca: {data?.sku?.brand?.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom>
              Modelo: {data?.sku?.model?.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom color='secondary'>
              Armazenamento: {data?.sku?.storage?.description}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant='h6' gutterBottom>
              Cor: {data?.sku?.color?.description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
