import { React, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Text, FormatDate, Table } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { mailObjectService } from 'services/mailObject';

import { useStyles } from './styles';

const columns = [
  { label: 'Objeto' },
  { label: 'Data de cadastro' },
  { label: 'Cadastrar Objeto', width: 60, align: 'center' },
  { label: 'Gerar Checkin', width: 60, align: 'center' },
];

export const OpeningPll = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [mailObjects, , loadingMailObjects, mailObjectschange] = useQuery(
    () => mailObjectService.listMailObjects(startIndex, rowsPerPage, search.trim()),
    [startIndex, rowsPerPage, search],
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPage(0);
    setStartIndex(1);
    setSearch(value);
  };

  const navigateToCheckinPll = (mail_object) => {
    browserHistory.push({
      pathname: '/conference/checkin-pll',
      state: { mail_object },
    });
  };

  const navigateToObjectPll = (mail_object) => {
    browserHistory.push({
      pathname: '/conference/register-object',
      state: { mail_object },
    });
  };

  return (
    <>
      <PageTitle>Pré abertura SEGURADORA</PageTitle>
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Text
              label='Pesquisar'
              size='small'
              variant='outlined'
              value={search}
              endAdornment={<SearchIcon />}
              onChange={handleSearchChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Table
              headers={columns}
              striped
              loading={loadingMailObjects}
              emptyMessage='Nenhum registro encontrado.'
              data={mailObjects?.data}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {mailObjects?.data?.map((object) => {
                const { photo_1, photo_2, photo_3 } = object;
                const allPhotosUploaded = photo_1 && photo_2 && photo_3;

                return (
                  <TableRow key={object.id}>
                    <TableCell>{object.code}</TableCell>
                    <TableCell>
                      <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object.receipt_date}</FormatDate>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        size='large'
                        align='center'
                        color='primary'
                        onClick={() => navigateToObjectPll(object)}
                        disabled={allPhotosUploaded}
                      >
                        Objeto
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        className={classes.button}
                        size='small'
                        align='center'
                        onClick={() => navigateToCheckinPll(object)}
                      >
                        Checkin
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50, 100, 200]}
              component='div'
              count={mailObjects?.totalRows}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage='Linhas por página:'
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count !== -1 ? count : ''}`
              }
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </>
    </>
  );
};
