import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Pagination } from '@mui/material';
import { FormatMoney } from 'components';

const columns = [
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'VALOR', align: 'center' },
];

export function InputProductTable({ data }) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = (data || []).slice(startIndex, startIndex + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align} style={{ fontWeight: 'bold' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(paginatedResults || []).map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell align='center'>{item.anymarketId}</TableCell>
                <TableCell align='center'>{item.sku}</TableCell>
                <TableCell align='center'>
                  <FormatMoney>{item.value}</FormatMoney>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='primary'
        />
      </Grid>
    </TableContainer>
  );
}
