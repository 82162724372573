import React, { useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useQuery } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import { Table } from 'components';
import { LIST_B2C_BOX_ITENS } from 'services/graphql/query/b2c';

const columns = [
  { label: 'Voucher', align: 'center' },
  { label: 'Remover', align: 'center' },
];

export function BoxItemTable({ boxId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const { data, loading, refetch } = useQuery(LIST_B2C_BOX_ITENS, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      boxId,
    },
    skip: !boxId,
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={loading}
        disableNumeration
      >
        {(data?.listB2CBoxsItens || []).map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell align='center'>{item?.b2CPicking?.sinister}</TableCell>
              <TableCell align='center'>
                <IconButton onClick={() => handleRemoveItem(item.id)} color='secondary'>
                  <CancelIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={data?.listB2CBoxsItens?.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
