import React, { useContext } from 'react';
import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Text } from 'components/Form/Text';
import { FormatMoney } from 'components/FormatMoney';
import { Table } from 'components/Table';

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};
import { CartContext } from '../../index';
import {
  removeFromCartAction,
  updateAmountAccessoryAction,
  removeLaborFromCartAction,
  updateAmountLaborAction,
  updateAmountAccessoryWholesaleAction,
  removeFromCarWholesaletAction,
} from '../store/actions';
import { useStyles } from './styles';

export const CartWithItems = () => {
  const columns = [
    { label: 'PART-NUMBER', align: 'center' },
    { label: 'DESCRIÇÃO', align: 'center' },
    { label: 'PREÇO(UN)', align: 'center' },
    { label: 'QUANTIDADE', align: 'center' },
    { label: 'SUBTOTAL', align: 'center' },
    { label: '', align: 'center' },
  ];

  const columnsLabor = [
    { label: 'DESCRIÇÃO', align: 'center' },
    { label: 'DESCRIÇÃO DO PRODUTO', align: 'center' },
    { label: 'PREÇO(UN)', align: 'center' },
    { label: 'QUANTIDADE', align: 'center' },
    { label: 'SUBTOTAL', align: 'center' },
    { label: '', align: 'center' },
  ];

  const classes = useStyles();

  const { serviceTypeSelect, state, dispatch, totalCart } = useContext(CartContext);

  return (
    <>
      <Grid
        container
        alignContent='center'
        className={classes.gridContainer}
        justifyContent='center'
      >
        <Grid item xs={12}>
          <Table
            maxHeight={400}
            emptyMessage='Nenhum acessório no carrinho.'
            striped
            disableNumeration
            headers={serviceTypeSelect == 1 ? columns : columnsLabor}
          >
            {serviceTypeSelect == 1 ? (
              state.map((accessory) => (
                <TableRow key={accessory?.id}>
                  <TableCell align='center' className={classes.tableCell}>
                    {accessory?.part_number}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    {accessory?.description}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <FormatMoney>{accessory?.component_acessory_price}</FormatMoney>
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountAccessoryAction(
                              { amount: accessory.amount - 1, accessory_id: accessory.id },
                              dispatch,
                            )
                          }
                        >
                          <FaMinusCircle className={classes.minusIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={4}>
                        <Text
                          number='number'
                          variant='outlined'
                          value={accessory.amount}
                          onChange={(e) =>
                            updateAmountAccessoryAction(
                              {
                                amount: e.target.value == 0 ? 1 : Number(e.target.value),
                                accessory_id: accessory.id,
                              },
                              dispatch,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountAccessoryAction(
                              { amount: accessory.amount + 1, accessory_id: accessory.id },
                              dispatch,
                            )
                          }
                        >
                          <FaPlusCircle className={classes.plusIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.subTotalTableCell}>
                    <FormatMoney>{accessory?.sub_total}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.subTotalTableCell}>
                    <IconButton onClick={() => removeFromCartAction(accessory, dispatch)}>
                      <FaTrash size='18' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}

            {serviceTypeSelect == 2 ? (
              state.map((labor) => (
                <TableRow key={labor?.id}>
                  <TableCell align='center' className={classes.tableCell}>
                    {labor?.description}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    {labor?.product?.description}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <FormatMoney>{labor?.labor_price}</FormatMoney>
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountLaborAction(
                              { amount: labor.amount - 1, labor_id: labor.id },
                              dispatch,
                            )
                          }
                        >
                          <FaMinusCircle className={classes.minusIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={4}>
                        <Text
                          number='number'
                          variant='outlined'
                          value={labor.amount}
                          onChange={(e) =>
                            updateAmountLaborAction(
                              {
                                amount: e.target.value == 0 ? 1 : Number(e.target.value),
                                labor_id: labor.id,
                              },
                              dispatch,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountLaborAction(
                              { amount: labor.amount + 1, labor_id: labor.id },
                              dispatch,
                            )
                          }
                        >
                          <FaPlusCircle className={classes.plusIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.subTotalTableCell}>
                    <FormatMoney>{labor?.sub_total}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.subTotalTableCell}>
                    <IconButton onClick={() => removeLaborFromCartAction(labor, dispatch)}>
                      <FaTrash size='18' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}

            {serviceTypeSelect == 3 ? (
              state.map((accessory) => (
                <TableRow key={accessory?.id}>
                  <TableCell align='center' className={classes.tableCell}>
                    {accessory?.part_number}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    {accessory?.description}
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <FormatMoney>{accessory?.component_acessory_price_wholesale}</FormatMoney>
                  </TableCell>
                  <TableCell align='center' className={classes.tableCell}>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountAccessoryWholesaleAction(
                              { amount: accessory.amount - 1, accessory_id: accessory.id },
                              dispatch,
                            )
                          }
                        >
                          <FaMinusCircle className={classes.minusIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item xs={4}>
                        <Text
                          number='number'
                          variant='outlined'
                          value={accessory.amount}
                          onChange={(e) =>
                            updateAmountAccessoryWholesaleAction(
                              {
                                amount: e.target.value == 0 ? 1 : Number(e.target.value),
                                accessory_id: accessory.id,
                              },
                              dispatch,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountAccessoryWholesaleAction(
                              { amount: accessory.amount + 1, accessory_id: accessory.id },
                              dispatch,
                            )
                          }
                        >
                          <FaPlusCircle className={classes.plusIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.subTotalTableCell}>
                    <FormatMoney>{accessory?.sub_total}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.subTotalTableCell}>
                    <IconButton onClick={() => removeFromCarWholesaletAction(accessory, dispatch)}>
                      <FaTrash size='18' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </Table>
        </Grid>
        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.total}>TOTAL:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalCart}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
