import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Text, Radio, Select, FormatDate, Table } from 'components';
import { Button } from 'components/Button';
import { Clipboard } from 'components/Clipboard';
import { Dialog } from 'components/Dialog';
import { Spacer } from 'components/Spacer';
import { Field, Formik, useFormikContext, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services';
import { serviceOrderService } from 'services';
import { budgetService } from 'services/budget';
import { mailObjectService } from 'services/mailObject';

import { AttachVoucher } from './AttachVoucher';
import { TableAdditionalServices } from './TableAdditionalServices';
import { validationSchema } from './validationschema';

const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      marginBottom: '10px',
    },
    gridContainerBottom: {
      marginBottom: '25px',
    },
    toTheEnd: {
      textAlign: 'end',
    },
    toTheinitial: {
      textAlign: 'initial',
    },
    toTheCenter: {
      textAlign: 'center',
    },
  };
});

export const ServiceAdditional = () => {
  const classes = useStyles();
  const [statuses, , loadingStatuses] = useQuery(() => budgetService.listStatuses(), []);
  const [paymentStatuses, , loadingPaymentStatuses] = useQuery(
    () => budgetService.listBudgetPaymentStatuses(),
    [],
  );
  const [isTradeIN, setTradeIn] = useState(false);

  const [information, setInformationState] = useBoolean();
  const [budget, setBudget] = useState([]);
  const [sinister, setSinister] = useState([]);

  const [isDialogSuccessOpen, setIsDialogSuccessOpen] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');
  const [paymentUrl2, setPaymentUrl2] = useState('');
  const [paymentUrl3, setPaymentUrl3] = useState('');
  const [importing, setImporting] = useState(false);
  const {
    state: { budget_id, service_order },
  } = useLocation();

  const handleCloseInformation = () => {
    setInformationState.toFalse();
  };

  const handleOpenInformation = () => {
    setInformationState.toTrue();
  };

  const isTrade = [
    {
      value: 'NAO',
      label: 'NAO',
    },
    {
      value: 'SIM',
      label: 'SIM',
    },
  ];

  const discountOptions = [
    {
      value: 0,
      label: 'Sem desconto',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 15,
      label: '15%',
    },
  ];

  const installmentOptions = [
    {
      value: 1,
      label: '1x sem juros',
    },
    {
      value: 2,
      label: '2x sem juros',
    },
    {
      value: 3,
      label: '3x sem juros',
    },
    {
      value: 4,
      label: '4x sem juros',
    },
    {
      value: 5,
      label: '5x sem juros',
    },
    {
      value: 6,
      label: '6x sem juros',
    },
    // {
    //   value: 7,
    //   label: '7x sem juros',
    // },
    // {
    //   value: 8,
    //   label: '8x sem juros',
    // },
    // {
    //   value: 9,
    //   label: '9x sem juros',
    // },
    // {
    //   value: 10,
    //   label: '10x sem juros',
    // },
    // {
    //   value: 11,
    //   label: '11x sem juros',
    // },
    // {
    //   value: 12,
    //   label: '12x sem juros',
    // },
  ];
  const [remarkData, setRemarkData] = useState();
  const columns = [{ label: 'Anotação' }, { label: 'Adicionado por' }, { label: 'Adicionado em' }];

  const FieldMethodPayment1 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method1', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 1
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method1'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const FieldMethodPayment2 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method2', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 2
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method2'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const FieldMethodPayment3 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method3', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 3
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method3'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  // const paymentStatusesOptions = (paymentStatuses || []).map((status) => ({
  //   value: status.id,
  //   label: status.description,
  // }));

  const statusesOptions = (statuses || []).map((status) => ({
    value: status.id,
    label: status.description,
  }));

  const { state } = useLocation();

  const [budgetData, , loadingBudgetData, refetchBudgetData] = useQuery(
    () => budgetService.getBudgetById(state?.budget_id),
    [],
  );

  const [RefuseData, , loadingRefuseData] = useQuery(
    () => budgetService.listRefuseMotivation(),
    [],
  );

  const refuseOptions = (RefuseData || []).map((refuse) => ({
    value: refuse.id,
    label: refuse.motivation,
  }));

  const navigateTo = () => {
    browserHistory.push({
      pathname: '/sac/budget-kit',
    });
  };

  const handleDialogSuccessClose = () => {
    setIsDialogSuccessOpen(false);
  };

  useEffect(() => {
    setBudget(budgetData);
  }, [budgetData]);

  const [objectData, , loadingobjectData] = useQuery(
    () => mailObjectService(budgetData?.service_order?.object_in),
    [],
  );

  useEffect(() => {
    setSinister(objectData);
  }, [objectData]);

  console.log('sinister', sinister);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    setImporting(true);
    try {
      const { data } = await budgetService.createFinalBudget({
        id: budget_id,
        ...values,
      });

      toast.success('Orçamento criado com sucesso');
      setImporting(false);

      // navigateToSAC();

      console.log('Data: ', data);

      console.log('Payment URL2: ', paymentUrl2);

      resetForm();
      setPaymentUrl(data.payment_url);
      setPaymentUrl2(data.payment_url2);
      setPaymentUrl3(data.payment_url3);

      if (values.status_id === 9) {
        setIsDialogSuccessOpen(false);
        navigateTo();
      } else {
        setIsDialogSuccessOpen(true);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
      setImporting(false);
    }
  };

  const FormAdditional = ({ formikProps }) => {
    const { values, setFieldValue } = useFormikContext();

    let gettingRest = 0;

    if (gettingRest === budgetData?.negociation_price ?? values.final_budget_price) {
      gettingRest = 0;
    } else {
      gettingRest = Number(
        budgetData?.negociation_price !== null
          ? budgetData?.negociation_price - values.installment_price * values.installments
          : values.final_budget_price - values.installment_price * values.installments,
      ).toFixed(2);
    }
    const [remarkData, setRemarkData] = useState();

    const [invoice, setInvoiceResult] = useState();

    const [colors, , loadingColors] = useQuery(() => serviceOrderService.listColorsProduct(), []);

    const colorsOptions = (colors || []).map((colors) => ({
      value: colors.description,
      label: colors.description,
    }));

    const functionGetRemarks = async () => {
      const { data } = await api.get(
        `/service-orders/serviceRemark/${budgetData?.service_order?.id}`,
      );
      console.log(data);
      setRemarkData(data);
    };

    // const functionInvoice = async () => {
    //   const { infnfe } = invoiceService.conferenceConsult(budgetData?.service_order?.number);

    //   setInvoiceResult(infnfe);
    // };

    useEffect(() => {
      functionGetRemarks();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [budgetData?.service_order]);

    // useEffect(() => {
    //   functionInvoice();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [budgetData?.service_order]);

    // console.log('invoice', invoice);

    const remarkCreate = async (description, services) => {
      await serviceOrderService.createServiceRemark(description, services).then((item) => {
        console.log('Item: ', item);
        toast.success('Registro realizado com sucesso.');
      });
      functionGetRemarks().catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
    };

    return (
      <Form>
        <Dialog open={isDialogSuccessOpen} onClose={navigateTo} maxWidth='xs'>
          <Dialog.Title onClose={handleDialogSuccessClose}>
            Orçamento criado com sucesso
          </Dialog.Title>
          <Dialog.Content>
            <Typography>Link gerado pela plataforma de pagamento:</Typography>
            <Spacer axis='vertical' size={8} />
            <Clipboard value={paymentUrl} />
            {paymentUrl2 !== '' || paymentUrl2 !== null ? (
              <>
                <Clipboard value={paymentUrl2} /> <Spacer axis='vertical' size={0} />{' '}
              </>
            ) : (
              <></>
            )}
            {paymentUrl3 !== '' || paymentUrl3 !== null ? (
              <>
                <Clipboard value={paymentUrl3} /> <Spacer axis='vertical' size={0} />{' '}
              </>
            ) : (
              <></>
            )}
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={navigateTo} variant='text'>
              Fechar
            </Button>
          </Dialog.Actions>
        </Dialog>

        <Paper elevation={5}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                Service: {budgetData?.service_order?.number}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                Imei: {budgetData?.service_order?.imei}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom>
                Cliente: {budgetData?.service_order?.client?.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                CPF/CNPJ: {budgetData?.service_order?.client?.cgc}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom>
                E-mail: {budgetData?.service_order?.client?.email}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                Telefone: {budgetData?.service_order?.client?.phone_number}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography variant='subtitle2' gutterBottom>
                Seguradora: {budgetData?.service_order?.SubInsurancePll?.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom>
                Seguradora: {budgetData?.service_order?.SubInsurancePll?.name}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                Modelo: {budgetData?.service_order?.model ?? 'NÃO POSSUI'}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom>
                Cor: {budgetData?.service_order?.color_product ?? 'NÃO POSSUI'}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom color='secondary'>
                Tipo de Cobertura: {budgetData?.service_order?.insurance_wty ?? 'NÃO POSSUI'}
              </Typography>
            </Grid>

            <Grid item xs={2}>
              <Typography variant='subtitle2' gutterBottom>
                Sinistro:{' '}
                {budgetData?.service_order?.mail_object?.search_website_value ?? 'NÃO POSSUI'}
              </Typography>
            </Grid>
            <Grid item xs={12} align='center'>
              <Typography variant='h6' gutterBottom>
                Laudo Técnico: {budgetData?.service_order?.technical_report}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Spacer size={16} />

        <Button onClick={navigateTo} variant='text'>
          Voltar para tela principal
        </Button>

        <Grid container spacing={2}>
          <Grid xs={12} md={12} lg={12}>
            <Grid item xs={12} md={12} lg={12}>
              <Field
                size='small'
                fullWidth
                name='description'
                label='Descrição'
                variant='outlined'
                component={Text}
                inputProps={{ maxLength: 140 }}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
        <Spacer size={12} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <Button onClick={() => remarkCreate(values.description, budgetData?.service_order?.id)}>
              REGISTRAR
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={12}>
            <Table
              headers={columns}
              striped
              actionsLabel={<VisibilityIcon />}
              emptyMessage='Nenhum registro encontrado.'
              data={remarkData}
              disableNumeration
            >
              {remarkData?.map((serviceRemark) => (
                <TableRow key={serviceRemark?.id}>
                  <TableCell>{serviceRemark?.description}</TableCell>
                  <TableCell>{serviceRemark?.user?.name}</TableCell>
                  <TableCell>
                    <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                      {serviceRemark?.created_at}
                    </FormatDate>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
        </Grid>

        <Typography variant='h6' gutterBottom>
          Serviço Adicional
        </Typography>

        <Grid className={classes.gridContainer} container spacing={2}>
          <Grid item xs={12}>
            <TableAdditionalServices budget={budget} />
          </Grid>
        </Grid>

        <Typography variant='h6' gutterBottom>
          Preço e tomada de decisão
        </Typography>

        <Grid className={classes.gridContainerBottom} container spacing={2}>
          <Typography variant='h7' gutterBottom>
            * Selecione serviços,peças e método de pagamento mesmo para orçamento recusado.
            Utilizamos eles para nossos relatórios.
          </Typography>
        </Grid>

        {importing ? (
          <Grid xs={6} lg={12} className={classes.loading}>
            <Box sx={{ width: '100%' }}>
              <LinearProgress color='secondary' />
              <br></br>
              <Grid className={classes.gridImages} xs={6} lg={12}>
                {' '}
              </Grid>
            </Box>
          </Grid>
        ) : (
          <Grid className={classes.gridImages} xs={6} lg={12}>
            {' '}
          </Grid>
        )}

        <Grid container spacing={3}>
          <Grid item xs={6} lg={3}>
            <Field
              variant='outlined'
              name='wanted_value'
              label='Valor desejado pelo cliente'
              size='small'
              mask='money'
              component={Text}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Field
              variant='outlined'
              name='discount'
              label='Desconto %'
              size='small'
              component={Select}
              options={discountOptions}
              disabled={
                budgetData?.negociation_price != null || budgetData?.negociation_price != undefined
              }
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Field
              variant='outlined'
              name='status_id'
              label='Status'
              size='small'
              component={Select}
              options={statusesOptions}
              loading={loadingStatuses}
            />
          </Grid>
          <Grid item xs={6} lg={4}>
            <Field
              variant='outlined'
              name='refuse_motivation'
              label='Motivo recusa'
              size='small'
              component={Select}
              options={refuseOptions}
              loading={loadingRefuseData}
              disabled={values.status_id != 8}
            />
          </Grid>
          {/* <Grid item xs={3} lg={3} style={{ fontSize: 15 }}>
            <Field
              component={Checkbox}
              name='istradein'
              label='Troca ?'
              onClick={disableIFNotTradeIn}
            />
            <Typography variant='h7' gutterBottom>
              TROCA DE KIT ?
            </Typography>
          </Grid> */}
          {budgetData?.service_order?.process_type_id == 3 ? (
            <>
              <Grid item xs={6} lg={4}>
                <Field
                  variant='outlined'
                  name='istradein'
                  label='É troca?'
                  size='small'
                  component={Select}
                  options={isTrade}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Field
                  variant='outlined'
                  name='color_tradein'
                  label='Cor Trade IN'
                  size='small'
                  component={Select}
                  options={colorsOptions}
                  loading={loadingRefuseData}
                />
              </Grid>

              <Grid item xs={6} lg={4}>
                <Field variant='outlined' name='sku' label='SKU' size='small' component={Text} />
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Grid>

        <Grid className={classes.gridContainer} container spacing={0}>
          <Grid item xs={6} lg={12}>
            <Field
              color='primary'
              name='payment_type'
              value={1}
              fullWidth
              component={Radio}
              label='1 Método de Pagamento'
            />
          </Grid>

          <Grid item xs={6} lg={12}>
            <Field
              color='primary'
              name='payment_type'
              value={2}
              fullWidth
              component={Radio}
              label='2 Métodos de Pagamentos'
            />
          </Grid>

          <Grid item xs={6} lg={12}>
            <Field
              color='primary'
              name='payment_type'
              value={3}
              fullWidth
              component={Radio}
              label='3 Métodos de Pagamentos'
            />
          </Grid>
        </Grid>

        {values.payment_type == 1 ? (
          <>
            <FieldMethodPayment1 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>

            {useEffect(() => {
              if (values.payment_type == 1) {
                if (
                  values?.payment_method1 == 1 ||
                  values?.payment_method1 == 4 ||
                  values?.payment_method1 == 6 ||
                  values?.payment_method1 == 10 ||
                  values?.payment_method1 == 11 ||
                  values?.payment_method1 == 13
                ) {
                  setFieldValue('installments', 1);
                  setFieldValue(
                    'installment_price',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments).toFixed(2),
                  );
                }

                setFieldValue(
                  'installment_price',
                  budgetData?.negociation_price !== null
                    ? parseFloat(budgetData?.negociation_price / values.installments).toFixed(2)
                    : parseFloat(values.final_budget_price / values.installments).toFixed(2),
                );

                setFieldValue('payment_method2', null);
                setFieldValue('total_price_card2', 0);
                setFieldValue('installment_price2', 0);
                setFieldValue('installments2', 1);

                setFieldValue('payment_method3', null);
                setFieldValue('total_price_card3', 0);
                setFieldValue('installment_price3', 0);
                setFieldValue('installments3', 1);
              }
            }, [
              setFieldValue,
              values.payment_type,
              values.additional_services_id,
              values.components_total_price,
              values.additional_services_total_price,
              values?.payment_method1,
              values?.payment_method2,
              values.installment,
              values.installments,
              values.installments2,
              values.installment_price,
              values.installment_price2,
              values.total_price_card1,
              values.total_price_card2,
              values.discount,
              values.final_budget_price,
            ])}
          </>
        ) : (
          ''
        )}

        {values.payment_type == 2 ? (
          <>
            <FieldMethodPayment1 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='total_price_card1'
                  label='Valor total no cartão'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='wanted_value'
                  label='Valor desejado pelo cliente'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
            </Grid>
            <FieldMethodPayment2 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments2'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method2 == 1 ||
                    values?.payment_method2 == 4 ||
                    values?.payment_method2 == 6 ||
                    values?.payment_method2 == 10 ||
                    values?.payment_method2 == 11 ||
                    values?.payment_method2 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='total_price_card2'
                  label='Valor total no cartão'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price2'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>

            {useEffect(() => {
              if (values.payment_type == 2) {
                if (
                  values?.payment_method1 == 1 ||
                  values?.payment_method1 == 4 ||
                  values?.payment_method1 == 6 ||
                  values?.payment_method1 == 10 ||
                  values?.payment_method1 == 11 ||
                  values?.payment_method1 == 13
                ) {
                  setFieldValue('installments', 1);
                  setFieldValue(
                    'installment_price',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments).toFixed(2),
                  );
                }

                if (
                  values?.payment_method2 == 1 ||
                  values?.payment_method2 == 4 ||
                  values?.payment_method2 == 6 ||
                  values?.payment_method2 == 10 ||
                  values?.payment_method2 == 11 ||
                  values?.payment_method2 == 13
                ) {
                  setFieldValue('installments2', 1);
                  setFieldValue(
                    'installment_price2',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments2).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments2).toFixed(2),
                  );
                }

                setFieldValue(
                  'installment_price',
                  parseFloat(values.total_price_card1 / values.installments).toFixed(2),
                );
                setFieldValue(
                  'installment_price2',
                  parseFloat(values.total_price_card2 / values.installments2).toFixed(2),
                );

                setFieldValue('payment_method3', null);
                setFieldValue('total_price_card3', 0);
                setFieldValue('installment_price3', 0);
                setFieldValue('installments3', 1);
              }
            }, [
              setFieldValue,
              values.payment_type,
              values.additional_services_id,
              values.components_total_price,
              values.additional_services_total_price,
              values?.payment_method1,
              values?.payment_method2,
              values.installment,
              values.installments,
              values.installments2,
              values.installment_price,
              values.installment_price2,
              values.total_price_card1,
              values.total_price_card2,
              values.discount,
              values.final_budget_price,
            ])}
          </>
        ) : (
          ''
        )}

        {/* etste */}

        {values.payment_type == 3 ? (
          <>
            <FieldMethodPayment1 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='total_price_card1'
                  label='Valor total no cartão'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>

            <FieldMethodPayment2 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments2'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method2 == 1 ||
                    values?.payment_method2 == 4 ||
                    values?.payment_method2 == 6 ||
                    values?.payment_method2 == 10 ||
                    values?.payment_method2 == 11 ||
                    values?.payment_method2 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='total_price_card2'
                  label='Valor total no cartão'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price2'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>

            <FieldMethodPayment3 />
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installments3'
                  label='Parcelas'
                  size='small'
                  type='number'
                  disabled={
                    values?.payment_method3 == 1 ||
                    values?.payment_method3 == 4 ||
                    values?.payment_method3 == 6 ||
                    values?.payment_method3 == 10 ||
                    values?.payment_method3 == 11 ||
                    values?.payment_method3 == 13
                      ? true
                      : false
                  }
                  component={Select}
                  options={installmentOptions}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='total_price_card3'
                  label='Valor total no cartão'
                  size='small'
                  mask='money'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} lg={3}>
                <Field
                  variant='outlined'
                  name='installment_price3'
                  label='Valor por parcela'
                  size='small'
                  mask='money'
                  component={Text}
                  disabled
                />
              </Grid>
            </Grid>

            {useEffect(() => {
              if (values.payment_type == 3) {
                if (
                  values?.payment_method1 == 1 ||
                  values?.payment_method1 == 4 ||
                  values?.payment_method1 == 6 ||
                  values?.payment_method1 == 10 ||
                  values?.payment_method1 == 11 ||
                  values?.payment_method1 == 13
                ) {
                  setFieldValue('installments', 1);
                  setFieldValue(
                    'installment_price',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments).toFixed(2),
                  );
                }

                if (
                  values?.payment_method2 == 1 ||
                  values?.payment_method2 == 4 ||
                  values?.payment_method2 == 6 ||
                  values?.payment_method2 == 10 ||
                  values?.payment_method2 == 11 ||
                  values?.payment_method2 == 13
                ) {
                  setFieldValue('installments2', 1);
                  setFieldValue(
                    'installment_price2',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments2).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments2).toFixed(2),
                  );
                }

                if (
                  values?.payment_method3 == 1 ||
                  values?.payment_method3 == 4 ||
                  values?.payment_method3 == 6 ||
                  values?.payment_method3 == 10 ||
                  values?.payment_method3 == 11 ||
                  values?.payment_method3 == 13
                ) {
                  setFieldValue('installments3', 1);
                  setFieldValue(
                    'installment_price3',
                    budgetData?.negociation_price !== null
                      ? parseFloat(budgetData?.negociation_price / values.installments3).toFixed(2)
                      : parseFloat(values.final_budget_price / values.installments3).toFixed(2),
                  );
                }

                setFieldValue(
                  'installment_price',
                  parseFloat(values.total_price_card1 / values.installments).toFixed(2),
                );
                setFieldValue(
                  'installment_price2',
                  parseFloat(values.total_price_card2 / values.installments2).toFixed(2),
                );

                setFieldValue(
                  'installment_price3',
                  parseFloat(values.total_price_card3 / values.installments3).toFixed(2),
                );
              }
            }, [
              setFieldValue,
              values.additional_services_id,
              values?.payment_method1,
              values?.payment_method2,
              values?.payment_method3,
              values.installment,
              values.installments,
              values.installments2,
              values.installments3,
              values.components_total_price,
              values.additional_services_total_price,
              values.installment_price,
              values.installment_price2,
              values.total_price_card1,
              values.total_price_card2,
              values.total_price_card3,
              values.final_budget_price,
              values.discount,
              values.payment_type,
            ])}
          </>
        ) : (
          ''
        )}

        <Grid container spacing={2}>
          <Grid container justifyContent='space-between' spacing={12}>
            <Grid item xs={6} lg={3}>
              {!values.voucher ? (
                <Button fullWidth onClick={handleOpenInformation} color='primary' variant='text'>
                  Anexar comprovante
                </Button>
              ) : (
                <Button fullWidth onClick={handleOpenInformation} color='primary' variant='text'>
                  {}
                </Button>
                // <Field variant='outlined' name='voucher' size='small' component={Text} disabled />
              )}
            </Grid>
            <Grid item xs={6} lg={1}>
              <Button
                type='submit'
                disabled={
                  !formikProps.isValid ||
                  !formikProps.dirty ||
                  !values.payment_method1 ||
                  (values.payment_type == 1 &&
                    budgetData?.negociation_price === null &&
                    gettingRest == 0 &&
                    values.installment_price * values.installments < values.final_budget_price) ||
                  (values.payment_type == 2 &&
                    budgetData?.negociation_price === null &&
                    values.payment_method2 &&
                    values.total_price_card1 + values.total_price_card2 <
                      values.final_budget_price) ||
                  (values.payment_type == 3 &&
                    budgetData?.negociation_price === null &&
                    values.payment_method3 &&
                    values.total_price_card1 + values.total_price_card2 + values.total_price_card3 <
                      values.final_budget_price) ||
                  (values.payment_type == 1 &&
                    budgetData?.negociation_price !== null &&
                    gettingRest == 0 &&
                    values.installment_price * values.installments <
                      budgetData?.negociation_price) ||
                  (values.payment_type == 2 &&
                    budgetData?.negociation_price !== null &&
                    values.payment_method2 &&
                    values.total_price_card1 + values.total_price_card2 <
                      budgetData?.negociation_price) ||
                  (values.payment_type == 3 &&
                    budgetData?.negociation_price !== null &&
                    values.payment_method3 &&
                    values.total_price_card1 + values.total_price_card2 + values.total_price_card3 <
                      budgetData?.negociation_price)
                }
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <AttachVoucher open={information} handleClose={handleCloseInformation} />
      </Form>
    );
  };
  return (
    <Formik
      initialValues={{
        wanted_value: 0,
        payment_type: 1,
        payment_method1: 1,
        payment_method2: null,
        payment_method3: null,
        status_id: '',
        additional_services_id: [],
        additional_services_total_price: 0,
        installments: 1,
        installments2: 1,
        installments3: 1,
        installment_price: 0,
        installment_price2: 0,
        installment_price3: 0,
        total_price_card1: 0,
        total_price_card2: 0,
        total_price_card3: 0,
        discount: 0,
        additional_price: 0,
        refuse_motivation: '',
        istradein: '',
        color_tradein: '',
        sku: '',
        description: '',
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {(props) => <FormAdditional formikProps={props} />}
    </Formik>
  );
};
