import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { RECEIVING_REPORT } from 'services/graphql/query/reports';
import XLSX from 'xlsx';
import * as Yup from 'yup';

export function ReceivingReport() {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const [downloadReport] = useMutation(RECEIVING_REPORT, {
    onCompleted: (data) => {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data?.receivingReport));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          warehouse: worksheet,
        },
        SheetNames: ['warehouse'],
      });
      XLSX.writeFile(workbook, `Relatório_Recebimento.xlsx`);
      toast.success('Relatório baixado com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Erro ao baixar o relatório`);
    },
  });

  const handleSubmit = async (values) => {
    const inputDate = new Date(values.input_data);
    const outputDate = new Date(values.output_data);

    const inputDateISO = inputDate.toISOString();
    const outputDateISO = outputDate.toISOString();

    await downloadReport({ variables: { inputDate: inputDateISO, outputDate: outputDateISO } });
  };

  return (
    <>
      <PageTitle>Relatório de Recebimento</PageTitle>

      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
        validationSchema={Yup.object({
          input_data: Yup.date().required('Data de entrada é obrigatória'),
          output_data: Yup.date().required('Data de saída é obrigatória'),
        })}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  component={DatePicker}
                  label='Data inicial'
                  fullWidth
                  inputVariant='outlined'
                  variant='inline'
                  onChange={(date) => setFieldValue('input_data', date.toISOString())}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  component={DatePicker}
                  label='Data final'
                  fullWidth
                  inputVariant='outlined'
                  variant='inline'
                  onChange={(date) => setFieldValue('output_data', date.toISOString())}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth type='submit' loading={isSubmitting}>
                  Baixar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(values.input_data);
              setOutputData(values.output_data);
            }, [values])}
          </Form>
        )}
      </Formik>
    </>
  );
}
