import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { cashierService } from 'services/cashier';

export const ConfirmDialog = ({ open, onClose, selectedOrdering }) => {
  console.log('What Comes here: ', selectedOrdering);

  console.log('ID: ', selectedOrdering?.id);

  const handleDownloadClick = async (id, client_id) => {
    const solded = await cashierService.listAccessoriesSolded(id);

    const accessories = solded?.data?.map((test) => {
      return { accessory_id: test?.accessory_id, amount: test?.amount };
    });

    const { data } = await cashierService.downloadNoFiscalCoupon(id, client_id, accessories);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });

    saveAs(file, `${id}.pdf`);

    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <Dialog.Title onClose={onClose}>Aviso</Dialog.Title>
      <Dialog.Content>
        <Typography>Deseja baixar o PDF referente a nota?</Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} variant='text' color='default'>
          Não
        </Button>
        <Button
          onClick={() => handleDownloadClick(selectedOrdering?.id, selectedOrdering?.client_id)}
        >
          Sim
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
