import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Checkbox, Spacer, Table, Text, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService, serviceOrderService } from 'services';

import { ReflectInvoiceDialog } from './ReflectInvoiceDialog';
import { validationSchemaManual } from './validation';

const columns = [
  { label: 'Incluir' },
  { label: 'Part-Number' },
  { label: 'Descrição' },
  { label: 'Quantidade', align: 'left' },
  { label: 'Service', align: 'left' },
  { label: 'Imei SERVICE', align: 'left' },
  { label: 'Imei CONFERÊNCIA', align: 'left' },
  { label: 'Classificação', align: 'left' },
  // { label: 'IMEI' },
  { label: 'Check-Kit Realizado', align: 'center' },

  { label: 'Imei OK', align: 'center' },

  { label: <VisibilityIcon />, align: 'center' },
];

const columnsToFinish = [
  { label: 'Total de produtos na nota', align: 'center' },
  { label: 'Total de produtos validados', align: 'center' },
  { label: 'Total de produtos não validados', align: 'center' },
  { label: 'Ação', align: 'center', align: 'center' },
];

export const ReflectInvoice = () => {
  const formikRef = useRef();

  const [loading, setLoading] = useState(false);

  const [client, setClient] = useState();
  const [datas, setDatas] = useState();
  const [frete, setFrete] = useState();
  const [checkedItem, setCheckedItem] = useState([]);
  const [missingItem, setMissingItem] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [search, setSearch] = useState('');
  const [invoice, setInvoice] = useState({});
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [validated, setValidated] = useState(0);
  const [notValidated, setNotValidated] = useState(0);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setInvoice(item);
  };

  const formattedData = (datas || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredData = formattedData.filter((invoice) => {
    const upperInfnfe = invoice.infnfe?.toUpperCase();
    const lowerInfnfe = invoice.infnfe?.toLowerCase();

    return upperInfnfe?.includes(search) || lowerInfnfe?.includes(search);
  });

  const freteOptions = [
    // {
    //   value: 0,
    //   label: 'Contratação do Frete por conta do Remetente (CIF)',
    // },
    // {
    //   value: 1,
    //   label: 'Contratação do Frete por conta do Destinatário (FOB)',
    // },
    // {
    //   value: 2,
    //   label: 'Contratação do Frete por conta de Terceiros',
    // },
    {
      value: '3',
      label: 'Transporte Próprio por conta do Remetente',
    },
    {
      value: '4',
      label: 'Transporte Próprio por conta do Destinatário',
    },
    {
      value: 'S',
      label: 'Sem Ocorrência de Transporte',
    },
  ];

  const onSubmitHandler = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setMissingItem([]);
    setCheckedItem([]);
    setValidated(0);
    setNotValidated(0);
    setServiceOrders([]);
    setFrete();
    setDatas([]);
    if (values.frete === 0) {
      toast.error('Selecione uma modalidade de frete');
      throw 'Selecione uma modalidade de frete';
    }
    try {
      const { data } = await invoiceService.listInvoicesRepairReflect(values.infnfe);
      console.log('zabeu: ', data);

      data?.map((count) => {
        if (count.checkit !== null) {
          setValidated((prevState) => prevState + 1);
        } else {
          setNotValidated((prevState) => prevState + 1);
        }
      });

      data?.map((service) => {
        if (service.teste !== null) {
          setServiceOrders((oldState) => [...oldState, service?.teste?.number]);
        }
      });

      setFrete(values.frete);
      setDatas(data);
      setMissingItem(data);

      if (data[0]?.service_order?.client_id) {
        const x = await serviceOrderService.getClientById(data[0]?.service_order?.client_id);
        setClient(x?.data);
      }

      setSubmitting(true);
      toast.success(`NFe's encontrada com sucesso.`);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao encontrar OS');
    } finally {
      setSubmitting(false);
    }
  };

  const handleChecked = (invoice) => {
    const indexObject = checkedItem.findIndex((item) => {
      return item.id === invoice;
    });

    const indexObjectMiss = missingItem.findIndex((item) => {
      return item.id === invoice;
    });

    const indexObjectData = datas.filter((item) => {
      return item.id === invoice;
    });

    if (indexObject > -1) {
      setCheckedItem(checkedItem.filter((_, item) => item !== indexObject));
      setMissingItem((oldState) => [...oldState, indexObjectData?.[0]]);

      if (indexObjectData?.[0].service_order !== null) {
        setServiceOrders(checkedItem.filter((_, item) => item !== indexObject));
      }
    } else {
      setCheckedItem((oldState) => [...oldState, indexObjectData?.[0]]);
      setMissingItem(missingItem.filter((_, item) => item !== indexObjectMiss));
      if (indexObjectData?.[0].service_order !== null) {
        setServiceOrders((oldState) => [...oldState, indexObjectData?.[0].service_order?.number]);
      }
    }
  };

  const handleIsChecked = (invoice) => {
    const indexObject = checkedItem.findIndex((item) => {
      return item.id === invoice;
    });

    if (indexObject > -1) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmitInvoice = async () => {
    setLoading(true);
    try {
      const { data } = await invoiceService.createReflectInvoice({
        infnfe: datas[0].infnfe,
        nnf: datas[0].nnf,
        items: checkedItem,
        service_orders: serviceOrders,
        frete: frete,
      });

      console.log('baata', data);

      toast.success('Nota gerada com sucesso');

      window.open(data?.danfe.retorno.link_nfe, '_blank');

      // setTimeout(() => {
      //   window.location.reload();
      // }, 4000);

      // setTimeout(() => {
      //   location.reload();
      // }, 4000);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Ocorreu um erro ao gerar nota de saída');
    } finally {
      setLoading(false);

      setMissingItem([]);
      setValidated(0);
      setNotValidated(0);
      setServiceOrders([]);
      setFrete();
      setDatas([]);
      setClient();
    }
  };

  console.log('Service Orders: ', serviceOrders);
  console.log('handleChecked: ', checkedItem);
  console.log('MissingItem: ', missingItem);
  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };
  return (
    <>
      <PageTitle>Espelhar Nota</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ infnfe: '', frete: 0 }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchemaManual}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            {' '}
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4}>
                <Field
                  label='Ordem de Serviço'
                  name='infnfe'
                  variant='outlined'
                  size='small'
                  onInput={toInputUppercase}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <Field
                  label='Tipo de frete'
                  name='frete'
                  variant='outlined'
                  size='small'
                  onInput={toInputUppercase}
                  options={freteOptions}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button disabled={!props.dirty || !props.isValid} type='submit' fullWidth>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12}>
                <Table
                  headers={columns}
                  maxHeight
                  striped
                  emptyMessage='Sem registros.'
                  // actionsLabel={<VisibilityIcon />}
                  // onActionsClick={handleOpenInformation}
                  data={filteredData}
                  disableNumeration
                  size='small'
                >
                  {filteredData?.map((invoice) => (
                    <TableRow key={invoice.id}>
                      <TableCell size='small' align='center'>
                        <Checkbox
                          color='primary'
                          onChange={() => handleChecked(invoice.id)}
                          checked={
                            handleIsChecked(invoice.id) || invoice.invoice_generated === true
                          }
                          disabled={
                            invoice.invoice_generated === true ||
                            !invoice?.checkit ||
                            invoice?.product?.imei !== invoice?.teste?.imei
                          }
                        />
                      </TableCell>
                      <TableCell>{invoice?.cprod}</TableCell>
                      <TableCell>{invoice?.xprod}</TableCell>
                      <TableCell>{invoice?.qcom}</TableCell>
                      <TableCell>
                        {invoice?.teste?.number ?? 'IMEI CONFERÊNCIA X SERVICE DIVERGENTE'}
                      </TableCell>

                      {
                        <TableCell>
                          {invoice?.teste?.imei ?? 'IMEI CONFERÊNCIA X SERVICE DIVERGENTE'}
                        </TableCell>
                      }

                      {<TableCell>{invoice?.product?.imei}</TableCell>}
                      <TableCell>
                        {invoice?.teste?.isDevol == true ? 'DEVOLUÇÃO SEM REPARO' : 'SALDO A'}
                      </TableCell>
                      <TableCell align='center'>
                        {!invoice?.teste?.number ? (
                          'IMEI CONFERÊNCIA X SERVICE DIVERGENTE'
                        ) : invoice?.checkit ? (
                          <CheckCircleIcon style={{ color: '#17C13E' }} />
                        ) : (
                          <CancelIcon style={{ color: '#D9534F' }} />
                        )}
                      </TableCell>

                      <TableCell align='center'>
                        {invoice?.product?.imei === invoice?.teste?.imei ? (
                          <CheckCircleIcon style={{ color: '#17C13E' }} />
                        ) : (
                          <CancelIcon style={{ color: '#D9534F' }} />
                        )}
                      </TableCell>

                      <TableCell align='center'>
                        <IconButton
                          color='primary'
                          onClick={(e) => handleOpenInformation(e, invoice)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
              <ReflectInvoiceDialog
                open={isDetailsOpen}
                handleClose={handleCloseInformation}
                invoice={invoice}
                client={client}
              />
            </Grid>
            <Spacer size={4} />
          </Form>
        )}
      </Formik>

      {console.log('Checked Items Length: ', checkedItem.length)}
      {console.log('Service Orders Length: ', serviceOrders.length)}

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={5}>
            <Table
              headers={columnsToFinish}
              maxHeight
              striped
              emptyMessage='Sem registros.'
              disableNumeration
              size='medium'
            >
              <TableRow key={invoice.id}>
                <TableCell align='center'>{datas?.length ? datas?.length : 0}</TableCell>
                <TableCell align='center'>{validated}</TableCell>
                {console.log('validated: ', validated)}
                <TableCell align='center'>{notValidated}</TableCell>
                <TableCell align='center'>
                  <Button
                    color='primary'
                    disabled={
                      checkedItem?.length !== serviceOrders?.length ||
                      checkedItem?.length === 0 ||
                      loading
                    }
                    onClick={onSubmitInvoice}
                  >
                    Gerar nota de saída
                  </Button>
                </TableCell>
              </TableRow>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
