import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import cep from 'cep-promise';
import { Text } from 'components';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';

export const FormRegistration = () => {
  const { values, setFieldValue } = useFormikContext();

  const [loadingCep, setLoadingCep] = useState(false);

  const onBlurCep = async () => {
    setLoadingCep(true);
    const { state, city, neighborhood, street } = await cep(values?.zipcode);

    setFieldValue('state', state);
    setFieldValue('city', city);
    setFieldValue('district', neighborhood);
    setFieldValue('street', street);

    setLoadingCep(false);
  };

  const getCNPJ = async () => {
    const consultarCNPJ = require('consultar-cnpj');
    const { razao_social, atualizado_em, capital_social, estabelecimento, socios } =
      await consultarCNPJ(values.cnpj);
    const { inscricoes_estaduais } = estabelecimento;

    setFieldValue('razao_social', razao_social);
    setFieldValue('atualizado_em', atualizado_em);
    setFieldValue('capital_social', capital_social);
    setFieldValue('inscricao_estadual', inscricoes_estaduais[0]?.inscricao_estadual);
    setFieldValue('pais_id', socios[2]?.pais_id);
  };

  const [states, , loadingStates] = useQuery(
    () => api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados'),
    [],
  );

  const ufsOptions = (states || []).map((state) => {
    return {
      value: state.sigla,
      label: state.sigla,
    };
  });

  // const isCPF = values.cgc?.length < 12;

  return (
    <>
      <PageTitle att dev>
        Registro único de Cupom Fiscal
      </PageTitle>

      <Typography gutterBottom variant='h6'>
        Emissor
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Field
            label='CNPJ'
            name='cnpj'
            onBlur={getCNPJ}
            size='small'
            variant='outlined'
            component={Text}
            mask='document'
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Field
            label='Nome da organização'
            name='razao_social'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Field
            label='Ultima atualização Juridica'
            name='atualizado_em'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>

        <Grid item xs={12} lg={2}>
          <Field
            label='Capital social'
            name='capital_social'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field
            label='Inscrição Estadual'
            name='inscricao_estadual'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>

        <Grid item xs={6} lg={2}>
          <Field
            label='Código País'
            name='pais_id'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field label='País' name='country' size='small' variant='outlined' component={Text} />
        </Grid>
      </Grid>
      <Typography gutterBottom variant='h6'>
        Endereço do emissor
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Field
            mask='zipcode'
            label='CEP'
            name='zipcode'
            size='small'
            variant='outlined'
            component={Text}
            onBlur={onBlurCep}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            label='Cidade'
            name='city'
            size='small'
            variant='outlined'
            component={Text}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field
            label='Estado'
            name='state'
            size='small'
            variant='outlined'
            component={Text}
            loading={loadingStates || loadingCep}
            options={ufsOptions}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <Field
            label='Bairro'
            name='district'
            size='small'
            variant='outlined'
            loading={loadingCep}
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            label='Logradouro'
            name='street'
            size='small'
            variant='outlined'
            component={Text}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field label='Número' name='number' size='small' variant='outlined' component={Text} />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field
            label='Complemento'
            name='complement'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>

        <Grid item xs={6} lg={2}>
          <Field
            label='Código Município'
            name='county_code'
            size='small'
            variant='outlined'
            component={Text}
          />
        </Grid>
      </Grid>
    </>
  );
};
