import React from 'react';

import ArrowBack from '@material-ui/icons/ArrowBack';

import { browserHistory } from 'routes/browserHistory';

import { useStyles } from './styles';

export const BackNavButton = ({ path }) => {
  const classes = useStyles();

  const handleClick = () => {
    if (path) {
      return browserHistory.push(path);
    }

    return browserHistory.goBack();
  };

  return (
    <div className={classes.root} onClick={handleClick} role='button'>
      <ArrowBack className={classes.arrowIcon} fontSize='small' color='action' />

      <span className={classes.backText}>Voltar</span>
    </div>
  );
};
