import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { useMutation, useQuery } from '@apollo/client';
import { TextField, Autocomplete } from '@mui/material';
import { Button, Dialog } from 'components';
import { Field, Form, Formik } from 'formik';
import { LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR } from 'services/graphql/query/product';
import { UPDATE_SERVICE_ORDER_PRODUCT } from 'services/graphql/query/service-orders';

import { validationSchema } from './validation';

export function UpdatedProduct({ currentProduct, isOpen = false, handleClose, sinister, refetch }) {
  if (!isOpen) {
    return <></>;
  }

  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [storages, setStorages] = useState([]);
  const [colors, setColors] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);

  const { data, loading } = useQuery(LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR);

  const [updateProduct] = useMutation(UPDATE_SERVICE_ORDER_PRODUCT, {
    onCompleted: () => {
      toast.success('Produto atualizado com sucesso');
      refetch();
      handleClose();
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar o produto';
      toast.error(`Error: ${errorMessage}`);
    },
  });

  useEffect(() => {
    if (data) {
      const uniqueBrands = Array.from(new Set(data.listProduct.map((p) => p.brandId))).map((id) => {
        const brand = data.listProduct.find((p) => p.brandId === id)?.brand;
        return { id, description: brand?.description || 'Unknown' };
      });
      setBrands(uniqueBrands);
    }
  }, [data]);

  useEffect(() => {
    if (selectedBrand) {
      const filteredStorages = Array.from(
        new Set(
          data.listProduct.filter((p) => p.brandId === selectedBrand.id).map((p) => p.modelId),
        ),
      ).map((id) => {
        const model = data.listProduct.find((p) => p.modelId === id)?.model;
        return { id, description: model?.description || 'Unknown' };
      });
      setModels(filteredStorages);
      setSelectedModel(null);
      setStorages([]);
      setSelectedStorage(null);
      setColors([]);
      setSelectedColor(null);
    }
  }, [selectedBrand, data]);

  useEffect(() => {
    if (selectedModel) {
      const filteredStorages = Array.from(
        new Set(
          data.listProduct.filter((p) => p.modelId === selectedModel.id).map((p) => p.storageId),
        ),
      ).map((id) => {
        const storage = data.listProduct.find((p) => p.storageId === id)?.storage;
        return { id, description: storage?.description || 'Unknown' };
      });
      setStorages(filteredStorages);
      setSelectedStorage(null);
      setColors([]); // Limpa as cores quando o modelo é alterado
      setSelectedColor(null);
    }
  }, [selectedModel, data]);

  useEffect(() => {
    if (selectedStorage) {
      const filteredColors = Array.from(
        new Set(
          data.listProduct
            .filter(
              (p) =>
                p.brandId === selectedBrand.id &&
                p.modelId === selectedModel.id &&
                p.storageId === selectedStorage.id,
            )
            .map((p) => p.colorId),
        ),
      ).map((id) => {
        const color = data.listProduct.find((p) => p.colorId === id)?.color;
        return { id, description: color?.description || 'Unknown' };
      });
      setColors(filteredColors);
      setSelectedColor(null);
    }
  }, [selectedStorage, selectedBrand, selectedModel, data]);

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await updateProduct({
        variables: {
          data: { ...values, sinister: sinister },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <Dialog.Title onClose={handleClose}>Atualizar produto: {data.sinister}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            brandId: selectedBrand?.id ?? '',
            modelId: selectedModel?.id ?? '',
            storageId: selectedStorage?.id ?? '',
            colorId: selectedColor?.id ?? '',
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={3}>
                  <Field
                    name='brandId'
                    component={Autocomplete}
                    options={brands}
                    getOptionLabel={(option) => option.description}
                    value={selectedBrand || null}
                    onChange={(event, newValue) => {
                      setSelectedBrand(newValue);
                      props.setFieldValue('brandId', newValue?.id || '');
                      setSelectedModel(null); // Resetar modelo ao mudar a marca
                      setSelectedStorage(null); // Resetar armazenamento
                      setColors([]); // Limpar cores
                      setSelectedColor(null); // Limpar cor selecionada
                      setStorages([]); // Limpar opções de armazenamento
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Marca' variant='outlined' />
                    )}
                  />
                </Grid>

                <Grid item xs={12} xl={3}>
                  <Field
                    name='modelId'
                    component={Autocomplete}
                    options={models}
                    getOptionLabel={(option) => option.description}
                    value={selectedModel || null}
                    onChange={(event, newValue) => {
                      setSelectedModel(newValue);
                      props.setFieldValue('modelId', newValue?.id || '');
                      setSelectedStorage(null); // Resetar armazenamento ao mudar modelo
                      setColors([]); // Limpar cores
                      setSelectedColor(null); // Limpar cor selecionada
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Modelo' variant='outlined' />
                    )}
                    disabled={!selectedBrand} // Bloqueia o modelo se não houver marca selecionada
                  />
                </Grid>

                <Grid item xs={12} xl={3}>
                  <Field
                    name='storageId'
                    component={Autocomplete}
                    options={storages}
                    getOptionLabel={(option) => option.description}
                    value={selectedStorage}
                    onChange={(event, newValue) => {
                      setSelectedStorage(newValue);
                      props.setFieldValue('storageId', newValue?.id || '');
                      setColors([]); // Limpa cores ao alterar armazenamento
                      setSelectedColor(null); // Limpar cor selecionada
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Armazenamento' variant='outlined' />
                    )}
                    disabled={!selectedModel} // Bloqueia o armazenamento se não houver modelo selecionado
                  />
                </Grid>

                <Grid item xs={12} xl={3}>
                  <Field
                    name='colorId'
                    component={Autocomplete}
                    options={colors}
                    getOptionLabel={(option) => option.description}
                    value={selectedColor}
                    onChange={(event, newValue) => {
                      setSelectedColor(newValue);
                      props.setFieldValue('colorId', newValue?.id || '');
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Cor' variant='outlined' />
                    )}
                    disabled={!selectedStorage} // Bloqueia a cor se não houver armazenamento selecionado
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    type='submit'
                    color='secondary'
                    disabled={!props.isValid || props.isSubmitting}
                  >
                    Salvar Alterações e Continuar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
