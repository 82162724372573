/* eslint-disable no-restricted-imports */
import React, { useContext, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
  IconButton,
} from '@material-ui/core';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Pagination } from '@mui/material';

import { ImportXmlDialog } from './ImportXmlDialog';
import { WaitingOracleOutBoundContext } from './WaitingOracleOutBound';

const columns = [
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'CÓDIGO PEDIDO', align: 'center' },
  { label: 'CLIENTE', align: 'center' },
  { label: 'VOUCHER', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'PRODUTO', align: 'center' },
  { label: 'ANEXAR XML', align: 'center' },
];

export function WaitingOracleOutBoundTable() {
  const { data, loading, search, refetch } = useContext(WaitingOracleOutBoundContext);

  if (loading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const [isOpenImportXmlDialog, setIsOpenImportXmlDialog] = useState(false);
  const [itemSelect, setItemSelect] = useState();
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenImportXmlDialog = (item) => {
    setItemSelect(item);
    setIsOpenImportXmlDialog(true);
  };

  const handleCloseImportXmlDialog = () => {
    setIsOpenImportXmlDialog(false);
  };

  const filteredOrders = data.filter(
    (order) =>
      order.anymarketId.toLowerCase().includes(search.toLowerCase()) ||
      order.orderCode.toLowerCase().includes(search.toLowerCase()) ||
      order.client.toLowerCase().includes(search.toLowerCase()) ||
      order.imei.toLowerCase().includes(search.toLowerCase()) ||
      order.sinister.toLowerCase().includes(search.toLowerCase()) ||
      order.sku.toLowerCase().includes(search.toLowerCase()) ||
      order.product.toLowerCase().includes(search.toLowerCase()),
  );

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = filteredOrders.slice(startIndex, startIndex + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align} style={{ fontWeight: 'bold' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(paginatedResults || []).map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell align='center'>{item?.anymarketId ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.orderCode ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.client ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.sinister ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.imei ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.sku ?? 'N/A'}</TableCell>
                <TableCell align='center'>{item?.product ?? 'N/A'}</TableCell>
                <TableCell align='center'>
                  <IconButton color='primary'>
                    <AddCircleOutlineIcon
                      fontSize='large'
                      onClick={() => handleOpenImportXmlDialog(item)}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='secondary'
        />
      </Grid>

      <ImportXmlDialog
        isOpen={isOpenImportXmlDialog}
        handleClose={handleCloseImportXmlDialog}
        refetch={refetch}
        item={itemSelect}
      />
    </TableContainer>
  );
}
