import { createContext, useContext } from 'react';

export const InvoiceContext = createContext(null);

export const InvoiceContextProvider = InvoiceContext.Provider;

export const useInvoice = () => {
  const ctx = useContext(InvoiceContext);

  if (!ctx) {
    throw new Error('You must inform a provider');
  }

  return ctx;
};
