import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Select, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { mailServices } from 'services/mail';

import { validationSchema } from './validation';

const columns = [
  { label: 'Service' },
  { label: 'Nome Cliente' },
  { label: 'Objeto' },
  { label: 'Nº Nota' },
];

export const MinuteBatch = () => {
  const [validOutObjects, setValidOutObjects] = useState([]);
  const [contract, setContract] = useState();

  const [mailContractsData, , loadingMailContracts] = useQuery(mailServices.listMailContracts, []);

  const [validOutObjectsBatch, , loadingValidOutObjectsBatch, refetchValidOutObjectsBatch] =
    useQuery(() => mailServices.listValidOutObjectsContractActive(contract), [contract]);

  console.log('validOutObjectsBatch: ', validOutObjectsBatch);

  const mailContractOptions = (mailContractsData || []).map((mailContract) => {
    return {
      value: mailContract.id,
      label: mailContract.contract_number + ' ' + mailContract.contract_method,
    };
  });

  const handleDownloadTagsClick = async (id) => {
    const { data } = await mailServices.impressMinuteBatch(id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${id}.pdf`);
  };

  const onSubmitHandler = async (contract_mail_id, batch_id) => {
    console.log('batch_id: ', batch_id);
    try {
      // await handleDownloadTagsClick(batch_id);

      await mailServices.updateToFinishContract(contract_mail_id);

      refetchValidOutObjectsBatch();
      toast.success('Contrato finalizado com sucesso.');
    } catch (err) {
      console.log(err?.response?.data?.message);
      toast.error(
        err?.response?.data?.message || 'Contrato não localizado para finalizar a minuta.',
      );
    }
  };

  // const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
  //   try {
  //     const batchExists = await mailServices.listValidOutObjectsContractActive(contract);
  //     console.log('batchExists: ', batchExists);
  //     if (!batchExists) {
  //       console.log('entrou no if');
  //       const getValidOutObject = await mailServices.createValidOutObjectBatch(contract);

  //       const { data } = await mailServices.createValidOutObjectToBatch(
  //         values.mail_contract_id,
  //         values.nf_type,
  //         values.prefix_country,
  //         values.service_order_number,
  //         getValidOutObject?.data?.id,
  //       );

  //       await mailServices.addValidOutObjectToBatch(data.id, contract);
  //     } else {
  //       console.log('entrou no else');
  //       console.log('Desgraça: ', batchExists?.data?.id);
  //       const { data } = await mailServices.createValidOutObjectToBatch(
  //         values.mail_contract_id,
  //         values.nf_type,
  //         values.prefix_country,
  //         values.service_order_number,
  //         batchExists?.data?.id,
  //       );

  //       await mailServices.addValidOutObjectToBatch(data.id, contract);
  //     }
  //   } catch (err) {
  //     toast.error(err?.response?.data?.message || 'Erro ao cadastrar objeto para a minuta em lote');
  //   } finally {
  //     refetchValidOutObjectsBatch();
  //   }
  // };

  return (
    <>
      <PageTitle dev>Minuta em Lote</PageTitle>

      <Formik
        initialValues={{
          service_order_number: '',
          mail_contract_id: 0,
          prefix_country: 'BR',
          nf_type: 'F',
        }}
        validationSchema={validationSchema}
        // onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  options={mailContractOptions}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Contrato'
                  name='mail_contract_id'
                  loading={loadingMailContracts}
                  component={Select}
                  onChange={(e) => setContract(e)}
                />
              </Grid>
            </Grid>
            {contract ? (
              <>
                {/* <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Field
                      variant='outlined'
                      fullWidth
                      size='small'
                      inputProps={{ maxLength: 10 }}
                      placeholder='Service'
                      name='service_order_number'
                      component={Text}
                      endAdornment={<SearchIcon />}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      color='primary'
                      type='submit'
                      disabled={!props.dirty || !props.isValid}
                    >
                      ADICIONAR PARA A MINUTA
                    </Button>
                  </Grid>
                </Grid> */}

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Table
                      headers={columns}
                      emptyMessage='Nenhum registro encontrado.'
                      loading={loadingValidOutObjectsBatch}
                      data={validOutObjectsBatch}
                    >
                      {validOutObjectsBatch?.valid_out_objects?.map((validOutObjectBatch) => (
                        <TableRow key={validOutObjectBatch?.id}>
                          {console.log('Batch: ', validOutObjectBatch)}
                          <TableCell>{validOutObjectBatch?.service?.number}</TableCell>
                          <TableCell>{validOutObjectBatch?.service?.client?.name}</TableCell>
                          <TableCell>
                            {validOutObjectBatch?.sigep_range_object?.MailPrefix?.initials}
                            {validOutObjectBatch?.sigep_range_object?.range_position_number}
                            {validOutObjectBatch?.prefix_country}
                          </TableCell>
                          <TableCell>
                            {validOutObjectBatch?.service?.included_invoices?.nfse_number ?? '-'}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={3}>
                    <Button
                      fullWidth
                      onClick={() => onSubmitHandler(contract, validOutObjectsBatch?.id)}
                      disabled={
                        validOutObjectsBatch?.valid_out_objects?.length === undefined ||
                        validOutObjectsBatch?.valid_out_objects?.length <= 0
                          ? true
                          : false
                      }
                    >
                      VALIDAR LOTE
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
