import React from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Photo from '@material-ui/icons/Photo';

import { FileUploader } from 'components/FileUploader';
import { Field, useFormikContext } from 'formik';

import { useStyles } from './styles';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const AttachVoucher = ({ open, handleClose }) => {
  if (!open) return null;

  const { setFieldValue, values } = useFormikContext();
  const classes = useStyles();

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const addVoucher = async () => {
    const voucher = await convertToBase64(values.voucher);
    if (values.voucher) {
      console.log('VOUCHER: ', values.voucher);
      setFieldValue('voucher', voucher);

      toast.success('Comprovante adicionado com sucesso.');
    } else {
      toast.error('Erro ao adicionar o comprovante.');
      console.error('err comprovante');
    }
  };

  return (
    <>
      <Dialog
        maxWidth='sm'
        fullWidth={true}
        scroll='paper'
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        {/* <button onClick={() => console.log(values.voucher)}>ESTE ANEXO </button> */}
        <DialogTitle onClose={handleClose}>Anexar comprovante</DialogTitle>
        <DialogContent>
          {!values.voucher ? (
            <Field
              name='voucher'
              value={values.voucher}
              label='Comprovante'
              helperText='A imagem deve possuir uma boa qualidade.'
              onRemoveFile={console.log('TESTE REMOVE')}
              component={FileUploader}
              handleClean={'Aqui veio'}
            />
          ) : (
            <>
              <Field
                name='voucher'
                value={values.voucher}
                label='Comprovante'
                helperText='A imagem deve possuir uma boa qualidade.'
                component={FileUploader}
              />
              <div className={classes.photoImgContainer}>
                <Chip
                  className={classes.photoTag}
                  icon={<Photo />}
                  label={Date.now() + '_comprovante_anexado'}
                  variant='outlined'
                  textColor
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          {!values.voucher ? (
            <Button
              autoFocus
              onClick={() => {
                handleClose();
              }}
              color='primary'
            >
              Fechar
            </Button>
          ) : (
            <Button
              autoFocus
              onClick={() => {
                addVoucher();
                handleClose();
              }}
              color='primary'
            >
              Confirmar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
