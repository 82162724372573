/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import {
  Box,
  Typography,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Paper,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  Button,
  MobileStepper,
} from '@material-ui/core';
import { KeyboardArrowLeft } from '@material-ui/icons';

import { useMutation, useQuery } from '@apollo/client';
import { useGraphQL } from 'hooks/useGraphQL';
import {
  CREATE_COSMETIC_SREENING_RESPONSE,
  LIST_COSMETIC_SCREENING_QUESTIONS,
} from 'services/graphql/query/cosmetic-screening';
import { EXIST_FUNTIONAL_DEFECT } from 'services/graphql/query/functional-screening';

import { MoreInfoDialog } from './CosmeticResultsDialog';

export function CosmeticQuestions({ handleReset, sinister }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [gradeResult, setGradeResult] = useState();

  const { data, loading } = useGraphQL(LIST_COSMETIC_SCREENING_QUESTIONS);

  const funtionalDefect = useQuery(EXIST_FUNTIONAL_DEFECT, {
    variables: { sinister: sinister },
    fetchPolicy: 'network-only',
  });

  const [submitResponses] = useMutation(CREATE_COSMETIC_SREENING_RESPONSE, {
    onCompleted: (data) => {
      toast.success('Triagem cosmética salva com sucesso');
      setGradeResult(parseInt(data?.submitCosmeticScreeningResponses));
      handleOpenDialog();
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar a triagem cosmética!';
      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  if (loading || funtionalDefect.loading) {
    return <CircularProgress />;
  }

  const questions = data.cosmeticScreeningQuestions;
  const totalSteps = questions.length;

  const handleAnswer = (questionId, answer) => {
    setAnswers((prevAnswers) => {
      const existingAnswerIndex = prevAnswers.findIndex((ans) => ans.questionId === questionId);
      const newAnswers = [...prevAnswers];
      if (existingAnswerIndex > -1) {
        newAnswers[existingAnswerIndex].answer = answer;
      } else {
        newAnswers.push({ questionId, answer });
      }
      return newAnswers;
    });

    if (activeStep === totalSteps - 1) {
      setIsLoading(true);
      handleFinish(questionId, answer);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSelectedAnswer(null);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setSelectedAnswer(null);
  };

  const handleFinish = async (questionId, answer) => {
    try {
      const formattedAnswers = answers.map((item) => ({
        questionId: parseInt(item.questionId),
        grade: parseInt(item.answer[0]),
      }));

      await submitResponses({
        variables: {
          data: {
            responses: [
              ...formattedAnswers,
              { questionId: parseInt(questionId), grade: parseInt(answer) },
            ],
            sinister: sinister,
          },
        },
      });
    } catch (error) {
      console.error('Erro ao enviar as respostas:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentQuestion = questions[activeStep];

  const groupedQuestions = currentQuestion.listCosmeticScreeningQuestions.reduce(
    (acc, question) => {
      if (!acc[question.gradeId]) {
        acc[question.gradeId] = { gradeId: question.gradeId, descriptions: [] };
      }
      acc[question.gradeId].descriptions.push(question.description);
      return acc;
    },
    {},
  );

  const handleCardClick = (questionId, gradeId) => {
    setSelectedAnswer({ questionId, gradeId });
    handleAnswer(questionId, [gradeId]);
  };

  const isSelected = (gradeId) => {
    return selectedAnswer && selectedAnswer.gradeId === gradeId;
  };

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    handleReset();
    setIsOpenDialog(false);
  }

  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {questions.map((question) => (
          <Step key={question.id}>
            <StepLabel>{question.description}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Typography variant='h5'>Selecione a questão que mais se enquadra com o produto:</Typography>
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant='h6'>{currentQuestion.description}</Typography>

        {Object.keys(groupedQuestions).map((gradeId) => {
          const isLastStep = activeStep === totalSteps - 1;

          const isDisabled =
            isLastStep &&
            ((funtionalDefect.data?.existFuntionalDefect && gradeId === '1') ||
              (!funtionalDefect.data?.existFuntionalDefect && gradeId === '6'));

          return (
            <Box key={gradeId} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    variant='outlined'
                    style={{
                      borderColor: isSelected(gradeId) ? 'blue' : 'gray',
                      borderWidth: 2,
                      opacity: isDisabled ? 0.5 : 1, // Visually indicate that it's disabled
                      pointerEvents: isDisabled ? 'none' : 'auto', // Disable interaction if necessary
                    }}
                    onClick={() => !isDisabled && handleCardClick(currentQuestion.id, gradeId)}
                  >
                    <CardActionArea disabled={isDisabled}>
                      <CardContent>
                        <Typography>
                          {groupedQuestions[gradeId].descriptions.map((desc, index) => (
                            <span key={index}>
                              - {desc}
                              <br />
                            </span>
                          ))}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          );
        })}

        <MobileStepper
          variant='dots'
          steps={totalSteps}
          position='static'
          activeStep={activeStep}
          backButton={
            <Button size='small' onClick={handleBack} disabled={activeStep === 0}>
              <KeyboardArrowLeft />
              Voltar
            </Button>
          }
          nextButton={null}
        />
      </Paper>

      <MoreInfoDialog
        isOpen={isOpenDialog}
        handleClose={handleCloseDialog}
        sinister={sinister}
        gradeResult={gradeResult}
        reset={handleReset}
      />
    </Box>
  );
}
