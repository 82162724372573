import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/createComponentsImageImport.svg';
import { Button } from 'components';
import { Formik, Form } from 'formik';
import { UseDebounce } from 'hooks/useDebounce';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';
import { api } from 'services/api';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const ConvertManyXML = () => {
  const [importing, setImporting] = useState(false);
  const [initialValues, setValues] = useState();

  const { debounce } = UseDebounce(3000, false);

  const classes = useStyles();

  const [convertion, , loadingConvertion, refetchConvertion] = useQuery(
    () => invoiceService.listConvertedXmls(),
    [],
  );

  console.log('Convertion', convertion);

  useEffect(() => {}, [convertion]);

  const handleSheetDownload = async () => {
    refetchConvertion();
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX?.utils?.json_to_sheet(convertion));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    await XLSX?.writeFile(workbook, `conversaoXmls${now}.xlsx`);
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleTruncateTable = async () => {
    try {
      await invoiceService.truncateConvertedXmls();

      toast.success('Tabela limpada com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro a limpar a tabela.');
    }
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      toast.info('Iniciando importação...');

      const files = e.target.files;

      let xmlsArray = [];

      const reader = new FileReader();

      const readFile = (index) => {
        if (index >= files.length) return;
        let file = files[index];

        reader.onload = async (event) => {
          const result = event.target.result;
          console.log('RESULTADOS: ', result);

          api
            .post('/invoices/convert/many/xml', result, {
              headers: {
                'Content-Type': 'application/xml',
              },
            })
            .then((response) => {
              const { data } = response;

              console.log('Data: ', data);

              const { newProvider, ...importValues } = data;

              setValues({
                ...initialValues,

                ...importValues,
              });

              if (newProvider) {
                refetchProviders();
              }
            });

          xmlsArray.push(result);

          console.log('XMLS: ', xmlsArray);
          readFile(index + 1);
        };

        debounce(() => {
          toast.success('Importação Finalizada');
        }, []);

        reader.readAsBinaryString(file);
      };

      readFile(0);
      refetchConvertion();

      // reader.readAsBinaryString(files);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xml'
        multiple
        onChange={handleFileChange}
        accept='text/xml'
        hidden
      />

      <PageTitle>Converter XML para Excel</PageTitle>
      <Formik
        initialValues={{
          service_order_number: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Paper className={classes.gridPaper} variant='outlined'>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      onClick={handleImportClick}
                      type=''
                      disabled={importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Importar XML
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      variant='outlined'
                      startIcon={<DownloadIcon />}
                      onClick={handleSheetDownload}
                      disabled={importing}
                    >
                      Baixar planilha
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      onClick={handleTruncateTable}
                      type=''
                      disabled={importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Limpar dados da tabela
                    </Button>
                  </Grid>

                  {importing ? (
                    <Grid xs={6} lg={12} className={classes.loading}>
                      <Box sx={{ width: '90%' }}>
                        <LinearProgress color='secondary' />
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                        </Grid>
                        <UploadImg className={classes.imgTracker} />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid className={classes.gridImages} xs={6} lg={12}>
                      {' '}
                      <UploadImg className={classes.imgTracker} />
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}></Grid>
                <Grid item xs={12} lg={3}></Grid>

                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Atenção! A consulta pode demorar alguns minutos.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
