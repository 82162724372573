import { gql } from '@apollo/client';

export const LIST_COSMETIC_SCREENING_QUESTIONS = gql`
  query {
    cosmeticScreeningQuestions {
      id
      description
      __typename @skip(if: true)
      listCosmeticScreeningQuestions {
        id
        description
        cosmeticScreeningQuestionsId
        gradeId
        __typename @skip(if: true)
      }
    }
  }
`;

export const CREATE_COSMETIC_SREENING_RESPONSE = gql`
  mutation SubmitCosmeticScreeningResponses($data: CreateCosmeticScreeningResponsesInput!) {
    submitCosmeticScreeningResponses(data: $data)
  }
`;

export const LIST_COSMETIC_SCREENING_RESPONSES = gql`
  query ListCosmeticScreeningReponses($limit: Int, $offset: Int, $sinister: String!) {
    listCosmeticScreeningReponses(limit: $limit, offset: $offset, sinister: $sinister) {
      totalCount
      responses {
        id
        question
        gradeId
        createdAt
        user {
          name
        }
      }
    }
  }
`;
