import React from 'react';
import { useRouteMatch } from 'react-router';

import { AssurantMinute } from 'pages/Private/Expedition/AssurantMinute';
import { EmissionInvoice } from 'pages/Private/Expedition/EmissionInvoice';
import { MinuteBatch } from 'pages/Private/Expedition/MinuteBatch';
import { PreExpedition } from 'pages/Private/Expedition/PreExpedition';
import { Protocol } from 'pages/Private/Expedition/Protocol';
import { ReflectInvoice } from 'pages/Private/Expedition/ReflectInvoice';
import { ReimpressMinuteBatch } from 'pages/Private/Expedition/ReimpressMinuteBatch';
import { ReprintDanfe } from 'pages/Private/Expedition/ReprintDanfe';
import { ReprintInvoicePostCard } from 'pages/Private/Expedition/ReprintInvoicePostCard';
import { Revenue } from 'pages/Private/Expedition/Revenue';

import { Route } from '../Route';

export const ExpeditionRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}`} exact component={Protocol} />
      <Route isPrivate path={`${url}/revenue`} component={Revenue} />
      <Route isPrivate path={`${url}/pre-expedition`} component={PreExpedition} />
      <Route isPrivate path={`${url}/reflect-invoice`} component={ReflectInvoice} />
      <Route isPrivate path={`${url}/emission-invoice`} component={EmissionInvoice} />
      <Route isPrivate path={`${url}/minute-batch`} exact component={MinuteBatch} />
      <Route
        isPrivate
        path={`${url}/reprint_minute-batch`}
        exact
        component={ReimpressMinuteBatch}
      />
      <Route isPrivate path={`${url}/reprint-danfe`} exact component={ReprintDanfe} />
      <Route isPrivate path={`${url}/reprint-post-card`} exact component={ReprintInvoicePostCard} />
      <Route isPrivate path={`${url}/assurant-minute`} exact component={AssurantMinute} />
    </>
  );
};
