/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line

import React, { useContext, useMemo } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { FormatDate } from 'components';
import { ServiceOrderContext } from 'pages/Private/ServiceOrder/Consult';

import { useStyles } from './styles';

export default function DenseTable() {
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGspn, serviceFromGaia } = serviceData;

  console.log('serviceFromGaia: ', serviceFromGaia);
  console.log('serviceFromGaia Status: ', serviceFromGaia?.status?.description);

  const classes = useStyles();

  const existsInfo = useMemo(() => {
    if (!serviceData.length && serviceData.length === 0) {
      return <></>;
    } else {
      return (
        <TableContainer>
          <Table className={classes.table} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell align='justify'>Tipo Informação</TableCell>
                <TableCell align='justify'>Informação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align='justify'>IMEI:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.imei}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>IMEI 2:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.imei2}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>IMEI1 OUT:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.imei1_out}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>IMEI2 OUT:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.imei2_out}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>SERIAL:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.serial_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>SERIAL NOVO:</TableCell>
                <TableCell align='justify'>{serviceFromGaia?.serial_new}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>Data de compra: </TableCell>
                <TableCell align='justify'>
                  <FormatDate format='dd/MM/yyyy - HH:mm:ss'>
                    {serviceFromGaia?.purchase_date}
                  </FormatDate>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>Data de criação: </TableCell>
                <TableCell align='justify'>
                  <FormatDate format='dd/MM/yyyy - HH:mm:ss'>
                    {serviceFromGaia?.create_date}
                  </FormatDate>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>Condição do aparelho: </TableCell>
                <TableCell align='justify'>{serviceFromGaia?.device_defect}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align='justify'>Etapa: </TableCell>
                <TableCell align='justify'>{serviceFromGaia?.status?.description}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }, [
    classes.table,
    serviceData,
    serviceFromGaia?.imei,
    serviceFromGaia?.imei2,
    serviceFromGaia?.imei1_out,
    serviceFromGaia?.imei2_out,
    serviceFromGaia?.serial_number,
    serviceFromGaia?.create_date,
    serviceFromGaia?.purchase_date,
    serviceFromGaia?.device_defect,
    serviceFromGaia?.status?.description,
  ]);

  return existsInfo;
}
