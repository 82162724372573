import React from 'react';
import { useState } from 'react';

import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do Jornada_oracle',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportPO({ handleImportClick, loading }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  return (
    <>
      <Button
        fullWidth
        color='primary'
        variant='outlined'
        onClick={() => handleOpenDialog()}
        loading={loading}
      >
        Importar Planilha da PO
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
