import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Select, Text, Table } from 'components';
import { Field, Formik, Form, useFormikContext } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { mailServices } from 'services/mail';
import { useContextSelector } from 'use-context-selector';

import { RegisterSigepDialog } from './RegisterSigepDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Contrato', width: 190 },
  { label: 'Prefixo', align: 'center' },
  { label: 'Posição do objeto', width: 190, align: 'center' },
  { label: 'Cartão postagem', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

const RegisterSigep = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [sigep, setSigep] = useState({});
  const [sigepData, setSigepData] = useState([]); // Salvando o estado da requisição fora do estado da requisição objeto

  const [mailContractsData, , loadingMailContracts] = useQuery(mailServices.listMailContracts, []);

  const [mailMethodsData, , loadingMailMethods] = useQuery(mailServices.listMailMethods, []);

  const [mailPrefixesData, , loadingMailPrefixes] = useQuery(mailServices.listMailPrefixes, []);

  const [sigepRangeObjects, , loadingSigepRangeObjects, refetchSigepObjects] = useQuery(
    mailServices.listSigepRangeObjects,
    [],
  );

  console.log('sigepRangeObjects: ', sigepRangeObjects);

  //depois passei o estado dentro do useEffect
  useEffect(() => {
    setSigepData(sigepRangeObjects);
    console.log('sigepRangeObjects: ', sigepRangeObjects);
  }, [sigepRangeObjects]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setSigep(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await mailServices.createSigepRange(values);
      resetForm();

      refetchSigepObjects();
      toast.success('Sigep Range Cadastrado');
    } catch (error) {
      console.log(error);
      toast.error('error');
    } finally {
      setSubmitting(false);
    }
  };

  const mailContractOptions = (mailContractsData || []).map((mailContract) => {
    return {
      value: mailContract.id,
      label:
        mailContract.contract_number +
        ' ' +
        mailContract.contract_method +
        ' ' +
        mailContract.additional_information,
    };
  });

  const mailMethodOptions = (mailMethodsData || []).map((mailMethod) => {
    return {
      value: mailMethod.id,
      label: mailMethod.initials,
    };
  });

  const mailPrefixOptions = (mailPrefixesData || []).map((mailPrefix) => {
    return {
      value: mailPrefix.id,
      label: mailPrefix.initials,
    };
  });

  const HandleReset = () => {
    const { resetForm } = useFormikContext();

    return (
      <Button fullWidth type='reset' variant='outlined' onClick={resetForm}>
        Limpar
      </Button>
    );
  };

  return (
    <>
      <PageTitle>Range SIGEP</PageTitle>
      <Formik
        initialValues={{
          mail_contract_id: 0,
          mail_method_id: 0,
          mail_prefix_id: 0,
          initial_range: undefined,
          final_range: undefined,
          post_card: '',
        }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant='h6' gutterBottom>
                    Informações iniciais
                  </Typography>
                  <Field
                    size='small'
                    fullWidth
                    name='mail_contract_id'
                    variant='outlined'
                    label='Contrato'
                    options={mailContractOptions}
                    loading={loadingMailContracts}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.containerTitle} variant='h6' gutterBottom>
                    Dados do Range
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    fullWidth
                    name='mail_prefix_id'
                    variant='outlined'
                    label='Prefixo'
                    options={mailPrefixOptions}
                    loading={loadingMailPrefixes}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    defaultValue={1}
                    label='Faixa Inicial'
                    name='initial_range'
                    inputProps={{ minLength: 8, maxLength: 8 }}
                    mask='number'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Faixa Final'
                    name='final_range'
                    inputProps={{ minLength: 8, maxLength: 8 }}
                    mask='number'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.containerTitle} variant='h6' gutterBottom>
                    Cartão
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Cartão Postagem'
                    name='post_card'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={2}>
                  <Button
                    fullWidth
                    color='primary'
                    type='submit'
                    disabled={!props.dirty || !props.isValid}
                  >
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} lg={2}>
                  <HandleReset />
                </Grid>
                <Grid item xs={12}>
                  <Table
                    headers={columns}
                    // maxHeight={450}
                    striped
                    size='small'
                    loading={loadingSigepRangeObjects}
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    emptyMessage='Nenhum registro encontrado.'
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                    data={sigepRangeObjects}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {sigepData?.map((service) => (
                      <TableRow key={service.id}>
                        <TableCell>{service.mailContract?.contract_method}</TableCell>
                        <TableCell align='center'>{service.MailPrefix?.initials}</TableCell>
                        <TableCell align='center'>{service.range_position_number}</TableCell>
                        <TableCell align='center'>{service.post_card}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, service)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <RegisterSigepDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  mailContractOptions={mailContractOptions}
                  mailPrefixOptions={mailPrefixOptions}
                  sigep={sigep}
                  reloadSigep={refetchSigepObjects}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default RegisterSigep;
