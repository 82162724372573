export const addToCartAction = (accessory, dispatch) => {
  dispatch({ type: '@ADD_TO_CART', accessory });
};

export const removeFromCartAction = (accessory, dispatch) => {
  dispatch({ type: '@REMOVE_FROM_CART', accessory });
};

export const updateAmountAccessoryAction = (amountAndAcessoryId, dispatch) => {
  dispatch({ type: '@UPDATE_AMOUNT', amountAndAcessoryId });
};

export const cleanCartAction = (dispatch) => {
  dispatch({ type: '@CLEAN_CART', cartCleaned: [] });
};
