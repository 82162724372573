import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    imgContainer: {
      display: 'block',
      width: '100%',
      maxWidth: '320px',
      margin: 'auto',
      paddingTop: '60px',
    },
    gridSendBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    photoImgContainer: {
      position: 'relative',
    },
    photoImg: {
      width: '100%',
      height: '100%',
    },
    photoTag: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
      backgroundColor: '#fff',
    },
  };
});
