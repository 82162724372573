import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { CustomAutocomplete, Dialog, Table } from 'components';
import { Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  part_number: yup.object().required(),
});

const columns = [
  { label: 'Part Number Pai' },
  { label: 'Descrição do Part Number Pai', align: 'center' },
  { label: 'Part Number Filho', align: 'center' },
  { label: 'Descrição do Part Number Filho', align: 'center' },
];

export const ComponentAlternateModelDialog = ({
  open,
  handleClose,
  componentAlternate = {},
  reloadComponentAlternate,
}) => {
  console.log('Componente doido: ', componentAlternate);

  const classes = useStyles();

  const [search, setSearch] = useState('');

  const [components, , loadingComponents] = useQuery(
    () => componentService.listComponentsWhereDoNotRepeatPn(1, 10, search),
    [search],
  );

  const componentOptions = (components || []).map((component) => ({
    value: component.part_number,
    label: component.description,
  }));

  const [
    listComponentsAlternates,
    ,
    loadingListComponentsAlternates,
    refetchListComponentsAlternates,
  ] = useQuery(
    () => componentService.getVincComponentsAlternate(componentAlternate?.id),
    [componentAlternate],
  );

  console.log('listComponentsAlternates: ', listComponentsAlternates);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('VALUES: ', values);

      console.log('Get Value: ', values.part_number.value);
      console.log('Get Label: ', values.part_number.label);

      const part_number = values.part_number.value;
      const description = values.part_number.label;
      await componentService.createVincComponentsAlternate(
        componentAlternate.id,
        part_number,
        description,
      );

      refetchListComponentsAlternates();
      toast.success('Alternate vinculado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível vincular o alternate.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Componente: {componentAlternate?.description}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ description: componentAlternate.description }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12} md={10} lg={10} xl={10}>
                    <CustomAutocomplete
                      name='part_number'
                      label='Selecione o Part Number alternate'
                      filteredValues={componentOptions}
                      onChange={(value) => setSearch(value)}
                    />
                  </Grid>
                  <Grid item xs={12} md={2} lg={2} xl={2}>
                    <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Table
                      headers={columns}
                      striped
                      emptyMessage='Nenhum serviço encontrado.'
                      loading={loadingListComponentsAlternates}
                      disableNumeration
                    >
                      {listComponentsAlternates?.map((componentAlternate) => (
                        <TableRow key={componentAlternate.id}>
                          <TableCell>
                            {componentAlternate.vinc_part_number_component_main}
                          </TableCell>
                          <TableCell align='center'>
                            {componentAlternate.vinc_description_component_main}
                          </TableCell>
                          <TableCell>{componentAlternate.vinc_part_number_component_sub}</TableCell>
                          <TableCell align='center'>
                            {componentAlternate.vinc_description_component_sub}
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
