import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';
import XLSX from 'xlsx';

export const SacReport = () => {
  const [qualityData, setQuality] = useState([]);
  const [shippingData, setShipping] = useState([]);

  const [qualityReport, , loadingQualityDetails, refetchQualityDataDate] = useQuery(
    () => serviceOrderService.listSacQualityAssuranceReport(),
    [],
  );

  const [shippingReport, , loadingShippingDetails, refetchShippingDataDate] = useQuery(
    () => serviceOrderService.listSacShippingReport(),
    [],
  );

  useEffect(() => {
    setQuality(qualityReport);
  }, [qualityReport]);

  useEffect(() => {
    setShipping(shippingReport);
  }, [shippingReport]);

  const handleDownloadSacReport = async () => {
    const now = new Date();
    const worksheet1 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(qualityData));
    const worksheet2 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(shippingData));

    const workbook = (XLSX.WorkBook = {
      Sheets: {
        QA: worksheet1,
        Expedicao: worksheet2,
      },
      SheetNames: ['QA', 'Expedicao'],
    });
    XLSX.writeFile(workbook, `RelatorioSAC${now}.xlsx`);

    refetchQualityDataDate();
    refetchShippingDataDate();
  };

  return (
    <>
      <PageTitle>Relatório etapas SAC</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            loading={loadingShippingDetails && loadingQualityDetails}
            fullWidth
            onClick={handleDownloadSacReport}
          >
            exportar relatório
          </Button>
        </Grid>
      </Grid>
      <Spacer size={15} />
    </>
  );
};
