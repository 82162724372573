import { React, useState } from 'react';
import { useEffect } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Select from '@mui/material/Select';
import { FormatMoney, Table } from 'components';
import { useFormikContext } from 'formik';
import { UseDebounce } from 'hooks/useDebounce';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

const columns = [
  {
    label: 'Produto',
    align: 'justify',
  },
  {
    label: 'Descrição',
    align: 'justify',
  },
  {
    label: 'Quantidade',
    align: 'justify',
  },
  {
    label: 'Valor',
    align: 'center',
    width: 100,
  },
  {
    label: 'Local Estoque',
    align: 'center',
    width: 120,
  },
];

export const TableRegister = () => {
  const { debounce } = UseDebounce();

  const { values, setFieldValue } = useFormikContext();
  const { invoice_items, component_level_location_id } = values;

  const [filteredLocations, setFilteredLocations] = useState([]);

  const [stockLocations, , loadingStockLocations, stockLocationsRefetch] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(component_level_location_id),
    [component_level_location_id],
  );

  useEffect(() => {
    setFilteredLocations([]);
    handleFilterLocationsByInvoiceItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component_level_location_id]);

  console.log('stockLocations: ', stockLocations);

  const handleFilterLocationsByInvoiceItems = async () => {
    await invoice_items?.map(async (item) => {
      const stockLocationsFilter =
        await componentService.listStockLocationsByLevelLocationAndPartNumber(
          parseInt(component_level_location_id),
        );

      if (stockLocationsFilter.data[0]) {
        stockLocationsFilter.data[0].part_number = item?.part_number;
        setFilteredLocations((oldState) => [...oldState, stockLocationsFilter?.data[0]]);
      }
    });
  };

  const handleFillIStockLocation = (cprod, local_id) => {
    const indexObjectRegister = invoice_items.findIndex((item) => {
      return item.cprod === cprod;
    });

    if (indexObjectRegister > -1) {
      invoice_items[indexObjectRegister].cprod = cprod;
      invoice_items[indexObjectRegister].location_id = local_id;
      setFieldValue('component_level_location_id', component_level_location_id);
    }
  };

  return (
    <>
      <Table
        headers={columns}
        emptyMessage='Nenhum registro encontrado.'
        loading={loadingStockLocations}
      >
        {invoice_items.map((item) => (
          <TableRow key={item.cprod}>
            <TableCell>{item.part_number}</TableCell>
            <TableCell>{item.description}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell align='right'>
              <FormatMoney>{item.purchase_price}</FormatMoney>
            </TableCell>
            <TableCell>
              <FormControl variant='filled' sx={{ m: 1, minWidth: 120 }}>
                <Select
                  labelId='demo-simple-select-filled-label'
                  id='demo-simple-select-filled'
                  fullWidth
                >
                  {
                    filteredLocations?.filter((e) => e.part_number === item.part_number)?.length > 0
                      ? filteredLocations
                          ?.filter?.((e) => {
                            if (e.part_number === item?.part_number) return e;
                          })
                          .map((location) => (
                            <MenuItem
                              onBlur={() => handleFillIStockLocation(item?.cprod, location?.id)}
                              key={location?.id}
                              value={location?.id}
                            >
                              {component_level_location_id === '' ? null : location?.location_name}
                            </MenuItem>
                          ))
                      : stockLocations?.map((location) => (
                          <MenuItem
                            onBlur={() => handleFillIStockLocation(item?.cprod, location?.id)}
                            key={location?.id}
                            value={location?.id}
                          >
                            {component_level_location_id === '' ? null : location?.location_name}
                          </MenuItem>
                        ))
                    // : stockLocations?.map((location) => (
                    //     <MenuItem
                    //       onBlur={() => handleFillIStockLocation(item.cprod, location.id)}
                    //       key={location.id}
                    //       value={location.id}
                    //     >
                    //       {console.log('Caso Normal')}
                    //       {component_level_location_id === '' ? null : location.location_name}
                    //     </MenuItem>
                  }
                </Select>
              </FormControl>
            </TableCell>
          </TableRow>
        ))}
      </Table>

      {!values.PONo == '' ? (
        <Grid xs={12} lg={12}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress color='secondary' />
            <Grid xs={6} lg={12}>
              {''}
            </Grid>
          </Box>
        </Grid>
      ) : (
        <>
          <Grid xs={6} lg={12}></Grid>
          <Typography>NÃO IMPORTANDO NADA NO MOMENTO..</Typography>
        </>
      )}
    </>
  );
};
