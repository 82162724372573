import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';

import { FunctionalScreeningContext } from '..';

import { UPDATE_SERVICE_ORDER_IMEI } from 'services/graphql/query/service-orders';

export function ImeiForm() {
  const { sinister, imei, navigationStep, reset } = useContext(FunctionalScreeningContext);

  const [updateImei] = useMutation(UPDATE_SERVICE_ORDER_IMEI, {
    onCompleted: () => {
      toast.success('IMEI atualizado com sucesso');
      reset();
      navigationStep(2);
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar o IMEI novo';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    try {
      await updateImei({
        variables: {
          data: { ...values, sinister: sinister },
        },
      });
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={{ currentImei: imei, newImei: '' }} onSubmit={handleSubmit}>
      {(props) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} xl={6}>
              <Field
                name='currentImei'
                label='IMEI/Serial atual'
                size='small'
                variant='outlined'
                fullWidth
                component={Text}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} xl={6}>
              <Field
                name='newImei'
                label='IMEI/Serial novo'
                size='small'
                variant='outlined'
                fullWidth
                component={Text}
              />
            </Grid>
            <Grid item xs={12} xl={12}>
              <Button
                fullWidth
                type='submit'
                color='primary'
                disabled={!props.dirty || !props.isValid || props.isSubmitting}
              >
                Salvar Alterações e Continuar
              </Button>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Button
                fullWidth
                color='secondary'
                variant='outlined'
                disabled={props.isSubmitting}
                onClick={() => navigationStep(0)}
              >
                Voltar
              </Button>
            </Grid>
            <Grid item xs={12} xl={6}>
              <Button
                fullWidth
                color='primary'
                variant='outlined'
                disabled={props.isSubmitting}
                onClick={() => navigationStep(2)}
              >
                Continuar
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
