import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/styles/makeStyles';

import { Text, FormatDate, Table } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: '5px',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export const ConsultToDoConference = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const [todoData, , loadingTodo, refetchTodo] = useQuery(
    () => invoiceService.listtodoConference(),
    [],
  );

  console.log('todoData', todoData);

  const filteredObjects = (todoData || []).filter((object) => {
    const loweredSearch = search.toLowerCase();

    return (
      object.nnf.toLowerCase().includes(loweredSearch) ||
      object.created_at.toLowerCase().includes(loweredSearch) ||
      object.total.toLowerCase().includes(loweredSearch)
    );
  });

  // useEffect(() => {
  //   setMailObjects(todoData);
  // }, [todoData]);

  const columns = [
    { label: 'N° Nota' },
    { label: 'Quantidade de itens' },
    { label: 'Data importação' },
    { label: 'Data recebimento' },
    { label: 'Nome Cliente' },
  ];

  return (
    <>
      <PageTitle>Notas aguardando conferência </PageTitle>

      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar NF'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            title='Lista de Notas'
            headers={columns}
            loading={loadingTodo}
            maxHeight={300}
            emptyMessage='Nenhum registro encontrado.'
            striped
          >
            {filteredObjects?.map((object) => (
              <TableRow key={object?.id}>
                {console.log('object: ', object)}
                <TableCell>{object?.nnf}</TableCell>
                <TableCell>{object?.total}</TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.created_at}</FormatDate>
                </TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.receive_date}</FormatDate>
                </TableCell>
                <TableCell>{object?.company_name}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
