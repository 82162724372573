import { api } from 'services/api';

export class MailObjectService {
  async listMailObjects(startIndex, rowsPerPage, search) {
    return api.get('/mail-objects', {
      params: {
        startIndex,
        rowsPerPage,
        search,
      },
    });
  }

  async listInsuranceCompanies() {
    return api.get('/mail-objects/insurance-companies');
  }

  async listInsuranceReport() {
    return api.get('/mail-objects/insurance-report');
  }

  async listTotalObbjectDaily() {
    return api.get('/mail-objects/total-daily');
  }

  async impressMinute(service_order_number) {
    return api.get(`/mail-objects/${service_order_number}/impressMinute`, {
      responseType: 'arraybuffer',
    });
  }

  async impressMinuteManual(service_order_number) {
    return api.get(`/mail-objects/${service_order_number}/impressMinuteManual`, {
      responseType: 'arraybuffer',
    });
  }

  async impressMinuteManualswap(service_order_number) {
    return api.get(`/mail-objects/${service_order_number}/impressMinuteManualswap`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadComponentsTags(invoiceId) {
    return api.get(`/invoices/${invoiceId}/components-tags`, {
      responseType: 'arraybuffer',
    });
  }

  async getMailObjectBySinister(search_website_value) {
    return api.get(`/mail-objects/sinister/${search_website_value}`);
  }

  async getMailObjectByCode(code) {
    return api.get(`/mail-objects/code/${code}`);
  }

  async addPhotos(mail_object_id, data) {
    return api.put(`/mail-objects/photos/${mail_object_id}`, data);
  }

  async updateInsuranceReport(service_order_number, data) {
    return api.put(`/mail-objects/insurance-report/${service_order_number}`, data);
  }

  async createDownloadFiles(url) {
    return api.post(`/mail-objects/download-files`, { url });
  }

  async getDownloadFiles(url) {
    return api.post(`/mail-objects/get-download-files`, { url });
  }

  // MINUTE
  async createMinute(service_orders) {
    return api.post(`/mail-objects/minute`, { service_orders: service_orders });
  }

  async createMinuteTag(minute_header_id) {
    return api.post(
      `/mail-objects/minute/tag`,
      { minute_header_id: minute_header_id },
      { responseType: 'arraybuffer' },
    );
  }
}

export const mailObjectService = new MailObjectService();
