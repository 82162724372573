import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { mailServices } from 'services/mail';

const columns = [
  { label: 'Lote nº' },
  { label: 'Contrato' },
  { label: 'Ordens de serviço' },
  { label: 'Reimprimir' },
];

export const ReimpressMinuteBatch = () => {
  const [validOutObjectsBatch, , loadingValidOutObjectsBatch, refetchValidOutObjectsBatch] =
    useQuery(() => mailServices.listValidOutObjectsBatchDone(), []);

  console.log('validOutObjectsBatch: ', validOutObjectsBatch);

  const handleDownloadTagsClick = async (id) => {
    const { data } = await mailServices.impressMinuteBatch(id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${id}.pdf`);
  };

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log(values);
    handleDownloadTagsClick(values.id);
    setTimeout(() => {
      setSubmitting(false);
      toast.success('Reimpressão realizada com sucesso.');
    }, 2000);
  };

  return (
    <>
      <PageTitle>Reimpressão de Lote de Minuta</PageTitle>
      <Formik initialValues={{ id: '' }} onSubmit={onSubmitHandler}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Número do lote de minuta'
                  name='id'
                  variant='outlined'
                  size='small'
                  // inputProps={{ maxLength: 10, minlength: 10 }}
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
            {/*
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Table
                  headers={columns}
                  actionsLabel={<VisibilityIcon />}
                  emptyMessage='Nenhum registro encontrado.'
                  onActionsClick={handleOpenInformation}
                  loading={loadingValidOutObjectsBatch}
                >
                  {validOutObjectsBatch?.map((validOutObject) => (
                    <TableRow key={validOutObject?.id}>
                      <TableCell>{validOutObject?.id}</TableCell>
                      <TableCell>{validOutObject?.mail_contracts?.contract_method}</TableCell>
                      <TableCell>
                        {validOutObject?.valid_out_objects?.map((item) => {
                          return `${item?.service?.number + ', '}`;
                        })}
                      </TableCell>
                      <TableCell>
                        {
                          validOutObject?.valid_out_objects?.[
                            validOutObject?.valid_out_objects?.length
                          ]?.service?.created_at
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid> */}
          </Form>
        )}
      </Formik>
    </>
  );
};
