import React, { useEffect } from 'react';

import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GraphicAging = () => {
  const [agingData, , loadingagingData, refetchAgingData] = useQuery(
    () => serviceOrderService.listAging(),
    [],
  );

  const getStep = (step) => {
    return agingData?.filter((item) => item.description === step)[0];
  };

  const seg = agingData?.map((age) => {
    return age.description;
  });

  console.log('step: ', getStep('PCP'));

  console.log('agingData', agingData);

  const data = [
    {
      name: 'PCP' + ' ' + getStep('PCP')?.total ?? 0,
      pv: getStep('PCP')?.total ?? 0,
    },
    {
      name: 'Reparo' + ' ' + getStep('Em reparo')?.total ?? 0,
      pv: getStep('Em reparo')?.total ?? 0,
    },
    {
      name: 'Orc' + ' ' + (getStep('Orçamento')?.total ?? 0),
      pv: getStep('Orçamento')?.total ?? 0,
    },
    {
      name: 'QA' + ' ' + getStep('Aguardando QA')?.total ?? 0,
      pv: getStep('Aguardando QA')?.total ?? 0,
    },
    {
      name: 'Check' + ' ' + getStep('Aguardando Check Kit')?.total ?? 0,
      pv: getStep('Aguardando Check Kit')?.total ?? 0,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchAgingData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchAgingData]);

  console.log(data);
  return (
    <ResponsiveContainer width='100%' height='90%'>
      <BarChart
        width={80}
        height={30}
        data={data}
        margin={{
          left: -25,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis />
        <Tooltip />

        <Bar barSize={30} dataKey='pv' stackId='a' fill='#b31237' />
      </BarChart>
    </ResponsiveContainer>
  );
};
