import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@mui/icons-material/Add';
import { Button, Dialog, Text } from 'components';
import { Mask } from 'components/Mask';
import { Form, Formik, Field, useFormikContext } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { browserHistory } from 'routes/browserHistory';
import { serviceOrderService } from 'services';
import { cashierService } from 'services/cashier';
import { useContextSelector } from 'use-context-selector';

import { validationSchema } from './formik';
import { useStyles } from './styles';

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const OrderDialog = ({ open, onClose, selectedComponent = {} }) => {
  const classes = useStyles();
  const user = useContextSelector(AuthContext, (context) => context.user);
  const [name, setName] = useState('');

  const { part_number, description, component_acessory_price } = selectedComponent;

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('cgc: ', values.cgc);
      console.log('Part Number: ', part_number);
      console.log('Quantidade: ', values.quantity);
      await cashierService.orderComponents(values.cgc, part_number, values.quantity);

      toast.success('Encomenda solicitada com sucesso.');
      resetForm();
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message || 'Erro ao realizar a encomenda do acessório.');
    } finally {
      setSubmitting(false);
    }
  };

  const ClientName = () => {
    const { setFieldValue } = useFormikContext();

    const handleCgcBlur = async (value) => {
      try {
        const data = await serviceOrderService.getClientByCgc(value.replace(/\D/g, ''));

        const { cgc, name } = data.data;

        console.log('DOCS: ', data.data);
        console.log('NAME: ', name);

        setFieldValue('client_name', name);
        if (cgc === undefined || cgc === '') {
          toast.error('Informe um CPF/CNPJ válido para prosseguir');
        }
      } catch (err) {
        toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
      } finally {
      }
    };

    return (
      <Field
        variant='outlined'
        label='CPF/CNPJ do cliente'
        name='cgc'
        size='small'
        component={Text}
        mask='document'
        onBlur={(e) => handleCgcBlur(e.target.value)}
      />
    );
  };

  const navigateToRegisterClient = () => {
    browserHistory.push('/cashier/register-client');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <Dialog.Title onClose={onClose}>Deseja confirmar a requisição de orçamento ?</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ cgc: '', client_name: '', quantity: 0 }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item md={5}>
                    <Typography>
                      <strong style={{ marginRight: '5px' }}>Part Number:</strong>
                      {part_number}
                    </Typography>
                  </Grid>
                  <Grid item md={7}>
                    <Typography>
                      <strong style={{ marginRight: '5px' }}>Descrição:</strong>
                      {description}
                    </Typography>
                  </Grid>
                  <Grid item md={7}>
                    <Typography>
                      <strong style={{ marginRight: '5px' }}>Preço(un):</strong>
                      <Mask type='money'>{component_acessory_price}</Mask>
                    </Typography>
                  </Grid>
                  <Grid item md={5}>
                    <Field
                      label='Quantidade'
                      name='quantity'
                      size='small'
                      variant='outlined'
                      type='number'
                      fullWidth
                      inputProps={{
                        min: 1,
                      }}
                      component={Text}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <ClientName />
                  </Grid>
                  <Grid item md={6}>
                    <Field
                      label='Nome do cliente'
                      size='small'
                      variant='outlined'
                      name='client_name'
                      component={Text}
                      onInput={toInputUppercase}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Button fullWidth variant='outlined' onClick={navigateToRegisterClient}>
                      <AddIcon /> Novo Cliente
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <Typography>
                      <strong style={{ marginRight: '5px' }}>Atendente responsavél: </strong>
                      {user.name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid className={classes.btnContainer} item xs={12}>
                  <Button onClick={onClose} variant='text' color='default'>
                    Cancelar
                  </Button>
                  <Button
                    disabled={!props.dirty || !props.isValid}
                    size='small'
                    type='submit'
                    onClose={onClose}
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
