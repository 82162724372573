import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, FileUploader, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { mailObjectService } from 'services/mailObject';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export function AttachReport() {
  const classes = useStyles();

  const [service, setService] = useState();
  const [sinister, setSinister] = useState();
  const [currentObject, setCurrentObject] = useState({});

  const handleSubmit = async (values) => {
    try {
      const { data } = await serviceOrderService.listServiceToAddReport(values);
      console.log(data);
      setService(values);
      setSinister(data);

      toast.success('Consultada realizada com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao consultar Service');
    }
  };

  const onSubmitHandler = async ({ attach_1 }, { setSubmitting, resetForm }) => {
    console.log('Attach 1 Comes: ', attach_1);
    try {
      const formData = new FormData();

      formData.append('attach_1', attach_1);

      const { data } = await mailObjectService.updateInsuranceReport(service, formData);

      setCurrentObject({
        ...currentObject,
        ...data,
      });

      setSinister(service);

      setService();
      resetForm();

      toast.success('Documento franquia adicionado com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao enviar arquivo.');
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Anexar laudo</PageTitle>
      <Formik
        initialValues={{ service_order_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Field
                  label='Service'
                  name='service_order_number'
                  variant='outlined'
                  size='small'
                  inputProps={{ maxLength: 50, minlength: 3 }}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Button
                  onClick={() => handleSubmit(props.values.service_order_number)}
                  disabled={!props.isValid || !props.dirty}
                  fullWidth
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {service ? (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      name='attach_1'
                      label='Anexo'
                      helperText='O anexo não deve exceder 2mb'
                      component={FileUploader}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} lg={6}>
                    <Button disabled={props.isSubmitting} type='submit' fullWidth>
                      Enviar
                    </Button>
                  </Grid>
                </>
              ) : (
                <>
                  <img className={classes.imgContainer} src={checkKitImg} />
                </>
              )}
            </Grid>

            <Grid container spacing={2}></Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
