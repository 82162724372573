import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Text, Table } from 'components';
import { Field, Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [{ label: 'Service' }, { label: 'Motivo' }];
const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

const motive = 'Solicitação Usuário';

export const RegisterReleaseWarranty = () => {
  const classes = useStyles();
  const [name, setName] = useState('');

  const [serviceOrderProcess, setServiceOrderProcess] = useState([]);

  const [serviceOrderProcessData, , loadingServiceOrderProcessData] = useQuery(
    () => api.get('/warranty/warranty-liberation'),
    [],
  );

  useEffect(() => {
    setServiceOrderProcess(serviceOrderProcessData);
  }, [serviceOrderProcessData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    console.log('VALUES: ', values);
    try {
      const { data } = await api.post('/warranty/warranty-liberation', values);

      setServiceOrderProcess((prevState) => [...prevState, data]);

      toast.success('Service liberada com sucesso');

      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao liberar service.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Liberar validação de garantia</PageTitle>
      <Formik
        initialValues={{ service_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='service_number'
                    label='Service'
                    variant='outlined'
                    size='small'
                    component={Text}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={4}>
                  <Field
                    name='motive'
                    label='Motivo'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid> */}
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Liberar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={300}
                    loading={loadingServiceOrderProcessData}
                  >
                    {serviceOrderProcess?.map((service) => (
                      <TableRow key={service.service_id}>
                        <TableCell>{service.service.number}</TableCell>
                        <TableCell>{motive}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default RegisterReleaseWarranty;
