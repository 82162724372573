import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  number: yup.string().required(),
  object_in: yup
    .string()
    .required(
      'É necessário informa o objeto de entrada',
      'Formato do código inválido.',
      (value) => {
        return value ? !!value.match(/^[A-Z]{2}[1-9]{9}[A-Z]{2}$/) : true;
      },
    ),
  model: yup.string().required(),
  service_part_number: yup.string().required(),
  imei: yup.string().required(),
  imei2: yup.string().required(),
  process_type_id: yup.number().required(),
  bounce: yup.boolean().required(),
  ean: yup.string().required(),
  add_info: yup.string().required(),
  serial_number: yup.string().required(),
  device_defect: yup.string().required(),
  create_date: yup.date().required(),
  fabrication_date: yup.date().required(),
  street: yup.string().required(),
  street_number: yup.string().required(),
  warranty_type: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  email: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  zipcode: yup.string().required(),
  color_product: yup.string().required(),
  complement: yup.string(),
  residential_phone_number: yup.string(),
  phone_number: yup.string().required(),

  cgc: yup
    .string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
  // ie: yup.string().when('cgc', {
  //   is: (cgc) => {
  //     return cgc?.length >= 12;
  //   },
  //   then: yup.string().required(),
  // }),
});

export const initialValues = {
  nf_type: 'E',
  accessories_id: [],
  object_in: '',
  number: '',
  service_part_number: '',
  bounce: null,
  ean: '',
  model: '',
  serial_number: '',
  process_type_id: 0,
  product_id: 0,
  status_description: '',
  imei: '',
  imei2: '',
  warranty_type: '',
  device_defect: '',
  device_accessory: '',
  create_date: new Date(),
  fabrication_date: new Date(),
  cgc: '',
  street: '',
  street_number: '',
  zipcode: '',
  name: '',
  residential_phone_number: '',
  phone_number: '',
  city: '',
  state: '',
  email: '',
  district: '',
  complement: '',
  observation: '',
  special_comment: '',
  service_order_found: false,
  color_product: '',
  sub_bpo_id: 0,
  resale_id: 0,
  checkHasNF: false,
  nf_key: null,
  insurance_wty: '',
  invalidEmail: false,
  //checksHome: false,
};
