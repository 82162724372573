export const addToCartAction = (accessory, dispatch) => {
  dispatch({ type: '@ADD_TO_CART', accessory });
};

export const removeFromCartAction = (accessory, dispatch) => {
  dispatch({ type: '@REMOVE_FROM_CART', accessory });
};

export const updateAmountAccessoryAction = (amountAndAcessoryId, dispatch) => {
  dispatch({ type: '@UPDATE_AMOUNT', amountAndAcessoryId });
};

export const addToCartWholesaleAction = (accessory, dispatch) => {
  dispatch({ type: '@ADD_TO_CART_WHOLESALE', accessory });
};

export const removeFromCarWholesaletAction = (accessory, dispatch) => {
  dispatch({ type: '@REMOVE_FROM_CART_WHOLESALE', accessory });
};

export const updateAmountAccessoryWholesaleAction = (amountAndAcessoryId, dispatch) => {
  dispatch({ type: '@UPDATE_AMOUNT_WHOLESALE', amountAndAcessoryId });
};

export const cleanCartAction = (dispatch) => {
  dispatch({ type: '@CLEAN_CART', cartCleaned: [] });
};

export const addLaborToCartAction = (labor, dispatch) => {
  dispatch({ type: '@ADD_LABOR_TO_CART', labor });
};

export const removeLaborFromCartAction = (labor, dispatch) => {
  dispatch({ type: '@REMOVE_LABOR_FROM_CART', labor });
};

export const updateAmountLaborAction = (amountAndLaborId, dispatch) => {
  dispatch({ type: '@UPDATE_LABOR_AMOUNT', amountAndLaborId });
};
