import { gql } from '@apollo/client';

export const STOCK_REPORT = gql`
  mutation {
    stockReport {
      Voucher
      IMEI
      SKU
      Modelo
      Local
      Cliente
      Loja
      Rede
      Tipo_de_Rede
      Lote
      Status_atual
      Condicao
      Triagem_funcional
      Grade
      Criado_em
      Atualizado_em
      Tela
      Laterais
      Traseira
      Defeitos_Adicionais
      Resultado_Triagem_Funcional
      Data_Recebimento
      Data_Funcional
      Data_Cosmetico
      Data_Laudo
      Data_Alocacao
      Data_Oracle
      Respostas_Funcional
      __typename @skip(if: true)
    }
  }
`;

export const ORACLE_REPORT = gql`
  mutation {
    oracleReport {
      Voucher
      IMEI
      SKU
      Grade
      Numero_do_Grade
      RI
      NF
      __typename @skip(if: true)
    }
  }
`;

export const CLOSING_ASSURANT_REPORT = gql`
  mutation ClosingAssurantReport($inputDate: String!, $outputDate: String!) {
    closingAssurantReport(inputDate: $inputDate, outputDate: $outputDate) {
      Voucher
      Loja
      Cod_Loja
      Estado_loja
      Vendedor
      Data_Trade_in
      SKU
      Marca
      Produto
      Condicao
      Valor
      NF_EMITIDA
      Cliente
      CPF_CNPJ
      Data_Triagem
      Resultado_Triagem_Funcional
      Laudo
      Respostas_Funcional
      __typename @skip(if: true)
    }
  }
`;
export const RECEIVING_REPORT = gql`
  mutation ReceivingReport($inputDate: String!, $outputDate: String!) {
    receivingReport(inputDate: $inputDate, outputDate: $outputDate) {
      Transportadora
      Placa
      Motorista
      CPF
      Romaneio
      Data_Recebimento
      Voucher
      __typename @skip(if: true)
    }
  }
`;

export const IN_OUT_REPORT = gql`
  mutation {
    inOutReport {
      Voucher
      Status_DE
      Status_PARA
      Usuario
      Data
      __typename @skip(if: true)
    }
  }
`;

export const B2C_ORDER_REPORT = gql`
  mutation {
    b2COrderReport {
      ID_Anymarket
      Codigo_Pedido
      Marketplace
      Status_Atual
      Forma_Entrega
      Cliente
      Data_Pedido
      Data_Atendimento
      Data_Expedicao
      SKU_Requisitado
      IMEI_Atendido
      SKU_Atendido
      Nota_Fiscal
      Chave_NF
      XML
      Minuta
      __typename @skip(if: true)
    }
  }
`;

export const B2C_PICKING_REPORT = gql`
  mutation {
    b2CPickingReport {
      ID_Anymarket
      Codigo_Pedido
      Marketplace
      Cliente
      Data_Pedido
      Status
      SKU_Requisitado
      Produto_Requisitado
      Voucher_Sugerido
      IMEI_Sugerido
      SKU_Sugerido
      Local
      Data_Recebimento
      ID_Movimentacao_Oracle
      __typename @skip(if: true)
    }
  }
`;
