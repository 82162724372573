import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Text, Table, FormatDate } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { RegisterDeviceDefectDialog } from './RegisterDeviceDefectDialog';
import { validationSchema } from './validation';

const columns = [
  { label: 'Sigla' },
  { label: 'Descrição' },
  { label: 'Status' },
  { label: 'Data de Criação' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const RegisterDeviceDefect = () => {
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();

    setDeviceDefect(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [search, setSearch] = useState('');

  const [devicesDefects, setDevicesDefects] = useState([]);
  const [deviceDefect, setDeviceDefect] = useState({});

  const [deviceData, , loadingDevice, refetchDevice] = useQuery(
    () => serviceOrderService.listDeviceDefects(),
    [],
  );

  useEffect(() => {
    setDevicesDefects(deviceData);
  }, [deviceData]);

  const formattedDevicesDefect = (deviceData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredDevicesDefect = formattedDevicesDefect.filter((device) => {
    const upperDescription = device.description?.toUpperCase();
    const lowerDescription = device.description?.toLowerCase();

    return upperDescription?.includes(search) || lowerDescription?.includes(search);
  });

  useEffect(() => {}, [formattedDevicesDefect, filteredDevicesDefect]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await serviceOrderService.createDeviceDefects(values);

      toast.success('Processo cadastrado com sucesso');
      refetchDevice();
      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar serviço.');
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Defeitos de Dispositivos</PageTitle>
      <Formik
        initialValues={{ initials: '', description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='initials'
                    label='Sigla'
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    name='description'
                    label='Defeito do dispositivo'
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='search'
                    label='Pesquisar'
                    variant='outlined'
                    size='small'
                    endAdornment={<SearchIcon />}
                    component={Text}
                    value={search}
                    onChange={handleSearchChange}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingDevice}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Nenhum serviço encontrado.'
                    // onActionsClick={handleOpenInformation}
                    data={filteredDevicesDefect}
                    maxHeight
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredDevicesDefect.map((device) => (
                      <TableRow key={device.id}>
                        <TableCell>{device.initials}</TableCell>
                        <TableCell>{device.description}</TableCell>
                        <TableCell>{device.active ? 'Ativo' : 'Inativo'}</TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy'>{device.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, device)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <RegisterDeviceDefectDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  deviceDefect={deviceDefect}
                  reloadDevice={refetchDevice}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
