import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { componentService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  quantity: yup.number().required(),
  current_quantity: yup.number().required(),
  sum: yup.boolean().required(),
});

const operationOptions = [
  {
    value: true,
    label: 'Adição',
  },
  {
    value: false,
    label: 'Dedução',
  },
];

export const UpdateBalanceDialog = ({ open, handleClose, component, reloadComponents }) => {
  if (!open) return null;

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await componentService.updateComponentsBalance(component?.id, values);

      toast.success('Saldo atualizado com sucesso.');

      resetForm();
      reloadComponents();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível atualizar o saldo.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='md'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>
        Atualizar saldo do Part Number: {component?.part_number}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            quantity: 0,
            current_quantity: component?.quantity,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      label='Quantidade atual'
                      name='current_quantity'
                      size='small'
                      fullWidth
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      label='Quantidade a ser atualizada'
                      name='quantity'
                      size='small'
                      fullWidth
                      variant='outlined'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      size='small'
                      fullWidth
                      name='sum'
                      variant='outlined'
                      label='Operação'
                      emptyOption='Selecione'
                      options={operationOptions}
                      component={Select}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button onClick={handleClose} color='default' fullWidth>
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      color='primary'
                      fullWidth
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
