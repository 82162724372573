import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  };
});

export default useStyles;
