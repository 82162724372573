import {
  addToCart,
  cleanCart,
  removeFromCart,
  updateAmount,
  addToCartWholesale,
  removeFromCartWholesale,
  updateAmountWholesale,
} from './reducerFunctions';
import { addLaborToCart, removeLaborFromCart, updateLaborAmount } from './reducerFunctionsLabor';

export const cartReducer = (state, action) => {
  switch (action.type) {
    case '@ADD_TO_CART':
      return addToCart(state, action);

    case '@REMOVE_FROM_CART':
      return removeFromCart(state, action);

    case '@UPDATE_AMOUNT':
      return updateAmount(state, action);

    case '@ADD_TO_CART_WHOLESALE':
      return addToCartWholesale(state, action);

    case '@REMOVE_FROM_CART_WHOLESALE':
      return removeFromCartWholesale(state, action);

    case '@UPDATE_AMOUNT_WHOLESALE':
      return updateAmountWholesale(state, action);

    case '@CLEAN_CART':
      return cleanCart(state, action);

    case '@ADD_LABOR_TO_CART':
      return addLaborToCart(state, action);

    case '@REMOVE_LABOR_FROM_CART':
      return removeLaborFromCart(state, action);

    case '@UPDATE_LABOR_AMOUNT':
      return updateLaborAmount(state, action);

    default:
      break;
  }
};
