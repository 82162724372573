import React, { useCallback } from 'react';
import Joyride, { LIFECYCLE, STATUS } from 'react-joyride';

import { useTheme } from '@material-ui/core/styles';

import { AuthContext } from 'hooks/useAuth';
import { accountService } from 'services/account';
import { useContextSelector } from 'use-context-selector';

const defaultStepProps = {
  disableBeacon: true,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  floaterProps: {
    disableAnimation: true,
  },
};

const joyrideLocale = {
  back: 'Voltar',
  close: 'Fechar',
  last: 'Entendi',
  next: 'Próximo',
  skip: 'Pular',
};

export const Tutorial = ({ name, steps, onFinish, ...props }) => {
  const theme = useTheme();
  const userViewedTutorials = useContextSelector(AuthContext, ({ user }) => user.viewed_tutorials);
  const updateUser = useContextSelector(AuthContext, (context) => context.setAuthData);

  const userDidntSeeTour = !userViewedTutorials?.includes(name);

  const callback = useCallback(
    async (data) => {
      const { status, lifecycle } = data;

      if (lifecycle === LIFECYCLE.COMPLETE && STATUS.FINISHED === status && userDidntSeeTour) {
        onFinish?.();
        await accountService.addViewedTutorial(name);
        updateUser((prevState) => {
          const { user } = prevState;
          const { viewed_tutorials } = user;

          return {
            ...prevState,
            user: {
              ...user,
              viewed_tutorials: [...viewed_tutorials, name],
            },
          };
        });
      }
    },
    [name, onFinish, userDidntSeeTour, updateUser],
  );

  const shouldShowTour = userDidntSeeTour;
  const tourSteps = steps.map((s) => ({ ...s, ...defaultStepProps }));

  const joyrideStyles = {
    overlay: {
      width: '100%',
    },
    options: {
      textColor: '#000',
    },
    tooltipContent: {
      padding: '5px',
      // height: '50%',
      marginLeft: '10px',
    },
    tooltipContainer: {
      textAlign: 'left',
      marginLeft: '20px',
    },
    buttonNext: {
      fontWeight: 700,
      backgroundColor: theme.palette.primary.main,
      outline: 0,
      fontSize: 14,
      padding: 12,
    },
    buttonBack: {
      color: theme.palette.primary.main,
      outline: 0,
      fontSize: 14,
      padding: 12,
    },
    buttonClose: {
      display: 'none',
    },
  };

  return (
    <Joyride
      locale={joyrideLocale}
      scrollToFirstStep={true}
      run={shouldShowTour}
      steps={tourSteps}
      showSkipButton={false}
      showProgress={true}
      continuous={true}
      callback={callback}
      styles={joyrideStyles}
      {...props}
    />
  );
};
