import React, { createContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { FIND_ALL_SERVICE_FOR_WAITING_ORACLE } from 'services/graphql/query/service-orders';

import { WaitingOracleHeader } from './WaitingOracleHeader';
import { WaitingOracleTable } from './WaitingOracleTable';

export const WaitingOracleContext = createContext();

export function WaitingOracleInBound() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [getSelectedRow, setSelectedRow] = useState([]);
  const [getSelectedAll, setSelectedAll] = useBoolean(false);

  const { data, loading, refetch } = useQuery(FIND_ALL_SERVICE_FOR_WAITING_ORACLE, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      search: search,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleResetStates = () => {
    setSelectedAll.toFalse();
    setSelectedRow([]);
    setSearch('');
  };

  return (
    <WaitingOracleContext.Provider
      value={{
        data,
        loading,
        refetch,
        handleChangePage,
        handleChangeRowsPerPage,
        handleSearchChange,
        totalCount: data?.findAllServiceForWaitingOracle?.totalCount,
        page,
        rowsPerPage,
        search,
        getSelectedRow,
        setSelectedRow,
        getSelectedAll,
        setSelectedAll,
        handleResetStates,
      }}
    >
      <PageTitle>Aguardando Oracle</PageTitle>
      <WaitingOracleHeader />
      <WaitingOracleTable />
    </WaitingOracleContext.Provider>
  );
}
