import React from 'react';
import { BsCheck } from 'react-icons/bs';
import { FaCartPlus } from 'react-icons/fa';

import clsx from 'clsx';

import { useStyles } from './styles';

export function StepIconComponent(props) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons = {
    1: <FaCartPlus size='18' />,
    2: <BsCheck size='20' />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
