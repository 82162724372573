import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import sorting from 'assets/images/sorting.svg';
import { Button, Checkbox, Spacer, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const columns = [
  {
    label: 'Possui',
    width: 80,
  },
  { label: 'Sigla' },
  { label: 'Description' },
];

const budgetStatusesOptions = {
  3: 'Autorizado',
  8: 'Reprovado',
  10: 'Reprovado',
  15: 'Autorizado',
  16: 'Autorizado',
  17: 'Autorizado',
  18: 'Autorizado',
};
export function SortingAssurance() {
  const classes = useStyles();

  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const formikRef = useRef();

  const [service, setService] = useState();
  const [checkedDefects, setCheckedDefects] = useState([]);

  console.log('service?.number: ', service?.number);

  // const [budget, , loadingBudget, refetchBudget] = useQuery(
  //   () => budgetService.listBudgetsServices(service?.number),
  //   [service],
  // );

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const service = await serviceOrderService.getServiceByNumberWhereIsNotSortingAssurance(
        values.service_number,
      );

      setService(service.data);
      setSubmitting(true);

      toast.success('OS encontrada com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao encontrar OS');
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitValidation =
    (validation) =>
    async ({ setSubmitting, resetForm }) => {
      let arrayQualityAssurance = [];
      try {
        if (checkedDefects.length > 0) {
          checkedDefects.map((defect) => {
            const generateQA = {
              service_order_id: service.id,
              sorting: defect,
              approved: true,
              user_id: user,
            };

            return arrayQualityAssurance.push(generateQA);
          });
        } else {
          const generateQA = {
            service_order_id: service.id,
            approved: validation,
            user_id: user,
          };

          arrayQualityAssurance.push(generateQA);
        }

        await serviceOrderService.createSortingAssurance({
          qualityAssurance: arrayQualityAssurance,
        });

        setCheckedDefects([]);
        setService();

        formikRef.current.setFieldValue('service_number', '');

        toast.success(
          'Triagem validada com sucesso, em casos de NPI o produto será enviado para o Check-Kit.',
        );

        setSubmitting(true);
        resetForm();
      } catch (err) {
        toast.error(err.response?.data?.message);
      }
    };

  const { state } = useLocation();

  const handleChecked = (defect) => {
    if (checkedDefects.includes(defect))
      setCheckedDefects(checkedDefects.filter((item) => item !== defect));
    else setCheckedDefects((oldState) => [...oldState, defect]);
  };

  const [search, setSearch] = useState('');

  const [deviceData, , loadingDevice, refetchDevice] = useQuery(
    () => serviceOrderService.listDeviceDefects(),
    [],
  );

  const formattedDevicesDefect = (deviceData || []).map((b) => {
    return {
      ...b,
    };
  });

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const filteredDevicesDefect = formattedDevicesDefect.filter((device) => {
    const upperDescription = device.description?.toUpperCase();
    const lowerDescription = device.description?.toLowerCase();

    return upperDescription?.includes(search) || lowerDescription?.includes(search);
  });

  return (
    <>
      <PageTitle>Triagem</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ service_number: state?.service_order_number ?? '' }}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  component={Text}
                  //onInput={toInputUppercase}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            {service?.device_defect ? (
              <>
                <Typography variant='h6' gutterBottom>
                  Triagem de Defeitos
                </Typography>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {/* <Table
                      headers={columns}
                      data={filteredDevicesDefect}
                      striped
                      disableNumeration
                      maxHeight={500}
                    >
                      <TableRow key={service.id}>
                        <TableCell size='small' align='center'>
                          <Checkbox
                            color='primary'
                            onChange={() => handleChecked(service.device_defect)}
                            checked={checkedDefects.includes(service.device_defect)}
                          />
                        </TableCell>
                        <TableCell>{service?.device_defect}</TableCell>
                      </TableRow>
                    </Table> */}
                    <Grid item xs={12} lg={9}>
                      <Table
                        headers={columns}
                        striped
                        loading={loadingDevice}
                        emptyMessage='Nenhum serviço encontrado.'
                        data={filteredDevicesDefect}
                        maxHeight
                        disableNumeration
                      >
                        {filteredDevicesDefect.map((device) => (
                          <TableRow key={device.id}>
                            <TableCell size='small' align='center'>
                              <Checkbox
                                color='primary'
                                onChange={() => handleChecked(device.id)}
                                checked={checkedDefects.includes(device.id)}
                              />
                            </TableCell>
                            <TableCell>{device.initials}</TableCell>
                            <TableCell>{device.description}</TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12} lg={2}>
                    <Button
                      disabled={checkedDefects.length > 0}
                      fullWidth
                      onClick={onSubmitValidation(true)}
                    >
                      Aprovar
                    </Button>
                  </Grid> */}
                  <Grid item xs={12} lg={2}>
                    <Button fullWidth onClick={onSubmitValidation(false)}>
                      Salvar triagem
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <img className={classes.imgContainer} src={sorting} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
