import React, { createContext, useState } from 'react';

import { Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { B2CTable } from './components/B2CTable';
import { ImportButton } from './components/ImportButton';

export const B2CContext = createContext();

export function B2C() {
  const [results, setResults] = useState();

  return (
    <>
      <PageTitle>B2C</PageTitle>
      <B2CContext.Provider value={{ results, setResults }}>
        <ImportButton />

        <Spacer size={32} />

        {results && <B2CTable />}
      </B2CContext.Provider>
    </>
  );
}
