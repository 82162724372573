import { toast } from 'react-toastify';

export const addLaborToCart = (state, action) => {
  const { labor } = action;
  const cart = [...state];

  if (!labor.labor_price) {
    toast.error('acessório sem preço de venda cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...labor, amount: 1, sub_total: labor.labor_price }];
  }

  console.log(labor);

  const hasLaborIndexInCart = state.findIndex((a) => {
    return a.id == action.labor.id;
  });

  let laborAdded = [];

  if (hasLaborIndexInCart >= 0) {
    laborAdded = cart[hasLaborIndexInCart];

    laborAdded.amount += 1;
    laborAdded.sub_total = laborAdded.amount * laborAdded.labor_price;

    return cart;
  }

  return [...cart, { ...labor, amount: 1, sub_total: labor.labor_price }];
};

export const removeLaborFromCart = (state, action) => {
  const cart = [...state];
  const { labor } = action;

  const hasLaborIndexInCart = state.findIndex((a) => {
    return a.id === labor.id;
  });

  if (hasLaborIndexInCart >= 0) {
    cart.splice(hasLaborIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updateLaborAmount = (state, action) => {
  const cart = [...state];
  const { amountAndLaborId } = action;

  const laborIndex = cart.findIndex((a) => {
    return a.id === amountAndLaborId.labor_id;
  });

  cart[laborIndex].amount = amountAndLaborId.amount;

  cart[laborIndex].sub_total = cart[laborIndex].amount * cart[laborIndex].labor_price;

  if (amountAndLaborId.amount <= 0) {
    cart.splice(laborIndex, 1);
  }

  return cart;
};
