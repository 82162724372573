/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from 'react';

import { Box, Chip, Typography } from '@material-ui/core';

import { useQuery } from '@apollo/client';
import {
  LIST_FUNTIONAL_SCREENING_DEFECTS,
  LIST_FUNTIONAL_SCREENING_RESPONSES,
} from 'services/graphql/query/functional-screening';

export function FuntionalDefects({ sinister }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterQuestions, setFilterQuestions] = useState();

  const funtionalResponses = useQuery(LIST_FUNTIONAL_SCREENING_RESPONSES, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  const defectsResponses = useQuery(LIST_FUNTIONAL_SCREENING_DEFECTS, {
    variables: {
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setFilterQuestions(
      funtionalResponses?.data?.listFuncionalScreeningReponses?.responses?.filter(
        (item) => item?.response === false,
      ),
    );
  }, [funtionalResponses]);

  return (
    <>
      <Box mb={3}>
        <Typography variant='h6' gutterBottom>
          Divergências funcionais:
        </Typography>
        {filterQuestions?.map((item) => (
          <Chip
            key={item?.id}
            label={item?.question}
            color='primary'
            variant='outlined'
            style={{ marginRight: 8, marginBottom: 8 }}
          />
        ))}
      </Box>

      <Box mb={3}>
        <Typography variant='h6' gutterBottom>
          Defeitos Adicionais:
        </Typography>
        {defectsResponses?.data?.listFuncionalScreeningDefects?.map((item) => (
          <Chip
            key={item?.id}
            label={item?.defect?.description}
            color='primary'
            variant='outlined'
            style={{ marginRight: 8, marginBottom: 8 }}
          />
        ))}
      </Box>
    </>
  );
}
