import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Dialog, Text, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { serviceOrderService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  initials: yup.string().required(),
  active: yup.boolean().required(),
});

export const RegisterDeviceDefectDialog = ({
  open,
  handleClose,
  deviceDefect = {},
  reloadDevice,
}) => {
  if (!open) return null;

  const activeRef = useRef();

  const [isSwitchOn, setIsSwitchOn] = useBoolean(deviceDefect.active);

  const [isButtonEnabled, setIsButtonEnabled] = useBoolean(deviceDefect.active);

  const handleSwitch = () => {
    setIsSwitchOn.toggle();
    setIsButtonEnabled.toggle();
    activeRef?.current?.setFieldValue('active', !isButtonEnabled);
  };

  console.log('isButtonEnabled', isButtonEnabled);
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 30,
    height: 16,
    padding: 0,
    right: '1px',
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 30,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(16px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,

      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#512da8' : '#512da8',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 12,
      height: 12,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 300,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: theme.palette.mode === 'dark' ? '512da8' : '#d1c4e9',
      boxSizing: 'border-box',
    },
  }));

  useEffect(() => {}, []);

  console.log('Defect: ', deviceDefect);
  console.log('Ref Active: ', activeRef?.current?.values?.active);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    values.active = isSwitchOn;
    try {
      await serviceOrderService.updateDeviceDefect(values);
      reloadDevice();
      toast.success('Defeito alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o defeito');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>
        Defeito do Dispositivo:
        {deviceDefect.description}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            id: deviceDefect.id,
            description: deviceDefect.description,
            initials: deviceDefect.initials,
            active: deviceDefect.active,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
          innerRef={activeRef}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Defeito'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='initials'
                      label='Sigla'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction='row' spacing={1} alignItems='center'>
                      <Typography>OFF</Typography>
                      <AntSwitch
                        name='active'
                        defaultChecked={isSwitchOn}
                        inputProps={{ 'aria-label': 'ant design' }}
                        onChange={handleSwitch}
                      />
                      <Typography>ON</Typography>
                    </Stack>
                  </Grid>
                </Grid>
                <br />
                <div container>
                  <Button onClick={handleClose} color='primary' variant='text'>
                    Fechar
                  </Button>
                  <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
