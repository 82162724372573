import React, { useContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as CellImage } from 'assets/images/undraw_reminder_re_fe15.svg';
import { Table, FormatDate, Button } from 'components';
import { Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';

import { ServiceOrderContext } from '../..';
import { OsPartsRemoveFromOsDialog } from './OsPartsRemoveFromOsDialog';
import { PartInformationDialog } from './PartInformationDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Local de estoque', align: 'center' },
  { label: 'Data da aplicação', align: 'center' },
  { label: 'AÇÃO', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

// const columns2 = [
//   { label: 'Valor Cobrado', align: 'center' },
//   { label: 'Valor Minimo possível', align: 'center' },
// ];

export const OsParts = () => {
  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [isDetailsOpenRemoveParts, setIsDetailsStateRemoveParts] = useBoolean();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [budgets, setBudget] = useState({});

  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsServicesByServiceIdToAplicatedComponents(serviceFromGaia?.id),
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBudget(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseInformationRemoveParts = () => {
    setIsDetailsStateRemoveParts.toFalse();
  };

  const handleOpenInformatioRemoveParts = (e, item) => {
    setIsDetailsStateRemoveParts.toTrue();
    setBudget(item);
  };

  return (
    <Formik>
      <Form>
        {!budget ? (
          <>
            <div className={classes.container}>
              <h1 className={classes.title}>
                Ordem de serviço informada não possui peças lançadas.
              </h1>
              <CellImage className={classes.changeLogImage} />
            </div>
          </>
        ) : (
          <>
            <Grid item xs={12} lg={12}>
              <Table
                headers={columns}
                loading={loadingBudget}
                size='small'
                // actionsLabel={<VisibilityIcon />}
                emptyMessage='Nenhuma peça foi lançada ainda.'
                // onActionsClick={handleOpenInformation}
                striped
                maxHeight
                data={budget}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                disableNumeration
              >
                {budget?.map((stockRequisition) => {
                  return (
                    <TableRow key={stockRequisition?.id}>
                      <TableCell align='center'>{stockRequisition?.part_number}</TableCell>
                      <TableCell align='center'>
                        {stockRequisition?.description
                          ? stockRequisition?.description
                          : 'SEM DESCRIÇÃO'}
                      </TableCell>
                      <TableCell align='center'>{stockRequisition?.amount}</TableCell>
                      <TableCell align='center'>{stockRequisition?.local_estoque}</TableCell>
                      <TableCell align='center'>
                        <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                          {stockRequisition?.created_at}
                        </FormatDate>
                      </TableCell>
                      <TableCell align='center'>
                        <Button
                          color='secondary'
                          fullwidth
                          onClick={(e) => handleOpenInformatioRemoveParts(e, stockRequisition)}
                        >
                          Excluir
                        </Button>
                      </TableCell>
                      <TableCell align='center'>
                        <IconButton
                          color='primary'
                          onClick={(e) => handleOpenInformation(e, stockRequisition)}
                        >
                          <AddCircleIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid>
            <PartInformationDialog
              open={isDetailsOpen}
              handleClose={handleCloseInformation}
              service={serviceFromGaia}
              stockRequisition={budgets}
              reloadStatuses={refetchBudgets}
            />
            <OsPartsRemoveFromOsDialog
              open={isDetailsOpenRemoveParts}
              handleClose={handleCloseInformationRemoveParts}
              service={serviceFromGaia}
              stockRequisition={budgets}
              reloadBudgets={refetchBudgets}
            />
          </>
        )}
      </Form>
    </Formik>
  );
};
