import React, { Fragment } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { FormatMoney, Spacer } from 'components';
import { SimpleTable } from 'components/SimpleTable';

const columns = [
  { label: 'Item', align: 'center' },
  { label: 'NF', align: 'center' },
  { label: 'Emissão', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'Valor total', align: 'center' },
  { label: 'Custo total', align: 'center' },
  { label: 'Lucro', align: 'center' },
];

export const MonthlyClosingTable = ({ invoices, loading }) => {
  return (
    <>
      <Spacer size={40} />
      <SimpleTable headers={columns} totalCount={invoices?.length} isLoading={loading}>
        {invoices?.invoicings?.map((row, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell align='center'>{index + 1}</TableCell>
              <TableCell align='center'>{row?.invoice}</TableCell>
              <TableCell align='center'>{row?.emission_date}</TableCell>
              <TableCell align='center'>{row?.client}</TableCell>
              <TableCell align='center'>
                <FormatMoney>{row?.total_value}</FormatMoney>
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{row?.total_cost}</FormatMoney>
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{row?.profit}</FormatMoney>
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </SimpleTable>
    </>
  );
};
