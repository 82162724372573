import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Table, Select, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [{ label: 'Tipo de estoque' }, { label: 'Local Vinculado', align: 'center' }];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const RegisterStockLocation = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const [search, setSearch] = useState('');

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const [listLocations, , loadingListLocations, loadingListLocationsRefetch] = useQuery(
    () => componentService.listStockLocations(),
    [],
  );

  const levelLocationsOptions = (levelLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.description,
  }));

  // const listComponentsLocations = (listLocations || []).map((levelLocation) => ({
  //   value: levelLocation.id,
  //   label: levelLocation.description,
  // }));

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await componentService.createStockLocations(values);

      loadingListLocationsRefetch();
      toast.success('Local de estoque cadastrado com sucesso');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar Local.');
    } finally {
      setSubmitting(false);
    }
  };

  // const handleFillLocation = (id, local) => {
  //   const indexObjectRegister = registeredPns.findIndex((item) => {
  //     return item.prod_id === id;
  //   });

  //   if (indexObjectRegister > -1) {
  //     registeredPns[indexObjectRegister].lacre = lacre;
  //   }
  // };

  return (
    <>
      <PageTitle>Cadastro de Local de Estoque</PageTitle>
      <Formik
        initialValues={{ location_name: '', level_location_id: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='level_location_id'
                    label='Tipo de Estoque'
                    variant='outlined'
                    size='small'
                    //loading={levelLocationsOptions}
                    options={levelLocationsOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='location_name'
                    min={30}
                    label='Local '
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    loading={loadingListLocations}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {listLocations?.map((locations) => (
                      <TableRow key={locations.id}>
                        <TableCell>{locations.description}</TableCell>
                        <TableCell align='center'>{locations.location_name}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
