import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, FormatMoney, Table, Tabs } from 'components';

const columns = [
  { label: 'Part-Number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Preço Mínimo', align: 'center' },
  { label: 'Preço de Venda', align: 'center' },
];

const paymentMethodsOptions = {
  1: 'Boleto',
  2: 'Link de pagamento crédito',
  3: 'Link de pagamento débito',
  6: 'PIX',
  10: 'Cartão de débito',
  11: 'Dinheiro',
  12: 'Cartão de Crédito',
  13: 'Pagamento direto ao Caixa',
};

const serviceColumns = [
  { label: 'Serviço', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Valor', align: 'center' },
];

export const TableBudgetsReprovedDialog = ({ open, handleClose, budget = {} }) => {
  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Produto: {budget?.xprod}</Dialog.Title>
      <Dialog.Content>
        <Tabs gutterButtom>
          <Tabs.Content label='Ordem de Serviço'>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>ORDEM DE SERVIÇO:</Typography>
                <Typography>{budget?.service_order?.number}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>OBJECT IN:</Typography>
                <Typography>{budget?.service_order?.object_in}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PART-NUMBER:</Typography>
                <Typography>{budget?.service_order?.service_part_number}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>EAN:</Typography>
                <Typography>{budget?.service_order?.ean}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>MODELO:</Typography>
                <Typography>{budget?.service_order?.model}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>SERIAL:</Typography>
                <Typography>{budget?.service_order?.serial_number}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>IMEI:</Typography>
                <Typography>{budget?.service_order?.imei}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>IMEI 2:</Typography>
                <Typography>{budget?.service_order?.imei}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DEFEITO:</Typography>
                <Typography>{budget?.service_order?.device_defect}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>TIPO DE PROCESSO:</Typography>
                <Typography>
                  {budget?.service_order?.process_type_id === 2 ? 'Varejo' : 'Logística Reversa'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>Garantia:</Typography>
                <Typography>
                  {budget?.service_order?.warranty_type === 'In Warranty'
                    ? 'Em Garantia'
                    : 'Fora de Garantia'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>COR DO PRODUTO:</Typography>
                <Typography>{budget?.service_order?.color_product}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>INFORMAÇÃO ADICIONAL:</Typography>
                <Typography>{budget?.service_order?.add_info}</Typography>
              </Grid>
            </Grid>
          </Tabs.Content>
          <Tabs.Content label='Componentes'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table
                  headers={columns}
                  striped
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  {budget?.sales_components?.map((salesComponent) => (
                    <TableRow key={salesComponent?.id}>
                      <TableCell align='center'>{salesComponent?.part_number}</TableCell>
                      <TableCell align='center'>{salesComponent?.description}</TableCell>
                      <TableCell align='center'>
                        <FormatMoney>{salesComponent?.origin_value}</FormatMoney>
                      </TableCell>
                      <TableCell align='center'>
                        <FormatMoney>{salesComponent?.sell_price}</FormatMoney>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
            <Grid item xs={12} align='right'>
              <Typography variant='subtitle2'>TOTAL EM COMPONENTES:</Typography>
              <Typography>
                <FormatMoney>{budget?.components_total_price}</FormatMoney>
              </Typography>
            </Grid>
          </Tabs.Content>
          <Tabs.Content label='Serviços'>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table
                  headers={serviceColumns}
                  striped
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                >
                  {budget?.additional_services?.map((additionalService) => (
                    <TableRow key={additionalService?.id}>
                      <TableCell align='center'>{additionalService?.description}</TableCell>
                      <TableCell align='center'>
                        {additionalService?.additional_service_product?.description}
                      </TableCell>
                      <TableCell align='center'>
                        <FormatMoney>{additionalService?.service_price}</FormatMoney>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
            <Grid item xs={12} align='right'>
              <Typography variant='subtitle2'>TOTAL EM SERVIÇOS:</Typography>
              <Typography>
                <FormatMoney>{budget?.additional_services_total_price}</FormatMoney>
              </Typography>
            </Grid>
          </Tabs.Content>
          <Tabs.Content label='Orçamento'>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>ATENDENTE:</Typography>
                <Typography>{budget?.attendant?.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>E-MAIL DO ATENDENTE:</Typography>
                <Typography>{budget?.attendant?.email}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'></Typography>
                <Typography></Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>CLIENTE:</Typography>
                <Typography>{budget?.service_order?.client?.name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>E-MAIL DO CLIENTE:</Typography>
                <Typography>{budget?.service_order?.client?.email}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>CELULAR DO CLIENTE :</Typography>
                <Typography>{budget?.service_order?.client?.phone_number}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>MÉTODO DE PAGAMENTO 1:</Typography>
                <Typography>{paymentMethodsOptions[budget?.payment_method1] ?? '-'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PARCELAS:</Typography>
                <Typography>
                  {paymentMethodsOptions[budget?.payment_method1] ? budget?.installments : '-'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR POR PARCELA:</Typography>
                <Typography>
                  <FormatMoney>{budget?.installment_price}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>MÉTODO DE PAGAMENTO 2:</Typography>
                <Typography>{paymentMethodsOptions[budget?.payment_method2] ?? '-'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PARCELAS:</Typography>
                <Typography>
                  {paymentMethodsOptions[budget?.payment_method2] ? budget?.installments2 : '-'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR POR PARCELA:</Typography>
                <Typography>
                  <FormatMoney>{budget?.installment_price2}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>MÉTODO DE PAGAMENTO 3:</Typography>
                <Typography>{paymentMethodsOptions[budget?.payment_method3] ?? '-'}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PARCELAS:</Typography>
                <Typography>
                  {paymentMethodsOptions[budget?.payment_method3] ? budget?.installments3 : '-'}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR POR PARCELA:</Typography>
                <Typography>
                  <FormatMoney>{budget?.installment_price3}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR TOTAL:</Typography>
                <Typography>
                  <FormatMoney>
                    {budget?.additional_services_total_price + budget?.components_total_price}
                  </FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DESCONTO:</Typography>
                <Typography>{parseInt(budget?.discount)}%</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>VALOR TOTAL COM DESCONTO:</Typography>
                <Typography>
                  <FormatMoney>{budget?.final_budget_price}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2'>VALOR COM NEGOCIAÇÃO:</Typography>
                <Typography>
                  <FormatMoney>{budget?.negociation_price ?? 0}</FormatMoney>
                </Typography>
              </Grid>
              <Grid item xs={12} align='right'>
                <Typography variant='h6' color='secondary'>
                  VALOR TOTAL PAGO:
                </Typography>
                <Typography color='secondary'>
                  <FormatMoney>
                    {budget?.negociation_price ?? budget?.final_budget_price}
                  </FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Tabs.Content>
        </Tabs>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color='primary'>
            Fechar
          </Button>
        </DialogActions>
      </Dialog.Content>
    </Dialog>
  );
};
