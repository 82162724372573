import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    formContainer: {
      paddingTop: 10,
    },
    button: {
      justifyContent: 'flex-end',
    },
  };
});
