import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { useFormikContext } from 'formik';
import { api } from 'services/api';

import { useInvoice } from '../context';

export const Actions = () => {
  const { refetchProviders, setDisabledFields } = useInvoice();

  const [importing, setImporting] = useState();

  const inputFileRef = useRef(null);

  const {
    resetForm,
    isSubmitting,
    isValid,
    dirty,
    initialValues,
    setValues,
    setFieldValue,
    values,
  } = useFormikContext();

  const { invoice_items } = values;

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 10000);
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.type !== 'text/xml') {
        toast.error('Adicione um arquivo do tipo XML');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = event.target.result;

        api
          .post('/invoices/import-xml', result, {
            headers: {
              'Content-Type': 'application/xml',
            },
          })
          .then((response) => {
            const { data } = response;

            const { newProvider, ...importValues } = data;
            setValues({
              ...initialValues,

              ...importValues,
            });

            if (newProvider) {
              refetchProviders();
            }
            setDisabledFields(true);
          })
          .catch((err) => {
            setImporting(false);
            toast.error(
              err?.response?.data?.message ||
                'Erro ao realizar importação, tente novamente mais tarde.',
            );
          });
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  const handleImportClick = () => {
    setFieldValue('PONo', '');
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xml'
        onChange={handleFileChange}
        hidden
        accept='text/xml'
      />

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={4}>
          <Button
            onClick={handleImportClick}
            color='primary'
            variant='contained'
            fullWidth
            loading={importing}
          >
            Importar XML
          </Button>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Button onClick={resetForm} color='primary' variant='text' fullWidth>
            Limpar
          </Button>
        </Grid>
        <Grid item xs={6} md={4} lg={4}>
          <Button
            type='submit'
            fullWidth
            disabled={
              !isValid ||
              !dirty ||
              !invoice_items?.every(
                (item) =>
                  (item.location_id && item.location_id !== '') ||
                  (item.location_id && item.location_id !== null),
              )
            }
            loading={isSubmitting}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
