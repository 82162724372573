import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  };
});
