import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  status_id: yup.number().required(),
  // components_total_price: yup.number().required(),
  additional_services_id: yup.array().of(yup.number().required()).required(),
  additional_services_total_price: yup.number().required(),
  payment_method1: yup.number().required(),
  payment_method2: yup.number().nullable(),
  payment_method3: yup.number().nullable(),
  installments: yup.number(),
  installments2: yup.number().nullable(),
  installments3: yup.number().nullable(),
  installment_price: yup.number().nullable(),
  installment_price2: yup.number().nullable(),
  installment_price3: yup.number().nullable(),
  discount: yup.number().nullable(),
  additional_price: yup.number().nullable(),
});
