import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    actionIcon: {
      width: 20,
      height: 20,
    },
    checkIcon: {
      color: '#4CAF50',
    },
  };
});
