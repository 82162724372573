import React from 'react';
import { useRouteMatch } from 'react-router';

import { ReportObjectsReceived } from 'pages/Private/Production/Report';
import { PreBudgetReport } from 'pages/Private/ReportsExtract/PreBudgetReport';
import { Aging4hReport } from 'pages/Reports/Aging4hReport';
import { AgingDateReport } from 'pages/Reports/AgingDateReport';
import { AgingReport } from 'pages/Reports/AgingReport';
import { AppleBudgetReport } from 'pages/Reports/AppleBudgetReport';
import { BudgetReport } from 'pages/Reports/BudgetReport';
import { ComponentDebitReport } from 'pages/Reports/ComponentDebitReport';
import { ConferenceReportAll } from 'pages/Reports/ConferenceReport';
import { CostReport } from 'pages/Reports/CostReport';
import { EticketReport } from 'pages/Reports/EticketReport';
import { FinalInsuranceReport } from 'pages/Reports/FinalAssuranceReport';
import { FinancialReport } from 'pages/Reports/FinancialReport';
import { InsuranceReport } from 'pages/Reports/InsuranceReport';
import { MinuteReport } from 'pages/Reports/MinuteReport';
import { NFReceiveReport } from 'pages/Reports/NFReceiveReport';
import { NonComplianceReport } from 'pages/Reports/NonCompliaceReport';
import { ObjectBlockedReport } from 'pages/Reports/ObjectBlockedReport';
import { ObjectReport } from 'pages/Reports/ObjectReport';
import { PartsHoldingReport } from 'pages/Reports/PartsHoldingReport';
import { PartsRequisitions } from 'pages/Reports/PartsRequisitionsReport';
import { ProductionDepartmentReport } from 'pages/Reports/ProductionDepartmentReport';
import { retailInboundOutbound } from 'pages/Reports/RetailInboundOutboundReport';
import { SacReport } from 'pages/Reports/SacReport';
import { SortingReport } from 'pages/Reports/SortingReport';

import { Route } from '../Route';

export const ReportRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/report-prebudget`} component={PreBudgetReport} />
      <Route isPrivate path={`${path}/report-financial`} component={FinancialReport} />
      <Route isPrivate path={`${path}/report-budget`} component={BudgetReport} />
      <Route isPrivate path={`${path}/report-object`} component={ObjectReport} />
      <Route isPrivate path={`${path}/report-aging`} component={AgingReport} />
      <Route isPrivate path={`${path}/report-nf-receive`} component={NFReceiveReport} />
      <Route isPrivate path={`${path}/report-conference-all`} component={ConferenceReportAll} />
      <Route isPrivate path={`${path}/report-objects-received`} component={ReportObjectsReceived} />
      <Route isPrivate path={`${path}/report-insurance-all`} component={InsuranceReport} />
      <Route isPrivate path={`${path}/report-etickets`} component={EticketReport} />
      <Route
        isPrivate
        path={`${path}/report-nonCompliance-quality`}
        component={NonComplianceReport}
      />
      <Route
        isPrivate
        path={`${path}/report-productionDepartment`}
        component={ProductionDepartmentReport}
      />
      <Route isPrivate path={`${path}/report-agingDate`} component={AgingDateReport} />
      <Route isPrivate path={`${path}/report-aging4h`} component={Aging4hReport} />
      <Route isPrivate path={`${path}/report-sorting`} component={SortingReport} />
      <Route isPrivate path={`${path}/report-partsRequisitions`} component={PartsRequisitions} />
      <Route
        isPrivate
        path={`${path}/report-retailInboundOutbound`}
        component={retailInboundOutbound}
      />
      <Route isPrivate path={`${path}/report-partsHolding`} component={PartsHoldingReport} />
      <Route isPrivate path={`${path}/report-sac`} component={SacReport} />
      <Route isPrivate path={`${path}/report-final-insurance`} component={FinalInsuranceReport} />
      <Route isPrivate path={`${path}/report-component-debit`} component={ComponentDebitReport} />
      <Route isPrivate path={`${path}/report-minute`} component={MinuteReport} />
      <Route isPrivate path={`${path}/report-cost`} component={CostReport} />
      <Route isPrivate path={`${path}/report-apple`} component={AppleBudgetReport} />
      <Route isPrivate path={`${path}/report-object-blocked`} component={ObjectBlockedReport} />
    </>
  );
};
