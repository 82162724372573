import { api } from 'services/api';

export class AccountService {
  async listUsers() {
    return api.get('/users');
  }

  async updateUser(data) {
    return api.put('/users', data);
  }

  async listUsersAccessActive() {
    return api.get('/users/access/active');
  }

  async listUsersSubAccessActive() {
    return api.get('/users/access/sub/active');
  }

  async addViewedTutorial(tutorial) {
    return api.post('/users/tutorial', { tutorial });
  }

  async ListViewedTutorial(user_id) {
    return api.get('/users/tutorial');
  }

  async updateUserPassword(email, password, phone, quiz_id, answer) {
    return api.put('/users/profile', { email, password, phone, quiz_id, answer });
  }

  async listUserRoles() {
    return api.get('/users/roles');
  }

  async createRole(data) {
    return api.post('/users/roles', data);
  }

  async updateRole(data) {
    return api.put('/users/roles', data);
  }

  async changeUserRole(email, role_id, user_id) {
    return api.put('/users/roles/change', { email, role_id, user_id });
  }

  async updateUserRolePermissions(id, allPermissions) {
    return api.put('/users/roles/permissions', { id, allPermissions });
  }

  async setAsAttendant(email, name, user_id) {
    return api.put('/users/is-attendant', { email, name, user_id });
  }

  async setAsSeller(email, name, user_id) {
    return api.put('/users/is-seller', { email, name, user_id });
  }

  async updateUserAdditionalPermissions(id, allAdditionalPermissions) {
    return api.put('/users/permissions/additional', { id, allAdditionalPermissions });
  }

  async getIsAttendant() {
    return api.get('/users/is-attendant');
  }

  async listAttendantActive() {
    return api.get('/users/is-attendant/active');
  }

  async getIsSeller() {
    return api.get('/users/is-seller');
  }

  async listSellersActive() {
    return api.get('/users/is-seller/active');
  }

  async getUserById(id) {
    return api.get(`/users/id/${id}`);
  }

  async getUserByEmail(email) {
    return api.get(`/users/${email}`);
  }

  async updateAttendant(old_email_attendant, email, name) {
    return api.put(`/users/is-attendant/change`, { old_email_attendant, email, name });
  }

  async updateAttendantActive(data) {
    return api.put(`/users/is-attendant/active`, data);
  }

  async updateSeller(old_email_seller, email, name) {
    return api.put(`/users/is-seller/change`, { old_email_seller, email, name });
  }

  async updateSellerActive(data) {
    return api.put('/users/is-seller/active', data);
  }

  async listUserQuantity() {
    return api.get('/users/quantity');
  }

  async listUserQuiz() {
    return api.get('/users/quiz');
  }

  async rateAvaliation(commentary, last_rate) {
    return api.post('/users/ratings', { commentary, last_rate });
  }

  async getUserMainAccessByRoleId() {
    return api.get('/users/vinc-main-access');
  }

  async getUserSubAccessByRoleId() {
    return api.get('/users/vinc-sub-access');
  }

  async getUserMainAccessByUserId() {
    return api.get('/users/vinc-additional-main-access');
  }

  async getUserSubAccessByUserId() {
    return api.get('/users/vinc-additional-sub-access');
  }

  async getRoleById(id) {
    return api.get(`/users/roles/${id}`);
  }
}

export const accountService = new AccountService();
