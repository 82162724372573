import { gql } from '@apollo/client';

export const LIST_GRADES = gql`
  query {
    listGrades {
      id
      description
      __typename @skip(if: true)
    }
  }
`;
