import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog, Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { cashierService } from 'services/cashier';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  accessory_price: yup.number().required(),
});

export const AlterAccessoryPriceDialog = ({
  open,
  handleClose,
  component = {},
  reloadComponents,
}) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('values: ', values);
      await cashierService.updateComponentAccessoryPrice(component.id, values.accessory_price);

      reloadComponents();
      toast.success('Nome do acessório alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o nome do acessório.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='xs' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Part Number: {component?.part_number}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ accessory_price: component.component_acessory_price }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Field
                        variant='outlined'
                        name='accessory_price'
                        label='Alterar o valor do acessório'
                        size='small'
                        mask='money'
                        component={Text}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
