import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { warehouseService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  request_id: yup.number().required(),
  model: yup.string(),
  comment: yup.string(),
  status_id: yup.number(),
});

export function CancelServiceDialog({ request, isOpen = false, handleClose, refetch }) {
  if (!isOpen) {
    return <></>;
  }
  async function handleUpdateProduct(values, { setSubmitting }) {
    try {
      setSubmitting(true);

      await warehouseService.cancelService(values);
      toast.success('Pedido cancelado com sucesso');
      refetch();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao cancelar o pedido.');
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Cancelamento do atendimento</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            request_id: request.id,
            model: request?.model ?? '',
            comment: '',
            status_id: 3,
          }}
          onSubmit={handleUpdateProduct}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='model'
                      label='Modelo requisitado'
                      size='small'
                      component={Text}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='comment'
                      label='Motivo do cancelamento'
                      size='small'
                      component={Text}
                      multiline
                      rows={10}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={handleClose} color='secondary' variant='outlined' fullWidth>
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      disabled={!props.dirty || !props.isValid}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      Cancelar pedido
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
