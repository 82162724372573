import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, BackNavButton } from 'components';
import { Formik, useFormikContext } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { clientService } from 'services/client';

import { FormRegistration } from './Form';
import { initialValues, validationSchema } from './formik';

export const RegisterClient = ({ open, onClose, onSuccess }) => {
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await clientService.createClient(values);

      toast.success('Cliente cadastrado com sucesso.');
      resetForm();
      onClose();
      onSuccess && (await onSuccess(values));
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao cadastrar cliente.');
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const HandleReset = () => {
    const { resetForm } = useFormikContext();

    return (
      <Button fullWidth type='reset' variant='outlined' onClick={resetForm}>
        Limpar
      </Button>
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, dirty, isValid }) => (
        <>
          <BackNavButton />
          <PageTitle>Cadastrar novo cliente</PageTitle>
          <FormRegistration />
          <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} container spacing={2}>
            <Grid item xs={2}>
              <HandleReset />
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={handleSubmit} type='submit' disabled={!dirty || !isValid}>
                Confirmar
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};
