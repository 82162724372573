/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, Spacer } from 'components';
import { RESERVE_PRODUCTS, VERIFY_PRODUCTS_AVAILABLE } from 'services/graphql/query/b2c';

import { InputProductTable } from './components/InputProductTable';
import { OutputProductTable } from './components/OutputProductTable';
import { useStyles } from './styles';

export function AvailableProductDialog({ isOpen, handleClose, item }) {
  if (!isOpen) {
    return <></>;
  }

  const classes = useStyles();

  const productAvailable = useQuery(VERIFY_PRODUCTS_AVAILABLE, {
    variables: { headerId: item.anymarketId },
    skip: !item,
    fetchPolicy: 'network-only',
  });

  const [reserveProducts, reserveProductsOptions] = useMutation(RESERVE_PRODUCTS, {
    onCompleted: () => {
      toast.success('Produtos reservados com sucesso');
      handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao reservar os produtos, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  if (productAvailable.loading || reserveProductsOptions.loading) {
    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
        <Dialog.Title onClose={handleClose}>Picking</Dialog.Title>
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <CircularProgress />
        </Grid>
      </Dialog>
    );
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='xl'>
      <Dialog.Title onClose={handleClose}>Picking</Dialog.Title>

      <Dialog.Content>
        <Typography variant='h6'>Informações do pedido</Typography>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <p className={classes.detailKey}>ID ANYMARKET:</p>
            <p className={classes.detailValue}>{item.anymarketId}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.detailKey}>CÓDIGO PEDIDO</p>
            <p className={classes.detailValue}>{item.orderCode}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.detailKey}>MARKETPLACE</p>
            <p className={classes.detailValue}>{item.marketplace}</p>
          </Grid>
          <Grid item xs={3}>
            <p className={classes.detailKey}>CLIENTE</p>
            <p className={classes.detailValue}>{item.client.name}</p>
          </Grid>
        </Grid>

        <Spacer axis='vertical' size={1} />
      </Dialog.Content>

      <Dialog.Content>
        <Grid container justifyContent='center' style={{ marginTop: 10, padding: 10 }} spacing={6}>
          <Grid xs={11} sm={12} md={5} lg={5} xl={5}>
            <Typography variant='subtitle1' align='center' style={{ fontWeight: 'bold' }}>
              Produtos requisitados
            </Typography>
            <InputProductTable data={productAvailable?.data?.verifyProductsAvaible?.input || []} />
          </Grid>

          <Grid xs={1} sm={1} md={1} lg={1} xl={1}></Grid>

          <Grid xs={11} sm={12} md={5} lg={5} xl={5}>
            <Typography variant='subtitle1' align='center' style={{ fontWeight: 'bold' }}>
              Produtos disponíveis
            </Typography>
            <OutputProductTable
              data={productAvailable?.data?.verifyProductsAvaible?.output ?? []}
            />
          </Grid>
        </Grid>
      </Dialog.Content>

      <Dialog.Actions>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={6}>
            <></>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Button
              fullWidth
              disabled={
                productAvailable?.data?.verifyProductsAvaible?.output?.length <= 0 ||
                productAvailable?.data?.verifyProductsAvaible?.input?.length <= 0 ||
                !productAvailable.data
              }
              onClick={() =>
                reserveProducts({
                  variables: {
                    data: {
                      headerId: item.anymarketId,
                      productServed: productAvailable?.data?.verifyProductsAvaible?.output,
                      productOrder: productAvailable?.data?.verifyProductsAvaible?.input,
                    },
                  },
                })
              }
            >
              Reservar produtos
            </Button>
          </Grid>
        </Grid>
      </Dialog.Actions>
    </Dialog>
  );
}
