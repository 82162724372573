import React from 'react';

import { Dialog, Button } from 'components';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export const ViewCouponDialog = ({ open, handleClose, voucher = {}, refreshVoucher }) => {
  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Visualizar Anexo</Dialog.Title>
      <Dialog.Content>
        <img src={voucher.voucher} />
      </Dialog.Content>
      <Dialog.Actions>
        <Button variant='text' onClick={handleClose}>
          Fechar
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
