import { api } from './api';

export class MailServices {
  async createMailObjectBlocked(data) {
    return await api.post('/mail-objects/create-block-object', data);
  }

  async unlockMailObjectBlocked(data) {
    return await api.post('/mail-objects/unlock-block-object', data);
  }

  async listMailObjectBlocked() {
    return await api.get('/mail-objects/list-block');
  }

  async listMailObjectBlockedReport() {
    return await api.get('/mail-objects/list-block-report');
  }

  async listMailPrefixes() {
    return await api.get('/mail/mail-prefixes');
  }

  async listMailMethods() {
    return await api.get('/mail/mail-methods');
  }

  async listMailContracts() {
    return await api.get('/mail/mail-contracts');
  }

  async createSigepRange(data) {
    return await api.post('/mail/sigep-range', data);
  }

  async listSigepRangeObjects() {
    return await api.get('/mail/sigep-range');
  }

  async updateSigepRange(id, mail_contract_id, mail_prefix_id, post_card) {
    return await api.put('/mail/sigep-range', { id, mail_contract_id, mail_prefix_id, post_card });
  }

  async createSinisterCheckin(data) {
    return api.post(`/mail/insurance/sinister/checkin`, data);
  }

  async createValidOutObject(data) {
    return await api.post('/mail/valid-out-objects', data);
  }

  async createValidOutObjectToBatch(
    mail_contract_id,
    nf_type,
    prefix_country,
    service_order_number,
    valid_out_objects_batch_id,
  ) {
    return await api.post('/mail/valid-out-objects', {
      mail_contract_id,
      nf_type,
      prefix_country,
      service_order_number,
      valid_out_objects_batch_id,
    });
  }

  async createValidOutObjectBatch(mail_contract_id) {
    return api.post(`/mail/valid-out-objects-batch`, { mail_contract_id });
  }

  async addValidOutObjectToBatch(valid_out_objects_id, mail_contract_id) {
    return api.put(`/mail/valid-out-objects-batch`, { valid_out_objects_id, mail_contract_id });
  }

  async updateSinister(sinister) {
    return api.put(`/mail/updateSinister`, { sinister });
  }

  async updateToFinishContract(mail_contract_id) {
    return api.put(`/mail/valid-out-objects-batch/finish`, { mail_contract_id });
  }

  async listMailContractsGeneral(skip, take, searchFilter, postData) {
    return api.get(
      `/mail/valid-out-objects?skip=${skip}&take=${take}&searchFilter=${searchFilter}&postData=${postData}`,
    );
  }

  async listValidOutObjectsContractActive(mail_contract_id) {
    return api.get(`/mail/valid-out-objects-batch/${mail_contract_id}`);
  }

  async impressMinuteBatch(id) {
    return api.get(`/mail/impress-minute/batch/${id}`, {
      responseType: 'arraybuffer',
    });
  }

  async listValidOutObjectsBatchDone() {
    return api.get(`/mail/valid-out-objects-batch/done`);
  }

  async createObjectBlockingReason(reason) {
    return api.post('/mail/blocked-reason', { reason });
  }

  async alterObjectBlockingReason(id, reason) {
    return api.put('/mail/blocked-reason', { id, reason });
  }

  async inativeObjectBlockingReason(id) {
    return api.delete('/mail/blocked-reason', { id });
  }

  async listObjectBlockingReason() {
    return api.get('/mail/blocked-reason');
  }
}

export const mailServices = new MailServices();
