import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';
import XLSX from 'xlsx';

export const CostReport = () => {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const handleDownloadNonComplianceReport = async () => {
    toast.info('Gerando o relatório de custo...');
    try {
      const { data } = await serviceOrderService.costReport(inputData, outputData);

      const now = new Date();
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          mySheet: worksheet,
        },
        SheetNames: ['mySheet'],
      });
      XLSX.writeFile(workbook, `RelatorioFinalSeguradora${now}.xlsx`);

      toast.success('Relatório gerado com sucesso.');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Erro ao gerar o relatório de custo');
    }
  };

  return (
    <>
      <PageTitle>Relatório de custos</PageTitle>
      <Spacer size={15} />
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
        onSubmit={handleDownloadNonComplianceReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                />
              </Grid>
              <Spacer size={15} />
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button loading={props?.isSubmitting} fullWidth type='submit'>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
