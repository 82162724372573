import React from 'react';
import { useRouteMatch } from 'react-router';

import { Pcp } from 'pages/Private/Pcp/Geral';
import { PcpInhome } from 'pages/Private/Pcp/GeralInhome';
import { PcpRh } from 'pages/Private/Pcp/GeralRh';
import { OutOfWarrantyBudget } from 'pages/Private/Pcp/OutOfWarrantyBudget';
import { WaitingFinishInhome } from 'pages/Private/Pcp/WaitingFinishInhome';
import { WaitingWithdrawal } from 'pages/Private/Pcp/WaitingWithdrawal';

import { Route } from '../Route';

export const PcpRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/general`} component={Pcp} />
      <Route isPrivate path={`${path}/inhomeservice`} component={PcpInhome} />
      <Route isPrivate path={`${path}/awaiting-rh`} component={PcpRh} />
      <Route isPrivate path={`${path}/out-of-warranty-budget`} component={OutOfWarrantyBudget} />
      <Route isPrivate path={`${path}/awaiting-withdrawal`} component={WaitingWithdrawal} />
      <Route isPrivate path={`${path}/awaiting-finishinhome`} component={WaitingFinishInhome} />
    </>
  );
};
