import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 8,
  },
  title: {
    fontWeight: 700,
  },
  detailKey: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  detailValue: {
    fontSize: 16,
    fontWeight: 300,
  },
  detailKey: {
    fontSize: 16,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  detailValue: {
    fontSize: 16,
    fontWeight: 300,
  },
}));
