import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  cgc: yup
    .string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
});
