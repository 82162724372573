import { gql } from '@apollo/client';

export const VALID_B2C_ORDER = gql`
  mutation ValidB2COrder($data: [ValidB2COrderInput!]!) {
    validB2COrder(data: $data) {
      anymarketId
      orderCode
      marketplace
      status
      deliveryMethod
      orderDate
      client
      cgc
      __typename @skip(if: true)
      items {
        sku
        totalProductValue
        __typename @skip(if: true)
      }
    }
  }
`;

export const CREATE_B2C_PRODUCTS = gql`
  mutation CreateB2COrder($data: [CreateB2COrderInput!]!) {
    createB2COrder(data: $data)
  }
`;

export const LIST_ALL_ORDERS_FOR_PICKING = gql`
  query {
    listAllOrdersForPicking {
      anymarketId
      orderCode
      marketplace
      client
      requestDate
      status
      statusId
      requestSku
      requestProduct
      suggestionSinister
      suggestionImei
      suggestionProduct
      suggestionLocation
      suggestionCreatedAt
      __typename @skip(if: true)
    }
  }
`;

export const VERIFY_PRODUCTS_AVAILABLE = gql`
  query VerifyProductsAvaible($headerId: String!) {
    verifyProductsAvaible(headerId: $headerId) {
      input {
        id
        anymarketId
        sku
        value
        __typename @skip(if: true)
      }
      output {
        sinister
        imei
        productSku
        gradeId
        createdAt
        __typename @skip(if: true)
      }
    }
  }
`;

export const RESERVE_PRODUCTS = gql`
  mutation ReserveProducts($data: ReserveProductsInput!) {
    reserveProducts(data: $data)
  }
`;

export const LIST_PRODUCTS_FOR_PICKING = gql`
  query ListProductsForPicking($headerId: String!) {
    listProductsForPicking(headerId: $headerId) {
      pickingId
      sinister
      imei
      stockAddress
      __typename @skip(if: true)
    }
  }
`;

export const CHECK_PRODUCT = gql`
  mutation CheckProduct($pickingId: Int!) {
    checkProduct(pickingId: $pickingId)
  }
`;

export const CREATE_B2C_BOX = gql`
  mutation CreateB2CBox($headerId: String!) {
    createB2CBox(headerId: $headerId)
  }
`;

export const LIST_B2C_BOXS = gql`
  query ListB2CBoxs($headerId: String!) {
    listB2CBoxs(headerId: $headerId) {
      id
      anymarketId
      finalized
      boxNumber
      __typename @skip(if: true)
      b2cOrders {
        orderCode
        marketplace
        __typename @skip(if: true)
        client {
          name
          __typename @skip(if: true)
        }
      }
      _count {
        B2CBoxItens
        __typename @skip(if: true)
      }
    }
  }
`;

export const FINISH_B2C_BOX = gql`
  mutation FinishB2CBox($boxId: Int!) {
    finishB2CBox(boxId: $boxId)
  }
`;

export const INCLUDE_SINISTER_IN_BOX = gql`
  mutation IncludeSinisterInBox($boxId: Int!, $sinister: String!) {
    includeSinisterInBox(boxId: $boxId, sinister: $sinister)
  }
`;

export const LIST_B2C_BOX_ITENS = gql`
  query ListB2CBoxsItens($boxId: Int!, $limit: Int!, $offset: Int!) {
    listB2CBoxsItens(boxId: $boxId, limit: $limit, offset: $offset) {
      b2CPicking {
        id
        b2cProductsId
        sinister
      }
    }
  }
`;

export const FINISH_B2C_PICKING = gql`
  mutation FinishB2CPicking($headerId: String!) {
    finishB2CPicking(headerId: $headerId)
  }
`;

export const LIST_ALL_ORDERS_FOR_INVOICE = gql`
  query {
    listAllOrdersForInvoice {
      sinister
      imei
      sku
      product
      anymarketId
      orderCode
      client
      __typename @skip(if: true)
    }
  }
`;

export const INCLUDE_XML = gql`
  mutation IncludeXML($xmlData: String!, $anymarketId: String!) {
    includeXML(xmlData: $xmlData, anymarketId: $anymarketId)
  }
`;

export const LIST_ALL_ORDERS_FOR_EXPEDITION = gql`
  query {
    listAllOrdersForExpedition {
      anymarketId
      orderCode
      marketplace
      createdAt
      __typename @skip(if: true)
      client {
        name
        __typename @skip(if: true)
      }
      businessStatus {
        id
        description
        __typename @skip(if: true)
      }
      B2CInvoices {
        number
        key
        s3
        __typename @skip(if: true)
      }
    }
  }
`;

export const VALIDATE_PICKING = gql`
  mutation ValidatePicking($data: [ValidatedPickingInput!]!) {
    validatePicking(data: $data)
  }
`;
