import React, { useState } from 'react';

import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';

import { Register } from './Register';
import { Search } from './Search';
const RegisterComponent = () => {
  const [components, setComponents] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [startIndex, setStartIndex] = useState(1);

  const [componentsData, , loadingComponents, refetchComponentsCashier] = useQuery(
    () => componentService.listGroupComponent(startIndex, rowsPerPage, search.trim()),
    [startIndex, rowsPerPage, search],
  );
  return (
    <>
      <PageTitle>Cadastrar componente teste</PageTitle>
      <Register setComponents={setComponents} />
      <Search
        components={componentsData}
        loadingComponents={loadingComponents}
        page={page}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
        search={search}
        setSearch={setSearch}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        refetchComponentsCashier={refetchComponentsCashier}
      />
    </>
  );
};

export default RegisterComponent;
