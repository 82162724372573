import React from 'react';

import { Tabs } from 'components';

import { TableFinished } from './TableFinished';
import { TableNegociation } from './TableNegociation';
import { TableOverview } from './TableOverview';
import { TableSac } from './TableSac';
import { TableSacSeg } from './TableSacSeg';
import { TableSafe2Pay } from './TableSafe2Pay';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='SAC' disablePadding>
        <TableSac />
      </Tabs.Content>
      <Tabs.Content label='SAC-SEGURADORA' disablePadding>
        <TableSacSeg />
      </Tabs.Content>
      <Tabs.Content label='Safe2Pay' disablePadding>
        <TableSafe2Pay />
      </Tabs.Content>
      <Tabs.Content label='Em negociação' disablePadding>
        <TableNegociation />
      </Tabs.Content>
      <Tabs.Content label='Finalizados' disablePadding>
        <TableFinished />
      </Tabs.Content>
      <Tabs.Content label='Overview' disablePadding>
        <TableOverview />
      </Tabs.Content>
    </Tabs>
  );
};
