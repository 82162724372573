import React from 'react';
import { useRouteMatch } from 'react-router';

import { ImportServices } from 'pages/Private/Implementation/ImportServices';
import { AdvancedConsult } from 'pages/Private/ServiceOrder/AdvancedConsult';
import { AlterServiceGaia } from 'pages/Private/ServiceOrder/AlterServiceGaia';
import { CancelServiceGaia } from 'pages/Private/ServiceOrder/CancelServiceGaia';
import { Consult } from 'pages/Private/ServiceOrder/Consult';
import { Opening } from 'pages/Private/ServiceOrder/Opening';
import { ReprintTag } from 'pages/Private/ServiceOrder/ReprintTag';
import { UpdateServiceGSPN } from 'pages/Private/ServiceOrder/UpdateServiceGspn';

import { Route } from '../Route';

export const ServiceOrderRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/import-services`} component={ImportServices} />
      <Route isPrivate path={`${path}/opening`} component={Opening} />
      <Route isPrivate path={`${path}/consult`} component={Consult} />
      <Route isPrivate path={`${path}/reprint-service-tag`} component={ReprintTag} />
      <Route isPrivate path={`${path}/cancel-gaia-service`} component={CancelServiceGaia} />
      <Route isPrivate path={`${path}/advanced-consult`} component={AdvancedConsult} />
      <Route isPrivate path={`${path}/alter-gaia-service`} component={AlterServiceGaia} />
      <Route isPrivate path={`${path}/update-gaia-service`} component={UpdateServiceGSPN} />
    </>
  );
};
