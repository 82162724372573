import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, Text, Button, Select, Radio } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { accountService } from 'services';

import { validationSchema } from './validation';

export const ListProfilesDialog = ({ open, handleClose, profile = {}, reloadProfiles }) => {
  if (!open) return null;

  console.log('Usuário: ', profile);

  const [roles, , loadingRoles] = useQuery(() => accountService.listUserRoles(), []);

  const rolesOptions = (roles || []).map((role) => ({
    value: role.id,
    label: role.description,
  }));

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await accountService.updateUser(values);

      console.log('Values: ', values);

      toast.success('Usuário atualizado com sucesso.');

      reloadProfiles();
      resetForm();
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>
        Usuário:
        {profile?.name}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            id: profile?.id,
            fullname: profile?.name,
            email: profile?.email,
            phone: profile?.phone,
            role_id: profile?.role_id,
            active: profile?.active,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      label='Nome Completo'
                      name='fullname'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      label='Email'
                      name='email'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      size='small'
                      fullWidth
                      name='role_id'
                      variant='outlined'
                      label='Grupo'
                      options={rolesOptions}
                      component={Select}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      label='Telefone'
                      name='phone'
                      mask='phone'
                      variant='outlined'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography variant='h6' style={{ fontSize: '16px' }}>
                      Ativar / Desativar Usuário
                    </Typography>
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Field
                      color='primary'
                      name='active'
                      value={true}
                      fullWidth
                      component={Radio}
                      label='Ativo'
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      color='primary'
                      name='active'
                      value={false}
                      fullWidth
                      component={Radio}
                      label='Inativo'
                    />
                  </Grid>
                  <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Atualizar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
