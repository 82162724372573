import { gql } from '@apollo/client';

export const LIST_PRODUCT = gql`
  query {
    listProduct {
      sku
      __typename @skip(if: true)
    }
  }
`;

export const LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR = gql`
  query {
    listProduct {
      sku
      brandId
      modelId
      storageId
      colorId
      __typename @skip(if: true)
      brand {
        id
        description
      }
      model {
        id
        description
      }
      storage {
        id
        description
      }
      color {
        id
        description
      }
    }
  }
`;
