/* eslint-disable no-restricted-imports */
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { Grid } from '@material-ui/core';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { LIST_CARRIER } from 'services/graphql/query/carrier';
import { ROMANIO_TAG } from 'services/graphql/query/pdf';
import {
  CREATE_RECEIVING_HEADER,
  CREATE_RECEIVING_ITEM,
  FINALIZE_RECEIVING,
  LIST_RECEIVING_HEADER,
} from 'services/graphql/query/receiving';

import { ProductCollectionForm } from '../ProductCollectionForm';
import { ReceivingCardList } from '../ReceivingCardList';
import { validationSchema } from './validation';

export function DockForm() {
  const [currentReceiving, setCurrentReceiving] = useState(null);

  const carrier = useQuery(LIST_CARRIER, {
    fetchPolicy: 'network-only',
  });

  const receivings = useQuery(LIST_RECEIVING_HEADER, {
    fetchPolicy: 'network-only',
  });

  const [createReceivingHeader] = useMutation(CREATE_RECEIVING_HEADER, {
    refetchQueries: [{ query: LIST_RECEIVING_HEADER }],
    onCompleted: () => {
      toast.success('Informações salvas com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [romanioTag] = useMutation(ROMANIO_TAG, {
    onCompleted: (data) => {
      const pdfBase64 = data?.romanioTag || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `romaneio.pdf`;
      link.click();

      window.location.reload();
    },
    onError: (error) => {
      toast.error('Erro ao gerar o PDF!');
      console.error('Erro ao gerar o PDF:', error);
    },
  });

  const [finalizeReceiving] = useMutation(FINALIZE_RECEIVING, {
    refetchQueries: [{ query: LIST_RECEIVING_HEADER }],
    onCompleted: () => {
      toast.success('Recebimento finalizado');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao finalizar';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [createReceivingItem] = useMutation(CREATE_RECEIVING_ITEM, {
    refetchQueries: [{ query: LIST_RECEIVING_HEADER }],
    onCompleted: () => {
      toast.success('Item coletado com sucesso');

      setCurrentReceiving((prev) => ({
        ...prev,
        itemsCollected: (prev.itemsCollected || 0) + 1, // Atualiza o contador de itens coletados
      }));
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao coletar o item';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const carrierOptions = (carrier?.data?.listCarriers || []).map((object) => ({
    value: object.id,
    label: object.description,
  }));

  const handleStartReceiving = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await createReceivingHeader({
        variables: { data: values },
      });

      resetForm();
    } catch (error) {
      toast.error('Erro ao salvar');
    } finally {
      setSubmitting(false);
    }
  };

  const handleItemCollected = async (voucher) => {
    console.log('currentReceiving ==>', currentReceiving);
    try {
      await createReceivingItem({
        variables: { voucher, headerId: currentReceiving.id },
      });
    } catch (error) {
      toast.error('Erro ao coletar');
    }
  };

  const handleFinishReceiving = async (id) => {
    try {
      await finalizeReceiving({
        variables: { id },
      });

      await romanioTag({
        variables: { headerId: id },
      });
    } catch (error) {
      toast.error('Erro ao finalizar');
    }
  };

  return (
    <>
      {!currentReceiving && (
        <Formik
          initialValues={{ carrier: '', plate: '', driver: '', document: '' }}
          validationSchema={validationSchema}
          onSubmit={handleStartReceiving}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Transportadora'
                    name='carrier'
                    size='small'
                    component={Select}
                    options={carrierOptions}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Placa'
                    name='plate'
                    size='small'
                    component={Text}
                    mask='plate'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <Field
                    variant='outlined'
                    label='Motorista'
                    name='driver'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='CPF'
                    name='document'
                    size='small'
                    component={Text}
                    mask='document'
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                  <Button fullWidth type='submit' disabled={!props.dirty || !props.isValid}>
                    Iniciar Coleta
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      {currentReceiving && (
        <ProductCollectionForm
          onItemCollected={handleItemCollected}
          onReturn={setCurrentReceiving}
          data={currentReceiving}
        />
      )}

      {receivings?.data?.listReceivingNotFinalized && !currentReceiving && (
        <>
          <Spacer size={32} />
          <ReceivingCardList
            receivingList={receivings?.data?.listReceivingNotFinalized}
            onFinish={handleFinishReceiving}
            onContinue={setCurrentReceiving}
          />
        </>
      )}
    </>
  );
}
