import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import useMask from 'hooks/useMask';

export const ClientInfo = ({ clientInfo }) => {
  const { maskedValue: residential_phone_number } = useMask(
    'residential_phone_number',
    clientInfo?.residential_phone_number,
  );

  const { maskedValue: cpf } = useMask('cpf', clientInfo?.cgc);
  const { maskedValue: phone_number } = useMask('phone_number', clientInfo?.phone_number);

  return (
    <Grid container spacing={1} style={{ maxWidth: '45rem' }}>
      <Grid item xs={6}>
        <Typography>
          <strong>Nome: </strong>
          {clientInfo?.name}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <strong>Email: </strong>
          {clientInfo?.email}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <strong>CGC: </strong>
          {cpf}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <strong>Telefone Residencial: </strong>
          {clientInfo?.residential_phone_number ? residential_phone_number : ''}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <strong>Celular: </strong>
          {clientInfo?.phone_number ? phone_number : 'Numero de celular não cadastrado'}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography>
          <strong>Inscrição estadual: </strong>
          {clientInfo?.ie ? clientInfo?.ie : 'Não possui inscrição estadual'}
        </Typography>
      </Grid>
    </Grid>
  );
};
