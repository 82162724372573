import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as PushImg } from 'assets/images/push.svg';
import { Button, Select, Text } from 'components';
import { Form, Formik, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService, api } from 'services';
import { useContextSelector } from 'use-context-selector';

import { validationSchema } from './formik';
import { useStyles } from './styles';

export const GroupOfChanges = () => {
  const userId = useContextSelector(AuthContext, (context) => context.user.id);

  const [roles, , loadingRoles] = useQuery(() => api.get('/users/roles'), []);

  const rolesOptions = (roles || []).map((role) => ({
    value: role.id,
    label: role.description,
  }));

  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await accountService.changeUserRole(values.email, values.role_id, userId);

      toast.success('Grupo do usuário foi alterado com sucesso..');
      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Erro ao realizar a alteração do grupo do usuário.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle />
      <Formik
        initialValues={{ email: '', role_id: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <div className={classes.containerPaper}>
                <Typography gutterBottom variant='h6'>
                  Trocar de grupo
                </Typography>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='email'
                      variant='outlined'
                      size='small'
                      label='Email do usuário/Login'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name='role_id'
                      variant='outlined'
                      size='small'
                      label='Grupo'
                      loading={loadingRoles}
                      options={rolesOptions}
                      component={Select}
                    />
                  </Grid>
                  <Grid className={classes.btnContainer} item xs={12}>
                    <Button size='small' variant='text' color='primary'>
                      Cancelar
                    </Button>
                    <Button disabled={!props.dirty || !props.isValid} size='small' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </div>
              <PushImg className={classes.pushImg} />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
