import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  part_number: yup.string().required(),
  billing_id: yup.number(),
  quantity: yup.number().required().min(1),
  purchase_price: yup.number().required().min(0.01),

  description: yup.string().required(),
});
