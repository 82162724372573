import React, { createContext, useCallback, useEffect, useReducer, useState } from 'react';
import { FaCartPlus } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import WarningIcon from '@mui/icons-material/Warning';
import { Text, Button, Table, Select } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Form, Formik, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { api, componentService } from 'services';

import { Cart } from './Cart';
import {
  addToCartAction,
  addLaborToCartAction,
  cleanCartAction,
  addToCartWholesaleAction,
} from './Cart/store/actions';
import { cartReducer } from './Cart/store/reducer';
import { OrderDialog } from './OrderDialog';
import { useStyles } from './styles';

const columns = [
  { label: '#', width: 40, align: 'center' },
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Preço (un)' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Ações', align: 'center', width: 180 },
];

const columnsLabor = [
  { label: '#', width: 40, align: 'center' },
  { label: 'Descrição' },
  { label: 'Descrição do Produto' },
  { label: 'Preço (un)' },
  { label: 'Ações', align: 'center', width: 180 },
];

export const CartContext = createContext();
export const initialCartState = [];

const serviceTypeOptions = [
  {
    value: 1,
    label: 'Venda de acessórios',
  },
  {
    value: 2,
    label: 'Serviços',
  },
  {
    value: 3,
    label: 'Venda de acessórios (ATACADO)',
  },
];

export const AcessoryAvailable = () => {
  const classes = useStyles();

  const [componentsCashier, setComponentsCashier] = useState([]);
  const [open, setOpenState] = useBoolean();
  const [openCart, setOpenCart] = useBoolean();
  const [selectedComponent, setSelectedComponent] = useState(0);
  const [ordering, setOrdering] = useState();
  const [totalCart, setTotalCart] = useState(0);
  const [state, dispatch] = useReducer(cartReducer, initialCartState);

  const [serviceTypeSelect, setServiceTypeSelect] = useState();
  const [labors, setLabors] = useState([]);
  const [search, setSearch] = useState('');

  const [componentsCashierWholesale, setComponentsCashierWholesale] = useState([]);

  // Exemplo
  const [componentsCashierData, , loadingComponentsCashierData, refetchComponentsCashier] =
    useQuery(componentService.listComponentsCashier, []);

  const [laborsData, , loadingLabors, refetchLabors] = useQuery(
    () => api.get('/cashier/labor/nogspn'),
    [],
  );

  const [
    componentsCashierWholesaleData,
    ,
    loadingComponentsCashierWholesaleData,
    refetchComponentsCashierWholesale,
  ] = useQuery(componentService.listComponentsCashierWholesale, []);

  const totalItens = useCallback(
    (setTotalCart) => {
      const total = state.reduce((total, { sub_total }) => {
        return total + sub_total;
      }, 0);

      if (!state.length) {
        return setTotalCart(0);
      }

      setTotalCart(total);
    },
    [state],
  );

  useEffect(() => {
    cleanCartAction(dispatch);
  }, [serviceTypeSelect]);

  useEffect(() => {
    setComponentsCashier(componentsCashierData);
    totalItens(setTotalCart);
  }, [componentsCashierData, totalItens]);

  useEffect(() => {
    setComponentsCashierWholesale(componentsCashierWholesaleData);
    totalItens(setTotalCart);
  }, [componentsCashierWholesaleData, totalItens]);

  const onSelectRowClick = (row) => {
    setSelectedComponent(row);
  };

  const formattedComponentsCashier = (componentsCashierData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedLabors = (laborsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedComponentsCashierWholesale = (componentsCashierWholesaleData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredComponentsCashier = (formattedComponentsCashier || []).filter(
    (componentsCashier) => {
      const lowerPartNumber = componentsCashier?.part_number?.toLowerCase();
      const upperPartNumber = componentsCashier?.part_number?.toUpperCase();

      const lowerDescription = componentsCashier?.description?.toLowerCase();
      const upperDescription = componentsCashier?.description?.toUpperCase();

      return (
        lowerPartNumber?.includes(search) ||
        upperPartNumber?.includes(search) ||
        lowerDescription?.includes(search) ||
        upperDescription?.includes(search)
      );
    },
  );

  const filteredLabors = (formattedLabors || []).filter((laborsNoGspn) => {
    const lowerDescription = laborsNoGspn?.description?.toLowerCase();
    const upperDescription = laborsNoGspn?.description?.toUpperCase();

    const lowerProductDescription = laborsNoGspn?.product?.description?.toLowerCase();
    const upperProductDescription = laborsNoGspn?.product?.description?.toUpperCase();

    return (
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerProductDescription?.includes(search) ||
      upperProductDescription?.includes(search)
    );
  });

  const filteredComponentsCashierWholesale = (formattedComponentsCashierWholesale || []).filter(
    (componentsCashier) => {
      const lowerPartNumber = componentsCashier?.part_number?.toLowerCase();
      const upperPartNumber = componentsCashier?.part_number?.toUpperCase();

      const lowerDescription = componentsCashier?.description?.toLowerCase();
      const upperDescription = componentsCashier?.description?.toUpperCase();

      return (
        lowerPartNumber?.includes(search) ||
        upperPartNumber?.includes(search) ||
        lowerDescription?.includes(search) ||
        upperDescription?.includes(search)
      );
    },
  );

  useEffect(() => {}, [
    filteredComponentsCashier,
    filteredLabors,
    filteredComponentsCashierWholesale,
  ]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {}, [
    formattedComponentsCashier,
    formattedComponentsCashierWholesale,
    filteredLabors,
    serviceTypeSelect,
  ]);

  const handleServiceType = (row) => {
    setServiceTypeSelect(row);
  };

  useEffect(() => {
    setLabors(laborsData);
  }, [laborsData]);

  return (
    <>
      <Formik initialValues={{ serviceType: '' }}>
        <Form>
          <OrderDialog
            open={open}
            onClose={setOpenState.toFalse}
            selectedComponent={selectedComponent}
            setOrdering={setOrdering}
          />

          <Grid className={classes.root} container justifyContent='space-between' spacing={2}>
            <Grid item xs={12} md={4} lg={4}>
              <Text
                label='Pesquisar'
                size='small'
                variant='outlined'
                fullWidth
                endAdornment={<SearchIcon />}
                onChange={handleSearchChange}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                variant='outlined'
                name='serviceType'
                label='Tipo de serviço'
                size='small'
                component={Select}
                options={serviceTypeOptions}
                onChange={handleServiceType}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={setOpenCart.toTrue}
                    loading={ordering}
                    fullWidth
                    size='small'
                    className={classes.buttonOpenCart}
                  >
                    <div className={state.length ? classes.cartCount : classes.cartEmpty}>
                      {state.length}
                    </div>
                    <FaCartPlus size={30} />
                  </Button>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Button
                    onClick={setOpenState.toTrue}
                    disabled={!selectedComponent}
                    loading={ordering}
                    fullWidth
                    size='small'
                  >
                    Encomendar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CartContext.Provider
                value={{
                  serviceTypeSelect,
                  state,
                  dispatch,
                  totalCart,
                  refetchComponentsCashier,
                  refetchLabors,
                  refetchComponentsCashierWholesale,
                }}
              >
                <Cart open={openCart} onClose={setOpenCart.toFalse} />
                <Table
                  maxHeight
                  headers={
                    serviceTypeSelect == 1 || serviceTypeSelect == 3 ? columns : columnsLabor
                  }
                  emptyMessage='Nenhum registro encontrado.'
                  striped
                  disableNumeration
                >
                  {serviceTypeSelect == 1 ? (
                    filteredComponentsCashier?.map((accessory) => (
                      <TableRow key={accessory.id}>
                        <TableCell>
                          <Radio
                            color='primary'
                            size='small'
                            checked={selectedComponent?.id === accessory.id}
                            onChange={() => onSelectRowClick(accessory)}
                          />
                        </TableCell>
                        <TableCell>{accessory?.part_number}</TableCell>
                        <TableCell>{accessory.description}</TableCell>
                        <TableCell>
                          <FormatMoney>{accessory.component_acessory_price ?? 0}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>{accessory.quantity}</TableCell>
                        <TableCell>
                          <Button
                            variant='outlined'
                            disabled={accessory.quantity > 0 ? false : true}
                            onClick={() => addToCartAction(accessory, dispatch)}
                            className={classes.buttonAddToCart}
                          >
                            Adicionar ao carrinho
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {serviceTypeSelect == 2 ? (
                    filteredLabors?.map((labor) => (
                      <TableRow key={labor.id}>
                        <TableCell>
                          <Radio
                            color='primary'
                            size='small'
                            checked={selectedComponent?.id === labor.id}
                            onChange={() => onSelectRowClick(labor)}
                          />
                        </TableCell>
                        <TableCell>{labor?.description}</TableCell>
                        <TableCell>{labor?.product?.description}</TableCell>
                        <TableCell>
                          <FormatMoney>{labor?.labor_price}</FormatMoney>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant='outlined'
                            onClick={() => addLaborToCartAction(labor, dispatch)}
                            className={classes.buttonAddToCart}
                          >
                            Adicionar ao carrinho
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {serviceTypeSelect == 3 ? (
                    filteredComponentsCashierWholesale?.map((accessory) => (
                      <TableRow key={accessory.id}>
                        <TableCell>
                          <Radio
                            color='primary'
                            size='small'
                            checked={selectedComponent?.id === accessory.id}
                            onChange={() => onSelectRowClick(accessory)}
                          />
                        </TableCell>
                        <TableCell>{accessory?.part_number}</TableCell>
                        <TableCell>{accessory.description}</TableCell>
                        <TableCell>
                          <FormatMoney>
                            {accessory.component_acessory_price_wholesale ?? 0}
                          </FormatMoney>
                        </TableCell>
                        <TableCell align='center'>{accessory.quantity}</TableCell>
                        <TableCell>
                          <Button
                            variant='outlined'
                            disabled={accessory.quantity > 0 ? false : true}
                            onClick={() => addToCartWholesaleAction(accessory, dispatch)}
                            className={classes.buttonAddToCart}
                          >
                            Adicionar ao carrinho
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </Table>
              </CartContext.Provider>
            </Grid>
            <Grid item xs={12} lg={3}>
              <div className={classes.containerLegend}>
                <WarningIcon color='warning' />
                <p variant='caption' className={classes.p}>
                  Selecione a emissão fiscal correta no momento da venda.
                </p>
              </div>
            </Grid>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
