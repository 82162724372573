import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Select } from 'components';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { Text } from 'components/Form/Text';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { api, componentService } from 'services';

import { StockContext } from '../..';
import { useStyles } from './styles';
import { validationSchema } from './validation';

export const ConfirmDialog = ({ open, onClose, selectedOrdering, dialogProps }) => {
  const {
    part_number,
    description,
    quantity,
    stock_location,
    component_level_location_id,
    location_id,
  } = selectedOrdering;
  const classes = useStyles();

  const { reFetchComponentsAccessories, reFetchComponentsCashier } = useContext(StockContext);

  const [stockLocations, , loadingStockLocations, stockLocationsRefetch] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(component_level_location_id),
    [component_level_location_id],
  );

  const locationsOptions = (stockLocations || []).map((location) => ({
    value: location.id,
    label: location.location_name,
  }));

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await api.post('/components/stock/location/move', values);

      toast.success('Componente transferido com sucesso');

      reFetchComponentsAccessories();
      reFetchComponentsCashier();

      resetForm();
      onClose();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm'>
      <Formik
        initialValues={{
          amount_handled: 0,
          part_number,
          from_location_level_id: component_level_location_id,
          to_location_level_id: component_level_location_id,
          old_location_id: location_id,
          location_id: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Dialog.Title onClose={onClose}>{dialogProps.title}</Dialog.Title>
            <Dialog.Content>
              <Grid spacing={2} container>
                <Grid item md={12}>
                  <Typography>
                    <strong className={classes.marginContext}>Part Number:</strong>
                    {part_number}
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography>
                    <strong className={classes.marginContext}>Descrição:</strong>
                    {description}
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography>
                    <strong className={classes.marginContext}>Local:</strong>
                    {stock_location?.location_name}
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Field
                    size='small'
                    fullWidth
                    name='location_id'
                    variant='outlined'
                    label='Tipo de estoque'
                    emptyOption='Selecione'
                    loading={loadingStockLocations}
                    options={locationsOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item md={6}>
                  <Typography>
                    <Field
                      name='amount_handled'
                      variant='outlined'
                      label='Quantidade'
                      size='small'
                      inputProps={{
                        min: 1,
                        max: quantity,
                      }}
                      mask='number'
                      component={Text}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <br />
            </Dialog.Content>
            <Dialog.Actions>
              <Button onClick={onClose} variant='text' color='default'>
                Não
              </Button>
              <Button type='submit' disabled={!props.isValid || !props.dirty}>
                Sim
              </Button>
            </Dialog.Actions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
