import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Spacer, Table, FormatMoney } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';

import { PreBudgetTecInformation } from './PreBudgetTecInformation';

const columns = [
  { label: 'Técnico' },
  { label: 'Service' },
  { label: 'Cliente' },
  { label: 'Total' },
  { label: 'Status' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const PreBudgetTecCheck = () => {
  const [search, setSearch] = useState('');

  const [preBudgetTec, , loadingPreBudgetTec] = useQuery(
    () => budgetService.listPreBudgetTec(),
    [],
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [preBudgetTecDetails, setPreBudgetTecDetails] = useState({});

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setPreBudgetTecDetails(item);
  };

  console.log('Pre Budget Tec: ', preBudgetTec);

  const formattedPreBudgetTec = (preBudgetTec || []).map((b) => {
    return {
      ...b,
    };
  });

  useEffect(() => {}, [formattedPreBudgetTec]);

  const filteredPreBudgetsTec = formattedPreBudgetTec.filter((preBudget) => {
    const lowerClientName = preBudget.client?.name.toLowerCase();
    const upperClientName = preBudget.client?.name.toUpperCase();

    const lowerUserName = preBudget.user?.name?.toLowerCase();
    const upperUserName = preBudget.user?.name?.toUpperCase();

    const lowerDescription = preBudget.description?.toLowerCase();
    const upperDescription = preBudget.description?.toUpperCase();

    const lowerService = preBudget?.service_order?.number?.toLowerCase();
    const upperService = preBudget?.service_order?.number?.toLowerCase();
    const equalService = preBudget?.service_order?.number;

    return (
      lowerClientName?.includes(search) ||
      upperClientName?.includes(search) ||
      lowerUserName?.includes(search) ||
      upperUserName?.includes(search) ||
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerService?.includes(search) ||
      upperService?.includes(search) ||
      equalService?.includes(search)
    );
  });

  useEffect(() => {
    console.log('filteredPreBudgetsTec: ', filteredPreBudgetsTec);
  }, [filteredPreBudgetsTec]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <PageTitle>Consultar Pré Orçamento Técnico</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            headers={columns}
            loading={loadingPreBudgetTec}
            // actionsLabel={<VisibilityIcon />}
            // onActionsClick={handleOpenInformation}
            data={filteredPreBudgetsTec}
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
            emptyMessage='Nenhum registro encontrado.'
            striped
          >
            {filteredPreBudgetsTec?.map((preBudget) => (
              <TableRow key={preBudget?.id}>
                {console.log('Pre Budget: ', preBudget)}
                <TableCell>{preBudget?.user?.name}</TableCell>
                <TableCell>{preBudget?.service_order?.number}</TableCell>
                <TableCell>{preBudget?.client?.name}</TableCell>
                <TableCell>
                  <FormatMoney>{preBudget?.total_items}</FormatMoney>
                </TableCell>
                <TableCell>{preBudget?.approved ? 'APROVADO' : 'REPROVADO'}</TableCell>
                <TableCell align='center'>
                  <IconButton color='primary' onClick={(e) => handleOpenInformation(e, preBudget)}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <PreBudgetTecInformation
          open={isDetailsOpen}
          handleClose={handleCloseInformation}
          preBudgetTecDetails={preBudgetTecDetails}
        />
      </Grid>
    </>
  );
};
