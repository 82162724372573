import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as Work } from 'assets/images/work.svg';
import { Button, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { AlterServiceGaiaDialog } from './AlterServiceGaiaDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

export const AlterServiceGaia = () => {
  const classes = useStyles();

  const [serviceData, setServiceData] = useState([]);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e) => {
    setIsDetailsState.toTrue();
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { number } = values;

      const { data } = await serviceOrderService.getByNumber(number);

      console.log(data);
      resetForm();
      console.log(data);
      setServiceData(data);

      handleOpenInformation();

      toast.success('Service consultada com sucesso');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao consultar');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Editar Dados da Service</PageTitle>
      <Formik
        initialValues={{ number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    name='number'
                    size='small'
                    label='Service'
                    // inputProps={{ maxLength: 10, minlength: 1 }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>
                <AlterServiceGaiaDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  serviceOrder={serviceData}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Grid className={classes.containerImg} container>
        <Work className={classes.trashImage} />
      </Grid>
    </>
  );
};
