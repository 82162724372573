import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  carrier: Yup.string().required('Campo obrigatório'),
  plate: Yup.string()
    .matches(
      /^[A-Z]{3}[0-9][A-Z0-9]-?[0-9]{2}$/,
      'Placa inválida, formato esperado: AAA-1234 ou AAA1A23',
    )
    .required('Campo obrigatório'),
  driver: Yup.string().required('Campo obrigatório'),
  document: Yup.string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
});
