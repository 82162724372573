import {
  addServiceToCart,
  removeServiceFromCart,
  updateServiceAmount,
  cleanCart,
} from './reducerFunctions';

export const cartServiceReducer = (state, action) => {
  switch (action.type) {
    case '@ADD_SERVICE_TO_CART':
      return addServiceToCart(state, action);

    case '@REMOVE_SERVICE_FROM_CART':
      return removeServiceFromCart(state, action);

    case '@UPDATE_SERVICE_AMOUNT':
      return updateServiceAmount(state, action);

    case '@CLEAN_CART':
      return cleanCart(state, action);

    default:
      break;
  }
};
