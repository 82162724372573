import React, { useContext, useEffect } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Text, FormatMoney, Spacer, Radio, Select } from 'components';
import { Field, useFormikContext } from 'formik';

import { BudgetCartContext } from '../..';
import { useStyles } from './styles';

export const ConfirmBudgetTec = () => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const {
    state,
    dispatch,
    totalCart,
    stateService,
    dispatchService,
    totalCartService,
    setTotalCartService,
  } = useContext(BudgetCartContext);
  const paymentStatuses = [
    {
      id: 1,
      description: 'Boleto',
    },
    {
      id: 2,
      description: 'Link de pagamento cartão de crédito',
    },
    {
      id: 6,
      description: 'PIX',
    },
  ];

  const discountOptions = [
    {
      value: 0,
      label: 'Sem desconto',
    },
    {
      value: 5,
      label: '5%',
    },
    {
      value: 10,
      label: '10%',
    },
    {
      value: 15,
      label: '15%',
    },
  ];

  const installmentOptions = [
    {
      value: 1,
      label: '1x sem juros',
    },
    {
      value: 2,
      label: '2x sem juros',
    },
    {
      value: 3,
      label: '3x sem juros',
    },
    {
      value: 4,
      label: '4x sem juros',
    },
    {
      value: 5,
      label: '5x sem juros',
    },
    {
      value: 6,
      label: '6x sem juros',
    },
  ];

  const FieldMethodPayment1 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method1', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 1
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method1'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const FieldMethodPayment2 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method2', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 2
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method2'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  const FieldMethodPayment3 = () => {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (paymentStatuses?.id) {
        setFieldValue('payment_method3', paymentStatuses?.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentStatuses?.id, setFieldValue]);

    return (
      <>
        <Typography variant='h6' gutterBottom>
          Método de Pagamento 3
        </Typography>

        <Grid className={classes.gridContainer} container spacing={1}>
          {paymentStatuses?.map((status) => (
            <Grid key={status.id} item>
              <Field
                color='primary'
                name='payment_method3'
                value={status.id}
                fullWidth
                component={Radio}
                label={status.description}
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  useEffect(() => {
    setFieldValue(
      'total_items',
      totalCart +
        totalCartService -
        parseFloat((totalCart + totalCartService) * values.discount) / 100,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.discount]);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.accessoriesInCart} variant='outlined'>
            <Typography component={'span'} variant='h6'>
              Meus Componentes:
            </Typography>
            {state?.map((component) => (
              <Grid container alignItems='center' key={component?.id}>
                <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                  <Typography component={'span'} variant='body2'>
                    PART-NUMBER: {component?.part_number}
                  </Typography>
                  <Typography component={'span'} variant='body2'>
                    DESCRIÇÃO DO PROCESSO: {component?.description}
                  </Typography>
                  <Typography component={'span'} variant='body2' className={classes.infoAccessory}>
                    <Typography component={'span'} variant='body2' className={classes.description}>
                      PREÇO(UN):
                      <Typography variant='body2'>
                        <FormatMoney>{component?.sell_price}</FormatMoney>
                      </Typography>
                    </Typography>
                    <Typography component={'span'} variant='body2' className={classes.quantity}>
                      QUANTIDADE:
                      <Typography component={'span'} variant='body2'>
                        {component?.amount}
                      </Typography>
                    </Typography>
                    <Typography component={'span'} variant='body2'>
                      SUBTOTAL:
                      <Typography component={'span'} variant='body2' className={classes.subTotal}>
                        <FormatMoney>{component?.sub_total}</FormatMoney>
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Typography component={'span'} variant='h6'>
              Meus Serviços:
            </Typography>
            {stateService?.map((service) => (
              <Grid container alignItems='center' key={service?.id}>
                <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                  <Typography component={'span'} variant='body2'>
                    SERVIÇO: {service?.description}
                  </Typography>
                  <Typography component={'span'} variant='body2'>
                    TIPO DO PRODUTO: {service?.additional_service_product?.description}
                  </Typography>
                  <Typography component={'span'} variant='body2' className={classes.infoAccessory}>
                    <Typography component={'span'} variant='body2' className={classes.description}>
                      PREÇO(UN):
                      <Typography component={'span'} variant='body2'>
                        <FormatMoney>{service?.service_price}</FormatMoney>
                      </Typography>
                    </Typography>
                    <Typography component={'span'} variant='body2' className={classes.quantity}>
                      QUANTIDADE:
                      <Typography variant='body2'>{service?.amount}</Typography>
                    </Typography>
                    <Typography component={'span'} variant='body2'>
                      SUBTOTAL:
                      <Typography component={'span'} variant='body2' className={classes.subTotal}>
                        <FormatMoney>{service?.sub_total}</FormatMoney>
                      </Typography>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '240px' }}
            variant='outlined'
          >
            <Typography component={'span'} variant='h6' gutterBottom>
              Resumo do pedido:
            </Typography>
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}
              spacing={2}
            >
              <Grid item xs={3}>
                <p>
                  {state.length} produto{state.length > 1 ? 's' : ''}
                </p>
              </Grid>
              <Grid item xs={3}>
                <FormatMoney>{totalCart}</FormatMoney>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='middle' />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}
              spacing={2}
            >
              <Grid item xs={3}>
                <p>
                  {stateService.length} serviço{stateService.length > 1 ? 's' : ''}
                </p>
              </Grid>
              <Grid item xs={3}>
                <FormatMoney>{totalCartService}</FormatMoney>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='middle' />
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography component={'span'} variant='h6' gutterBottom>
                  Total:
                </Typography>
              </Grid>
              <Grid>
                <Typography component={'span'} variant='h6' gutterBottom>
                  <FormatMoney>{totalCart + totalCartService}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography variant='h6' gutterBottom>
                  Valor de desconto:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' gutterBottom>
                  -{' '}
                  <FormatMoney>
                    {parseFloat(((totalCart + totalCartService) * values.discount) / 100)}
                  </FormatMoney>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography variant='h6' gutterBottom>
                  Total com Desconto:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' gutterBottom>
                  <FormatMoney>
                    {totalCart +
                      totalCartService -
                      parseFloat((totalCart + totalCartService) * values.discount) / 100}
                  </FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Typography variant='h6' gutterBottom>
            Dados do Técnico Responsável
          </Typography>

          <Grid className={classes.paymentMethod} container spacing={1}>
            <Grid item xs={12} lg={12}>
              <Field
                size='small'
                fullWidth
                name='tec_name'
                variant='outlined'
                label='Nome'
                component={Text}
                disabled
              />
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={0} />

          <Typography variant='h6' gutterBottom>
            Definição de valores do orçamento
          </Typography>

          <Grid className={classes.paymentMethod} container spacing={1}>
            <Grid item xs={12} lg={4}>
              <Field
                size='small'
                fullWidth
                name='total_items'
                variant='outlined'
                label='Valor dos itens'
                mask='money'
                component={Text}
                disabled
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <Field
                variant='outlined'
                name='discount'
                label='Desconto %'
                size='small'
                onFocus={(e) => setFieldValue('total_items', totalCart + totalCartService)}
                autoFocus
                component={Select}
                options={discountOptions}
              />
            </Grid>
          </Grid>

          <Grid className={classes.gridContainer} container spacing={0}>
            <Grid item xs={6} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={1}
                fullWidth
                component={Radio}
                label='1 Método de Pagamento'
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={2}
                fullWidth
                component={Radio}
                label='2 Métodos de Pagamentos'
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={3}
                fullWidth
                component={Radio}
                label='3 Métodos de Pagamentos'
              />
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={0} />

          {values.payment_type == 1 ? (
            <>
              <FieldMethodPayment1 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method1 == 1 ||
                      values?.payment_method1 == 4 ||
                      values?.payment_method1 == 6 ||
                      values?.payment_method1 == 10 ||
                      values?.payment_method1 == 11 ||
                      values?.payment_method1 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              {useEffect(() => {
                if (values.payment_type == 1) {
                  if (
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                  ) {
                    setFieldValue('installments', 1);
                    setFieldValue(
                      'installment_price',
                      parseFloat(values.total_items / values.installments).toFixed(2),
                    );
                  }

                  setFieldValue(
                    'installment_price',
                    parseFloat(values.total_items / values.installments).toFixed(2),
                  );

                  setFieldValue('payment_method2', null);
                  setFieldValue('total_price_card2', 0);
                  setFieldValue('installment_price2', 0);
                  setFieldValue('installments2', 1);

                  setFieldValue('payment_method3', null);
                  setFieldValue('total_price_card3', 0);
                  setFieldValue('installment_price3', 0);
                  setFieldValue('installments3', 1);
                }
              }, [
                setFieldValue,
                values?.total_items,
                values.payment_type,
                values?.payment_method1,
                values?.payment_method2,
                values.installment,
                values.installments,
                values.installments2,
                values.installment_price,
                values.installment_price2,
                values.total_price_card1,
                values.total_price_card2,
                values.discount,
              ])}
            </>
          ) : (
            ''
          )}

          {values.payment_type == 2 ? (
            <>
              <FieldMethodPayment1 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method1 == 1 ||
                      values?.payment_method1 == 4 ||
                      values?.payment_method1 == 6 ||
                      values?.payment_method1 == 10 ||
                      values?.payment_method1 == 11 ||
                      values?.payment_method1 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card1'
                    label='Valor total no cartão'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
              <FieldMethodPayment2 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments2'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method2 == 1 ||
                      values?.payment_method2 == 4 ||
                      values?.payment_method2 == 6 ||
                      values?.payment_method2 == 10 ||
                      values?.payment_method2 == 11 ||
                      values?.payment_method2 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Valor total no cartão'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              {useEffect(() => {
                if (values.payment_type == 2) {
                  if (
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                  ) {
                    setFieldValue('installments', 1);
                    setFieldValue(
                      'installment_price',
                      parseFloat(values.total_items / values.installments).toFixed(2),
                    );
                  }

                  if (
                    values?.payment_method2 == 1 ||
                    values?.payment_method2 == 4 ||
                    values?.payment_method2 == 6 ||
                    values?.payment_method2 == 10 ||
                    values?.payment_method2 == 11 ||
                    values?.payment_method2 == 13
                  ) {
                    setFieldValue('installments2', 1);
                    setFieldValue(
                      'installment_price2',
                      parseFloat(values.total_items / values.installments2).toFixed(2),
                    );
                  }

                  setFieldValue(
                    'installment_price',
                    parseFloat(values.total_price_card1 / values.installments).toFixed(2),
                  );
                  setFieldValue(
                    'installment_price2',
                    parseFloat(values.total_price_card2 / values.installments2).toFixed(2),
                  );

                  setFieldValue('payment_method3', null);
                  setFieldValue('total_price_card3', 0);
                  setFieldValue('installment_price3', 0);
                  setFieldValue('installments3', 1);
                }
              }, [
                setFieldValue,
                values.total_items,
                values.payment_type,
                values?.payment_method1,
                values?.payment_method2,
                values.installment,
                values.installments,
                values.installments2,
                values.installment_price,
                values.installment_price2,
                values.total_price_card1,
                values.total_price_card2,
                values.discount,
              ])}
            </>
          ) : (
            ''
          )}

          {values.payment_type == 3 ? (
            <>
              <FieldMethodPayment1 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method1 == 1 ||
                      values?.payment_method1 == 4 ||
                      values?.payment_method1 == 6 ||
                      values?.payment_method1 == 10 ||
                      values?.payment_method1 == 11 ||
                      values?.payment_method1 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card1'
                    label='Valor total no cartão'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <FieldMethodPayment2 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments2'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method2 == 1 ||
                      values?.payment_method2 == 4 ||
                      values?.payment_method2 == 6 ||
                      values?.payment_method2 == 10 ||
                      values?.payment_method2 == 11 ||
                      values?.payment_method2 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Valor total no cartão'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <FieldMethodPayment3 />
              <Grid container spacing={2}>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installments3'
                    label='Parcelas'
                    size='small'
                    type='number'
                    disabled={
                      values?.payment_method3 == 1 ||
                      values?.payment_method3 == 4 ||
                      values?.payment_method3 == 6 ||
                      values?.payment_method3 == 10 ||
                      values?.payment_method3 == 11 ||
                      values?.payment_method3 == 13
                        ? true
                        : false
                    }
                    component={Select}
                    options={installmentOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card3'
                    label='Valor total no cartão'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price3'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              {useEffect(() => {
                if (values.payment_type == 3) {
                  if (
                    values?.payment_method1 == 1 ||
                    values?.payment_method1 == 4 ||
                    values?.payment_method1 == 6 ||
                    values?.payment_method1 == 10 ||
                    values?.payment_method1 == 11 ||
                    values?.payment_method1 == 13
                  ) {
                    setFieldValue('installments', 1);
                    setFieldValue(
                      'installment_price',
                      parseFloat(values.total_items / values.installments).toFixed(2),
                    );
                  }

                  if (
                    values?.payment_method2 == 1 ||
                    values?.payment_method2 == 4 ||
                    values?.payment_method2 == 6 ||
                    values?.payment_method2 == 10 ||
                    values?.payment_method2 == 11 ||
                    values?.payment_method2 == 13
                  ) {
                    setFieldValue('installments2', 1);
                    setFieldValue(
                      'installment_price2',
                      parseFloat(values.total_items / values.installments2).toFixed(2),
                    );
                  }

                  if (
                    values?.payment_method3 == 1 ||
                    values?.payment_method3 == 4 ||
                    values?.payment_method3 == 6 ||
                    values?.payment_method3 == 10 ||
                    values?.payment_method3 == 11 ||
                    values?.payment_method3 == 13
                  ) {
                    setFieldValue('installments3', 1);
                    setFieldValue(
                      'installment_price3',
                      parseFloat(values.total_items / values.installments3).toFixed(2),
                    );
                  }

                  setFieldValue(
                    'installment_price',
                    parseFloat(values.total_price_card1 / values.installments).toFixed(2),
                  );
                  setFieldValue(
                    'installment_price2',
                    parseFloat(values.total_price_card2 / values.installments2).toFixed(2),
                  );

                  setFieldValue(
                    'installment_price3',
                    parseFloat(values.total_price_card3 / values.installments3).toFixed(2),
                  );
                }
              }, [
                setFieldValue,
                values.total_items,
                values?.payment_method1,
                values?.payment_method2,
                values?.payment_method3,
                values.installment,
                values.installments,
                values.installments2,
                values.installments3,
                values.installment_price,
                values.installment_price2,
                values.total_price_card1,
                values.total_price_card2,
                values.total_price_card3,
                values.discount,
                values.payment_type,
              ])}
            </>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </>
  );
};
