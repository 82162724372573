import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useMutation, useQuery } from '@apollo/client';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Button, Dialog, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import {
  CREATE_RI_INVOICE_AND_PO_PROCESS,
  FIND_RI_INVOICE_AND_PO_PROCESS,
} from 'services/graphql/query/service-orders';
import { CREATE_PRODUCT_IN_STOCK } from 'services/graphql/query/stock';
import { GET_BEST_STOCK_ADDRESS_BY_GRADE } from 'services/graphql/query/stock-address';

import { WaitingAllocationContext } from '..';

export function WaitingAllocationDialog({ isOpen = false, handleClose, selectedData }) {
  if (!isOpen) {
    return <></>;
  }
  const { refetch } = useContext(WaitingAllocationContext);

  const [isValidProduct, setIsValidProduct] = useState(false);

  const riAndInvoiceAndProcess = useQuery(FIND_RI_INVOICE_AND_PO_PROCESS, {
    skip: !isValidProduct,
    variables: { sinister: selectedData.sinister },
  });

  const [submit] = useMutation(CREATE_RI_INVOICE_AND_PO_PROCESS, {
    onCompleted: () => {
      toast.success('Dados salvos com sucesso');
      riAndInvoiceAndProcess.refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [submitNewProductInStock] = useMutation(CREATE_PRODUCT_IN_STOCK, {
    onCompleted: () => {
      toast.success('Dados salvos com sucesso');
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const locations = useQuery(GET_BEST_STOCK_ADDRESS_BY_GRADE, {
    skip: !isValidProduct,
    variables: { sinister: selectedData.sinister },
    fetchPolicy: 'network-only',
  });

  console.log('locations ==>', locations);

  async function handleValidationImeiOrVoucher(values, { setSubmitting, resetForm }) {
    setSubmitting(true);
    try {
      const imei = selectedData?.imei;
      const sinister = selectedData?.sinister;

      if (imei !== values.imei && sinister !== values.imei) {
        resetForm();
        toast.error('IMEI ou Voucher divergente do produto selecionado');
      } else {
        setIsValidProduct(true);
      }
    } catch (error) {
      toast.error('Erro ao realizar a validação');
    } finally {
      setSubmitting(false);
    }
  }

  async function handleSubmitValues(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await submit({
        variables: { data: values },
      });
    } catch (error) {
      toast.error('Erro ao salvar as informações');
    } finally {
      setSubmitting(false);
    }
  }

  async function handleSubmitNewProductInStock(values, { setSubmitting }) {
    setSubmitting(true);
    values.addressId = locations?.data?.getBestStockAddressByGrade?.id;
    try {
      await submitNewProductInStock({
        variables: { data: values },
      });
    } catch (error) {
      toast.error('Erro ao salvar as informações');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={() => handleClose()} maxWidth='sm'>
      <Dialog.Title onClose={() => handleClose()}>Alocar produto</Dialog.Title>
      <Dialog.Content>
        {!isValidProduct && (
          <Formik initialValues={{ imei: '' }} onSubmit={handleValidationImeiOrVoucher}>
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        name='imei'
                        component={Text}
                        label='Validar IMEI/Voucher'
                        size='small'
                        variant='outlined'
                        endAdornment={<QrCodeIcon />}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button type='submit' fullWidth>
                        Validar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        )}
        {isValidProduct && !riAndInvoiceAndProcess?.data && (
          <Formik
            initialValues={{
              ri: riAndInvoiceAndProcess?.data?.findRiInvoiceAndPoProcess?.ri ?? '',
              invoice: riAndInvoiceAndProcess?.data?.findRiInvoiceAndPoProcess?.invoice ?? '',
              sinister: selectedData?.sinister ?? '',
            }}
            onSubmit={handleSubmitValues}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <Field
                        name='ri'
                        component={Text}
                        label='Numero RI'
                        size='small'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field
                        name='invoice'
                        component={Text}
                        label='Nota Gerada'
                        size='small'
                        variant='outlined'
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button
                        type='submit'
                        fullWidth
                        disabled={!props.dirty}
                        loading={props.isSubmitting}
                      >
                        Confirmar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        )}
        {riAndInvoiceAndProcess?.data && (
          <Formik
            initialValues={{
              addressId: '',
              sinister: selectedData.sinister,
            }}
            onSubmit={handleSubmitNewProductInStock}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12}>
                      <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                        Alocar em: {locations?.data?.getBestStockAddressByGrade?.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Button type='submit' fullWidth loading={props.isSubmitting}>
                        Alocar
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>
        )}
      </Dialog.Content>
    </Dialog>
  );
}
