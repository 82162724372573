import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Select, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import { mailServices } from 'services/mail';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  mail_contract_id: yup.number().required(),
  mail_prefix_id: yup.number().required(),
  post_card: yup.string().required(),
});

export const RegisterSigepDialog = ({
  open,
  handleClose,
  mailContractOptions,
  mailPrefixOptions,
  sigep,
  reloadSigep,
}) => {
  // if (!open) return null;
  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('VALUUUUES: ', values);
      await mailServices.updateSigepRange(
        sigep?.id,
        values.mail_contract_id,
        values.mail_prefix_id,
        values.post_card,
      );

      console.log(sigep?.id, values?.mail_contract_id, values?.mail_prefix_id, values?.post_card);

      reloadSigep();
      toast.success('Sigep Range alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o Sigep Range.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose} className={classes.title}>
        Alterar: {sigep?.mailContract?.contract_method}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            mail_contract_id: sigep?.mailContract?.id,
            mail_prefix_id: sigep?.MailPrefix?.id,
            post_card: sigep?.post_card,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      fullWidth
                      name='mail_contract_id'
                      variant='outlined'
                      label='Contrato'
                      options={mailContractOptions}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      fullWidth
                      name='mail_prefix_id'
                      variant='outlined'
                      label='Prefixo'
                      options={mailPrefixOptions}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      variant='outlined'
                      label='Cartão Postagem'
                      name='post_card'
                      component={Text}
                    />
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='default' variant='text'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
