import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    containerPaper: {
      margin: 'auto',
      padding: '20px',
      width: '100%',
      maxWidth: 450,
      borderRadius: 8,
      border: '1px solid rgb(211, 211, 211)',

      display: 'flex',
      flexDirection: 'column',
    },
    btnContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    pushImg: {
      width: '100%',
      height: 200,
      maxWidth: 300,
      margin: '20px 0',
    },
  };
});
