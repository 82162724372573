import { api } from 'services/api';

export class ProvidersService {
  async createETicket(data) {
    return await api.post('/providers/correios/solicitarPostagemReversa', data);
  }

  async listEtickets() {
    return await api.get('/providers/e-ticket');
  }

  async calcFare(
    sCepOrigem,
    sCepDestino,
    nVlPeso,
    nCdFormato,
    nVlComprimento,
    nVlAltura,
    nVlLargura,
    nVlDiametro,
  ) {
    return await api.post('/providers/correios/calc-frete', {
      sCepOrigem,
      sCepDestino,
      nVlPeso,
      nCdFormato,
      nVlComprimento,
      nVlAltura,
      nVlLargura,
      nVlDiametro,
    });
  }
}

export const providerService = new ProvidersService();
