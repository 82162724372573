import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      minHeight: '100vh',
    },
    main: {
      flexGrow: 1,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flexGrow: 1,
      padding: 30,
      fontSize: 12,
      width: '100%',

      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('sm')]: {
        padding: 16,
      },
    },
    legend: {
      fontWeight: 'bold',
    },
    containerLegend: {
      display: 'flex',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '80px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    p: {
      marginLeft: '10px',
      fontSize: '1rem',
    },
  };
});
