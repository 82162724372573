import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as ProductImg } from 'assets/images/product.svg';
import { Button, Text, FormatDate, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
// import { api } from 'services/api';
import { quoteService } from 'services';
import * as Yup from 'yup';

import { useStyles } from './styles';

const columns = [
  { label: 'Nome' },
  { label: 'Data de cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const validationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
});

export const CreateQuoteSmartphoneUseTime = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [useTime, setUseTime] = useState({});

  const [smartphoneUseTime, , loadingSmartphoneUseTime, smartphoneUseTimeRefetch] = useQuery(
    () => quoteService.listSmartphoneUseTime(),
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setUseTime(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await quoteService.createSmartphoneUseTime(values);

      smartphoneUseTimeRefetch();
      toast.success('Tempo de uso cadastrado com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao cadastrar tempo de uso');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Cadastrar Tempo de uso para cotação de Smartphone</PageTitle>
      <Formik
        initialValues={{ description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Digite o nome do modelo'
                    name='description'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    loading={loadingSmartphoneUseTime}
                    emptyMessage='Sem marcas cadastrados.'
                    data={smartphoneUseTime}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {smartphoneUseTime?.map((model) => (
                      <TableRow key={model.id}>
                        <TableCell>{model.description}</TableCell>
                        <TableCell>
                          <FormatDate>{model.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, model)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                {/* <AlterQuoteSmartphoneBrand
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  brand={model}
                  reloadBrands={smartphoneModelsRefetch}
                /> */}
                <Grid className={classes.imgContainer} item xs={12} lg={6}>
                  <ProductImg className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
