import React, { useContext } from 'react';

import { Button } from 'components';
import { useFormikContext } from 'formik';

import { CartContext } from '../..';
import { registerClient } from '../ClientInfo';
import { useStyles } from './styles';

import { ClientContext } from '..';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values, resetForm } = useFormikContext();

  const { clientExists, setClientExists, hasClient, setHasClient } = useContext(ClientContext);

  const { totalCart } = useContext(CartContext);

  const classes = useStyles();

  // console.log('Values No Actions: ', values);
  // console.log('Total No Actions: ', totalCart);

  const isFirstStep = activeStep === 0;

  return (
    <div className={classes.footer}>
      <div className={classes.actions}>
        <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
          Voltar
        </Button>
        {activeStep === stepsSize - 1 && (
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={
              !isValid ||
              !dirty ||
              (values.payment_method == 11 && values.money_received < values.installment_price) ||
              (values.payment_method2 == 11 && values.money_received < values.installment_price2) ||
              (values.payment_method3 == 11 && values.money_received < values.installment_price3) ||
              (values.payment_method == 11 &&
                values.payment_method2 == 11 &&
                values.money_received < values.total) ||
              (values.payment_method == 11 &&
                values.payment_method2 == 11 &&
                values.payment_method3 == 11 &&
                values.money_received < values.total) ||
              !values.payment_method ||
              (values.payment_type == 2 && values.payment_method2 == 0) ||
              (values.payment_type == 3 && values.payment_method3 == 0) ||
              (values.payment_type == 1 &&
                values.installment_price * values.installments < values.total) ||
              (values.payment_type == 2 &&
                values.total_price_card + values.total_price_card2 < values.total) ||
              (values.payment_type == 3 &&
                values.total_price_card + values.total_price_card2 + values.total_price_card3 <
                  values.total)
            }
            loading={isSubmitting}
          >
            Confirmar Venda
          </Button>
        )}
        {activeStep !== stepsSize - 1 &&
          (clientExists ? (
            <Button
              color='primary'
              loading={isSubmitting}
              onClick={() => registerClient(values, setClientExists, setHasClient, resetForm)}
            >
              Cadastrar cliente
            </Button>
          ) : (
            <Button color='primary' loading={isSubmitting} onClick={handleNext}>
              Próxima Página
            </Button>
          ))}
      </div>
    </div>
  );
};
