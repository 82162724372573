import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import cep from 'cep-promise';
import { Button, Dialog } from 'components';
import { Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { serviceOrderService, warehouseService } from 'services';

import { validationSchema } from './validationSchema';

import { WarehouseRequestsContext } from '..';

export function RequestFormDialog({ isOpen = false, handleClose }) {
  if (!open) {
    return <></>;
  }

  const { refetchRequests } = useContext(WarehouseRequestsContext);

  const [loadingClient, setLoadingClient] = useState(false);
  const [loadingCep, setLoadingCep] = useState(false);

  const onBlurCep = async ({ setFieldValue, values }) => {
    setLoadingCep(true);
    const { state, city, neighborhood, street } = await cep(values.zipcode);

    setFieldValue('state', state);
    setFieldValue('city', city);
    setFieldValue('district', neighborhood);
    setFieldValue('street', street);

    setLoadingCep(false);
  };

  const handleCgcBlur = async ({ errors, setValues, values }) => {
    try {
      setLoadingClient(true);
      if (errors.cgc) return;

      const { data } = await serviceOrderService.getClientByCgc(values.cgc);

      const {
        addresses,
        id: client_id,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
      } = data;

      const clientFirstAddress = addresses[0];

      setValues({
        ...values,
        ...clientFirstAddress,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
        client_id,
      });
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
    } finally {
      setLoadingClient(false);
    }
  };

  async function onSubmit(values, { setSubmitting }) {
    try {
      setSubmitting(true);

      await warehouseService.createRequest(values);

      toast.success('Pedido cadastrado com sucesso');
      refetchRequests();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao cadastrar o pedido');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <Dialog.Title>Preencha o formulário abaixo para realizar o pedido</Dialog.Title>

      <Dialog.Content>
        <Formik
          initialValues={{
            model: '',
            cgc: '',
            street: '',
            street_number: '',
            zipcode: '',
            name: '',
            residential_phone_number: '',
            phone_number: '',
            city: '',
            state: '',
            email: '',
            district: '',
            complement: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form>
              <Grid style={{ padding: '15px', width: '100%' }} container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Field
                    variant='outlined'
                    label='Modelo'
                    name='model'
                    size='small'
                    component={Text}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='CPF/CNPJ do cliente'
                    name='cgc'
                    size='small'
                    component={Text}
                    mask='document'
                    onBlur={() => handleCgcBlur(props)}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={4} xl={4}>
                  <Field
                    variant='outlined'
                    label='Nome do cliente'
                    name='name'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Email'
                    name='email'
                    component={Text}
                    type='email'
                    size='small'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Telefone Fixo'
                    name='residential_phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <Field
                    variant='outlined'
                    label='Celular'
                    name='phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    fullWidth
                    name='zipcode'
                    mask='zipcode'
                    variant='outlined'
                    label='CEP'
                    component={Text}
                    onBlur={() => onBlurCep(props)}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Cidade'
                    name='city'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Estado'
                    name='state'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Bairro'
                    name='district'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Rua'
                    name='street'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Complemento'
                    name='complement'
                    size='small'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Número'
                    name='street_number'
                    size='small'
                    component={Text}
                    loading={loadingClient || loadingCep}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button color='secondary' variant='outlined' onClick={handleClose} fullWidth>
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Button
                    color='primary'
                    variant='contained'
                    type='submit'
                    fullWidth
                    disabled={!props.isValid || !props.dirty}
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
