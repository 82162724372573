import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TablePagination } from '@mui/material';
import { Dialog } from 'components';
import { FormatDate, Table } from 'components';

const columns = [
  { label: 'Descrição', align: 'center' },
  { label: 'Data', align: 'center' },
];

export function WaitingOracleChangesDialog({ isOpen = false, handleClose, logs }) {
  if (!isOpen) {
    return <></>;
  }

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <Dialog.Title onClose={handleClose}>Detalhes</Dialog.Title>
      <Dialog.Content>
        <Table
          headers={columns}
          striped
          emptyMessage='Nenhum produto encontrado.'
          loading={logs?.loading}
          disableNumeration
        >
          {logs?.map((item) => {
            return (
              <TableRow key={item?.id}>
                <TableCell align='center'>{item?.description}</TableCell>
                <TableCell align='center'>
                  <FormatDate>{item?.createdAt}</FormatDate>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>

        <TablePagination
          component='div'
          count={logs.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Dialog.Content>
    </Dialog>
  );
}
