import React, { useRef, useState } from 'react';
import { FiCopy, FiCheck } from 'react-icons/fi';

import { Button, Text } from 'components';

import { useStyles } from './styles';

export const Clipboard = ({ value }) => {
  const classes = useStyles();

  const [copied, setCopied] = useState(false);

  const inputRef = useRef(null);

  const handleCopy = () => {
    inputRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  return (
    <Text
      size='small'
      variant='outlined'
      value={value}
      endAdornment={
        <Button className={classes.copyButton} variant='text' onClick={handleCopy}>
          {copied ? <FiCheck size='18' /> : <FiCopy size='18' />}
        </Button>
      }
      ref={inputRef}
    />
  );
};
