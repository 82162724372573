import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useStyles } from './styles';

export const Loading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={40} color='primary' />
    </div>
  );
};
