import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/createComponentsImageImport.svg';
import { Button, Select } from 'components';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';

export const ImportStockComponents = () => {
  const [importing, setImporting] = useState(false);
  const [initialValues, setValues] = useState();
  const [componenteLevelLocationId, setComponentLevelLocationId] = useState();
  const [locationId, setLocationId] = useState();
  const classes = useStyles();

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const levelLocationsOptions = (levelLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.description,
  }));

  const [stockLocations, , loadingStockLocations] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(componenteLevelLocationId),
    [componenteLevelLocationId],
  );

  const stockLocationsOptions = (stockLocations || []).map((stockLocations) => ({
    value: stockLocations.id,
    label: stockLocations.location_name,
  }));

  const inputFileRef = useRef(null);
  const formikRef = useRef();

  const data = {
    manualComponents: {
      component_level_location_id: componenteLevelLocationId,
      location_id: locationId,
    },
  };

  function OBJtoXML(obj) {
    var xml = '';
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? '' : '<' + prop + '>';
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += '<' + prop + '>';
          xml += OBJtoXML(new Object(obj[prop][array]));
          xml += '</' + prop + '>';
        }
      } else if (typeof obj[prop] == 'object') {
        xml += OBJtoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? '' : '</' + prop + '>';
    }
    var xml = xml.replace(/<\/?[0-9]{1,}>/g, '');

    return xml;
  }

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const files = e.target.files;

      let xmlsArray = [];

      const reader = new FileReader();

      const readFile = (index) => {
        if (index >= files.length) return;
        let file = files[index];

        reader.onload = async (event) => {
          const result = event.target.result;

          const xmlDoc1 = new DOMParser().parseFromString(result, 'text/xml');
          const xmlDoc2 = new DOMParser().parseFromString(OBJtoXML(data), 'text/xml');

          const sources = xmlDoc2.evaluate(
            '//manualComponents',
            xmlDoc2,
            null,
            XPathResult.ORDERED_NODE_SNAPSHOT_TYPE,
            null,
          );

          const destination = xmlDoc1.querySelector('nfeProc');
          for (var i = 0; i < sources.snapshotLength; i++) {
            const node = sources.snapshotItem(i);
            const n_node = node.cloneNode(true);
            destination.append(n_node);
          }

          console.log('destination: ', destination.outerHTML);

          api
            .post('/invoices/import-xmls', destination.outerHTML, {
              headers: {
                'Content-Type': ' application/xml',
              },
            })
            .then((response) => {
              const { data } = response;

              console.log('Data: ', data);

              const { newProvider, ...importValues } = data;

              setValues({
                ...initialValues,

                ...importValues,
              });

              if (newProvider) {
                refetchProviders();
              }
            })
            .catch((err) => {
              console.log('ERROOO:', err?.response?.data?.message);
              toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
            });

          toast.info('Iniciando importação...');
          setTimeout(() => {
            toast.success('Importação Finalizada');
          }, 3000);

          xmlsArray.push(result);

          console.log('XMLS: ', xmlsArray);
          readFile(index + 1);
        };

        reader.readAsBinaryString(file);
      };

      readFile(0);

      // reader.readAsBinaryString(files);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xml'
        multiple
        onChange={handleFileChange}
        accept='text/xml'
        hidden
      />

      <PageTitle>Importar Estoque Por XML</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{
          component_level_location_id: '',
          location_id: '',
        }}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Paper className={classes.gridPaper} variant='outlined'>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      fullWidth
                      name='component_level_location_id'
                      variant='outlined'
                      label='Tipo de estoque'
                      emptyOption='Selecione'
                      loading={loadingLevelLocations}
                      disabled={importing}
                      options={levelLocationsOptions}
                      component={Select}
                      onChange={(e) => [setComponentLevelLocationId(e), setLocationId('')]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      fullWidth
                      name='location_id'
                      variant='outlined'
                      label='Local de estoque'
                      emptyOption='Selecione'
                      loading={loadingStockLocations}
                      options={stockLocationsOptions}
                      component={Select}
                      onChange={(e) => setLocationId(e)}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Button
                      fullWidth
                      onClick={handleImportClick}
                      type=''
                      disabled={importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Importar XML
                    </Button>
                  </Grid>

                  {importing ? (
                    <Grid xs={6} lg={12} className={classes.loading}>
                      <Box sx={{ width: '90%' }}>
                        <LinearProgress color='secondary' />
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                        </Grid>
                        <UploadImg className={classes.imgTracker} />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid className={classes.gridImages} xs={6} lg={12}>
                      {' '}
                      <UploadImg className={classes.imgTracker} />
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}></Grid>
                <Grid item xs={12} lg={3}></Grid>

                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Atenção! A consulta pode demorar alguns minutos.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
