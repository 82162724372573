import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { TableTabs } from './TableTabs';

export const IssuanceOfAnalysisNote = () => {
  return (
    <>
      <PageTitle>Emitir nota de análise</PageTitle>
      <TableTabs />
    </>
  );
};
