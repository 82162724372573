import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: '10px',
    },
    containerImg: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',
    },
    trashImage: {
      height: 250,
      marginTop: 50,
      position: 'relative',
      margin: 'auto 0',
    },
  };
});
