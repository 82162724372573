import React from 'react';

import { Dialog } from 'components';
import { Tabs } from 'components';

import { CosmeticScreeningDetail } from './CosmeticScreeningDetail';
import { FuntionalScreeningDetails } from './FuntionalScreeningDetails';
import { LogsDetails } from './LogsDetails';
export function MoreInfoDialog({ isOpen = false, handleClose, sinister }) {
  if (!isOpen) {
    return <></>;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg'>
      <Dialog.Title onClose={handleClose}>Detalhes</Dialog.Title>
      <Dialog.Content>
        <Tabs>
          <Tabs.Content label='Triagem Funcional' disablePadding>
            <FuntionalScreeningDetails sinister={sinister} />
          </Tabs.Content>
          <Tabs.Content label='Triagem Cosmética' disablePadding>
            <CosmeticScreeningDetail sinister={sinister} />
          </Tabs.Content>
          <Tabs.Content label='Logs' disablePadding>
            <LogsDetails sinister={sinister} />
          </Tabs.Content>
        </Tabs>
      </Dialog.Content>
    </Dialog>
  );
}
