import React, { createContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';

import { Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';

import { TableComponentsInWarranty } from './TableComponentsInWarranty';
import { TableComponentsOutOfWarranty } from './TableComponentsOutOfWarranty';

export const MovementStockContext = createContext();

export const MovementStock = () => {
  const [search, setSearch] = useState('');
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [inWarrantField, setInWarrantyFieldValue] = useState();
  const [outOfWarrantField, setOutOfWarrantyFieldValue] = useState();

  const inWarranty = 1;
  const outOfWarranty = 3;

  const [
    stockLocationsInWarranty,
    ,
    loadingStockLocationsInWarranty,
    refetchStockLocationInWarranty,
  ] = useQuery(() => componentService.listStockLocationsByLevelLocation(inWarranty), [inWarranty]);

  const [
    stockLocationsOutOfWarranty,
    ,
    loadingStockLocationsOutOfWarranty,
    loadingStockLocationsRefetchOutOfWarranty,
  ] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(outOfWarranty),
    [outOfWarranty],
  );

  const locationsInWarrantyOptions = (stockLocationsInWarranty || []).map((state) => {
    return {
      value: state.id,
      label: state.location_name,
    };
  });

  const locationsOutOfWarrantyOptions = (stockLocationsOutOfWarranty || []).map((state) => {
    return {
      value: state.id,
      label: state.location_name,
    };
  });

  const [componentsInWarranty, , loadingComponentsInWarranty, refetchComponentsInWarranty] =
    useQuery(
      () => componentService.listComponentsByStockLevelLocation(inWarrantField),
      [inWarrantField],
    );

  const [
    componentsOutOfWarranty,
    ,
    loadingComponentsOutOfWarranty,
    refetchComponentsOutOfWarranty,
  ] = useQuery(
    () => componentService.listComponentsByStockLevelLocation(outOfWarrantField),
    [outOfWarrantField],
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <MovementStockContext.Provider
        value={{
          refetchComponentsInWarranty,
          refetchComponentsOutOfWarranty,
        }}
      >
        <PageTitle>Movimentação de Estoque</PageTitle>
        <Formik initialValues={{ in_warranty: '', out_of_warranty: '' }}>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Text
                      label='Pesquisar'
                      size='small'
                      variant='outlined'
                      fullWidth
                      onChange={handleSearchChange}
                      value={search}
                      endAdornment={<SearchIcon />}
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Paper elevation={5}>
                      <Field
                        size='small'
                        variant='outlined'
                        fullWidth
                        label='Local - Estoque de Componentes'
                        name='in_warranty'
                        component={Select}
                        loading={loadingStockLocationsInWarranty}
                        options={locationsInWarrantyOptions}
                        onChange={(e) => setInWarrantyFieldValue(e)}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper elevation={5}>
                      <Field
                        size='small'
                        variant='outlined'
                        fullWidth
                        label='Local - Estoque de Componentes Fora de Garantia'
                        name='out_of_warranty'
                        component={Select}
                        loading={loadingStockLocationsOutOfWarranty}
                        options={locationsOutOfWarrantyOptions}
                        onChange={(e) => setOutOfWarrantyFieldValue(e)}
                      />
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper elevation={5}>
                      <TableComponentsInWarranty componentsInWarranty={componentsInWarranty} />
                    </Paper>
                  </Grid>

                  <Grid item xs={6}>
                    <Paper elevation={5}>
                      <TableComponentsOutOfWarranty
                        componentsOutOfWarranty={componentsOutOfWarranty}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </MovementStockContext.Provider>
    </>
  );
};
