import React, { useContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { ReactComponent as ChangeLogImage } from 'assets/images/change-log.svg';
import { Text } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';
import { Form, Field, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService, serviceOrderService } from 'services';

import { ServiceOrderContext } from '../..';
import { useStyles } from './styles';

const columns = [
  { label: 'SeqNo' },
  { label: 'Part Number', align: 'center' },
  { label: 'Billing', align: 'center' },
];

const columns2 = [
  { label: 'Valor Cobrado', align: 'center' },
  { label: 'Valor Minimo possível', align: 'center' },
];

const invoicesOptions = {
  NotaRemessaEntrada: 1,
  NotaRemessaSaida: 2,
  NotaDeVenda: 3,
  NotaDeServico: 4,
  NotaDeAnalise: 5,
  NotaDeReparo: 6,
};

const columnsSalesComponent = [
  { label: 'Part-number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Preço de venda componente', align: 'center' },
  { label: 'Valor minimo permitido', align: 'center' },
];

export const OsBudget = () => {
  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [budgets, setBudget] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsServicesByServiceId(serviceFromGaia?.id),
    [],
  );

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBudget(item);
  };

  const [service, , loadingService] = useQuery(
    () => serviceOrderService.listPNsFromGspnByNumber(serviceFromGaia?.number),
    [],
  );

  const [invoiceService, , ,] = useQuery(
    () =>
      invoiceService.getInvoiceByOsIdAndInvoiceTypeId(
        serviceFromGaia?.id,
        invoicesOptions.NotaDeServico,
      ),
    [],
  );

  const [invoiceSelling, , ,] = useQuery(
    () =>
      invoiceService.getInvoiceByOsIdAndInvoiceTypeId(
        serviceFromGaia?.id,
        invoicesOptions.NotaDeVenda,
      ),
    [],
  );

  const api = {
    nf_servico: 'AQUI VAI A NOTA DE SERVIÇO',
    nf_venda: 'AQUI VAI A NOTA DE VENDA',
    motivo_recusa_nf: 'Negado',
    motivo_reprovado: 'Negado',
  };

  // useEffect(() => {
  //   serviceFromGaia;
  // }, [serviceFromGaia]);

  const dataOrc = new Date(budget?.created_at).toLocaleString();

  return (
    <Formik>
      <Form>
        {!budget ? (
          <>
            <div className={classes.container}>
              <h1 className={classes.title}>Ordem de serviço informada não possui orçamento.</h1>
              <ChangeLogImage className={classes.changeLogImage} />
            </div>
          </>
        ) : (
          <>
            <Grid className={classes.formContainer} container spacing={2}>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Data Orçamento'
                  fullWidth
                  inputProps={{ editable: false }}
                  value={dataOrc}
                  component={Text}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Responsável SAC'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.attendant?.name}
                  disabled
                />
              </Grid>

              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Valor Peças'
                  mask='money'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.components_total_price ?? 0}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Valor Serviços'
                  mask='money'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.additional_services_total_price ?? 0}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  mask='money'
                  value={
                    budget?.components_total_price +
                    budget?.additional_services_total_price -
                    budget?.final_budget_price
                  }
                  label='Valor Desconto'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Valor Total'
                  mask='money'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.final_budget_price ?? budget?.negociation_price}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Número da NF de Serviço'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={invoiceService?.number ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  value={invoiceService?.nfse_number ?? '-'}
                  label='Chave da NF de Serviço'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={2}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Número da NF de Vendas'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={invoiceSelling?.number ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={6} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  value={invoiceSelling?.nfse_number ?? '-'}
                  label='Chave da NF de Vendas'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='Reprova - Motivo'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.cancel_budget_reason?.title ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='SWAP'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget?.tradeIn ?? '-'}
                  disabled
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <Table
                headers={columns}
                loading={loadingService}
                actionsLabel={<VisibilityIcon />}
                emptyMessage='Nenhum registro encontrado.'
                onActionsClick={handleOpenInformation}
                striped
                maxHeight
                data={service}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                disableNumeration
              >
                {service?.results.map((serviceOrder) => {
                  return (
                    <TableRow key={serviceOrder?.SeqNo}>
                      {console.log('Service Ordenado: ', budget)}
                      <TableCell>{serviceOrder?.SeqNo}</TableCell>
                      <TableCell align='center'>{serviceOrder?.PartCode}</TableCell>
                      <TableCell align='center'>{serviceOrder?.InvoiceNo}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid> */}
            {/* <LinkInformationDialog
              open={isDetailsOpen}
              handleClose={handleCloseInformation}
              budget={budget}
              reloadStatuses={refetchBudgets}
            /> */}

            <Tabs gutterBottom>
              <Tabs.Content label='Componentes usados' disablePadding>
                <Table
                  headers={columnsSalesComponent}
                  emptyMessage='Nenhum registro encontrado.'
                  disableNumeration
                  striped
                  maxHeight={400}
                >
                  {budget?.sales_components?.map((saleComponent) => {
                    return (
                      <TableRow key={saleComponent?.id}>
                        <TableCell align='center'>{saleComponent?.part_number}</TableCell>
                        <TableCell align='center'>{saleComponent?.description}</TableCell>
                        <TableCell align='center'>
                          <FormatMoney>{saleComponent?.sell_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <FormatMoney>{saleComponent?.origin_value}</FormatMoney>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </Tabs.Content>
            </Tabs>
          </>
        )}
      </Form>
    </Formik>
  );
};
