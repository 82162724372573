import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { ReactComponent as CartImg } from 'assets/images/cart.svg';
import { ReactComponent as IlustrationImg } from 'assets/images/graphicIlustration.svg';
import { ReactComponent as GraphicImg } from 'assets/images/graphicMoney.svg';
import { ReactComponent as MoneyImg } from 'assets/images/money.svg';
import { ReactComponent as TesteGraficoImg } from 'assets/images/testeGrafico.svg';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { useStyles } from './styles';

export const Report = () => {
  const classes = useStyles();

  const [totalOrcamentoDiario, setTotalPrice] = useState([]);

  const [budgettotalpriceTodayData, , loadingServiceQuantityBudget] = useQuery(
    () => serviceOrderService.listBudgetTotalPriceToday(),
    [],
  );

  const [totalOrcamentoAcessorioDiario, setTotalPriceAcessorio] = useState([]);

  const [budgettotalpriceAcessoryTodayData, , loadingServiceQuantityAcessoryBudget] = useQuery(
    () => serviceOrderService.listBudgetTotalAcessoryToday(),
    [],
  );

  const [totalBydgetAprovedDiary, setTotalBudgetAproved] = useState([]);

  const [totalBydgetAprovedDiaryData, , loadingtotalBydgetAprovedDiaryData] = useQuery(
    () => serviceOrderService.listAprovedBudgetToday(),
    [],
  );

  const [totalBudgetReprovedDiary, setTotalBudgetReproved] = useState([]);

  const [totalBudgetReprovedDiaryData, , loadingtotalBudgetReprovedDiary] = useQuery(
    () => serviceOrderService.listReprovedBudgetToday(),
    [],
  );

  const [totalAllBudgetToday, setTotalBudgetToday] = useState([]);

  const [totalAllBudgetTodayData, , loadingtotalAllBudgetTodayData] = useQuery(
    () => serviceOrderService.listAllBudgetToday(),
    [],
  );

  useEffect(() => {
    if (totalAllBudgetTodayData) {
      const { totalorc } = totalAllBudgetTodayData[0];
      setTotalBudgetToday(totalorc);
    }
  }, [totalAllBudgetTodayData]);

  useEffect(() => {
    if (totalBudgetReprovedDiaryData) {
      const { totalreprovados } = totalBudgetReprovedDiaryData[0];
      setTotalBudgetReproved(totalreprovados);
    }
  }, [totalBudgetReprovedDiaryData]);

  useEffect(() => {
    if (totalBydgetAprovedDiaryData) {
      const { totalaprovados } = totalBydgetAprovedDiaryData[0];
      setTotalBudgetAproved(totalaprovados);
    }
  }, [totalBydgetAprovedDiaryData]);

  useEffect(() => {
    if (budgettotalpriceTodayData) {
      const { totalorcamentodiario } = budgettotalpriceTodayData[0];
      setTotalPrice(totalorcamentodiario);
    }
  }, [budgettotalpriceTodayData]);

  useEffect(() => {
    if (budgettotalpriceAcessoryTodayData) {
      const { totalorcamentodiarioacessorio } = budgettotalpriceAcessoryTodayData[0];
      setTotalPriceAcessorio(totalorcamentodiarioacessorio);
    }
  }, [budgettotalpriceAcessoryTodayData]);

  console.log(budgettotalpriceAcessoryTodayData);

  const desempenho =
    (totalBydgetAprovedDiary
      ? totalBydgetAprovedDiary
      : 0 / totalAllBudgetToday
      ? totalAllBudgetToday
      : 0) * 100;

  return (
    <>
      <PageTitle>Relatório diario de caixa</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperSale} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}> R$ {totalOrcamentoDiario ?? 0}</p>
                <p className={classes.text}>Valor total de vendas orçamentos FG</p>
              </div>
              <MoneyImg className={classes.moneyImgs} />
            </div>

            <footer className={classes.footerPaperSale}>
              <p>Total de venda diária</p>
            </footer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperQuantity} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>R$ {totalOrcamentoAcessorioDiario ?? 0}</p>
                <p className={classes.text}>Quantidade de vendas em acessórios</p>
              </div>
              <CartImg className={classes.cartImg} />
            </div>

            <footer className={classes.footerPaperQuantity}>
              <p>Quantidade de vendas diária</p>
            </footer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperPerformance} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{desempenho ?? 0}%</p>
                <p className={classes.text}>Desempenho </p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance}>
              <p>Desempenho diário do SAC em orçamentos.</p>
            </footer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paperGraphics} elevation={2}>
            <div className={classes.divSectionIlustrationGraphic}>
              <p className={classes.divider} />
              <divider>
                <section className={classes.sectionContextIlustrationGrahic}>
                  <IlustrationImg className={classes.imgIlustrationGraphic} />
                  <div className={classes.teste}>
                    <p className={classes.p}>Total de orçamentos aprovados.</p>
                    <p className={classes.titleNumber}>Nº {totalBydgetAprovedDiary ?? 0}</p>
                  </div>
                </section>
              </divider>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paperGraphics1} elevation={2}>
            <div className={classes.divSectionIlustrationGraphic1}>
              <p className={classes.divider1} />
              <section className={classes.sectionContextIlustrationGrahic1}>
                <TesteGraficoImg className={classes.imgIlustrationGraphic1} />
                <div>
                  <p className={classes.p1}>Total de orçamentos reprovados.</p>
                  <p className={classes.titleNumber1}>Nº {totalBudgetReprovedDiary ?? 0}</p>
                </div>
              </section>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
