import React, { useState, memo, useCallback } from 'react';
import { NavLink } from 'react-router-dom';

import Collapse from '@material-ui/core/Collapse';
import DrawerMUI from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { browserHistory } from 'routes/browserHistory';
import { APP_VERSION } from 'settings';

import { useLayoutContext } from '../context';
import { menu } from './menu';
import { MenuItemRoute } from './MenuItemRoute';
import { MenuItemToggle } from './MenuItemToggle';
import useStyles from './styles';

const Drawer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('3000px'));
  const classes = useStyles();

  const [openMenus, setOpenMenus] = useState([]);

  const { drawerOpen, handleDrawerToggle } = useLayoutContext();

  const toggleMenu = useCallback(
    (menuId) => {
      const isOpen = openMenus.includes(menuId);

      if (isOpen) {
        setOpenMenus((prevState) => prevState.filter((id) => menuId !== id));

        return;
      }

      setOpenMenus((prevState) => [...prevState, menuId]);
    },
    [openMenus],
  );

  const sendToPage = useCallback(
    (link) => {
      browserHistory.push(link);
      !isDesktop && handleDrawerToggle();
    },
    [isDesktop, handleDrawerToggle],
  );

  return (
    <DrawerMUI
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={drawerOpen}
      onClose={handleDrawerToggle}
      className={classes.drawer}
      classes={{ paper: classes.paper }}
      ModalProps={{ keepMounted: true }}
    >
      <List className={classes.list}>
        <p className={classes.gaia}>
          Gaia<span>System</span>
        </p>

        {menu.map((menuItem) => {
          const hasSubMenu = !!menuItem.items;
          if (hasSubMenu) {
            const isOpen = openMenus.includes(menuItem.id);
            const { items, id, roles, ...props } = menuItem;

            return (
              <>
                <MenuItemToggle
                  id={id}
                  {...props}
                  isOpen={isOpen}
                  onClick={toggleMenu}
                  isNested
                  roles={roles}
                />
                <Collapse in={isOpen} timeout='auto' unmountOnExit>
                  <List style={{ marginLeft: '20px' }}>
                    {items.map(({ path, label, icon, roles }) => (
                      <MenuItemRoute
                        key={path}
                        style={{ color: 'red' }}
                        fullPath={`/${id}${path}`}
                        label={label}
                        icon={icon}
                        isNested
                        onClick={sendToPage}
                        roles={roles}
                      />
                    ))}
                  </List>
                </Collapse>
              </>
            );
          }
          const { path, label, icon, roles } = menuItem;
          return (
            <MenuItemRoute
              key={path}
              path={path}
              label={label}
              icon={icon}
              onClick={sendToPage}
              roles={roles}
            />
          );
        })}
      </List>
      <NavLink to='/changelog' className={classes.version}>
        Versão {APP_VERSION}
      </NavLink>
    </DrawerMUI>
  );
};

export default memo(Drawer);
