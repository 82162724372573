import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { budgetService } from 'services/budget';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Prazo para entrega', align: 'center' },
  { label: 'Valor frete ', align: 'center' },
  { label: 'Valor sem adicionais ', align: 'center' },
  { label: 'Entre ao sabado ', align: 'center' },
  { label: 'Valor declarado ', align: 'center' },
  { label: 'Tipo de entrega ', align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const CalcFare = () => {
  const classes = useStyles();

  const [batchDataOnly, setBillingDataOnly] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [pendingList, , loadingpendingList, refetchswap] = useQuery(
    () => budgetService.listSwapPending(),
    [],
  );

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/providers/correios/calc-frete', values);

      console.log('Data: ', data);

      setBillingDataOnly(data);

      refetchswap();
      toast.success('Frete calculado com sucesso');

      // resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao calcular o frete.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Calcular FRETE </PageTitle>
      <Formik
        initialValues={{
          sCepOrigem: '',
          sCepDestino: '',
          nVlPeso: '',
          nCdFormato: '',
          nVlComprimento: '',
          nVlAltura: '',
          nVlLargura: '',
          nVlDiametro: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='sCepOrigem'
                    label='CEP ORIGEM'
                    variant='outlined'
                    mask='zipcode'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='sCepDestino'
                    min={30}
                    label='CEP DESTINO'
                    variant='outlined'
                    mask='zipcode'
                    size='small'
                    component={Text}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Field
                    name='nVlPeso'
                    min={30}
                    inputProps={{ min: 0.3 }}
                    label='PESO'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                {/*
                <Grid item xs={12} lg={4}>
                  <Field
                    name='nCdFormato'
                    min={30}
                    label='FORMATO'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid> */}
                <Grid item xs={12} lg={4}>
                  <Field
                    name='nVlComprimento'
                    min={30}
                    inputProps={{ min: 15, max: 100 }}
                    label='COMPRIMENTO'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='nVlLargura'
                    min={30}
                    label='LARGURA'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='nVlAltura'
                    min={30}
                    label='ALTURA'
                    inputProps={{ min: 1, max: 100 }}
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={4}>
                  <Field
                    name='nVlDiametro'
                    min={30}
                    label='DIAMETRO'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid> */}
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Calcular frete
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={300}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {/* {batchDataOnly?.map((batch) => (
                      <TableRow key={batch.id}>
                        <TableCell align='center'>{batch.PrazoEntrega + ' ' + 'Dias'}</TableCell>
                        <TableCell align='center'>{'R$' + ' ' + batch.Valor}</TableCell>
                        <TableCell align='center'>
                          {'R$' + ' ' + batch.ValorSemAdicionais}
                        </TableCell>

                        <TableCell align='center'>{batch.EntregaSabado}</TableCell>
                        <TableCell align='center'>{batch.ValorValorDeclarado}</TableCell>
                        <TableCell align='center'>
                          {batch.Codigo == '04014' ? 'SEDEX ' : 'PAC'}
                        </TableCell>
                      </TableRow>
                    ))} */}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
