import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  id: yup.number().required(),
  description: yup.string().required(),
  service_price: yup.number().required(),
});

export const RegisterServiceBudgetDialog = ({
  open,
  handleClose,
  serviceOrder = {},
  reloadAdditionalService,
}) => {
  if (!open) return null;
  const classes = useStyles();

  console.log('Service Order: ', serviceOrder);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await api.put('/budgets/additional-services', values);

      toast.success('Serviço atualizado com sucesso!');

      resetForm();
      reloadAdditionalService();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar serviço.');
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        <PageTitle />
        Serviço para Orçamento:
        <span style={{ paddingLeft: '5px' }}>
          {serviceOrder.additional_service_product.description}
        </span>
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            id: serviceOrder?.id,
            service_price: serviceOrder.service_price,
            description: serviceOrder.description,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Serviço'
                      variant='outlined'
                      size='small'
                      emptyOption='Selecione'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name='service_price'
                      label='Valor do Serviço'
                      variant='outlined'
                      size='small'
                      mask='money'
                      emptyOption='Selecione'
                      inputProps={{
                        min: 1,
                      }}
                      component={Text}
                    />
                  </Grid>
                </Grid>
                <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
                  <Button onClick={handleClose} color='primary' variant='text'>
                    Fechar
                  </Button>
                  <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
