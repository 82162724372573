import React from 'react';
import { toast } from 'react-toastify';

import { Button, Dialog } from 'components';
import { Formik } from 'formik';
import { collaboratorsService } from 'services';

import { Form } from './Form';
import { initialValues, validationSchema } from './formik';

export const RegisterClientDialog = ({ open, onClose, onSuccess, createTradeIn }) => {
  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await collaboratorsService.createClient(values);

      toast.success('Cadastrado com sucesso.');
      createTradeIn();
      resetForm();
      onClose();
      onSuccess && (await onSuccess(values));
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao cadastrar cliente.');
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, dirty, isValid }) => (
        <Dialog open={open} onClose={onClose} maxWidth='md'>
          <Dialog.Title onClose={onClose}>Precisamos de algumas informações</Dialog.Title>
          <Dialog.Content>
            <Form />
          </Dialog.Content>
          <Dialog.Actions>
            <Button fullWidth onClick={onClose} color='secondary'>
              Fechar
            </Button>
            <Button fullWidth onClick={handleSubmit} type='submit' disabled={!dirty || !isValid}>
              Confirmar
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  );
};
