import React from 'react';

import { Tabs } from 'components';

import { IssueAnalysisNote } from './IssueAnalysisNote';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Emissão' disablePadding>
        <IssueAnalysisNote />
      </Tabs.Content>
    </Tabs>
  );
};
