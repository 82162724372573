import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  title: {
    fontWeight: 700,
  },
  buttonsContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'end',
  },
}));
