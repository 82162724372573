import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Table, CustomAutocomplete } from 'components';
import { Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import XLSX from 'xlsx';

import { ComponentAlternateModelDialog } from './ComponentAlternateModelDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const RegisterComponentAlternate = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [componentAlternate, setComponentAlternate] = useState({});
  const [search, setSearch] = useState('');

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setComponentAlternate(item);
  };

  // const [vincAlternates, , loadingVincAlternates] = useQuery(
  //   () => componentService.listVincComponentsAlternate(),
  //   [],
  // );

  const handleDownloadReportAlternate = async () => {
    const { data } = await componentService.listVincComponentsAlternate();

    console.log('Get all Components: ', data);

    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        Alternates: worksheet,
      },
      SheetNames: ['Alternates'],
    });

    await XLSX.writeFile(workbook, `RelatórioAlternates${now}.xlsx`);
  };

  const [components, , loadingComponents] = useQuery(
    () => componentService.listComponentsWhereDoNotRepeatPn(1, 10, search),
    [search],
  );

  const componentOptions = (components || []).map((component) => ({
    value: component.part_number,
    label: component.description,
  }));

  const [
    listComponentsAlternates,
    ,
    loadingListComponentsAlternates,
    refetchListComponentsAlternates,
  ] = useQuery(() => componentService.listComponentsAlternate(), []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const part_number = values.part_number.value;
      const description = values.part_number.label;
      await componentService.createComponentsAlternate(part_number, description);

      refetchListComponentsAlternates();
      toast.success('Componente Alternativo criado com sucesso.');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar o componente como alternate');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Cadastrar Alternate</PageTitle>
      <Formik
        initialValues={{ part_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} md={8} lg={8} xl={8}>
                  <CustomAutocomplete
                    name='part_number'
                    label='Selecione o Part Number alternate'
                    filteredValues={componentOptions}
                    onChange={(value) => setSearch(value)}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={12} md={2} lg={2} xl={2}>
                  <Button fullWidth color='primary' onClick={handleDownloadReportAlternate}>
                    Exportar Relatório
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    loading={loadingListComponentsAlternates}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {listComponentsAlternates?.map((componentAlternate) => (
                      <TableRow key={componentAlternate.id}>
                        <TableCell>{componentAlternate.part_number}</TableCell>
                        <TableCell align='center'>{componentAlternate.description}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, componentAlternate)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <ComponentAlternateModelDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  componentAlternate={componentAlternate}
                  reloadComponentAlternate={refetchListComponentsAlternates}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
