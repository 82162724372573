import React, { useState } from 'react';
import { BiTransfer } from 'react-icons/bi';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import clsx from 'clsx';
import { Table, Text } from 'components';
import { useBoolean } from 'hooks/useBoolean';

import { ConfirmDialog } from './ConfirmDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Local', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export const TableBoxAcessories = ({ componentsFg }) => {
  const classes = useStyles();
  const [confirmDialog, setConfirmDialog] = useBoolean();
  const [selectedOrdering, setSelectedOrdering] = useState({});
  const [dialogProps, setDialogProps] = useState({});

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
  };

  const filteredComponents = (componentsFg || []).filter((components) => {
    const lowerPartNumber = components?.part_number?.toLowerCase();
    const upperPartNumber = components?.part_number?.toUpperCase();

    const lowerDescription = components?.description?.toLowerCase();
    const upperDescription = components?.description?.toUpperCase();

    return (
      lowerPartNumber?.includes(search) ||
      upperPartNumber?.includes(search) ||
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search)
    );
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>

      <ConfirmDialog
        open={confirmDialog}
        onClose={setConfirmDialog.toFalse}
        dialogProps={dialogProps}
        selectedOrdering={selectedOrdering}
      />
      <Typography variant='h6'>Acessórios em FG</Typography>
      <Table
        headers={columns}
        emptyMessage='Nenhum registro encontrado.'
        striped
        disableNumeration
        // maxHeight={600}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      >
        {filteredComponents?.map((components) => (
          <TableRow key={components.id}>
            <TableCell align='center'>{components.part_number}</TableCell>
            <TableCell align='center'>{components.description}</TableCell>
            <TableCell align='center'>{components?.stock_location?.location_name}</TableCell>
            <TableCell align='center'>{components.quantity}</TableCell>
            <TableCell align='center'>
              <section>
                <IconButton
                  onClick={() =>
                    handleActionClick(
                      {
                        title: 'Deseja confirmar a transferência para o novo local de estoque?',
                        accept: true,
                      },
                      components,
                    )
                  }
                >
                  <BiTransfer className={clsx(classes.actionIcon, classes.checkIcon)} />
                </IconButton>
              </section>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};
