import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Table } from 'components';

import { useStyles } from './styles';

const columns = [{ label: 'Service' }, { label: 'Objeto' }, { label: 'Nota fiscal de saida' }];

export const TableWaiting = ({ minute, loadingMinutes }) => {
  const classes = useStyles();

  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    const v = e.target.value;

    setSearch(v);
  };

  const filteredMinutes = (minute || []).filter((c) => {
    const lowerDesc = c.description.toLowerCase();
    const lowerCompDesc = c.component_location.description.toLowerCase();
    const lowerPartNumber = c.part_number.toLowerCase();

    return (
      lowerDesc.includes(search) ||
      c.quantity == search ||
      lowerPartNumber.includes(search) ||
      lowerCompDesc.includes(search)
    );
  });

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <Table headers={columns} emptyMessage='Nenhuma minuta a gerar.' loading={loadingMinutes}>
          {filteredMinutes?.map((minute) => (
            <TableRow key={minute.name_cliente}>
              <TableCell>{minute.name_cliente}</TableCell>
              <TableCell>{minute.service_number}</TableCell>
            </TableRow>
          ))}
        </Table>
      </Grid>
    </Grid>
  );
};
