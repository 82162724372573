import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Grid from '@mui/material/Grid';
import { ReactComponent as ImgWork } from 'assets/images/work.svg';
import { Text, Button, Table, CustomAutocomplete } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Ordem de serviço' },
  { label: 'Processo' },
  { label: 'Atendimento' },
  { label: 'Part Number' },
  { label: 'Quantidade' },
  { label: 'Quem solicitou ?' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const AwaitingParts = () => {
  const classes = useStyles();

  const [search, setSearch] = useState('');

  const [components, , loadingComponents] = useQuery(
    () => componentService.listUniquePartNumbers(),
    [],
  );

  const componentOptions = (components || []).map((component) => ({
    value: component.part_number,
    label: component.part_number,
  }));

  const [name, setName] = useState('');

  const [labors, setLabors] = useState([]);

  const [laborsData, , loadingLabors, refetchLabors] = useQuery(
    () => api.get('/service-orders/isAwaitingParts'),
    [],
  );

  useEffect(() => {
    setLabors(laborsData);
  }, [laborsData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      values.part_number = search;

      const { data } = await api.post('service-orders/service-awaiting-parts', values);

      setLabors((prevState) => [
        ...prevState,
        {
          ...data,
        },
      ]);

      refetchLabors();
      toast.success('Service registrada para aguardando componentes');

      resetForm();
    } catch (err) {
      console.error(err);
      console.log(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar .');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Enviar para aguardando componentes</PageTitle>
      <Formik
        initialValues={{ service_order_id: '', part_number: '', quantity: 0 }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Ordem de serviço'
                    name='service_order_id'
                    value={name}
                    size='small'
                    component={Text}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <CustomAutocomplete
                    name='part_number'
                    label='Selecione o Part Number alternate'
                    filteredValues={componentOptions}
                    onChange={(value) => setSearch(value)}
                  />
                  {/* <Field
                    variant='outlined'
                    label='Part Number'
                    name='part_number'
                    size='small'
                    // options={additionalServiceProductOptions}
                    component={Text}
                  /> */}
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='quantity'
                    label='Quantidade'
                    variant='outlined'
                    size='small'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Enviar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Table
                    headers={columns}
                    striped
                    disableNumeration
                    maxHeight={350}
                    Loading={loadingLabors}
                    data={laborsData}
                    emptyMessage='Nenhum serviço encontrado.'
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                  >
                    {labors?.map((labor) => (
                      <TableRow key={labor.id}>
                        <TableCell>{labor.number}</TableCell>
                        <TableCell>{labor.description}</TableCell>
                        <TableCell>{labor.warranty_type}</TableCell>
                        <TableCell>{labor.part_number}</TableCell>
                        <TableCell>{labor.quantity}</TableCell>
                        <TableCell>{labor.name}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <Grid className={classes.containerImg} item xs={4}>
                  <ImgWork className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
