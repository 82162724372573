import React from 'react';
import { useRouteMatch } from 'react-router';

import { MonthlyClosing } from 'pages/Private/Administration/MonthlyClosing';
import { Route } from 'routes/Route';

export const AdministrationRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/monthly-closing`} component={MonthlyClosing} />
    </>
  );
};
