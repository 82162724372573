import { gql } from '@apollo/client';

export const LIST_STATUS = gql`
  query {
    listStatus {
      id
      description
    }
  }
`;

export const UPDATED_SERVICE_STATUS = gql`
  mutation UpdatedServiceStatus($sinister: String!, $new_status: Int!, $reason: String!) {
    updatedServiceStatus(sinister: $sinister, new_status: $new_status, reason: $reason)
  }
`;

export const UPDATED_MANY_SERVICE_STATUS = gql`
  mutation UpdatedManyServiceStatus($data: [UpdatedManyServiceStatusInput!]!, $new_status: Int!) {
    updatedManyServiceStatus(data: $data, new_status: $new_status)
  }
`;
