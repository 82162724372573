import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';

import { useStyles } from './styles';

export function ReprintDanfe() {
  const classes = useStyles();

  const handleDownloadTagsClick = async (service_order_number) => {
    const { data } = await budgetService.getDanfe(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log(values);
    handleDownloadTagsClick(values.service_number)
      .then((response) => {
        console.log('Response: ', response);

        setTimeout(() => {
          setSubmitting(false);
          toast.success('Reimpressão realizada com sucesso.');
        }, 2000);
      })
      .catch((err) => {
        setSubmitting(false);

        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc?.decode(arr);

        const parsedResult = JSON?.parse(result);

        toast.error(parsedResult?.message);
      });
  };

  return (
    <>
      <PageTitle>Reimpressão de DANFE</PageTitle>
      <Formik initialValues={{ service_number: '' }} onSubmit={onSubmitHandler}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  // inputProps={{ maxLength: 10, minlength: 10 }}
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button type='submit' fullWidth>
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <img className={classes.imgContainer} src={checkKitImg} />
    </>
  );
}
