import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    gridPaper: {
      padding: '15px',
      width: '80%',
      display: 'flex',
      flexDirection: 'column',
    },
    contextPaper: {
      position: 'relative',
    },
    legend: {
      fontWeight: 'bold',
    },
    containerLegend: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '50px',
      width: 250,
      padding: '10px',
      marginTop: '10px',
    },
    p: {
      marginLeft: '10px',
      fontSize: '0.9rem',
    },

    gridImages: {
      display: 'flex',
      margin: 'auto',
    },
    imgTracker: {
      height: 200,
      marginTop: 15,
    },
  };
});
