import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Text, Button, Table, FormatDate } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { cashierService } from 'services/cashier';
import XLSX from 'xlsx';

import { ConfirmDialog } from './ConfirmDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Cliente' },
  { label: 'Atendente' },
  { label: 'Valor' },
  { label: 'Data de Emissão' },
  { label: 'Nota' },
];

export const ConsultNoteNoFiscal = () => {
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);
  const [search, setSearch] = useState('');

  const [dialogProps, setDialogProps] = useState({});
  const [selectedOrdering, setSelectedOrdering] = useState({});
  const [confirmDialog, setConfirmDialog] = useBoolean();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [includedInvoicesData, , loadingIncludedInvoicesData] = useQuery(
    () => cashierService.listCouponNoFiscal(),
    [],
  );

  const formattedInvoices = (includedInvoicesData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredInvoices = formattedInvoices.filter((invoices) => {
    const upperAttendantName = invoices.attendant?.name?.toUpperCase();
    const lowerAttendantName = invoices.attendant?.name?.toLowerCase();

    const upperClienteName = invoices.client?.name?.toUpperCase();
    const lowerClienteName = invoices.invoice_type?.description?.toLowerCase();

    const localeDate = new Date(invoices?.created_at).toLocaleString('pt-br');

    return (
      upperClienteName?.includes(search) ||
      lowerClienteName?.includes(search) ||
      localeDate?.includes(search) ||
      upperAttendantName?.includes(search) ||
      lowerAttendantName?.includes(search)
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
  };

  useEffect(() => {
    setInvoices(includedInvoicesData);
    console.log(invoices);
  }, [includedInvoicesData, invoices]);

  const handleDownloadCashierNoFiscal = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(includedInvoicesData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioDeCuponsNaoFiscais${now}.xlsx`);
  };

  return (
    <>
      <PageTitle>Consultar cupom sem valor fiscal</PageTitle>

      <Formik>
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    onChange={handleSearchChange}
                    variant='outlined'
                    size='small'
                    endAdornment={<SearchIcon />}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button fullWidth onClick={handleDownloadCashierNoFiscal} color='primary'>
                    exportar relatório
                  </Button>
                </Grid>
                <Grid item xs={12} lg={10}>
                  <Table
                    headers={columns}
                    striped
                    disableNumeration
                    maxHeight={600}
                    loading={loadingIncludedInvoicesData}
                    data={filteredInvoices}
                    emptyMessage='Nenhum registro encontrado.'
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  >
                    {filteredInvoices?.map((invoice) => (
                      <TableRow key={invoice?.id}>
                        <TableCell>{invoice?.client?.name}</TableCell>
                        <TableCell>{invoice?.attendant?.name}</TableCell>
                        <TableCell>
                          <FormatMoney>{invoice?.sale_total_price ?? 0}</FormatMoney>{' '}
                        </TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                            {invoice?.created_at}
                          </FormatDate>
                        </TableCell>

                        <TableCell>
                          <section>
                            {invoice?.sales_note}
                            <IconButton
                              onClick={() =>
                                handleActionClick(
                                  {
                                    title: 'Tem certeza que deseja cancelar a requisição?',
                                    accept: false,
                                  },
                                  invoice,
                                )
                              }
                            >
                              <ArrowDownwardIcon
                                fontSize='small'
                                sx={{ color: 'rgb(0, 128, 0)' }}
                              />
                            </IconButton>
                          </section>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
              <ConfirmDialog
                open={confirmDialog}
                onClose={setConfirmDialog.toFalse}
                dialogProps={dialogProps}
                selectedOrdering={selectedOrdering}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
