import { gql } from '@apollo/client';

export const LIST_LOGS = gql`
  query ListLogs($limit: Int, $offset: Int, $sinister: String!) {
    listLogs(limit: $limit, offset: $offset, sinister: $sinister) {
      totalCount
      responses {
        id
        description
        createdAt
        status {
          description
        }
        previous_status {
          description
        }
        user {
          name
        }
      }
    }
  }
`;
export const LIST_LOGS_DATA = gql`
  query ListLogsData($limit: Int, $offset: Int, $sinister: String!) {
    listLogsData(limit: $limit, offset: $offset, sinister: $sinister) {
      totalCount
      responses {
        id
        description
        createdAt
        user {
          name
        }
      }
    }
  }
`;
