import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';
import * as Yup from 'yup';

import { useStyles } from './styles';
import { UpdateRequestTagDialog } from './UpdateRequestTagDialog';
export const validationSchema = Yup.object().shape({
  cubagem: Yup.string().required(),
});
export function ReprintPackingListConferenceInvoiceRepairGeral() {
  const classes = useStyles();
  const [cubagemset, setCubagem] = useState({});

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [batata, setItemRequest] = useState({});

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    const { data } = await invoiceService.packingListValidationMagazineDiversos(values.nnf);
    setCubagem(values.cubagem);

    setItemRequest(data);
    setIsDetailsState.toTrue();
  };

  return (
    <>
      <PageTitle>Reimpressão de Romaneio diversos</PageTitle>
      <Formik
        initialValues={{ nnf: '', cubagem: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='N° nota entrada'
                  name='nnf'
                  variant='outlined'
                  size='small'
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Cubagem'
                  name='cubagem'
                  variant='outlined'
                  size='small'
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button type='submit'>GERAR ROMANEIO</Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <img className={classes.imgContainer} src={checkKitImg} />
      <UpdateRequestTagDialog
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        itemRequest={batata}
        cubagemRequest={cubagemset}
        // setListRequest={setListRequest}
      />
    </>
  );
}
