import { React, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { collaboratorsService } from 'services';

import { DialogMoreInformation } from './dialog';
import { RegisterClientDialog } from './RegisterClientDialog';

export const CardTemplate = ({
  image,
  title,
  description,
  className,
  allInformation,
  setThisMyProduct,
  setFinishTemplate,
  setIsCardRender,
  finishTemplate,
  valuesData,
  setOffer,
  confirmOffer,
  condition,
  offerValue,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openClientDialog, setOpenClientDialog] = useState(false);

  const onOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMyProduct = () => {
    onSubmitHandler();
    setThisMyProduct(allInformation);
    setFinishTemplate(true);
    setIsCardRender(false);
  };

  const handleOpenClientDialog = () => {
    setOpenClientDialog(true);
  };

  const handleCloseClientDialog = () => {
    setOpenClientDialog(false);
  };

  const createTradeIn = async () => {
    try {
      await collaboratorsService.createTradeIn(allInformation, condition, offerValue);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao cadastrar a troca');
    }
  };

  const onSubmitHandler = async () => {
    const tradInInformation = Object.assign(valuesData, allInformation);

    const { data } = await collaboratorsService.calcTradeIn(tradInInformation);

    setOffer(data);
  };

  return (
    <Card sx={{ maxWidth: '100%', marginLeft: '25px' }} className={className}>
      <CardMedia sx={{ height: 140 }} component='img' src={image} title={title} />
      <CardContent>
        <Typography gutterBottom variant='h6' component='div'>
          {title}
        </Typography>
        <Typography variant='body2' color='text.secondary' align='justify'>
          {String(description).substring(0, 100) + '...'}
        </Typography>
      </CardContent>
      <CardActions>
        <Button fullWidth size='small' color='secondary' onClick={onOpenDialog}>
          Mais informações
        </Button>
        {!confirmOffer ? (
          <Button
            fullWidth
            size='small'
            color='primary'
            onClick={handleMyProduct}
            disabled={finishTemplate}
          >
            Esse é o meu aparelho!
          </Button>
        ) : (
          <></>
          // <Button
          //   fullWidth
          //   size='small'
          //   color='primary'
          //   variant='contained'
          //   onClick={handleOpenClientDialog}
          // >
          //   Confirmar oferta
          // </Button>
        )}
      </CardActions>
      <DialogMoreInformation
        open={openDialog}
        allInformation={allInformation}
        handleClose={handleCloseDialog}
        handleMyProduct={handleMyProduct}
        finishTemplate={finishTemplate}
      />
      <RegisterClientDialog
        open={openClientDialog}
        onClose={handleCloseClientDialog}
        createTradeIn={createTradeIn}
      />
    </Card>
  );
};
