import React from 'react';

import Link from '@material-ui/core/Link';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, FormatMoney, Spacer } from 'components';

export const DialogAverage = ({ open, onClose, average, productSearchData }) => {
  if (!open) {
    return null;
  }

  console.log('productSearchData', productSearchData);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <Dialog.Title>Aviso</Dialog.Title>
      <Dialog.Title>
        {' '}
        <Typography color='secondary' align='left'>
          {' '}
          Valor médio de mercado: <FormatMoney>{average}</FormatMoney>
        </Typography>
      </Dialog.Title>

      <Dialog.Title>
        {' '}
        <Typography align='justify'>
          Calculamos o valor médio de acordo com as seguintes lojas:{' '}
        </Typography>
      </Dialog.Title>
      <Spacer size={10} />
      <Dialog.Content>
        <Table size='small'>
          <TableBody>
            {productSearchData?.map((item) => (
              <TableRow key={item.id}>
                <TableCell style={{ fontWeight: 'bold' }}>
                  {' '}
                  <Link href={item?.offer?.offer_page_url} underline='always' target='_blank'>
                    {item?.offer?.store_name}
                  </Link>
                </TableCell>
                <TableCell>{item?.offer?.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Dialog.Content>
      <Dialog.Actions>
        <Button fullWidth color='primary' onClick={onClose}>
          Confirmar
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};
