import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  service_number: yup.string().required(),
});

export function BudgetTemplate() {
  const handleDownloadTagsClick = async (service) => {
    const { data } = await budgetService.getTemplate(service);
    console.log('Data: ', data);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `Laudo.pdf`);
  };

  const onSubmitHandler = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);

    handleDownloadTagsClick(values.service_number)
      .then((response) => {
        console.log('Response: ', response);

        setTimeout(() => {
          setSubmitting(false);
          toast.success('Reimpressão realizada com sucesso.');
        }, 2000);
      })
      .catch((err) => {
        setSubmitting(false);

        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc.decode(arr);

        const parsedResult = JSON.parse(result);

        toast.error(parsedResult?.message);
      });
    resetForm();
  };

  return (
    <>
      <PageTitle>Reimpressão de laudo ORÇAMENTO/REPARO</PageTitle>
      <Formik
        initialValues={{ service_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  inputProps={{ maxLength: 50, minlength: 1 }}
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
