import { toast } from 'react-toastify';

export const addPnToCart = (state, action) => {
  const { part_number } = action;
  const cart = [...state];

  if (!part_number.sell_price) {
    toast.error('Part Number sem preço de venda cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...part_number, amount: 1, sub_total: part_number.sell_price }];
  }

  console.log('Part Number Added: ', part_number);

  const hasPnIndexInCart = state.findIndex((a) => {
    return a.id == action.part_number.id;
  });

  let pnAdded = [];

  if (hasPnIndexInCart >= 0) {
    pnAdded = cart[hasPnIndexInCart];

    pnAdded.amount += 1;
    pnAdded.sub_total = pnAdded.amount * pnAdded.sell_price;

    return cart;
  }
  return [...cart, { ...part_number, amount: 1, sub_total: part_number.sell_price }];
};

export const removePnFromCart = (state, action) => {
  const cart = [...state];
  const { part_number } = action;

  const hasPnIndexInCart = state.findIndex((a) => {
    return a.id === part_number.id;
  });

  if (hasPnIndexInCart >= 0) {
    cart.splice(hasPnIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updatePnAmount = (state, action) => {
  const cart = [...state];
  const { amountAndPnId } = action;

  const pnIndex = cart.findIndex((a) => {
    return a.id === amountAndPnId.part_number_id;
  });

  cart[pnIndex].amount = amountAndPnId.amount;

  cart[pnIndex].sub_total = cart[pnIndex].amount * cart[pnIndex].sell_price;

  if (amountAndPnId.amount <= 0) {
    cart.splice(pnIndex, 1);
  }

  return cart;
};

export const cleanCart = (state, action) => {
  const { cartCleaned } = action;

  return cartCleaned;
};
