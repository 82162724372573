import React, { useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FormatDate, Mask, Spacer } from 'components';
import { useFormikContext } from 'formik';

import { useStyles } from './styles';

export const DataOs = ({ serviceOrder }) => {
  const classes = useStyles();

  const { client } = serviceOrder;

  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    setFieldValue('service_order_id', serviceOrder.id);
  }, [setFieldValue, serviceOrder.id]);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant='h6' gutterBottom>
        Ordem de serviço
      </Typography>
      <Grid container spacing={4}>
        <Grid item>
          <p className={classes.detailKey}>Número Service:</p>
          <p className={classes.detailValue}>{serviceOrder?.number}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Model:</p>
          <p className={classes.detailValue}>{serviceOrder?.model}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Número de série:</p>
          <p className={classes.detailValue}>{serviceOrder?.serial_number}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Seguradora:</p>
          <p className={classes.detailValue}>{serviceOrder?.SubInsurancePll?.name}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>NF Compra:</p>
          <p className={classes.detailValue}>{serviceOrder?.purchase_invoice}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Processo:</p>
          <p className={classes.detailValue}>
            {serviceOrder?.process?.initials} - {serviceOrder?.process?.description}
          </p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Etapa:</p>
          <p className={classes.detailValue}>{serviceOrder?.status_alias}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Status:</p>
          <p className={classes.detailValue}>{serviceOrder?.status_description}</p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Data da ordem de serviço:</p>
          <p className={classes.detailValue}>
            <FormatDate>{serviceOrder?.created_at}</FormatDate>
          </p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Data de compra:</p>
          <p className={classes.detailValue}>
            <FormatDate>{serviceOrder?.purchase_date}</FormatDate>
          </p>
        </Grid>
        <Grid item>
          <p className={classes.detailKey}>Data de fabricação:</p>
          <p className={classes.detailValue}>
            <FormatDate>{serviceOrder?.fabrication_date}</FormatDate>
          </p>
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={32} />

      <Typography className={classes.title} variant='h6' gutterBottom>
        Cliente
      </Typography>
      <Grid container spacing={4}>
        <Grid item>
          <p className={classes.detailKey}>Nome do cliente:</p>
          <p className={classes.detailValue}>{client?.name}</p>
        </Grid>
        {client?.email && (
          <Grid item>
            <p className={classes.detailKey}>Email:</p>
            <p className={classes.detailValue}>{client?.email}</p>
          </Grid>
        )}
        {client?.cgc && (
          <Grid item>
            <p className={classes.detailKey}>CPF ou CNPJ:</p>
            <p className={classes.detailValue}>
              <Mask type='document'>{client?.cgc}</Mask>
            </p>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
