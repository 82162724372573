/* eslint-disable no-restricted-imports */
import React from 'react';

import { Card, CardContent, Typography, Grid } from '@material-ui/core';

import { Button } from 'components';

export function ReceivingCardList({ receivingList, onContinue, onFinish }) {
  return (
    <>
      <Typography variant='h6'>Coletas pendentes</Typography>
      <Grid container spacing={2}>
        {receivingList.map((receiving, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant='h6'>Recebimento #{receiving.id}</Typography>
                <Typography variant='body1'>
                  Transportadora: {receiving.carrier?.description}
                </Typography>
                <Typography variant='body1'>Motorista: {receiving.driver}</Typography>
                <Typography variant='body1'>Placa: {receiving.plate}</Typography>
                <Typography variant='body1'>Itens Coletados: {receiving.itemsCollected}</Typography>

                <Grid container spacing={2}>
                  <Grid item sm={5}>
                    <Button fullWidth onClick={() => onContinue(receiving)}>
                      Continuar Coleta
                    </Button>
                  </Grid>
                  <Grid item sm={5}>
                    <Button fullWidth onClick={() => onFinish(receiving.id)} color='secondary'>
                      Encerrar Coleta
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
