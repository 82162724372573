import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Dialog, Button, Text, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

import { useStyles } from './styles';

export const OrderDialog = ({ open, handleClose, searchComponents }) => {
  if (!open) return null;
  const classes = useStyles();

  const [componentLevelLocation, setComponentLevelLocation] = useState();

  const [stockLocations, , loadingStockLocations] = useQuery(
    () =>
      componentService.listStockLocationsByLevelLocation(
        componentLevelLocation ?? searchComponents?.component_level_location_id,
      ),
    [componentLevelLocation],
  );

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const onSubmitHandler = async (values) => {
    // try {
    // } catch (error) {
    // }
  };

  const upperValue = (e) => {
    e.target.value = ('' + e.target.value)?.toUpperCase();
  };

  console.log('Service Order: ', searchComponents);

  const levelLocationsOptions = (levelLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.description,
  }));

  const stockLocationsOptions = (stockLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.location_name,
  }));

  return (
    <Dialog
      maxWidth='md'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Part number: {searchComponents?.part_number}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            id: searchComponents?.id,
            part_number: searchComponents?.part_number,
            quantity: searchComponents?.quantity ?? '0',
            purchase_price: searchComponents?.purchase_price,
            description: searchComponents?.description,
            component_location_id: searchComponents?.location_id ?? '',
            component_level_location_id: searchComponents?.component_level_location_id,
          }}
          onSubmit={onSubmitHandler}
        >
          <Form>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={4}>
                <Field
                  name='part_number'
                  label='Part Number'
                  variant='outlined'
                  size='small'
                  emptyOption='Selecione'
                  component={Text}
                  onChange={(e) => {
                    upperValue(e);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='quantity'
                  label='Quantidade'
                  // mask='number'
                  variant='outlined'
                  size='small'
                  emptyOption='Selecione'
                  component={Text}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='purchase_price'
                  label='Preço de compra'
                  variant='outlined'
                  size='small'
                  mask='money'
                  component={Text}
                  inputProps={{
                    min: 1,
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='component_location_id'
                  label='Local'
                  variant='outlined'
                  size='small'
                  emptyOption='Selecione'
                  component={Select}
                  options={stockLocationsOptions}
                />
              </Grid>
              <Grid item xs={4}>
                <Field
                  name='component_level_location_id'
                  label='Tipo de estoque'
                  variant='outlined'
                  size='small'
                  emptyOption='Selecione'
                  component={Select}
                  location={loadingLevelLocations}
                  options={levelLocationsOptions}
                  onChange={(e) => setComponentLevelLocation(e)}
                />
              </Grid>

              <Grid item xs={4}>
                <Field
                  name='description'
                  label='Descrição'
                  variant='outlined'
                  size='small'
                  emptyOption='Selecione'
                  component={Text}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button fullWidth color='secondary' onClick={handleClose}>
                  Fechar
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Button fullWidth color='primary' type='submit'>
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
