import React, { useState } from 'react';
import { BiTransfer } from 'react-icons/bi';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';

import { ConfirmDialog } from './ConfirmDialog';

const columns = [
  { label: 'Part Number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Billing', align: 'center' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export const TableComponentsOutOfWarranty = ({ componentsOutOfWarranty }) => {
  const [confirmDialog, setConfirmDialog] = useBoolean();
  const [selectedOrdering, setSelectedOrdering] = useState({});
  const [dialogProps, setDialogProps] = useState({});

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog}
        onClose={setConfirmDialog.toFalse}
        dialogProps={dialogProps}
        selectedOrdering={selectedOrdering}
      />
      <Typography variant='h6' style={{ marginLeft: 18 }}>
        Componentes em Garantia
      </Typography>
      <Table
        headers={columns}
        emptyMessage='Nenhum registro encontrado.'
        striped
        disableNumeration
        maxHeight={400}
      >
        {componentsOutOfWarranty?.map((component) => (
          <TableRow key={component.id}>
            <TableCell align='center'>{component?.part_number}</TableCell>
            <TableCell align='center'>{component?.description}</TableCell>
            <TableCell align='center'>{component?.billing_id}</TableCell>
            <TableCell align='center'>{component?.quantity}</TableCell>
            <TableCell align='center'>
              <section>
                <IconButton
                  onClick={() =>
                    handleActionClick(
                      {
                        title: 'Deseja confirmar a transferência para o estoque?',
                        accept: true,
                      },
                      component,
                    )
                  }
                >
                  <BiTransfer style={{ color: '#4CAF50' }} />
                </IconButton>
              </section>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};
