/* eslint-disable react/react-in-jsx-scope */
import { memo } from 'react';

import {
  LinearProgress,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@mui/material';

import { Environment } from '../../environment';

const TableComponent = ({ headers, isLoading, totalCount, children, handleChangePage, page }) => {
  return (
    <TableContainer component={Paper} variant='outlined' sx={{ m: 0, width: 'auto' }}>
      <Table size='medium'>
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell
                key={header.label}
                variant='head'
                width={header.width}
                align={header.align || 'left'}
                sx={{ fontWeight: 'bold', fontSize: '16px' }}
              >
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>

        {totalCount === 0 && !isLoading && <caption>{Environment.EMPTY_LIST}</caption>}

        <TableFooter>
          {isLoading && (
            <TableRow>
              <TableCell colSpan={10}>
                <LinearProgress variant='indeterminate' />
              </TableCell>
            </TableRow>
          )}
          {totalCount > 0 && totalCount > Environment.ROWS_LIMIT && (
            <TableRow>
              <TableCell colSpan={4}>
                <Pagination
                  page={page}
                  count={Math.ceil(totalCount / Environment.ROWS_LIMIT)}
                  onChange={
                    (_, newPage) => handleChangePage(_, newPage.toString())
                    // setSearchParams(
                    //   { searchFilter: search, skip: newPage.toString() },
                    //   { replace: true },
                    // )
                  }
                />
              </TableCell>
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export const SimpleTable = memo(TableComponent);
