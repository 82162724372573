import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_ALL_ORDERS_FOR_PICKING } from 'services/graphql/query/b2c';
import { B2C_PICKING_REPORT } from 'services/graphql/query/reports';
import XLSX from 'xlsx';

import { ImportButton } from './components/ImportButton';
import { PickingB2CTable } from './components/PickingB2CTable';

export const PickingContext = createContext();

const pickingOptions = [
  { value: 'b2b', label: 'B2B' },
  { value: 'b2c', label: 'B2C' },
];

export function Picking() {
  const [selectedPicking, setSelectedPicking] = useState('');
  const [search, setSearch] = useState('');

  const b2cForPicking = useQuery(LIST_ALL_ORDERS_FOR_PICKING, {
    skip: !selectedPicking || selectedPicking !== 'b2c',
    fetchPolicy: 'network-only',
  });

  const [downloadReport, downloadReportOptions] = useMutation(B2C_PICKING_REPORT, {
    onCompleted: (data) => {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data?.b2CPickingReport));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          warehouse: worksheet,
        },
        SheetNames: ['warehouse'],
      });
      XLSX.writeFile(workbook, `Relatório_Picking.xlsx`);
      toast.success('Relatório baixado com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Erro ao baixar o relatório`);
    },
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <PageTitle>Picking</PageTitle>

      <Formik initialValues={{ picking: '', search: '' }}>
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
              <Field
                name='picking'
                component={Select}
                label='Tipo de Picking'
                options={pickingOptions}
                value={setSelectedPicking}
                onChange={(e) => setSelectedPicking(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Field
                label='Pesquisar'
                name='search'
                size='small'
                variant='outlined'
                endAdornment={<SearchIcon />}
                value={search}
                onChange={handleSearchChange}
                component={Text}
                disabled={!selectedPicking}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <Button
                fullWidth
                color='secondary'
                onClick={async () => await downloadReport()}
                disabled={downloadReportOptions.loading}
                loading={downloadReportOptions.loading}
              >
                Baixar relatório
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={2} xl={2}>
              <ImportButton refetch={b2cForPicking.refetch} />
            </Grid>
          </Grid>
        </Form>
      </Formik>

      <Spacer size={42} />

      {selectedPicking && (
        <PickingContext.Provider
          value={{
            b2cOrders: b2cForPicking?.data?.listAllOrdersForPicking ?? [],
            b2cLoading: b2cForPicking?.loading ?? false,
            b2cRefetch: b2cForPicking.refetch,
            search: search,
          }}
        >
          {selectedPicking === 'b2c' && <PickingB2CTable />}
        </PickingContext.Provider>
      )}
    </>
  );
}
