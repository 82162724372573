import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Spacer, Table, Text } from 'components';
import { Form, Formik, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services/budget';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';
import { validationSchema } from './validation';
const columns = [
  { label: 'Nome do Cliente' },
  { label: 'Número da Service' },
  { label: 'Tipo' },
  { label: 'Status' },
];

export const TableWainting = () => {
  const userId = useContextSelector(AuthContext, (context) => context.user.id);

  const [services, setService] = useState([]);
  const [budgets, , loadingBudgets, refetchPreBudgets] = useQuery(
    budgetService.getServiceOrderType,
    [],
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    console.log('budgets: ', budgets);
    setService(budgets);
  }, [budgets]);

  const classes = useStyles();

  const onSubmitHandler = async (value, { setSubmitting, resetForm }) => {
    try {
      await budgetService.setServiceOrderType(value.service_order_number);

      toast.success('Retirada realizada.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao fazer a retirada.');
    } finally {
      setSubmitting(false);
      refetchPreBudgets();
      resetForm();
      budgetService.getServiceOrderType([]);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          service_order_number: '',
        }}
        enableReinitialize={true}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
        handleSubmit={TableWainting}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='service_order_number'
                    label='Service'
                    size='small'
                    variant='outlined'
                    inputProps={{ maxLength: 15 }}
                    component={Text}
                    endAdornment={<SearchIcon />}
                  />
                </Grid>
                {/* <Grid item xs={12} lg={3}>
                  <Button
                    fullWidth
                    disabled={!props.dirty || !props.isValid}
                    type='submit'
                    onCLick={props.resetForm}
                  >
                    Confirmar Retirada
                  </Button>
                </Grid> */}
              </Grid>
              <Spacer size={16} axis='vertical' />
              <Grid container>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    loading={loadingBudgets}
                    emptyMessage='Nenhum registro encontrado.'
                    disableNumeration
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  >
                    {services?.map((budgets) => (
                      <TableRow key={budgets.name}>
                        <TableCell>{budgets.name}</TableCell>
                        <TableCell>{budgets.number}</TableCell>
                        <TableCell>{budgets.description}</TableCell>
                        <TableCell>{budgets.status_description}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
