import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import clsx from 'clsx';
import { Text, Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';

import { ConfirmDialog } from './ConfirmDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Quantidade', align: 'center' },
  { label: 'CPF/CNPJ', align: 'center' },
  { label: 'Responsavél pela venda', align: 'center' },
  { label: 'Ações', align: 'center' },
];

const rows = [
  {
    part_number: 15426,
    description: 'Placa',
    quantity: 5,
    cgc: '484.602.898-5 ',
    responsible_for_the_sale: 'Bruno Leandro',
  },
  {
    part_number: 15426,
    description: 'Placa',
    quantity: 5,
    cgc: '484.602.898-5 ',
    responsible_for_the_sale: 'Bruno Leandro',
  },
  {
    part_number: 15426,
    description: 'Placa',
    quantity: 5,
    cgc: '484.602.898-5 ',
    responsible_for_the_sale: 'Bruno Leandro',
  },
  {
    part_number: 15426,
    description: 'Placa',
    quantity: 5,
    cgc: '484.602.898-5 ',
    responsible_for_the_sale: 'Bruno Leandro',
  },
];

export const PurchaseRequisition = () => {
  const classes = useStyles();
  const [dialogProps, setDialogProps] = useState({});
  const [selectedOrdering, setSelectedOrdering] = useState({});

  const [orders, setOrders] = useState([]);

  const [ordersData, , loadingOrdersData, refetchOrdersData] = useQuery(
    componentService.listByOrderWFC,
    [],
  );

  useEffect(() => {
    console.log(ordersData);
    setOrders(ordersData);
  }, [ordersData]);

  const [confirmDialog, setConfirmDialog] = useBoolean();

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
    console.log('ORDER RING: ', ordering);
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog}
        onClose={setConfirmDialog.toFalse}
        dialogProps={dialogProps}
        selectedOrdering={selectedOrdering}
        reloadComponents={refetchOrdersData}
      />
      <PageTitle>Confirmar encomenda</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={columns}
            loading={loadingOrdersData}
            striped
            // maxHeight={350}
            emptyMessage='Nenhum registro encontrado.'
            disableNumeration
          >
            {orders?.map((order) => (
              <TableRow key={order.id}>
                <TableCell>{order.component.part_number}</TableCell>
                <TableCell>{order.component.description}</TableCell>
                <TableCell align='center'>{order.quantity}</TableCell>
                <TableCell align='center'>{order.client.cgc}</TableCell>
                <TableCell align='center'>{order.user.name}</TableCell>
                <TableCell>
                  {' '}
                  <div className={classes.actions}>
                    <IconButton
                      onClick={() =>
                        handleActionClick(
                          {
                            title: 'Tem certeza que deseja reprovar a requisição?',
                            accept: false,
                          },
                          order,
                        )
                      }
                    >
                      <CloseIcon className={clsx(classes.actionIcon, classes.closeIcon)} />
                    </IconButton>
                    <IconButton
                      onClick={() =>
                        handleActionClick(
                          {
                            title: 'Tem certeza que deseja aprovar a requisição?',
                            accept: true,
                          },
                          order,
                        )
                      }
                    >
                      <CheckIcon className={clsx(classes.actionIcon, classes.checkIcon)} />
                    </IconButton>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
