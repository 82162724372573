import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Dialog, Mask, Table, Tabs } from 'components';
import { Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';
import { accountService, componentService } from 'services';
import * as yup from 'yup';

import { OsPartsRemoveFromOsDialog } from './OsPartsRemoveFromOsDialog';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

const columns = [
  { label: 'Part-Number' },
  { label: 'Description' },
  { label: 'Quantidade em estoque' },
  { label: 'Quantidade requisitada' },
  { label: 'Local de estoque' },
  { label: 'AÇÃO', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const AnalysisStockRequestDialog = ({
  open,
  handleClose,
  stockRequest = {},
  reloadStockRequest,
}) => {
  const stockComponents = stockRequest?.component_id || [];
  const [isDetailsOpenRemoveParts, setIsDetailsStateRemoveParts] = useBoolean();

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () =>
      budgetService.listBudgetsServicesByServiceIdToAplicatedComponents(
        stockRequest?.service_order_id,
      ),
    [],
  );

  const [components, setComponents] = useState();
  const [validateValues, setValidateValues] = useState([]);
  const [budgets, setBudget] = useState({});

  const [user, , loadingUser, refetchUser] = useQuery(
    () => accountService.getUserById(stockRequest?.user_id),
    [stockRequest?.user_id],
  );

  const handleTest = async (stock) => {
    const { data } = await componentService.listComponentsByIds(
      stockRequest?.service_order_id,
      stock,
    );

    setComponents(data);
  };

  // const serviceFromGaia = stockRequest.service.number;

  useEffect(() => {
    handleTest(stockRequest?.component_id);
    handleValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockRequest?.component_id]);

  const handleCloseInformationRemoveParts = () => {
    setIsDetailsStateRemoveParts.toFalse();
  };

  const handleOpenInformatioRemoveParts = (e, item) => {
    setIsDetailsStateRemoveParts.toTrue();
    setBudget(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      reloadStockRequest();
      toast.success('Nome do produto alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o nome do produto.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  const handleValues = () => {
    setValidateValues([]);

    components?.map((stock) => {
      if (stock?.amount_stock < stock?.amount) {
        setValidateValues((oldState) => [...oldState, false]);
      } else {
        setValidateValues((oldState) => [...oldState, true]);
      }
    });
  };

  const onSubmitSituation = async (situation) => {
    try {
      console.log(situation);

      await componentService.updateStockRequisition(stockRequest?.service_order_id, situation);

      if (situation === true) {
        toast.success('Requisição de Estoque aprovada com sucesso.');
      } else {
        toast.warn('Requisição de Estoque reprovada.');
      }

      reloadStockRequest();
      handleClose();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Erro ao aprovar ou reprovar requisição de estoque.',
      );
    }
  };

  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Ordem de Serviço: {stockRequest?.service_order_number}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            service_order_number: stockRequest.service_order_number,
            part_number: '',
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Tabs gutterButtom>
                  <Tabs.Content label='Solicitante'>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Nome do Solicitante:</Typography>
                        <Typography>{user?.name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Email:</Typography>
                        <Typography>{user?.email}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Grupo:</Typography>
                        <Typography>{user?.role?.description}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Telefone:</Typography>
                        <Typography>
                          <Mask type='phone'>
                            {user?.phone ? user?.phone : 'Sem telefone cadastrado'}
                          </Mask>
                        </Typography>
                      </Grid>
                    </Grid>
                  </Tabs.Content>
                  <Tabs.Content label='Componentes'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Table headers={columns} striped disableNumeration>
                          {components?.map((row) => (
                            <TableRow key={row.description}>
                              <TableCell>{row.part_number}</TableCell>
                              <TableCell>{row.description}</TableCell>
                              <TableCell>{row.amount_stock}</TableCell>
                              <TableCell>{row.amount}</TableCell>
                              <TableCell>{row.location_name}</TableCell>
                              <TableCell align='center'>
                                {/* <Button
                                  color='secondary'
                                  fullwidth
                                  onClick={(e) => handleOpenInformatioRemoveParts(e, row)}
                                >
                                  Reprovar
                                </Button> */}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </Grid>
                    </Grid>
                  </Tabs.Content>
                </Tabs>
                <DialogActions>
                  {/* <Button autoFocus onClick={() => onSubmitSituation(false)} color='secondary'>
                    Reprovar
                  </Button> */}
                  {/* <Button
                    color='primary'
                    onClick={() => onSubmitSituation(true)}
                    disabled={validateValues.includes(false)}
                  >
                    Aprovar
                  </Button> */}
                </DialogActions>

                <OsPartsRemoveFromOsDialog
                  open={isDetailsOpenRemoveParts}
                  handleClose={handleCloseInformationRemoveParts}
                  // service={serviceFromGaia}
                  stockRequisition={budgets}
                  reloadBudgets={refetchBudgets}
                />
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
