import React, { Fragment } from 'react';
import { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Dialog, FormatDate } from 'components';
import { SimpleTable } from 'components/SimpleTable';
import { useQuery } from 'hooks/useQuery';
import { warehouseService } from 'services';

const columns = [
  { label: 'Data atendimento', align: 'center' },
  { label: 'Modelo atendido', align: 'center' },
  { label: 'Serial', align: 'center' },
  { label: 'Comentário', align: 'center' },
  { label: 'Objeto', align: 'center' },
  { label: 'Atendente', align: 'center' },
];

export function RequestDetails({ request, isOpen = false, handleClose }) {
  if (!isOpen) {
    return <></>;
  }

  const [page, setPage] = useState(1);

  const [service, , loadingService, refetchService] = useQuery(
    () => warehouseService.findService(request.id),
    [request],
  );

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <Dialog.Title>Detalhes do atendimento</Dialog.Title>
      <Dialog.Content>
        <SimpleTable
          headers={columns}
          totalCount={1}
          isLoading={loadingService}
          handleChangePage={handleChangePage}
          page={page}
        >
          <TableRow>
            <TableCell align='center'>
              <FormatDate format='dd/MM/yyyy HH:mm'>{service?.created_at}</FormatDate>
            </TableCell>
            <TableCell align='center'>{service?.product?.model}</TableCell>
            <TableCell align='center'>{service?.product?.serial}</TableCell>
            <TableCell align='center'>{service?.request?.comment ?? service?.comment}</TableCell>
            <TableCell align='center'>
              {service?.request?.minute_item?.object?.full_object}
            </TableCell>
            <TableCell align='center'>{service?.user?.name}</TableCell>
          </TableRow>
        </SimpleTable>
      </Dialog.Content>
    </Dialog>
  );
}
