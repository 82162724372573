import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    button: {
      fontSize: 11,
      height: 30,
    },
  };
});
