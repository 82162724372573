import React, { useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ServiceOrderContext } from 'pages/Private/ServiceOrder/Consult';

import { useStyles } from './styles';

export default function DenseTable() {
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGspn, serviceFromGaia } = serviceData;

  console.log('serviceFromGaia: ', serviceFromGaia);
  console.log('serviceFromGaia Status: ', serviceFromGaia?.status?.description);

  const classes = useStyles();

  return (
    <TableContainer>
      <Table className={classes.table} size='small' aria-label='a dense table'>
        <TableHead>
          <TableRow>
            <TableCell align='justify'>Tipo Informação</TableCell>
            <TableCell align='justify'>Informação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align='justify'>Nome:</TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>E-mail:</TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.email}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>CPF/CNPJ:</TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.cgc}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Celular:</TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.phone_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>CEP: </TableCell>
            <TableCell align='justify'>
              {serviceFromGaia?.client?.addresses?.[0]?.zipcode}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>UF: </TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.addresses?.[0]?.state}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Cidade: </TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.addresses?.[0]?.city}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Bairro: </TableCell>
            <TableCell align='justify'>
              {serviceFromGaia?.client?.addresses?.[0]?.district}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Rua: </TableCell>
            <TableCell align='justify'>{serviceFromGaia?.client?.addresses?.[0]?.street}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Nº: </TableCell>
            <TableCell align='justify'>
              {serviceFromGaia?.client?.addresses?.[0]?.street_number}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align='justify'>Complemento: </TableCell>
            <TableCell align='justify'>
              {serviceFromGaia?.client?.addresses?.[0]?.complement}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
