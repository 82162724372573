const plate = {
  apply: (value) => {
    if (!value) return '';

    // Remove qualquer caractere que não seja letra ou número e converte para maiúsculas
    const cleanValue = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();

    // Expressão regular para formar a máscara de placa de veículo (AAA-1234 ou AAA1A23)
    const regexp = /^([a-zA-Z]{0,3})(\d{0,1})([a-zA-Z0-9]{0,3})/;
    const result = '$1$2-$3';

    // Aplica a máscara e remove o hífen no final, caso presente
    return cleanValue.replace(regexp, result).replace(/-$/, '');
  },

  clean: (value) =>
    (value || '')
      .replace(/[^a-zA-Z0-9]/g, '')
      .substr(0, 7)
      .toUpperCase(),
};

export default plate;
