import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { Mask } from 'components/Mask';
import { Form, Formik } from 'formik';
import { componentService } from 'services';

import { useStyles } from './styles';

export const ConfirmDialog = ({
  open,
  onClose,
  selectedOrdering,
  dialogProps,
  reloadComponents,
}) => {
  const classes = useStyles();

  console.log('SELECTED: ', selectedOrdering);

  console.log('DialogProps: ', dialogProps);

  const approveComponentOrder = async (id) => {
    try {
      await componentService.approveOrder(id);
      reloadComponents();
      toast.success('Requisição de componentes aprovada.');
    } catch (err) {
      console.log('Deu ruim: ', id);
      toast.error(err.response?.data?.message || 'Falha ao realizar na operação dos componentes.');
    } finally {
    }
  };

  const reproveComponentOrder = async (id) => {
    try {
      await componentService.reproveOrder(id);

      reloadComponents();

      toast.success('Requisição de componentes reprovada.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Falha ao realizar na operação dos componentes.');
    } finally {
      console.log('Finally');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <Dialog.Title onClose={onClose}>{dialogProps.title}</Dialog.Title>
      <Dialog.Content>
        <Formik>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <p className={classes.detailKey}>
                      <strong>Responsável pela venda</strong>
                    </p>
                    <p className={classes.detailValue}>{selectedOrdering?.client?.name}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={classes.detailKey}>
                      <strong>Part Number</strong>
                    </p>
                    <p className={classes.detailValue}>
                      {selectedOrdering?.component?.part_number}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={classes.detailKey}>
                      <strong>Descrição</strong>
                    </p>
                    <p className={classes.detailValue}>
                      {selectedOrdering?.component?.description}
                    </p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={classes.detailKey}>
                      <strong>Quantidade</strong>
                    </p>
                    <p className={classes.detailValue}>{selectedOrdering?.quantity}</p>
                  </Grid>
                  <Grid item xs={6}>
                    <p className={classes.detailKey}>
                      <strong>CPF/CNPJ do cliente</strong>
                    </p>
                    <p className={classes.detailValue}>
                      <Mask type='document'>{selectedOrdering?.client.cgc}</Mask>
                    </p>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} variant='text' color='default'>
          Não
        </Button>
        {dialogProps.accept === true ? (
          <Button
            onClick={() => {
              approveComponentOrder(selectedOrdering.id), onClose();
            }}
          >
            Aprovar
          </Button>
        ) : (
          <Button
            onClick={() => {
              reproveComponentOrder(selectedOrdering.id), onClose();
            }}
          >
            Reprovar
          </Button>
        )}
      </Dialog.Actions>
    </Dialog>
  );
};
