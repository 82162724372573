import { gql } from '@apollo/client';

export const LIST_CARRIER = gql`
  query {
    listCarriers {
      id
      description
      __typename @skip(if: true)
    }
  }
`;
