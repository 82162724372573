import React, { Fragment, useContext, useState } from 'react';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormatDate } from 'components';
import { SimpleTable } from 'components/SimpleTable';

import { WarehouseServiceContext } from '..';

import { SubTitle } from 'pages/Layout/SubTitle';

import { CancelServiceDialog } from './CancelServiceDialog';
import { ServiceDialog } from './ServiceDialog';

const columns = [
  { label: 'ID', align: 'center' },
  { label: 'Requerente', align: 'center' },
  { label: 'Modelo', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'CGC', align: 'center' },
  { label: 'Status', align: 'center' },
  { label: 'Criado em', align: 'center' },
  { label: 'Atualizado em', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export function ServiceTables() {
  const { requests, loadingRequests, handleChangePage, page, refetchRequests } =
    useContext(WarehouseServiceContext);

  const [request, setRequest] = useState();

  const [isOpenServiceDialog, setIsOpenServiceDialog] = useState(false);
  const [isOpenCancelServiceDialog, setIsOpenCancelServiceDialog] = useState(false);

  function handleOpenServiceDialog(requested) {
    setRequest(requested);
    setIsOpenServiceDialog(true);
  }

  function handleCloseServiceDialog() {
    setIsOpenServiceDialog(false);
  }

  function handleOpenCancelServiceDialog(requested) {
    setRequest(requested);
    setIsOpenCancelServiceDialog(true);
  }

  function handleCloseCancelServiceDialog() {
    setIsOpenCancelServiceDialog(false);
  }
  return (
    <>
      <SubTitle>Pedidos</SubTitle>
      <SimpleTable
        headers={columns}
        totalCount={requests?.totalCount ?? 1}
        isLoading={loadingRequests}
        handleChangePage={handleChangePage}
        page={page}
      >
        {requests?.request?.map((row, index) => (
          <Fragment key={index}>
            <TableRow>
              <TableCell align='center'>{row?.id}</TableCell>
              <TableCell align='center'>{row?.user?.name}</TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>
                {row?.model}
              </TableCell>
              <TableCell align='center'>{row?.client?.name}</TableCell>
              <TableCell align='center'>{row?.client?.cgc}</TableCell>
              <TableCell
                align='center'
                style={{
                  fontWeight: 'bold',
                  color:
                    row?.status_id === 1
                      ? 'yellow'
                      : row?.status_id === 2
                      ? 'green'
                      : row.status_id === 3
                      ? 'red'
                      : 'blue',
                }}
              >
                {row?.status?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.created_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy HH:mm'>{row?.updated_at}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                {row?.status_id === 1 && (
                  <>
                    <IconButton color='primary' onClick={() => handleOpenServiceDialog(row)}>
                      <AddCircleIcon />
                    </IconButton>
                    <IconButton
                      color='secondary'
                      onClick={() => handleOpenCancelServiceDialog(row)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </>
                )}
                {/* <IconButton>
                  <VisibilityIcon />
                </IconButton> */}
              </TableCell>
            </TableRow>
          </Fragment>
        ))}
      </SimpleTable>
      <ServiceDialog
        isOpen={isOpenServiceDialog}
        handleClose={handleCloseServiceDialog}
        request={request}
        refetch={refetchRequests}
      />
      <CancelServiceDialog
        isOpen={isOpenCancelServiceDialog}
        handleClose={handleCloseCancelServiceDialog}
        request={request}
        refetch={refetchRequests}
      />
    </>
  );
}
