import { api } from 'services/api';

export class LogsService {
  async listBillingLogByServiceNumber(service_number) {
    return api.get(`/logs/logBilling/cronologic/${service_number}`);
  }

  async listBillingLogByBillingId(billing_id) {
    return api.get(`/logs/logBilling/byBilling/${billing_id}`);
  }
}

export const logsService = new LogsService();
