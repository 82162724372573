import React from 'react';
import { FaFileInvoiceDollar, FaUserAlt, FaRegHandshake } from 'react-icons/fa';
import { GrMoney } from 'react-icons/gr';

import clsx from 'clsx';

import { useStyles } from './styles';

export function StepIconComponent(props) {
  const classes = useStyles();
  const { active, completed } = props;

  const icons = {
    1: <FaFileInvoiceDollar size='20' />,
    2: <GrMoney size='20' />,
    3: <FaRegHandshake size='20' />,
    4: <FaUserAlt size='20' />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
