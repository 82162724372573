import React, { useEffect, useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { FormatDate } from 'components';
import { Table } from 'components/Table';

export const InvoiceInfos = ({ billingInfos }) => {
  const [billingInvoice, setInvoice] = useState();

  console.log('XABURI: ', billingInvoice);

  useEffect(() => {
    setInvoice(billingInfos[0] ?? '');
  }, [billingInfos]);

  const columns = [
    { label: 'Billing', align: 'center' },
    { label: 'Número da nota', align: 'center' },
    { label: 'Numero de série', align: 'center' },
    { label: 'Data de emissão', align: 'center' },
    { label: 'Data de recebimento', align: 'center' },
  ];

  return (
    <Table headers={columns} disabledNumeration>
      <TableRow align='center'>
        {console.log('TEST: ', billingInvoice)}
        <TableCell align='center'>{billingInvoice?.invoice?.invoice_key}</TableCell>
        <TableCell align='center'>{billingInvoice?.invoice?.invoice_number}</TableCell>
        <TableCell align='center'>{billingInvoice?.invoice?.serial_number}</TableCell>
        <TableCell align='center'>
          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
            {billingInvoice?.invoice?.emission_date}
          </FormatDate>
        </TableCell>
        <TableCell align='center'>
          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
            {billingInvoice?.invoice?.receipt_date}
          </FormatDate>
        </TableCell>
      </TableRow>
    </Table>
  );
};
