import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as UploadImg } from 'assets/images/undraw_investing_re_bov7.svg';
import { Button, Spacer } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { administrationService } from 'services/administration';
import XLSX from 'xlsx';

import { MonthlyClosingTable } from './MonthlyClosingTable';
import { useStyles } from './styles';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do GAIA',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Deixe todos os campos como texto',
  },
  {
    value: 5,
    label: 'Separe as casas decimais com vírgula',
  },
  {
    value: 6,
    label: 'Coloque o número do CNPJ sem utilizar ponto ou traço',
  },
  {
    value: 7,
    label: 'Salve o arquivo como .csv',
  },
];

export const MonthlyClosing = () => {
  const classes = useStyles();

  const inputFileRef = useRef(null);

  const [importing, setImporting] = useState(false);
  const [getInvoices, setInvoices] = useState();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleOpenInformation = () => {
    setIsDetailsState.toTrue();
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleDownloadLayout = async () => {
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'NUM NF',
          b: 'OS GAIA',
          c: 'CNPJ CLIENTE',
          d: 'VALOR DA NF',
          e: 'DATA EMISSÃO',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Layout.xlsx');
  };

  const handleFileChange = (e) => {
    toast.info('Importando planilha de fechamento...');
    handleCloseInformation();
    setImporting(true);
    try {
      const file = e.target.files[0];

      if (file.name.match('.xslx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const dadosFile = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[1] == null ||
            obj.split(';')[1] == undefined ||
            obj.split(';')[2] == null ||
            obj.split(';')[2] == undefined ||
            obj.split(';')[3] == null ||
            obj.split(';')[3] == undefined ||
            obj.split(';')[4] == null ||
            obj.split(';')[4] == undefined
          ) {
            return false;
          } else {
            const dados = {
              invoice: obj.split(';')[0],
              service_order: obj.split(';')[1],
              cgc: obj.split(';')[2],
              value:
                obj.split(';')[3] != null || obj.split(';')[3] != undefined
                  ? parseFloat(obj.split(';')[3].replace(',', '.')).toFixed(2)
                  : null,
              emission_date: obj.split(';')[4],
            };

            return dados;
          }
        });

        const { data } = await administrationService.validatedMonthlyClosing({
          dataFile: dadosFile,
        });

        setInvoices(data);
        setImporting(false);
      };
      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      setImporting(false);
      toast.error('Erro ao importar, tente novamente!');
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <PageTitle dev>Fechamento mensal</PageTitle>

      <Spacer size={35} />

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
          <Button
            loading={importing}
            color='secondary'
            variant='outlined'
            fullWidth
            onClick={() => handleDownloadLayout()}
          >
            Baixar layout
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
          <Button loading={importing} fullWidth onClick={() => handleOpenInformation()}>
            Importar planilha
          </Button>
        </Grid>
      </Grid>
      {getInvoices || importing ? (
        <>
          <MonthlyClosingTable invoices={getInvoices} loading={importing} />
        </>
      ) : (
        <Grid className={classes.containerImg} container>
          <UploadImg style={{ height: 300, marginTop: 15 }} />
        </Grid>
      )}

      <DialogInformationForImport
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        importFile={handleImportClick}
        examples={examples}
      />
    </>
  );
};
