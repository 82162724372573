import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog, Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { serviceOrderService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export const DialogChangeInformation = ({
  open,
  handleClose,
  accessory = {},
  reloadAccessories,
}) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await serviceOrderService.updateAccessory(accessory.id, values.description);

      reloadAccessories();
      toast.success('Nome do acessório alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o nome do acessório.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='xs' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Descrição: {accessory?.description}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ description: accessory.description }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Field
                        variant='outlined'
                        name='description'
                        label='Alterar descrição'
                        size='small'
                        component={Text}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
