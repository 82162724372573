import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { ReactComponent as GraphicImg } from 'assets/images/graphicMoney.svg';
import { DatePicker, Spacer } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { useStyles } from './styles';

export const ReportObjectsReceived = () => {
  const [inputData, setInputData] = useState(new Date());

  const classes = useStyles();

  const [totalBydgetAprovedDiary, setTotalBudgetAproved] = useState([]);

  const [totalBydgetAprovedDiary2, setTotalBudgetAproved2] = useState([]);

  const [totalBydgetAprovedDiary3, setTotalBudgetAproved3] = useState([]);

  const [totalBydgetAprovedDiary4, setTotalBudgetAproved4] = useState([]);

  const [totalBydgetAprovedDiary5, setTotalBudgetAproved5] = useState([]);

  const [totalBydgetAprovedDiary6, setTotalBudgetAproved6] = useState([]);

  const [totalBydgetAprovedDiaryCheckit, setTotalBudgetCheckit] = useState([]);

  const [totalBydgetAprovedDiaryRetail, setTotalBudgetRetail] = useState([]);

  const [totalBydgetAprovedDiaryQa, setTotalBudgetQa] = useState([]);

  const [repairtotal, setRepairTotal] = useState([]);

  const [sortingTotal, setSortingTotal] = useState([]);

  const [totalAllBudgetTodayData, , loadingtotalAllBudgetTodayData] = useQuery(
    () => serviceOrderService.getObjectReceived(inputData),
    [inputData],
    [],
  );

  const [totalAllBudgetTodayDataCheckit, , loadingtotalAllBudgetTodayDataCheckit] = useQuery(
    () => serviceOrderService.getCheckitDone(inputData),
    [inputData],
    [],
  );

  const [totalAllBudgetTodayDataRetail, , loadingtotalAllBudgetTodayDataRetail] = useQuery(
    () => serviceOrderService.getObjectRetail(inputData),
    [inputData],
    [],
  );

  const [totalQAData, , loadingqadata] = useQuery(
    () => serviceOrderService.listQaInspectorDiary(inputData),
    [inputData],
    [],
  );

  const [totalRepairData, , loadingagingData] = useQuery(
    () => serviceOrderService.listRepairInspectorDiary(inputData),
    [inputData],
    [],
  );

  const [totalSortingData, , loadingSortingData] = useQuery(
    () => serviceOrderService.listSortingTotal(inputData),
    [inputData],
    [],
  );

  useEffect(() => {
    if (totalSortingData) {
      const { count } = totalSortingData[0] ?? 0;
      setSortingTotal(count);
    }
  }, [totalSortingData]);

  useEffect(() => {
    if (totalAllBudgetTodayDataRetail) {
      const { count } = totalAllBudgetTodayDataRetail[0] ?? 0;
      setTotalBudgetRetail(count);
    }
  }, [totalAllBudgetTodayDataRetail]);

  useEffect(() => {
    if (totalRepairData) {
      const { totalreparo } = totalRepairData[0] ?? 0;
      setRepairTotal(totalreparo);
    }
  }, [totalRepairData]);

  useEffect(() => {
    if (totalQAData) {
      const { totalqa } = totalQAData[0] ?? 0;
      setTotalBudgetQa(totalqa);
    }
  }, [totalQAData]);

  useEffect(() => {
    if (totalAllBudgetTodayData) {
      const { totalobj, totalserv, name } = totalAllBudgetTodayData[0] ?? 0;
      setTotalBudgetAproved(totalobj);
      setTotalBudgetAproved2(totalserv);
      setTotalBudgetAproved3(name);
    }
  }, [totalAllBudgetTodayData]);

  useEffect(() => {
    if (totalAllBudgetTodayData) {
      const { totalobj, totalserv, name } = totalAllBudgetTodayData[1] ?? 0;
      setTotalBudgetAproved4(totalobj);
      setTotalBudgetAproved5(totalserv);
      setTotalBudgetAproved6(name);
    }
  }, [totalAllBudgetTodayData]);

  useEffect(() => {
    if (totalAllBudgetTodayDataCheckit) {
      const { totalcheck } = totalAllBudgetTodayDataCheckit[0] ?? 0;
      setTotalBudgetCheckit(totalcheck);
    }
  }, [totalAllBudgetTodayDataCheckit]);

  return (
    <>
      <PageTitle>Relatórios diários</PageTitle>
      <Formik
        initialValues={{
          input_data: inputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='input_data'
                variant='outlined'
                fullWidth
                inputVariant='outlined'
                label='DATA'
                component={DatePicker}
                disableFuture='true'
              />
            </Grid>
            <Spacer size={15}></Spacer>
            {useEffect(() => {
              setInputData(props.values.input_data);
            }, [props])}
          </Form>
        )}
      </Formik>
      <Spacer size={50} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperSale} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}> {totalBydgetAprovedDiary ?? 0}</p>
                <p className={classes.text}>Quantidade de objetos {totalBydgetAprovedDiary3}</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPaperSale}>
              <p>Recebimento {totalBydgetAprovedDiary3}</p>
            </footer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperQuantity} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}> {totalBydgetAprovedDiary4 ?? 0}</p>
                <p className={classes.text}>Quantidade de objetos {totalBydgetAprovedDiary6}</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPaperQuantity}>
              <p>Recebimento {totalBydgetAprovedDiary6}</p>
            </footer>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperPerformance} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{totalBydgetAprovedDiaryRetail ?? 0}</p>
                <p className={classes.text}>Quantidade de objetos VAREJO</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance}>
              <p>Recebimento VAREJO</p>
            </footer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperPerformance2} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{totalBydgetAprovedDiaryCheckit ?? 0}</p>
                <p className={classes.text}>Quantidade de finalizações CHECK-KIT</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance2}>
              <p>Finalização checkit</p>
            </footer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperPerformance3} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{totalBydgetAprovedDiaryQa ?? 0}</p>
                <p className={classes.text}>Quantidade de finalizações QA</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance3}>
              <p>Finalização QA</p>
            </footer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4} md={12}>
          <Paper className={classes.paperPerformance4} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{repairtotal ?? 0}</p>
                <p className={classes.text}>Quantidade de finalizações REPARO</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance4}>
              <p>Finalização REPARO</p>
            </footer>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={12} md={12}>
          <Paper className={classes.paperPerformance5} elevation={2}>
            <div className={classes.content}>
              <div>
                <p className={classes.mainTitle}>{sortingTotal ?? 0}</p>
                <p className={classes.text}>Quantidade de finalizações TRIAGEM</p>
              </div>
              <GraphicImg className={classes.graphicImg} />
            </div>

            <footer className={classes.footerPerformance5}>
              <p>Finalização TRIAGEM</p>
            </footer>
          </Paper>
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <Paper className={classes.paperGraphics} elevation={2}>
            <div className={classes.divSectionIlustrationGraphic}>
              <p className={classes.divider} />
              <divider>
                <section className={classes.sectionContextIlustrationGrahic}>
                  <IlustrationImg className={classes.imgIlustrationGraphic} />
                  <div className={classes.teste}>
                    <p className={classes.p}>Total de orçamentos aprovados.</p>
                    <p className={classes.titleNumber}>Nº {totalBydgetAprovedDiary ?? 0}</p>
                  </div>
                </section>
              </divider>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.paperGraphics1} elevation={2}>
            <div className={classes.divSectionIlustrationGraphic1}>
              <p className={classes.divider1} />
              <section className={classes.sectionContextIlustrationGrahic1}>
                <TesteGraficoImg className={classes.imgIlustrationGraphic1} />
                <div>
                  <p className={classes.p1}>Total de orçamentos reprovados.</p>
                  <p className={classes.titleNumber1}>Nº {totalBudgetReprovedDiary ?? 0}</p>
                </div>
              </section>
            </div>
          </Paper>
        </Grid> */}
      </Grid>
    </>
  );
};
