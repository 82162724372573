import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Select, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import { cashierService } from 'services/cashier';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  product_id: yup.number().required(),
  description: yup.string().required(),
  labor_price: yup.number().required(),
});

export const LabourDialog = ({
  open,
  handleClose,
  additionalServiceProductOptions,
  labor = {},
  refreshLabor,
}) => {
  if (!open) return null;
  const classes = useStyles();

  console.log('Service Order: ', labor);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await cashierService.updateLabor(
        labor.id,
        values.product_id,
        values.description,
        values.labor_price,
      );

      refreshLabor();
      toast.success('Taxa de análise alterada com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar a taxa de análise.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Alterar
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            labor_price: labor.labor_price,
            description: labor.description,
            product_id: labor.product_id,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Serviço'
                      variant='outlined'
                      size='small'
                      emptyOption='Selecione'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      variant='outlined'
                      label='Produto'
                      name='product_id'
                      size='small'
                      options={additionalServiceProductOptions}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      name='labor_price'
                      label='Valor'
                      variant='outlined'
                      size='small'
                      mask='money'
                      component={Text}
                      inputProps={{
                        min: 1,
                      }}
                    />
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='default' variant='text'>
                      Fechar
                    </Button>
                    <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
