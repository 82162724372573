import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { ServiceOrderContext } from 'pages/Private/ServiceOrder/Consult';

export default function GeralObservation() {
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography>{serviceFromGaia?.special_comment}</Typography>
      </Grid>
    </Grid>
  );
}
