import React from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Checkbox, Text, FormatMoney, Table } from 'components';
import { Field, useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services/component';

import { useStyles } from './styles';

const columns = [
  {
    label: '#',
    width: 80,
    align: 'center',
  },
  {
    label: 'Part Number',
  },
  {
    label: 'Descrição',
  },
  {
    label: 'Valor de compra',
  },
];

export const Form = () => {
  const classes = useStyles();

  const { values, setFieldValue, isValid, dirty, isSubmitting, handleSubmit } = useFormikContext();

  const [components, , loadingComponents] = useQuery(() => componentService.listComponents(), []);

  const handleCheckboxClick = (componentId) => {
    const { components_id } = values;
    const isSelected = components_id.includes(componentId);

    if (isSelected) {
      const filteredComponentsId = components_id.filter((cId) => cId !== componentId);

      return setFieldValue('components_id', filteredComponentsId);
    }

    setFieldValue('components_id', [...components_id, componentId]);
  };

  const isCheckboxChecked = (componentId) => values.components_id.includes(componentId);

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Field
            size='small'
            name='search_os'
            variant='outlined'
            label='Pesquisar componente'
            endAdornment={<SearchIcon />}
            component={Text}
            fullWidth
          />
        </Grid>
      </Grid>
      <Table
        headers={columns}
        loading={loadingComponents}
        maxHeight={400}
        emptyMessage='Nenhum registro encontrado.'
        striped
        disableNumeration
      >
        {components?.map((component) => (
          <TableRow key={component.id}>
            <TableCell size='small' align='center'>
              <Checkbox
                color='primary'
                onChange={() => handleCheckboxClick(component.id)}
                checked={isCheckboxChecked(component.id)}
              />
            </TableCell>
            <TableCell>{component.part_number}</TableCell>
            <TableCell>{component.description}</TableCell>
            <TableCell>
              <FormatMoney>{component.purchase_price}</FormatMoney>
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Field component={Checkbox} name='update_is_required' label='Atualização de Software' />
        </Grid>
      </Grid>
      <div className={classes.buttons} container>
        <Button onClick={handleSubmit} disabled={isSubmitting || !dirty || !isValid} size='small'>
          Confirmar Orçamento
        </Button>
      </div>
    </>
  );
};
