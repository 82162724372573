import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useMutation, useQuery } from '@apollo/client';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Button, Dialog, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { CHECK_PRODUCT, LIST_PRODUCTS_FOR_PICKING } from 'services/graphql/query/b2c';

export function ConferenceDialog({ isOpen, handleClose, headerId }) {
  if (!isOpen) {
    return <></>;
  }

  const { data, refetch } = useQuery(LIST_PRODUCTS_FOR_PICKING, {
    variables: { headerId },
    skip: !headerId,
    fetchPolicy: 'network-only',
    errorPolicy: 'ignore',
    onCompleted: (data) => {
      if (!data?.listProductsForPicking || data.listProductsForPicking.length === 0) {
        handleClose();
      }
    },
    onError: (error) => {
      handleClose();
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao reservar os produtos, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [checkProduct] = useMutation(CHECK_PRODUCT, {
    onCompleted: () => {
      toast.success('Confirmado o picking do produto');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao confirmar a conferência!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  async function handleValidationVoucher(values, { setSubmitting, resetForm }) {
    setSubmitting(true);
    try {
      const sinister = values?.sinister;

      if (sinister === data.listProductsForPicking.sinister) {
        checkProduct({ variables: { pickingId: data.listProductsForPicking.pickingId } });
        resetForm();
        refetch();
      } else {
        toast.error('Voucher divergente do produto selecionado');
      }
    } catch (error) {
      toast.error('Erro ao realizar a validação');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Dialog.Title onClose={handleClose}>Conferência</Dialog.Title>

      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Voucher:{' '}
              <text style={{ fontWeight: 'bold' }}>{data?.listProductsForPicking?.sinister}</text>
            </Typography>
            <Typography variant='subtitle2'></Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Local:{' '}
              <text style={{ fontWeight: 'bold' }}>
                {data?.listProductsForPicking?.stockAddress}
              </text>
            </Typography>
            <Typography variant='subtitle2'></Typography>
          </Grid>
        </Grid>

        <Formik initialValues={{ sinister: '' }} onSubmit={handleValidationVoucher}>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      name='sinister'
                      component={Text}
                      label='Validar Voucher'
                      size='small'
                      variant='outlined'
                      endAdornment={<QrCodeIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Button type='submit' fullWidth>
                      Validar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
