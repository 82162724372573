import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import CheckboxMUI from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { Text, Select, DatePicker, Tutorial, Dialog, Button, Checkbox } from 'components';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services/api';
import { mailObjectService } from 'services/mailObject';
import { pllService } from 'services/pll';
import { serviceOrderService } from 'services/serviceOrder';

import { useStyles } from './styles';
import { tutorialSteps } from './tutorial';

const warrantyTypeList = [
  {
    value: 'In Warranty',
    label: 'Em Garantia',
  },
  {
    value: 'Out of Warranty',
    label: 'Fora de Garantia',
  },
];

const insurante_Wty = [
  {
    value: 'DA',
    label: 'DA',
  },
  {
    value: 'GE',
    label: 'GE',
  },

  {
    value: 'LIKE NEW',
    label: 'LIKE NEW',
  },
];

const bounceList = [
  {
    value: true,
    label: 'Sim',
  },
  {
    value: false,
    label: 'Não',
  },
];

const SoInfo = () => {
  const [isWarningDialog, setIsWarningDialog] = useState(false);
  const [hasInHome, setHasInHome] = useState(false);
  const [hasPreBudget, setHasPreBudget] = useState(false);
  const [mailObjectCode, setMailObjectCode] = useState(null);
  const [mailObjectId, setMailObjectId] = useState(null);

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  useEffect(() => {
    const noProductsTypeFound =
      !additionalServicesProductsData?.length && !loadingAdditionalServiceProducts;

    if (noProductsTypeFound) {
      setIsWarningDialog(true);
    }
  }, [additionalServicesProductsData, loadingAdditionalServiceProducts]);

  const navigateToRegisterProduct = () => {
    browserHistory.push('/general-registration/register-model');
  };

  const handleWarningDialogClose = () => {
    setIsWarningDialog(false);
  };

  const form = useFormikContext();

  const { setValues, values, setSubmitting, initialValues, setFieldValue } = form;

  const [insuranceCompanies, , loadingInsuranceCompanies] = useQuery(
    mailObjectService.listInsuranceCompanies,
    [],
  );

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase().trim();
  };

  const disableServiceIfIsInhome = () => {
    if (hasInHome) {
      setHasInHome(false);
    } else {
      setHasInHome(true);
      const { customAlphabet } = require('nanoid');
      const nanoid = customAlphabet('1234567890', 15);

      const randomService = nanoid().toUpperCase();

      // setFieldValue('process_type_id', 4);
      setFieldValue('number', 'SVC' + randomService);
      // setFieldValue('status_description', 'INITIAL');
      // setFieldValue('object_in', 'Não possui');
      // setFieldValue('ean', 'Não possui');
      // setFieldValue('add_info', 'Não possui');
      // setFieldValue('imei', 'Não possui');
      // setFieldValue('imei2', 'Não possui');
      // setFieldValue('void_type', 'Não possui');
      // setFieldValue('warranty_type', 'Não possui');
      // setFieldValue('serial_number', 'Não possui');
    }
  };

  const disableIFisPreBudget = () => {
    if (hasPreBudget) {
      setHasPreBudget(false);
    } else {
      setHasPreBudget(true);
      const { customAlphabet } = require('nanoid');
      const nanoid = customAlphabet('1234567890', 15);
      const randomService = nanoid().toUpperCase();

      // setFieldValue('process_type_id', 4);
      setFieldValue('number', 'PSVC' + randomService);
      setFieldValue('status_description', 'INITIAL');
      setFieldValue('object_in', 'Não possui');
      setFieldValue('ean', 'Não possui');
      setFieldValue('add_info', 'Não possui');
      setFieldValue('imei', 'Não possui');
      setFieldValue('imei2', 'Não possui');
      setFieldValue('void_type', 'Não possui');
      setFieldValue('warranty_type', 'Não possui');
      setFieldValue('serial_number', 'Não possui');
    }
  };

  const [subInsuranceCompanies, , loadingSubInsuranceCompanies] = useQuery(
    () => pllService.listSubInsuranceCompanies(),
    [],
  );

  const [serviceOrderProcesses, , loadingServiceOrderProcess] = useQuery(
    () => serviceOrderService.listProcesses(),
    [],
  );

  const [serviceOrderAccessories, , loadingServiceOrderAccessories] = useQuery(
    () => serviceOrderService.listServiceOrderAccessories(),
    [],
  );

  const lastSearchedNumberRef = useRef('');
  const serviceOrderNumberRef = useRef(null);

  const [resales, , loadingResales] = useQuery(() => pllService.listResales(), []);

  const [colors, , loadingColors] = useQuery(() => serviceOrderService.listColorsProduct(), []);

  const classes = useStyles();

  const onSinisterObjectComplete = async (sinister) => {
    try {
      const { data } = await mailObjectService.getMailObjectBySinister(sinister);

      setMailObjectCode(data?.code);
      setMailObjectId(data?.id);

      setFieldValue('mail_object_code', data?.code);
      setFieldValue('object_in', data?.code);

      toast.success('Objeto validado com sucesso');
    } catch (err) {
      setMailObjectCode(null);
      setMailObjectId(null);
      toast.error(err?.response?.data?.message);
    }
  };

  const onTutorialFinish = () => {
    serviceOrderNumberRef.current.focus();
  };

  const insuranceCompaniesOptions = (insuranceCompanies || []).map((insuranceCompany) => ({
    value: insuranceCompany.id,
    label: insuranceCompany.name,
  }));

  const subInsuranceCompaniesOptions = (subInsuranceCompanies || []).map((subInsuranceCompany) => ({
    value: subInsuranceCompany.id,
    label: subInsuranceCompany.name,
  }));

  const resalesOptions = (resales || []).map((resale) => ({
    value: resale.id,
    label: resale.name,
  }));

  const colorsOptions = (colors || []).map((colors) => ({
    value: colors.description,
    label: colors.description,
  }));
  const serviceOrderProcessesOptions = (serviceOrderProcesses || []).map((serviceOrder) => ({
    value: serviceOrder.id,
    label: serviceOrder.description,
  }));

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.description,
    }),
  );

  const serviceOrderAccessoriesOptions = (serviceOrderAccessories || []).map((accessory) => ({
    value: accessory.id,
    label: accessory.description,
  }));

  return (
    <>
      <Tutorial name={'abertura'} steps={tutorialSteps} onFinish={onTutorialFinish} />

      <Dialog open={isWarningDialog} onClose={handleWarningDialogClose} maxWidth='xs'>
        <Dialog.Title onClose={handleWarningDialogClose}>Aviso</Dialog.Title>
        <Dialog.Content>
          <Typography>
            Não há nenhum <strong> Tipo de Produto</strong> cadastrado no sistema. Deseja cadastrar
            agora?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={handleWarningDialogClose} variant='text'>
            Fechar
          </Button>
          <Button onClick={navigateToRegisterProduct}>Cadastrar produto</Button>
        </Dialog.Actions>
      </Dialog>

      <Typography gutterBottom variant='h6'>
        Dados Gerais
      </Typography>
      <Grid item xs={12} lg={6}>
        <div style={{ display: 'flex', margin: 'auto', alignItems: 'center' }}>
          <CheckboxMUI color='primary' onClick={disableServiceIfIsInhome} />
          <Typography style={{ fontSize: '15px' }}>
            Selecione para Services de instalação inicial SEM VÍNCULO COM O CLIENTE
          </Typography>
        </div>
      </Grid>

      <Grid item xs={12} lg={6}>
        <div style={{ display: 'flex', margin: 'auto', alignItems: 'center' }}>
          <CheckboxMUI color='primary' onClick={disableIFisPreBudget} />
          <Typography style={{ fontSize: '15px' }}>Selecione para PRÉ ORÇAMENTO</Typography>
        </div>
      </Grid>
      <Grid container className={classes.formContainer} spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <Field name='number'>
            {({ form, field }) => (
              <Text
                id='service-order-number'
                size='small'
                variant='outlined'
                label='Número da Service'
                inputProps={{ maxLength: hasInHome ? 30 : 30 }}
                ref={serviceOrderNumberRef}
                onInput={toInputUppercase}
                form={form}
                field={field}
                disabled={hasInHome ? true : false}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='process_type_id'
            label='Tipo de processo'
            variant='outlined'
            options={serviceOrderProcessesOptions}
            loading={loadingServiceOrderProcess}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='product_id'
            label='Tipo de Produto'
            variant='outlined'
            options={additionalServiceProductOptions}
            loading={loadingServiceOrderProcess}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='object_in'
            variant='outlined'
            label='Objeto IN'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='service_part_number'
            variant='outlined'
            label='Part-Number'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='bounce'
            variant='outlined'
            label='Bounce'
            component={Select}
            options={bounceList}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field size='small' name='ean' variant='outlined' label='Código EAN' component={Text} />
        </Grid>
        <Grid item xs={12} lg={2} style={{ fontSize: 15 }}>
          <Field
            component={Checkbox}
            name='checkHasNF'
            label='Possuí Chave NF'
            disabled={hasInHome}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='nf_key'
            variant='outlined'
            label='Chave NF'
            component={Text}
            disabled={!values?.checkHasNF}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='add_info'
            variant='outlined'
            label='Informação Adicional'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            size='small'
            name='warranty_type'
            label='Tipo de garantia'
            variant='outlined'
            options={warrantyTypeList}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} lg={2} style={{ fontSize: 15 }}>
          <Field
            component={Checkbox}
            name='has_vinc_service'
            label='Possuí vínculo com service'
            disabled={hasInHome}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='vinc_service'
            variant='outlined'
            label='Víncular service'
            component={Text}
            disabled={!values?.has_vinc_service}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='franchise'
            label='Franquia'
            variant='outlined'
            mask='money'
            component={Text}
            //  disabled={values?.process_type_id < 1 || hasInHome ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='sinister'
            label='Sinistro'
            variant='outlined'
            component={Text}
            onBlur={(e) => onSinisterObjectComplete(e.target.value)}
            // disabled={values?.process_type_id < 1 || hasInHome ? true : false}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Field
            size='small'
            name='insurance_wty'
            label='Garantia de seguro'
            variant='outlined'
            options={insurante_Wty}
            component={Select}
          />
        </Grid>
      </Grid>

      <Typography gutterBottom variant='h6'>
        Dados do aparelho
      </Typography>

      <Grid className={classes.formContainer} container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <Field size='small' name='model' variant='outlined' label='Modelo' component={Text} />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='serial_number'
            variant='outlined'
            label='Serial'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            fullWidth
            id='imei'
            name='imei'
            variant='outlined'
            label='IMEI'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            fullWidth
            id='imei2'
            name='imei2'
            variant='outlined'
            label='IMEI 2'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='color_product'
            label='Cor do produto'
            variant='outlined'
            options={colorsOptions}
            loading={loadingColors}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={6}>
          <Field
            size='small'
            name='device_defect'
            label='Defeito do dispositivo'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Field
            size='small'
            name='fabrication_date'
            variant='inline'
            inputVariant='outlined'
            label='Data Fabricação'
            component={DatePicker}
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <Field
            multiple
            name='accessories_id'
            label='Acessórios'
            emptyOption='Limpar'
            size='small'
            fullWidth
            options={serviceOrderAccessoriesOptions}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Field
            size='small'
            name='device_accessory'
            label='Acessório do Dispositivo'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Field
            size='small'
            name='special_comment'
            variant='outlined'
            label='Observações'
            multiline
            rows={4}
            component={Text}
          />
        </Grid>
      </Grid>

      <Typography gutterBottom variant='h6'>
        Dados da Compra
      </Typography>

      <Grid className={classes.formContainer} container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Field
            size='small'
            name='purchase_invoice'
            variant='outlined'
            label='Nota Fiscal Compra'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Field
            size='small'
            name='purchase_date'
            variant='inline'
            inputVariant='outlined'
            label='Data Compra'
            component={DatePicker}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <Field
            size='small'
            name='create_date'
            variant='inline'
            inputVariant='outlined'
            label='Data de criação'
            component={DatePicker}
          />
        </Grid>
      </Grid>

      <Typography gutterBottom variant='h6'>
        Recebimento
      </Typography>

      <Grid className={classes.formContainer} container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Field
            size='small'
            fullWidth
            name='mail_object_code'
            variant='outlined'
            label='Objeto Entrada'
            component={Text}
            value={mailObjectCode}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>{mailObjectCode ? '' : ''}</InputAdornment>
              ),
            }}
            inputProps={{ maxLength: 13 }}
            disabled
          />
        </Grid>

        {/* <Grid item xs={12} lg={3}>
          <Field
            size='small'
            fullWidth
            name='mail_object_id'
            variant='outlined'
            label='Validação do objeto de entrada'
            value={mailObjectId}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>{mailObjectId ? '' : ''}</InputAdornment>
              ),
            }}
            component={Text}
            disabled
          />
        </Grid> */}

        <>
          {!values.process_type_id > 2 ? (
            <Grid item xs={12} lg={3}>
              <Field
                size='small'
                fullWidth
                name='pll_bpo'
                variant='outlined'
                label='Seguradoras'
                loading={loadingInsuranceCompanies}
                options={insuranceCompaniesOptions}
                component={Select}
              />
            </Grid>
          ) : (
            <Grid item xs={12} lg={3}>
              <Field
                size='small'
                fullWidth
                name='sub_bpo_id'
                variant='outlined'
                label='Sub Seguradoras'
                loading={loadingSubInsuranceCompanies}
                options={subInsuranceCompaniesOptions}
                component={Select}
              />
            </Grid>
          )}
          {/* <Grid item xs={12} lg={3}>
            <Field
              size='small'
              fullWidth
              name='resale_id'
              variant='outlined'
              label='Revendas'
              loading={loadingResales}
              options={resalesOptions}
              component={Select}
            />
          </Grid> */}
        </>
      </Grid>
    </>
  );
};

export default SoInfo;
