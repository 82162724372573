import React, { useState, useEffect, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Table, Text, FormatDate, DatePicker } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';

import { useStyles } from './styles';

const columns = [
  { label: 'Processo' },
  { label: 'Informação adicional' },
  { label: 'Service' },
  { label: 'Status' },
  { label: 'Data Orçamento' },
  { label: 'Nome Cliente' },
  { label: 'Técnico' },
  { label: 'Valor Total' },
  { label: 'Valor Negociação' },
];

export const TableOverview = () => {
  TableOverview.onload = async (event) => {
    () => setInputData(props.values.output_data);
  };
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const today = new Date();

  const [budgetsDataDate, , loadingBudgetsDate, refetchBudgetsDataDate] = useQuery(
    () => budgetService.listPreBudgetsAnalyzedFromTechnicianInPcpDateAll(inputData, outputData),
    [inputData, outputData],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const filteredComponents = useMemo(() => {
    const searchLower = search.toLowerCase();
    return budgetsDataDate?.filter((budget) => {
      return (
        (budget.description && budget.description.toLowerCase().includes(searchLower)) ||
        (budget.insurance && budget.insurance.toLowerCase().includes(searchLower)) ||
        (budget.description && budget.description.toLowerCase().includes(searchLower)) ||
        (budget.number && budget.number.toLowerCase().includes(searchLower)) ||
        (budget?.status && budget?.status.toLowerCase().includes(searchLower))
      );
    });
  }, [budgetsDataDate, search]);

  return (
    <>
      <Formik
        initialValues={{
          input_data: new Date(new Date().setDate(today.getDate() - 30)),
          output_data: new Date(),
        }}
      >
        {(props) => (
          <Form>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  variant='outlined'
                  name='search_os'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  label='Pesquisar'
                  component={Text}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                  OnChange={() => setInputData(props.values.input_data)}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                  OnChange={() => setInputData(props.values.output_data)}
                />
              </Grid>{' '}
              <Grid item xs={12} lg={12}>
                <Table
                  headers={columns}
                  loading={loadingBudgetsDate}
                  stripped
                  maxHeight
                  emptyMessage='Nenhum registro encontrado.'
                  page={page}
                  data={filteredComponents}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration
                >
                  {useEffect(() => {
                    setInputData(props.values.input_data);
                    setOutputData(props.values.output_data);

                    refetchBudgetsDataDate();
                  }, [props.values])}

                  {filteredComponents?.map((budget) => (
                    <TableRow key={budget.id}>
                      <TableCell>{budget.description}</TableCell>
                      <TableCell>{budget.insurance}</TableCell>
                      <TableCell>{budget.number}</TableCell>
                      <TableCell className={`${classes?.statusCell} status-${budget?.status_id}`}>
                        <Typography className={classes?.statusDescription}>
                          {console.log('Budget STatus: ', budget)}
                          {budget?.status}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <FormatDate>{budget.created_at}</FormatDate>
                      </TableCell>
                      <TableCell>{budget.client}</TableCell>
                      <TableCell>{budget.name}</TableCell>
                      <TableCell>
                        <FormatMoney>{budget.final_budget_price}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <FormatMoney>{budget?.negociation_price}</FormatMoney>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
