import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog, Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { mailServices } from 'services/mail';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  reason: yup.string().required(),
});

export const AlterModelDialog = ({ open, handleClose, product = {}, reloadProducts }) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await mailServices.alterObjectBlockingReason(product.id, values.reason);

      reloadProducts();
      toast.success('Nome alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível o nome do produto.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='xs' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Detalhes do item: {product?.reason}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ reason: product.reason }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='reason'
                      label='Alterar nome'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
