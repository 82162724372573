import React from 'react';

import { Product } from './Product';
import { ServiceOrder } from './ServiceOrder';
import { Shipping } from './Shipping';

export const OsData = () => {
  return (
    <>
      <Product />
      <ServiceOrder />
      <Shipping />
    </>
  );
};
