import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Table } from 'components';
import { Mask } from 'components/Mask';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

import { useStyles } from './styles';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Quantidade', align: 'center' },
  { label: 'CPF/CNPJ do Cliente', align: 'center' },
  { label: 'Responsável pela venda', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export const BudgetStatus = () => {
  const classes = useStyles();
  const [dialogProps, setDialogProps] = useState({});
  const [selectedOrdering, setSelectedOrdering] = useState({});
  const [confirmDialog, setConfirmDialog] = useBoolean();

  const [orders, setOrders] = useState([]);

  const [ordersData, , loadingOrdersData, refetchOrdersData] = useQuery(
    componentService.listOrder,
    [],
  );

  console.log('orders', ordersData);

  useEffect(() => {
    console.log(ordersData);
    setOrders(ordersData);
  }, [ordersData]);

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} md={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            emptyMessage='Nenhum registro encontrado.'
            headers={columns}
            loading={loadingOrdersData}
            striped
            maxHeight={350}
            disableNumeration
          >
            {orders?.map((order) => (
              <TableRow key={order?.id}>
                <TableCell>{order?.part_number}</TableCell>
                <TableCell>{order?.legenda}</TableCell>
                <TableCell align='center'>{order?.quantity}</TableCell>
                <TableCell align='center'>
                  <Mask type='document'>{order?.cgc}</Mask>
                </TableCell>
                <TableCell align='center'>{order?.name}</TableCell>
                <TableCell align='center'>{order?.situacao}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
