import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Text, Table, FormatMoney } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api, componentService } from 'services';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';

import { AlterAccessoryPriceDialog } from './AlterAccessoryPriceDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Valor (un)' },
  { label: 'Valor Atacado' },
  { label: <VisibilityIcon />, align: 'center' },
];

const rows = [];

export const RegisterAccessoryPrice = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [importing, setImporting] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [accessory, setAccessory] = useState({});
  const [accessories, setAccessories] = useState([]);
  const [informationDialog, setInformationDialog] = useBoolean();

  const [accessoriesData, , loadingComponents, refetchComponents] = useQuery(
    componentService.listComponentsCashier,
    [],
  );

  useEffect(() => {
    setAccessories(accessoriesData);
  }, [accessoriesData]);

  const classes = useStyles();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setAccessory(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      await api.post('/components/accessory/price', values);

      toast.success('Preço de acessório cadastrado com sucesso');
      refetchComponents();
    } catch (error) {
      toast.error(error.response.data?.message || 'Erro ao cadastrar valor.');
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  const handleDownloadLayoutSalesPrice = async () => {
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(
      [
        {
          S: 'Part-Number',
          h: 'Preço Unitário (exemplo): 23,98 (obs: não pode ser no formato monetário: R$23,98)',
          n: 'Preço Atacado (exemplo): 3,23 (obs: não pode ser no formato monetário: R$3,23)',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'Layout_preco_acessorio.xlsx');
  };

  const inputFileRef = useRef(null);

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xslx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const dadosFile = arr.map(function (obj) {
          if (obj.split(';')[0] == null || obj.split(';')[0] == undefined) {
            return false;
          } else {
            const dados = {
              part_number: obj.split(';')[0],
              component_accessory_price:
                obj.split(';')[1] != null || obj.split(';')[1] != undefined
                  ? parseFloat(obj.split(';')[1].replace(',', '.')).toFixed(2)
                  : null,
              component_accessory_price_wholesale:
                obj.split(';')[2] != null || obj.split(';')[2] != undefined
                  ? parseFloat(obj.split(';')[2].replace(',', '.')).toFixed(2)
                  : null,
            };

            return dados;
          }
        });

        const createDatasByFile = await componentService.CreateAccessoryPricesFile({
          accessoryPrices: dadosFile,
        });

        if (createDatasByFile.data.length != 0) {
          toast.success(
            `${createDatasByFile.data.length} preço de acessórios cadastrados com sucesso.`,
          );
          refetchComponents();
          //setImporting(false);
        } else {
          toast.success(`Todos os ${arr.length - 1} preços de acessórios foram atualizados.`);
          refetchComponents();
        }
      };
      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />
      <PageTitle>Preço de acessório</PageTitle>
      <Formik
        initialValues={{
          part_number: '',
          component_accessory_price: 0,
          component_accessory_price_wholesale: 0,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='part_number'
                    label='Part Number'
                    variant='outlined'
                    size='small'
                    component={Text}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='component_accessory_price'
                    label='Preço (Un)'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='component_accessory_price_wholesale'
                    label='Preço Atacado'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth onClick={handleImportClick} type=''>
                    IMPORTAR PREÇOS
                  </Button>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth variant='outlined' onClick={handleDownloadLayoutSalesPrice}>
                    BAIXAR LAYOUT
                  </Button>
                </Grid>
                {importing ? (
                  <Grid xs={6} lg={12} className={classes.loading}>
                    <Box sx={{ width: '90%' }}>
                      <LinearProgress color='secondary' />
                      <Grid className={classes.gridImages} xs={6} lg={12}>
                        {' '}
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  <Grid className={classes.gridImages} xs={6} lg={12}>
                    {' '}
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingComponents}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Nenhum registro encontrado.'
                    // onActionsClick={handleOpenInformation}
                    data={accessoriesData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {accessoriesData?.map((salesComponent) => (
                      <TableRow key={salesComponent.id}>
                        {console.log('salesComponent: ', salesComponent)}
                        <TableCell>{salesComponent.part_number}</TableCell>
                        <TableCell>{salesComponent.description}</TableCell>
                        <TableCell>
                          <FormatMoney>
                            {salesComponent.component_acessory_price ?? null}
                          </FormatMoney>
                        </TableCell>
                        <TableCell>
                          {salesComponent.component_acessory_price_wholesale != null ? (
                            <FormatMoney>
                              {salesComponent.component_acessory_price_wholesale}
                            </FormatMoney>
                          ) : (
                            <>-</>
                          )}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, salesComponent)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterAccessoryPriceDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  component={accessory}
                  reloadComponents={refetchComponents}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <AlterAccessoryPriceDialog open={informationDialog} handleClose={handleCloseInformation} />
    </>
  );
};
