import { addPnToCart, removePnFromCart, updatePnAmount, cleanCart } from './reducerFunctions';

export const pnReducer = (state, action) => {
  switch (action.type) {
    case '@ADD_PN_TO_CART':
      return addPnToCart(state, action);

    case '@REMOVE_PN_FROM_CART':
      return removePnFromCart(state, action);

    case '@UPDATE_PN_AMOUNT':
      return updatePnAmount(state, action);

    case '@CLEAN_CART':
      return cleanCart(state, action);

    default:
      break;
  }
};
