export const addComponentToCartAction = (component, dispatch) => {
  dispatch({ type: '@ADD_COMPONENT_TO_CART', component });
};

export const removeComponentFromCartAction = (component, dispatch) => {
  dispatch({ type: '@REMOVE_COMPONENT_FROM_CART', component });
};

export const updateAmountComponentAction = (amountAndComponentId, dispatch) => {
  dispatch({ type: '@UPDATE_COMPONENT_AMOUNT', amountAndComponentId });
};

export const addServiceToCartAction = (service, dispatch) => {
  dispatch({ type: '@ADD_SERVICE_TO_CART', service });
};

export const removeServiceFromCartAction = (service, dispatch) => {
  dispatch({ type: '@REMOVE_SERVICE_FROM_CART', service });
};

export const updateAmountServiceAction = (amountAndServiceId, dispatch) => {
  dispatch({ type: '@UPDATE_SERVICE_AMOUNT', amountAndServiceId });
};

export const cleanCartAction = (dispatch) => {
  dispatch({ type: '@CLEAN_CART', cartCleaned: [] });
};
