import React, { useContext } from 'react';
import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Text } from 'components/Form/Text';
import { Table } from 'components/Table';

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

import { StockRequisitionContext } from '../../index';
import { removeFromCartAction, updateAmountAccessoryAction } from '../store/actions';
import { useStyles } from './styles';

const columns = [
  { label: 'PART-NUMBER', align: 'center' },
  { label: 'DESCRIÇÃO', align: 'center' },
  { label: 'QUANTIDADE', align: 'center' },
  { label: '', align: 'center' },
];

export const CartWithItems = () => {
  const classes = useStyles();

  const { state, dispatch } = useContext(StockRequisitionContext);

  return (
    <>
      <Grid
        container
        alignContent='center'
        className={classes.gridContainer}
        justifyContent='center'
      >
        <Grid item xs={12}>
          <Table
            maxHeight={400}
            emptyMessage='Nenhum acessório no carrinho.'
            striped
            disableNumeration
            headers={columns}
          >
            {state.map((accessory) => (
              <TableRow key={accessory?.id}>
                <TableCell align='center'>{accessory?.part_number}</TableCell>
                <TableCell align='center'>{accessory?.description}</TableCell>
                <TableCell align='center'>
                  <Grid container direction='row'>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() =>
                          updateAmountAccessoryAction(
                            { amount: accessory.amount - 1, accessory_id: accessory.id },
                            dispatch,
                          )
                        }
                      >
                        <FaMinusCircle className={classes.minusIcon} />
                      </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                      <Text
                        number='number'
                        variant='outlined'
                        value={accessory.amount}
                        onChange={(e) =>
                          updateAmountAccessoryAction(
                            {
                              amount: e.target.value == 0 ? 1 : Number(e.target.value),
                              accessory_id: accessory.id,
                            },
                            dispatch,
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() =>
                          updateAmountAccessoryAction(
                            { amount: accessory.amount + 1, accessory_id: accessory.id },
                            dispatch,
                          )
                        }
                      >
                        <FaPlusCircle className={classes.plusIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => removeFromCartAction(accessory, dispatch)}>
                    <FaTrash size='18' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
