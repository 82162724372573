import { gql } from '@apollo/client';

export const CREATE_STOCK_ADDRESS = gql`
  mutation CreateStockAddress($data: CreateStockLocationInput!) {
    createStockAddress(data: $data)
  }
`;

export const CREATE_STOCK_ADDRESS_BY_FILE = gql`
  mutation CreateStockAddressByFile($data: [CreateStockLocationByFileInput!]!) {
    createStockAddressByFile(data: $data)
  }
`;

export const LIST_STOCK_LOCATIONS = gql`
  query {
    listStockAddress {
      id
      description
      maxAmount
      allocatedAmount
      street {
        id
        description
        __typename @skip(if: true)
      }
      block {
        id
        description
        grade {
          id
          description
          __typename @skip(if: true)
        }
        __typename @skip(if: true)
      }
      floor {
        id
        description
        __typename @skip(if: true)
      }
      location {
        id
        description
        __typename @skip(if: true)
      }
      __typename @skip(if: true)
    }
  }
`;

export const LIST_STOCK_ADDRESS_BY_GRADE = gql`
  query ListStockAddressByGrade($sinister: String!) {
    listStockAddressByGrade(sinister: $sinister) {
      id
      description
      __typename @skip(if: true)
    }
  }
`;

export const GET_BEST_STOCK_ADDRESS_BY_GRADE = gql`
  query GetBestStockAddressByGrade($sinister: String!) {
    getBestStockAddressByGrade(sinister: $sinister) {
      description
      id
    }
  }
`;
