import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/styles/makeStyles';

import { Button, Text, FormatDate, Table, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';

import { validationSchema, initialValues } from './formik';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: '5px',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export const RegisterSigepContract = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const method_Options = [
    {
      value: 'SEDEX',
      label: 'SEDEX',
    },
    {
      value: 'PAC',
      label: 'PAC',
    },
  ];

  const [mailObjectsData, , loadingMailObjects] = useQuery(
    () => api.get('/mail/mail-contracts'),
    [],
  );

  const [objectTypes, , loadingTypeObjects] = useQuery(() => api.get('/mail/mail-contracts'), []);

  const [insuranceCompanies, , loadingInsuranceCompanies] = useQuery(
    () => api.get('/mail-objects/insurance-companies'),
    [],
  );

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/mail/create-mail-contracts', values);

      setMailObjects((prevState) => [...prevState, data]);

      toast.success('Contrato cadastrado com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    { label: 'Contrato' },
    { label: 'Informação Adicional' },
    { label: 'Método' },
    { label: 'Data de Cadastro' },
  ];

  return (
    <>
      <PageTitle>Registro de Contrato SIGEP</PageTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.formContainer}>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Informação Adicional'
                  name='additional_information'
                  component={Text}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Contrato'
                  name='contract_number'
                  component={Text}
                  size='small'
                  mask='number'
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Método'
                  name='contract_method'
                  component={Select}
                  options={method_Options}
                  size='small'
                  mask='number'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button color='primary' type='submit' fullWidth disabled={!dirty || !isValid}>
                  Cadastrar
                </Button>
              </Grid>
              {/* <Grid item xs={6} md={2}>
                <Button color='primary' variant='text' fullWidth>
                  Limpar
                </Button>
              </Grid> */}
            </Grid>
          </Form>
        )}
      </Formik>

      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar contrato'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            title='Lista de objetos'
            headers={columns}
            loading={loadingMailObjects}
            // maxHeight={300}
            emptyMessage='Nenhum registro encontrado.'
            striped
          >
            {mailObjectsData?.map((object) => (
              <TableRow key={object.id}>
                <TableCell>{object.contract_number}</TableCell>
                <TableCell>{object.additional_information}</TableCell>
                <TableCell>{object.contract_method}</TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object.created_at}</FormatDate>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
