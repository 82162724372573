import React from 'react';

import Grid from '@material-ui/core/Grid';

import { BackNavButton, Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { TableTabs } from './Tabs';

export const ReportOs = () => {
  return (
    <>
      <PageTitle dev />
      <BackNavButton />

      <Spacer axis='vertical' size={16} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TableTabs />
        </Grid>
      </Grid>
    </>
  );
};
