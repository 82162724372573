import React from 'react';
import { useRouteMatch } from 'react-router';

import { B2COrderReport } from 'pages/Private/WarehouseReport/B2COrderReport';
import { ClosingAssurant } from 'pages/Private/WarehouseReport/ClosingAssurant';
import { InOutReport } from 'pages/Private/WarehouseReport/InOutReport';
import { ReceivingReport } from 'pages/Private/WarehouseReport/ReceivingReport';

import { Route } from '../Route';

export const WarehouseReport = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/closing-assurant`} component={ClosingAssurant} />
      <Route isPrivate path={`${path}/receiving`} component={ReceivingReport} />
      <Route isPrivate path={`${path}/in-out`} component={InOutReport} />
      <Route isPrivate path={`${path}/b2c-order-report`} component={B2COrderReport} />
    </>
  );
};
