import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, FormatMoney, Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services/budget';

import { LinkInformationDialog } from './LinkInformationDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Service', width: 250 },
  { label: 'Valor orçamento' },
  { label: 'Total pago' },
  { label: 'Falta pagar' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const TableSafe2Pay = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1);
  const [search, setSearch] = useState('');
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [budget, setBudget] = useState({});

  const [budgets, , loadingBudgets, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsByNotNegociationId(startIndex, rowsPerPage, search),
    [startIndex, rowsPerPage, search],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setStartIndex(1);
    setPage(0);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBudget(item);
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setStartIndex(1);
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={columns}
            striped
            loading={loadingBudgets}
            emptyMessage='Nenhum registro encontrado.'
            data={budgets?.data}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
          >
            {budgets?.data?.map((budget) => (
              <TableRow key={budget?.id}>
                <TableCell className={`${classes?.statusCell} status-${budget?.status.id}`}>
                  <Typography className={classes?.serviceNumber}>
                    {budget?.service_order?.number}
                  </Typography>
                  <Typography className={classes?.statusDescription}>
                    {budget?.status?.description}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormatMoney>
                    {budget?.negociation_price
                      ? budget?.negociation_price
                      : budget?.final_budget_price}
                  </FormatMoney>
                </TableCell>
                <TableCell>
                  <FormatMoney>0</FormatMoney>
                </TableCell>
                <TableCell>
                  <FormatMoney>0</FormatMoney>
                </TableCell>
                <TableCell align='center'>
                  <IconButton color='primary' onClick={(e) => handleOpenInformation(e, budget)}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50, 100, 200]}
            component='div'
            count={budgets?.totalCount}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Linhas por página:'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : ''}`
            }
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
        <LinkInformationDialog
          open={isDetailsOpen}
          handleClose={handleCloseInformation}
          budget={budget}
          reloadStatuses={refetchBudgets}
        />
      </Grid>
      <Typography variant='overline' className={classes.legend} gutterBottom>
        Legenda
      </Typography>
      <Grid container spacing={2} justify='space-between'>
        <Grid className={classes.gridLegend} item xs={12} lg={3}>
          <div className={classes.divLegend}>
            <section className={classes.sectionContext}>
              <div className={classes.legend1} />
              <p className={classes.infoLegend}> - Processamento</p>
            </section>

            <section className={classes.sectionContext}>
              <div className={classes.legend2} />
              <p className={classes.infoLegend}> - Autorizado</p>
            </section>

            <section className={classes.sectionContext}>
              <div className={classes.legend3} />
              <p className={classes.infoLegend}> - Disponível</p>
            </section>
          </div>
        </Grid>
        <Grid className={classes.gridLegend} item xs={12} lg={3}>
          <div className={classes.divLegend}>
            <section className={classes.sectionContext}>
              <div className={classes.legend4} />
              <p className={classes.infoLegend}> - Baixado</p>
            </section>

            <section className={classes.sectionContext}>
              <div className={classes.legend5} />
              <p className={classes.infoLegend}> - Liberado</p>
            </section>
            <section className={classes.sectionContext}>
              <div className={classes.legend6} />
              <p className={classes.infoLegend}> - Chargeback</p>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className={classes.divLegend}>
            <section className={classes.sectionContext}>
              <div className={classes.legend7} />
              <p className={classes.infoLegend}> - Pendente</p>
            </section>
            <section className={classes.sectionContext}>
              <div className={classes.legend8} />
              <p className={classes.infoLegend}> - Em disputa</p>
            </section>

            <section className={classes.sectionContext}>
              <div className={classes.legend9} />
              <p className={classes.infoLegend}> - Devolvido</p>
            </section>
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className={classes.divLegend}>
            <section className={classes.sectionContext}>
              <div className={classes.legend10} />
              <p className={classes.infoLegend}> - Recusado</p>
            </section>
            <section className={classes.sectionContext}>
              <div className={classes.legend11} />
              <p className={classes.infoLegend}> - Aguardando negociação</p>
            </section>
            <section className={classes.sectionContext}>
              <div className={classes.legend12} />
              <p className={classes.infoLegend}> - Em cancelamento</p>
            </section>
            <section className={classes.sectionContext}>
              <div className={classes.legend13} />
              <p className={classes.infoLegend}> - Cancelado</p>
            </section>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
