import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { TablePagination } from '@mui/material';
import Chip from '@mui/material/Chip';
import { FormatDate, Table } from 'components';
import { LIST_COSMETIC_SCREENING_RESPONSES } from 'services/graphql/query/cosmetic-screening';

const columns = [
  { label: 'Pergunta', align: 'center' },
  { label: 'Grade', align: 'center' },
  { label: 'Data', align: 'center' },
  { label: 'Usuário', align: 'center' },
];

function getGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: 'LIKE NEW',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

export function CosmeticScreeningDetail({ sinister }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const cosmeticResponses = useQuery(LIST_COSMETIC_SCREENING_RESPONSES, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Respostas Triagem Cosmética:
      </Typography>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={cosmeticResponses?.loading}
        disableNumeration
      >
        {cosmeticResponses?.data?.listCosmeticScreeningReponses?.responses?.map((item) => {
          const grade = getGradeColor(item?.gradeId);
          return (
            <TableRow key={item?.id}>
              <TableCell align='center'>{item?.question}</TableCell>
              <TableCell align='center'>
                <Chip
                  label={grade.label}
                  style={{
                    backgroundColor: grade.color,
                    color: grade.textColor,
                    border: grade.border || 'none',
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <FormatDate>{item?.createdAt}</FormatDate>
              </TableCell>
              <TableCell align='center'>{item?.user?.name}</TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={cosmeticResponses?.data?.listCosmeticScreeningReponses?.totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
