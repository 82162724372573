import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Text, FormatMoney } from 'components';
import { Field, useFormikContext } from 'formik';
import * as yup from 'yup';

import { useStyles } from './styles';
import { TableRegister } from './TableRegister';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  part_number: yup.string().required(),
  quantity: yup.number().required().min(1),
  purchase_price: yup.number().required().min(0.01),
});

const InvoiceItem = () => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const [errors, setErrors] = useState({});

  const resetItemForm = () => {
    setFieldValue('description', '');
    setFieldValue('part_number', '');
    setFieldValue('quantity', 1);
    setFieldValue('purchase_price', 0);
  };

  const sendItemHandler = async (values) => {
    try {
      await validationSchema.validate(values, { abortEarly: false });

      const item = {
        description: values.description,
        part_number: values.part_number,
        quantity: values.quantity,
        purchase_price: values.purchase_price,
        ncm: values.ncm,
        cprod: values.cprod,
        cean: values.cean,
        xprod: values.xprod,
        cest: values.cest,
        extipi: values.extipi,
        cfop: values.cfop,
        ucom: values.ucom,
        qcom: values.qcom,
        vuncom: values.vuncom,
        vprod: values.vprod,
        ceantrib: values.ceantrib,
        utrib: values.utrib,
        qtrib: values.qtrib,
        vuntrib: values.vuntrib,
        indtot: values.indtot,
        xped: values.xped,
        nitemped: values.nitemped,
        icms_orig: values.icms10.orig,
        icms_cst: values.icms10.cst,
        icms_modbc: values.icms10.modbc,
        icms_vbc: values.icms10.vbc,
        icms_picms: values.icms10.picms,
        icms_vicms: values.icms10.vicms,
        icms_modbcst: values.icms10.modbcst,
        icms_vbcst: values.icms10.vbcst,
        icms_picmsst: values.icms10.picmsst,
        icms_vicmsst: values.icms10.vicmsst,
        ipi_cenq: values.ipi.cenq,
        ipitrib_cst: values.ipitrib.cst,
        ipitrib_vbc: values.ipitrib.vbc,
        ipitrib_pipi: values.ipitrib.pipi,
        ipitrib_vipi: values.ipitrib.vipi,
        pisnt_cst: values.pisnt.cst,
        confinsnt_cst: values.confinst.cst,
      };

      const prevItems = values.invoice_items;

      setFieldValue('invoice_items', [...prevItems, item]);
      setErrors({});
      resetItemForm();
    } catch (err) {
      const errors = err.inner.reduce((acc, e) => {
        const fieldName = e.params.path;

        return {
          ...acc,
          [fieldName]: e.message,
        };
      }, {});
      setErrors(errors);
    }
  };

  const totalPrice = (items) => items.reduce((acc, item) => (acc += item.purchase_price), 0);
  const invoiceTotalPrice = totalPrice(values.invoice_items);

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Itens da Nota
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={3}>
          <Field
            size='small'
            name='part_number'
            variant='outlined'
            label='Part Number'
            component={Text}
            errorMessageProp={errors.part_number}
            fullWidth
            inputProps={{ maxLength: 15 }}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Field
            size='small'
            name='quantity'
            variant='outlined'
            type='number'
            label='Quantidade'
            component={Text}
            errorMessageProp={errors.quantity}
            inputProps={{
              min: 1,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Field
            size='small'
            name='purchase_price'
            mask='money'
            variant='outlined'
            label='Valor'
            component={Text}
            errorMessageProp={errors.purchase_price}
            inputProps={{
              min: 1,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Field
            size='small'
            fullWidth
            name='description'
            variant='outlined'
            label='Descrição'
            component={Text}
            errorMessageProp={errors.description}
          />
        </Grid>
        <Grid item xs={6} lg={2}>
          <Button onClick={resetItemForm} variant='text' color='primary' fullWidth>
            Limpar
          </Button>
        </Grid>
        <Grid item xs={6} lg={2}>
          <Button color='primary' fullWidth onClick={() => sendItemHandler(values)}>
            Adicionar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableRegister />
        </Grid>
        <Grid item className={classes.totalPrice}>
          <Typography>
            Total da fatura: <FormatMoney>{invoiceTotalPrice}</FormatMoney>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default InvoiceItem;
