import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Text, Spacer } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

import { useStyles } from './styles';

export function ReprintTagComponents() {
  const classes = useStyles();

  const handleDownloadTagsClick = async (invoice_number) => {
    const { data } = await invoiceService.downloadComponentsTagsByInvoiceNumber(invoice_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'Etiquetas.pdf');
  };

  const onSubmitHandlerPN = (values, { setSubmitting, resetForm }) => {
    handleDownloadTagsClick(values.invoice_number)
      .then((response) => {
        setSubmitting(true);
        console.log('Response: ', response);

        resetForm();
        toast.success('Reimpressão realizada com sucesso.');
      })
      .catch((err) => {
        setSubmitting(false);
        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc.decode(arr);

        const parsedResult = JSON.parse(result);

        toast.error(parsedResult?.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      <PageTitle>Reimpressão de etiquetas de componentes</PageTitle>
      <Formik initialValues={{ invoice_number: '' }} onSubmit={onSubmitHandlerPN}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Spacer axis='vertical' size={32} />

            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Número da nota'
                  name='invoice_number'
                  variant='outlined'
                  size='small'
                  component={Text}
                  disabled={props.isSubmitting}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button type='submit' fullWidth disabled={props.isSubmitting}>
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <img className={classes.imgContainer} src={checkKitImg} />
    </>
  );
}
