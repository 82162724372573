import { toast } from 'react-toastify';

export const addToCart = (state, action) => {
  const { accessory } = action;
  const cart = [...state];

  if (!accessory.component_acessory_price) {
    toast.error('acessório sem preço de venda cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...accessory, amount: 1, sub_total: accessory.component_acessory_price }];
  }

  console.log(accessory);

  const hasAccessoryIndexInCart = state.findIndex((a) => {
    return a.id == action.accessory.id;
  });

  let accessoryAdded = [];

  if (hasAccessoryIndexInCart >= 0) {
    accessoryAdded = cart[hasAccessoryIndexInCart];

    if (accessoryAdded.quantity < accessoryAdded.amount + 1) {
      toast.error(
        `Não é possivel adicionar mais do item ${accessoryAdded.description}, limite do estoque atingido`,
      );

      return cart;
    }

    accessoryAdded.amount += 1;
    accessoryAdded.sub_total = accessoryAdded.amount * accessoryAdded.component_acessory_price;

    return cart;
  }

  return [...cart, { ...accessory, amount: 1, sub_total: accessory.component_acessory_price }];
};

export const removeFromCart = (state, action) => {
  const cart = [...state];
  const { accessory } = action;

  const hasAccessoryIndexInCart = state.findIndex((a) => {
    return a.id === accessory.id;
  });

  if (hasAccessoryIndexInCart >= 0) {
    cart.splice(hasAccessoryIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updateAmount = (state, action) => {
  const cart = [...state];
  const { amountAndAcessoryId } = action;

  const accessoryIndex = cart.findIndex((a) => {
    return a.id === amountAndAcessoryId.accessory_id;
  });

  if (cart[accessoryIndex].quantity < amountAndAcessoryId.amount) {
    console.log(amountAndAcessoryId.amount);
    toast.error(
      `Não é possivel adicionar mais do item ${cart[accessoryIndex].description}, limite do estoque atingido`,
    );
    return cart;
  }

  cart[accessoryIndex].amount = amountAndAcessoryId.amount;

  cart[accessoryIndex].sub_total =
    cart[accessoryIndex].amount * cart[accessoryIndex].component_acessory_price;

  if (amountAndAcessoryId.amount <= 0) {
    cart.splice(accessoryIndex, 1);
  }

  return cart;
};

export const addToCartWholesale = (state, action) => {
  const { accessory } = action;
  const cart = [...state];

  if (!accessory.component_acessory_price_wholesale) {
    toast.error('acessório sem preço de venda cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...accessory, amount: 1, sub_total: accessory.component_acessory_price_wholesale }];
  }

  console.log(accessory);

  const hasAccessoryIndexInCart = state.findIndex((a) => {
    return a.id == action.accessory.id;
  });

  let accessoryAdded = [];

  if (hasAccessoryIndexInCart >= 0) {
    accessoryAdded = cart[hasAccessoryIndexInCart];

    if (accessoryAdded.quantity < accessoryAdded.amount + 1) {
      toast.error(
        `Não é possivel adicionar mais do item ${accessoryAdded.description}, limite do estoque atingido`,
      );

      return cart;
    }

    accessoryAdded.amount += 1;
    accessoryAdded.sub_total =
      accessoryAdded.amount * accessoryAdded.component_acessory_price_wholesale;

    return cart;
  }

  return [
    ...cart,
    { ...accessory, amount: 1, sub_total: accessory.component_acessory_price_wholesale },
  ];
};

export const removeFromCartWholesale = (state, action) => {
  const cart = [...state];
  const { accessory } = action;

  const hasAccessoryIndexInCart = state.findIndex((a) => {
    return a.id === accessory.id;
  });

  if (hasAccessoryIndexInCart >= 0) {
    cart.splice(hasAccessoryIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updateAmountWholesale = (state, action) => {
  const cart = [...state];
  const { amountAndAcessoryId } = action;

  const accessoryIndex = cart.findIndex((a) => {
    return a.id === amountAndAcessoryId.accessory_id;
  });

  if (cart[accessoryIndex].quantity < amountAndAcessoryId.amount) {
    console.log(amountAndAcessoryId.amount);
    toast.error(
      `Não é possivel adicionar mais do item ${cart[accessoryIndex].description}, limite do estoque atingido`,
    );
    return cart;
  }

  cart[accessoryIndex].amount = amountAndAcessoryId.amount;

  cart[accessoryIndex].sub_total =
    cart[accessoryIndex].amount * cart[accessoryIndex].component_acessory_price_wholesale;

  if (amountAndAcessoryId.amount <= 0) {
    cart.splice(accessoryIndex, 1);
  }

  return cart;
};

export const cleanCart = (state, action) => {
  const { cartCleaned } = action;

  return cartCleaned;
};
