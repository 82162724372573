import React, { useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Checkbox, FormatDate, Spacer, Table, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { ServiceOrderContext } from 'pages/Private/ServiceOrder/Consult';
import { api, serviceOrderService } from 'services';
import * as yup from 'yup';

const columns = [
  { label: 'Anotação' },
  { label: 'Adicionado por' },
  { label: 'Adicionado em' },
  { label: 'Aparecer no Consulte aqui' },
];

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export default function Remark() {
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGspn, serviceFromGaia } = serviceData;

  const [remarkData, setRemarkData] = useState();

  const functionGetRemarks = async () => {
    const { data } = await api.get(`/service-orders/serviceRemark/${serviceFromGaia?.id}`);
    setRemarkData(data);
  };

  console.log('remarkData', remarkData);

  useEffect(() => {
    functionGetRemarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceFromGaia]);

  const onSubmitHandler = async (description, service_order_id) => {
    await serviceOrderService
      .createServiceRemark(description, serviceFromGaia.id)
      .then((item) => {
        console.log('Item: ', item);
        toast.success('Registro realizado com sucesso.');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      })
      .finally(() => {
        functionGetRemarks();
      });
  };

  const handleCheckConsulteAqui = async (service_remark_id, checked) => {
    const { data } = await api.put(`/service-orders/serviceRemark/${service_remark_id}/${checked}`);
    setRemarkData(data);
  };

  const existsInfo = useMemo(() => {
    if (!serviceData.length && serviceData.length === 0) {
      return <></>;
    } else {
      return (
        <Formik
          initialValues={{
            description: '',
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Table
                      headers={columns}
                      striped
                      actionsLabel={<VisibilityIcon />}
                      emptyMessage='Nenhum registro encontrado.'
                      data={remarkData}
                      disableNumeration
                    >
                      {remarkData?.map((serviceRemark) => (
                        <TableRow key={serviceRemark?.id}>
                          <TableCell>{serviceRemark?.description}</TableCell>
                          <TableCell>{serviceRemark?.user?.name}</TableCell>
                          <TableCell>
                            <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                              {serviceRemark?.created_at}
                            </FormatDate>
                          </TableCell>
                          <TableCell align='center'>
                            <Checkbox
                              color='primary'
                              checked={serviceRemark?.view_consulte_aqui}
                              onChange={(e) =>
                                handleCheckConsulteAqui(serviceRemark?.id, e?.target?.checked)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid xs={12} md={12} lg={12}>
                    <Grid item xs={12} md={6} lg={6}>
                      <Field
                        size='small'
                        fullWidth
                        name='description'
                        label='Descrição'
                        variant='outlined'
                        component={Text}
                        multiline
                        rows={4}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Spacer size={12} />
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={6}>
                    <Button onClick={() => onSubmitHandler(props.values.description)}>
                      REGISTRAR
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarkData]);

  return existsInfo;
}
