import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Dialog } from 'components';
import { Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { componentService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { Actions } from './Actions';
import { CartWithItems } from './CartWithItems';
import { ConfirmPurchase } from './ConfirmPurchase';
import { StepIconComponent } from './StepIcon';

import { StockRequisitionContext } from '..';

import { cleanCartAction } from './store/actions';

const steps = [
  {
    label: 'Carrinho',
  },
  {
    label: 'Confirmar',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const ClientContext = createContext();

export const Cart = ({ open, onClose, selectedComponent = {} }) => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [activeStep, setActiveStep] = useState(0);

  const { state, serviceOrder, dispatch, refetchComponents } = useContext(StockRequisitionContext);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await componentService.createStockRequisition(serviceOrder, state, user.id);

      refetchComponents();
      cleanCartAction(dispatch);
      resetForm();
      setActiveStep(0);
      onClose();

      toast.success('Requisição de estoque realizada com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar a requisição de estoque.');
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = useCallback((activeStep, formProps) => {
    const steps = {
      0: <CartWithItems FormProps={formProps} />,
      1: <ConfirmPurchase FormProps={formProps} />,
    };

    return steps[activeStep];
  }, []);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='lg'>
        <div>
          <Typography color='primary' align='center' variant='h6'>
            {serviceOrder}
          </Typography>
          ;
          <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
            {steps.map((step) => (
              <Step key={step?.label}>
                <StepLabel StepIconComponent={StepIconComponent}>{step?.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Formik initialValues={{ service_order: '' }} onSubmit={onSubmitHandler}>
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              {getStepContent(activeStep, props)}
              <Actions
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                stepsSize={steps?.length}
              />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
