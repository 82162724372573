import React from 'react';
import { useRouteMatch } from 'react-router';

import { CreateAccount } from 'pages/Private/GeneralRegistration/CreateAccount';
import { CreateLog } from 'pages/Private/GeneralRegistration/CreateLog';
import { CreateObjectBlockingReason } from 'pages/Private/GeneralRegistration/CreateObjectBlockingReason';
import { CreateQuoteSmartphoneBrand } from 'pages/Private/GeneralRegistration/CreateQuoteSmartphoneBrand';
import { CreateQuoteSmartphoneInsuranceType } from 'pages/Private/GeneralRegistration/CreateQuoteSmartphoneInsuranceType';
import { CreateQuoteSmartphoneModel } from 'pages/Private/GeneralRegistration/CreateQuoteSmartphoneModel';
import { CreateQuoteSmartphoneUseTime } from 'pages/Private/GeneralRegistration/CreateQuoteSmartphoneUseTime';
import { RegisterAccessoryPrice } from 'pages/Private/GeneralRegistration/RegisterAccessoryPrice';
import { RegisterAcessory } from 'pages/Private/GeneralRegistration/RegisterAcessory';
import { RegisterAttendant } from 'pages/Private/GeneralRegistration/RegisterAttendant';
import { RegisterBillingExpiration } from 'pages/Private/GeneralRegistration/RegisterBillingExpiration';
import { RegisterBillingExpirationType } from 'pages/Private/GeneralRegistration/RegisterBillingType';
import { RegisterBudgetStatus } from 'pages/Private/GeneralRegistration/RegisterBudgetStatus';
import { RegisterClient } from 'pages/Private/GeneralRegistration/RegisterClient';
import { RegisterComponentsSalesPriceFile } from 'pages/Private/GeneralRegistration/RegisterComponentsSalesPriceFile';
import { RegisterDeviceDefect } from 'pages/Private/GeneralRegistration/RegisterDeviceDefect';
import { RegisterModel } from 'pages/Private/GeneralRegistration/RegisterModel';
import { RegisterColor } from 'pages/Private/GeneralRegistration/RegisterModel copy';
import { RegisterProcess } from 'pages/Private/GeneralRegistration/RegisterProcess';
import { RegisterSeller } from 'pages/Private/GeneralRegistration/RegisterSeller.jsx';
import { RegisterServiceBudget } from 'pages/Private/GeneralRegistration/RegisterServiceBudget';
import { RegisterServiceProducts } from 'pages/Private/GeneralRegistration/RegisterServiceProducts';
import { RegisterShippingCompany } from 'pages/Private/GeneralRegistration/RegisterShippingCompany';
import RegisterSigep from 'pages/Private/GeneralRegistration/RegisterSigep';
import { RegisterSubDeviceDefect } from 'pages/Private/GeneralRegistration/RegisterSubDeviceDefect';
import { RegisterSubInsurance } from 'pages/Private/GeneralRegistration/RegisterSubInsurance';
import { SingleRegistrationOfTaxCoupon } from 'pages/Private/GeneralRegistration/SingleRegistrationOfTaxCoupon';
import { RegisterSigepContract } from 'pages/Private/Receivement/RegisterSigepContract';

import { Route } from '../Route';

export const GeneralRegistrationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/register-sigep_range`} component={RegisterSigep} />
      <Route isPrivate path={`${path}/register-service-budget`} component={RegisterServiceBudget} />
      <Route isPrivate path={`${path}/register-process`} component={RegisterProcess} />
      <Route isPrivate path={`${path}/register-budget-status`} component={RegisterBudgetStatus} />
      <Route isPrivate path={`${path}/register-model`} component={RegisterModel} />
      <Route isPrivate path={`${path}/register-acessory`} component={RegisterAcessory} />
      <Route isPrivate path={`${path}/register-service-colors`} component={RegisterColor} />

      <Route
        isPrivate
        path={`${path}/create-billingexpiration`}
        component={RegisterBillingExpiration}
      />
      <Route
        isPrivate
        path={`${path}/create-billingexpirationType`}
        component={RegisterBillingExpirationType}
      />

      <Route
        isPrivate
        path={`${path}/register-service-products`}
        component={RegisterServiceProducts}
      />
      <Route
        isPrivate
        path={`${path}/register-accessory-price`}
        component={RegisterAccessoryPrice}
      />
      <Route isPrivate path={`${path}/register-contract-sigep`} component={RegisterSigepContract} />

      <Route isPrivate path={`${path}/register-clerk`} component={RegisterAttendant} />
      <Route isPrivate path={`${path}/register-client`} component={RegisterClient} />
      <Route isPrivate path={`${path}/create-account`} component={CreateAccount} />
      <Route isPrivate path={`${path}/create-log`} component={CreateLog} />
      <Route
        isPrivate
        path={`${path}/create-components-sales-price`}
        component={RegisterComponentsSalesPriceFile}
      />
      <Route isPrivate path={`${path}/register-sub-insurance`} component={RegisterSubInsurance} />
      <Route
        isPrivate
        path={`${path}/single-registration-of-tax-coupon`}
        component={SingleRegistrationOfTaxCoupon}
      />
      <Route isPrivate path={`${path}/register-seller`} component={RegisterSeller} />
      <Route isPrivate path={`${path}/register-shipping`} component={RegisterShippingCompany} />
      <Route isPrivate path={`${path}/create-device-defect`} component={RegisterDeviceDefect} />
      <Route
        isPrivate
        path={`${path}/create-sub-device-defect`}
        component={RegisterSubDeviceDefect}
      />
      <Route
        isPrivate
        path={`${path}/create-quote-smartphone-brand`}
        component={CreateQuoteSmartphoneBrand}
      />
      <Route
        isPrivate
        path={`${path}/create-quote-smartphone-model`}
        component={CreateQuoteSmartphoneModel}
      />
      <Route
        isPrivate
        path={`${path}/create-quote-smartphone-use-time`}
        component={CreateQuoteSmartphoneUseTime}
      />
      <Route
        isPrivate
        path={`${path}/create-quote-smartphone-insurance-type`}
        component={CreateQuoteSmartphoneInsuranceType}
      />
      <Route
        isPrivate
        path={`${path}/create-object-blocking-reason`}
        component={CreateObjectBlockingReason}
      />
    </>
  );
};
