import React, { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Select, Text, FormatMoney, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';

import { RegisterServiceBudgetDialog } from './RegisterServiceBudgetDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Serviço' },
  { label: 'Produto' },
  { label: 'Valor' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const RegisterServiceBudget = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [serviceOrder, setServiceOrder] = useState({});

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setServiceOrder(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const [name, setName] = useState('');

  const [additionalServices, setAdditionalServices] = useState([]);

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.description,
    }),
  );

  const [additionalServicesData, , loadingAdditionalServices, refetchAdditionalServicesData] =
    useQuery(() => api.get('/budgets/additional-services'), []);

  useEffect(() => {
    setAdditionalServices(additionalServicesData);
  }, [additionalServicesData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { service_price, ...rest } = values;

      const { data } = await api.post('/budgets/additional-services', values);

      setAdditionalServices((prevState) => [...prevState, data]);

      toast.success('Serviço cadastrado com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar serviço.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Tipo de serviço para orçamento</PageTitle>
      <Formik
        initialValues={{ description: '', product: '', service_price: 0 }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='description'
                    label='Serviço'
                    variant='outlined'
                    size='small'
                    component={Text}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='additional_service_product_id'
                    label='Produto'
                    variant='outlined'
                    size='small'
                    options={additionalServiceProductOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='service_price'
                    label='Valor do serviço'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={300}
                    loading={loadingAdditionalServices}
                    data={additionalServices}
                    actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {additionalServices?.map((service) => (
                      <TableRow key={service.id}>
                        <TableCell>{service.description}</TableCell>
                        <TableCell>{service.additional_service_product?.description}</TableCell>
                        <TableCell>
                          <FormatMoney>{service.service_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, service)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
              <RegisterServiceBudgetDialog
                open={isDetailsOpen}
                handleClose={handleCloseInformation}
                serviceOrder={serviceOrder}
                reloadAdditionalService={refetchAdditionalServicesData}
              />
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
