import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { ServiceOrderContext } from '../../..';
import useStyles from '../styles';

export const Product = () => {
  const classes = useStyles();

  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Produto
      </Typography>
      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            variant='outlined'
            size='small'
            label='Serial IN'
            value={serviceFromGaia?.imei}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={3}>
          <TextField
            variant='outlined'
            size='small'
            label='Modelo'
            value={serviceFromGaia?.model}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Tipo de Produto'
            value={serviceFromGaia?.product_type?.description}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Fabricante'
            value='Motorola'
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
      </Grid>
    </>
  );
};
