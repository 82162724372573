/* eslint-disable no-restricted-imports */
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';
import Webcam from 'react-webcam';

import {
  Box,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { GENERATE_REPORT } from 'services/graphql/query/pdf';

const UploadPhoto = ({ label, onFileSelect, handleNext }) => {
  const webcamRef = useRef(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    const imageBlob = dataURItoBlob(imageSrc);
    onFileSelect(imageBlob);
    handleNext();
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  return (
    <Grid container direction='column' alignItems='center' spacing={2}>
      <Grid item>
        <Typography variant='h6'>{label}</Typography>
      </Grid>
      <Grid item>
        <Webcam ref={webcamRef} screenshotFormat='image/jpeg' style={{ width: '100%' }} />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Button onClick={handleCapture} variant='contained' color='secondary' fullWidth>
          Tirar Foto
        </Button>
      </Grid>
    </Grid>
  );
};

export function PhotoUploadForm({ sinister }) {
  const steps = ['Foto do IMEI', 'Foto da Traseira', 'Foto do Defeito', 'Foto Opcional'];
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [problemDescription, setProblemDescription] = useState('');

  const [generateReport] = useMutation(GENERATE_REPORT, {
    onCompleted: (data) => {
      const pdfBase64 = data?.generateReport || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = `${sinister}-report.pdf`;
      link.click();
      window.location.reload();
    },
    onError: (error) => {
      toast.error('Erro ao gerar o PDF!');
      console.error('Erro ao gerar o PDF:', error);
    },
  });

  const handleFileSelect = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onloadend = () => {
      const base64data = reader.result;
      setUploadedPhotos((prev) => [...prev, base64data]);
    };
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setUploadedPhotos([]);
    setProblemDescription('');
  };

  const handleSendPhotos = async () => {
    if (uploadedPhotos.length < 3) {
      toast.error('Por favor, envie pelo menos 3 fotos.');
      return;
    }

    try {
      await generateReport({
        variables: { photoUrls: uploadedPhotos, sinister, description: problemDescription },
      });
    } catch (error) {
      console.error('Erro ao enviar fotos:', error);
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((item, index) => (
          <Step key={index}>
            <StepLabel>{item}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        {activeStep < steps.length ? (
          <UploadPhoto
            label={steps[activeStep]}
            onFileSelect={handleFileSelect}
            handleNext={handleNext}
          />
        ) : (
          <>
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Typography variant='h6'>Fotos Tiradas</Typography>
              <Grid item>
                <Grid container spacing={2}>
                  {uploadedPhotos.map((photo, index) => (
                    <Grid item key={index}>
                      <img
                        src={photo}
                        alt={`upload-preview-${index}`}
                        style={{ width: '100px', height: '100px' }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label='Descreva o problema'
                  multiline
                  rows={6} // Aumentado para 6 linhas
                  variant='outlined'
                  fullWidth
                  value={problemDescription}
                  onChange={(e) => setProblemDescription(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container direction='column' alignItems='center' spacing={2}>
              <Grid item sm={6}>
                <Button onClick={handleReset} variant='outlined' color='secondary' fullWidth>
                  Reiniciar
                </Button>
              </Grid>
              <Grid item sm={6}>
                <Button onClick={handleSendPhotos} variant='contained' color='primary' fullWidth>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Box>
  );
}
