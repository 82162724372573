import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FormatMoney } from 'components';
import { Text, Button, BackNavButton } from 'components';
import { Form, Formik, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { budgetService } from 'services/budget';
import { useContextSelector } from 'use-context-selector';

import { validationSchema } from './validation';

export const Negotiation = () => {
  const userName = useContextSelector(AuthContext, (context) => context.user.name);

  const [loadingServiceOrder, setLoadingServiceOrder] = useState(false);
  const [budget, setBudget] = useState({});

  const {
    state: {
      service_order_number,
      budget_id,
      final_budget_price,
      name,
      min_components_value,
      additional_services_total_price,
      wanted_value,
      sinister,
      bpo,
    },
  } = useLocation();

  console.log('service_order_number: ', sinister);

  const navigateTo = () => {
    browserHistory.push({
      pathname: '/sac/budget-kit',
    });
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await budgetService.addBudgetNegociation(budget_id, values);
      resetForm();
      toast.success('Orçamento com negociação criado com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar a criação da negociação.');
    } finally {
      setSubmitting(false);
      navigateTo();
    }
  };

  return (
    <>
      <BackNavButton />
      <PageTitle>Aguardando negociação</PageTitle>
      <Formik
        initialValues={{
          service_order_number: service_order_number,
          client_name: name,
          final_budget_price: final_budget_price,
          min_components_value: min_components_value,
          additional_services_total_price: additional_services_total_price,
          negociation_price: 0,
          negociator: userName,
          wanted_value: wanted_value,
          bpo: bpo,
          sinister: sinister,
        }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='service_order_number'
                    label='Service'
                    size='small'
                    variant='outlined'
                    inputProps={{ maxLength: 50 }}
                    component={Text}
                    onBlur={() => handleServiceOrder(props)}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    name='client_name'
                    label='Nome do cliente'
                    size='small'
                    variant='outlined'
                    component={Text}
                    loading={loadingServiceOrder}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    name='bpo'
                    label='Seguradora'
                    size='small'
                    variant='outlined'
                    component={Text}
                    loading={loadingServiceOrder}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    name='sinister'
                    label='Sinistro'
                    size='small'
                    variant='outlined'
                    component={Text}
                    loading={loadingServiceOrder}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='final_budget_price'
                    label='Valor do orçamento'
                    size='small'
                    variant='outlined'
                    mask='money'
                    inputProps={{
                      min: 1,
                    }}
                    disabled
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    name='negociator'
                    label='Negociador responsável'
                    size='small'
                    variant='outlined'
                    component={Text}
                    laading={userName}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='negociation_price'
                    label='Novo orçamento'
                    size='small'
                    variant='outlined'
                    component={Text}
                    mask='money'
                    inputProps={{
                      min: 1,
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button fullWidth disabled={!props.dirty || !props.isValid} type='submit'>
                    Confirmar Negociação
                  </Button>
                </Grid>
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <Typography style={{ color: 'red' }}>
                      Preço minimo para componentes :{' '}
                      <FormatMoney>{min_components_value ?? 0}</FormatMoney>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography style={{ color: 'red' }}>
                      Preço minimo para serviços :{' '}
                      <FormatMoney>{additional_services_total_price ?? 0}</FormatMoney>
                    </Typography>

                    <Typography style={{ color: 'blue' }}>
                      Preço solicitado pelo cliente : <FormatMoney>{wanted_value ?? 0}</FormatMoney>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
