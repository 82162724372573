import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Spacer, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService, serviceOrderService } from 'services';
import { mailObjectService } from 'services/mailObject';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  // {
  //   label: 'Possui',
  //   width: 80,
  // },
  { label: 'Descrição', align: 'center' },
];

export function CheckKit() {
  const classes = useStyles();

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 3000);
  };

  const [hasFinish, setHasFinish] = useState(false);

  const [isDevol, setDevol] = useState(false);

  const [isNotObject, setNotObject] = useState(false);

  const disableFinish = () => {
    console.log(hasFinish);
    if (hasFinish) {
      setHasFinish(false);
      console.log(hasFinish);
    } else {
      setHasFinish(true);
      console.log(hasFinish);
    }
  };

  const disableDevol = () => {
    if (isDevol) {
      setDevol(false);
    } else {
      setDevol(true);
    }
  };

  const disbleIfIsNotObject = () => {
    if (isNotObject) {
      setNotObject(false);
    } else {
      setNotObject(true);
      formikRef.current.setFieldValue('manual_object', 'NADA CONSTA');
    }
  };
  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const formikRef = useRef();
  const [submitingCheck, setSubmitingCheck] = useState();

  const [service, setService] = useState();
  const [swap, setSwap] = useState();

  const [object, setObject] = useState();
  const [imei_validation, setImeiValidation] = useState();
  const [imeiprocess, setImei] = useState();
  const [imeioutprocess, setImeiOut] = useState();

  const [infoadd, setInfoAdd] = useState();

  const [accessories, setAccessories] = useState();
  const [checkedAccessories, setCheckedAccessories] = useState([]);

  const handleDownloadDanfe = async (service_order_number) => {
    console.log('service_order_number: ', service_order_number);
    const { data } = await budgetService.getDanfe(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}-DANFE.pdf`);
  };

  const handleDownloadPostCard = async (service_order_number) => {
    console.log('service_order_number: ', service_order_number);
    const { data } = await budgetService.getPostCard(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const onDownloadCards = async (values) => {
    try {
      setTimeout(() => {
        handleDownloadDanfe(values.service_number);
      }, 2000);

      setTimeout(() => {
        handleDownloadPostCard(values.service_number);
      }, 2000);
      // }

      toast.success('Download Realizado com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro realizar download');
    } finally {
    }
  };

  const handleDownloadTagsClick = async (service_order_number) => {
    const { data } = await mailObjectService.impressMinuteManualswap(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const handleDownloadTagConsulteAqui = async () => {
    const { data } = await serviceOrderService.reprintTagConsulteAqui();
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `consulteAqui.pdf`);
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const { data } = await serviceOrderService.getServiceByNumberWhereIsNotCheckKit(
        values.service_number,
      );

      setObject(values.manual_object);

      setImeiValidation(values.imei_validation);

      console.log('datacjekiot', data);

      const { acessorios_que_vieram } = data;

      const { imei } = data;

      const { imei1_out } = data;
      const { add_info } = data;

      const { tradeIn } = data;

      setInfoAdd(add_info);

      setSwap(tradeIn);

      setImeiOut(imei1_out);

      setImei(imei);
      setService(data);
      setAccessories(acessorios_que_vieram ?? 'NADA');

      setSubmitting(true);

      toast.success('OS encontrada com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao encontrar OS');
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitConference = async ({ setSubmitting, resetForm }) => {
    setSubmitingCheck('true');
    let arrayCheckKit = [];

    try {
      let finish = '';
      {
        hasFinish ? (finish = '0') : (finish = '1');
      }

      console.log('finish', finish);

      let devol = '';
      {
        isDevol ? (devol = '0') : (devol = '1');
      }
      if (checkedAccessories.length > 0) {
        checkedAccessories.map((accessorie) => {
          const generateCheckKit = {
            service_order_id: service.id,
            service_order_accessory_id: accessorie,
            manual_object: object,
            imei_validation: imei_validation,

            checkFinish: finish,
            user_id: user,
            isdevol: devol,
          };

          return arrayCheckKit.push(generateCheckKit);
        });
      } else {
        const generateCheckKit = {
          service_order_id: service.id,
          manual_object: object,
          imei_validation: imei_validation,
          checkFinish: finish,
          user_id: user,
          isdevol: devol,
        };

        arrayCheckKit.push(generateCheckKit);
      }

      await serviceOrderService.createCheckKit({ checkKit: arrayCheckKit });

      if (swap === 'SIM') {
        handleDownloadTagsClick(service?.number);
      }

      //Logistica reversa ou Seguradora
      if (service.process_type_id == 3 || service.process_type_id == 1) {
        await serviceOrderService.invoiceEmissionOutbound(service?.number);
      }

      handleDownloadTagConsulteAqui();

      if (service.process_type_id == 3 || service.process_type_id == 1) {
        {
          setTimeout(() => {
            handleDownloadDanfe(service?.number);
          }, 2000);

          setTimeout(() => {
            handleDownloadPostCard(service?.number);
          }, 2000);
          // }
        }
      }

      if (service.process_type_id == 2) {
        {
          setTimeout(() => {
            handleDownloadTagsClickBudget(service?.number);
          }, 2000);
        }
      }

      setAccessories(undefined);
      setCheckedAccessories([]);

      formikRef.current.setFieldValue('service_number', '');

      formikRef.current.setFieldValue('manual_object', '');

      formikRef.current.setFieldValue('imei_validation', '');

      toast.success('Check-Kit validado com sucesso.');
      setSubmitingCheck('false');
      refreshPage();

      setSubmitting(true);
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message);
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleDownloadTagsClickBudget = async (service) => {
    const { data } = await budgetService.getTemplate(service);
    console.log('Data: ', data);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `Laudo.pdf`);
  };

  useEffect(() => {}, [checkedAccessories]);

  return (
    <>
      <PageTitle>Check-Kit</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{
          service_number: '',
          manual_object: '',
          imei_validation: '',
          checkFinish: true,

          isdevol: true,
        }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  inputProps={{ minlength: 13 }}
                  component={Text}
                  onInput={toInputUppercase}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Objeto Manual'
                  name='manual_object'
                  variant='outlined'
                  size='small'
                  component={Text}
                  onInput={toInputUppercase}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Imei do aparelho (Atual)'
                  name='imei_validation'
                  variant='outlined'
                  inputProps={{ maxLength: 15, minlength: 15 }}
                  size='small'
                  component={Text}
                  onInput={toInputUppercase}
                />
              </Grid>

              <Grid item xs={3} lg={3} style={{ fontSize: 15 }}>
                <Field
                  component={Checkbox}
                  name='notObject'
                  label='Objeto ?'
                  onClick={disbleIfIsNotObject}
                />
                <Typography variant='h7' gutterBottom>
                  SEM OBJETO
                </Typography>
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button
                  disabled={props.isSubmitting || !props.isValid || !props.dirty}
                  type='submit'
                  fullWidth
                >
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            {accessories ?? '' ? (
              <>
                <Typography variant='h6' gutterBottom>
                  Conferência de Acessórios
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Table
                      headers={columns}
                      striped
                      disableNumeration
                      data={accessories}
                      maxHeight={500}
                    >
                      <TableRow key={1}>
                        <TableCell size='small' align='center'>
                          {accessories[0]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[1]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[2]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[3]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[4]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[5]}
                        </TableCell>
                        <TableCell size='small' align='center'>
                          {accessories[6]}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Grid>

                  <Grid item xs={12} lg={12} style={{ fontSize: 15 }}>
                    <Typography variant='h7' color='secondary' gutterBottom>
                      SWAP: {swap ?? 'Não possui'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={12} style={{ fontSize: 15 }}>
                    <Typography variant='h7' gutterBottom>
                      Seguradora: {infoadd ?? 'Não possui'}
                    </Typography>
                  </Grid>

                  <Grid item xs={12} lg={12} style={{ fontSize: 15 }}>
                    <Typography variant='h7' gutterBottom>
                      Imei: {imeioutprocess ? imeioutprocess : imeiprocess}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button
                      disabled={props.isSubmitting || submitingCheck == 'true'}
                      fullWidth
                      onClick={onSubmitConference}
                    >
                      Validar Conferência
                    </Button>
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={3}>
                    <Field
                      label='Objeto Manual'
                      name='manual_object'
                      variant='outlined'
                      size='small'
                      component={Text}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                    />
                  </Grid> */}
                  <Grid item xs={12} lg={3} style={{ fontSize: 15 }}>
                    <Field
                      component={Checkbox}
                      name='checkFinish'
                      label='Encerrar OS'
                      disabled={service.process_type_id == 2 || service.process_type_id == 4}
                      onClick={disableFinish}
                    />
                    <Typography variant='h7' gutterBottom>
                      Encerrar OS
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={3} style={{ fontSize: 15 }}>
                    <Field
                      component={Checkbox}
                      name='isdevol'
                      label='Devolução'
                      onClick={disableDevol}
                    />
                    <Typography variant='h7' gutterBottom>
                      Devolução sem Reparo
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <img className={classes.imgContainer} src={checkKitImg} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
