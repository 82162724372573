import React from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import { BackNavButton, Spacer } from 'components';
import { Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { preBudgetService } from 'services/preBudget';

import { Form } from './Form';
import { validationSchema } from './validation';

export const OutOfWarrantyBudget = () => {
  const { state } = useLocation();

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await preBudgetService.createPreBudget(values);

      toast.success('Pré-orçamento criado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao criar pré-orçamento.');
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <BackNavButton />

      <Spacer axis='vertical' size={16} />

      <PageTitle>Gerar orçamento</PageTitle>

      <Formik
        initialValues={{
          service_order_id: state.service_order_id,
          update_is_required: false,

          components_id: [],
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        <Form />
      </Formik>
    </>
  );
};
