import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ReactComponent as UploadImg } from 'assets/images/createComponentsImageImport.svg';
import { Text, FormatDate, Table, DatePicker } from 'components';
import { Button } from 'components';
import { Formik, Form, Field } from 'formik';
import { UseDebounce } from 'hooks/useDebounce';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService, invoiceService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const ImportManyInvocieXmlRepair = () => {
  const [importing, setImporting] = useState(false);
  const [initialValues, setValues] = useState();
  const [receive_date, setInputData] = useState(new Date());
  const [additionalServiceProducts, setAdditionalServiceProducts] = useState([]);

  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const { debounce } = UseDebounce(3000, false);

  const classes = useStyles();

  const [convertion, , loadingConvertion, refetchConvertion] = useQuery(
    () => invoiceService.listConvertedXmls(),
    [],
  );

  const [todoData, , loadingTodo, refetchTodo] = useQuery(
    () => invoiceService.listtodoConference(),
    [],
  );

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts, productsRefetch] =
    useQuery(budgetService.listAdditionalServiceProducts, []);

  const productOptions = (additionalServicesProductsData || []).map((product) => ({
    value: product.id,
    label: product.description,
  }));

  const filteredObjects = (todoData || []).filter((object) => {
    const loweredSearch = search.toLowerCase();

    return (
      object.nnf.toLowerCase().includes(loweredSearch) ||
      object.created_at.toLowerCase().includes(loweredSearch) ||
      object.total.toLowerCase().includes(loweredSearch)
    );
  });

  const columns = [
    { label: 'N° Nota' },
    { label: 'Quantidade de itens' },
    { label: 'Data importação' },
    { label: 'Data Recebimento' },
    { label: 'Nome Cliente' },
    { label: 'Tipo de produto' },
  ];

  // console.log('Convertion', convertion);

  useEffect(() => {}, [convertion]);

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      toast.info('Iniciando importação...');

      const files = e.target.files;

      let xmlsArray = [];

      const reader = new FileReader();

      const readFile = (index) => {
        if (index >= files.length) return;
        let file = files[index];

        reader.onload = async (event) => {
          const result = event.target.result;

          api
            .post(`/invoices/repair/xml/${receive_date}`, result, {
              headers: {
                'Content-Type': 'application/xml',
              },
            })
            .then((response) => {
              const { data } = response;

              const { newProvider, ...importValues } = data;

              setValues({
                ...initialValues,

                ...importValues,
              });

              if (newProvider) {
                refetchProviders();
              }
            });

          xmlsArray.push(result);

          // console.log('XMLS: ', xmlsArray);
          readFile(index + 1);
        };

        debounce(() => {
          toast.success('Importação Finalizada, confira na tabela abaixo');
        }, []);

        reader.readAsBinaryString(file);
      };

      readFile(0);
      refetchConvertion();

      // reader.readAsBinaryString(files);
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || 'Erro ao importar, tente novamente!');
    } finally {
      setImporting(false);
    }
  };

  const handleChangeProduct = async (nnf, product_id) => {
    toast.info(`Atualizando o tipo de produto da NF: ${nnf}`);

    console.log('object?.nnf', product_id);
    try {
      await invoiceService.updatedInvoiceRepairProductType({ nnf, product_id });
      refetchTodo();
      toast.success(`Atualizado o tipo de produto com sucesso.`);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao atualizar o tipo de produto');
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xml'
        multiple
        onChange={handleFileChange}
        accept='text/xml'
        hidden
      />

      <PageTitle>Importar notas de reparo em lote</PageTitle>
      <Formik
        initialValues={{
          service_order_number: '',
          receive_date: new Date(),
          input_data: new Date(),
          output_data: new Date(),
          product: '',
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Paper className={classes.gridPaper} variant='outlined'>
                <Grid container spacing={1}>
                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      onClick={handleImportClick}
                      type=''
                      disabled={!props.dirty || !props.isValid || importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Importar XML
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Field
                      size='small'
                      fullWidth
                      name='receive_date'
                      variant='inline'
                      inputVariant='outlined'
                      label='Data de Recebimento'
                      component={DatePicker}
                      OnChange={() => setInputData(props.values.receive_date)}
                    />

                    {useEffect(() => {
                      setInputData(props.values.receive_date);
                    }, [props.values])}
                  </Grid>

                  {importing ? (
                    <Grid xs={6} lg={12} className={classes.loading}>
                      <Box sx={{ width: '90%' }}>
                        <LinearProgress color='secondary' />
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                        </Grid>
                        <UploadImg className={classes.imgTracker} />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid className={classes.gridImages} xs={6} lg={12}>
                      {' '}
                      <UploadImg className={classes.imgTracker} />
                    </Grid>
                  )}
                </Grid>
              </Paper>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}></Grid>
                <Grid item xs={12} lg={3}></Grid>

                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Atenção! A importação pode demorar alguns minutos.
                    </p>
                  </div>
                </Grid>
              </Grid>
              <PageTitle>Notas aguardando conferência </PageTitle>
              <Grid container spacing={2} className={classes.formContainer}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar NF'
                    size='small'
                    fullWidth
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Table
                    title='Lista de Notas'
                    headers={columns}
                    loading={loadingTodo}
                    // maxHeight={300}
                    emptyMessage='Nenhum registro encontrado.'
                    striped
                  >
                    {filteredObjects?.map((object) => (
                      <TableRow key={object?.id}>
                        <TableCell>{object?.nnf}</TableCell>
                        <TableCell>{object?.total}</TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.created_at}</FormatDate>
                        </TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                            {object?.receive_date}
                          </FormatDate>
                        </TableCell>
                        <TableCell>{object?.company_name}</TableCell>
                        <TableCell>
                          <FormControl variant='standard' sx={{ m: 1, minWidth: 90 }}>
                            <InputLabel id='demo-simple-select-standard-label'>Produto</InputLabel>
                            <Select
                              onChange={(e) => handleChangeProduct(object?.nnf, e?.target?.value)}
                              labelId='demo-simple-select-standard-label'
                              id='demo-simple-select-standard'
                              label='product'
                              value={object?.product_id}
                            >
                              {productOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
