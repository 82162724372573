import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  // cgc: yup
  //   .string()
  //   .required()
  //   .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
  //     if (text?.length < 14) {
  //       return cpf.isValid(text);
  //     }
  //     return cnpj.isValid(text);
  //   }),
  // email: yup.string().required(),
  // name: yup.string().required(),
  // city: yup.string().required(),
  // state: yup.string().required(),
  // district: yup.string().required(),
  // street_number: yup.string().required(),
  // zipcode: yup.string().required(),
  // street: yup.string().required(),
  // phone_number: yup.string().required(),
  // total_items: yup.number(),
  // description: yup.string(),
  // payment_method: yup.number(),
  // installments: yup.number(),
  // installment_price: yup.number(),
  // payment_method2: yup.number(),
  // installments2: yup.number(),
  // installment_price2: yup.number(),
  // payment_method3: yup.number(),
  // installments3: yup.number(),
  // installment_price3: yup.number(),
  // reason_refuse: yup.string(),
});
