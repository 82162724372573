import React, { useContext } from 'react';

import { Button } from 'components';
import { useFormikContext } from 'formik';

import { StockRequisitionContext } from '../..';
import { useStyles } from './styles';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values, resetForm } = useFormikContext();

  const { state } = useContext(StockRequisitionContext);

  const classes = useStyles();

  const isFirstStep = activeStep === 0;

  return (
    <div className={classes.footer}>
      <div className={classes.actions}>
        <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
          Voltar
        </Button>
        {activeStep === stepsSize - 1 && (
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            disabled={!state?.length > 0}
            loading={isSubmitting}
          >
            Confirmar Requisição
          </Button>
        )}
        {activeStep !== stepsSize - 1 && (
          <Button color='primary' loading={isSubmitting} onClick={handleNext}>
            Próxima Página
          </Button>
        )}
      </div>
    </div>
  );
};
