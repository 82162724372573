import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    legend: {
      fontWeight: 'bold',
    },
    containerLegend: {
      display: 'flex',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '40px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    p: {
      marginLeft: '10px',
      fontSize: '1rem',
    },

    buttonAddToCart: {
      fontSize: '12px',
      lineHeight: 1.2,
      padding: '24px 16px',
    },

    buttonOpenCart: {
      position: 'relative',
    },

    cartCount: {
      left: 'calc(100% - 50% - -7px)',
      color: 'whitesmoke',
      padding: '0 0.5rem',
      position: 'absolute',
      fontSize: '0.9rem',
      background: 'red',
      borderRadius: '50%',
      top: '-26%',
    },

    cartEmpty: {
      display: 'none',
    },
  };
});
