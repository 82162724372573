import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Bubble from 'assets/images/bubble.svg';
import Welcome from 'assets/images/welcome.svg';
import { FormatMoney } from 'components';
import { AuthContext } from 'hooks/useAuth';
// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useQuery } from 'hooks/useQuery';
import { serviceOrderService } from 'services';
import { accountService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { Graphic } from './Graphic';
import { GraphicAging } from './GraphicAging';
import { GraphicPerformance } from './GraphicPerformance';
import { GraphicPerformanceBudget } from './GraphicPerformanceBudget';
import { GraphiRepairInspector } from './GraphiRepairInspector';
import { GraphiRepairInspectorNonCompliance } from './GraphiRepairInspectorNonCompliance';
import { GrapichFinished } from './GrapichFinished';
import { useStyles } from './styles';

const Home = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  console.log('Jose o melhor da infra', user);

  const classes = useStyles();

  const zero = 0;

  const [budgettotalprice, setTotalPrice] = useState([]);

  const [nonCompliance, setTotalNonCompliance] = useState([]);

  const [userQuantityTotal, setUserQuantity] = useState([]);

  const [count, setCount] = useState([]);

  const [repairtotal, setRepairTotal] = useState([]);

  const [qatotal, setQaTotal] = useState([]);

  const [totalorc, setTotalOrc] = useState([]);

  const [countbudget, setcountBudget] = useState([]);

  const [countfinished, setCountFinished] = useState([]);

  const [
    serviceQuantityFinished,
    ,
    loadingServiceQuantityFinished,
    refetchServiceQuantityFinished,
  ] = useQuery(() => serviceOrderService.listServiceInQuantityFinished(), []);

  const [serviceQuantityBudget, , loadingServiceQuantityBudget, refetchServiceQuantityBudget] =
    useQuery(() => serviceOrderService.listServiceInQuantityBudget(), []);

  const [
    serviceQuantityTotalPrice,
    ,
    loadingserviceQuantityTotalPrice,
    refetcServiceQuantityTotalPrice,
  ] = useQuery(() => serviceOrderService.listQuantityByTotalPrice(), []);

  const [userQuantity, , loadinguserQuantity, refetchUserQuantity] = useQuery(
    () => accountService.listUserQuantity(),
    [],
  );

  const [totalRepairData, , loadingagingData, refetchTotalRepairData] = useQuery(
    () => serviceOrderService.listRepairInspectorTotal(),
    [],
  );

  const [totalQAData, , loadingqadata, refetchTotalQAData] = useQuery(
    () => serviceOrderService.listQaInspectorTotal(),
    [],
  );

  const [totalOrcData, , loadingorctotalData, refetchTotalOrcData] = useQuery(
    () => serviceOrderService.listOrctotal(),
    [],
  );

  const [serviceQuantity, , loadingServiceQuantity, refetchServiceQuantity] = useQuery(
    () => serviceOrderService.listServiceInQuantity(),
    [],
  );

  const [serviceNonCompliance, , loadingserviceNonCompliance, refetchServiceNonCompliance] =
    useQuery(() => serviceOrderService.listTotalRepairNonCompliance(), []);

  useEffect(() => {
    if (serviceNonCompliance) {
      const { totalqa } = serviceNonCompliance[0];
      setTotalNonCompliance(totalqa);
    }
  }, [serviceNonCompliance]);

  useEffect(() => {
    if (serviceQuantityTotalPrice) {
      const { budgettotalprice } = serviceQuantityTotalPrice[0];
      setTotalPrice(budgettotalprice);
    }
  }, [serviceQuantityTotalPrice]);

  useEffect(() => {
    if (serviceQuantityBudget) {
      const { countbudget } = serviceQuantityBudget[0];
      setcountBudget(countbudget);
    }
  }, [serviceQuantityBudget]);

  useEffect(() => {
    if (serviceQuantityFinished) {
      const { countfinished } = serviceQuantityFinished[0];
      setCountFinished(countfinished);
    }
  }, [serviceQuantityFinished]);

  useEffect(() => {
    if (serviceQuantity) {
      const { count } = serviceQuantity[0];
      setCount(count);
    }
  }, [serviceQuantity]);

  useEffect(() => {
    if (totalRepairData) {
      const { totalreparo } = totalRepairData[0];
      setRepairTotal(totalreparo);
    }
  }, [totalRepairData]);

  useEffect(() => {
    if (totalQAData) {
      const { totalqa } = totalQAData[0];
      setQaTotal(totalqa);
    }
  }, [totalQAData]);

  useEffect(() => {
    if (totalOrcData) {
      const { totalorccriado } = totalOrcData[0];
      setTotalOrc(totalorccriado);
    }
  }, [totalOrcData]);

  useEffect(() => {
    if (userQuantity) {
      const { quantity } = userQuantity[0];
      setUserQuantity(quantity);
    }
  }, [userQuantity]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetchServiceNonCompliance();
      refetchServiceQuantity();
      refetchServiceQuantityBudget();
      refetchServiceQuantityFinished();
      refetchTotalOrcData();
      refetchTotalQAData();
      refetchTotalRepairData();
      refetchUserQuantity();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [
    refetchServiceNonCompliance,
    refetchServiceQuantity,
    refetchServiceQuantityBudget,
    refetchServiceQuantityFinished,
    refetchTotalOrcData,
    refetchTotalQAData,
    refetchTotalRepairData,
    refetchUserQuantity,
  ]);

  return (
    <>
      {user.role.id === 14 ? (
        <Grid className={classes.containerBoxs} container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Paper elevation={2}>
              <Typography className={classes.titleWelcome} gutterbottom variant='h5'>
                Bem-vindo(a) ao <strong style={{ color: '#6F50F5' }}>Gaia-System</strong>{' '}
                {user.name}.
              </Typography>
              <div className={classes.contextWelcome}>
                <Typography variant='body2' gutterBottom>
                  O sistema de gerenciamento e gestão <strong>Gaia-System</strong>, foi desenvolvido
                  para auxiliar os postos de reparos. Através dele é possível acompanhar e
                  automatizar o processo de reparo das ordens de serviço!
                </Typography>

                <img className={classes.imgWelcome} src={Welcome} />
              </div>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        <>
          {/* <ModalUpdatePassword /> */}
          {/* <PageTitle>Dashboard</PageTitle> */}
          <Grid className={classes.containerBoxs} container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Paper elevation={2}>
                <Typography className={classes.titleWelcome} gutterbottom variant='h5'>
                  Bem-vindo(a) ao <strong style={{ color: '#6F50F5' }}>Gaia-System</strong>{' '}
                  {user.name}.
                </Typography>
                <div className={classes.contextWelcome}>
                  <Typography variant='body2' gutterBottom>
                    O sistema de gerenciamento e gestão <strong>Gaia-System</strong>, foi
                    desenvolvido para auxiliar os postos de reparos. Através dele é possível
                    acompanhar e automatizar o processo de reparo das ordens de serviço!
                  </Typography>

                  <img className={classes.imgWelcome} src={Welcome} />
                </div>
              </Paper>
            </Grid>
          </Grid>
          <Grid className={classes.containerBoxs} container spacing={2}>
            <Grid item xs={6} lg={3}>
              <Paper elevation={5} className={classes.cardServicesOpen}>
                <section className={classes.sectionCards}>
                  <div className={classes.divCards}>
                    <p className={classes.titleCards}> Nº {count}</p>
                    <p>Services abertas</p>
                  </div>
                  <div>
                    <img src={Bubble} className={classes.imgBubbleCards} />
                  </div>
                </section>
              </Paper>
            </Grid>

            <Grid item xs={6} lg={3}>
              <Paper elevation={5} className={classes.cardOscompleted}>
                <section className={classes.sectionCards}>
                  <div className={classes.divCards}>
                    <p className={classes.titleCards}>Nº {countfinished ?? zero}</p>
                    <p>OS finalizadas</p>
                  </div>
                  <div>
                    <img src={Bubble} className={classes.imgBubbleCards} />
                  </div>
                </section>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper elevation={5} className={classes.cardApprovedBudget}>
                <section className={classes.sectionCards}>
                  <div className={classes.divCards}>
                    <p className={classes.titleCards}>Nº {countbudget ?? zero}</p>
                    <p>Orçamentos aprovados</p>
                  </div>
                  <div>
                    <img src={Bubble} className={classes.imgBubbleCards} />
                  </div>
                </section>
              </Paper>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Paper elevation={5} className={classes.cardTotalSold}>
                <section className={classes.sectionCards}>
                  <div className={classes.divCards}>
                    <p className={classes.titleCards}>
                      {' '}
                      <FormatMoney>{budgettotalprice ?? zero}</FormatMoney>
                    </p>
                    <p>Total de vendas</p>
                  </div>
                  <div>
                    <img src={Bubble} className={classes.imgBubbleCards} />
                  </div>
                </section>
              </Paper>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: 5,
              paddingInline: 700,
            }}
          >
            <Grid item xs={6} lg={12} alignContent='center'>
              <Paper elevation={5} className={classes.cardMeta}>
                <section
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      fontSize: '16px',
                      color: '#ffff',
                      padding: '10px',
                    }}
                  >
                    <p>Meta diária</p>
                    <p className={classes.titleCards}> Nº 50</p>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      fontSize: '16px',
                      color: '#ffff',
                      padding: '10px',
                    }}
                  >
                    <p>Meta semanal</p>
                    <p className={classes.titleCards}> Nº 250</p>
                  </div>
                </section>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={2} className={classes.paperContainer}>
                <Typography gutterbottom variant='subtitle2' align='left'>
                  Produção REPARO
                </Typography>
                <Typography gutterbottom variant='subtitle3' align='left' color='secondary'>
                  Total REPARO : {repairtotal}
                  <br></br>
                  Meta por técnico 18
                </Typography>

                <GraphiRepairInspector />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={2} className={classes.paperContainer}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Não conforme REPARO
                </Typography>
                <Typography gutterbottom variant='subtitle2' align='left'>
                  Total não conforme : {nonCompliance ?? 0}
                </Typography>

                <GraphiRepairInspectorNonCompliance />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={2} className={classes.paperContainer}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Produção QA
                </Typography>
                <Typography gutterbottom variant='subtitle2' align='left'>
                  Total QA : {qatotal ?? 0}
                </Typography>

                <GraphicPerformance />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={2} className={classes.paperRecentTransaction}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Services por etapas(Aging)
                </Typography>
                <GraphicAging />
              </Paper>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={0} className={classes.paperContainer}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Orçamentos diarios
                </Typography>
                {/* <Typography gutterbottom variant='subtitle2' align='left'>
      Total Orçamentos criados : {totalorc}
    </Typography> */}

                <GraphicPerformanceBudget />
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6}>
              <Paper variant='elevation' elevation={2} className={classes.paperRecentTransaction}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Services abertas
                </Typography>

                <Graphic />
              </Paper>
            </Grid>

            {/* <Paper variant='elevation' elevation={2} className={classes.paperTrafficUsers}>
        <Typography gutterbottom variant='subtitle1' align='left'>
          Usuários cadastrados
        </Typography>
        <section className={classes.sectionTrafficUsers}>
          <div className={classes.divGraphicTrafficUsers}>
            <GraphicActiveUsers />
          </div>
          <div>
            <Typography className={classes.userActive} variant='h6' gutterbottom>
              {userQuantityTotal ?? 0} Reais
            </Typography>
            <p>Usuários cadastrados e ativos.</p>
            {
              <Typography className={classes.userOff} variant='h6' gutterbottom>
                {userQuantityTotal ?? 0} Ativos
              </Typography>
            }
            {<p>Usuários que frequentemente usam o sistema</p>}
          </div>
        </section>
      </Paper> */}

            <Grid item xs={12} lg={12}>
              <Paper variant='elevation' elevation={2} className={classes.paperContainer}>
                <Typography gutterbottom variant='subtitle1' align='left'>
                  Services finalizadas Diarias
                </Typography>

                <GrapichFinished />
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={4}>
      <Paper variant='elevation' elevation={2} className={classes.paperTrafficUsers}>
        <Typography gutterbottom variant='subtitle1' align='left'>
          Usuários cadastrados
        </Typography>
        <section className={classes.sectionTrafficUsers}>
          <div className={classes.divGraphicTrafficUsers}>
            <GraphicActiveUsers />
          </div>
          <div>
            <Typography className={classes.userActive} variant='h6' gutterbottom>
              {userQuantityTotal ?? 0} Reais
            </Typography>
            <p>Usuários cadastrados e ativos.</p>
            {
              <Typography className={classes.userOff} variant='h6' gutterbottom>
                {userQuantityTotal ?? 0} Ativos
              </Typography>
            }
            {<p>Usuários que frequentemente usam o sistema</p>}
          </div>
        </section>
      </Paper>
    </Grid> */}
          </Grid>
        </>
      )}
    </>
  );
};

export default Home;
