import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: '10px',
  },
  plusIcon: {
    color: '#512da8',
    maxWidth: '1.2rem',
  },

  minusIcon: {
    color: '#bcbcbc',
    maxWidth: '1.2rem',
  },

  tableCell: {
    maxWidth: '7rem',
    fontWeight: 500,
    fontSize: '15px',
  },

  subTotalTableCell: {
    maxWidth: '7rem',
    color: '#66BB6A',
    fontWeight: 500,
    fontSize: '18px',
  },

  totalGrid: {
    maxWidth: '90%',
  },

  total: {
    fontWeight: 600,
  },

  totalPrice: {
    marginLeft: '0.6rem',
    fontSize: '1rem',
    fontWeight: 500,
    color: 'green',
  },
}));
