import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Table, Select, Text, Dialog } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { invoiceService } from 'services';
import { api } from 'services/api';

import { AlterBillingExpirationDialog } from './AlterBillingExpirationDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Tipo de Billing' },
  { label: 'Prazo para expirar', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const RegisterBillingExpiration = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [billing, setBilling] = useState({});

  const [search, setSearch] = useState('');
  const [billingDataOnly, setBillingDataOnly] = useState([]);
  const [isWarningDialog, setIsWarningDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setBilling(item);
  };

  const [billingTypeOnly, , loadingBillingTypeOnly] = useQuery(
    invoiceService.listBillingTypeOnly,
    [],
  );

  const [billingExpirationDays, , loadingBillingExpirationDays, billingExpirationDaysRefetch] =
    useQuery(() => invoiceService.listBillingExpirationDays(), []);

  useEffect(() => {
    const noBillingTypeFound = !billingTypeOnly?.length && !loadingBillingTypeOnly;

    if (noBillingTypeFound) {
      setIsWarningDialog(true);
    }
  }, [billingTypeOnly, loadingBillingTypeOnly, billingExpirationDays]);

  const handleWarningDialogClose = () => {
    setIsWarningDialog(false);
  };

  const navigateToRegisterTypeBilling = () => {
    browserHistory.push('/general-registration/create-billingexpirationtype');
  };

  useEffect(() => {
    setBillingDataOnly(billingExpirationDays);
  }, [billingExpirationDays]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/invoices/billing/days', values);

      setBillingDataOnly((prevState) => [...prevState, data]);

      billingExpirationDaysRefetch();
      toast.success('Prazo para para Billing cadastrado com sucesso');

      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Erro ao cadastrar tempo de expiração da billing.',
      );
    } finally {
      setSubmitting(false);
    }
  };

  const billingOptionsOnly = (billingTypeOnly || []).map((billing) => ({
    value: billing.id,
    label: billing.tipoBilling,
  }));

  return (
    <>
      <Dialog open={isWarningDialog} onClose={handleWarningDialogClose}>
        <Dialog.Title onClose={handleWarningDialogClose}>Aviso</Dialog.Title>
        <Dialog.Content>
          <Typography>
            Não há nenhum <strong> Tipo de Billing</strong> cadastrada no sistema. Deseja cadastrar
            agora?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button variant='text' onClick={handleWarningDialogClose}>
            Fechar
          </Button>
          <Button onClick={navigateToRegisterTypeBilling}>Cadastrar</Button>
        </Dialog.Actions>
      </Dialog>

      <PageTitle>Expiração da Billing</PageTitle>
      <Formik
        initialValues={{ days: '', id_tipoBilling: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='id_tipoBilling'
                    label='Tipo de Billing'
                    variant='outlined'
                    size='small'
                    loading={loadingBillingTypeOnly}
                    options={billingOptionsOnly}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='days'
                    min={30}
                    label='Prazo para expirar'
                    variant='outlined'
                    size='small'
                    mask='number'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={300}
                    loading={loadingBillingExpirationDays}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {billingExpirationDays?.map((billing) => (
                      <TableRow key={billing.id}>
                        <TableCell>{billing.expiration_type.tipoBilling}</TableCell>
                        <TableCell align='center'>{billing.days}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, billing)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterBillingExpirationDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  billing={billing}
                  reloadBilling={billingExpirationDaysRefetch}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
