import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';
import XLSX from 'xlsx';

export const ProductionDepartmentReport = () => {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const [productionOpeningData, setProductionsOpening] = useState([]);

  const [productionSortingData, setProductionsSorting] = useState([]);

  const [productionRepairData, setProductionsRepair] = useState([]);

  const [productionQualityData, setProductionsQuality] = useState([]);

  const [productionCheckkitData, setProductionCheckkit] = useState([]);

  const [stepsData, setSteps] = useState([]);

  const [
    productionDepartmentOpening,
    ,
    loadingDepartmentOpening,
    refetchProductionsOpeningDataDate,
  ] = useQuery(
    () => serviceOrderService.productionDepartmentOpening(inputData, outputData),
    [inputData, outputData],
    [],
  );

  const [
    productionDepartmentSorting,
    ,
    loadingDepartmentSorting,
    refetchProductionsSortingDataDate,
  ] = useQuery(
    () => serviceOrderService.productionDepartmentSorting(inputData, outputData),
    [inputData, outputData],
    [],
  );

  const [productionDepartmentRepair, , loadingDepartmentRepair, refetchProductionsRepairDataDate] =
    useQuery(
      () => serviceOrderService.productionDepartmentRepair(inputData, outputData),
      [inputData, outputData],
      [],
    );

  const [
    productionDepartmentQuality,
    ,
    loadingDepartmentQuality,
    refetchProductionsQualityDataDate,
  ] = useQuery(
    () => serviceOrderService.productionDepartmentQuality(inputData, outputData),
    [inputData, outputData],
    [],
  );

  const [productionDepartmentCheckkit, , loadingCompliance, refetchProductionsCheckkitDataDate] =
    useQuery(
      () => serviceOrderService.productionDepartmentCheckkit(inputData, outputData),
      [inputData, outputData],
      [],
    );

  const [stepsMapping, , loadingStepsMapping, refetchStepsDataDate] = useQuery(
    () => serviceOrderService.stepsMapping(inputData, outputData),
    [inputData, outputData],
    [],
  );

  useEffect(() => {
    setProductionsOpening(productionDepartmentOpening);
  }, [productionDepartmentOpening]);

  useEffect(() => {
    setProductionsSorting(productionDepartmentSorting);
  }, [productionDepartmentSorting]);

  useEffect(() => {
    setProductionsRepair(productionDepartmentRepair);
  }, [productionDepartmentRepair]);

  useEffect(() => {
    setProductionsQuality(productionDepartmentQuality);
  }, [productionDepartmentQuality]);

  useEffect(() => {
    setProductionCheckkit(productionDepartmentCheckkit);
  }, [productionDepartmentCheckkit]);

  useEffect(() => {
    setSteps(stepsMapping);
  }, [stepsMapping]);

  const handleDownloadProductionDepartmentReport = async () => {
    const now = new Date();
    const worksheet1 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionOpeningData));
    const worksheet2 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionSortingData));
    const worksheet3 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionRepairData));
    const worksheet4 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionQualityData));
    const worksheet5 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(productionCheckkitData));
    const worksheet6 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(stepsData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        Abertura: worksheet1,
        Triagem: worksheet2,
        Reparo: worksheet3,
        QA: worksheet4,
        CheckKit: worksheet5,
        DE_PARA: worksheet6,
      },
      SheetNames: ['Abertura', 'Triagem', 'Reparo', 'QA', 'CheckKit', 'DE_PARA'],
    });
    XLSX.writeFile(workbook, `RelatorioProducaoSetor${now}.xlsx`);

    refetchProductionsOpeningDataDate();
    refetchProductionsSortingDataDate();
    refetchProductionsRepairDataDate();
    refetchProductionsQualityDataDate();
    refetchProductionsCheckkitDataDate();
    refetchStepsDataDate();
  };

  return (
    <>
      <PageTitle>Relatório produção por setor</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            loading={
              loadingStepsMapping &&
              loadingCompliance &&
              loadingDepartmentQuality &&
              loadingDepartmentRepair &&
              loadingDepartmentSorting &&
              loadingDepartmentOpening
            }
            fullWidth
            onClick={handleDownloadProductionDepartmentReport}
          >
            exportar relatório
          </Button>
        </Grid>
      </Grid>
      <Spacer size={15} />
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de entrada'
                component={DatePicker}
              />
            </Grid>
            <Spacer size={15} />
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de saída'
                component={DatePicker}
              />
            </Grid>

            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
