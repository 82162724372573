import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components';
import { Form, Formik } from 'formik';

import { FormRegistration } from './Form';
import { initialValues } from './formik';
import useStyles from './styles';
import { validationSchema } from './validation';

export const SingleRegistrationOfTaxCoupon = () => {
  const classes = useStyles();

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <FormRegistration />
              <Grid className={classes.btnSubmit} container spacing={2}>
                <Grid item xs={6} lg={2}>
                  <Button type='submit' disabled={!props.isValid || !props.dirty} fullWidth>
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
