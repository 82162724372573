import React, { createContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { ReactComponent as Trash } from 'assets/images/undraw_empty_cart_co35.svg';
import { Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { useGraphQL } from 'hooks/useGraphQL';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_PROVIDERS } from 'services/graphql/query/provider';
import { VALID_SERVICE_ORDER } from 'services/graphql/query/service-orders';

import { ImportProducts } from './components/importProduct';
import { ProductTable } from './components/ProductTable';
import { useStyles } from './styles';
import { validationSchema } from './validationSchema';

export const ReceivementContext = createContext();

export function Receivement() {
  const classes = useStyles();
  const [importing, setImporting] = useState(false);

  const [getProviderSelect, setProviderSelect] = useState(0);
  const [getResults, setResults] = useState();

  const [openImportInfo, setOpenImportInfo] = useState(false);

  const { data, loading } = useGraphQL(LIST_PROVIDERS);
  const [validProducts] = useMutation(VALID_SERVICE_ORDER, {
    onCompleted: (data) => {
      toast.success('Produtos importados com sucesso!');
      setImporting(false);
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  const providerOptions = (data?.listProviders || []).map((object) => ({
    value: object.id,
    label: object.description,
  }));

  const inputFileRef = useRef(null);
  const formikRef = useRef();

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleImportInfoOpen = () => {
    setOpenImportInfo(true);
  };

  const handleImportInfoClose = () => {
    setOpenImportInfo(false);
  };

  async function handleImportStore(e) {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              if (
                values[0] == null || // VOUCHER
                values[1] == null || // SKU
                values[3] == null || // APARELHO
                values[4] == null || // CONDIÇÃO
                values[5] == null || // IMEI
                values[7] == null || // DATA APROVAÇÃO
                values[10] == null || // VALOR TOTAL A PAGAR
                values[11] == null || // VENDEDOR
                values[12] == null || // CLIENTE
                values[13] == null || // CPF
                values[14] == null || // EMAIL
                values[15] == null || // TELEFONE
                values[20] == null || // CEP
                values[22] == null || // UF LOJA
                values[23] == null || // LOJA
                values[27] == null || // LOTE
                values[31] == null || // REDE
                values[32] == null // CODIGO DA LOJA
              ) {
                return null;
              }

              return {
                sinister: values[0],
                sku: values[1],
                product: values[3],
                condition: values[4],
                imei: values[5],
                aprove_date: values[7],
                value: values[10],
                seller: values[11],
                client_name: values[12],
                cgc: values[13],
                email: values[14],
                phone_number: values[15],
                zip_code: values[20],
                storeUF: values[22],
                store: values[23],
                batch: values[27],
                storeProvider: values[31],
                storeCode: values[32],
              };
            })
            .filter((item) => item !== null);

          const { data } = await validProducts({
            variables: { data: products, provider_id: getProviderSelect },
          });

          setResults(data?.validProductWithServiceOrder);
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
    }
  }

  const handleReset = () => {
    setResults();
    formikRef?.current?.resetForm();
    inputFileRef.current.value = '';
  };

  return (
    <ReceivementContext.Provider value={{ handleImportClick }}>
      <PageTitle>Recebimento</PageTitle>

      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />
      <Formik
        innerRef={formikRef}
        initialValues={{ provider_id: '' }}
        validationSchema={validationSchema}
        onSubmit={handleImportInfoOpen}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                <Field
                  variant='outlined'
                  label='Fornecedor'
                  name='provider_id'
                  size='small'
                  options={providerOptions}
                  component={Select}
                  loading={loading || importing}
                  onChange={setProviderSelect}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <ImportProducts />
                {/* <Button
                  fullWidth
                  color='primary'
                  onClick={() => handleImportClick()}
                  disabled={!props.isValid || !props.dirty}
                  loading={importing | loading}
                >
                  Importar Produtos
                </Button> */}
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {getResults && (
        <ProductTable
          data={getResults}
          setLoading={setImporting}
          loading={importing}
          reset={handleReset}
          setResults={setResults}
        />
      )}
      {!getResults && !importing ? (
        <Grid className={classes.containerImg} container>
          <Trash className={classes.trashImage} />
        </Grid>
      ) : !getResults && importing ? (
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          style={{ minHeight: '70vh' }}
        >
          <CircularProgress />
        </Grid>
      ) : (
        <></>
      )}
    </ReceivementContext.Provider>
  );
}
