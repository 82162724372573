import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { invoiceService } from 'services';
import { serviceOrderService } from 'services/serviceOrder';

import { ServiceOrderContext } from '../../..';
import useStyles from '../styles';
import TableGeneral from './Table';
import { OsTabs } from './Tabs';

const invoicesOptions = {
  NotaRemessaEntrada: 1,
  NotaRemessaSaida: 2,
  NotaDeVenda: 3,
  NotaDeServico: 4,
  NotaDeAnalise: 5,
  NotaDeReparo: 6,
};

export const ServiceOrder = () => {
  const classes = useStyles();

  const { serviceData, logs, setLogs, setLoadingLogs } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  const [generatingEntraceInvoice, setGeneratingEntraceInvoice] = useState(false);

  const [invoiceEntrance, , , refechInvoiceEntrace] = useQuery(
    () =>
      invoiceService.getInvoiceByOsIdAndInvoiceTypeId(
        serviceFromGaia?.id,
        invoicesOptions.NotaRemessaEntrada,
      ),
    [],
  );
  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };
  const [invoiceOutbound, , ,] = useQuery(
    () =>
      invoiceService.getInvoiceByOsIdAndInvoiceTypeId(
        serviceFromGaia?.id,
        invoicesOptions.NotaRemessaSaida,
      ),
    [],
  );

  const GenerateEntranceInvoice = async () => {
    await serviceOrderService.invoiceEmissionEntrance(serviceFromGaia?.number).then((response) => {
      if (response?.data?.retorno.status != 'Erro') toast.success(`Nota gerada com sucesso`);
      else toast.error(response?.data?.retorno?.registros?.registro?.erros[0].erro);
    });

    // setTimeout(() => {
    //   window.location.reload();
    // }, 4000);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Ordem de Serviço
      </Typography>

      <Grid className={classes.container} container spacing={2}>
        <Grid item xs={6} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Os Vinculada (OS ANTIGA)'
            value={serviceFromGaia?.old_service}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Os sistema de terceiros'
            value={serviceFromGaia?.vinc_service}
            fullWidth
            inputProps={{ editable: false }}
            onInput={toInputUppercase}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Processo'
            value={serviceFromGaia?.process?.description}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Bounce'
            value={serviceFromGaia?.bounce == true ? 'SIM' : 'NÃO'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={6} md={4} lg={1}>
          <TextField
            variant='outlined'
            size='small'
            label='Atendimento'
            value={
              serviceFromGaia?.warranty_type === 'Out of Warranty'
                ? 'Fora de Garantia'
                : 'Em Garantia'
            }
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={1}>
          <TextField
            variant='outlined'
            size='small'
            label='Tag de Serviço In'
            value={serviceFromGaia?.warranty_type == 'Out of Warranty' ? 'FG' : 'Garantia'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Número do sinistro'
            value={serviceFromGaia?.sinister ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Objeto IN'
            value={serviceFromGaia?.object_in ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Objeto OUT'
            value={serviceFromGaia?.object_out ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Seguradora'
            value={serviceFromGaia?.SubInsurancePll?.name}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Etapa'
            value={serviceFromGaia?.status.description}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Número da NF de Entrada'
            value={invoiceEntrance?.number ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        <Grid item xs={3} md={3} lg={3}>
          <TextField
            variant='outlined'
            size='small'
            label='Chave da NF de Entrada'
            value={invoiceEntrance?.nfse_number ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={2}>
          <TextField
            variant='outlined'
            size='small'
            label='Número da NF de Saída'
            value={invoiceOutbound?.number ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>
        <Grid item xs={6} md={3} lg={4}>
          <TextField
            variant='outlined'
            size='small'
            label='Chave da NF de Saida'
            value={invoiceOutbound?.nfse_number ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        <Grid item xs={6} md={3} lg={4}>
          <TextField
            variant='outlined'
            size='small'
            label='Tipo atendimento seguradora'
            value={serviceFromGaia?.insurance_wty ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        <Grid item xs={6} md={3} lg={4}>
          <TextField
            variant='outlined'
            size='small'
            multiline
            label='Laudo'
            value={serviceFromGaia?.technical_report ?? '-'}
            fullWidth
            inputProps={{ editable: false }}
          />
        </Grid>

        {(invoiceEntrance?.nfse_number == '' && serviceFromGaia?.process?.id == 1) ||
        (invoiceEntrance?.nfse_number == undefined && serviceFromGaia?.process?.id == 1) ||
        (invoiceEntrance?.nfse_number == undefined && serviceFromGaia?.process?.id == 3) ||
        (invoiceEntrance?.nfse_number == '' && serviceFromGaia?.process?.id == 3) ? (
          <Grid item xs={6} md={3} lg={4}>
            <Button
              disabled={generatingEntraceInvoice}
              onClick={() => GenerateEntranceInvoice()}
              color='secondary'
            >
              Gerar NF de remessa de entrada
            </Button>
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={8}>
          <OsTabs />
        </Grid>
        <Grid item xs={12} md={6} lg={4} fullWidth>
          <TableGeneral />
        </Grid>
      </Grid>
    </>
  );
};
