/* eslint-disable no-restricted-imports */
import React, { useContext, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
  IconButton,
  Checkbox,
} from '@material-ui/core';

import { Pagination } from '@mui/material';
import { FormatDate } from 'components';

import DownloadIcon from '@material-ui/icons/CloudDownload';

import { WarehouseExpeditionContext } from '..';

const columns = [
  { label: '', align: 'center' }, // Coluna para o checkbox
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'CÓDIGO PEDIDO', align: 'center' },
  { label: 'MARKETPLACE', align: 'center' },
  { label: 'STATUS', align: 'center' },
  { label: 'DATA', align: 'center' },
  { label: 'CLIENTE', align: 'center' },
  { label: 'NF', align: 'center' },
  { label: 'CHAVE NF', align: 'center' },
  { label: 'XML', align: 'center' },
];

export function WarehouseExpeditionTable() {
  const {
    b2cOrders,
    b2cLoading,
    search,
    setSelectedIds,
    selectedIds,
    selectedItems,
    setSelectedItems,
  } = useContext(WarehouseExpeditionContext);

  const [page, setPage] = useState(1);

  const rowsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenXml = (xmlUrl) => {
    window.open(xmlUrl, '_blank');
  };

  const handleCheckboxChange = (item) => {
    setSelectedItems(item);
    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(item?.anymarketId)) {
        return prevSelected.filter((id) => id !== item?.anymarketId);
      } else {
        return [...prevSelected, item?.anymarketId];
      }
    });
  };

  if (b2cLoading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const filteredOrders = b2cOrders.filter(
    (order) =>
      order.anymarketId.toLowerCase().includes(search.toLowerCase()) ||
      order.orderCode.toLowerCase().includes(search.toLowerCase()) ||
      order.client.name.toLowerCase().includes(search.toLowerCase()),
  );

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = filteredOrders.slice(startIndex, startIndex + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align} style={{ fontWeight: 'bold' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(paginatedResults || []).map((item, index) => (
            <TableRow key={index}>
              <TableCell align='center'>
                <Checkbox
                  checked={selectedIds.includes(item.anymarketId)}
                  onChange={() => handleCheckboxChange(item)}
                />
              </TableCell>
              <TableCell align='center'>{item.anymarketId}</TableCell>
              <TableCell align='center'>{item.orderCode}</TableCell>
              <TableCell align='center'>{item.marketplace}</TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>
                {item.businessStatus.description}
              </TableCell>
              <TableCell align='center'>
                <FormatDate>{item.createdAt}</FormatDate>
              </TableCell>
              <TableCell align='center'>{item.client.name}</TableCell>
              <TableCell align='center'>{item.B2CInvoices.number}</TableCell>
              <TableCell align='center'>{item.B2CInvoices.key}</TableCell>
              <TableCell align='center'>
                <IconButton color='primary' onClick={() => handleOpenXml(item.B2CInvoices.s3)}>
                  <DownloadIcon fontSize='large' />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(b2cOrders.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='secondary'
        />
      </Grid>
    </TableContainer>
  );
}
