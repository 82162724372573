import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services/budget';
import { mailServices } from 'services/mail';
import { mailObjectService } from 'services/mailObject';

import { TableWaiting } from './Table';
import { validationSchema } from './validation';

export const Protocol = () => {
  const [search, setSearch] = useState('');
  const [postData, setPostData] = useState(new Date());

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [byInvoice, setByInvoice] = useState(false);

  const [mailContractsData, , loadingMailContracts] = useQuery(mailServices.listMailContracts, []);

  const handleDownloadPostCard = async (service_order_number) => {
    const { data } = await budgetService.getPostCard(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const mailContractOptions = (mailContractsData || []).map((mailContract) => {
    return {
      value: mailContract.id,
      label:
        mailContract.contract_number +
        ' ' +
        mailContract.contract_method +
        ' ' +
        mailContract.additional_information,
    };
  });

  const [validOutObjectsData, , loadingValidOutObjectsData] = useQuery(
    () => mailServices.listMailContractsGeneral(page, rowsPerPage, search, postData),
    [page, rowsPerPage, search, postData],
  );

  const handleDownloadTagsClick = async (service_order_number, mail_contract_id) => {
    const { data } = await mailObjectService.impressMinute(service_order_number, mail_contract_id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await mailServices.createValidOutObject(values);
      resetForm();
      toast.success('Objeto gerado com sucesso!');
      await handleDownloadTagsClick(values.service_order_number, values.mail_contract_id);
      await handleDownloadPostCard(values.service_order_number);
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao cadastrar nota.');
    } finally {
      setSubmitting(false);
    }
  };

  const isByInvoice = () => {
    if (byInvoice) {
      setByInvoice(false);
    } else {
      setByInvoice(true);
    }
  };

  return (
    <>
      <PageTitle>Minuta</PageTitle>

      <Formik
        initialValues={{
          service_order_number: '',
          mail_contract_id: 0,
          reprint_service: '',
          prefix_country: 'BR',
          nf_type: 'F',
          isReenvio: false,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Field
                  variant='outlined'
                  fullWidth
                  size='small'
                  placeholder='Service'
                  name='service_order_number'
                  component={Text}
                  endAdornment={<SearchIcon />}
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid item xs={12} lg={1} style={{ fontSize: 15 }}>
                <Field
                  component={Checkbox}
                  name='isReenvio'
                  label='Reenvio'
                  onClick={isByInvoice}
                />
                <Typography variant='h7' gutterBottom>
                  Reenvio
                </Typography>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  options={mailContractOptions}
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Contrato'
                  name='mail_contract_id'
                  loading={loadingMailContracts}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  fullWidth
                  color='primary'
                  type='submit'
                  disabled={!props.dirty || !props.isValid}
                >
                  GERAR OBJETO DE SAÍDA
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button fullWidth variant='outlined'>
                  Limpar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={50} />
            <Grid item xs={12}>
              <TableWaiting
                validOutObjects={validOutObjectsData?.results}
                loadingValidObjects={loadingValidOutObjectsData}
                page={page}
                handleChangePage={handleChangePage}
                handleSearchChange={handleSearchChange}
                search={search}
                total={validOutObjectsData?.totalCount ?? 0}
                postData={postData}
                setPostData={setPostData}
              />
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
