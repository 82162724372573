import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  street: yup.string().required(),
  street_number: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  email: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  zipcode: yup.string().required(),
  complement: yup.string(),
  residential_phone_number: yup.string(),
  phone_number: yup.string(),
  cgc: yup
    .string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
});

export const initialValues = {
  cgc: '',
  street: '',
  street_number: '',
  zipcode: '',
  name: '',
  city: '',
  state: '',
  email: '',
  district: '',
  complement: '',
  active: '',
};
