import React, { useState, useEffect, useContext, useCallback } from 'react';
import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Spacer, FormatMoney, Button, Table } from 'components';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services';

import { BudgetCartContext } from '../..';
import {
  addServiceToCartAction,
  removeServiceFromCartAction,
  updateAmountServiceAction,
} from '../store/actions';
import { useStyles } from './styles';

const columns = [
  { label: 'Descrição' },
  { label: 'Serviço Adicional' },
  { label: 'Valor' },
  { label: 'Ação', align: 'center', width: 180 },
];

const columnsService = [
  { label: 'DESCRIÇÃO' },
  { label: 'SERVIÇO ADICIONAL' },
  { label: 'VALOR' },
  { label: 'QUANTIDADE' },
  { label: 'SUBTOTAL' },
  { label: '', align: 'center' },
];

export const initialServiceCartState = [];

export const AdditionalServices = () => {
  const classes = useStyles();

  const {
    state,
    dispatch,
    totalCart,
    stateService,
    dispatchService,
    totalCartService,
    setTotalCartService,
  } = useContext(BudgetCartContext);

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [additionalServices, , loadingAdditionalServices, refetchAdditionalServices] = useQuery(
    budgetService.listAdditionalServices,
    [],
  );

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    budgetService.listAdditionalServiceProducts,
    [],
  );

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.description,
      label: additionalServiceProduct.description,
    }),
  );

  const formattedAdditionalServices = (additionalServices || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredAdditionalServices = (formattedAdditionalServices || []).filter((services) => {
    const lowerDescription = services.description?.toLowerCase();
    const upperDescription = services.description?.toUpperCase();
    const lowerProductDescription =
      services?.additional_service_product?.description?.toLowerCase();
    const upperProductDescription =
      services?.additional_service_product?.description?.toUpperCase();

    return (
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerProductDescription?.includes(search) ||
      upperProductDescription?.includes(search)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalItens = useCallback(
    (setTotalCartService) => {
      const total = stateService.reduce((total, { sub_total }) => {
        return total + sub_total;
      }, 0);

      if (!stateService.length) {
        return setTotalCartService(0);
      }

      setTotalCartService(total);
    },
    [stateService],
  );

  useEffect(() => {}, [
    formattedAdditionalServices,
    filteredAdditionalServices,
    refetchAdditionalServices,
  ]);

  useEffect(() => {
    totalItens(setTotalCartService);
  }, [setTotalCartService, totalItens]);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} md={5} lg={5}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            endAdornment={<SearchIcon />}
            onChange={handleSearchChange}
            value={search}
          />
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} md={12} lg={5}>
          <Paper>
            <Typography align='center' variant='h6' gutterBottom>
              Serviços adicionais
            </Typography>
            <Table
              headers={columns}
              emptyMessage='Nenhum registro encontrado.'
              striped
              loading={loadingAdditionalServices}
              maxHeight={500}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredAdditionalServices?.map((additionalService) => (
                <TableRow key={additionalService.id}>
                  <TableCell>{additionalService?.description}</TableCell>
                  <TableCell>
                    {additionalService?.additional_service_product?.description}
                  </TableCell>
                  <TableCell>
                    <FormatMoney>{additionalService?.service_price}</FormatMoney>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      onClick={() => addServiceToCartAction(additionalService, dispatchService)}
                      className={classes.buttonAddToCart}
                    >
                      Adicionar Item
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={7}>
          <Paper>
            <Typography align='center' variant='h6' gutterBottom>
              Carrinho - Orçamento de Serviços Adicionais
            </Typography>
            <Table
              headers={columnsService}
              emptyMessage='Nenhum registro encontrado.'
              striped
              maxHeight={500}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {stateService?.map((additionalService) => (
                <TableRow key={additionalService.id}>
                  <TableCell className={classes.tableCell}>
                    {additionalService?.description}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <FormatMoney>{additionalService?.service_price}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {additionalService?.additional_service_product?.description}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <IconButton
                          onClick={() =>
                            updateAmountServiceAction(
                              {
                                amount: additionalService.amount - 1,
                                service_id: additionalService.id,
                              },
                              dispatchService,
                            )
                          }
                        >
                          <FaMinusCircle className={classes.minusIcon} />
                        </IconButton>
                      </Grid>
                      <Grid className={classes.qntd} item xs={5}>
                        <Text
                          number='number'
                          variant='outlined'
                          value={additionalService.amount}
                          onChange={(e) =>
                            updateAmountServiceAction(
                              {
                                amount: e.target.value == 0 ? 1 : Number(e.target.value),
                                service_id: additionalService.id,
                              },
                              dispatchService,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          onClick={() =>
                            updateAmountServiceAction(
                              {
                                amount: additionalService.amount + 1,
                                service_id: additionalService.id,
                              },
                              dispatchService,
                            )
                          }
                        >
                          <FaPlusCircle className={classes.plusIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.subTotalTableCell}>
                    <FormatMoney>{additionalService?.sub_total}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.subTotalTableCell}>
                    <IconButton
                      onClick={() =>
                        removeServiceFromCartAction(additionalService, dispatchService)
                      }
                    >
                      <FaTrash size='18' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.total}>TOTAL - COMPONENTES:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalCart}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.total}>TOTAL - SERVIÇOS ADICIONAIS:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalCartService}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.totalAll}>TOTAL - GERAL:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalCartService + totalCart}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
