import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button, DatePicker } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { collaboratorsService } from 'services';
import XLSX from 'xlsx';

export const PitziAgingReport = () => {
  const today = new Date();

  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());

  const [agingData, setAging] = useState([]);
  const [agingRepairtData, setRepairAging] = useState([]);

  const [agingDataApple, setAgingApple] = useState([]);
  const [agingRepairtAppleData, setRepairAgingApple] = useState([]);

  const [aging, , loadingAging, refetchAgingDataDate] = useQuery(
    () => collaboratorsService.listPitziAgingReport(inputData, outputData),
    [inputData, outputData],
  );

  const [agingApple, , loadingAgingApple, refetchAgingAppleDataDate] = useQuery(
    () => collaboratorsService.listPitziAgingReportApple(inputData, outputData),
    [inputData, outputData],
  );

  useEffect(() => {
    setAging(aging);
    setAgingApple(agingApple);
  }, [aging, agingApple]);

  const handleDownloadGeneralStockRelatory = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(agingData));
    const worksheet2 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(agingDataApple));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        Pitzi_Motorola: worksheet,
        Pitzi_Apple: worksheet2,
      },
      SheetNames: ['Pitzi_Motorola', 'Pitzi_Apple'],
    });
    XLSX.writeFile(workbook, `RelatorioPitziAging${now}.xlsx`);

    refetchAgingDataDate();
    refetchAgingAppleDataDate();
  };

  return (
    <>
      <PageTitle>Relatório Pitzi Aging</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            loading={loadingAging && loadingAgingApple}
            fullWidth
            onClick={handleDownloadGeneralStockRelatory}
          >
            exportar relatório
          </Button>
        </Grid>
      </Grid>
      <Spacer size={15} />
      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de entrada'
                component={DatePicker}
              />
            </Grid>
            <Spacer size={15} />
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de saída'
                component={DatePicker}
              />
            </Grid>

            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
