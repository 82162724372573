import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Dialog, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { LIST_STATUS, UPDATED_SERVICE_STATUS } from 'services/graphql/query/status';

import { validationSchema } from './validation';

export function UpdatedStatus({ isOpen = false, handleClose, data, refetch }) {
  if (!isOpen) {
    return <></>;
  }

  const [submit] = useMutation(UPDATED_SERVICE_STATUS, {
    onCompleted: () => {
      toast.success('Status atualizado com sucesso');
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao atualizar o status';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const statusList = useQuery(LIST_STATUS, {
    variables: { sinister: data?.sinister },
    fetchPolicy: 'network-only',
  });

  const statusOptions = (statusList?.data?.listStatus || []).map((item) => ({
    value: item?.id,
    label: item?.description,
  }));

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await submit({
        variables: {
          sinister: data?.sinister,
          new_status: values.new_status,
          reason: values.reason,
        },
      });
    } catch (error) {
      toast.error('Erro ao atualizar o status');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Atualizar status: {data.sinister}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ new_status: '', reason: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={12}>
                  <Field
                    variant='outlined'
                    label='Novo status'
                    name='new_status'
                    size='small'
                    options={statusOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Field
                    variant='outlined'
                    label='Motivo'
                    name='reason'
                    size='small'
                    multiline
                    rows={5}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Button
                    type='submit'
                    fullWidth
                    disabled={!props.dirty || !props.isValid}
                    loading={props.isSubmitting}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
