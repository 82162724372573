/* eslint-disable react/no-unknown-property */
import React, { createContext, useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import WarningIcon from '@mui/icons-material/Warning';
import Alert from '@mui/material/Alert';
import { Table } from 'components';
import { Button, Text } from 'components';
import { Tutorial } from 'components/Tutorial';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';
import { warrantyService } from 'services/warranty';
import { useContextSelector } from 'use-context-selector';

import { tutorialSteps } from '../tutorial';
import { useStyles } from './styles';
import { DebitDialog } from './TableGeneral/DebitDialog';
import { validationSchema } from './validation';

const columns = [{ label: 'Mensagens do sistema ' }];
export const LogMessageContext = createContext('logs');

const WarrantyValidation = () => {
  const classes = useStyles();

  const [disableBills, setHasBilling] = useState(false);
  const [service, setService] = useState();
  const [messages, setMessages] = useState();

  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const userViewedTutorials = useContextSelector(AuthContext, ({ user }) => user.viewed_tutorials);
  let tutorialName = '';
  const logs = {
    message: '',
  };

  const disableBill = () => {
    if (disableBills) {
      setHasBilling(false);
    } else {
      setHasBilling(true);
    }
  };

  const serviceOrderNumberRef = useRef(null);

  const [dataApi, , loadingAdditionalServiceProducts] = useQuery(
    () => warrantyService.WarrantyValidation(values.service_number, values.checkBill),

    [],
  );

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [logMessageWerranty, setLogMessageWarranty] = useState([]);

  const [attachmentWarranty, setAttachment] = useState([]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (service) => {
    setIsDetailsState.toTrue();
    setService(service);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      let billOrNot = '';
      {
        disableBills ? (billOrNot = '0') : (billOrNot = '1');
      }

      console.log(billOrNot);

      const { data } = await warrantyService.WarrantyValidation(values.service_number, billOrNot);

      setLogMessageWarranty(data.messageValidationSteps);
      setAttachment(data.fileNames);

      console.log('Data: ', data);

      handleOpenInformation(values.service_number);

      toast.info('Validação concluida, consulte o painel abaixo.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar validação.');
    } finally {
      setSubmitting(false);
    }
  };

  const cleanTableMessages = () => {
    setLogMessageWarranty([]), setAttachment([]);
  };

  const onTutorialFinish = () => {
    accountService.addViewedTutorial('Garantia');
  };

  return (
    <>
      {userViewedTutorials?.map((tutorial) => {
        console.log(tutorial);
        if (tutorial == 'garantia') {
          tutorialName = 'garantia';
        }
      })}
      {tutorialName == 'garantia' ? (
        <></>
      ) : (
        <Tutorial steps={tutorialSteps} onFinish={onTutorialFinish} />
      )}
      <PageTitle>Validar garantia</PageTitle>
      <Formik
        initialValues={{ service_number: '', checkBill: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  id='warranty-validation'
                  variant='outlined'
                  fullWidth
                  size='small'
                  label='Service'
                  name='service_number'
                  endAdornment={<SearchIcon />}
                  inputProps={{ maxLength: 10, minlength: 10 }}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  id='warranty-validation-button'
                  color='primary'
                  fullWidth
                  type='submit'
                  disabled={!props.dirty || !props.isValid}
                >
                  Análisar
                </Button>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Button
                  size='large'
                  variant='outlined'
                  fullWidth
                  onClick={() => cleanTableMessages()}
                >
                  Limpar
                </Button>
              </Grid>
              <Grid item xs={12} lg={12}>
                <LogMessageContext.Provider value={{ logMessageWerranty, attachmentWarranty }}>
                  <Table
                    headers={columns}
                    striped
                    maxHeight
                    disableNumeration
                    emptyMessage='Nenhum registro encontrado.'
                    loading={props.isSubmitting}
                  >
                    {logMessageWerranty.map((logMessage) => (
                      <TableRow key={logMessage.type}>
                        <TableCell>
                          <Alert severity={logMessage.type == 1 ? 'success' : 'error'}>
                            {console.log('Message: ', logMessage)}
                            {console.log('attachmentWarranty: ', attachmentWarranty)}
                            <strong>{logMessage.message}</strong>
                          </Alert>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </LogMessageContext.Provider>
              </Grid>
              <DebitDialog
                open={isDetailsOpen}
                handleClose={handleCloseInformation}
                service={service}
                messages={logMessageWerranty}
              />
            </Grid>
            <div className={classes.containerLegend}>
              <WarningIcon color='warning' />
              <p variant='caption' className={classes.p}>
                Caso deseje emitir as notas fiscais referente a service, selecione o campo.
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default WarrantyValidation;
