import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  street: yup.string().required(),
  street_number: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  email: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  zipcode: yup.string().required(),
  complement: yup.string(),
  residential_phone_number: yup.string(),
  phone_number: yup.string().required(),
});

export const initialValues = {
  cnpj: '',
  razao_social: '',
  atualizado_em: '',
  capital_social: '',
  inscricao_estadual: '',
  pais_id: '',
  country: '',
  zipcode: '',
  city: '',
  state: '',
  district: '',
  neighborhood: '',
  street: '',
  number: '',
  complement: '',
  county_code: '',
};
