import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button, Spacer } from 'components';
import { Field, useFormikContext } from 'formik';

export const DialogTechnicalReport = ({ open, handleClose }) => {
  if (!open) return null;

  const { handleSubmit, isSubmitting, isValid, dirty, values, setFieldValue } = useFormikContext();

  return (
    <Dialog
      maxWidth='md'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>Laudo Técnico para o Orçamento</Dialog.Title>
      <Dialog.Content>
        <Grid container>
          <Grid item xs={12}>
            <Field
              name='technical_report'
              label='Laudo Técnico'
              variant='outlined'
              size='small'
              multiline
              rows={3}
              component={Text}
              inputProps={{ maxLength: 100 }}
              onBlur={(e) => setFieldValue('technical_report', e.target.value)}
            />
          </Grid>
        </Grid>

        <Spacer size={8} />

        <Grid container>
          <Button onClick={handleClose} color='primary' variant='text'>
            Fechar
          </Button>
          <Button
            type='submit'
            color='primary'
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!isValid || !dirty}
          >
            Confirmar Envio
          </Button>
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
