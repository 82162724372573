import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  gridDeliveryImg: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  deliveryImg: {
    width: '40%',
  },
}));
