import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Text, Table, FormatDate, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { RegisterSubDeviceDefectDialog } from './RegisterSubDeviceDefectDialog';
import { validationSchema } from './validation';

const columns = [
  { label: 'Sigla' },
  { label: 'Descrição' },
  { label: 'Status' },
  { label: 'Data de Criação' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const RegisterSubDeviceDefect = () => {
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();

    setSubDeviceDefect(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [search, setSearch] = useState('');

  const [subDevicesDefects, setSubDevicesDefects] = useState([]);
  const [subDeviceDefect, setSubDeviceDefect] = useState({});

  const [deviceData, , loadingDevice, refetchDevice] = useQuery(
    () => serviceOrderService.listDeviceDefects(),
    [],
  );

  const deviceDefectOptions = (deviceData || []).map((status) => ({
    value: status.id,
    label: status.initials + ' - ' + status.description,
  }));

  const [subDeviceData, , loadingSubDevice, refetchSubDevice] = useQuery(
    () => serviceOrderService.listSubDeviceDefects(),
    [],
  );

  useEffect(() => {
    setSubDevicesDefects(subDeviceData);
  }, [subDeviceData]);

  const formattedSubDevicesDefect = (subDeviceData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredSubDevicesDefect = formattedSubDevicesDefect.filter((device) => {
    const upperDescription = device.description?.toUpperCase();
    const lowerDescription = device.description?.toLowerCase();

    return upperDescription?.includes(search) || lowerDescription?.includes(search);
  });

  useEffect(() => {}, [formattedSubDevicesDefect, filteredSubDevicesDefect]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await serviceOrderService.createSubDeviceDefects(values);

      toast.success('Sub Defeito de dispositivo cadastrado com sucesso');
      refetchSubDevice();
      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar sub defeito de dispositivo.');
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Sub Defeito de Dispositivos</PageTitle>
      <Formik
        initialValues={{ device_defect_id: null, initials: '', description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='device_defect_id'
                    label='Defeito do Dispositivo'
                    variant='outlined'
                    size='small'
                    component={Select}
                    options={deviceDefectOptions}
                    loading={loadingDevice}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <Field
                    name='initials'
                    label='Sigla'
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='description'
                    label='Sub Defeito do dispositivo'
                    variant='outlined'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='search'
                    label='Pesquisar'
                    variant='outlined'
                    size='small'
                    endAdornment={<SearchIcon />}
                    component={Text}
                    value={search}
                    onChange={handleSearchChange}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingSubDevice}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Nenhum serviço encontrado.'
                    // onActionsClick={handleOpenInformation}
                    data={filteredSubDevicesDefect}
                    maxHeight
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredSubDevicesDefect.map((subDevice) => (
                      <TableRow key={subDevice.id}>
                        <TableCell>{subDevice.description}</TableCell>
                        <TableCell>{subDevice.active ? 'Ativo' : 'Inativo'}</TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy'>{subDevice.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, subDevice)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <RegisterSubDeviceDefectDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  subDeviceDefect={subDeviceDefect}
                  reloadSubDevice={refetchSubDevice}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
