import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Button, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'N° da Service', align: 'center' },
  { label: 'Processo ', align: 'center' },
  { label: 'Atendimento ', align: 'center' },
  { label: 'Part-Number ', align: 'center' },
  { label: 'Quantidade ', align: 'center' },
  { label: 'Nome ', align: 'center' },
];

export const FinalizeCMP = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };
  // const [batchDataOnly, setBillingDataOnly] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [labors, setLabors] = useState([]);

  const [laborsData, , loadingLabors, refetchLabors] = useQuery(
    () => api.get('/service-orders/isAwaitingParts'),
    [],
  );

  useEffect(() => {
    setLabors(laborsData);
  }, [laborsData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/service-orders/setIsFinishedCMP', values);

      // setBillingDataOnly(batchDataOnly);

      refetchLabors();
      toast.success('Parts on holding Finalizado com sucesso');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao finalizar Parts on holding.');
    } finally {
      setSubmitting(false);
    }
  };

  const batchsOptions = (labors || []).map((batch) => ({
    value: batch.number,
    label: batch.number,
  }));

  const filteredBudgetsApproved = labors?.filter((budget) => {
    const upperService = budget?.number?.toUpperCase();
    const lowerService = budget?.number?.toLowerCase();

    return upperService?.includes(search) || lowerService?.includes(search);
  });

  return (
    <>
      <PageTitle>Baixa de PARTS ON HOLDING</PageTitle>
      <Formik
        initialValues={{ service_number: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='service_number'
                    label='Service'
                    variant='outlined'
                    size='small'
                    // loading={loadingbatchDataOnly}

                    component={Text}
                  />
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4}>
                    <Text
                      label='Pesquisar'
                      size='small'
                      variant='outlined'
                      endAdornment={<SearchIcon />}
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Gravar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredBudgetsApproved?.map((batch) => (
                      <TableRow key={batch.id}>
                        <TableCell align='center'>{batch.number}</TableCell>
                        <TableCell align='center'>{batch.description}</TableCell>
                        <TableCell align='center'>{batch.warranty_type}</TableCell>
                        <TableCell align='center'>{batch.part_number}</TableCell>
                        <TableCell align='center'>{batch.quantity}</TableCell>
                        <TableCell align='center'>{batch.name}</TableCell>
                        <TableCell align='center'>{batch.name}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
