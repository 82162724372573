import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Field, Formik } from 'formik';
import { componentService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  quantity: yup.number().required(),
});

const initialValues = {
  quantity: '',
};

export const OsPartsRemoveFromOsDialog = ({
  open,
  handleClose,
  service,
  stockRequisition,
  reloadBudgets,
}) => {
  if (!open) return null;

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await componentService.updatePartsFromOs(
        stockRequisition.stock_requisition_id,
        stockRequisition.component_id,
        Number(values.quantity),
      );

      toast.success('Requisição de componentes e estoque atualizado com sucesso.');
      resetForm();
      handleClose();
      reloadBudgets();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Erro ao remover o componente da requisição da OS.',
      );
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  console.log('So true: ', stockRequisition);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, dirty, isValid }) => (
        <Dialog open={open} onClose={handleClose} maxWidth='lg'>
          <Dialog.Title onClose={handleClose}>
            Deseja remover a requisição da peça: {stockRequisition?.part_number}
          </Dialog.Title>
          <Dialog.Content>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>PART-NUMBER:</Typography>
                <Typography>{stockRequisition?.part_number}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>DESCRIÇÃO:</Typography>
                <Typography>{stockRequisition?.description}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant='subtitle2'>QUANTIDADE REQUISITADA:</Typography>
                <Typography>{stockRequisition?.amount}</Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography variant='subtitle2'>QUANTIDADE A SER REMOVIDA:</Typography>
                <Field
                  size='small'
                  variant='outlined'
                  name='quantity'
                  label='Quantidade'
                  fullWidth
                  component={Text}
                />
              </Grid>
            </Grid>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={handleClose} variant='text' color='default'>
              Fechar
            </Button>
            <Button
              color='secondary'
              onClick={handleSubmit}
              type='submit'
              disabled={!dirty || !isValid}
            >
              Confirmar
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  );
};
