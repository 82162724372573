import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Text, Dialog, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';

export const ViewAttachReport = () => {
  const [service, setService] = useState();
  const [sinister, setSinister] = useState('');
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function DownloadPDF({ url, fileName }) {
    const handleClick = async () => {
      await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => console.error(error));
    };
    return <Button onClick={handleClick}>Download PDF</Button>;
  }

  const handleSubmit = async (values) => {
    try {
      const { data } = await serviceOrderService.getReportFile(values);

      setService(values);
      setSinister(data[0].report_insurance);

      handleClickOpen();
      toast.success('Consulta realizada com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao consultar laudo da service');
    }
  };
  return (
    <>
      <PageTitle>Visualizar Laudo</PageTitle>
      <Formik initialValues={{ service_number: '' }}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={6}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  inputProps={{ maxLength: 50, minlength: 3 }}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <Button
                  onClick={() => handleSubmit(props.values.service_number)}
                  disabled={!props.isValid || !props.dirty}
                  fullWidth
                >
                  Buscar
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              {service ? (
                <>
                  <Dialog onClose={handleClose} maxWidth='md' open={open}>
                    <Dialog.Title>Visualizar Anexo</Dialog.Title>
                    <Dialog.Content>
                      {/* <DownloadPDF fileName='laudo' url={sinister} /> */}
                      <a
                        href={sinister}
                        target='_blank'
                        // download
                        style={{ textDecoration: 'none', color: 'black' }}
                        rel='noreferrer'
                      >
                        Clique aqui para abrir seu comprovante
                      </a>
                    </Dialog.Content>
                    <Dialog.Actions>
                      <Button onClick={handleClose} variant='text'>
                        Fechar
                      </Button>
                    </Dialog.Actions>
                  </Dialog>
                </>
              ) : (
                <></>
              )}
            </Grid>

            <Grid container spacing={2}></Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
