import { api } from 'services/api';

export class WarehouseService {
  // LOGS
  async findLogHistory(skip, take, product_id) {
    return api.get(`/warehouse/logs?skip=${skip}&take=${take}&product_id=${product_id}`);
  }

  // MINUTE
  async createMinute(items) {
    return api.post('/warehouse/minute', { items: items });
  }

  async downloadMinuteTag(minute_header_id) {
    return api.post('/warehouse/minute/tag', { minute_header_id }, { responseType: 'arraybuffer' });
  }

  // REQUESTS
  async createRequest(data) {
    return api.post('/warehouse/requests', data);
  }

  async listRequests(skip, take, searchFilter, status_id, expedition) {
    return api.get(
      `/warehouse/requests?skip=${skip}&take=${take}&searchFilter=${searchFilter}&status_id=${status_id}&expedition=${expedition}`,
    );
  }

  // REQUEST STATUS
  async listRequestStatus() {
    return api.get('/warehouse/requests/status');
  }

  // SERVICES
  async cancelService(data) {
    return api.put('/warehouse/services', data);
  }

  async createService(data) {
    return api.post('/warehouse/services', data);
  }

  async findService(request_id) {
    return api.get(`/warehouse/services/${request_id}`);
  }

  // SIGEP RANGE OBJECTS
  async createPostCard(request_id) {
    return api.get(`warehouse/sigep-range-objects/${request_id}`, { responseType: 'arraybuffer' });
  }

  async createSigepRange(data) {
    return api.post('warehouse/sigep-range-objects', data);
  }

  async listSigepRangeObjects(skip, take, searchFilter) {
    return api.get(
      `/warehouse/sigep-range-objects?skip=${skip}&take=${take}&searchFilter=${searchFilter}`,
    );
  }

  // STOCK
  async createProductInStock(data) {
    return api.post('/warehouse/stock', data);
  }

  async createProductInStockByFile(data) {
    return api.post('/warehouse/stock', data);
  }

  async findProductBySerial(serial) {
    return api.get(`/warehouse/stock/${serial}`);
  }

  async inativeProductInStock(id) {
    return api.delete(`/warehouse/stock/${id}`);
  }

  async listStock(skip, take, searchFilter) {
    return api.get(`/warehouse/stock?skip=${skip}&take=${take}&searchFilter=${searchFilter}`);
  }

  async getProductTag(product_id) {
    return api.get(`warehouse/stock/tag/${product_id}`, { responseType: 'arraybuffer' });
  }

  async updateProductInStock(data) {
    return api.put('/warehouse/stock', data);
  }

  async validatedStockFile(data) {
    return api.post('/warehouse/validated-stock-file', data);
  }
}

export const warehouseService = new WarehouseService();
