import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Text } from 'components';
import { Formik, Field } from 'formik';

export const OrderDialog = ({ open, onClose, selectedOrdering = {}, dialogProps }) => {
  const { name } = selectedOrdering;

  const initialValues = { quantity: 1 };

  const onSubmit = async ({ quantity }, { resetForm }) => {
    setOrdering(true);

    try {
      await api.post('/components/orderings', {
        component_id: selectedComponent.id,
        quantity,
      });

      resetForm();
      toast.success('Componente requisitado com sucesso.');
      onClose();

      const updateComponents = (components) => {
        return components
          .map((c) => {
            if (c.id === selectedComponent.id) {
              return {
                ...c,
                quantity: c.quantity - quantity,
              };
            }

            return c;
          })
          .filter((c) => c.quantity > 0);
      };

      setComponents((prev) => updateComponents(prev));
      refetchOrderings();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOrdering(false);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {(props) => (
          <Dialog open={open} onClose={onClose} maxWidth='sm'>
            <Dialog.Title onClose={onClose}>Deseja confirmar à venda?</Dialog.Title>
            <Dialog.Content>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography gutterBottom>Nome do cliente:</Typography>
                  {name}
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom>CPF/CNPJ:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography gutterBottom>Descrição:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography gutterBottom>Part Number:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography gutterBottom>Valor unitario:</Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    label='Quantidade desejada'
                    name='quantity'
                    size='small'
                    variant='outlined'
                    type='number'
                    fullWidth
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    size='small'
                    name='total'
                    label='Total'
                    variant='outlined'
                    disabled
                    component={Text}
                  />
                </Grid>
              </Grid>
            </Dialog.Content>
            <Dialog.Actions>
              <Button onClick={onClose} variant='text' color='default'>
                Cancelar
              </Button>
              <Button onClick={props.handleSubmit}>Confirmar</Button>
            </Dialog.Actions>
          </Dialog>
        )}
      </Formik>
    </>
  );
};
