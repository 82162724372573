import React, { useState, useCallback, useEffect, createContext, useReducer } from 'react';
import { FaCartPlus } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Button, Table, FormatMoney, Spacer } from 'components';
import { Formik, Form, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { Cart } from './Cart';
import { addComponentToCartAction } from './Cart/store/actions';
import { cartComponentReducer } from './Cart/store/reducer';
import { cartServiceReducer } from './Cart/store/reducerService';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number' },
  { label: 'Processo' },
  { label: 'Valor' },
  { label: 'Valor Minimo' },
  { label: 'Ação', align: 'center', width: 180 },
];

export const BudgetCartContext = createContext();
export const initialBudgetCartState = [];
export const initialServiceCartState = [];

export const PreBudgetTec = () => {
  const classes = useStyles();

  const user = useContextSelector(AuthContext, (context) => context.user);

  const serviceInHome = useLocation();

  console.log('serviceInHome: ', serviceInHome);

  const [componentsCashier, setComponentsCashier] = useState([]);
  const [openCart, setOpenCart] = useBoolean();
  const [ordering, setOrdering] = useState();
  const [totalCart, setTotalCart] = useState(0);
  const [state, dispatch] = useReducer(cartComponentReducer, initialBudgetCartState);

  const [totalCartService, setTotalCartService] = useState(0);
  const [stateService, dispatchService] = useReducer(cartServiceReducer, initialServiceCartState);

  const [salesComponents, setSalesComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState(0);

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [salesComponentsData, , loadingSalesComponents, refetchSalesComponents] = useQuery(
    componentService.listSalesComponents,
    [],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const totalItens = useCallback(
    (setTotalCart) => {
      const total = state.reduce((total, { sub_total }) => {
        return total + sub_total;
      }, 0);

      if (!state.length) {
        return setTotalCart(0);
      }

      setTotalCart(total);
    },
    [state],
  );

  useEffect(() => {
    setComponentsCashier(salesComponentsData);
    totalItens(setTotalCart);
  }, [salesComponentsData, totalItens]);

  const onSelectRowClick = (row) => {
    setSelectedComponent(row);
  };

  const formattedComponents = (salesComponentsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredComponents = (formattedComponents || []).filter((componentsCashier) => {
    const lowerPartNumber = componentsCashier?.part_number?.toLowerCase();
    const upperPartNumber = componentsCashier?.part_number?.toUpperCase();

    return lowerPartNumber?.includes(search) || upperPartNumber?.includes(search);
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {
    setSalesComponents(salesComponentsData);
  }, [salesComponentsData]);

  useEffect(() => {}, [formattedComponents, filteredComponents]);

  return (
    <>
      <PageTitle>Orçamento Técnico</PageTitle>

      <Formik
        initialValues={{
          name: user?.name,
          email: user?.email,
          phone: user?.phone,
          service_number: serviceInHome?.state?.serviceInHome?.number,
          client_name: serviceInHome?.state?.serviceInHome?.name,
        }}
      >
        <Form>
          <Typography variant='h6' gutterBottom>
            Dados do Técnico
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Field
                size='small'
                fullWidth
                name='name'
                variant='outlined'
                label='Nome'
                component={Text}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                size='small'
                fullWidth
                name='email'
                variant='outlined'
                label='E-mail'
                component={Text}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <Field
                size='small'
                fullWidth
                name='phone'
                variant='outlined'
                label='Telefone / Celular'
                mask='phone'
                component={Text}
                disabled
              />
            </Grid>
          </Grid>

          <Typography variant='h6' gutterBottom>
            Dados do Cliente
          </Typography>

          <Grid spacing={2} container>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                size='small'
                fullWidth
                name='service_number'
                variant='outlined'
                label='Ordem de serviço'
                component={Text}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <Field
                size='small'
                fullWidth
                name='client_name'
                variant='outlined'
                label='Nome do cliente'
                component={Text}
                disabled
              />
            </Grid>
          </Grid>

          <Grid spacing={2} container>
            <Grid item xs={12} md={4} lg={4}>
              <Text
                label='Pesquisar'
                size='small'
                variant='outlined'
                fullWidth
                endAdornment={<SearchIcon />}
                onChange={handleSearchChange}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Grid container direction='row' spacing={2}>
                <Grid item xs={12} md={6} lg={6}>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={setOpenCart.toTrue}
                    loading={ordering}
                    fullWidth
                    size='small'
                    className={classes.buttonOpenCart}
                  >
                    <div className={state.length ? classes.cartCount : classes.cartEmpty}>
                      {state.length}
                    </div>
                    <FaCartPlus size={30} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={24} />

          <Grid item xs={12} md={12} lg={12}>
            <BudgetCartContext.Provider
              value={{
                state,
                dispatch,
                totalCart,
                refetchSalesComponents,
                stateService,
                dispatchService,
                totalCartService,
                setTotalCartService,
                serviceInHome,
              }}
            >
              <Cart open={openCart} onClose={setOpenCart.toFalse} />
              <Table
                headers={columns}
                data={salesComponentsData}
                emptyMessage='Nenhum registro encontrado.'
                striped
                maxHeight={500}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                disableNumeration
              >
                {filteredComponents?.map((salesComponent) => (
                  <TableRow key={salesComponent.id}>
                    <TableCell>{salesComponent.part_number}</TableCell>
                    <TableCell>{salesComponent.description}</TableCell>
                    <TableCell>
                      <FormatMoney>{salesComponent.sell_price}</FormatMoney>
                    </TableCell>
                    <TableCell>
                      <FormatMoney>{salesComponent.origin_value}</FormatMoney>
                    </TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        onClick={() => addComponentToCartAction(salesComponent, dispatch)}
                        className={classes.buttonAddToCart}
                      >
                        Adicionar Item
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </BudgetCartContext.Provider>
          </Grid>
        </Form>
      </Formik>
    </>
  );
};
