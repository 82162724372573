import { api } from 'services/api';

export class ServiceOrderService {
  async createClient(data) {
    return api.post('/service-orders/clients', data);
  }

  async createService(data) {
    return api.post('/service-orders', data);
  }

  async updateService(data) {
    return api.put('/service-orders', data);
  }

  async updateColor(id, description) {
    return api.put('/service-orders/updateColor', { id, description });
  }

  async updateSinister(id, sinister) {
    return api.put('/service-orders/updateSinister', { id, sinister });
  }

  async getClientByCgc(cgc) {
    return api.get(`/service-orders/clients/${cgc}`);
  }

  async getObjectReceived(input_data) {
    return api.get(`/service-orders/objectsReceived/${input_data}`);
  }

  async getObjectRetail(input_data) {
    return api.get(`/service-orders/objectsRetail/${input_data}`);
  }

  async getCheckitDone(input_data) {
    return api.get(`/service-orders/checkitDone/${input_data}`);
  }
  async getClientById(id) {
    return api.get(`/service-orders/clientsid/${id}`);
  }

  async getServiceOrderByNumberGspn(number) {
    return api.get(`/service-orders/gspn/${number}`);
  }

  async getServiceOrderByNumberGspnFile(data) {
    return api.get(`/service-orders/gspn-file`, data);
  }

  async createCheckKit(data) {
    return api.post(`/service-orders/check-kit`, data);
  }

  async getServiceByNumberWhereIsNotCheckKit(number) {
    return api.get(`/service-orders/check-kit/service/${number}`);
  }

  async getServiceByNumberWhereIsNotQualityAssurance(number) {
    return api.get(`/service-orders/quality-assurance/service/${number}`);
  }

  async getServiceByNumberWhereIsNotSortingAssurance(number) {
    return api.get(`/service-orders/sorting-assurance/service/${number}`);
  }

  async getAssuranceDefects(number) {
    return api.get(`/service-orders/sorting-assurance-defects/service/${number}`);
  }

  async getManyQualityAssurance(number) {
    return api.get(`/service-orders/quality-assurance/many/service/${number}`);
  }

  async listProcesses() {
    return api.get('/service-orders/processes');
  }

  async getServiceOrderRh() {
    return api.get('/service-orders/list/rhservices');
  }

  async allServices() {
    return api.get('/service-orders/list/allServices');
  }

  async nonCompliceQuality(input_date, output_date) {
    return api.get(`/service-orders/list/nonCompliceQuality/${input_date}/${output_date}`);
  }

  async listPartsHoldingReport() {
    return api.get(`/service-orders/list/partsHoldingReport`);
  }

  async productionDepartmentOpening(input_date, output_date) {
    return api.get(`/service-orders/list/productionDepartmentOpening/${input_date}/${output_date}`);
  }

  async productionDepartmentSorting(input_date, output_date) {
    return api.get(`/service-orders/list/productionDepartmentSorting/${input_date}/${output_date}`);
  }

  async productionDepartmentRepair(input_date, output_date) {
    return api.get(`/service-orders/list/productionDepartmentRepair/${input_date}/${output_date}`);
  }

  async productionDepartmentQuality(input_date, output_date) {
    return api.get(`/service-orders/list/productionDepartmentQuality/${input_date}/${output_date}`);
  }

  async productionDepartmentCheckkit(input_date, output_date) {
    return api.get(
      `/service-orders/list/productionDepartmentCheckkit/${input_date}/${output_date}`,
    );
  }

  async agingDate(input_date, output_date) {
    return api.get(`/service-orders/list/agingDate/${input_date}/${output_date}`);
  }

  async aging4h() {
    return api.get('/service-orders/list/aging4h');
  }

  async listReportType() {
    return api.get('/service-orders/report-type');
  }

  async aging4hDetails() {
    return api.get('/service-orders/list/aging4hDetails');
  }

  async stepsMapping(input_date, output_date) {
    return api.get(`/service-orders/list/stepsMapping/${input_date}/${output_date}`);
  }

  async awaitingParts(data) {
    return api.post('/service-orders/awaitingParts', data);
  }

  async listAging() {
    return api.get('/service-orders/list-aging');
  }

  async listServiceStatuses() {
    return api.get('/service-orders/list-statuses');
  }

  async getSteps() {
    return api.get('/service-orders/steps');
  }

  async listForCurrentUser() {
    return api.get('/service-orders/me');
  }

  async listFinancialReport(input_date, output_date) {
    return api.get(`/service-orders/list-financialreport/${input_date}/${output_date}`);
  }

  async listPreBudgetReport(input_date, output_date) {
    return api.get(`/service-orders/list-prebudgetreport/${input_date}/${output_date}`);
  }

  async listOutOfWarranty() {
    return api.get('/service-orders/out-of-warranty');
  }

  async updateServiceStatusId(id) {
    return api.put('/service-orders/status/id', { id });
  }

  async updateServiceStatusIdQA(id, description, imei1_out, imei2_out, serial_new) {
    return api.put('/service-orders/statusqa/id', {
      id,
      description,
      imei1_out,
      imei2_out,
      serial_new,
    });
  }
  async listServiceInQuantity() {
    return api.get('/service-orders/list/quantity');
  }

  async listQaInspector() {
    return api.get('/service-orders/list/listqaInspector');
  }

  async listRepairInspector() {
    return api.get('/service-orders/list/listrepairInspector');
  }

  async listRepairNonCompliance() {
    return api.get('/service-orders/list/listrepairNonCompliance');
  }

  async listTotalRepairNonCompliance() {
    return api.get('/service-orders/list/listqanoncompliancetotal');
  }
  async listRepairInspectorTotal() {
    return api.get(`/service-orders/list/listrepairInspectorTotal`);
  }

  async listRepairInspectorDiary(input_date) {
    return api.get(`/service-orders/list/listrepairInspectorDiary/${input_date}`);
  }

  async sortingReport(input_date, output_date) {
    return api.get(`/service-orders/list/sortingReport/${input_date}/${output_date}`);
  }

  async listSortingTotal(input_date) {
    return api.get(`/service-orders/sortingTotal/${input_date}`);
  }

  async listQaInspectorTotal() {
    return api.get(`/service-orders/list/listqainspectortotal`);
  }

  async listQaInspectorDiary(input_date) {
    return api.get(`/service-orders/list/listqainspectordiary/${input_date}`);
  }

  async listOrctotal() {
    return api.get('/service-orders/list/listOrcTotal');
  }

  async listQuantityByTotalPrice() {
    return api.get('/service-orders/list/quantity/byTotalPrice');
  }

  async listStockRequests(serviceId) {
    return api.get(`/service-orders/list-stock-requests/${serviceId}`);
  }

  async listServiceInQuantityBudget() {
    return api.get('/service-orders/list/quantity/byBudget');
  }

  async listWeekTransactions() {
    return api.get('/service-orders/budget/week/transactions');
  }

  async listServiceInQuantityFinished() {
    return api.get('/service-orders/list/quantity/finished');
  }

  async listOutOfWarrantyNoBudget() {
    return api.get('/service-orders/out-of-warranty/noBudget');
  }

  async listSacQualityAssuranceReport() {
    return api.get('/service-orders/list/sacQualityAssuranceReport');
  }

  async listSacShippingReport() {
    return api.get('/service-orders/list/sacShippingReport');
  }

  async listServiceOrdersStatusInRepair() {
    return api.get('/service-orders/status/in-repair');
  }

  async getByNumber(number) {
    return api.get(`/service-orders/${number}`);
  }

  async getByNumberWithNoRequisition(number) {
    return api.get(`/service-orders/nostockrequisition/${number}`);
  }
  async getNextStep(service_number) {
    return api.get(`/service-orders/status/next/${service_number}`);
  }

  async getServiceOrderByNumberWithGaiaAndGspnData(number) {
    return api.get(`/service-orders/gspn/service/${number}`);
  }

  async createServiceOrderAccessories(values) {
    return api.post('/service-orders/accessories', values);
  }

  async cancelServiceOrder(values) {
    return api.put('/service-orders/cancel', values);
  }

  async listServiceOrderAccessories() {
    return api.get('/service-orders/accessories');
  }

  async listBudgetTotalPriceToday() {
    return api.get('/service-orders/budget/today/totalPrice');
  }

  async listBudgetTotalAcessoryToday() {
    return api.get('/service-orders/budget/today/totalAcessory');
  }

  async listAprovedBudgetToday() {
    return api.get('/service-orders/budget/today/aproved');
  }

  async listColorsProduct() {
    return api.get('/service-orders/colors-products');
  }

  async creteColors(description) {
    return api.post('/service-orders/colors-products', description);
  }
  async listReprovedBudgetToday() {
    return api.get('/service-orders/budget/today/reproved');
  }

  async listAllBudgetToday() {
    return api.get('/service-orders/budget/today/all');
  }

  async listAprovedWithPartsServiceBudgetWeek() {
    return api.get('/service-orders/budget/week/approved/withPartsService');
  }

  async listAprovedWithPartLabourServiceBudgetWeek() {
    return api.get('/service-orders/budget/week/reproved/withPartsService');
  }

  async listBudgetAprovedOnly() {
    return api.get('/service-orders/list-aprovedonly');
  }

  async listBudgetsrReport(input_date, output_date) {
    return api.get(`/service-orders/list-budgetreport/${input_date}/${output_date}`);
  }

  async listAgingReport(input_date, output_date) {
    return api.get(`/service-orders/list-agingreport/${input_date}/${output_date}`);
  }

  async finalInsuranceReport(input_date, output_date) {
    return api.get(`/service-orders/list-final-insurance-report/${input_date}/${output_date}`);
  }

  async costReport(input_date, output_date) {
    return api.get(`/service-orders/list-cost-report/${input_date}/${output_date}`);
  }

  async listAgingRepairReport(input_date, output_date) {
    return api.get(`/service-orders/list-agingrepairreport/${input_date}/${output_date}`);
  }

  async listEticket(input_date, output_date) {
    return api.get(`/service-orders/list-eticketreport/${input_date}/${output_date}`);
  }

  async listObjectReport(input_date, output_date) {
    return api.get(`/service-orders/list-objectreport/${input_date}/${output_date}`);
  }

  async updateAccessory(id, description) {
    return api.put(`/service-orders/accessories`, { id, description });
  }

  async updateProcess(id, initials, description) {
    return api.put(`service-orders/processes`, { id, initials, description });
  }

  async createServiceFile(data) {
    return api.post('/service-orders/file', data);
  }

  async updateServiceGspn(service_order_number) {
    return api.post('/service-orders/updateServiceGspn', service_order_number);
  }

  async registerColor(description) {
    return api.post('/service-orders/updateServiceGspn', description);
  }

  async listPNsFromGspnByNumber(number) {
    return api.get(`/service-orders/gspn/pns/${number}`);
  }

  async DownloadOpeningTag(service_order_number) {
    return api.get(`/service-orders/${service_order_number}/download-service-tag`, {
      responseType: 'arraybuffer',
    });
  }

  async downloadOpeningTags(services) {
    return api.post(`/service-orders/download-service-tags`, services, {
      responseType: 'arraybuffer',
    });
  }

  async listDeviceDefects() {
    return api.get('/service-orders/device-defect');
  }

  async createDeviceDefects(data) {
    return api.post('/service-orders/device-defect', data);
  }

  async updateDeviceDefect(data) {
    return api.put('/service-orders/device-defect', data);
  }

  async listSubDeviceDefects() {
    return api.get('/service-orders/sub-device-defect');
  }

  async listSubDeviceDefectsByMainDefect(device_defect_id) {
    return api.get(`/service-orders/sub-device-defect/${device_defect_id}`);
  }

  async createSubDeviceDefects(data) {
    return api.post('/service-orders/sub-device-defect', data);
  }

  async listQualityAssuranceDefects() {
    return api.get('/service-orders/quality-assurance-defect');
  }

  async createQualityAssurance(data) {
    return api.post('/service-orders/quality-assurance', data);
  }

  async createSortingAssurance(data) {
    return api.post('/service-orders/sorting-assurance', data);
  }

  async updateQualityAssurance(id) {
    return api.put('/service-orders/quality-assurance', { id });
  }

  async listServiceOrdersByInfnfe(infnfe) {
    return api.post(`/service-orders/infnfe`, { infnfe });
  }

  async listServiceAdvancedConsult(data) {
    return api.post(`/service-orders/advanced/consult`, { data });
  }

  async listServiceAdvancedConsultInvoice(data) {
    return api.post(`/service-orders/advanced/consult-invoice`, { data });
  }

  async invoiceEmissionEntrance(service_order_number) {
    return api.post('/service-orders/emissions-invoice/entrance', { service_order_number });
  }

  async invoiceEmissionOutbound(service_order_number) {
    return api.post('/service-orders/emissions-invoice/outbound', { service_order_number });
  }

  async createServiceRemark(description, service_order_id) {
    return api.post(`/service-orders/serviceRemark`, { description, service_order_id });
  }

  async listServiceToAddReport(service_order_number) {
    return api.get(`/service-orders/list-report-service/${service_order_number}`);
  }

  async getReportFile(service_number) {
    return api.get(`/service-orders/listReportFile/${service_number}`);
  }

  async reprintTagConsulteAqui() {
    return api.get(`/service-orders/consulte-aqui/tag`, {
      responseType: 'arraybuffer',
    });
  }

  async listAssurantServiceOrders(page, rowsPerPage, search) {
    return api.get(`/service-orders/list/assurant/services`, {
      params: {
        page: page,
        rowsPerPage: rowsPerPage,
        search: search,
      },
    });
  }

  async createAssurantMinute(services) {
    return api.post(
      `/service-orders/list/assurant/minute`,
      { services },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async listMinuteReport(input_date, output_date) {
    return api.get(`/service-orders/list/report/minute/${input_date}/${output_date}`);
  }

  async appleReport(input_date, output_date) {
    return api.get(`/service-orders/list-apple-budget-report/${input_date}/${output_date}`);
  }
}

export const serviceOrderService = new ServiceOrderService();
