import { gql } from '@apollo/client';

export const LIST_FUNCTIONAL_SCREENING_QUESTIONS = gql`
  query FunctionalScreeningQuestions($sinister: String!) {
    functionalScreeningQuestions(sinister: $sinister) {
      id
      description
      listFuntionalScreeningQuestions {
        id
        description
      }
    }
  }
`;

export const CREATE_FUNCTIONAL_SCREENING_RESPONSE = gql`
  mutation SubmitFunctionalScreeningResponses($data: CreateFunctionalScreeningResponsesInput!) {
    submitFunctionalScreeningResponses(data: $data)
  }
`;

export const LIST_FUNTIONAL_DEFECTS = gql`
  query {
    listFuntionalDefects {
      id
      description
      __typename @skip(if: true)
    }
  }
`;

export const VINC_SERVICE_WITH_UNIT_ADITIONAL_DEFECT = gql`
  mutation VincServiceWithUnitAditionalDefect($data: VincServiceWithAditionalDefectUnitInput!) {
    vincServiceWithUnitAditionalDefect(data: $data)
  }
`;

export const VINC_SERVICE_WITH_ADITIONAL_DEFECT = gql`
  mutation VincServiceWithAditionalDefect($data: VincServiceWithAditionalDefectInput!) {
    vincServiceWithAditionalDefect(data: $data)
  }
`;

export const INATIVE_SERVICE_WITH_ADITIONAL_DEFECT = gql`
  mutation InativeServiceAditionalDefect($data: InativeServiceWithAditionalDefectInput!) {
    inativeServiceAditionalDefect(data: $data)
  }
`;

export const LIST_FUNTIONAL_SCREENING_RESPONSES = gql`
  query ListFuncionalScreeningReponses($limit: Int, $offset: Int, $sinister: String!) {
    listFuncionalScreeningReponses(limit: $limit, offset: $offset, sinister: $sinister) {
      totalCount
      responses {
        id
        question
        response
        createdAt
        user {
          name
        }
      }
    }
  }
`;

export const LIST_FUNTIONAL_SCREENING_DEFECTS = gql`
  query ListFuncionalScreeningDefects($sinister: String!) {
    listFuncionalScreeningDefects(sinister: $sinister) {
      id
      defect {
        id
        description
      }
    }
  }
`;

export const EXIST_FUNTIONAL_DEFECT = gql`
  query ExistFuntionalDefect($sinister: String!) {
    existFuntionalDefect(sinister: $sinister)
  }
`;
