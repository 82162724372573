import { cnpj, cpf } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  model: yup.string().required(),
  cgc: yup
    .string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
  email: yup.string().required(),
  name: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  district: yup.string().required(),
  street_number: yup.string().required(),
  zipcode: yup.string().required(),
  street: yup.string().required(),
  phone_number: yup.string().required(),
});
