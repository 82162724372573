import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Photo from '@material-ui/icons/Photo';

import { BackNavButton, Button, FileUploader, Spacer } from 'components';
import { Field, Formik } from 'formik';
import { getAwsBucketPath } from 'helpers/getImagePath';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { mailObjectService } from 'services/mailObject';

import { useStyles } from './styles';

export const RegisterObject = () => {
  const {
    state: { mail_object },
  } = useLocation();

  const [currentObject, setCurrentObject] = useState({});

  useEffect(() => {
    setCurrentObject(mail_object);
  }, [mail_object]);

  const classes = useStyles();

  const handleSubmit = async ({ photo_1, photo_2, photo_3 }, { setSubmitting, resetForm }) => {
    try {
      console.log('Photo 1: ', photo_1);

      const formData = new FormData();
      formData.append('photo_1', photo_1);
      formData.append('photo_2', photo_2);
      formData.append('photo_3', photo_3);

      const { data } = await mailObjectService.addPhotos(currentObject.id, formData);

      setCurrentObject({
        ...currentObject,
        ...data,
      });

      toast.success('Fotos adicionadas com sucesso.');

      resetForm();

      const allPhotosUploaded = data.photo_1 && data.photo_2 && data.photo_3;

      allPhotosUploaded && browserHistory.push('/conference/opening-pll');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao enviar fotos.');
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle dev>Adicionar fotos para o objeto: {currentObject?.code}</PageTitle>

      <BackNavButton />

      <Spacer axis='vertical' size={16} />

      <Formik
        initialValues={{
          photo_1: null,
          photo_2: null,
          photo_3: null,
        }}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, handleSubmit }) => (
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              {!currentObject.photo_1 ? (
                <Field
                  name='photo_1'
                  label='Foto 1'
                  helperText='A imagem deve possuir uma boa qualidade.'
                  component={FileUploader}
                />
              ) : (
                <div className={classes.photoImgContainer}>
                  <Chip
                    className={classes.photoTag}
                    icon={<Photo />}
                    label='Foto 1'
                    variant='outlined'
                    textColor
                  />
                  <img
                    className={classes.photoImg}
                    src={getAwsBucketPath(currentObject.photo_1)}
                    alt='Imagem do objeto'
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              {!currentObject.photo_2 ? (
                <Field
                  name='photo_2'
                  label='Foto 2'
                  helperText='A imagem deve possuir uma boa qualidade.'
                  component={FileUploader}
                />
              ) : (
                <div className={classes.photoImgContainer}>
                  <Chip
                    className={classes.photoTag}
                    icon={<Photo />}
                    label='Foto 2'
                    variant='outlined'
                    textColor
                  />
                  <img
                    className={classes.photoImg}
                    src={getAwsBucketPath(currentObject.photo_2)}
                    alt='Imagem do objeto'
                  />
                </div>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              {!currentObject.photo_3 ? (
                <Field
                  name='photo_3'
                  label='Foto 3'
                  helperText='A imagem deve possuir uma boa qualidade.'
                  component={FileUploader}
                />
              ) : (
                <div className={classes.photoImgContainer}>
                  <Chip
                    className={classes.photoTag}
                    icon={<Photo />}
                    label='Foto 3'
                    variant='outlined'
                    textColor
                  />
                  <img
                    className={classes.photoImg}
                    src={getAwsBucketPath(currentObject.photo_2)}
                    alt='Imagem do objeto'
                  />
                </div>
              )}
            </Grid>
            <Grid className={classes.gridSendBtn} item xs={12}>
              <Button onClick={handleSubmit} loading={isSubmitting} disabled={!dirty}>
                Enviar
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </>
  );
};
