import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Button, Select, Text, Table, FormatMoney } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService, componentService, serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';

import { RegisterServiceProductsDialog } from './RegisterServiceProductsDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Processo' },
  { label: 'Valor' },
  { label: 'Valor Minimo' },
  { label: <VisibilityIcon />, align: 'center' },
];

const columnsRemainingPns = [{ label: 'Part Number' }, { label: 'Processo' }];

export const RegisterServiceProducts = () => {
  const location = useLocation();

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [importing, setImporting] = useState(false);
  const [serviceOrderProcess, setServiceOrderProcess] = useState({});

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();

    setServiceOrderProcess(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const [salesComponents, setSalesComponents] = useState([]);

  const [search, setSearch] = useState('');

  const [serviceOrderProcesses, , loadingServiceOrderProcess, refetchServiceOrderProcess] =
    useQuery(serviceOrderService.listProcesses, []);

  const [salesComponentsData, , loadingSalesComponents, refetchSalesComponents] = useQuery(
    componentService.listSalesComponents,
    [],
  );

  console.log('WTF: ', salesComponentsData);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    setSalesComponents(salesComponentsData);
  }, [salesComponentsData]);

  const formattedSalesComponment = (salesComponentsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredSalesComponent = formattedSalesComponment.filter((saleComponent) => {
    const upperPartNumber = saleComponent.part_number?.toUpperCase();
    const lowerPartNumber = saleComponent.part_number?.toLowerCase();

    const upperDescription = saleComponent.description?.toUpperCase();
    const lowerDescription = saleComponent.description?.toLowerCase();

    return (
      upperPartNumber?.includes(search) ||
      lowerPartNumber?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerDescription?.includes(search)
    );
  });

  useEffect(() => {}, [formattedSalesComponment, filteredSalesComponent]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      console.log(...salesComponents);
      setSubmitting(true);
      console.log(values);
      const { data } = await budgetService.CreateSalesComponent(values);

      setSubmitting(false);
      toast.success('Preço de venda pra componente cadastrado com sucesso');

      setSalesComponents([...salesComponents, data]);
      refetchSalesComponents();
    } catch (error) {
      toast.error(error.response.data?.message || 'Erro ao cadastrar valor.');
    }
  };

  const handleDownloadLayoutSalesPrice = async () => {
    var wb = XLSX.utils.book_new();

    var ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'Part Number',
          b: 'Descrição',
          c: 'Código do processo: Exemplo 2 para balcão',
          d: 'Valor (exemplo): 23,98',
          e: 'Valor Minimo (exemplo): 3,98',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'LayoutComponentPrice.xlsx');
  };

  const serviceOrderProcessesOptions = (serviceOrderProcesses || []).map((serviceOrderProcess) => ({
    value: serviceOrderProcess.id,
    label: serviceOrderProcess.id + '-' + serviceOrderProcess.description,
  }));

  const inputFileRef = useRef(null);

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const dadosFile = arr.map(function (obj) {
          if (obj.split(';')[2] == null || obj.split(';')[2] == undefined) {
            obj.split(';')[2] = 0;
            return false;
          } else {
            console.log('Teste: ', parseFloat(obj.split(';')[2].replace(',', '.')));
            const dados = {
              part_number: obj.split(';')[0],
              description: obj.split(';')[1],
              process_type_id: obj.split(';')[2],
              sell_price: parseFloat(obj.split(';')[3].replace(',', '.')).toFixed(2),
              origin_value: parseFloat(obj.split(';')[4].replace(',', '.')).toFixed(2),
              user_id: user.id,
            };

            return dados;
          }
        });

        const createDatasByFile = await budgetService.CreateSalesComponentFile({
          salesComponent: dadosFile,
        });

        if (createDatasByFile.data.length != 0) {
          toast.success(`${createDatasByFile.data.length} componentes cadastrados com sucesso.`);
          refetchServiceOrderProcess();
          refetchSalesComponents();
          setImporting(false);
        } else {
          toast.warning(
            'Não foi possível realizar a importação ou todos os componentes já estão cadastrados e foram apenas atualizados.',
          );
        }
      };
      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
      // } finally {
      setImporting(false);
    }
  };

  const handleDownloadComponentsPrice = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(salesComponents));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioPrecoComponentes${now}.xlsx`);
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Preço de componentes</PageTitle>
      <Formik
        initialValues={{
          part_number: '',
          process_type_id: 0,
          origin_value: 0,
          sell_price: 0,
          description: '',
          user_id: 'ade35a94-85df-4896-a0e9-2ea556d929f5',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.gridContainer} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='part_number'
                    label='Part Number'
                    variant='outlined'
                    size='small'
                    component={Text}
                    inputProps={{ maxLength: 30 }}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    name='process_type_id'
                    label='Processo'
                    variant='outlined'
                    size='small'
                    component={Select}
                    loading={loadingServiceOrderProcess}
                    options={serviceOrderProcessesOptions}
                  />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <Field
                    name='description'
                    label='Descrição'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='origin_value'
                    label='Valor minimo'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='sell_price'
                    label='Valor'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth onClick={handleImportClick} disabled={importing} type=''>
                    IMPORTAR PREÇOS
                  </Button>
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth variant='outlined' onClick={handleDownloadLayoutSalesPrice}>
                    BAIXAR LAYOUT
                  </Button>
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth variant='outlined' onClick={handleDownloadComponentsPrice}>
                    RELATÓRIO
                  </Button>
                </Grid>
                {importing ? (
                  <Grid xs={6} lg={12} className={classes.loading}>
                    <Box sx={{ width: '90%' }}>
                      <LinearProgress color='secondary' />
                      <Grid className={classes.gridImages} xs={6} lg={12}>
                        {' '}
                      </Grid>
                    </Box>
                  </Grid>
                ) : (
                  <Grid className={classes.gridImages} xs={6} lg={12}>
                    {' '}
                  </Grid>
                )}

                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    data={filteredSalesComponent}
                    emptyMessage='Nenhum registro encontrado.'
                    striped
                    // actionsLabel={<VisibilityIcon />}
                    // onActionsClick={handleOpenInformation}
                    maxHeight
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredSalesComponent?.map((salesComponent) => (
                      <TableRow key={salesComponent.id}>
                        <TableCell>{salesComponent.part_number}</TableCell>
                        <TableCell>{salesComponent.description}</TableCell>
                        <TableCell>{salesComponent?.process?.description}</TableCell>
                        <TableCell>
                          <FormatMoney>{salesComponent.sell_price}</FormatMoney>
                        </TableCell>
                        <TableCell>
                          <FormatMoney>{salesComponent.origin_value}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, salesComponent)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                  <Grid item xs={12} lg={3}>
                    <div className={classes.containerLegend}>
                      <WarningIcon color='warning' />
                      <p variant='caption' className={classes.p}>
                        O ID do processo para cadastro esta localizado na caixa de seleção chamada:
                        Processo
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <div className={classes.containerLegend}>
                      <WarningIcon color='warning' />
                      <p variant='caption' className={classes.p}>
                        Máximo 10.000 PN por vez
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              {location?.state?.preBudgetComponents ? (
                <>
                  <Grid className={classes.alignToEnd} container spacing={2}>
                    <Grid item xs={6} lg={4}>
                      <Table
                        headers={columnsRemainingPns}
                        data={location?.state?.preBudgetComponents}
                        emptyMessage='Nenhum registro encontrado.'
                        striped
                        actionsLabel={<VisibilityIcon />}
                        onActionsClick={handleOpenInformation}
                        maxHeight
                        disableNumeration
                      >
                        {location?.state?.preBudgetComponents?.map((remaining) => (
                          <TableRow key={remaining.PartsNo}>
                            <TableCell>{remaining.PartsNo}</TableCell>
                            <TableCell>{location?.state?.service_order_number}</TableCell>
                          </TableRow>
                        ))}
                      </Table>
                      <Grid item xs={12} lg={3}>
                        <div className={classes.containerLegend}>
                          <WarningIcon color='warning' />
                          <p variant='caption' className={classes.p}>
                            Aqui estão os PNs sem cadastros para esta service!
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </Form>
          </>
        )}
      </Formik>
      <RegisterServiceProductsDialog
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        serviceOrderProduct={serviceOrderProcess}
        reloadServiceProducts={refetchSalesComponents}
      />
    </>
  );
};
