import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useMutation } from '@apollo/client';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { Button, Spacer, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { INCLUDE_SINISTER_IN_BOX } from 'services/graphql/query/b2c';

import { CreateBoxDialogContext } from '../..';
import { BoxItemTable } from '../BoxItemTable';

export function IncludeItem() {
  const { boxId, onBack, showIncludeForm } = useContext(CreateBoxDialogContext);

  const [includeSinister] = useMutation(INCLUDE_SINISTER_IN_BOX, {
    onCompleted: () => {
      toast.success('Voucher adicionado com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao adicionar o sinistro';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  async function handleIncludeVoucher(values, { setSubmitting, resetForm }) {
    setSubmitting(true);

    try {
      await includeSinister({
        variables: { boxId, sinister: values.sinister },
      });
      resetForm();
    } catch (error) {
      console.log('error', error);
    }
  }

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton color='secondary' onClick={onBack}>
          <ArrowBackIcon fontSize='large' />
        </IconButton>
      </Grid>

      <Spacer size={32} />

      {showIncludeForm && (
        <Formik initialValues={{ sinister: '' }} onSubmit={handleIncludeVoucher}>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      name='sinister'
                      component={Text}
                      label='Voucher/IMEI'
                      size='small'
                      variant='outlined'
                      onInput={toInputUppercase}
                      endAdornment={<QrCodeIcon />}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Button type='submit' fullWidth disabled={!props.isValid || !props.dirty}>
                      Incluir na Box
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      )}

      <BoxItemTable boxId={boxId} />
    </>
  );
}
