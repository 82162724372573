import React, { useContext } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { TablePagination } from '@mui/material';
import Chip from '@mui/material/Chip';
import { FormatDate, Table } from 'components';

import { WarehouseStockContext } from '..';

import { ActionMenu } from './ActionMenu';

const columns = [
  { label: 'Voucher', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Status', align: 'center' },
  { label: 'Local', align: 'center' },
  { label: 'Condição', align: 'center' },
  { label: 'Triagem funcional', align: 'center' },
  { label: 'Grade', align: 'center' },
  { label: 'Cadastrado em', align: 'center' },
  { label: 'Atualizado em', align: 'center' },
  { label: 'Ações', align: 'center' },
];

function getGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: 'LIKE NEW',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

export function StockTable() {
  const {
    data,
    loading,
    refetch,
    totalCount,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useContext(WarehouseStockContext);

  return (
    <>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={loading}
        disableNumeration
      >
        {data?.listServices?.serviceOrders?.map((item, index) => {
          const grade = getGradeColor(item?.grade?.id);
          return (
            <TableRow key={index}>
              <TableCell align='center'>{item?.sinister}</TableCell>
              <TableCell align='center'>{item?.imei}</TableCell>
              <TableCell align='center'>{item?.sku?.sku}</TableCell>
              <TableCell align='center'>
                {item?.sku?.brand?.description +
                  ' ' +
                  item?.sku?.model?.description +
                  ' ' +
                  item?.sku?.storage?.description +
                  ' ' +
                  item?.sku?.color?.description}
              </TableCell>
              <TableCell align='center' style={{ fontWeight: 'bold' }}>
                {item?.status?.description}
              </TableCell>
              <TableCell align='center'>{item?.stock?.description}</TableCell>
              <TableCell align='center'>{item?.condition}</TableCell>
              <TableCell align='center'>{item?.functionalScreeningResult}</TableCell>
              <TableCell align='center'>
                <Chip
                  label={grade.label}
                  style={{
                    backgroundColor: grade.color,
                    color: grade.textColor,
                    border: grade.border || 'none',
                  }}
                />
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy'>{item?.createdAt}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <FormatDate format='dd/MM/yyyy'>{item?.updatedAt}</FormatDate>
              </TableCell>
              <TableCell align='center'>
                <ActionMenu data={item} />
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
