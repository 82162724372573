import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Table, FormatDate } from 'components';
import { Button } from 'components/Button';
import { Formik, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { serviceOrderService } from 'services/serviceOrder';

import { useStyles } from './styles';

const columns = [
  { label: 'Service' },
  { label: 'Data de Criação' },
  { label: 'Processo' },
  { label: 'Atendimento' },
  { label: 'Status', align: 'center', width: 180 },
];

export const TableLaboratory = () => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const [search, setSearch] = useState('');

  const [serviceOrders, , loadingServiceOrders] = useQuery(
    () => serviceOrderService.listServiceOrdersStatusInRepair(),
    [],
  );

  console.log(serviceOrders);

  const formattedBudgetsApproved = (serviceOrders || []).map((b) => {
    return {
      ...b,
    };
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredBudgetsApproved = formattedBudgetsApproved.filter((budget) => {
    const upperService = budget?.number.toUpperCase();
    const lowerService = budget?.number.toLowerCase();

    return upperService?.includes(search) || lowerService?.includes(search);
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {
    console.log(serviceOrders);
  }, [serviceOrders]);

  const navigateToNextStep = async (serviceOrder, row) => {
    const { data } = await serviceOrderService.getNextStep(serviceOrder);

    browserHistory.push({
      pathname: data,
      state: { service_order_number: serviceOrder },
    });
  };

  console.log('filteredBudgetsApproved', filteredBudgetsApproved);

  // const statuses = 10 ? true : (statuses = 8 ? true : (statuses = 6 ? true : false));

  return (
    <Formik initialValues={{ search_os: '' }}>
      <>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Field
              size='small'
              fullWidth
              endAdornment={<SearchIcon />}
              name='search_os'
              variant='outlined'
              label='Consultar OS'
              component={Text}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} lg={6}>
            <Table
              headers={columns}
              loading={loadingServiceOrders}
              emptyMessage='Nenhum registro encontrado.'
              striped
              data={filteredBudgetsApproved}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredBudgetsApproved?.map((serviceOrder) => {
                return (
                  <TableRow key={serviceOrder?.id}>
                    <TableCell>{serviceOrder?.number}</TableCell>

                    <TableCell align='center'>
                      <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                        {serviceOrder?.created_at}
                      </FormatDate>
                    </TableCell>
                    <TableCell>{serviceOrder?.process.description}</TableCell>
                    <TableCell>{serviceOrder?.warranty_type}</TableCell>

                    <TableCell align='center'>
                      <Button
                        // disabled
                        size='small'
                        fullWidth
                        onClick={() => navigateToNextStep(serviceOrder.number)}
                      >
                        EM REPARO
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </Grid>
        </Grid>
      </>
    </Formik>
  );
};
