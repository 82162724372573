import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  contract_number: yup.string().required(),
  additional_information: yup.string().required(),
  contract_method: yup.string().required(),
});

export const initialValues = {
  contract_number: '',
  aaddional_information: '',
  contract_method: '',
};
