import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    paperContainer: { padding: '15px' },
    containerTitle: {
      margin: '-5px',
      marginLeft: '5px',
    },
  };
});
