import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  // cgc: yup.string().test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
  //   if (text?.length < 14) {
  //     return cpf.isValid(text);
  //   }

  //   return cnpj.isValid(text);
  // }),
  email: yup.string(),
  name: yup.string(),
  city: yup.string(),
  state: yup.string(),
  district: yup.string(),
  street_number: yup.string(),
  zipcode: yup.string(),
  street: yup.string(),
  phone_number: yup.string(),
  payment_method: yup.number().required(),
  payment_method2: yup.number(),
  installments: yup.number().required(),
  installments2: yup.number(),
  attendance_id: yup.string().required(),
  seller_id: yup.string(),
  payment_code: yup.string(),
  emission_coupon: yup.number().required(),
});

// import { cpf, cnpj } from 'cpf-cnpj-validator';
// import * as yup from 'yup';

// export const validationSchema = yup.object().shape({
//   cgc: yup
//     .string()
//     .required()
//     .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
//       if (text?.length < 14) {
//         return cpf.isValid(text);
//       }

//       return cnpj.isValid(text);
//     }),
//   email: yup.string().required(),
//   name: yup.string().required(),
//   city: yup.string().required(),
//   state: yup.string().required(),
//   district: yup.string().required(),
//   street_number: yup.string().required(),
//   zipcode: yup.string().required(),
//   street: yup.string().required(),
//   phone_number: yup.string().required(),
//   payment_method: yup.number().required(),
//   payment_method2: yup.number(),
//   installments: yup.number().required(),
//   installments2: yup.number(),
//   attendance_id: yup.string().required(),
//   emission_coupon: yup.number().required(),
// });
