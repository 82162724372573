import React from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { Text } from 'components/Form/Text';
import { Field, Formik } from 'formik';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { RequestComponentContext } from '../../context';
import { useStyles } from './styles';

export const OrderDialog = ({ open, onClose, selectedComponent = {}, setOrdering }) => {
  const classes = useStyles();

  const setComponents = useContextSelector(
    RequestComponentContext,
    (context) => context.setComponents,
  );
  const refetchOrderings = useContextSelector(
    RequestComponentContext,
    (context) => context.refetchOrderings,
  );

  const onSubmit = async ({ quantity }, { resetForm }) => {
    setOrdering(true);

    try {
      await api.post('/components/orderings', {
        component_id: selectedComponent.id,
        quantity,
      });

      resetForm();
      toast.success('Componente requisitado com sucesso.');
      onClose();

      const updateComponents = (components) => {
        return components
          .map((c) => {
            if (c.id === selectedComponent.id) {
              return {
                ...c,
                quantity: c.quantity - quantity,
              };
            }

            return c;
          })
          .filter((c) => c.quantity > 0);
      };

      setComponents((prev) => updateComponents(prev));
      refetchOrderings();
    } catch (err) {
      toast.error(err.message);
    } finally {
      setOrdering(false);
    }
  };

  const { quantity, part_number, description, component_location } = selectedComponent;

  const validationSchema = yup.object().shape({
    quantity: yup.number().required().min(1).max(quantity, 'Quantidade indisponível atualmente.'),
  });

  const initialValues = { quantity: 1 };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(props) => (
        <Dialog open={open} onClose={onClose} maxWidth='xs'>
          <Dialog.Title onClose={onClose}>Requisitar componente</Dialog.Title>
          <Dialog.Content>
            <Typography gutterBottom>
              <strong>Part Number: </strong>
              {part_number}
            </Typography>
            <Typography gutterBottom>
              <strong>Descrição:</strong> {description}
            </Typography>
            <Typography gutterBottom>
              <strong>Local: </strong>
              {component_location?.description}
            </Typography>
            <Typography gutterBottom>
              <strong>Quantidade: </strong>
              {quantity}
            </Typography>
            <Typography className={classes.quantity} gutterBottom variant='body2'>
              Digite a quantidade desejada:
            </Typography>
            <Field
              label='Quantidade'
              name='quantity'
              size='small'
              variant='outlined'
              type='number'
              fullWidth
              inputProps={{
                min: 1,
              }}
              component={Text}
            />
          </Dialog.Content>
          <Dialog.Actions>
            <Button onClick={onClose} variant='text' color='default'>
              Cancelar
            </Button>
            <Button onClick={props.handleSubmit} type='submit'>
              Confirmar
            </Button>
          </Dialog.Actions>
        </Dialog>
      )}
    </Formik>
  );
};
