import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Table } from 'components/Table';
import { useQuery } from 'hooks/useQuery';
import { serviceOrderService } from 'services/serviceOrder';

export const Sorting = ({ serviceOrder }) => {
  const columns = [{ label: 'Descrição do defeito', align: 'center' }];
  const [defects, , loadingDefects] = useQuery(
    () => serviceOrderService.getAssuranceDefects(serviceOrder?.number),
    [],
  );

  const defectsOptions = (defects || []).map((sorted) => ({
    value: sorted.description,
    label: sorted.description,
  }));

  return serviceOrder ? (
    <Table headers={columns} disableNumeration>
      {defects?.map((sorting) => (
        <TableRow align='center' key={sorting?.id}>
          <TableCell align='center'>{sorting?.description}</TableCell>
        </TableRow>
      ))}
    </Table>
  ) : (
    <Typography>Esse orçamento não possui part-numbers</Typography>
  );
};
