import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as ProductImg } from 'assets/images/product.svg';
import { Button, Text, FormatDate, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
// import { api } from 'services/api';
import { pllService } from 'services';
import { budgetService } from 'services/budget';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { AlterModelDialog } from './AlterModelDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Nome' },
  { label: 'Data de cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
});

export const RegisterSubInsurance = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [name, setName] = useState('');
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [product, setProduct] = useState({});

  const [additionalServiceProducts, setAdditionalServiceProducts] = useState([]);

  const [subInsuranceCompanies, , loadingSubInsuranceCompanies, insuranceRefetch] = useQuery(
    () => pllService.listSubInsuranceCompanies(),
    [],
  );

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts, productsRefetch] =
    useQuery(budgetService.listAdditionalServiceProducts, []);

  useEffect(() => {
    setAdditionalServiceProducts(subInsuranceCompanies);
  }, [subInsuranceCompanies]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    // console.log('ITEM: ', item);
    setProduct(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await pllService.createSubInsurance(values);

      console.log('seguradora', data);

      setAdditionalServiceProducts((prevState) => [...prevState, data]);

      insuranceRefetch();
      toast.success('Seguradora cadastrada com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao cadastrar seguradora');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Seguradoras</PageTitle>
      <Formik
        initialValues={{ name: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Digite o nome da seguradora'
                    name='name'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Cadastrar Seguradora
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    loading={loadingSubInsuranceCompanies}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Sem seguradoras cadastrados.'
                    // onActionsClick={handleOpenInformation}
                    data={additionalServicesProductsData}
                    title='Nome'
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {subInsuranceCompanies?.map((subInsuranceCompanie) => (
                      <TableRow key={subInsuranceCompanie.id}>
                        {/* {console.log('AdditionalServiceProduct: ', additionalServiceProduct)} */}
                        <TableCell>{subInsuranceCompanie.name}</TableCell>
                        <TableCell>
                          <FormatDate>{subInsuranceCompanie.created_at}</FormatDate>{' '}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, subInsuranceCompanie)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterModelDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  product={product}
                  reloadProducts={insuranceRefetch}
                />
                <Grid className={classes.imgContainer} item xs={12} lg={6}>
                  <ProductImg className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
