export const tutorialSteps = [
  {
    target: '#warranty-validation',
    content: 'Para validar a service , digite-a neste campo.',
  },
  {
    target: '#warranty-validation-button',
    content: 'Clique no botão e aguarde a validação.',
  },
];
