import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 700,
  },
  detailValue: {
    fontSize: 16,
    fontWeight: 300,
  },
  buttonsContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'end',
  },
}));
