import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(2),
  },
  tableRoot: {
    width: '100%',
    marginBottom: theme.spacing(2),
    maxHeight: 350,
  },
  tableRowRoot: {
    '&:nth-of-type(even)': {
      backgroundColor: '#fafafa',
    },
  },
  head: {
    backgroundColor: '#fafafa',
  },
}));
