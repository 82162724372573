import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/createComponentsImageImport.svg';
import { Button } from 'components';
import { Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { validationSchema } from './validation';

export const ImportBases = () => {
  const [hasSelected, setHasSelected] = useState(false);

  const [importComponents, setImportComponents] = useState();
  const [components, setComponents] = useState([]);
  const [importing, setImporting] = useState(false);
  const [componentsCataloged, setComponentsCatalog] = useState([]);
  const classes = useStyles();

  const [ComponentsCatalog, , loadingComponentsCatalog] = useQuery(
    componentService.listcatalogBase,
    [],
  );
  const enableDateIfSelected = () => {
    if (hasSelected) {
      setHasSelected(false);
    } else {
      setHasSelected(true);
    }
  };

  useEffect(() => {}, []);

  const handleDownloadComponentUnitPrice = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(ComponentsCatalog));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioBaseGspn${now}.xlsx`);
  };

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleFileChange = (e) => {
    try {
      setImporting(true);

      const NewDate = new Date();
      const hour = NewDate.getHours();

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false);
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const services = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              service_order_number: obj.split(';')[0],
            };

            console.log('Dados: ', dados);

            return dados;
          }
        });

        const createDataByFile = await componentService.CreateBaseImport({
          service_order_number: services,
        });

        if (createDataByFile.data.length != 0) {
          toast.success(`${createDataByFile.data.length} Base inserida com sucesso.`);
          setImporting(false);
        } else {
          toast.error('Occoreu um erro ao realizar a importação !');
          setImporting(false);
        }
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao importar, tente novamente!');
      // } finally {
      setImporting(false);
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Baixar base GSPN</PageTitle>
      <Formik
        initialValues={{
          service_order_number: '',
          input_data: new Date(),
          output_data: new Date(),
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Paper className={classes.gridPaper} variant='outlined'>
                <Grid container spacing={1}>
                  <Grid item xs={6} lg={3}>
                    <Button
                      fullWidth
                      onClick={handleImportClick}
                      type=''
                      disabled={importing}
                      startIcon={<FileUploadIcon />}
                    >
                      Importar planilha
                    </Button>
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <Button
                      fullWidth
                      variant='outlined'
                      startIcon={<DownloadIcon />}
                      onClick={handleDownloadComponentUnitPrice}
                      disabled={importing}
                    >
                      Baixar planilha
                    </Button>
                  </Grid>

                  {importing ? (
                    <Grid xs={6} lg={12} className={classes.loading}>
                      <Box sx={{ width: '90%' }}>
                        <LinearProgress color='secondary' />
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                        </Grid>
                        <UploadImg className={classes.imgTracker} />
                      </Box>
                    </Grid>
                  ) : (
                    <Grid className={classes.gridImages} xs={6} lg={12}>
                      {' '}
                      <UploadImg className={classes.imgTracker} />
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <WarningIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Máximo de importação por vez - 1.000
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Esperar carregamento completo da importação.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Atenção! Utilize esta tela somente quando necessário.
                    </p>
                  </div>
                </Grid>

                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Atenção! A consulta pode demorar alguns minutos.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
