import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    imgContainer: {
      display: 'flex',
      paddingRight: 50,
    },
    img: {
      height: 250,
    },
  };
});
