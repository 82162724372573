import React, { useContext } from 'react';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Spacer, Select } from 'components';
import { Field, useFormikContext } from 'formik';
import { useBoolean } from 'hooks/useBoolean';

import { BudgetCartContext } from '../..';
import { useStyles } from './styles';

const refuseOptions = [
  {
    value: 'VALOR',
    label: 'VALOR',
  },
  {
    value: 'TEMPO DE REPARO',
    label: 'TEMPO DE REPARO',
  },
  {
    value: 'METODO DE PAGAMENTO',
    label: 'METODO DE PAGAMENTO',
  },
];

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values, setFieldValue, resetForm } =
    useFormikContext();

  console.log('toshiii: ', values);

  const { totalCart } = useContext(BudgetCartContext);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
    setFieldValue('approved', true);
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setFieldValue('approved', false);
  };

  const classes = useStyles();

  const isFirstStep = activeStep === 0;

  console.log('Active Step: ', activeStep);

  return (
    <>
      <div className={classes.footer}>
        <div className={classes.actions}>
          <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
            Voltar
          </Button>
          {activeStep === stepsSize - 1 ? (
            <>
              <Button
                color='secondary'
                onClick={handleOpenInformation}
                disabled={!isValid || !dirty}
              >
                Recusar
              </Button>
              <Button
                type='submit'
                color='primary'
                onClick={() => {
                  handleSubmit();
                  setFieldValue('approved', true);
                }}
                disabled={!isValid || !dirty}
                loading={isSubmitting}
              >
                Aprovar
              </Button>
            </>
          ) : (
            <Button color='primary' loading={isSubmitting} onClick={handleNext}>
              Próxima Página
            </Button>
          )}
        </div>
      </div>

      <Dialog
        maxWidth='md'
        scroll='paper'
        onClose={() => handleCloseInformation()}
        aria-labelledby='customized-dialog-title'
        open={isDetailsOpen}
      >
        <Dialog.Title
          onClose={() => {
            handleCloseInformation();
          }}
        >
          Reprovação
        </Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Field
                size='small'
                name='reason_refuse'
                variant='outlined'
                label='Motivo da Recusa'
                component={Select}
                options={refuseOptions}
              />
            </Grid>
          </Grid>

          <Spacer size={8} />

          <Grid container>
            <Button onClick={() => handleCloseInformation()} color='primary' variant='text'>
              Fechar
            </Button>
            <Button
              onClick={handleSubmit}
              color='primary'
              type='submit'
              disabled={!values.reason_refuse}
            >
              Confirmar
            </Button>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
