import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button, Checkbox, Spacer, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  serial_number: Yup.string().required(),
});

const columns = [
  { label: 'Incluir', align: 'center' },
  { label: 'Service', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Modelo', align: 'center' },
  { label: 'Imei', align: 'center' },
  { label: 'Cor', align: 'center' },
  { label: 'Check-Kit', align: 'center' },
  { label: 'Minuta', align: 'center' },
];

export function AssurantMinute() {
  const [checkboxSelections, setCheckboxSelections] = useState({});
  const [page, setPage] = useState(0);
  const [startIndex, setStartIndex] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [search, setSearch] = useState('');

  const [assurantServiceOrders, , loadingAssurantServiceOrders, refetchAssurantServiceOrders] =
    useQuery(
      () => serviceOrderService.listAssurantServiceOrders(startIndex, rowsPerPage, search.trim()),
      [],
    );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  let x = 1;

  const assurantServiceData = (assurantServiceOrders?.services || []).map((item) => ({
    id: item?.id,
    service: item?.number,
    color: item?.color_product,
    imei: item?.imei,
    model: item?.model,
    partNumber: item?.service_part_number,
    product: item?.product_type?.description,
    checkKit: item?.checkKit !== null ? true : false,
    minute: item?.minute === null || item?.minute === undefined ? false : true,
    invoice: item?.invoice?.number,
    object: item?.object?.range?.full_object,
    quantity: 1,
  }));

  const handleDownloadTagsClick = async (selectedItems) => {
    const { data } = await serviceOrderService.createAssurantMinute(selectedItems);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `minuta.pdf`);
  };

  const onSubmitHandler = async () => {
    try {
      const selectedItems = filteredData.filter((item) => checkboxSelections[item.id]);
      handleDownloadTagsClick(selectedItems).then(() => {
        toast.success('Minuta gerada com sucesso.');
        setCheckboxSelections({});
        refetchAssurantServiceOrders();
        props.resetForm();
      });
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro na geração de minuta.');
    }
  };

  const filteredData = assurantServiceData.filter((searchData) => {
    const upperSerivce = searchData.service?.toUpperCase();
    const lowerService = searchData.service?.toLowerCase();
    const upperModel = searchData.model?.toUpperCase();
    const lowerModel = searchData.model?.toLowerCase();
    const imei = searchData.imei;

    return (
      upperSerivce?.includes(search) ||
      lowerService?.includes(search) ||
      upperModel?.includes(search) ||
      lowerModel?.includes(search) ||
      imei?.includes(search)
    );
  });

  const filteredDataWithSelection = filteredData.map((item) => ({
    ...item,
    isSelected: checkboxSelections[item.id] || false,
  }));

  const sortedData = [...filteredDataWithSelection].sort((a, b) => {
    if (a.isSelected && !b.isSelected) return -1;
    if (!a.isSelected && b.isSelected) return 1;
    return 0;
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setPage(0);
    setStartIndex(1);
    setSearch(value);
  };

  return (
    <>
      <PageTitle>Minuta Assurant</PageTitle>
      <Formik
        initialValues={{ service: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Pesquisar'
                  name='service'
                  variant='outlined'
                  size='small'
                  component={Text}
                  onChange={handleSearchChange}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button type='submit' onClick={onSubmitHandler} fullWidth>
                  Gerar Minuta
                </Button>
              </Grid>
            </Grid>
            <Spacer size={30} />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Table
                  emptyMessage='Nenhum registro encontrado.'
                  headers={columns}
                  stripped
                  loading={loadingAssurantServiceOrders}
                  data={sortedData}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration={true}
                >
                  {sortedData?.map((item) => (
                    <TableRow key={item?.id}>
                      <TableCell align='center' padding='checkbox' size='small'>
                        <Checkbox
                          onChange={() => {
                            setCheckboxSelections((prevState) => ({
                              ...prevState,
                              [item.id]: !prevState[item.id],
                            }));
                          }}
                          checked={checkboxSelections[item.id] || false}
                          disabled={!item.checkKit}
                          color='primary'
                        />
                      </TableCell>
                      <TableCell align='center'>{item?.service}</TableCell>
                      <TableCell align='center'>{item?.product}</TableCell>
                      <TableCell align='center'>{item?.model}</TableCell>
                      <TableCell align='center'>{item?.imei}</TableCell>
                      <TableCell align='center'>{item?.color}</TableCell>
                      <TableCell align='center'>
                        {item?.checkKit ? (
                          <CheckCircleIcon style={{ color: '#17C13E' }} />
                        ) : (
                          <CancelIcon style={{ color: '#D9534F' }} />
                        )}
                      </TableCell>
                      <TableCell align='center'>
                        {item?.minute ? (
                          <CheckCircleIcon style={{ color: '#17C13E' }} />
                        ) : (
                          <CancelIcon style={{ color: '#D9534F' }} />
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
