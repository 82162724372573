import React, { createContext, useEffect, useState } from 'react';
import { BiTransfer } from 'react-icons/bi';
import { QueryClient, QueryClientProvider } from 'react-query';

import Grid from '@material-ui/core/Grid';

import { green } from '@mui/material/colors';
import { Select, Spacer } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';
import { TableBoxAcessories } from './TableBoxAcessories';
import { TableStockAcessories } from './TableStockAcessories';

export const validationSchema = yup.object().shape({
  component_level_location_id: yup.string().required(),
});

export const StockContext = createContext();
const queryClient = new QueryClient();

export const BalanceTransfer = () => {
  const classes = useStyles();

  const [componentLevelLocation, setComponentLevelLocation] = useState();

  const [componentsAccessories, setComponentsAccessories] = useState([]);
  const [componentsFg, setComponentsFg] = useState([]);

  const [componentsAccessoriesData, , , reFetchComponentsAccessories] = useQuery(
    () => componentService.listComponentsStock(),
    [],
  );

  const [componentsFgData, , , reFetchComponentsFg] = useQuery(
    () => componentService.listComponentsStockFg(),
    [],
  );

  useEffect(() => {
    setComponentsAccessories(componentsAccessoriesData);
    setComponentsFg(componentsFgData);
  }, [componentsAccessoriesData, componentsFgData]);

  const levelLocationsOptions = [
    {
      value: 1,
      label: 'Estoque de Componentes',
    },
    {
      value: 2,
      label: 'Vendas',
    },
    {
      value: 3,
      label: 'Estoque de Componentes Fora de Garantia',
    },
  ];

  return (
    <>
      <StockContext.Provider
        value={{
          reFetchComponentsAccessories,
          reFetchComponentsFg,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <PageTitle>Transferência</PageTitle>
          <Formik
            initialValues={{ component_level_location_id: '' }}
            validationSchema={validationSchema}
          >
            {(props) => (
              <>
                <Form onSubmit={props.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6} lg={4}>
                      <Field
                        size='small'
                        fullWidth
                        name='component_level_location_id'
                        variant='outlined'
                        label='Tipo de estoque'
                        emptyOption='Selecione'
                        options={levelLocationsOptions}
                        component={Select}
                        onChange={(e) => setComponentLevelLocation(e)}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </>
            )}
          </Formik>

          <Grid item xs={8} lg={3}>
            {/* <Typography variant='overline' className={classes.legend} gutterBottom>
              Legenda
            </Typography> */}
            <Spacer size={12} />
            <div className={classes.containerLegend}>
              <BiTransfer fontSize='small' sx={{ color: green[400] }} className={classes.icon} />
              <p variant='caption' className={classes.p}>
                Transferir acessórios
              </p>
            </div>
          </Grid>
          <Spacer size={12} />

          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              {componentLevelLocation === 1 ? (
                <>
                  <TableStockAcessories componentsAccessories={componentsAccessories} />
                </>
              ) : componentLevelLocation === 3 ? (
                <>
                  <TableBoxAcessories componentsFg={componentsFg} />
                </>
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </QueryClientProvider>
      </StockContext.Provider>
    </>
  );
};
