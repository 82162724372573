import { addToCart, cleanCart, removeFromCart, updateAmount } from './reducerFunctions';

export const cartReducer = (state, action) => {
  switch (action.type) {
    case '@ADD_TO_CART':
      return addToCart(state, action);

    case '@REMOVE_FROM_CART':
      return removeFromCart(state, action);

    case '@UPDATE_AMOUNT':
      return updateAmount(state, action);

    case '@CLEAN_CART':
      return cleanCart(state, action);

    default:
      break;
  }
};
