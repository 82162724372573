import React from 'react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { useMutation } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, Spacer, Table } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { CREATE_SERVICE_ORDER } from 'services/graphql/query/service-orders';

const columns = [
  { label: 'Voucher', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'Produto', align: 'center' },
  { label: 'Condição', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'Cliente', align: 'center' },
  { label: 'Erro', align: 'center' },
  { label: 'Ações', align: 'center' },
];

export const ProductTable = ({ data, setLoading, loading, reset, setResults }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [itemsWithError, setItemsWithError] = useState(new Set());

  const [createServiceOrder] = useMutation(CREATE_SERVICE_ORDER, {
    onCompleted: () => {
      toast.success('Ordens de serviço criadas!');
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao criar as ordens de serviço, tente novamente!';
      toast.error(`Error: ${errorMessage}`);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    const errorIndices = new Set();
    data.forEach((item, index) => {
      if (item?.error !== '') {
        errorIndices.add(index);
      }
    });
    setItemsWithError(errorIndices);
  }, [data]);

  const handleRemoveItem = (index) => {
    const newData = data.filter((_, idx) => idx !== index);
    setResults(newData);
  };

  const handleKeepItem = (index) => {
    const newData = data.map((item, idx) => {
      if (idx === index) {
        return { ...item, error: undefined };
      }
      return item;
    });
    setResults(newData);

    setItemsWithError((prev) => {
      const newSet = new Set(prev);
      newSet.delete(index);
      return newSet;
    });
  };

  const onSubmit = async () => {
    if (itemsWithError.size > 0) {
      toast.error('Existem erros nos itens. Por favor, resolva-os antes de confirmar.');
      return;
    }

    toast.info('Salvando...');
    setLoading(true);

    await createServiceOrder({
      variables: { data },
    });
    reset();
    setLoading(false);
  };

  return (
    <>
      <Spacer size={40} />
      <Paper elevation={2}>
        <PageTitle>Resultados</PageTitle>

        <Table
          loading={loading}
          headers={columns}
          striped
          emptyMessage='Nenhum registro encontrado.'
          data={data}
          disableNumeration
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        >
          {data?.map((item, key) => {
            const hasError = item?.error !== undefined;
            return (
              <TableRow key={key} style={{ backgroundColor: hasError ? 'red' : 'inherit' }}>
                <TableCell align='center'>{item?.sinister}</TableCell>
                <TableCell align='center'>{item?.sku}</TableCell>
                <TableCell align='center'>{item?.product}</TableCell>
                <TableCell align='center'>{item?.condition}</TableCell>
                <TableCell align='center'>{item?.imei}</TableCell>
                <TableCell align='center'>{item?.client_name}</TableCell>
                <TableCell align='center' style={{ color: hasError ? 'red' : 'inherit' }}>
                  {hasError ? item?.error : '---'}
                </TableCell>
                <TableCell align='center'>
                  {hasError ? (
                    <>
                      {/* <IconButton onClick={() => handleKeepItem(key)}>
                        <CheckCircleIcon sx={{ color: 'green' }} />
                      </IconButton> */}
                      <IconButton onClick={() => handleRemoveItem(key)}>
                        <CancelIcon sx={{ color: 'red' }} />
                      </IconButton>
                    </>
                  ) : (
                    '---'
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </Paper>
      <Spacer size={30} />
      <Grid container justifyContent='center'>
        <Grid item xs={12} md={12} lg={12}>
          <Typography style={{ color: 'blue', textAlign: 'end' }}>
            Quantidade de items: {data?.length}
          </Typography>
          <Typography style={{ color: 'red', textAlign: 'end' }}>
            Quantidade de erros: {itemsWithError.size}
          </Typography>
        </Grid>
      </Grid>
      <Spacer size={25} />
      <Grid container justifyContent='space-between'>
        <Grid></Grid>
        <Grid>
          <Button
            fullWidth
            color='secondary'
            loading={loading}
            onClick={() => onSubmit()}
            disabled={itemsWithError.size > 0}
          >
            Confirmar abertura de os
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
