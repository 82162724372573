import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  input_purpose_id: yup.number().required(),
  invoice_key: yup.string().required(),
  invoice_number: yup.string().required(),
  provider_id: yup.string().required(),
  serial_number: yup.string().required(),
  component_level_location_id: yup.number().required(),
});
