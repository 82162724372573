export const tutorialSteps = [
  {
    target: '#service-order-number',
    content:
      'Para cadastrar sua ordem de serviço, é necessário que você informe o número da ordem de serviço.',
  },
  {
    target: '#service-order-number',
    content: 'Basta você digitar o número nesse campo e clicar fora dele.',
  },
];
