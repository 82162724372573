import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Table } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

import { ValidationDialog } from './ValidationDialog';

const columns = [{ label: 'Service ' }, { label: 'Motivo' }, { label: 'Valor' }];
const rows = [];
export const ValidateAnalysisGrade = () => {
  const [open, setOpenState] = useBoolean();

  const [invoice, setInvoice] = useState({});
  const [invoices, setInvoices] = useState([]);

  const [invoicesData, , loadingInvoicesData, refetchInvoicesData] = useQuery(
    () => invoiceService.listIncludedInvoicesWithoutValidation(),
    [],
  );

  const handleCloseInformartion = () => {
    setOpenState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setOpenState.toTrue();

    console.log('Item: ', item);

    setInvoice(item);
  };

  useEffect(() => {
    setInvoices(invoicesData);
  }, [invoicesData]);

  return (
    <>
      <ValidationDialog
        open={open}
        onClose={handleCloseInformartion}
        invoice={invoice}
        reloadInvoices={refetchInvoicesData}
      />
      <PageTitle>Validar nota de análise</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            size='small'
            label='Pesquisar'
            name='search'
            variant='outlined'
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Table
            headers={columns}
            striped
            size='small'
            disableNumeration
            maxHeight={400}
            loading={loadingInvoicesData}
            data={invoicesData}
            emptyMessage='Nenhuma'
            actionsLabel='Confirmar'
            onActionsClick={handleOpenInformation}
          >
            {invoices?.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.number}</TableCell>
                <TableCell>{invoice.description}</TableCell>
                <TableCell>
                  <FormatMoney>{invoice.price}</FormatMoney>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
