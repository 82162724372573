import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { FormatDate, Checkbox, Text, DatePicker, Button } from 'components';
import { SimpleTable } from 'components/SimpleTable';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { mailObjectService } from 'services';

import { useStyles } from './styles';

export const TableWaiting = ({
  loadingValidObjects,
  validOutObjects,
  page,
  handleChangePage,
  handleSearchChange,
  total,
  postData,
  setPostData,
}) => {
  const classes = useStyles();
  const [getSelectedAll, setSelectedAll] = useBoolean(false);
  const [getSelectedRow, setSelectedRow] = useState([]);

  const updateAllRowsChecked = () => {
    if (total !== getSelectedRow.length) {
      setSelectedRow(
        (validOutObjects || []).map((item) => ({ service_order_id: item.service_order_id })),
      );
    } else {
      setSelectedRow([]);
    }
    setSelectedAll.toggle();
  };

  const columns = [
    { label: 'Service', align: 'center' },
    { label: 'Sinistro', align: 'center' },
    { label: 'Processo', align: 'center' },
    { label: 'Nome Cliente', align: 'center' },
    { label: 'Objeto', align: 'center' },
    { label: 'Contrato', align: 'center' },
    { label: 'Data postagem', align: 'center' },
    {
      label: (
        <Checkbox
          color='secondary'
          checked={getSelectedAll}
          onChange={() => updateAllRowsChecked()}
        />
      ),
      align: 'center',
    },
  ];

  function updateRowsChecked(item) {
    const updatedRow = [...getSelectedRow];

    const index = updatedRow.indexOf(item.service_order_id);
    if (index !== -1) {
      updatedRow.splice(index, 1);
      setSelectedAll.toFalse();
    } else {
      updatedRow.push({ service_order_id: item.service_order_id });
    }

    if (updatedRow.length === total) {
      setSelectedAll.toTrue();
    }

    setSelectedRow(updatedRow);
  }

  const handleDownloadTags = async (header_id) => {
    const { data } = await mailObjectService.createMinuteTag(header_id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'minuta.pdf');
  };

  async function handleCreateMinute({ setSubmitting }) {
    try {
      setSubmitting(true);
      const { data } = await mailObjectService.createMinute(getSelectedRow);

      await handleDownloadTags(data.minute_id);

      toast.success('Minuta gerada com sucesso');

      updateAllRowsChecked();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao gerar a minuta');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <Formik initialValues={{ search: '', post_data: postData }} onSubmit={handleCreateMinute}>
        {(props) => (
          <Form>
            <Grid container className={classes.root} spacing={2}>
              <Grid item xs={12} md={5} lg={5}>
                <Field
                  label='Service/ Sinistro/ Processo/ Cliente/ CPF/ Objeto/ Contrato'
                  size='small'
                  name='search'
                  variant='outlined'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  onChange={handleSearchChange}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Field
                  size='small'
                  name='post_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data da postagem'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button
                  onClick={() => handleCreateMinute(props)}
                  disabled={getSelectedRow.length <= 0}
                  loading={props.isSubmitting}
                >
                  {' '}
                  Gerar Minuta{' '}
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setPostData(props.values.post_data);
            }, [props])}
          </Form>
        )}
      </Formik>

      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <SimpleTable
            headers={columns}
            totalCount={total ?? 1}
            isLoading={loadingValidObjects}
            handleChangePage={handleChangePage}
            page={page}
          >
            {validOutObjects?.map((validOutObject) => (
              <TableRow key={validOutObject.id}>
                <TableCell align='center'>{validOutObject?.service?.number}</TableCell>
                <TableCell align='center'>{validOutObject?.service?.sinister}</TableCell>
                <TableCell align='center'>
                  {validOutObject?.service?.process?.description}
                </TableCell>

                <TableCell align='center'>{validOutObject?.service?.client?.name}</TableCell>
                <TableCell align='center'>
                  {validOutObject?.sigep_range_object?.full_object}
                </TableCell>
                <TableCell align='center'>
                  {validOutObject?.sigep_range_object?.mailContract?.contract_number +
                    ' ' +
                    validOutObject?.sigep_range_object?.mailContract?.contract_method +
                    ' ' +
                    validOutObject?.sigep_range_object?.mailContract?.additional_information}
                </TableCell>
                <TableCell align='center'>
                  <FormatDate format='dd/MM/yyyy HH:mm'>{validOutObject?.created_at}</FormatDate>
                </TableCell>
                <TableCell align='center'>
                  <Checkbox
                    checked={
                      getSelectedAll ||
                      getSelectedRow?.some(
                        (item) => item.service_order_id === validOutObject.service_order_id,
                      )
                    }
                    onChange={() => updateRowsChecked(validOutObject)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </SimpleTable>
        </Grid>
      </Grid>
    </>
  );
};
