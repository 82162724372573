import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: '5px',
    },
    divContaiver: {
      fontSize: '16px',
    },
    container: {
      display: 'flex',
    },
    tableButton: {
      fontSize: 11,
      height: 30,
    },
    captions: {
      width: 'fit-content',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 20,

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
  };
});
