import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Spacer, Table, Select, FormatDate } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const columns = [
  {
    label: 'Nome Cliente',
    width: 130,
  },
  { label: 'Ordem de serviço' },
  { label: 'Status orçamento' },
  { label: 'Data de orçamento' },
  { label: 'Valor mão de obra' },
  { label: 'Valor Final de orçamento' },
];

export function BudgetRetail() {
  const classes = useStyles();

  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const [retailts, , loadingRetails] = useQuery(budgetService.listBudgetRetail, []);

  console.log('retailts', retailts);

  const billingOptionsOnly = (retailts || []).map((retailItens) => ({
    value: retailItens.add_info,
    label: retailItens.add_info,
  }));

  const formikRef = useRef();

  const [retailnames, setRetail] = useState([]);

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const retailtByName = await budgetService.listBudgetRetailByName(values.client_name);

      console.log('client name', values.client_name);

      const database = retailtByName.data;

      console.log('teste', retailnames);

      setRetail(database);
      setSubmitting(true);

      toast.success('Orçamentos carregados com sucesso.');
    } catch (err) {
      toast.error('Erro ao encontrar cliente/orçamento');
    } finally {
      setSubmitting(false);
    }
  };

  let total = 0;

  retailnames?.map((totalPrice) => {
    total += totalPrice.final_budget_price;
  });

  let name_client = '';

  retailnames?.map((totalPrice) => {
    name_client = totalPrice.add_info;
  });

  // const createLinkHandler = async () => {
  //   budgetService.createLink(values.client_id, values.final_budget_price);
  // };

  return (
    <>
      <PageTitle>Cobrança mensal de VAREJO</PageTitle>
      <Formik innerRef={formikRef} initialValues={{ client_name: '' }} onSubmit={onSubmitHandler}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Cliente'
                  name='client_name'
                  variant='outlined'
                  size='small'
                  options={billingOptionsOnly}
                  component={Select}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button size='large' variant='text' fullWidth>
                  Limpar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            <Table
              headers={columns}
              striped
              maxHeight={350}
              emptyMessage='Nenhum registro encontrado.'
              disableNumeration
            >
              {retailnames?.map((retailname) => (
                <TableRow key={retailname.id}>
                  <TableCell>{retailname.name}</TableCell>
                  <TableCell>{retailname.number}</TableCell>
                  <TableCell>{retailname.status_desc}</TableCell>
                  <TableCell>
                    {' '}
                    <FormatDate format='dd/MM/yyyy HH:mm:ss'>{retailname.created_at}</FormatDate>
                  </TableCell>
                  <TableCell>
                    <FormatMoney>{retailname.additional_services_total_price}</FormatMoney>
                  </TableCell>
                  <TableCell align='center'>
                    <FormatMoney>{retailname.final_budget_price}</FormatMoney>
                  </TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              ))}
            </Table>
            <Grid item xs={5} md={5} lg={5}>
              <Typography variant='h6'>
                Total em orçamentos: <FormatMoney>{total}</FormatMoney>
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              {' '}
              <Grid item xs={5} md={5} lg={5}>
                <Button color='secondary'>Gerar cobrança</Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}
