import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as ImgRegisterAttendant } from 'assets/images/addAttendant.svg';
import { Button, Text, Table } from 'components';
import { Formik, Field, Form, useFormikContext } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { AlterAttendantDialog as AlterAttendantDialog } from './AlterAttendantDialog';
import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  name: yup.string().required(),
});

const columns = [
  { label: 'Email', width: 250 },
  { label: 'Nome completo' },
  { label: 'Situação' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const RegisterAttendant = () => {
  const userId = useContextSelector(AuthContext, (context) => context.user.id);
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [attendant, setAttendant] = useState({});
  const [attendants, setAttendants] = useState([]);

  const [attendantData, , loadingAttendantData, refetchAttendant] = useQuery(
    () => accountService.getIsAttendant(),
    [],
  );

  useEffect(() => {
    setAttendants(attendantData);
  }, [attendantData]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setAttendant(item);
  };

  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      await accountService.setAsAttendant(values.email, values.name, userId);

      toast.success('Usuário atribuído como atendente com sucesso.');
      refetchAttendant();
      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Erro ao realizar a atribuição do usuário como atendente.',
      );
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  const AttendantName = () => {
    const { setFieldValue } = useFormikContext();

    const handleEmailBlur = async (value) => {
      try {
        console.log('VALUE: ', value);
        const data = await accountService.getUserByEmail(value);

        const { email, name } = data.data;

        console.log('DOCS: ', data.data);
        console.log('NAME: ', name);

        setFieldValue('name', name);
        if (email === undefined || email === '') {
          toast.error('Informe um e-mail válido para prosseguir');
        }
      } catch (err) {
        toast.error(err.response?.data?.message || 'Erro ao encontrar atendente.');
      } finally {
      }
    };

    return (
      <Field
        variant='outlined'
        label='E-mail'
        name='email'
        size='small'
        component={Text}
        onBlur={(e) => handleEmailBlur(e.target.value)}
      />
    );
  };

  return (
    <>
      <PageTitle>Atendente</PageTitle>
      <Formik
        initialValues={{ email: '', name: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <AttendantName />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='name'
                    label='Nome completo'
                    component={Text}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingAttendantData}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Nenhum registro encontrado'
                    // onActionsClick={handleOpenInformation}
                    data={attendantData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {attendants?.map((attendant) => (
                      <TableRow key={attendant.id}>
                        <TableCell>{attendant.email}</TableCell>
                        <TableCell>{attendant.name}</TableCell>
                        <TableCell>
                          {attendant?.is_attendant_active ? 'Ativo' : 'Inativo'}
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, attendant)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterAttendantDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  attendant={attendant}
                  reloadAttendants={refetchAttendant}
                />
                <Grid className={classes.containerImg} item xs={12} lg={4}>
                  <ImgRegisterAttendant className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
