import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { VALID_B2B_ORDER } from 'services/graphql/query/b2b';

const examples = [
  {
    value: 1,
    label: 'Importe a planilha do pedido B2B',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportButton() {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const [validProducts] = useMutation(VALID_B2B_ORDER, {
    onCompleted: (data) => {
      toast.success('Produtos validados com sucesso!');
      setImporting(false);
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleReset = () => {
    inputFileRef.current.value = '';
  };

  async function handleImportStore(e) {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;
          console.log('header', header);

          dataLines.shift();

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              if (
                values[0] == null || // GRADE
                values[2] == null || // Quantidade
                values[4] == null || // Ganhador
                values[6] == null || // IMEI
                values[7] == null || // COD_ITEM
                values[13] == null || // UNIT_PRICE
                values[15] == null || // LOCAL
                values[18] == null || // RESERVA
                values[19] == null // LOTE
              ) {
                return null;
              }

              return {
                grade: values[0],
                amount: values[2],
                client: values[4],
                imei: values[6],
                sku: values[7],
                value: values[13],
                origin: values[15],
                reserve: values[18],
                lote: values[19],
              };
            })
            .filter((item) => item !== null);

          await validProducts({
            variables: { data: products },
          });

          // setResults(data?.validProductWithServiceOrder);
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />

      <Button fullWidth startIcon={<AddCircleIcon />} onClick={() => handleOpenDialog()}>
        Importar pedido
      </Button>

      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
