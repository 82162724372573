import React from 'react';

import Typography from '@material-ui/core/Typography';

import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';

export const ConfirmDialog = ({ open, onClose, selectedOrdering }) => {
  console.log('HAHAHAHA: ', selectedOrdering);

  // const IncludedInvoiceType = async (type) => {
  //   switch (type) {
  //     case 1:
  //       break;
  //     case 2:
  //       break;
  //     case 3:
  //       const invoice_sell = await invoiceService.downloadDanfe(selectedOrdering?.id);

  //       if (invoice_sell?.data?.retorno?.codigo_erro)
  //         toast.error(invoice_sell?.data?.retorno?.erros[0]?.erro);
  //       else window.open(invoice_sell.data?.retorno?.link_nfe, '_blank').focus();

  //       break;
  //     case 4:
  //       const { data } = await invoiceService.downloadDanfeAnaliseNF(selectedOrdering?.id);

  //       if (data?.retorno?.codigo_erro) toast.error(data?.retorno?.erros[0]?.erro);
  //       else window.open(data?.retorno?.nota_fiscal?.link_impressao, '_blank').focus();

  //       break;
  //     default:
  //   }

  //   onClose();
  // };

  const openInNewTab = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf', 'target', '_blank');
    document.body.appendChild(link);
    link.click();
  };

  const downloadInNewTab = (url) => {
    window.location.href = url;
  };

  console.log('dialog', selectedOrdering.link_invoices);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <Dialog.Title onClose={onClose}>Aviso</Dialog.Title>
      <Dialog.Content>
        <Typography>Deseja baixar o PDF referente a nota?</Typography>
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} variant='text' color='default'>
          Não
        </Button>
        <Button onClick={(e) => downloadInNewTab(selectedOrdering.link_invoice)}>Sim</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
