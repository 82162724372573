import React from 'react';

import { Button } from 'components';
import { useFormikContext } from 'formik';
import { useBoolean } from 'hooks/useBoolean';

import { DialogTechnicalReport } from './DialogTechnicalReport';
import { useStyles } from './styles';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const { handleSubmit, isSubmitting, isValid, dirty } = useFormikContext();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
  };

  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Button disabled={activeStep === 0} onClick={handleBack} className={classes.backButton}>
        Voltar
      </Button>
      {activeStep === stepsSize - 1 && (
        <Button
          color='primary'
          disabled={!isValid || !dirty}
          onClick={() => handleOpenInformation()}
        >
          Enviar
        </Button>
        // <Button
        //   type='submit'
        //   color='primary'
        //   onClick={handleSubmit}
        //   loading={isSubmitting}
        //   disabled={!isValid || !dirty}
        // >
        //   Enviar
        // </Button>
      )}
      {activeStep !== stepsSize - 1 && (
        <Button color='primary' loading={isSubmitting} onClick={handleNext}>
          Próxima Página
        </Button>
      )}
      <DialogTechnicalReport open={isDetailsOpen} handleClose={handleCloseInformation} />
    </div>
  );
};
