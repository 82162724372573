import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import cep from 'cep-promise';
import { Text, Button, Select, Table } from 'components';
import { Formik, Form, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api, clientService } from 'services';

import { AlterClientDialog } from './AlterClientDialog';
import { validationSchema } from './validation';

const columns = [
  { label: 'Nome' },
  { label: 'CGC' },
  { label: 'E-mail' },
  { label: 'Situação' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const RegisterClient = () => {
  const formikRef = useRef();

  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingcgc, setLoadingCgc] = useState(false);
  const [search, setSearch] = useState('');

  const [clientsData, , loadingClientsData, clientsDataRefetch] = useQuery(
    clientService.listClients,
    [],
  );

  const filteredClients = (clientsData || []).filter((clients) => {
    const lowerClientName = clients?.name?.toLowerCase();
    const upperClientName = clients?.name?.toUpperCase();
    const cgc = clients?.cgc?.toLowerCase();

    return (
      lowerClientName?.includes(search) ||
      upperClientName?.includes(search) ||
      cgc?.includes(search) ||
      cgc?.includes(search)
    );
  });

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState({});

  useEffect(() => {
    setClients(clientsData);
  }, [clientsData]);

  const formattedClientsData = (clientsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredClientsData = formattedClientsData.filter((element) => {
    const lowerName = element.name?.toLowerCase();
    const lowerEmail = element.email?.toLowerCase();

    return lowerName?.includes(search) || lowerEmail?.includes(search);
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setClient(item);
  };

  const onBlurCep = async (zipcode) => {
    setLoadingCep(true);

    const { state, city, neighborhood, street } = await cep(zipcode.target.value);
    formikRef.current.setFieldValue('state', state);
    formikRef.current.setFieldValue('city', city);
    formikRef.current.setFieldValue('district', neighborhood);
    formikRef.current.setFieldValue('street', street);

    setLoadingCep(false);
  };

  const consultarCNPJ = require('consultar-cnpj');

  // O Token é opcional

  const getCNPJ = async (cgc) => {
    const empresa = await consultarCNPJ(cgc);
    formikRef.current.setFieldValue(
      'ie',
      empresa?.estabelecimento?.inscricoes_estaduais[0]?.inscricao_estadual,
    );
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await clientService.createClient(values);

      toast.success('Novo cliente cadastrado com sucesso.');

      clientsDataRefetch();
      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const [states, , loadingStates] = useQuery(
    () => api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados'),
    [],
  );

  const ufsOptions = (states || []).map((state) => {
    return {
      value: state.sigla,
      label: state.sigla,
    };
  });

  return (
    <>
      <PageTitle>Clientes</PageTitle>

      <Formik
        innerRef={formikRef}
        initialValues={{
          cgc: '',
          street: '',
          street_number: '',
          zipcode: '',
          name: '',
          city: '',
          state: '',
          email: '',
          district: '',
          complement: '',
          residential_phone_number: '',
          phone_number: '',
          observation: '',
          ie: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    label='CPF/CNPJ do cliente'
                    name='cgc'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    mask='document'
                    onBlur={(e) => {
                      getCNPJ(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    label='Incrição Estadual'
                    name='ie'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    onBlur={(e) => {
                      getCNPJ(e);
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Field
                    variant='outlined'
                    label='Nome do cliente'
                    name='name'
                    size='small'
                    component={Text}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    mask='zipcode'
                    size='small'
                    fullWidth
                    name='zipcode'
                    label='CEP'
                    variant='outlined'
                    component={Text}
                    onBlur={(e) => {
                      onBlurCep(e);
                    }}
                    loading={loadingCep}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Cidade'
                    name='city'
                    component={Text}
                    loading={loadingCep}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Estado'
                    name='state'
                    component={Select}
                    loading={loadingCep}
                    options={ufsOptions}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Bairro'
                    name='district'
                    component={Text}
                    loading={loadingCep}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Rua'
                    name='street'
                    component={Text}
                    loading={loadingCep}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Complemento'
                    name='complement'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Número'
                    name='street_number'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <Field
                    variant='outlined'
                    label='Telefone Fixo'
                    name='residential_phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <Field
                    variant='outlined'
                    label='Celular'
                    name='phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    variant='outlined'
                    label='Email'
                    name='email'
                    type='email'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    maxHeight
                    striped
                    emptyMessage='Sem clientes cadastrados.'
                    loading={loadingClientsData}
                    data={clientsData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    //  disableNumeration
                  >
                    {filteredClients?.map((client) => (
                      <TableRow key={client.id}>
                        <TableCell>{client.name}</TableCell>
                        <TableCell>{client.cgc ?? 'Não cadastrado'}</TableCell>

                        <TableCell>{client.email}</TableCell>
                        <TableCell>{client.active ? 'Ativo' : 'Inativo'}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, client)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterClientDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  client={client}
                  reloadClients={clientsDataRefetch}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
