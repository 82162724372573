import React, { useMemo, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Button } from 'components/Button';
import { Text } from 'components/Form/Text';
import { FormatMoney } from 'components/FormatMoney';
import { RowsEmpty } from 'components/Table/Empty';
import { RowsLoader } from 'components/Table/Loader';
import { useBoolean } from 'hooks/useBoolean';
import { useContextSelector } from 'use-context-selector';

import { RequestComponentContext } from '../context';
import { OrderDialog } from './OrderDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Preço (un)' },
  { label: 'Local' },
  { label: 'Quantidade', align: 'center' },
];

export const Components = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [selectedComponent, setSelectedComponent] = useState();
  const [ordering, setOrdering] = useState();
  const [open, setOpenState] = useBoolean();

  const components = useContextSelector(RequestComponentContext, (context) => context.components);
  const loadingComponents = useContextSelector(
    RequestComponentContext,
    (context) => context.loadingComponents,
  );

  const handleChange = (e) => {
    const v = e.target.value;

    setSearch(v);
  };

  const onSelectRowClick = (component) => {
    setSelectedComponent(component);
  };

  const filteredComponents = useMemo(
    () =>
      (components || []).filter((c) => {
        const lowerDesc = c.description.toLowerCase();
        const lowerCompDesc = c.component_location.description.toLowerCase();
        const lowerPartNumber = c.part_number.toLowerCase();

        return (
          lowerDesc.includes(search) ||
          c.quantity == search ||
          lowerPartNumber.includes(search) ||
          lowerCompDesc.includes(search)
        );
      }),
    [components, search],
  );

  const columnsCount = columns.length + 1;

  return (
    <>
      <OrderDialog
        open={open}
        onClose={setOpenState.toFalse}
        selectedComponent={selectedComponent}
        setOrdering={setOrdering}
      />
      <Grid className={classes.root} container spacing={2} justify='space-between'>
        <Grid item xs={12} md={4}>
          <Text
            label='Consultar componente'
            size='small'
            variant='outlined'
            fullWidth
            onChange={handleChange}
            value={search}
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Button
            color='primary'
            onClick={setOpenState.toTrue}
            fullWidth
            disabled={!selectedComponent}
            loading={ordering}
          >
            Requisitar
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.tableRoot} maxHeight>
            <Table striped stickyHeader>
              <TableHead className={classes.head}>
                <TableRow>
                  <TableCell padding='checkbox' size='small' align='center'>
                    #
                  </TableCell>
                  {columns.map((header) => (
                    <TableCell
                      key={header.label}
                      variant='head'
                      width={header.width}
                      align={header.align || 'left'}
                    >
                      {header.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!filteredComponents?.length && !loadingComponents && (
                  <RowsEmpty
                    emptyMessage='Nenhum registro encontrado.'
                    columnsCount={columnsCount}
                  />
                )}
                {loadingComponents && <RowsLoader columnsCount={columnsCount} />}
                {!loadingComponents &&
                  filteredComponents?.map((component) => (
                    <TableRow key={component.id}>
                      <TableCell padding='checkbox' size='small'>
                        <Radio
                          color='primary'
                          size='small'
                          checked={selectedComponent?.id === component.id}
                          onChange={() => onSelectRowClick(component)}
                        />
                      </TableCell>
                      <TableCell>{component.part_number}</TableCell>
                      <TableCell>{component.description}</TableCell>
                      <TableCell>
                        <FormatMoney>{component.purchase_price}</FormatMoney>
                      </TableCell>
                      <TableCell>{component.component_location.description}</TableCell>
                      <TableCell align='center'>{component.quantity}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
