import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  service_order_number: Yup.string().required('Campo obrigatório'),
  mail_contract_id: Yup.number().required('Campo obrigatório'),
});

export const validationSchemaManual = Yup.object().shape({
  service_order_number: Yup.string().required('Campo obrigatório'),
  initials: Yup.string().required('Campo obrigatório'),
  range_position_number: Yup.string().required('Campo obrigatório'),
  prefix_country: Yup.string().required('Campo obrigatório'),
});
