import React, { useMemo, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import { Spacer, Dialog, Button } from 'components';
import { saveAs } from 'file-saver';
import { Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService, invoiceService } from 'services';
import { api } from 'services';

import { Actions } from './Actions';
import { InvoiceContextProvider } from './context';
import InvoiceData from './InvoiceData';
import InvoiceItem from './InvoiceItem';
import { validationSchema } from './validation';

export const RegisterInvoice = () => {
  const [isDownloadTagsDialogOpen, setIsDownloadTagsDialogState] = useBoolean(false);
  const [createdInvoice, setCreatedInvoice] = useState({});
  const [disabledFields, setDisabledFields] = useState(false);

  const [billingType, , loadingBillingType] = useQuery(invoiceService.listBillingTypeOnly, []);

  useEffect(() => {
    const noBillingTypeFound = !billingType?.length && !loadingBillingType;
  }, [billingType, loadingBillingType]);

  const [providers, , loadingProviders, refetchProviders] = useQuery(
    () => api.get('/providers'),
    [],
  );

  const billingOptions = (billingType || []).map((billingType) => {
    return {
      value: billingType.id,
      label: billingType.tipoBilling,
    };
  });

  const [inputPurposes, , loadingInputPurposes] = useQuery(() => api.get('/input-purposes'), []);

  const [locations, , loadingLocations] = useQuery(() => api.get('/components/locations'), []);

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      const { invoice_items, location_id, ...rest } = values;

      const formattedItems = invoice_items.map((item) => ({
        ...item,
      }));

      console.log('rerea', invoice_items);
      const formattedValues = {
        ...rest,
        invoice_items: formattedItems,
      };

      console.log('formattedItems', formattedValues);

      const { data } = await invoiceService.createInvoice(formattedValues);
      setCreatedInvoice(data);

      toast.success('Nota fiscal cadastrada com sucesso!');

      setIsDownloadTagsDialogState.toTrue();

      resetForm();
    } catch (err) {
      console.error(err);
      console.log(values.invoice_number);
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
      setDisabledFields(false);
    }
  };

  const handleDownloadTagsClick = async () => {
    const { data } = await invoiceService.downloadComponentsTags(createdInvoice.id);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, 'Etiquetas.pdf');
    setIsDownloadTagsDialogState.toFalse();
  };

  const contextValue = useMemo(
    () => ({
      inputPurposes,
      loadingInputPurposes,
      providers,
      loadingProviders,
      locations,
      levelLocations,
      loadingLevelLocations,
      loadingLocations,
      refetchProviders,
      setCreatedInvoice,
      disabledFields,
      setDisabledFields,
    }),
    [
      inputPurposes,
      loadingInputPurposes,
      providers,
      loadingProviders,
      refetchProviders,
      loadingLevelLocations,
      locations,
      levelLocations,
      loadingLocations,
      disabledFields,
      setDisabledFields,
    ],
  );

  return (
    <>
      <PageTitle>Cadastrar nota fiscal</PageTitle>

      <Dialog
        open={isDownloadTagsDialogOpen}
        onClose={setIsDownloadTagsDialogState.toFalse}
        maxWidth='xs'
      >
        <Dialog.Title onClose={setIsDownloadTagsDialogState.toFalse}>Aviso</Dialog.Title>
        <Dialog.Content>
          <Typography>
            Deseja baixar etiquetas (PDF) dos itens da nota fiscal cadastrada?
          </Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={setIsDownloadTagsDialogState.toFalse} variant='text' color='default'>
            Fechar
          </Button>
          <Button onClick={handleDownloadTagsClick}>Baixar</Button>
        </Dialog.Actions>
      </Dialog>

      <InvoiceContextProvider value={contextValue}>
        <Formik
          initialValues={{
            ncm: '',
            cprod: '',
            cean: '',
            xprod: '',
            cest: '',
            extipi: '',
            cfop: '',
            ucom: '',
            qcom: '',
            vuncom: '',
            vprod: '',
            ceantrib: '',
            utrib: '',
            qtrib: '',
            vuntrib: '',
            indtot: '',
            xped: '',
            nitemped: '',
            emission_date: new Date(),
            receipt_date: new Date(),
            input_purpose_id: '',
            invoice_key: '',
            invoice_number: '',
            provider_id: '',
            serial_number: '',
            invoice_items: [],
            description: '',
            part_number: '',
            quantity: 1,
            location_id: 0,
            component_location_id: '',
            component_level_location_id: '',
            price: 0,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <InvoiceData />

              <Spacer axis='vertical' size={16} />

              <InvoiceItem />

              <Actions />
            </Form>
          )}
        </Formik>
      </InvoiceContextProvider>
    </>
  );
};
