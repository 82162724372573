/* eslint-disable no-restricted-imports */
import React, { useState, useEffect, useRef } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import Quagga from '@ericblade/quagga2';

const BarcodeScannerDialog = ({ open, onClose, onScanSuccess }) => {
  const [scannedCode, setScannedCode] = useState('');
  const scannerRef = useRef(null); // Ref para o elemento scanner

  useEffect(() => {
    let initialized = false; // Flag para saber se o Quagga foi inicializado

    const initQuagga = () => {
      const scannerElement = scannerRef.current;
      if (!scannerElement) {
        console.error('Elemento #scanner não encontrado');
        return;
      }

      Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            constraints: {
              facingMode: 'environment', // Usa a câmera traseira
            },
            target: scannerElement, // Usa o ref para o elemento onde a câmera será exibida
          },
          decoder: {
            readers: ['code_128_reader'], // Tipo de código a ser lido
          },
          locate: true,
        },
        (err) => {
          if (err) {
            console.error('Erro ao iniciar Quagga:', err);
            return;
          }
          console.log('Quagga inicializado');
          initialized = true; // Marcar como inicializado
          Quagga.start(); // Começa o scanner
        },
      );

      // Evento para processar o código escaneado
      Quagga.onDetected((result) => {
        if (result && result.codeResult && result.codeResult.code) {
          setScannedCode(result.codeResult.code);
          Quagga.stop();
          onScanSuccess(result.codeResult.code); // Chama o callback com o código escaneado
          // onClose();
        }
      });
    };

    if (open) {
      const intervalId = setInterval(() => {
        if (scannerRef.current) {
          initQuagga();
          clearInterval(intervalId); // Para o intervalo após inicializar
        }
      }, 100); // Verifica a cada 100ms se o elemento está disponível

      return () => {
        if (initialized) {
          Quagga.stop(); // Para o Quagga quando o componente é desmontado ou o diálogo é fechado
          Quagga.offDetected(); // Remove o evento para evitar chamadas múltiplas
        }
        clearInterval(intervalId); // Limpa o intervalo ao desmontar
      };
    }

    return () => {
      if (initialized) {
        Quagga.stop(); // Para a câmera se o diálogo for fechado
        Quagga.offDetected();
      }
    };
  }, [open, onScanSuccess, onClose]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        Escaneie o Código de Barras
        <IconButton
          aria-label='close'
          onClick={onClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div
          ref={scannerRef} // Usando ref aqui
          style={{
            position: 'relative',
            width: '100%',
            height: '300px',
            marginBottom: '20px',
            border: '1px solid black',
            overflow: 'hidden', // Evita o transbordo
          }}
        >
          <canvas
            className='drawingBuffer'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              pointerEvents: 'none',
            }}
          ></canvas>
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BarcodeScannerDialog;
