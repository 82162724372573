import React from 'react';

import Grid from '@material-ui/core/Grid';

import { PageTitle } from 'pages/Layout/PageTitle';

import { ImportButton } from './components/ImportButton';

export function B2B() {
  return (
    <>
      <PageTitle>B2B</PageTitle>

      <Grid container spacing={2}>
        {/* <Grid item xs={12} xl={3}>
          <ManualButton />
        </Grid> */}
        <Grid item xs={12} xl={3}>
          <ImportButton />
        </Grid>
      </Grid>
    </>
  );
}
