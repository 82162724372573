/* eslint-disable no-restricted-imports */
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Collapse,
  Box,
  Paper,
  Typography,
  Grid,
  CircularProgress,
} from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import { useMutation } from '@apollo/client';
import { Pagination } from '@mui/material';
import { Button, FormatDate, FormatMoney } from 'components';
import { CREATE_B2C_PRODUCTS } from 'services/graphql/query/b2c';

import { B2CContext } from '../..';

const columns = [
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'CÓDIGO PEDIDO', align: 'center' },
  { label: 'Marketplace', align: 'center' },
  { label: 'Status', align: 'center' },
  { label: 'FORMA DE ENTREGA', align: 'center' },
  { label: 'DATA PEDIDO', align: 'center' },
  { label: 'CLIENTE', align: 'center' },
  { label: 'CPF', align: 'center' },
  { label: 'QTD. ITENS', align: 'center' },
];

export function B2CTable() {
  const { results, setResults } = useContext(B2CContext);

  const [openRows, setOpenRows] = useState({});
  const [page, setPage] = useState(1); // Estado para a página atual
  const rowsPerPage = 5; // Número de itens por página

  const toggleRow = (index) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const [submit, submitOptions] = useMutation(CREATE_B2C_PRODUCTS, {
    onCompleted: () => {
      setResults(null);
      toast.success('Pedido salvo com sucesso');
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar os pedidos.';
      toast.error(`Error: ${errorMessage}`);
    },
  });

  if (submitOptions.loading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  // Calcular o índice inicial e final dos itens a serem exibidos na página atual
  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = results.slice(startIndex, startIndex + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedResults.map((order, index) => (
            <React.Fragment key={order.anymarketId}>
              <TableRow>
                <TableCell>
                  <IconButton size='small' onClick={() => toggleRow(index)}>
                    {openRows[index] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </TableCell>
                <TableCell align='center'>{order.anymarketId}</TableCell>
                <TableCell align='center'>{order.orderCode}</TableCell>
                <TableCell align='center'>{order.marketplace}</TableCell>
                <TableCell align='center'>{order.status}</TableCell>
                <TableCell align='center'>{order.deliveryMethod}</TableCell>
                <TableCell align='center'>
                  <FormatDate>{order.orderDate}</FormatDate>
                </TableCell>
                <TableCell align='center'>{order.client}</TableCell>
                <TableCell align='center'>{order.cgc}</TableCell>
                <TableCell align='center'>{order.items.length}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                  <Collapse in={openRows[index]} timeout='auto' unmountOnExit>
                    <Box margin={1}>
                      <Typography variant='h6' gutterBottom component='div'>
                        Itens do Pedido
                      </Typography>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell align='center'>SKU</TableCell>
                            <TableCell align='center'>VALOR</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {order.items.map((item, idx) => (
                            <TableRow key={idx}>
                              <TableCell align='center'>{item.sku}</TableCell>
                              <TableCell align='center'>
                                <FormatMoney>{item.totalProductValue}</FormatMoney>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>

      {/* Componente de paginação */}
      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(results.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='primary'
        />
      </Grid>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Button onClick={() => submit({ variables: { data: results } })}>
          Confirmar importação
        </Button>
      </Grid>
    </TableContainer>
  );
}
