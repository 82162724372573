import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { VALIDATE_PICKING } from 'services/graphql/query/b2c';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do Relatório_Picking',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportButton({ refetch }) {
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [importing, setImporting] = useState(false);

  const inputFileRef = useRef(null);

  const [validProducts] = useMutation(VALIDATE_PICKING, {
    onCompleted: (data) => {
      toast.success('Produtos validados com sucesso!');
      setImporting(false);
      handleReset();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao importar, tente novamente!';

      toast.error(`Error: ${errorMessage}`);
      setImporting(false);
    },
  });

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  const handleReset = () => {
    inputFileRef.current.value = '';
    refetch();
  };

  async function handleImportStore(e) {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              const values = line.split(';');
              console.log('values ==>', values);
              if (
                values[0] == null || //ID ANYMARKET
                values[6] == null || //SKU REQUISITADO
                values[8] == null || //VOUCHER
                values[9] == null || //IMEI
                values[13] == null ||
                values[13] == '' //ID MOVIMENTACAO ORACLE
              ) {
                return null;
              }

              return {
                anymarketId: values[0],
                sku: values[6],
                sinister: values[8],
                imei: values[9],
                oracleId: values[13],
              };
            })
            .filter((item) => item !== null);
          console.log('products ==> ', products);
          await validProducts({
            variables: { data: products },
          });
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
    }
  }

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={(e) => handleImportStore(e)}
        accept='text/csv'
        hidden
      />
      <Button
        fullWidth
        onClick={handleOpenDialog}
        disabled={importing}
        loading={importing}
        color='secondary'
        variant='outlined'
      >
        Importar picking
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
