import React, { createContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useQuery } from '@apollo/client';
import { Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_ALL_ORDERS_FOR_INVOICE } from 'services/graphql/query/b2c';

import { WaitingOracleOutBoundTable } from './WaitingOracleOutBoundTable';

export const WaitingOracleOutBoundContext = createContext();

const pickingOptions = [
  { value: 'b2b', label: 'B2B' },
  { value: 'b2c', label: 'B2C' },
];

export function WaitingOracleOutBound() {
  const [selectedPicking, setSelectedPicking] = useState('');
  const [search, setSearch] = useState('');
  const [isOpenXMLDialog, setIsOpenXMLDialog] = useState(false);

  const b2cForInvoice = useQuery(LIST_ALL_ORDERS_FOR_INVOICE, {
    skip: !selectedPicking || selectedPicking !== 'b2c',
    fetchPolicy: 'network-only',
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleOpenXMLDialog = () => {
    setIsOpenXMLDialog(true);
  };

  const handleCloseXMLDialog = () => {
    setIsOpenXMLDialog(false);
  };

  return (
    <>
      <PageTitle>Pedidos</PageTitle>

      <Formik initialValues={{ picking: '', search: '' }}>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
              <Field
                name='picking'
                component={Select}
                label='Tipo de Picking'
                options={pickingOptions}
                value={setSelectedPicking}
                onChange={(e) => setSelectedPicking(e)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
              <Field
                label='Pesquisar'
                name='search'
                size='small'
                variant='outlined'
                endAdornment={<SearchIcon />}
                value={search}
                onChange={handleSearchChange}
                component={Text}
                disabled={!selectedPicking}
              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Button fullWidth onClick={handleOpenXMLDialog}>
                Importar XML
              </Button>
            </Grid> */}
          </Grid>
        </Form>
      </Formik>

      <Spacer size={42} />

      {selectedPicking && (
        <WaitingOracleOutBoundContext.Provider
          value={{
            data: b2cForInvoice?.data?.listAllOrdersForInvoice ?? [],
            loading: b2cForInvoice.loading,
            refetch: b2cForInvoice.refetch,
            search,
          }}
        >
          <WaitingOracleOutBoundTable />
        </WaitingOracleOutBoundContext.Provider>
      )}

      {/* <ImportManyXmlDialog isOpen={isOpenXMLDialog} handleClose={handleCloseXMLDialog} /> */}
    </>
  );
}
