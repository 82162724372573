import React from 'react';
import { useRouteMatch } from 'react-router';

import { ImportBases } from 'pages/Private/Implementation/ImportBases';
import { ImportServices } from 'pages/Private/Implementation/ImportServices';

import { Route } from '../Route';

export const ImplementationRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/import-services`} component={ImportServices} />
      <Route isPrivate path={`${path}/import-bases`} component={ImportBases} />
    </>
  );
};
