import React from 'react';
import { useRouteMatch } from 'react-router';

import { RegisterStockLocation } from 'pages/Private/GeneralRegistration/RegisterStockLocation';
import { ImportStockComponents } from 'pages/Private/Implementation/ImportStockComponents';
import { AnalysisStockRequest } from 'pages/Private/Stock/AnalysisStockRequest';
import { BalanceTransfer } from 'pages/Private/Stock/BalanceTransfer';
import { ConsultBilling } from 'pages/Private/Stock/ConsultBilling';
import { ConsultPnByBilling } from 'pages/Private/Stock/ConsultPnByBilling';
import { ConsultStock } from 'pages/Private/Stock/ConsultStock';
import { FinalizeCMP } from 'pages/Private/Stock/FinalizeCMP';
import { ImportStock } from 'pages/Private/Stock/ImportStock';
import { importStockUpdate } from 'pages/Private/Stock/importStockUpdate';
import { MovementStock } from 'pages/Private/Stock/MovementStock';
import { PnTracker } from 'pages/Private/Stock/PnTracker';
import { PurchaseRequisition } from 'pages/Private/Stock/PurchaseRequisition';
import RegisterComponent from 'pages/Private/Stock/RegisterComponent';
import { RegisterComponentAlternate } from 'pages/Private/Stock/RegisterComponentAlternate';
import { RegisterInvoice } from 'pages/Private/Stock/RegisterInvoice';
import RegisterLocal from 'pages/Private/Stock/RegisterLocal';
import { ReprintTagComponents } from 'pages/Private/Stock/ReprintTag';
import { RequestComponents } from 'pages/Private/Stock/RequestComponent';
import { RequestComponentDebit } from 'pages/Private/Stock/RequestComponentDebit';
import { StockDevolution } from 'pages/Private/Stock/StockDevolution';
import { StockImportation } from 'pages/Private/Stock/StockManualInclusion';
import { UpdateBalance } from 'pages/Private/Stock/UpdateBalance';

import { Route } from '../Route';

export const StockRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/import-stock-components`} component={ImportStockComponents} />
      <Route isPrivate path={`${path}/register-invoice`} component={RegisterInvoice} />
      <Route isPrivate path={`${path}/consult-billing`} component={ConsultBilling} />
      <Route isPrivate path={`${path}/register-location`} component={RegisterLocal} />
      <Route
        isPrivate
        path={`${path}/register-component-alternate`}
        component={RegisterComponentAlternate}
      />
      <Route isPrivate path={`${path}/register-component`} component={RegisterComponent} />
      <Route isPrivate path={`${path}/consult-stock`} component={ConsultStock} />
      <Route isPrivate path={`${path}/consult-billingpn`} component={ConsultPnByBilling} />
      <Route isPrivate path={`${path}/request-component`} component={RequestComponents} />
      <Route isPrivate path={`${path}/purchase-requisition`} component={PurchaseRequisition} />
      <Route isPrivate path={`${path}/pn-tracker`} component={PnTracker} />
      <Route isPrivate path={`${path}/balance-transfer`} component={BalanceTransfer} />
      <Route isPrivate path={`${path}/stock-devolution`} component={StockDevolution} />
      <Route isPrivate path={`${path}/stock-importation`} component={StockImportation} />
      <Route isPrivate path={`${path}/analysis-stock-request`} component={AnalysisStockRequest} />
      <Route isPrivate path={`${path}/movement-stock`} component={MovementStock} />
      <Route isPrivate path={`${path}/create-stock-location`} component={RegisterStockLocation} />
      <Route isPrivate path={`${path}/import-stock`} component={ImportStock} />
      <Route isPrivate path={`${path}/import-stock-update`} component={importStockUpdate} />
      <Route isPrivate path={`${path}/reprint-component-tags`} component={ReprintTagComponents} />
      <Route isPrivate path={`${path}/finalize-cmp`} component={FinalizeCMP} />
      <Route
        isPrivate
        path={`${path}/register-component-debit`}
        component={RequestComponentDebit}
      />
      <Route isPrivate path={`${path}/update-balance`} component={UpdateBalance} />
    </>
  );
};
