import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Button, Select, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { invoiceService } from 'services';
import { api, mailObjectService, pllService, serviceOrderService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  // process_type_id: yup.number().required(),
  // product_id: yup.number().required(),
  // color_product: yup.string().required(),
  // // sub_bpo_id: yup.string(),
  // pll_shops: yup.string(),
  // new_service: yup.string(),
  // warranty_lost: yup.string().required(),
});

export const AlterServiceGaiaDialog = ({ open, handleClose, serviceOrder = {} }) => {
  if (!open) return null;
  const classes = useStyles();

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );
  const warrantyTypeList = [
    {
      value: 'In Warranty',
      label: 'In Warranty',
    },
    {
      value: 'Out of Warranty',
      label: 'Out of Warranty',
    },
  ];

  const warrantyLost = [
    {
      value: 'SIM',
      label: 'SIM',
    },
    {
      value: 'NÃO',
      label: 'NÃO',
    },
  ];

  const [insuranceCompanies, , loadingInsuranceCompanies] = useQuery(
    mailObjectService.listInsuranceCompanies,
    [],
  );

  const [serviceStatuses, loadingserviceStatuses] = useQuery(
    serviceOrderService.listServiceStatuses,
    [],
  );

  const toTrim = (e) => {
    e.target.value = ('' + e.target.value).trim();
  };

  const [subInsuranceCompanies, , loadingSubInsuranceCompanies] = useQuery(
    pllService.listSubInsuranceCompanies,
    [],
  );

  const [serviceOrderProcesses, , loadingServiceOrderProcess] = useQuery(
    serviceOrderService.listProcesses,
    [],
  );

  const [serviceOrderAccessories, , loadingServiceOrderAccessories] = useQuery(
    serviceOrderService.listServiceOrderAccessories,
    [],
  );

  const [resales, , loadingResales] = useQuery(pllService.listResales, []);

  const [colors, , loadingColors] = useQuery(serviceOrderService.listColorsProduct, []);

  const insuranceCompaniesOptions = (insuranceCompanies || []).map((insuranceCompany) => ({
    value: insuranceCompany.id,
    label: insuranceCompany.name,
  }));

  const subInsuranceCompaniesOptions = (subInsuranceCompanies || []).map((subInsuranceCompany) => ({
    value: subInsuranceCompany.id,
    label: subInsuranceCompany.name,
  }));

  const resalesOptions = (resales || []).map((resale) => ({
    value: resale.id,
    label: resale.name,
  }));

  const insurante_Wty = [
    {
      value: 'DA',
      label: 'DA',
    },
    {
      value: 'GE',
      label: 'GE',
    },

    {
      value: 'LIKE NEW',
      label: 'LIKE NEW',
    },
  ];

  const colorsOptions = (colors || []).map((colors) => ({
    value: colors.description,
    label: colors.description,
  }));

  const serviceOrderProcessesOptions = (serviceOrderProcesses || []).map((serviceOrder) => ({
    value: serviceOrder.id,
    label: serviceOrder.description,
  }));

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.description,
    }),
  );

  const serviceOrderAccessoriesOptions = (serviceOrderAccessories || []).map((accessory) => ({
    value: accessory.id,
    label: accessory.description,
  }));

  const serviceStatusesStep = (serviceStatuses || []).map((status) => ({
    value: status.id,
    label: status.description,
  }));

  const [invoiceEntrance, , ,] = useQuery(
    () => invoiceService.getInvoiceByOsIdAndInvoiceTypeId(serviceOrder?.id, 1),
    [],
  );

  console.log('invoiceEntrance', invoiceEntrance);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    console.log('aaa', values);
    try {
      await serviceOrderService.updateService(values);

      toast.success('Service alterada com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar a service.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Alterar Service Nº: {serviceOrder?.number}
        {/* {console.log('teste: ', serviceOrder)} */}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            number: serviceOrder?.number,
            process_type_id: serviceOrder?.process_type_id,
            product_id: serviceOrder?.product_id,
            warranty_type: serviceOrder?.warranty_type,
            color_product: serviceOrder?.color_product,
            sub_bpo_id: serviceOrder?.sub_bpo_id,
            new_service: 'SÓ EDITAR SE FOR PARA MUDAR',
            pll_shops: serviceOrder?.pll_shops,
            status_id: serviceOrder?.status_id,
            sinister: String(serviceOrder?.sinister) ?? '',
            warranty_lost: serviceOrder?.warranty_lost,
            bounce: serviceOrder?.bounce === true ? 'SIM' : 'NÃO',
            devol: serviceOrder?.isDevol === true ? 'SIM' : 'NÃO',

            imei: serviceOrder?.imei,
            imei2: serviceOrder?.imei2,
            vinc_service: serviceOrder?.vinc_service,
            insurance_wty: serviceOrder?.insurance_wty,
            sinister_letter: serviceOrder?.sinister_letter ?? '',
            model: serviceOrder?.model ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='number'
                      label='Ordem de Serviço'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='new_service'
                        label='Nova Service'
                        variant='outlined'
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        size='small'
                        name='process_type_id'
                        label='Tipo de processo'
                        variant='outlined'
                        options={serviceOrderProcessesOptions}
                        loading={loadingServiceOrderProcess}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        size='small'
                        name='product_id'
                        label='Tipo de Produto'
                        variant='outlined'
                        options={additionalServiceProductOptions}
                        loading={loadingServiceOrderProcess}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='warranty_type'
                        label='Tipo de Garantia'
                        variant='outlined'
                        options={warrantyTypeList}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='warranty_lost'
                        label='Perda de garantia ?'
                        variant='outlined'
                        options={warrantyLost}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='bounce'
                        label='Bounce?'
                        variant='outlined'
                        options={warrantyLost}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='devol'
                        label='Devolução sem reparo ?'
                        variant='outlined'
                        options={warrantyLost}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        size='small'
                        name='color_product'
                        label='Cor do produto'
                        variant='outlined'
                        options={colorsOptions}
                        loading={loadingColors}
                        component={Select}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='sub_bpo_id'
                        variant='outlined'
                        label='Sub Seguradoras'
                        loading={loadingSubInsuranceCompanies}
                        options={subInsuranceCompaniesOptions}
                        component={Select}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='insurance_wty'
                        variant='outlined'
                        label='Atendimento seguro'
                        options={insurante_Wty}
                        component={Select}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='pll_shops'
                        variant='outlined'
                        label='Revendas'
                        loading={loadingResales}
                        options={resalesOptions}
                        component={Select}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='status_id'
                        variant='outlined'
                        label='Service Status'
                        loading={loadingserviceStatuses}
                        options={serviceStatusesStep}
                        component={Select}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='sinister'
                        variant='outlined'
                        label='Sinistro'
                        component={Text}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='model'
                        variant='outlined'
                        label='Modelo'
                        component={Text}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='sinister_letter'
                        variant='outlined'
                        label='Letra do Sinistro'
                        onInput={toTrim}
                        component={Text}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='sinister_letter'
                        variant='outlined'
                        label='Letra do Sinistro'
                        onInput={toTrim}
                        component={Text}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='imei'
                        variant='outlined'
                        label='IMEI'
                        value={serviceOrder?.imei ?? ''}
                        component={Text}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='imei2'
                        variant='outlined'
                        label='IMEI2'
                        value={serviceOrder?.imei2 ?? ''}
                        component={Text}
                      />
                    </Grid>

                    <Grid item xs={12} lg={12}>
                      <Field
                        size='small'
                        fullWidth
                        name='vinc_service'
                        variant='outlined'
                        label='Service sistema terceiro'
                        value={serviceOrder?.vinc_service ?? ''}
                        component={Text}
                      />
                    </Grid>
                  </>
                  {/* // <>
                    //   <Grid item xs={12} lg={12}>
                    //     <Field */}
                  {/* //       size='small'
                    //       fullWidth
                    //       name='vinc_service'
                    //       variant='outlined'
                    //       label='Service sistema terceiro'
                    //       value={serviceOrder?.vinc_service ?? ''}
                    //       component={Text}
                    //     />
                    //   </Grid>
                    //   <Grid className={classes.buttonsContainer} container></Grid>
                    // </>
                  // )} */}

                  <></>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary' variant='text'>
                      Fechar
                    </Button>
                    <Button color='primary' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
