import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginTop: '5px',
    },
    divContaiver: {
      fontSize: '16px',
    },
    container: {
      display: 'flex',
    },
    tableButton: {
      fontSize: 11,
      height: 30,
    },
    captions: {
      width: 'fit-content',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 20,

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    statusCell: {
      '&.status-1': {
        color: '#FEE231',
      },
      '&.status-2': {
        color: '#9ef01a',
      },
      '&.status-3': {
        color: '#70e000',
      },
      '&.status-4': {
        color: '#38b000',
      },
      '&.status-5': {
        color: '#FFBA08',
      },
      '&.status-6': {
        color: '#FAA307',
      },
      '&.status-7': {
        color: '#f72585',
      },
      '&.status-8': {
        color: '#FAA307',
      },
      '&.status-9': {
        color: '#E85D04',
      },
      '&.status-10': {
        color: '#D00000 ',
      },
      '&.status-11': {
        color: '#7209b7',
      },
      '&.status-12': {
        color: '#F95738',
      },
      '&.status-13': {
        color: '#4361ee',
      },
    },
    serviceNumber: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    statusDescription: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  };
});
