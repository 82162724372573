import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridContainer: {
    paddingTop: '10px',
  },

  plusIcon: {
    color: '#512da8',
    maxWidth: '1rem',
  },

  minusIcon: {
    color: '#bcbcbc',
    maxWidth: '1rem',
  },

  tableCell: {
    maxWidth: '2rem',
    fontWeight: 500,
    fontSize: '14px',
  },

  qntd: {
    maxWidth: 'auto',
    fontWeight: 500,
    fontSize: '14px',
  },

  subTotalTableCell: {
    maxWidth: '4rem',
    color: '#66BB6A',
    fontWeight: 500,
    fontSize: '16px',
  },

  totalGrid: {
    maxWidth: '90%',
  },

  total: {
    fontWeight: 600,
  },

  totalPrice: {
    marginLeft: '0.6rem',
    fontSize: '1rem',
    fontWeight: 500,
    color: 'green',
  },

  accessoriesInCart: {
    height: '100%',
    padding: '10px',
    overflow: 'auto',
  },
}));
