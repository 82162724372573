import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { UPDATE_SERVICE_ORDER_IMEI } from 'services/graphql/query/service-orders';

import { validationSchema } from './validation';

export function UpdatedImei({ isOpen = false, handleClose, data, refetch }) {
  if (!isOpen) {
    return <></>;
  }

  const [updateImei] = useMutation(UPDATE_SERVICE_ORDER_IMEI, {
    onCompleted: () => {
      toast.success('IMEI atualizado com sucesso');
      refetch();
      handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar o IMEI novo';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  async function handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    try {
      await updateImei({
        variables: {
          data: { ...values },
        },
      });
    } catch (error) {
      toast.error('Erro ao atualizar o status');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Atualizar IMEI: {data.sinister}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ currentImei: data?.imei, newImei: '', sinister: data.sinister }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} xl={12}>
                  <Field
                    variant='outlined'
                    label='IMEI/Serial Atual'
                    name='currentImei'
                    size='small'
                    disabled
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Field
                    variant='outlined'
                    label='Novo IMEI/Serial'
                    name='newImei'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} xl={12}>
                  <Button
                    type='submit'
                    fullWidth
                    disabled={!props.dirty || !props.isValid}
                    loading={props.isSubmitting}
                  >
                    Atualizar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
