import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  action: {
    padding: theme.spacing(1),
    minWidth: 'unset',
  },
  actionIcon: {
    width: 18,
    height: 18,
  },
  checkIcon: {
    color: '#4CAF50',
  },
  closeIcon: {
    color: '#DC3F53',
  },
}));
