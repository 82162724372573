import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 10,
  },
  expirationCell: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  sectionContainer: {
    display: 'flex',
  },
  legend1: {
    background: '#4CAF50',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    border: 'none',
    marginBottom: '5px',
  },
  legend2: {
    background: '#fa7e1e',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    border: 'none',
    marginBottom: '5px',
  },
  legend3: {
    background: '#F95738',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    border: 'none',
    marginBottom: '5px',
  },
  legend4: {
    background: '#FF0000',
    width: '1rem',
    height: '1rem',
    borderRadius: '50%',
    border: 'none',
    marginBottom: '5px',
  },
  textLegend: {
    marginLeft: '3px',
    fontSize: '14px',
  },
  title: {
    fontWeight: 'bold',
  },
}));
