import { api } from 'services/api';

export class WarrantyService {
  async warrantyService(number) {
    return api.get(`/warranty/warranty-validation/${number}`);
  }

  async WarrantyValidation(number, disableBill) {
    return api.post('/warranty/warranty-validation', { number, disableBill });
  }

  async warrantyDebit(service_number, disableBill) {
    return api.post('/warranty/warranty-debit', { service_number, disableBill });
  }

  async createBudgetInvoiceEmissions(data) {
    return api.post('/warranty/budget/invoice-emission', data);
  }

  async createBudgetInvoiceEmissionsNewKit(data) {
    return api.post('/warranty/budget/invoice-emissionnewkit', data);
  }

  async createBudgetInvoiceEmissionsExibithion(data) {
    return api.post('/warranty/exibithion', data);
  }

  async createBudgetInvoiceEmissionsExibithionNewKit(data) {
    return api.post('/warranty/exibithion-newkit', data);
  }
}

export const warrantyService = new WarrantyService();
