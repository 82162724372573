import { toast } from 'react-toastify';

export const addComponentToCart = (state, action) => {
  const { component } = action;
  const cart = [...state];

  if (!component.sell_price) {
    toast.error('Part Number sem preço de venda cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...component, amount: 1, sub_total: component.sell_price }];
  }

  console.log('Component Added: ', component);

  const hasComponentIndexInCart = state.findIndex((a) => {
    return a.id == action.component.id;
  });

  let componentAdded = [];

  if (hasComponentIndexInCart >= 0) {
    componentAdded = cart[hasComponentIndexInCart];

    componentAdded.amount += 1;
    componentAdded.sub_total = componentAdded.amount * componentAdded.sell_price;

    return cart;
  }

  return [...cart, { ...component, amount: 1, sub_total: component.sell_price }];
};

export const removeComponentFromCart = (state, action) => {
  const cart = [...state];
  const { component } = action;

  const hasComponentIndexInCart = state.findIndex((a) => {
    return a.id === component.id;
  });

  if (hasComponentIndexInCart >= 0) {
    cart.splice(hasComponentIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updateComponentAmount = (state, action) => {
  const cart = [...state];
  const { amountAndComponentId } = action;

  const componentIndex = cart.findIndex((a) => {
    return a.id === amountAndComponentId.component_id;
  });

  cart[componentIndex].amount = amountAndComponentId.amount;

  cart[componentIndex].sub_total = cart[componentIndex].amount * cart[componentIndex].sell_price;

  if (amountAndComponentId.amount <= 0) {
    cart.splice(componentIndex, 1);
  }

  return cart;
};

export const addServiceToCart = (state, action) => {
  const { service } = action;
  const cart = [...state];

  if (!service.service_price) {
    toast.error('Serviço sem preço cadastrado');

    return cart;
  }

  if (!state.length) {
    return [{ ...service, amount: 1, sub_total: service.service_price }];
  }

  console.log('Service Added: ', service);

  const hasServiceIndexInCart = state.findIndex((a) => {
    return a.id == action.service.id;
  });

  let serviceAdded = [];

  if (hasServiceIndexInCart >= 0) {
    serviceAdded = cart[hasServiceIndexInCart];

    serviceAdded.amount += 1;
    serviceAdded.sub_total = serviceAdded.amount * serviceAdded.service_price;

    return cart;
  }

  return [...cart, { ...service, amount: 1, sub_total: service.service_price }];
};

export const removeServiceFromCart = (state, action) => {
  const cart = [...state];
  const { service } = action;

  const hasServiceIndexInCart = state.findIndex((a) => {
    return a.id === service.id;
  });

  if (hasServiceIndexInCart >= 0) {
    cart.splice(hasServiceIndexInCart, 1);

    return cart;
  }

  return cart;
};

export const updateServiceAmount = (state, action) => {
  const cart = [...state];
  const { amountAndServiceId } = action;

  const serviceIndex = cart.findIndex((a) => {
    return a.id === amountAndServiceId.service_id;
  });

  cart[serviceIndex].amount = amountAndServiceId.amount;

  cart[serviceIndex].sub_total = cart[serviceIndex].amount * cart[serviceIndex].service_price;

  if (amountAndServiceId.amount <= 0) {
    cart.splice(serviceIndex, 1);
  }

  return cart;
};

export const cleanCart = (state, action) => {
  const { cartCleaned } = action;

  return cartCleaned;
};
