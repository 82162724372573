import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  paperSale: {
    backgroundImage: 'linear-gradient(to right, #48c0ee, #49b5ec, #50aae7, #5a9ee2, #6592da)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  content: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainTitle: {
    fontSize: '30px',
  },
  text: {
    fontSize: '16px',
  },
  footerPaperSale: {
    background: '#6592da',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },
  moneyImgs: {
    opacity: 0.5,
  },

  paperQuantity: {
    backgroundImage: 'linear-gradient(to right, #865fc0, #7d5abe, #7355bd, #6850bb, #5d4bba)',
    opacity: '0.9',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  footerPaperQuantity: {
    background: '#6850bb',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },
  cartImg: {
    opacity: 0.5,
  },
  paperPerformance: {
    backgroundImage: 'linear-gradient(to right, #e84989, #de4b91, #d34e99, #c7519f, #ba54a4)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  paperPerformance2: {
    backgroundImage: 'linear-gradient(to right, #32a4a8, #32a4a8, #32a4a8, #32a4a8, #32a4a8)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  paperPerformance3: {
    backgroundImage: 'linear-gradient(to right, #344feb, #344feb, #344feb, #344feb, #344feb)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  paperPerformance4: {
    backgroundImage: 'linear-gradient(to right, #fc9d03, #fc9d03, #fc9d03, #fc9d03, #fc9d03)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  paperPerformance5: {
    backgroundImage: 'linear-gradient(to right, #6D95CC, #6D95CC, #6D95CC,#6D95CC, #6D95CC)',
    opacity: '0.8',
    color: '#ffff',
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  graphicImg: {
    opacity: 0.8,
  },
  footerPerformance: {
    background: '#ba54a4',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },

  footerPerformance2: {
    background: '#32a4a8',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },

  footerPerformance3: {
    background: '#344feb',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },

  footerPerformance4: {
    background: '#fc9d03',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },

  footerPerformance5: {
    background: '#6D95CC',
    padding: '5px',
    borderRadius: '0.25rem',
    textAlign: 'center',
  },
  paperGraphics: {
    borderRadius: '0.25rem',
    backgroundImage: ' linear-gradient(to top, #e6e9f0 0%, #eef1f5 20%)',
    padding: 5,
  },

  paperGraphicLine: {
    borderRadius: '0.25rem',
    backgroundImage: ' linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%)',
  },
  paperGraphics: {
    borderRadius: '1rem',
    background: '#F1EEFF',
    padding: 5,
    height: 280,
    maxHeight: 450,
    [theme.breakpoints.down(450)]: {
      flexDirection: 'column',
      height: 'unset',
    },
  },
  divSectionIlustrationGraphic: {
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  divider: {
    height: 240,
    width: 10,
    background: '#253368',
    opacity: 0.9,
    borderRadius: 20,
    marginLeft: '15px',
  },
  sectionContextIlustrationGrahic: {
    display: 'flex',
    [theme.breakpoints.down(450)]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up(500)]: {
      display: 'flex',
    },
  },
  p: {
    color: '#253368',
    fontSize: '1.6rem',
    fontWeight: '700',
    marginTop: 40,
  },
  titleNumber: {
    color: '#253368',
    fontSize: '2.5rem',
    fontWeight: '700',
    marginTop: 30,
  },
  imgIlustrationGraphic: {
    height: '200px',
    paddingLeft: 20,
  },

  paperGraphics1: {
    borderRadius: '1rem',
    background: '#253368',
    padding: 5,
    height: 280,
    [theme.breakpoints.down(450)]: {
      height: 'unset',
    },
  },
  divSectionIlustrationGraphic1: {
    display: 'flex',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  divider1: {
    height: 240,
    width: 10,
    background: '#F1EEFF',
    opacity: 0.9,
    borderRadius: 20,
    marginLeft: '15px',
  },
  sectionContextIlustrationGrahic1: {
    display: 'flex',
    [theme.breakpoints.down(450)]: {
      flexDirection: 'column',
      background: 'green',
    },
  },
  p1: {
    color: '#F1EEFF',
    fontSize: '1.6rem',
    fontWeight: '700',
    marginTop: 40,
  },
  titleNumber1: {
    color: '#F1EEFF',
    fontSize: '2.5rem',
    fontWeight: '700',
    marginTop: 30,
  },
  imgIlustrationGraphic1: {
    height: '200px',
    paddingLeft: 20,
  },
  teste: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down(400)]: {
      flexDirection: 'column',
    },
    padding: 10,
  },
}));
