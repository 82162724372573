import React, { useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Spacer, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  // {
  //   label: 'Possui',
  //   width: 80,
  // },
  { label: 'Descrição', align: 'center' },
];

export function PostalCards() {
  const classes = useStyles();

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 300);
  };

  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const formikRef = useRef();

  const handleDownloadDanfe = async (service_order_number) => {
    console.log('service_order_number: ', service_order_number);
    const { data } = await budgetService.getDanfeLocal(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}-DANFE.pdf`);
  };

  const handleDownloadPostCard = async (service_order_number) => {
    console.log('service_order_number: ', service_order_number);
    const { data } = await budgetService.getPostCardLocal(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      // handleDownloadDanfe(values.service_number);

      handleDownloadPostCard(values.service_number);

      setSubmitting(true);

      toast.success('Download Realizado com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao fazer download.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Cartões expedição</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{
          service_number: '',
        }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  component={Text}
                  //   inputProps={{ style: { textTransform: 'uppercase' } }}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button
                  disabled={props.isSubmitting || !props.isValid || !props.dirty}
                  type='submit'
                  fullWidth
                >
                  IMPRIMIR CARTÕES
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            <img className={classes.imgContainer} src={checkKitImg} />
          </Form>
        )}
      </Formik>
    </>
  );
}
