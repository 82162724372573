import React from 'react';
import { useState, useMemo } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Table, Text } from 'components';
import { Button } from 'components/Button';
import { FormatMoney } from 'components/FormatMoney';
import { normalizeValue } from 'helpers/normalizeValue';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { budgetService } from 'services/budget';

import { useStyles } from './styles';

const columns = [
  { label: 'Service' },
  { label: 'Valor orçamento' },
  { label: 'Valor pedido pelo cliente' },
  { label: 'Ações', align: 'center', width: 80 },
];

export const TableNegociation = () => {
  const [budgets, , loadingBudgets] = useQuery(budgetService.listBudgetsById, []);
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateTo = (budget) => {
    browserHistory.push({
      pathname: '/conference/waiting-negotiation',
      state: {
        budget_id: budget.id,
        service_order_number: budget?.service_order?.number,
        name: budget?.service_order?.client.name,
        final_budget_price: budget?.final_budget_price,
        min_components_value: budget?.min_components_value,
        additional_services_total_price: budget?.additional_services_total_price,
        wanted_value: budget?.wanted_value,
        sinister: budget?.service_order?.sinister,
        bpo: budget?.service_order?.SubInsurancePll?.name,
      },
    });
  };

  // const formattedBudgets = (budgets || []).map((b) => {
  //   return {
  //     ...b,
  //   };
  // });

  // useEffect(() => { }, [formattedBudgets]);

  // const filteredBudgets = (formattedBudgets || []).filter((budget) => {
  //   const lowerServiceNumber = budget?.service_order?.number?.toLowerCase();

  //   return lowerServiceNumber?.includes(search);
  // });

  const filteredComponents = useMemo(() => {
    const searchLower = search.toLowerCase();
    const normalizedSearch = normalizeValue(searchLower);
    return budgets?.filter((budget) => {
      const normalizeValue1 = normalizeValue(budget.final_budget_price);
      const normalizeValue2 = normalizeValue(budget.wanted_value);
      return (
        (budget?.service_order?.number &&
          budget?.service_order?.number.toLowerCase().includes(searchLower)) ||
        (normalizeValue1 && normalizeValue1.includes(normalizedSearch)) ||
        (normalizeValue2 && normalizeValue2.includes(normalizedSearch))
      );
    });
  }, [budgets, search]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            variant='outlined'
            name='search'
            size='small'
            endAdornment={<SearchIcon />}
            label='Pesquisar'
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={columns}
            loading={loadingBudgets}
            stripped
            maxHeight
            emptyMessage='Nenhum registro encontrado.'
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
          >
            {filteredComponents?.map((budget) => (
              <TableRow key={budget.id}>
                <TableCell className={`${classes.statusCell} status-${budget.status.id}`}>
                  <Typography className={classes.serviceNumber}>
                    {budget?.service_order?.number}
                  </Typography>
                </TableCell>
                <TableCell>
                  <FormatMoney>{budget.final_budget_price}</FormatMoney>
                </TableCell>

                <TableCell>
                  <FormatMoney>{budget.wanted_value}</FormatMoney>
                </TableCell>

                <TableCell>
                  <Button
                    className={classes.tableButton}
                    onClick={() => navigateTo(budget)}
                    size='small'
                  >
                    Negociar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
