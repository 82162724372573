import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';

import { Button, RegisterClientDialog, Spacer, Text, BackNavButton } from 'components';
import { Formik, Form, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';

import { OrderDialog } from './OrderDialog';
import { validationSchema } from './validation';

export const CustomersPersonalData = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [loadingClient, setLoadingClient] = useState(false);
  const [open, setOpenState] = useBoolean();
  const [ordering, setOrdering] = useState();

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleCgcBlur = async ({ errors, setValues, values }) => {
    try {
      setLoadingClient(true);

      if (errors.cgc) return;

      const { data } = await serviceOrderService.getClientByCgc(values.cgc);

      const {
        addresses,
        id: client_id,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
      } = data;

      const clientFirstAddress = addresses[0];

      setValues({
        ...values,
        ...clientFirstAddress,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
        client_id,
      });
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
      console.error(err);
    } finally {
      setLoadingClient(false);
    }
  };

  return (
    <>
      <BackNavButton />

      <PageTitle dev />
      <OrderDialog
        open={open}
        onClose={setOpenState.toFalse}
        setOrdering={setOrdering}
        loading={ordering}
      />
      <Formik
        // onSubmit={onSubmitHandler}
        initialValues={{
          cgc: '',
          name: '',
          state_registration: '',
          zipcode: '',
          city: '',
          state: '',
          district: '',
          street: '',
          street_number: '',
          complement: '',
          residential_phone_number: '',
          phone_number: '',
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container justify='space-between'>
                <Grid item>
                  <Typography variant='h6' gutterBottom>
                    Dados do cliente (Pesquisar por CPF/CNPJ)
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    onClick={handleDialogOpen}
                    fullWidth
                    startIcon={<AddIcon />}
                    variant='outlined'
                  >
                    Novo cliente
                  </Button>
                </Grid>
              </Grid>
              <Spacer axis='vertical' size={32} />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    label='CPF/CNPJ do cliente'
                    name='cgc'
                    size='small'
                    component={Text}
                    mask='document'
                    onBlur={() => handleCgcBlur(props)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={9}>
                  <Field
                    variant='outlined'
                    label='Nome do cliente'
                    name='name'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>

                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    fullWidth
                    name='zipcode'
                    mask='zipcode'
                    variant='outlined'
                    label='CEP'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Cidade'
                    name='city'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Estado'
                    name='state'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Bairro'
                    name='district'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Rua'
                    name='street'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Complemento'
                    name='complement'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Número'
                    name='street_number'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Telefone Fixo'
                    name='residential_phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Celular'
                    name='phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Field
                    variant='outlined'
                    label='Email'
                    name='email'
                    component={Text}
                    type='email'
                    size='small'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid style={{ display: 'flex', justifyContent: 'flex-end' }} spacing={2} container>
                <Grid item xs={12} lg={2}>
                  <Button
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    onClick={setOpenState.toTrue}
                    fullWidth
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <RegisterClientDialog open={isDialogOpen} onClose={handleDialogClose} />
    </>
  );
};
