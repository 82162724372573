import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, Radio, Spacer, Text } from 'components';
import { Form, Field, Formik, useFormikContext } from 'formik';
import { accountService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  old_email_seller: yup.string().required(),
  email: yup.string().required(),
  name: yup.string().required(),
});

const validationSchemaActive = yup.object().shape({
  email: yup.string().required(),
  is_seller_active: yup.boolean().required(),
});

export const AlterSellerDialog = ({ open, handleClose, seller = {}, reloadSellers }) => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('values: ', values);
      await accountService.updateSeller(values.old_email_seller, values.email, values.name);

      reloadSellers();
      toast.success('Vendedor alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  const onSubmitHandlerActive = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('values: ', values);
      await accountService.updateSellerActive(values);

      reloadSellers();
      toast.success('Vendedor alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  const SellerName = () => {
    const { setFieldValue } = useFormikContext();

    const handleEmailBlur = async (value) => {
      try {
        console.log('VALUE: ', value);
        const data = await accountService.getUserByEmail(value);

        const { email, name } = data.data;

        console.log('DOCS: ', data.data);
        console.log('NAME: ', name);

        setFieldValue('name', name);
        if (email === undefined || email === '') {
          toast.error('Informe um e-mail válido para prosseguir');
        }
      } catch (err) {
        toast.error(err.response?.data?.message);
      } finally {
      }
    };

    return (
      <Field
        variant='outlined'
        label='E-mail'
        name='email'
        size='small'
        component={Text}
        onBlur={(e) => handleEmailBlur(e.target.value)}
      />
    );
  };

  return (
    <Dialog maxWidth='sm' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Detalhes do Vendedor: {seller?.name}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ old_email_seller: seller.email, email: '', name: '' }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid item xs={12} lg={12}>
                  <Typography variant='h6' style={{ fontSize: '16px' }}>
                    Trocar vendedor
                  </Typography>
                </Grid>

                <Spacer size={24} />
                <Grid spacing={2} container>
                  <Grid item xs={12} lg={12}>
                    <Field
                      variant='outlined'
                      name='old_email_seller'
                      label='Alterar o vendedor'
                      size='small'
                      component={Text}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <SellerName />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      variant='outlined'
                      name='name'
                      label='Name'
                      size='small'
                      component={Text}
                      disabled
                    />
                  </Grid>
                  <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>

        <Spacer size={24} />
        <Divider />
        <Spacer size={24} />

        <Formik
          initialValues={{ is_seller_active: seller?.is_seller_active, email: seller?.email }}
          onSubmit={onSubmitHandlerActive}
          validationSchema={validationSchemaActive}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid spacing={2} container>
                  <Grid item xs={12} lg={12}>
                    <Typography variant='h6' style={{ fontSize: '16px' }}>
                      Ativar / Desativar Vendedor
                    </Typography>
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Field
                      color='primary'
                      name='is_seller_active'
                      value={true}
                      fullWidth
                      component={Radio}
                      label='Ativo'
                    />
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    <Field
                      color='primary'
                      name='is_seller_active'
                      value={false}
                      fullWidth
                      component={Radio}
                      label='Inativo'
                    />
                  </Grid>
                  <Grid style={{ display: 'flex', justifyContent: 'end' }} container>
                    <Button onClick={handleClose} color='primary'>
                      Fechar
                    </Button>
                    <Button
                      disabled={!props.dirty || !props.isValid}
                      variant='contained'
                      color='primary'
                      type='submit'
                    >
                      Ativar / Inativar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
