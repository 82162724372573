import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';
import XLSX from 'xlsx';

export const Aging4hReport = () => {
  const [aging4h, , loadingCompliance] = useQuery(() => serviceOrderService.aging4h(), []);

  const [aging4hDetails, , loadingAging4hDetails] = useQuery(
    () => serviceOrderService.aging4hDetails(),
    [],
  );

  const handleDownloadAging4hReport = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(aging4h));
    const worksheet2 = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(aging4hDetails));

    const workbook = (XLSX.WorkBook = {
      Sheets: {
        Geral: worksheet,
        Detalhes: worksheet2,
      },
      SheetNames: ['Geral', 'Detalhes'],
    });
    XLSX.writeFile(workbook, `RelatorioAging4${now}.xlsx`);
  };

  return (
    <>
      <PageTitle>Relatório aging dias</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button
            loading={loadingAging4hDetails && loadingAging4hDetails}
            fullWidth
            onClick={handleDownloadAging4hReport}
          >
            exportar relatório
          </Button>
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
};
