import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

import { useStyles } from './styles';
import { UpdateRequestTagDialog } from './UpdateRequestTagDialog';

const columns = [{ label: 'Nº da nota' }, { label: 'AÇÃO', align: 'center' }];

export function ReprintPackingListConferenceInvoiceRepairAll() {
  const classes = useStyles();

  const [arrayItens, setArrayItens] = useState([]);
  const nnfRef = useRef(null);

  useEffect(() => {
    nnfRef.current.focus();
  }, []);

  const handleAddItens = async (values, { setSubmitting, resetForm }) => {
    const { data } = await invoiceService.packingListValidation(values.nnf);

    setItemRequest(data);
    setIsDetailsState.toTrue();
    //setArrayItens((oldState) => [...oldState, values]);
  };

  const handleRemoveItens = (index) => {
    setArrayItens((prevItems) => {
      return prevItems.filter((item, i) => i !== index);
    });
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [batata, setItemRequest] = useState({});

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleDownloadTagsClick = async (arrayNnfs) => {
    const { data } = await invoiceService.downloadConferenceInvoicePackingListAll({ arrayNnfs });
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `RomaneioGeral.pdf`);
  };

  const onSubmitHandler = () => {
    console.log('Array Itens: ', arrayItens);
    handleDownloadTagsClick(arrayItens)
      .then((response) => {
        console.log('Response: ', response);

        setArrayItens([]);
        toast.success('Impressão de romaneio realizada com sucesso.');
      })
      .catch((err) => {
        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc?.decode(arr);

        const parsedResult = JSON?.parse(result);

        toast.error(parsedResult?.message);
      });
  };

  console.log('Array Itens: ', arrayItens);

  return (
    <>
      <PageTitle>Reimpressão de Romaneio Geral</PageTitle>
      <Formik initialValues={{ nnf: '' }} onSubmit={handleAddItens}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='N° nota entrada'
                  name='nnf'
                  variant='outlined'
                  size='small'
                  component={Text}
                  inputRef={nnfRef}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Adicionar nota
                </Button>
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} onClick={() => onSubmitHandler()} fullWidth>
                  Gerar Romaneio
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Table
            small
            name='itens'
            headers={columns}
            maxHeight
            striped
            emptyMessage='Sem notas cadastradas.'
          >
            {arrayItens ? (
              arrayItens?.map((item, index) => (
                <TableRow key={item}>
                  {console.log('item: ', item)}
                  <TableCell>{item}</TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      color='secondary'
                      onClick={() => handleRemoveItens(index)}
                      fullWidth
                    >
                      REMOVER
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <></>
            )}
          </Table>
          <UpdateRequestTagDialog
            open={isDetailsOpen}
            handleClose={handleCloseInformation}
            itemRequest={batata}
            setArrayItens={setArrayItens}
          />
        </Grid>
      </Grid>
    </>
  );
}
