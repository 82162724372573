export const tutorialSteps = [
  {
    target: '#scan',
    content: 'Escaneie o QR-Code para que retorne as informações do componente',
  },
  {
    target: '#button-search',
    content: 'Clique no botão pesquisar',
  },
];
