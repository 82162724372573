import React from 'react';
import { toast } from 'react-toastify';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Dialog, Mask, Tabs, Table, Spacer, FormatMoney, Button } from 'components';
import { warrantyService } from 'services/warranty';

import { useStyles } from './styles';

const columnsComponents = [
  { label: 'CÓDIGO' },
  { label: 'DESCRIÇÃO DOS PRODUTOS / SERVIÇOS ' },
  { label: 'NCM/SH' },
  { label: 'CST' },
  { label: 'CFOP' },
  { label: 'QUANT.' },
  { label: 'VLR UNIT.' },
  { label: 'VLR TOTAL' },
];

export const IssueAnalysisInvoiceBudgetDialog = ({ open, handleClose, data, values }) => {
  if (!open) return null;

  const classes = useStyles();

  const handleSubmit = async () => {
    try {
      await warrantyService.createBudgetInvoiceEmissions(values);

      toast.success('Nota de orçamento emitida com sucesso');

      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  let totalBudget = 0;

  Promise.all(
    data?.itens?.map((value) => {
      totalBudget += Number(value?.item?.valor_unitario) * Number(value?.item?.quantidade);

      return totalBudget;
    }),
  );

  return (
    <>
      <Dialog maxWidth='lg' onClose={handleClose} open={open}>
        <Dialog.Title onClose={handleClose}>
          NFe Informações de Orçamento - Service: {data?.service_number}
        </Dialog.Title>

        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <p className={classes.detailKey}>Natureza de Operação:</p>
              <p className={classes.detailValue}>{data?.operation ?? '-'}</p>
            </Grid>
            <Grid item xs={6}>
              <p className={classes.detailKey}>Protocolo de autorização de uso:</p>
              <p className={classes.detailValue}>*********** - **/**/** **:**:**</p>
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={1} />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <p className={classes.detailKey}>Nome / Razão Social:</p>
              <p className={classes.detailValue}>{data?.name}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>CNPJ/CPF:</p>
              <p className={classes.detailValue}>
                <Mask type='document'>{data?.cgc}</Mask>
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Data Emissão:</p>
              <p className={classes.detailValue}>{data?.emission_date}</p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Endereço:</p>
              <p className={classes.detailValue}>{`${data?.street}, ${data?.street_number}`}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Bairro:</p>
              <p className={classes.detailValue}>
                <Mask type='document'>{data?.district}</Mask>
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>CEP:</p>
              <p className={classes.detailValue}>{data?.zipcode}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Data Saída:</p>
              <p className={classes.detailValue}>{data?.entry_exit_data}</p>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Munícipio:</p>
              <p className={classes.detailValue}>{data?.city}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>FONE/FAX:</p>
              <p className={classes.detailValue}>
                <Mask type='phone'>{data?.phone_number}</Mask>
              </p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>UF:</p>
              <p className={classes.detailValue}>{data?.state}</p>
            </Grid>
            <Grid item xs={3}>
              <p className={classes.detailKey}>Hora Saída:</p>
              <p className={classes.detailValue}>{data?.entry_exit_hour}</p>
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={15} />
          <Divider variant='fullWidth' />

          <Tabs gutterBottom>
            <Tabs.Content label='Dados do Produto/Serviço' disablePadding>
              <Table
                headers={columnsComponents}
                emptyMessage='Nenhum registro encontrado.'
                disableNumeration
                striped
              >
                {data?.itens?.map((saleComponent, index) => {
                  return (
                    <TableRow key={saleComponent?.item?.codigo}>
                      <TableCell>{saleComponent?.item?.codigo}</TableCell>
                      <TableCell>{saleComponent?.item?.descricao}</TableCell>
                      <TableCell>{saleComponent?.item?.ncm}</TableCell>
                      <TableCell>***</TableCell>
                      <TableCell>***</TableCell>
                      <TableCell>{saleComponent?.item?.quantidade}.00</TableCell>
                      <TableCell>
                        {Number(saleComponent?.item?.valor_unitario).toFixed(2)}
                      </TableCell>
                      <TableCell>
                        {Number(
                          Number(Number(saleComponent?.item?.valor_unitario)?.toFixed(2)) *
                            Number(saleComponent?.item?.quantidade),
                        ).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Tabs.Content>
          </Tabs>

          <Grid container spacing={2}>
            <Grid item xs={10}>
              <Typography variant='h6' align='right'>
                TOTAL: <FormatMoney>{totalBudget}</FormatMoney>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Button color='primary' onClick={() => handleSubmit()}>
                Confirmar Emissão
              </Button>
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
      ;
    </>
  );
};
