import { createContext, React, useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

import { BackNavButton, Spacer } from 'components';
import { Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { budgetService } from 'services/budget';
import { serviceOrderService } from 'services/serviceOrder';

import { Actions } from './Actions';
import { Budget } from './Budget';
import Remark from './Budget/Remark';
import { ComplainClient } from './ComplaintClient';
import { DataOs } from './DataOs';
import { initialValues } from './formik';
import { PreBudget } from './PreBudget';
import { pnReducer } from './PreBudget/store/reducer';
import { StepIconComponent } from './StepIcon';
import useStyles from './styles';

const steps = [
  {
    label: 'Dados OS',
  },

  {
    label: 'Anotação',
  },
  {
    label: 'Pré-Orçamento',
  },
  {
    label: 'Orçamento',
  },
];

const QontoConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const PreBudgetContext = createContext();
export const initialPreBudgetState = [];

export const BudgetFg = () => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const {
    state: { service_order_number },
  } = useLocation();

  const navigateTo = () => {
    browserHistory.push({
      pathname: '/pcp/general',
    });
  };

  const [serviceOrder, , loadingServiceOrder] = useQuery(
    () => serviceOrderService.getByNumber(service_order_number),
    [service_order_number],
  );

  const [totalPreBudget, setTotalPreBudget] = useState(0);
  const [state, dispatch] = useReducer(pnReducer, initialPreBudgetState);

  let clone = [];

  state?.map((superState) => {
    for (let i = 0; i < superState.amount; i++) {
      clone.push(superState);
    }
  });

  // clone?.forEach((item, i) => {
  //   let index = clone.indexOf(item);

  //   console.log('Index: ', index);

  //   if (index !== -1) {
  //     console.log('Entrou Index: ', i);
  //     item.index = i; // (Math.random() + 1).toString(36).substring(7);
  //     clone[index] = item;
  //   }
  // });

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await budgetService.createBudget({
        ...values,
      });
      toast.success('Orçamento analisado com sucesso');

      console.log('Data: ', data);
      navigateTo();
      resetForm();
    } catch (err) {
      console.log(err);
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar orçamento.');
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = (activeStep) => {
    const steps = {
      0: loadingServiceOrder ? null : <DataOs serviceOrder={serviceOrder} />,
      1: loadingServiceOrder ? null : <Remark serviceOrder={serviceOrder} />,
      2: <PreBudget serviceOrder={serviceOrder} />,
      3: <Budget />,
      4: <ComplainClient />,
    };

    return steps[activeStep];
  };

  const totalPns = useCallback(
    (setTotalPreBudget) => {
      const total = state?.reduce((total, { sub_total }) => {
        return total + sub_total;
      }, 0);

      if (!state?.length) {
        return setTotalPreBudget(0);
      }

      setTotalPreBudget(total);
    },
    [state],
  );

  useEffect(() => {
    totalPns(setTotalPreBudget);
  }, [setTotalPreBudget, totalPns]);

  return (
    <>
      <PreBudgetContext.Provider
        value={{
          state,
          dispatch,
          setTotalPreBudget,
          totalPreBudget,
          clone,
        }}
      >
        <BackNavButton />

        <Spacer axis='vertical' size={16} />

        <PageTitle>Orçamento FG</PageTitle>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel connector={<QontoConnector />}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                {getStepContent(activeStep)}
                <Actions
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  stepsSize={steps.length}
                />
              </Form>
            )}
          </Formik>
        </div>
      </PreBudgetContext.Provider>
    </>
  );
};
