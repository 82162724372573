import React, { useEffect, useContext, useState, useMemo, useCallback } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Text, Radio, Select } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Field, useFormikContext } from 'formik';
import { UseDebounce } from 'hooks/useDebounce';
import { useQuery } from 'hooks/useQuery';
import { accountService } from 'services';
import { budgetService } from 'services/budget';

import { CartContext } from '../..';
import { useStyles } from './styles';

const installments = [
  {
    value: 1,
    label: '1x',
  },
  {
    value: 2,
    label: '2x',
  },
  {
    value: 3,
    label: '3x',
  },
  {
    value: 4,
    label: '4x',
  },
  {
    value: 5,
    label: '5x',
  },
  {
    value: 6,
    label: '6x',
  },
  {
    value: 7,
    label: '7x',
  },
  {
    value: 8,
    label: '8x',
  },
  {
    value: 9,
    label: '9x',
  },
  {
    value: 10,
    label: '10x',
  },
  {
    value: 11,
    label: '11x',
  },
  {
    value: 12,
    label: '12x',
  },
];

const discountOptions = [
  {
    value: 0,
    label: 'Sem desconto',
  },
  {
    value: 5,
    label: '5%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 15,
    label: '15%',
  },
];

export const ConfirmPurchase = () => {
  const classes = useStyles();

  const { debounce } = UseDebounce();

  const { serviceTypeSelect, state, totalCart } = useContext(CartContext);
  const { values, setFieldValue } = useFormikContext();

  const [paymentStatuses, , loadingPaymentStatuses] = useQuery(
    () => budgetService.listBudgetPaymentByStatusesWhereNotGenerateLink(),
    [],
  );

  const [attendantData, , loadingAttendantData, refetchAttendant] = useQuery(
    () => accountService.listAttendantActive(),
    [],
  );

  const [sellerData, , loadingSellerData, refetchSeller] = useQuery(
    () => accountService.listSellersActive(),
    [],
  );

  const [attendant, setAttendant] = useState({});
  const [attendants, setAttendants] = useState([]);

  const attendantOptions = (attendantData || []).map((att) => {
    return {
      value: att.id,
      label: att.name,
    };
  });

  const sellersOptions = (sellerData || []).map((att) => {
    return {
      value: att.id,
      label: att.name,
    };
  });

  const paymentStatusesOptions = (paymentStatuses || []).map((statuses) => {
    return {
      value: statuses.id,
      label: statuses.description,
    };
  });

  const formatValue = (value) => parseFloat(value).toFixed(2);

  const updateCart = useCallback(() => {
    const discountmaster = totalCart - (totalCart * values.discount) / 100;

    setFieldValue('total', formatValue(discountmaster));
  }, [setFieldValue, totalCart, values.discount]);

  const memoizedUpdateCart = useMemo(() => updateCart, [updateCart]);

  useEffect(() => {
    memoizedUpdateCart();
  }, [memoizedUpdateCart]);

  useEffect(() => {
    setAttendants(attendantData);
  }, [attendantData]);

  const [
    installmentPrice,
    installmentPriceCard1,
    installmentPriceCard2,
    installmentPriceCard3,
    moneyReceived,
  ] = useMemo(
    () => [
      formatValue(values.total / values.installments),
      formatValue(values.total_price_card / values.installments),
      formatValue(values.total_price_card2 / values.installments2),
      formatValue(values.total_price_card3 / values.installments3),
      formatValue(values.money_received),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values.total,
      values.installments,
      values.total_price_card,
      values.installments2,
      values.total_price_card2,
      values.installments3,
      values.total_price_card3,
      values.discount,
      totalCart,
      values.money_received,
    ],
  );

  useEffect(() => {
    if (![12].includes(Number(values.payment_method))) {
      setFieldValue('installments', 1);
    }
  }, [values.payment_method, setFieldValue]);

  useEffect(() => {
    if (![12].includes(Number(values.payment_method2))) {
      setFieldValue('installments2', 1);
    }
  }, [values.payment_method2, setFieldValue]);

  useEffect(() => {
    if (![12].includes(Number(values.payment_method3))) {
      setFieldValue('installments3', 1);
    }
  }, [values.payment_method3, setFieldValue]);

  useEffect(() => {
    if (Number(values.payment_type) === 1) {
      setFieldValue('installment_price', installmentPrice);
      setFieldValue('payment_method2', null);
      setFieldValue('total_price_card2', 0);
      setFieldValue('installments2', 1);
      setFieldValue('installment_price2', 0);
      setFieldValue('payment_method3', null);
      setFieldValue('total_price_card3', 0);
      setFieldValue('installments3', 1);
      setFieldValue('money_received', moneyReceived);

      if (Number(values.payment_method) === 11) {
        const moneyChange = formatValue(values.money_received - installmentPrice);
        setFieldValue('money_change', moneyChange);
      } else {
        setFieldValue('money_received', formatValue(0));
        setFieldValue('money_change', formatValue(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values.payment_method,
    installmentPrice,
    installmentPriceCard1,
    moneyReceived,
    setFieldValue,
  ]);

  useEffect(() => {
    if (Number(values.payment_type) === 2) {
      setFieldValue('installment_price', installmentPriceCard1);
      setFieldValue('installment_price2', installmentPriceCard2);
      setFieldValue('payment_method3', null);
      setFieldValue('total_price_card3', 0);
      setFieldValue('installment_price3', 0);
      setFieldValue('installments3', 1);
      setFieldValue('money_received', moneyReceived);

      if (Number(values?.payment_method) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard1));
      }

      if (Number(values?.payment_method2) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard2));
      }

      if (Number(values?.payment_method) !== 11 && Number(values?.payment_method2) !== 11) {
        setFieldValue('money_received', formatValue(0));
        setFieldValue('money_change', formatValue(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values?.payment_method,
    values?.payment_method2,
    installmentPriceCard2,
    installmentPriceCard1,
    moneyReceived,
    setFieldValue,
  ]);

  useEffect(() => {
    if (Number(values.payment_type) === 3) {
      setFieldValue('installment_price', installmentPriceCard1);
      setFieldValue('installment_price2', installmentPriceCard2);
      setFieldValue('installment_price3', installmentPriceCard3);

      if (Number(values?.payment_method) === 11) {
        const moneyChange = formatValue(values?.money_received - installmentPriceCard1);
        setFieldValue('money_change', moneyChange);
      }

      if (Number(values?.payment_method2) === 11) {
        setFieldValue('money_change', formatValue(values.money_received - installmentPriceCard2));
      }

      if (Number(values?.payment_method) === 13) {
        const moneyChange3 = formatValue(values?.money_received - installmentPriceCard3);
        setFieldValue('money_change', moneyChange3);
      }

      if (
        ![11].includes(Number(values?.payment_method)) &&
        ![11].includes(Number(values?.payment_method2)) &&
        ![11].includes(Number(values?.payment_method3))
      ) {
        setFieldValue('money_received', 0);
        setFieldValue('money_change', 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.payment_type,
    values?.payment_method,
    values?.payment_method2,
    values?.payment_method3,
    installmentPriceCard1,
    installmentPriceCard2,
    installmentPriceCard3,
    moneyReceived,
    setFieldValue,
  ]);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} lg={6}>
          <Paper className={classes.accessoriesInCart} variant='outlined'>
            <Typography variant='h6'>Meus acessórios:</Typography>
            {serviceTypeSelect == 1 ? (
              state.map((accessory) => (
                <Grid container alignItems='center' key={accessory.id}>
                  <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                    <Typography variant='body2'>{accessory.description}</Typography>
                    <Typography variant='body2'>PART-NUMBER: {accessory.part_number}</Typography>
                    <Typography variant='body2' className={classes.infoAccessory}>
                      <Typography variant='body2' className={classes.description}>
                        PREÇO(UN):
                        <Typography variant='body2'>
                          <FormatMoney>{accessory.component_acessory_price}</FormatMoney>
                        </Typography>
                      </Typography>
                      <Typography variant='body2' className={classes.quantity}>
                        QUANTIDADE:
                        <Typography variant='body2'>{accessory.amount}</Typography>
                      </Typography>
                      <Typography variant='body2'>
                        SUBTOTAL:
                        <Typography variant='body2' className={classes.subTotal}>
                          <FormatMoney>{accessory.sub_total}</FormatMoney>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}

            {serviceTypeSelect == 2 ? (
              state.map((labor) => (
                <Grid container alignItems='center' key={labor.id}>
                  <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                    <Typography variant='body2'>{labor.description}</Typography>
                    <Typography variant='body2'>
                      DESCRIÇÃO DO PRODUTO: {labor?.product?.description}
                    </Typography>
                    <Typography variant='body2' className={classes.infoAccessory}>
                      <Typography variant='body2' className={classes.description}>
                        PREÇO(UN):
                        <Typography variant='body2'>
                          <FormatMoney>{labor.labor_price}</FormatMoney>
                        </Typography>
                      </Typography>
                      <Typography variant='body2' className={classes.quantity}>
                        QUANTIDADE:
                        <Typography variant='body2'>{labor.amount}</Typography>
                      </Typography>
                      <Typography variant='body2'>
                        SUBTOTAL:
                        <Typography variant='body2' className={classes.subTotal}>
                          <FormatMoney>{labor.sub_total}</FormatMoney>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}

            {serviceTypeSelect == 3 ? (
              state.map((accessory) => (
                <Grid container alignItems='center' key={accessory.id}>
                  <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                    <Typography variant='body2'>{accessory.description}</Typography>
                    <Typography variant='body2'>PART-NUMBER: {accessory.part_number}</Typography>
                    <Typography variant='body2' className={classes.infoAccessory}>
                      <Typography variant='body2' className={classes.description}>
                        PREÇO(UN):
                        <Typography variant='body2'>
                          <FormatMoney>{accessory.component_acessory_price}</FormatMoney>
                        </Typography>
                      </Typography>
                      <Typography variant='body2' className={classes.quantity}>
                        QUANTIDADE:
                        <Typography variant='body2'>{accessory.amount}</Typography>
                      </Typography>
                      <Typography variant='body2'>
                        SUBTOTAL:
                        <Typography variant='body2' className={classes.subTotal}>
                          <FormatMoney>{accessory.sub_total}</FormatMoney>
                        </Typography>
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              ))
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Paper
            style={{ padding: '12px', background: '#F8F8F8', height: '220px' }}
            variant='outlined'
          >
            <Typography variant='h6' gutterBottom>
              Resumo do pedido:
            </Typography>
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}
              spacing={2}
            >
              <Grid item xs={3}>
                <p>
                  {state.length} produto(s){state.length > 1 ? 's' : ''}
                </p>
              </Grid>
              <Grid item xs={3}>
                <FormatMoney>{totalCart}</FormatMoney>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='middle' />
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography variant='h6' gutterBottom>
                  Total:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' gutterBottom>
                  <FormatMoney>{totalCart}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography variant='h6' gutterBottom>
                  Valor de desconto:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' gutterBottom>
                  - <FormatMoney>{parseFloat((totalCart * values.discount) / 100)}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
            <Grid
              style={{ display: 'flex', justifyContent: 'space-between' }}
              container
              spacing={2}
            >
              <Grid>
                <Typography variant='h6' gutterBottom>
                  Total com Desconto:
                </Typography>
              </Grid>
              <Grid>
                <Typography variant='h6' gutterBottom>
                  <FormatMoney>{values.total}</FormatMoney>
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Grid className={classes.toCloseMargin} container spacing={0}>
            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={1}
                fullWidth
                component={Radio}
                label='1 Método de Pagamento'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={2}
                fullWidth
                component={Radio}
                label='2 Métodos de Pagamentos'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <Field
                color='primary'
                name='payment_type'
                value={3}
                fullWidth
                component={Radio}
                label='3 Métodos de Pagamentos'
              />
            </Grid>
          </Grid>

          <Grid className={classes.paymentMethod} container spacing={1}>
            <Grid item xs={12} lg={8}>
              <Field
                variant='outlined'
                label='Atendente'
                name='attendance_id'
                size='small'
                fullWidth
                component={Select}
                options={attendantOptions}
                className={classes.paymentMethodOptions}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <Field
                variant='outlined'
                name='discount'
                label='Desconto %'
                size='small'
                component={Select}
                options={discountOptions}
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <Field
                variant='outlined'
                label='Vendedor (opcional)'
                name='seller_id'
                size='small'
                fullWidth
                component={Select}
                options={sellersOptions}
                className={classes.paymentMethodOptions}
              />
            </Grid>
          </Grid>

          {values.payment_type == 1 ? (
            <>
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_type == 2 ? (
            <>
              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 2
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method2 != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_type == 3 ? (
            <>
              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 1
              </Typography>

              <Grid className={classes.paymentMethod} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 2
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments2'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method2 != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card2'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price2'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid className={classes.paymentMethod} container spacing={2} />
              <Typography variant='h6' gutterBottom>
                Método de Pagamento 3
              </Typography>

              <Grid className={classes.sellPerson} container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Pagamento'
                    name='payment_method3'
                    size='small'
                    fullWidth
                    component={Select}
                    options={paymentStatusesOptions}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    variant='outlined'
                    label='Parcelas'
                    name='installments3'
                    size='small'
                    fullWidth
                    component={Select}
                    options={installments}
                    mask='document'
                    className={classes.paymentMethodOptions}
                    disabled={values?.payment_method3 != 12 ? true : false}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='total_price_card3'
                    label='Total das parcelas'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    name='installment_price3'
                    label='Valor por parcela'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          {values.payment_method == 11 ||
          values.payment_method2 == 11 ||
          values.payment_method3 == 11 ? (
            <>
              <Typography className={classes.toCloseMargin} variant='h6' gutterBottom>
                Fluxo de Dinheiro
              </Typography>
              <Grid className={classes.toCloseMargin} container spacing={1}>
                <Grid item xs={6} lg={6}>
                  <Field
                    variant='outlined'
                    name='money_received'
                    label='Valor de Entrada'
                    size='small'
                    mask='money'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} lg={6}>
                  <Field
                    variant='outlined'
                    name='money_change'
                    label='Troco'
                    size='small'
                    mask='money'
                    component={Text}
                    disabled
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid className={classes.alignToTheEnd} container spacing={0}>
            <Grid item xs={6} lg={6}>
              <Field
                variant='outlined'
                name='total'
                label='TOTAL'
                size='small'
                mask='money'
                component={Text}
                disabled
              />
            </Grid>
          </Grid>

          <Grid className={classes.alignToTheEnd} container spacing={0}>
            {serviceTypeSelect == 1 ? (
              <>
                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={1}
                    fullWidth
                    component={Radio}
                    label='Emissão com cupom de valor fiscal'
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={2}
                    fullWidth
                    component={Radio}
                    label='Gerar somente comprovante'
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            {serviceTypeSelect == 2 ? (
              <>
                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={2}
                    fullWidth
                    component={Radio}
                    label='Gerar somente comprovante'
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={3}
                    fullWidth
                    component={Radio}
                    label='Nota de Serviço'
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}

            {serviceTypeSelect == 3 ? (
              <>
                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={1}
                    fullWidth
                    component={Radio}
                    label='Emissão com cupom de valor fiscal'
                  />
                </Grid>

                <Grid item xs={12} lg={6}>
                  <Field
                    color='primary'
                    name='emission_coupon'
                    value={2}
                    fullWidth
                    component={Radio}
                    label='Gerar somente comprovante'
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          {console.log('PAyment Code: ', values.payment_code)}
          {console.log('Seller ID: ', values.seller_id)}

          <Grid className={classes.alignToTheEnd} container spacing={2}>
            <Grid item xs={12} lg={12}>
              <Field
                size='small'
                name='payment_code'
                variant='outlined'
                label='Código de Pagamento (opcional)'
                multiline
                rows={1}
                component={Text}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
