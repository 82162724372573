import React, { useState } from 'react';

import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { MoreInfoDialog } from './MoreInfo/MoreInfoDialog';
const ITEM_HEIGHT = 48;

export function ActionMenu({ data }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMoreInfoDialog, setOpenMoreInfoDialog] = useState(false);

  const open = Boolean(anchorEl);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleOpenMoreInfoDialog() {
    setOpenMoreInfoDialog(true);
  }

  function handleCloseMoreInfoDialog() {
    setOpenMoreInfoDialog(false);
  }

  return (
    <>
      <IconButton
        aria-label='more'
        aria-controls={open ? 'action-menu' : undefined}
        aria-haspopup='true'
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id='action-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '28ch',
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpenMoreInfoDialog();
          }}
        >
          <ListItemIcon>
            <InfoIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Detalhes' />
        </MenuItem>
      </Menu>

      <MoreInfoDialog
        isOpen={openMoreInfoDialog}
        handleClose={handleCloseMoreInfoDialog}
        sinister={data?.sinister}
      />
    </>
  );
}
