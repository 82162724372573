import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    paper: {
      margin: '0 0 15px 0',
      width: '100%',
      height: '100%',
    },

    containerLegend: {
      display: 'flex',
      textAlign: 'center',
      alignItems: 'center',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '50px',
      width: 400,
      padding: '10px',
      marginTop: '10px',
    },

    offer: {
      fontSize: '36px',
      color: '#f50057',
      fontWeight: 'bold',
      width: '100%',
      height: '100%',
      textAlign: 'center',
    },

    condition: {
      fontSize: '30px',
      fontWeight: 'bold',
      width: '100%',
      height: '100%',
      textAlign: 'center',
    },

    cardPaper: {
      maxWidth: '100%',
      maxHeight: '100%',
      marginLeft: '25px',
    },
  };
});
