import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Button, Text, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { invoiceService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const currencies = [
  {
    value: '{CABO,CARREGADOR,FONE,CHAVE,CHIP}',
    label: 'CABO,CARREGADOR,FONE,CHAVE,CHIP',
  },

  {
    value: '{CABO,CARREGADOR,FONE,CHAVE}',
    label: 'CABO,CARREGADOR,FONE,CHAVE',
  },
  {
    value: '{CABO,CARREGADOR,FONE}',
    label: 'CABO,CARREGADOR,FONE',
  },

  {
    value: '{CABO,CARREGADOR,CHAVE}',
    label: 'CABO,CARREGADOR,CHAVE',
  },

  {
    value: '{CABO,FONE,CHAVE}',
    label: 'CABO,FONE,CHAVE',
  },

  {
    value: '{CARREGADOR,FONE}',
    label: 'CARREGADOR,FONE',
  },

  {
    value: '{CABO,CARREGADOR}',
    label: 'CABO,CARREGADOR',
  },

  {
    value: '{CABO}',
    label: 'CABO',
  },

  {
    value: '{CARREGADOR}',
    label: 'CARREGADOR',
  },

  {
    value: '{FONE}',
    label: 'FONE',
  },

  {
    value: '{CHAVE EXTRATORA}',
    label: 'CHAVE EXTRATORA',
  },
];

const validationSchema = yup.object().shape({
  imei: yup.string().required(),
});

export const AlterImeiConferenceDialog = ({ open, handleClose, serviceOrder = {} }) => {
  if (!open) return null;
  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await invoiceService.updateConference(values);

      toast.success('Service alterada com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar a conferência.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Alterar conferência para a nota Nº: {serviceOrder[0]?.numero_nota}
        {console.log('teste: ', serviceOrder)}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            imei: serviceOrder[0]?.imei,
            number: serviceOrder[0]?.numero_nota,
            infnfe: serviceOrder[0]?.chave_nota,
            observation: serviceOrder[0]?.observation,
            ean: serviceOrder[0]?.ean,
            acessories: serviceOrder[0]?.acessories_checked,
            imei_new: '',
            observation_new: '',
            ean_new: '',
            acessories_new: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='imei'
                      label='Imei atual conferência'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='observation'
                      label='Observação atual conferência'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='ean'
                      label='EAN atual conferência'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='acessories'
                      label='Acessorios que vieram atual conferência'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='number'
                      label='N° nota'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='infnfe'
                      label='Chave'
                      variant='outlined'
                      component={Text}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='imei_new'
                      label='Novo Imei conferência'
                      variant='outlined'
                      component={Text}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='observation_new'
                      label='Nova observação'
                      variant='outlined'
                      component={Text}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='ean_new'
                      label='Novo cód EAN'
                      variant='outlined'
                      component={Text}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      size='small'
                      name='acessories_new'
                      label='Novos acessórios'
                      variant='outlined'
                      options={currencies}
                      component={Select}
                    />
                  </Grid>

                  <></>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary' variant='text'>
                      Fechar
                    </Button>
                    <Button color='primary' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
