import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Grid from '@mui/material/Grid';
import { ReactComponent as ImgWork } from 'assets/images/work.svg';
import { Text, Select, Button, Table, FormatMoney } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';

import { LabourDialog } from './LabourDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Serviço' },
  { label: 'Produto' },
  { label: 'Valor' },
  { label: <VisibilityIcon />, align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const LabourWithNoOrder = () => {
  const classes = useStyles();

  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [serviceOrder, setServiceOrder] = useState({});

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setServiceOrder(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const [name, setName] = useState('');

  const [labors, setLabors] = useState([]);

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  const [laborsData, , loadingLabors, refetchLabors] = useQuery(
    () => api.get('/cashier/labor/nogspn'),
    [],
  );

  useEffect(() => {
    setLabors(laborsData);
  }, [laborsData]);

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.description,
    }),
  );

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/cashier/labor/nogspn', values);

      setLabors((prevState) => [
        ...prevState,
        {
          ...data,
        },
      ]);

      refetchLabors();
      toast.success('Mão de obra cadastrada com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      console.log(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar serviço.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Tipo de serviço sem GSPN</PageTitle>
      <Formik
        initialValues={{ description: '', product: '', service_price: 0 }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Serviço'
                    name='description'
                    value={name}
                    size='small'
                    component={Text}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    label='Produto'
                    name='product_id'
                    size='small'
                    options={additionalServiceProductOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    name='labor_price'
                    label='Valor do serviço'
                    variant='outlined'
                    size='small'
                    mask='money'
                    emptyOption='Selecione'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <Table
                    headers={columns}
                    striped
                    disableNumeration
                    maxHeight={350}
                    Loading={loadingLabors}
                    data={laborsData}
                    emptyMessage='Nenhum serviço encontrado.'
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                  >
                    {labors?.map((labor) => (
                      <TableRow key={labor.id}>
                        <TableCell>{labor.description}</TableCell>
                        <TableCell>{labor.product?.description}</TableCell>
                        <TableCell>
                          <FormatMoney>{labor.labor_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, labor)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <Grid className={classes.containerImg} item xs={4}>
                  <ImgWork className={classes.img} />
                </Grid>
              </Grid>
              {userRole === 1 ? (
                <LabourDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  additionalServiceProductOptions={additionalServiceProductOptions}
                  labor={serviceOrder}
                  refreshLabor={refetchLabors}
                />
              ) : (
                <></>
              )}
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
