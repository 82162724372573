import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { budgetService } from 'services/budget';
import { useContextSelector } from 'use-context-selector';

import { ViewCouponDialog } from './ViewCouponDialog';

const columns = [
  { label: 'Service' },
  { label: 'Valor do orçamento', align: 'center' },
  { label: 'Nome do cliente', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const ViewVoucher = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [isDialog, setIsDialog] = useBoolean();
  const [teste, setTeste] = useState({});

  const [voucher, setVoucher] = useState([]);

  const [budgetWithVoucher, , loadingbudgetWithVoucher, refetchVoucher] = useQuery(
    () => budgetService.listBudgetWithVoucher(),
    [],
  );

  const handleCloseInformation = () => {
    setIsDialog.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDialog.toTrue();
    console.log('ITEM: ', item);
    setTeste(item);
  };

  useEffect(() => {
    setVoucher(budgetWithVoucher);
  }, [budgetWithVoucher]);

  return (
    <>
      <PageTitle>Visualizar comprovante</PageTitle>
      <Formik initialVlaues={{ search: '' }}>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Pesquisar'
                    name='search'
                    variant='outlined'
                    size='small'
                    component={Text}
                    endAdornment={<SearchIcon />}
                  />
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    maxHeight={400}
                    striped
                    emptyMessage='Nenhum registro encontrado.'
                    loading={loadingbudgetWithVoucher}
                    // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                    // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                    data={budgetWithVoucher}
                    disableNumeration
                  >
                    {voucher?.map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell>{row.number}</TableCell>
                        <TableCell align='center'>{row.final_budget_price}</TableCell>
                        <TableCell align='center'>{row.name}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, row)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>

                        {/* <TableCell align='right'>{row.voucher}</TableCell> */}
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                {userRole === 1 ? (
                  <ViewCouponDialog
                    open={isDialog}
                    handleClose={handleCloseInformation}
                    voucher={teste}
                    refreshVoucher={refetchVoucher}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
