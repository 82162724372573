import React, { useContext } from 'react';
import { useState } from 'react';

import { Button } from 'components';
import { DialogInformationForImport } from 'components/DialogInformationForImport';
import { useFormikContext } from 'formik';

import { ReceivementContext } from '..';

const examples = [
  {
    value: 1,
    label: 'Utilize o layout do TRADEIN_GERAL',
  },
  {
    value: 2,
    label: 'Não retire o cabeçalho da planilha',
  },
  {
    value: 3,
    label: 'Não formate ou coloque fórmulas na planilha',
  },
  {
    value: 4,
    label: 'Salve o arquivo como .csv',
  },
];

export function ImportProducts() {
  const { handleImportClick } = useContext(ReceivementContext);

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { values, isValid, dirty } = useFormikContext();

  function handleOpenDialog() {
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  return (
    <>
      <Button
        fullWidth
        color='primary'
        onClick={() => handleOpenDialog()}
        disabled={!isValid || !dirty}
      >
        Importar Produtos
      </Button>
      <DialogInformationForImport
        open={isOpenDialog}
        handleClose={handleCloseDialog}
        examples={examples}
        importFile={handleImportClick}
      />
    </>
  );
}
