import React, { useState } from 'react';
import { IoIosBarcode } from 'react-icons/io';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, Dialog, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { warehouseService } from 'services';
import * as yup from 'yup';

function validarChaveNFe(chave) {
  // Verifica se a chave tem exatamente 44 dígitos
  if (chave.length !== 44) {
    return false;
  }

  // Verifica se todos os caracteres são numéricos
  if (!/^\d+$/.test(chave)) {
    return false;
  }

  // Calcula e valida o dígito verificador (DV)
  let peso = 2;
  let soma = 0;

  // Percorre a chave do final para o início, exceto o último dígito (DV)
  for (let i = 42; i >= 0; i--) {
    soma += parseInt(chave[i]) * peso;
    peso++;
    if (peso > 9) {
      peso = 2;
    }
  }

  const resto = soma % 11;
  const digitoVerificador = resto < 2 ? 0 : 11 - resto;

  // Verifica se o dígito verificador está correto
  return parseInt(chave[43]) === digitoVerificador;
}

yup.addMethod(yup.string, 'nfKey', function () {
  return this.test({
    name: 'nfKey',
    message: 'Chave da NF-e inválida',
    test: (value) => validarChaveNFe(value),
  });
});

const validationSchema = yup.object().shape({
  request_id: yup.number().required(),
  model: yup.string(),
  serial: yup.string(),
  model_service: yup.string(),
  comment: yup.string(),
  status_id: yup.number(),
  product_id: yup.number(),
  nf_key: yup.string().length(44).required(),
});

export function ServiceDialog({ request, isOpen = false, handleClose, refetch }) {
  if (!isOpen) {
    return <></>;
  }
  const [isProductValid, setIsProductValid] = useState(false);
  async function handleSerialBlur({ values, setFieldValue }) {
    try {
      const { data } = await warehouseService.findProductBySerial(values.serial.trim());

      setFieldValue('model_service', data.model);
      setFieldValue('product_id', data.id);
      setIsProductValid(true);
      toast.success('Produto encontrado com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao encontrar o produto pelo serial');
    }
  }

  async function handleUpdateProduct(values, { setSubmitting }) {
    try {
      setSubmitting(true);

      await warehouseService.createService(values);
      toast.success('Pedido atendido com sucesso, enviado para expedição.');
      refetch();
      handleClose();
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao atender o produto.');
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='sm'>
      <Dialog.Title onClose={handleClose}>Atendimento do item: {request?.model}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            request_id: request.id,
            model: request?.model ?? '',
            model_service: '',
            serial: '',
            comment: '',
            status_id: 2,
            product_id: 0,
            nf_key: '',
          }}
          onSubmit={handleUpdateProduct}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='model'
                      label='Modelo requisitado'
                      size='small'
                      component={Text}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='serial'
                      label='Serial atendido'
                      size='small'
                      endAdornment={<IoIosBarcode />}
                      component={Text}
                      onBlur={() => handleSerialBlur(props)}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='nf_key'
                      label='Chave NF da Assurant'
                      size='small'
                      endAdornment={<IoIosBarcode />}
                      component={Text}
                      inputProps={{ maxLength: 44 }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      variant='outlined'
                      name='model_service'
                      label='Modelo atendido'
                      size='small'
                      component={Text}
                      disabled={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button onClick={handleClose} color='secondary' variant='outlined' fullWidth>
                      Fechar
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      disabled={!props.dirty || !props.isValid || !isProductValid}
                      color='primary'
                      variant='contained'
                      type='submit'
                    >
                      Atender pedido
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
