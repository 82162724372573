import formatter from 'date-fns/format';

export const FormatDate = ({ children, format = 'dd/MM/yyyy' }) => {
  if (typeof children === 'string') {
    children = new Date(children);
  }

  if (!children) {
    return '';
  }

  console.log(children);
  return formatter(children, format);
};
