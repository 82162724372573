import React, { useContext } from 'react';

import { Button } from 'components';
import { useFormikContext } from 'formik';

import { ClientContext } from '../';
import { registerClient } from '../ClientInfoNew';
import { useStyles } from './styles';

export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
  const { handleSubmit, isValid, dirty, isSubmitting, values, resetForm } = useFormikContext();

  const { clientExists, setClientExists, hasClient, setHasClient } = useContext(ClientContext);

  const classes = useStyles();

  const isFirstStep = activeStep === 0;

  return (
    <div className={classes.footer}>
      <div className={classes.actions}>
        <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
          Voltar
        </Button>
        {activeStep === stepsSize - 1 &&
          (clientExists ? (
            <Button
              color='primary'
              loading={isSubmitting}
              onClick={() => registerClient(values, setClientExists, setHasClient, resetForm)}
            >
              Cadastrar cliente
            </Button>
          ) : (
            <Button
              type='submit'
              color='primary'
              onClick={handleSubmit}
              disabled={!isValid || !dirty || isSubmitting}
              loading={isSubmitting}
            >
              Enviar
            </Button>
          ))}
        {activeStep !== stepsSize - 1 && (
          <Button
            color='primary'
            disabled={!isFirstStep ? values.service_order_found : isSubmitting}
            loading={isSubmitting}
            onClick={handleNext}
          >
            {console.log('Values: ', values)}
            Próxima Página
          </Button>
        )}
      </div>
    </div>
  );
};

// import React from 'react';

// import { Button } from 'components';
// import { useFormikContext } from 'formik';

// import { useStyles } from './styles';

// export const Actions = ({ activeStep, handleBack, handleNext, stepsSize }) => {
//   const { handleSubmit, isValid, dirty, isSubmitting, values } = useFormikContext();

//   const classes = useStyles();

//   const isFirstStep = activeStep === 0;

//   return (
//     <div className={classes.footer}>
//       ,
//       <div className={classes.actions}>
//         <Button disabled={isFirstStep} onClick={handleBack} className={classes.backButton}>
//           Voltar
//         </Button>

//         {activeStep === stepsSize - 1 && (
//           <Button
//             type='submit'
//             color='primary'
//             onClick={handleSubmit}
//             disabled={!isValid || !dirty}
//             loading={isSubmitting}
//           >
//             Enviar
//           </Button>
//         )}
//         {activeStep !== stepsSize - 1 && (
//           <Button
//             color='primary'
//             disabled={!isFirstStep ? values.service_order_found : isSubmitting}
//             loading={isSubmitting}
//             onClick={handleNext}
//           >
//             {console.log('Values: ', values)}
//             Próxima Página
//           </Button>
//         )}
//       </div>
//     </div>
//   );
// };
