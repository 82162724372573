import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, FormatDate, Table } from 'components';
import { Button } from 'components/Button';
import { Formik, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { preBudgetService, serviceOrderService } from 'services';

import { LinkInformationDialog } from './LinkInformationDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Service' },
  { label: 'Data de criação/atribuição', align: 'center' },
  { label: 'Ação', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const TableAssigned = () => {
  const classes = useStyles();

  const location = useLocation();
  const service = location?.state?.service;

  const [search, setSearch] = useState(service ?? '');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  console.log('numero da service', service);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [serviceOrders, , loadingServiceOrders, refetchServiceOrders] = useQuery(
    preBudgetService.listGsnpPreBudgetsLocal,
    [],
  );

  const filteredServiceOrders = (serviceOrders || []).filter((serviceOrder) => {
    const loweredSearch = search.toLowerCase();

    return (
      serviceOrder?.number?.toLowerCase().includes(loweredSearch) ||
      serviceOrder?.created_at?.toLowerCase().includes(loweredSearch)
    );
  });

  console.log('filteredServiceOrders/: ', filteredServiceOrders);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const [preBudgetInformationDialog, setPreBudgetInformationDialog] = useState({});

  const navigateToOutOfWarrantyBudgetNoGspn = async (serviceOrderAndPreBudget) => {
    const { data } = await serviceOrderService.getByNumber(serviceOrderAndPreBudget?.number);

    console.log('Test Your Mind: ', data);

    browserHistory.push({
      pathname: '/conference/budget-fg',
      state: {
        pre_budget_id: 2,
        service_order_id: data?.id,
        service_order_number: data?.number,
      },
    });
  };

  const navigateToStockRequisition = async (serviceOrderAndPreBudget) => {
    console.log('Test Your Mind: ', data);
    const { data } = await serviceOrderService.getByNumber(serviceOrderAndPreBudget?.number);

    browserHistory.push({
      pathname: '/production/stock-requisition',
      state: {
        service_order_number: data?.number,
      },
    });
  };

  const navigateToOutOfWarrantyBudget = async (serviceOrderAndPreBudget) => {
    let partNumbersWithoutSalesComponents;

    const { data } = await preBudgetService.listGsnpPreBudgets(serviceOrderAndPreBudget?.number);

    console.log('maso q e isso', data);

    const partNumberHasNoSaleComponents = data[0]?.gspn_pre_budget?.filter((partNumber) => {
      return !partNumber?.sale_component_id;
    });

    if (partNumberHasNoSaleComponents && partNumberHasNoSaleComponents.length !== 0) {
      partNumbersWithoutSalesComponents = partNumberHasNoSaleComponents.map((component) => {
        return component.PartsNo;
      });

      browserHistory.push({
        pathname: '/general-registration/register-service-products',
        state: {
          pre_budget_id: 2,
          service_order_id: data[0]?.service?.idservice,
          service_order_number: data[0]?.service?.description,
          preBudgetComponents: data[0]?.gspn_pre_budget,
        },
      });

      toast.error(
        `Os componentes de partNumber ${partNumbersWithoutSalesComponents.join(
          ',',
        )} não estão cadastrados para o processo de service de ${data[0].service?.description}`,
      );
    } else {
      browserHistory.push({
        pathname: '/conference/budget-fg',
        state: {
          pre_budget_id: 2,
          service_order_id: data[0]?.service?.idservice,
          service_order_number: data[0]?.service?.number,
          preBudgetComponents: data[0]?.gspn_pre_budget,
        },
      });
    }
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setPreBudgetInformationDialog(item);
  };

  const handleUpdateToInRepair = async (service_number) => {
    try {
      const { data } = await serviceOrderService.getByNumber(service_number);

      await serviceOrderService.updateServiceStatusId(data?.id);

      browserHistory.push({
        pathname: '/production/stock-requisition',
        state: {
          service_order_number: data?.number,
        },
      });

      toast.success('Status da ordem de serviço alterada para EM REPARO');

      refetchServiceOrders();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Não foi possível alterar o status da ordem de serviço',
      );
    }
  };

  console.log('services: ', serviceOrders);

  const ConditionalActionPcp = ({ WarranyType, ServiceOrderAndPreBudget }) => {
    return WarranyType == 'Out of Warranty' ? (
      <Button
        variant='outlined'
        onClick={() => navigateToOutOfWarrantyBudgetNoGspn(ServiceOrderAndPreBudget)}
        size='small'
      >
        Orçamento
      </Button>
    ) : (
      <Button
        color='primary'
        size='small'
        disabled={ServiceOrderAndPreBudget.status_id !== 10}
        onClick={() => handleUpdateToInRepair(ServiceOrderAndPreBudget?.number)}
      >
        {ServiceOrderAndPreBudget.status_id === 10 ? 'PROSSIGA COM O REPARO' : 'EM REPARO'}
      </Button>
    );
  };

  return (
    <Formik initialValues={{ search_os: service ?? '' }}>
      <>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Field
              size='small'
              fullWidth
              endAdornment={<SearchIcon />}
              name='search_os'
              variant='outlined'
              label='Consultar OS'
              component={Text}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Table
              headers={columns}
              loading={loadingServiceOrders}
              emptyMessage='Nenhum registro encontrado.'
              striped
              maxHeight
              data={filteredServiceOrders}
              // onActionsClick={handleOpenInformation}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredServiceOrders?.map((serviceOrder) => {
                return (
                  <TableRow key={serviceOrder?.id}>
                    <TableCell>{serviceOrder?.number}</TableCell>
                    <TableCell align='center'>
                      <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                        {serviceOrder?.created_at}
                      </FormatDate>
                    </TableCell>

                    <TableCell align='center'>
                      <ConditionalActionPcp
                        WarranyType={serviceOrder?.warranty_type}
                        ServiceOrderAndPreBudget={serviceOrder}
                      />
                    </TableCell>
                    <TableCell align='center'>
                      <IconButton
                        color='primary'
                        onClick={(e) => handleOpenInformation(e, serviceOrder)}
                      >
                        <AddCircleIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </Grid>
          <LinkInformationDialog
            open={isDetailsOpen}
            handleClose={handleCloseInformation}
            prebudget={preBudgetInformationDialog}
          />
        </Grid>
      </>
    </Formik>
  );
};
