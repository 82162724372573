import { React, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import cep from 'cep-promise';

import { ClientContext } from '..';

import { Text } from 'components';
import { Field, useFormikContext } from 'formik';
import { api, serviceOrderService } from 'services';

export const registerClient = async (
  values,
  setClientExists,
  setHasClient,
  resetForm,
  setFieldValue,
) => {
  try {
    const { data } = await api.post('/service-orders/clients', values);

    toast.success('Cliente cadastrado com sucesso');
    setClientExists(false);
    setFieldValue('cgc', data?.client.cgc);
  } catch (error) {
    if (error?.response?.status == 500) {
      toast.error('Preencha os campos corretamente, falta algum campo para preencher');
    } else {
      toast.error(error?.response?.data?.message);
    }
  }
};

export const ClientInfoNew = ({ FormProps }) => {
  console.log('Form Props: ', FormProps);
  const [loadingClient, setLoadingClient] = useState(false);
  const [cgc, setCgc] = useState('');
  const { clientExists, setClientExists, hasClient, setHasClient } = useContext(ClientContext);
  const { resetForm, setFieldValue } = useFormikContext();
  const [isNotObject, setNotObject] = useState(false);
  useEffect(() => {
    setClientExists(false);
    setHasClient(true);
    return () => {
      setClientExists(false), setHasClient(false);
    };
  }, [setClientExists, setHasClient]);

  const disbleIfIsNotObject = () => {
    if (isNotObject) {
      setNotObject(false);
    } else {
      setNotObject(true);
    }
  };

  const handleCgcBlur = async ({ errors, setValues, values }) => {
    try {
      //console.log(values);
      setLoadingClient(true);
      if (errors.cgc) return;

      const { data } = await serviceOrderService.getClientByCgc(values.cgc);

      const {
        addresses,
        id: client_id,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
      } = data;

      const clientFirstAddress = addresses[0];

      setValues({
        ...values,
        ...clientFirstAddress,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
        client_id,
      });

      setHasClient(false);
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
      toast.warning('Faça o cadastro do novo cliente antes de efetuar a compra');

      setCgc(values.cgc);

      setClientExists(true);
      setHasClient(false);

      setFieldValue('cgc', cgc);
      console.error(err);
    } finally {
      setLoadingClient(false);
    }
  };

  const [loadingCep, setLoadingCep] = useState(false);
  const { values } = useFormikContext();
  const onBlurCep = async () => {
    setLoadingCep(true);
    const { state, city, neighborhood, street } = await cep(values.zipcode);

    setFieldValue('state', state);
    setFieldValue('city', city);
    setFieldValue('district', neighborhood);
    setFieldValue('street', street);

    setLoadingCep(false);
  };

  return (
    <>
      <Grid style={{ padding: '15px', width: '100%' }} container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Field
            variant='outlined'
            label='CPF/CNPJ do cliente'
            name='cgc'
            size='small'
            component={Text}
            mask='document'
            onBlur={() => handleCgcBlur(FormProps)}
            autoFocus
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <Field
            variant='outlined'
            label='Nome do cliente'
            name='name'
            size='small'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>

        <Grid item xs={6} lg={3}>
          <Field
            size='small'
            fullWidth
            name='zipcode'
            mask='zipcode'
            variant='outlined'
            label='CEP'
            component={Text}
            onBlur={onBlurCep}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            size='small'
            variant='outlined'
            fullWidth
            label='Cidade'
            name='city'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            size='small'
            variant='outlined'
            fullWidth
            label='Estado'
            name='state'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            size='small'
            variant='outlined'
            fullWidth
            label='Bairro'
            name='district'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Field
            size='small'
            variant='outlined'
            fullWidth
            label='Rua'
            name='street'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            variant='outlined'
            label='Complemento'
            name='complement'
            size='small'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            variant='outlined'
            label='Número'
            name='street_number'
            size='small'
            component={Text}
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            variant='outlined'
            label='Telefone Fixo'
            name='residential_phone_number'
            size='small'
            component={Text}
            mask='phone'
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <Field
            variant='outlined'
            label='Celular'
            name='phone_number'
            size='small'
            component={Text}
            mask='phone'
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Field
            variant='outlined'
            label='Email'
            name='email'
            component={Text}
            type='email'
            size='small'
            loading={loadingClient}
            InputLabelProps={{ shrink: true }}
            disabled={hasClient}
          />
        </Grid>
        {/* <Grid item xs={3} lg={3} style={{ fontSize: 15 }}>
          <Field component={Checkbox} name='invalidEmail' onClick={disbleIfIsNotObject} />
          <Typography variant='h7' gutterBottom>
            E-mail inválido
          </Typography>
        </Grid> */}
      </Grid>
    </>
  );
};
