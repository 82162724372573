import React, { memo, useCallback, useRef, useEffect } from 'react';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import { Button } from 'components';
import { getFieldError } from 'helpers/formik';

import { FileList } from './FileList';
import { Loading } from './Loading';
import { useStyles } from './styles';

const FileUploaderComp = ({
  field,
  form,
  loading,
  fullWidth = true,
  acceptOnly,
  onChange,
  onRemoveFile,
  isMultiple,
  errorMessage: errorMessageProp,
  value,
  label,
  name,
  helperText,
}) => {
  name = field?.name || name;
  value = field?.value || value;

  const inputRef = useRef(null);

  const classes = useStyles({
    fullWidth,
  });

  useEffect(() => {
    if (!value) {
      inputRef.current.value = '';
    }
  }, [value]);

  const handleChooseFileClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleOnDrop = (event) => {
    if (!inputRef.current) return;

    let filesDropped;

    if (isMultiple) {
      filesDropped = event.dataTransfer.files;
    } else {
      const dT = new DataTransfer();
      dT.items.add(event.dataTransfer.files[0]);
      filesDropped = dT;
    }

    inputRef.current.files = filesDropped;

    form && form?.setFieldValue(field?.name, isMultiple ? Array.from(filesDropped) : filesDropped);
    event.preventDefault();
  };

  const handleInputFileChange = (event) => {
    const filesDropped = isMultiple ? Array.from(event.target.files) : event.target.files[0];

    if (form) {
      form.setFieldValue(field?.name, filesDropped);
      form.setFieldTouched(field?.name, true, false);
    }

    onChange?.(event);
  };

  const handleClean = useCallback(() => {
    form && form.setFieldValue(field?.name, {});
    inputRef.current.value = '';
  }, [form, field]);

  const handleRemoveFile = useCallback(
    (event, index) => {
      if (isMultiple) {
        const dT = new DataTransfer();

        const filteredFiles = [].filter.call(inputRef.current.files, (file, i) => {
          const shouldntRemove = i !== index;
          shouldntRemove && dT.items.add(file);
          return shouldntRemove;
        });

        form && form.setFieldValue(field?.name, filteredFiles);
        inputRef.current.files = dT.files;
      } else {
        handleClean();
      }

      onRemoveFile && onRemoveFile(event);
    },
    [isMultiple, form, field, onRemoveFile, handleClean],
  );

  const preventDefaultEvent = (event) => event.preventDefault();

  const shouldShowList = !!inputRef.current?.files?.length;

  const errorMessage = errorMessageProp || getFieldError(field?.name, form);
  const hasError = !!errorMessage;

  return (
    <>
      {!!label && <FormLabel className={classes.formLabel}>{label}</FormLabel>}

      <div
        className={classes.root}
        onDragEnter={preventDefaultEvent}
        onDragOver={preventDefaultEvent}
        onDrop={handleOnDrop}
      >
        <input
          className={classes.fileInput}
          ref={inputRef}
          type='file'
          accept={acceptOnly}
          onChange={handleInputFileChange}
          multiple={!!isMultiple}
        />

        {!shouldShowList && (
          <>
            <CloudUploadIcon className={classes.uploadIcon} />
            <p className={classes.dragAndDropText}>Arraste e jogue seus arquivos aqui</p>
            <p className={classes.orText}>ou</p>
            <Button variant='outlined' onClick={handleChooseFileClick}>
              Escolher arquivo
            </Button>
          </>
        )}

        {shouldShowList && (
          <FileList
            files={inputRef.current?.files}
            handleRemoveFile={handleRemoveFile}
            handleClean={handleClean}
            handleChooseFileClick={handleChooseFileClick}
          />
        )}

        {(loading || form?.isSubmitting) && <Loading />}
      </div>

      {!!(errorMessage || helperText) && (
        <FormHelperText error={hasError} variant='outlined'>
          {errorMessage || helperText}
        </FormHelperText>
      )}
    </>
  );
};

export const FileUploader = memo(FileUploaderComp);
