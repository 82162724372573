import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useMutation, useQuery } from '@apollo/client';
import CancelIcon from '@mui/icons-material/Cancel';
import { TablePagination } from '@mui/material';
import { Table } from 'components';
import {
  LIST_LAST_TEN_RECEIVING_ITENS,
  REMOVE_RECEIVING_ITEM,
} from 'services/graphql/query/receiving';

const columns = [
  { label: 'Voucher', align: 'center' },
  { label: 'Remover', align: 'center' },
];

export function DockTable({ header_id, addNewItem }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const { data, loading, refetch } = useQuery(LIST_LAST_TEN_RECEIVING_ITENS, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      header_id,
    },
    fetchPolicy: 'network-only',
  });

  const [removeItem] = useMutation(REMOVE_RECEIVING_ITEM, {
    onCompleted: () => {
      toast.success('Item removido com sucesso');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao remover o item';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleRemoveItem = async (itemId) => {
    try {
      await removeItem({ variables: { itemId: itemId } });
    } catch (error) {
      console.log('Error =>', error);
      toast.error('Erro ao remover o item');
    }
  };

  useEffect(() => {
    refetch();
  }, [addNewItem, refetch]);

  return (
    <>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={loading}
        disableNumeration
      >
        {(data?.listLastTenReceivingItens?.responses || []).map((item, index) => {
          return (
            <TableRow key={index}>
              <TableCell align='center'>{item?.sinister}</TableCell>
              <TableCell align='center'>
                <IconButton onClick={() => handleRemoveItem(item.id)} color='secondary'>
                  <CancelIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={data?.listLastTenReceivingItens?.totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
