import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { RegisterClientDialog } from 'components';
import { BackNavButton, Button, Clipboard, Dialog, Select, Text, Spacer } from 'components';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { pllService } from 'services/pll';
import { serviceOrderService } from 'services/serviceOrder';

import { validationSchema, initialValues } from './formik';
import useStyles from './styles';

export const CheckinPll = () => {
  const {
    state: { mail_object },
  } = useLocation();

  const classes = useStyles();

  const [loadingClient, setLoadingClient] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDialogSuccessOpen, setIsDialogSuccessOpen] = useState(false);

  const [attendanceTypes, , loadingAttendancesTypes] = useQuery(
    () => pllService.getAttendanceTypes(),
    [],
  );
  const [models, , loadingModels] = useQuery(() => pllService.getModels(), []);
  const [colors, , loadingColors] = useQuery(() => pllService.getColors(), []);
  const [serviceTypes, , loadingServiceTypes] = useQuery(() => pllService.getServiceTypes(), []);

  const attendanceTypesOptions = (attendanceTypes || []).map((attendance) => ({
    value: attendance.id,
    label: attendance.title,
  }));

  const modelsOptions = (models || []).map((model) => ({
    value: model.id,
    label: model.title,
  }));

  const serviceTypesOptions = (serviceTypes || []).map((service) => ({
    value: service.id,
    label: service.title,
  }));

  const colorsOptions = (colors || []).map((color) => ({
    value: color.id,
    label: color.description,
  }));

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await pllService.createCheckin(values);
      resetForm();
      toast.success('Checkin realizado com sucesso.');
      setIsDialogSuccessOpen(true);
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar checkin.');
    } finally {
      setSubmitting(false);
    }
  };

  const handleCgcBlur = async ({ errors, setValues, values }) => {
    try {
      setLoadingClient(true);

      if (errors.cgc) return;

      const { data } = await serviceOrderService.getClientByCgc(values.cgc);

      const {
        addresses,
        id: client_id,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
      } = data;

      const clientFirstAddress = addresses[0];

      setValues({
        ...values,
        ...clientFirstAddress,
        cgc,
        email,
        exempt,
        ie,
        name,
        phone_number,
        residential_phone_number,
        client_id,
        mail_object_id: mail_object.id,
      });
    } catch (err) {
      setValues(initialValues);
      toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
      console.error(err);
    } finally {
      setLoadingClient(false);
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogSuccessClose = () => {
    setIsDialogSuccessOpen(false);
  };

  return (
    <>
      <Dialog open={isDialogSuccessOpen} onClose={handleDialogSuccessClose} maxWidth='xs'>
        <Dialog.Title onClose={handleDialogSuccessClose}>Checkin gerado com sucesso</Dialog.Title>
        <Dialog.Content>
          <Typography>Código CR</Typography>
          <Spacer axis='vertical' size={8} />
          <Clipboard />
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={handleDialogSuccessClose} variant='text'>
            Fechar
          </Button>
        </Dialog.Actions>
      </Dialog>
      <div className={classes.checkinHeader}>
        <BackNavButton />

        <span className={classes.mailObjectTag}>
          <LocalShippingIcon className='box-icon' />
          {mail_object.code}
        </span>
      </div>

      <Spacer axis='vertical' size={32} />

      <PageTitle>Checkin PLL</PageTitle>
      <Formik
        initialValues={{
          ...initialValues,
          mail_object_id: mail_object.id,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container justify='space-between'>
                <Grid item>
                  <Typography variant='h6' gutterBottom>
                    Dados do cliente (Pesquisar por CPF)
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    onClick={handleDialogOpen}
                    fullWidth
                    startIcon={<AddIcon />}
                    variant='outlined'
                  >
                    Novo cliente
                  </Button>
                </Grid>
              </Grid>

              <Spacer axis='vertical' size={32} />

              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    label='CPF do cliente'
                    name='cgc'
                    size='small'
                    component={Text}
                    mask='document'
                    onBlur={() => handleCgcBlur(props)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    label='Nome do cliente'
                    name='name'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <Field
                    variant='outlined'
                    label='Telefone Fixo'
                    name='residential_phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <Field
                    variant='outlined'
                    label='Celular'
                    name='phone_number'
                    size='small'
                    component={Text}
                    mask='phone'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    fullWidth
                    name='zipcode'
                    mask='zipcode'
                    variant='outlined'
                    label='CEP'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Estado'
                    name='state'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Cidade'
                    name='city'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Rua'
                    name='street'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Número'
                    name='street_number'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <Field
                    variant='outlined'
                    label='Complemento'
                    name='complement'
                    size='small'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Bairro'
                    name='district'
                    component={Text}
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Field
                    variant='outlined'
                    label='Email'
                    name='email'
                    component={Text}
                    type='email'
                    size='small'
                    loading={loadingClient}
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Typography variant='h6' gutterBottom>
                Sinistro
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='sinister'
                    label='Sinistro'
                    size='small'
                    inputProps={{ maxLength: 15 }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='service_type_pll_id'
                    label='Serviço PLL'
                    size='small'
                    component={Select}
                    options={serviceTypesOptions}
                    loading={loadingServiceTypes}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='attendance_type_pll_id'
                    label='Atendimento PLL'
                    size='small'
                    component={Select}
                    loading={loadingAttendancesTypes}
                    options={attendanceTypesOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='color_pll_id'
                    label='Cor PLL'
                    size='small'
                    component={Select}
                    loading={loadingColors}
                    options={colorsOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='model_pll_id'
                    label='Modelo'
                    size='small'
                    component={Select}
                    options={modelsOptions}
                    loading={loadingModels}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='buy_place'
                    label='Local compra'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='buy_value'
                    label='Valor da compra'
                    size='small'
                    mask='money'
                    inputProps={{
                      min: 1,
                    }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='imei'
                    label='IMEI'
                    size='small'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='serial'
                    label='Serial'
                    size='small'
                    component={Text}
                  />
                </Grid>
              </Grid>
              <div item className={classes.gridSendBtn}>
                <Button size='large' disabled={!props.dirty || !props.isValid} type='submit'>
                  Enviar
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>

      <RegisterClientDialog open={isDialogOpen} onClose={handleDialogClose} />
    </>
  );
};
