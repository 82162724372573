/* eslint-disable no-restricted-imports */
import React, { useRef, useState } from 'react';

import { Grid, TextField, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { Button, Spacer } from 'components';
import BarcodeScannerDialog from 'components/BarcodeScannerDialog';
import { Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';

import { DockTable } from '../DockTable';

export function ProductCollectionForm({ onItemCollected, onReturn, data }) {
  console.log('data', data);
  const [scannedCode, setScannedCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addNewItem, setAddNewItem] = useBoolean(false);
  const formikRef = useRef();

  const handleSubmit = async (values) => {
    setLoading(true);
    await onItemCollected(values.voucher);
    setLoading(false);
    setScannedCode('');
    setAddNewItem.toggle();
  };

  const handleScanSuccess = (code) => {
    try {
      setScannedCode(code); // Atualiza o código escaneado
      formikRef.current.handleSubmit();
      // onReturn((prev) => ({
      //   ...prev,
      //   itemsCollected: (prev.itemsCollected || 0) + 1, // Atualiza o contador de itens coletados
      // }));
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton color='secondary' onClick={() => onReturn(null)}>
          <ArrowBackIcon fontSize='large' />
        </IconButton>
      </Grid>
      <Spacer size={32} />
      <Formik
        innerRef={formikRef}
        initialValues={{ voucher: scannedCode || '' }}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form>
            <Typography>Itens coletados: {data?.itemsCollected ?? 0}</Typography>
            <TextField
              label='Código do Voucher'
              name='voucher'
              value={scannedCode || props.values.voucher}
              onChange={(e) => {
                props.handleChange(e);
                setScannedCode(e.target.value);
              }}
              variant='outlined'
              fullWidth
              margin='normal'
              disabled={loading}
            />

            <Grid container spacing={2}>
              <Grid item xs={6} xl={6}>
                <Button
                  fullWidth
                  onClick={() => setDialogOpen(true)} // Abre o diálogo do scanner
                  disabled={loading}
                >
                  Escanear Código
                </Button>
              </Grid>
              <Grid item xs={6} xl={6}>
                <Button
                  fullWidth
                  type='submit'
                  color='secondary'
                  disabled={(!scannedCode && !props.values.voucher) || loading}
                >
                  {loading ? 'Enviando...' : 'Confirmar Coleta'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Spacer size={42} />

      <DockTable header_id={data.id} addNewItem={addNewItem} />

      <BarcodeScannerDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onScanSuccess={handleScanSuccess}
      />
    </>
  );
}
