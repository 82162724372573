import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useQuery } from '@apollo/client';
import QrCodeIcon from '@mui/icons-material/QrCode';
import { ReactComponent as Trash } from 'assets/images/product.svg';
import { Button, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { FIND_SERVICE_ORDER_BY_IMEI_OR_SINISTER } from 'services/graphql/query/service-orders';

import { Header } from './components/header';
import { CosmeticQuestions } from './components/questions';
import { useStyles } from './styles';

export function CosmeticScreening() {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const formikRef = useRef();

  const { data, loading, error } = useQuery(FIND_SERVICE_ORDER_BY_IMEI_OR_SINISTER, {
    variables: {
      sinister: search,
      status_id: 3,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (search !== '') {
      if (data) {
        toast.success('Consulta realizada com sucesso!');
      }

      if (error) {
        console.error('Erro ao buscar dados:', error);
        const errorMessage =
          error.graphQLErrors?.[0]?.message ||
          error.networkError?.message ||
          'Erro ao realizar a consulta, tente novamente!';

        toast.error(`Error: ${errorMessage}`);
        handleReset();
      }
    }
  }, [data, error, search]);

  const handleSearch = (values) => {
    setSearch(values.search);
  };

  const handleReset = () => {
    formikRef?.current?.resetForm();
    setSearch('');
  };
  return (
    <>
      <PageTitle>Triagem Cosmética</PageTitle>

      <Formik initialValues={{ search: '' }} onSubmit={handleSearch} innerRef={formikRef}>
        {() => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={4}>
                <Field
                  name='search'
                  label='Pesquisar Voucher'
                  size='small'
                  variant='outlined'
                  fullWidth
                  endAdornment={<QrCodeIcon />}
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={2} lg={2}>
                <Button type='submit' fullWidth startIcon={<SearchIcon />}>
                  Consultar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      {data && (
        <>
          <Header data={data?.findServiceOrderByImeiOrSinister} />
          <Spacer size={64} />
          <CosmeticQuestions
            handleReset={handleReset}
            sinister={data?.findServiceOrderByImeiOrSinister?.sinister}
          />
        </>
      )}
      {!data && (
        <Grid className={classes.containerImg} container>
          <Trash className={classes.trashImage} />
        </Grid>
      )}
    </>
  );
}
