import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Button, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';
import { api } from 'services/api';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [{ label: 'Tipo de Billing' }];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};
export const RegisterBillingExpirationType = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [billingDatas, setBillingData] = useState([]);

  const classes = useStyles();

  const [search, setSearch] = useState('');

  const [billingData, , loadingBillingType] = useQuery(
    () => invoiceService.listBillingTypeOnly(),
    [],
  );

  useEffect(() => {
    setBillingData(billingData);
  }, [billingData]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    console.log(values);
    try {
      const { data } = await api.post('/invoices/billing/type', values);

      setBillingData((prevState) => [...prevState, data]);

      toast.success('Tipo de billing cadastrado com sucesso');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao cadastrar prazo.');
    } finally {
      setSubmitting(false);
    }
  };

  const billingOptionsFiltered = (billingDatas || []).filter((billing) => {
    const loweredSearch = search.toLowerCase();

    return billing.id || billing.tipoBilling.toLowerCase().includes(loweredSearch);
  });

  return (
    <>
      <PageTitle>Tipo de Billing</PageTitle>
      <Formik
        initialValues={{ id_tipoBilling: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <Field
                    name='tipoBilling'
                    label='Tipo de Billing'
                    variant='outlined'
                    size='small'
                    component={Text}
                  />
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={400}
                    loading={loadingBillingType}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {billingOptionsFiltered.map((billing) => (
                      <TableRow key={billing.id}>
                        <TableCell>{billing.tipoBilling}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
