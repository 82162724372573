import React from 'react';
import { useRouteMatch } from 'react-router';

import { GroupOfChanges } from 'pages/Private/GeneralReleases/GroupOfChanges';
import RegisterLib from 'pages/Private/GeneralReleases/LeaderReleases';
import { ReleaseQA } from 'pages/Private/GeneralReleases/ReleaseQA';

import { Route } from '../Route';

export const GeneralReleasesRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/lib-release`} component={RegisterLib} />

      <Route isPrivate path={`${path}/group-of-changes`} component={GroupOfChanges} />
      <Route isPrivate path={`${path}/release-qa`} component={ReleaseQA} />
    </>
  );
};
