import { api } from 'services/api';

export class CashierService {
  async createlabornogspn(data) {
    return api.post('/cashier/labor/nogspn', data);
  }

  async saleAccessories(values) {
    return api.post('/cashier/accessories/sale', values);
  }

  async listSaleAccessories() {
    return api.get('/cashier/accessories/sale');
  }

  async listSoldedAccessoriesNoWholesale() {
    return api.get('cashier/accessories/solded/nowholesale');
  }

  async listSoldedAccessoriesWholesale() {
    return api.get('cashier/accessories/solded/wholesale');
  }

  async listCouponNoFiscal() {
    return api.get('/cashier/coupon/nofiscal');
  }

  async downloadNoFiscalCoupon(id, client_id, accessories) {
    console.log('Values: ', accessories);
    return api.post(
      `/cashier/coupon/nofiscal`,
      { id, client_id, accessories },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async downloadNoFiscalService(id, client_id, labors) {
    console.log('Values: ', labors);
    return api.post(
      `/cashier/service/nofiscal`,
      { id, client_id, labors },
      {
        responseType: 'arraybuffer',
      },
    );
  }

  async listLaborsNoGspn() {
    return api.get('/cashier/labor/nogspn');
  }

  async saleServices(values) {
    return api.post('/cashier/services/sale', values);
  }

  async listSaleServices() {
    return api.get('/cashier/services/sale');
  }

  async orderComponents(cgc, part_number, quantity) {
    return api.post('/cashier/accessories/order', { cgc, part_number, quantity });
  }

  async updateComponentAccessoryPrice(id, accessory_price) {
    return api.put('/cashier/accessories/price', { id, accessory_price });
  }

  async updateLabor(id, product_id, description, labor_price) {
    return api.put('/cashier/labor', { id, product_id, description, labor_price });
  }

  async listAccessoriesSolded(accessories_sale_id) {
    return api.get(`cashier/accessories/solded/${accessories_sale_id}`);
  }
}

export const cashierService = new CashierService();
