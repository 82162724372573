import React from 'react';
import { useRouteMatch } from 'react-router';

import { BudgetFg } from 'pages/Private/Conference/BudgetFg';
import { ServiceAdditional } from 'pages/Private/Conference/BudgetFg/ServiceAdditional';
import { Negotiation } from 'pages/Private/Conference/BudgetFg/ServiceAdditional/Negotiation';
import { CheckinPll } from 'pages/Private/Conference/CheckinPll';
import { OpeningPll } from 'pages/Private/Conference/OpeningPll';
import { RegisterObject } from 'pages/Private/Conference/RegisterObject';

import { Route } from '../Route';

export const ConferenceRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/opening-pll`} component={OpeningPll} />
      <Route isPrivate path={`${url}/budget-fg`} component={BudgetFg} />
      <Route isPrivate path={`${url}/checkin-pll`} component={CheckinPll} />
      <Route isPrivate path={`${url}/register-object`} component={RegisterObject} />
      <Route isPrivate path={`${url}/waiting-negotiation`} component={Negotiation} />
      <Route isPrivate path={`${url}/additional-services`} component={ServiceAdditional} />
    </>
  );
};
