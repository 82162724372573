import React from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Button, Table } from 'components';
import { Formik, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { useContextSelector } from 'use-context-selector';

import { DialogChangeInformation } from './DialogChangeInformation';

const columns = [
  { label: 'Descrição' },
  { label: 'Data de cadastro', align: 'justify' },
  { label: <VisibilityIcon />, align: 'center' },
];

const rows = [
  {
    description_log: 'ASAS9856',
    date: '21/05/2021',
  },
];

export const CreateLog = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);
  const [isDialogChangeInformations, setIsDialogChangeInformations] = useBoolean();

  const handleOpenInformation = () => {
    setIsDialogChangeInformations.toTrue();
  };

  const handleCloseDialog = () => {
    setIsDialogChangeInformations.toFalse();
  };

  return (
    <>
      <PageTitle dev>Log</PageTitle>
      <Formik>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Field
                name='description'
                label='Descrição'
                variant='outlined'
                size='small'
                component={Text}
              />
            </Grid>
            <Grid item xs={12} lg={2}>
              <Button fullWidth>Cadastrar</Button>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: '10px' }} container>
            <Grid item xs={12} lg={6}>
              <Table
                headers={columns}
                maxHeight={400}
                striped
                emptyMessage='Nenhum serviço encontrado.'
                // onActionsClick={userRole === 1 ? handleOpenInformation : ''}
                // actionsLabel={userRole === 1 ? <VisibilityIcon /> : ''}
                disableNumeration
              >
                {rows?.map((row) => (
                  <TableRow key={row}>
                    <TableCell>{row.description_log}</TableCell>
                    <TableCell align='justify'>{row.date}</TableCell>
                    <TableCell align='center'>
                      <IconButton color='primary' onClick={(e) => handleOpenInformation(e, row)}>
                        <AddCircleIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </Table>
            </Grid>
            {userRole === 1 ? (
              <DialogChangeInformation
                open={isDialogChangeInformations}
                handleClose={handleCloseDialog}
              />
            ) : (
              <></>
            )}
          </Grid>
        </>
      </Formik>
    </>
  );
};
