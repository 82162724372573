import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    btnSubmit: {
      display: 'flex',
      justifyContent: 'end',
      marginTop: 20,
    },
  };
});

export default useStyles;
