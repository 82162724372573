import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    gridContainer: {
      marginTop: '5px',
      marginBottom: '5px',
    },
    divContainer: {
      fontSize: '16px',
    },
    container: {
      display: 'flex',
    },
    legend1: {
      background: '#0096c7',
      width: '1rem',
      height: '1rem',
      borderRadius: '2px',
      border: 'none',
      marginBottom: '5px',
    },
    legend2: {
      background: '#7209b7',
      width: '1rem',
      height: '1rem',
      marginBottom: '5px',
      borderRadius: '2px',
      border: 'none',
    },
    legend3: {
      background: '#f7b801',
      width: '1rem',
      marginBottom: '5px',
      height: '1rem',
      borderRadius: '2px',
    },
    title: {
      marginLeft: '3px',
    },
    input: {
      marginBottom: '1px',
    },
    containerLegend: {
      display: 'flex',
      background: '#f2f2f2',
      borderRadius: '40px 40px 40px 40px',
      border: '0.5% solid ',
      height: '40px',
      padding: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    p: {
      marginLeft: '5px',
      fontSize: '1rem',
    },
  };
});
