import React from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { api } from 'services/api';
import { useContextSelector } from 'use-context-selector';

import { RequestComponentContext } from '../../context';

export const ConfirmDialog = ({ open, onClose, selectedOrdering = {}, dialogProps }) => {
  const { id, user, component, quantity } = selectedOrdering;

  const setOrderings = useContextSelector(
    RequestComponentContext,
    (context) => context.setOrderings,
  );

  const handleConfirm = async (orderingId, accepted) => {
    try {
      await api.post(`/components/orderings/handle/${orderingId}`, {
        accepted,
      });

      const updateOrderings = (arr) => arr.filter((a) => a.id !== orderingId);

      setOrderings((prev) => updateOrderings(prev));

      toast.success('Requisição respondida com sucesso!');
    } catch (err) {
      console.error(err);
      toast.error('Erro ao responder requisição do componente.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs'>
      <Dialog.Title onClose={onClose}>{dialogProps.title}</Dialog.Title>
      <Dialog.Content>
        <Typography gutterBottom>
          <strong>Técnico: </strong>
          {user?.name}
        </Typography>
        <Typography gutterBottom>
          <strong>Componente: </strong>
          {component?.description}
        </Typography>
        <Typography gutterBottom>
          <strong>Local: </strong>
          {component?.component_location.description}
        </Typography>
        <Typography gutterBottom>
          <strong>Quantidade: </strong>
          {quantity}
        </Typography>
        <br />
      </Dialog.Content>
      <Dialog.Actions>
        <Button onClick={onClose} variant='text' color='default'>
          Não
        </Button>
        <Button onClick={() => handleConfirm(id, dialogProps.accept)}>Sim</Button>
      </Dialog.Actions>
    </Dialog>
  );
};
