import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { FormatDate } from 'components/FormatDate';

export const ServiceOrder = ({ serviceOrder }) => {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography>
            <strong>Numero: </strong>
            {serviceOrder?.number}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>Numero serial: </strong>
            {serviceOrder?.serial_number}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>imei: </strong>
            {serviceOrder?.imei}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>Modelo: </strong>
            {serviceOrder?.model}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>Defeito dispositivo: </strong>
            {serviceOrder?.device_defect}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>Data de criação: </strong>
            {serviceOrder?.created_at ? (
              <FormatDate format='dd/MM/yyyy HH:mm:ss'>{serviceOrder?.created_at}</FormatDate>
            ) : (
              ''
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            <strong>Tipo de processo(Gaia): </strong>
            {serviceOrder?.description}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
