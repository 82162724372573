import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Table, FormatDate } from 'components';
import { Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';

import wavFile from '../AnalysisStockRequest/sound.mp3';
import { AnalysisStockRequestDialog } from './AnalysisStockRequestDialog';

const columns = [
  { label: 'Service' },
  { label: 'Quantidade Geral' },
  { label: 'Processo' },
  { label: 'Data de cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const AnalysisStockRequest = () => {
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [stockRequest, setStockRequest] = useState({});
  const [stockRequest2, setStockRequest2] = useState({});
  const [stockRequest3, setStockRequest3] = useState({});

  const [stockRequestData, , loadingStockRequestData, refetchStockRequest] = useQuery(
    () => componentService.listStockRequests(),
    [],
  );

  const formattedStockRequest = (stockRequestData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredStockRequest = (formattedStockRequest || []).filter((components) => {
    const lowerServiceNumber = components?.service_order_number?.toLowerCase();
    const upperServiceNumber = components?.service_order_number?.toUpperCase();

    return lowerServiceNumber?.includes(search) || upperServiceNumber?.includes(search);
  });

  console.log('Request: ', stockRequestData);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('Item: ', item);
    setStockRequest(item);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const ttttt = stockRequestData;
      refetchStockRequest();
      setStockRequest3(stockRequestData);
      // alert(ttttt?.length);
      // alert(stockRequest3?.length);
      if (ttttt?.length !== stockRequest3?.length) {
        // alert('som');
        const audio = new Audio(wavFile);
        audio.play();
      }
    }, 40000);
    return () => {
      clearInterval(interval);
    };
  }, [refetchStockRequest, stockRequest3, stockRequest2, stockRequestData]);

  return (
    <>
      <PageTitle>Análise de Requisição de Estoque</PageTitle>
      <Formik>
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={6}>
              <Text
                label='Pesquisar'
                size='small'
                variant='outlined'
                fullWidth
                endAdornment={<SearchIcon />}
                onChange={handleSearchChange}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table
              headers={columns}
              emptyMessage='Nenhum registro encontrado.'
              striped
              disableNumeration
              loading={loadingStockRequestData}
              // actionsLabel={<VisibilityIcon />}
              // onActionsClick={handleOpenInformation}
              data={filteredStockRequest}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            >
              {filteredStockRequest?.map((stock) => (
                <TableRow key={stock.id}>
                  <TableCell>{stock.service_order_number}</TableCell>
                  <TableCell>{stock.service_order_number}</TableCell>
                  <TableCell>
                    {stock.component_level_location_id === 3 ? 'Fora de Garantia' : 'Em Garantia'}
                  </TableCell>
                  <TableCell>
                    <FormatDate format='dd/MM/yyyy HH:mm'>{stock.created_at}</FormatDate>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton color='primary' onClick={(e) => handleOpenInformation(e, stock)}>
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
          <AnalysisStockRequestDialog
            open={isDetailsOpen}
            handleClose={handleCloseInformation}
            stockRequest={stockRequest}
            reloadStockRequest={refetchStockRequest}
          />
        </Form>
      </Formik>
    </>
  );
};
