import React from 'react';
import { useRouteMatch } from 'react-router';

import { AlterConferenceGaia } from 'pages/Private/Receivement/AlterServiceGaia';
import { BlockObject } from 'pages/Private/Receivement/BlockObject';
import { CollectObject } from 'pages/Private/Receivement/CollectObject';
import { ConferenceInvoiceRepair } from 'pages/Private/Receivement/ConferenceInvoiceRepair';
import { ConsultToDoConference } from 'pages/Private/Receivement/ConsultToDoConference';
import { ConsultToDoOpening } from 'pages/Private/Receivement/ConsultToDoOpening';
import { Eticket } from 'pages/Private/Receivement/Eticket';
import { ImportManyInvocieXmlRepair } from 'pages/Private/Receivement/ImportManyInvocieXmlRepair';
import { ImportXmlInvoiceRepair } from 'pages/Private/Receivement/ImportXmlInvoiceRepair';
import { ReprintPackingListConferenceInvoiceRepair } from 'pages/Private/Receivement/ReprintPackingListConferenceInvoiceRepair';
import { ReprintPackingListConferenceInvoiceRepairDevol } from 'pages/Private/Receivement/ReprintPackingListConferenceInvoiceRepair copy';
import { ReprintPackingListConferenceInvoiceRepairGeral } from 'pages/Private/Receivement/ReprintPackingListConferenceInvoiceRepair copy 2';
import { ReprintPackingListConferenceInvoiceRepairAll } from 'pages/Private/Receivement/ReprintPackingListConferenceInvoiceRepairAll';
import { ReprintTagConferenceInvoiceRepair } from 'pages/Private/Receivement/ReprintTagConferenceInvoiceRepair';
import { WaitingWithdrawal } from 'pages/Private/Receivement/WaitingWithdrawal';

import { Route } from '../Route';

export const ReceivementRoutes = () => {
  const { url } = useRouteMatch();
  return (
    <>
      <Route isPrivate path={`${url}/collect-object`} component={CollectObject} />
      <Route isPrivate path={`${url}/eticket-generate`} component={Eticket} />
      <Route
        isPrivate
        path={`${url}/conference-invoice-repair`}
        component={ConferenceInvoiceRepair}
      />
      <Route isPrivate path={`${url}/invoice-repair`} component={ImportXmlInvoiceRepair} />
      <Route isPrivate path={`${url}/conference-todo`} component={ConsultToDoConference} />
      <Route isPrivate path={`${url}/opening-todo`} component={ConsultToDoOpening} />
      <Route isPrivate path={`${url}/import-many-invoice`} component={ImportManyInvocieXmlRepair} />
      <Route
        isPrivate
        path={`${url}/reprint-invoice-repair-tag`}
        component={ReprintTagConferenceInvoiceRepair}
      />
      <Route
        isPrivate
        path={`${url}/reprint-invoice-repair-packing-list`}
        component={ReprintPackingListConferenceInvoiceRepair}
      />

      <Route
        isPrivate
        path={`${url}/reprint-invoice-repair-packing-list-diversos`}
        component={ReprintPackingListConferenceInvoiceRepairGeral}
      />
      <Route
        isPrivate
        path={`${url}/reprint-invoice-repair-packing-list-all`}
        component={ReprintPackingListConferenceInvoiceRepairAll}
      />

      <Route
        isPrivate
        path={`${url}/reprint-invoice-repair-packing-list-devol`}
        component={ReprintPackingListConferenceInvoiceRepairDevol}
      />

      <Route isPrivate path={`${url}/alter-conference`} component={AlterConferenceGaia} />
      <Route isPrivate path={`${url}/awaiting-collect`} component={WaitingWithdrawal} />
      <Route isPrivate path={`${url}/block-object`} component={BlockObject} />
    </>
  );
};
