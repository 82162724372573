import { makeStyles } from '@material-ui/core/styles';

// import wavesImg from 'assets/images/waves.png';

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  container: {
    width: '100%',
    maxWidth: 800,
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },

  title: {
    fontSize: 28,
    margin: `${theme.spacing(2)}px 0`,
    // color: '#895bf5',
  },
}));
