import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

export const PreExpedition = () => {
  return (
    <>
      <PageTitle>Pré-Expedição</PageTitle>
    </>
  );
};
