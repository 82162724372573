import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import cep from 'cep-promise';
import { Text, Button, Select, Dialog, Radio } from 'components';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { api, clientService } from 'services';

import { validationSchema } from '../validation';

const columns = [{ label: 'Nome' }, { label: 'E-mail' }];

export const AlterClientDialog = ({ open, handleClose, client = {}, reloadClients }) => {
  const formikRef = useRef();

  const [address, setAddress] = useState(client?.addresses);
  const [loadingCep, setLoadingCep] = useState(false);

  console.log('Client: ', client);
  console.log('Address: ', address);

  useEffect(() => {
    const getAddress = client?.addresses?.[0];

    setAddress(getAddress);
  }, [client]);

  const onBlurCep = async (zipcode) => {
    setLoadingCep(true);

    const { state, city, neighborhood, street } = await cep(zipcode.target.value);

    formikRef.current.setFieldValue('state', state);
    formikRef.current.setFieldValue('city', city);
    formikRef.current.setFieldValue('district', neighborhood);
    formikRef.current.setFieldValue('street', street);

    setLoadingCep(false);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('Values: ', values);

      await clientService.updateClient(values);

      toast.success('Cliente Atualizado com sucesso.');

      reloadClients();
      resetForm();
      handleClose();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  function compare(a, b) {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  }

  const [states, , loadingStates] = useQuery(
    () => api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados'),
    [],
  );

  const ufsOptions = (states || []).map((state) => {
    return {
      value: state.sigla,
      label: state.sigla,
    };
  });

  ufsOptions.sort(compare);

  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Detalhes do Cliente: {client?.name}</Dialog.Title>
      {console.log('crinte: ', client)}
      <Dialog.Content>
        <Formik
          innerRef={formikRef}
          initialValues={{
            id: client?.id,
            cgc: client?.cgc,
            street: address?.street,
            street_number: address?.street_number,
            zipcode: address?.zipcode,
            name: client?.name,
            city: address?.city,
            state: address?.state,
            email: client?.email,
            district: address?.district,
            complement: address?.complement,
            residential_phone_number: client?.residential_phone_number,
            phone_number: client?.phone_number,
            active: client?.active,
            ie: client?.ie,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={3}>
                    <Field
                      label='CPF/CNPJ do cliente'
                      name='cgc'
                      component={Text}
                      variant='outlined'
                      fullWidth
                      size='small'
                      mask='document'
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      fullWidth
                      label='Inscrição estadual'
                      name='ie'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={9}>
                    <Field
                      variant='outlined'
                      label='Nome do cliente'
                      name='name'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      mask='zipcode'
                      size='small'
                      fullWidth
                      name='zipcode'
                      label='CEP'
                      variant='outlined'
                      component={Text}
                      onBlur={(e) => {
                        onBlurCep(e);
                      }}
                      loading={loadingCep}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      fullWidth
                      label='Cidade'
                      name='city'
                      component={Text}
                      loading={loadingCep}
                    />
                  </Grid>

                  <Grid item xs={6} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      fullWidth
                      label='Estado'
                      name='state'
                      component={Select}
                      loading={loadingCep}
                      options={ufsOptions}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      size='small'
                      variant='outlined'
                      fullWidth
                      label='Bairro'
                      name='district'
                      component={Text}
                      loading={loadingCep}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      size='small'
                      variant='outlined'
                      fullWidth
                      label='Rua'
                      name='street'
                      component={Text}
                      loading={loadingCep}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      variant='outlined'
                      label='Complemento'
                      name='complement'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      variant='outlined'
                      label='Número'
                      name='street_number'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      variant='outlined'
                      label='Telefone Fixo'
                      name='residential_phone_number'
                      size='small'
                      component={Text}
                      mask='phone'
                    />
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <Field
                      variant='outlined'
                      label='Celular'
                      name='phone_number'
                      size='small'
                      component={Text}
                      mask='phone'
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <Field
                      variant='outlined'
                      label='Email'
                      name='email'
                      type='email'
                      size='small'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Typography variant='h6' style={{ fontSize: '16px' }}>
                      Ativar / Desativar Cliente
                    </Typography>
                  </Grid>

                  <Grid item xs={6} lg={2}>
                    <Field
                      color='primary'
                      name='active'
                      value={true}
                      fullWidth
                      component={Radio}
                      label='Ativo'
                    />
                  </Grid>
                  <Grid item xs={6} lg={8}>
                    <Field
                      color='primary'
                      name='active'
                      value={false}
                      fullWidth
                      component={Radio}
                      label='Inativo'
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      color='primary'
                      variant='contained'
                      disabled={!props.isValid || !props.dirty}
                      type='submit'
                      fullWidth
                    >
                      Cadastrar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
