import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  service_number: yup.string().required(),
  serviceCode: yup.string().required(),
});

export const initialValues = {
  service_number: '',
  serviceCode: '',
  card: '',
};
