import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, FormatDate, Spacer, Table, Text } from 'components';
import { Formik, Form, Field } from 'formik';
import { api, serviceOrderService } from 'services';
import * as yup from 'yup';

const columns = [{ label: 'Anotação' }, { label: 'Adicionado por' }, { label: 'Adicionado em' }];

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export default function Remark({ serviceOrder }) {
  const [remarkData, setRemarkData] = useState();

  console.log('servicews.', serviceOrder);

  useEffect(() => {
    const functionGetRemarks = async () => {
      const { data } = await api.get(`/service-orders/serviceRemark/${serviceOrder.id}`);
      setRemarkData(data);
    };

    functionGetRemarks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarkData]);

  const onSubmitHandler = async (description, services) => {
    await serviceOrderService
      .createServiceRemark(description, serviceOrder.id)
      .then((item) => {
        console.log('Item: ', item);
        toast.success('Registro realizado com sucesso.');
      })
      .catch((err) => {
        console.log(err);
        toast.error(err?.response?.data?.message);
      });
  };

  const existsInfo = useMemo(() => {
    if (!serviceOrder.length && serviceOrder.length === 0) {
      return <></>;
    } else {
      return (
        <Formik
          initialValues={{
            description: '',
          }}
          // validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Table
                      headers={columns}
                      striped
                      actionsLabel={<VisibilityIcon />}
                      emptyMessage='Nenhum registro encontrado.'
                      data={remarkData}
                      disableNumeration
                    >
                      {remarkData?.map((serviceRemark) => (
                        <TableRow key={serviceRemark?.id}>
                          <TableCell>{serviceRemark?.description}</TableCell>
                          <TableCell>{serviceRemark?.user?.name}</TableCell>
                          <TableCell>
                            <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                              {serviceRemark?.created_at}
                            </FormatDate>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={12} lg={12}>
                    <Field
                      size='small'
                      fullWidth
                      name='description'
                      label='Realize uma anotação/descrição para o orçamento'
                      variant='outlined'
                      component={Text}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
                <Spacer size={12} />
                <Grid container spacing={2}>
                  <Grid item xs={12} align='right'>
                    <Button fullwidth onClick={() => onSubmitHandler(props.values.description)}>
                      REGISTRAR
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarkData]);

  return existsInfo;
}
