import React from 'react';

import { Tabs } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';

import { OsBudget } from './OsBudget';
import { OsConference } from './OsBudget copy';
import { OsData } from './OsData';
import { OsLog } from './OsLog';
import { OsLogConference } from './OsLogConference';
import { OsParts } from './OsParts';

export const OsTabs = () => {
  return (
    <Tabs gutterBottom>
      <Tabs.Content label='Dados OS' disablePadding>
        <OsData />
      </Tabs.Content>
      <Tabs.Content label='Orçamento' disablePadding>
        <PageTitle />
        <OsBudget />
      </Tabs.Content>
      <Tabs.Content label='Peças Lançadas' disablePadding>
        <PageTitle />
        <OsParts />
      </Tabs.Content>

      <Tabs.Content label='Conferência' disablePadding>
        <PageTitle />
        <OsConference />
      </Tabs.Content>
      <Tabs.Content label='Log OS' disablePadding>
        <OsLog />
      </Tabs.Content>
      <Tabs.Content label='Log Conferência' disablePadding>
        <OsLogConference />
      </Tabs.Content>
      {/* <Tabs.Content label='Billings' disablePadding>
        <OsBillings />
      </Tabs.Content> */}
    </Tabs>
  );
};
