import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ThemeProvider from '@material-ui/styles/ThemeProvider';

import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'hooks/useAuth';
import Routes from 'routes';
import { browserHistory } from 'routes/browserHistory';
import { graphql_api } from 'services/graphql';
import { APP_VERSION } from 'settings';
import { theme } from 'theme';

export function App() {
  console.log('Não há nada para ver aqui.');

  // console.log = console.warn = console.warn = () => {};

  let version = localStorage.getItem('version');
  if (version != APP_VERSION) {
    if ('caches' in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
      console.log('cache cleared');
      // Makes sure the page reloads. Changes are only visible after you refresh.
      window.location.reload(true);
    }

    localStorage.clear();
    localStorage.setItem('version', APP_VERSION);
  }

  return (
    <ApolloProvider client={graphql_api}>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
