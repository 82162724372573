import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { green } from '@mui/material/colors';
import { Text, Button, Table } from 'components';
import { FormatDate } from 'components/FormatDate';
import { Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';
import XLSX from 'xlsx';

import { ConfirmDialog } from './ConfirmDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Nº da Nota' },
  { label: 'Service' },
  { label: 'Tipo de nota' },
  { label: 'Data de criação da nota' },
  { label: 'Nota' },
  { label: 'Baixar' },
];

export const ConsultNote = () => {
  const classes = useStyles();

  const [invoices, setInvoices] = useState([]);

  const [dialogProps, setDialogProps] = useState({});
  const [selectedOrdering, setSelectedOrdering] = useState({});
  const [confirmDialog, setConfirmDialog] = useBoolean();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [search, setSearch] = useState('');

  const [includedInvoicesData, , loadingIncludedInvoicesData] = useQuery(
    () => invoiceService.listIncludedInvoices(),
    [],
  );

  const formattedInvoices = (includedInvoicesData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredInvoices = formattedInvoices.filter((invoices) => {
    const upperInvoice = String(invoices.id)?.toUpperCase();
    const lowerInvoice = String(invoices.id)?.toLowerCase();

    const upperService = invoices?.number?.toUpperCase();
    const lowerService = invoices?.number?.toLowerCase();

    const upperType = invoices?.description?.toUpperCase();
    const lowerType = invoices?.description?.toLowerCase();

    const localeDate = new Date(invoices?.created_at).toLocaleString('pt-br');

    return (
      upperType?.includes(search) ||
      lowerType?.includes(search) ||
      localeDate?.includes(search) ||
      upperService?.includes(search) ||
      lowerService?.includes(search) ||
      upperInvoice?.includes(Number(search)) ||
      lowerInvoice?.includes(Number(search))
    );
  });

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  useEffect(() => {
    setInvoices(includedInvoicesData);
  }, [includedInvoicesData, invoices]);

  const handleDownloadIncludedInvoices = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(includedInvoicesData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioDeCuponsNaoFiscais${now}.xlsx`);
  };

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
  };

  const downloadInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog}
        onClose={setConfirmDialog.toFalse}
        dialogProps={dialogProps}
        selectedOrdering={selectedOrdering}
      />
      <PageTitle>Consultar notas</PageTitle>

      <Formik initialValues={{ number_os: '' }} validationSchema={validationSchema}>
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    onChange={handleSearchChange}
                    size='small'
                    endAdornment={<SearchIcon />}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button fullWidth onClick={handleDownloadIncludedInvoices} color='primary'>
                    exportar relatório
                  </Button>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    disableNumeration
                    loading={loadingIncludedInvoicesData}
                    data={filteredInvoices}
                    // maxHeight={600}
                    emptyMessage='Nenhum registro encontrado.'
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  >
                    {filteredInvoices?.map((row) => (
                      <TableRow key={row?.id}>
                        <TableCell>{row?.nota}</TableCell>
                        <TableCell>{row?.number ?? 'SEM NÚMERO DE SERVIÇO'}</TableCell>
                        <TableCell>{row?.chave}</TableCell>
                        <TableCell>{row?.description}</TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>{row?.data_criacao}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => downloadInNewTab(row.link_invoice)}
                          >
                            <FileDownloadIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>

                <Grid item xs={6} lg={3}>
                  <Typography className={classes.legend} gutterBottom>
                    Legenda
                  </Typography>
                  <div className={classes.containerLegend}>
                    <ArrowDownwardIcon fontSize='small' sx={{ color: green[400] }} />
                    <p variant='caption' className={classes.p}>
                      Baixar notas
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
