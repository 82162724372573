import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: theme.spacing(2),
  },

  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  infoAccessoryGrid: {
    width: '100%',
    border: '1px solid #512da8',
    borderRadius: '10px',
    padding: '8px',
    '&:nth-child(1n+1)': {
      marginTop: '12px',
    },
  },

  cartCount: {
    left: 'calc(100% - 50% - -7px)',
    color: 'whitesmoke',
    padding: '0 0.5rem',
    position: 'absolute',
    fontSize: '0.9rem',
    background: 'red',
    borderRadius: '50%',
    top: '-26%',
  },

  cartEmpty: {
    display: 'none',
  },

  infoAccessory: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    marginTop: '12px',
  },

  subTotal: {
    color: 'green',
    display: 'flex',
  },

  quantity: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  paperBord: {
    height: 'auto',
    padding: '10px',
    overflow: 'scroll',
  },

  backButton: {
    marginRight: theme.spacing(1),
  },

  buttonOpenCart: {
    position: 'relative',
  },

  buttonAddToCart: {
    fontSize: '12px',
    lineHeight: 1.2,
    padding: '8px 16px',
  },

  accessoriesInCart: {
    height: '100%',
    padding: '10px',
    overflow: 'scroll',
  },
}));
