import React from 'react';
import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';
import { Button, Dialog, FileUploader, Spacer } from 'components';
import { Field, Form, Formik } from 'formik';
import { INCLUDE_XML } from 'services/graphql/query/b2c';
import { GENERATE_INVOICE_TAG } from 'services/graphql/query/pdf';

export function ImportXmlDialog({ isOpen, handleClose, item, refetch }) {
  if (!isOpen) {
    return <></>;
  }

  const [generatePdf] = useMutation(GENERATE_INVOICE_TAG, {
    onCompleted: (data) => {
      const pdfBase64 = data?.invoiceTag || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'danfe.pdf';
      link.click();
      toast.success('Danfe gerada com sucesso');
    },
    onError: (error) => {
      toast.error('Erro ao gerar a etiqueta!');
      console.error('Erro ao gerar a etiqueta:', error);
    },
  });

  const [uploadXml] = useMutation(INCLUDE_XML, {
    onCompleted: async () => {
      toast.success('Dados salvos com sucesso');
      await generatePdf({ variables: { anymarketId: item.anymarketId } });
      await refetch();
      await handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  async function handleImportXML(values, { setSubmitting, resetForm }) {
    const file = values.xml;

    if (!file) {
      toast.error('Nenhum arquivo selecionado');
      return;
    }

    if (file.type !== 'text/xml') {
      toast.error('Adicione um arquivo do tipo XML');
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      const xmlString = e.target.result;

      try {
        const response = await uploadXml({
          variables: {
            xmlData: xmlString,
            anymarketId: item.anymarketId,
          },
        });

        console.log('Response from server:', response);
      } catch (error) {
        console.error('Error uploading XML:', error);
      }
    };

    // Read the file as text (XML)
    reader.readAsText(file);
  }

  return (
    <Dialog maxWidth='sm' onClose={handleClose} open={isOpen}>
      <Dialog.Title onClose={handleClose}>Anexar XML</Dialog.Title>

      <Dialog.Content>
        <Formik initialValues={{ xml: '' }} onSubmit={handleImportXML}>
          <Form>
            <Field
              name='xml'
              // value={values.voucher}
              label='XML'
              onRemoveFile={console.log('TESTE REMOVE')}
              component={FileUploader}
              handleClean={'Aqui veio'}
            />

            <Spacer size={32} />
            <Button fullWidth type='submit'>
              Salvar XML
            </Button>
          </Form>
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
}
