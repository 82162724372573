import { cpf, cnpj } from 'cpf-cnpj-validator';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  cgc: yup
    .string()
    .required()
    .test('validateCPFCNPJ', 'CPF ou CNPJ inválido.', (text) => {
      if (text?.length < 14) {
        return cpf.isValid(text);
      }

      return cnpj.isValid(text);
    }),
  buy_place: yup.string().required(),
  buy_value: yup.number().required().min(0.01),
  color_pll_id: yup.number().required(),
  imei: yup.string().required(),
  mail_object_id: yup.string().required(),
  attendance_type_pll_id: yup.number().required(),
  model_pll_id: yup.number().required(),
  service_type_pll_id: yup.number().required(),
  color_pll_id: yup.number().required(),
  serial: yup.string().required(),
  complaint: yup.string().nullable(),
  observation: yup.string().nullable(),
  client_id: yup.string().required(),
  sinister: yup.string().required(),
});

export const initialValues = {
  cgc: '',
  buy_place: '',
  buy_value: 0,
  color_pll_id: '',
  imei: '',
  mail_object_id: '',
  attendance_type_pll_id: '',
  color_pll_id: '',
  model_pll_id: '',
  service_type_pll_id: '',
  serial: '',
  complaint: '',
  observation: '',
  client_id: '',
  sinister: '',
};
