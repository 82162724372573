import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Spacer, Table, Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services/component';
import XLSX from 'xlsx';

var json2csv = require('json2csv');
const columns = [
  { label: 'Billing' },
  { label: 'NF' },
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Quantidade', align: 'center' },
];

export const ConsultPnByBilling = () => {
  const [search, setSearch] = useState('');

  const [components, , loadingComponents] = useQuery(
    () => componentService.listComponentGroupedByBilling(),
    [],
  );

  const handleDownloadConsultPnByBilling = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(components));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioPnPorBililng${now}.xlsx`);
  };

  const filteredComponents = (components || []).filter((component) => {
    const loweredSearch = search.toLowerCase();

    return (
      component.description.toLowerCase().includes(loweredSearch) ||
      component.part_number.toLowerCase().includes(loweredSearch) ||
      component.billing_id.toLowerCase().includes(loweredSearch)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <PageTitle>Consultar componente por Billing</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={handleDownloadConsultPnByBilling}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            headers={columns}
            loading={loadingComponents}
            emptyMessage='Nenhum registro encontrado.'
            striped
          >
            {filteredComponents?.map((component) => (
              <TableRow key={component.part_number}>
                <TableCell>{component.billing_id}</TableCell>
                <TableCell>{component.invoice_number}</TableCell>
                <TableCell>{component.part_number}</TableCell>
                <TableCell>{component.description}</TableCell>
                <TableCell align='center'>{component.quantity}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
