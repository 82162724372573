import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import CheckIcon from '@mui/icons-material/Check';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button, Dialog } from 'components';

export const DialogInformationForImport = ({
  open,
  handleClose,
  importFile,
  examples,
  downloadLayout,
}) => {
  if (!open) return null;

  const [disable, setDisable] = useState(true);
  const [timer, setTimer] = useState(3);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        if (lastTimerCount <= 1) {
          clearInterval(interval);
          setDisable(false);
        }
        return lastTimerCount - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title att onClose={handleClose}></Dialog.Title>
      <Dialog.Content>
        <Typography style={{ fontWeight: 'bold' }}>Siga as seguintes instruções:</Typography>
        <List>
          {examples.map((item) => (
            <ListItem key={item.value}>
              <ListItemIcon>
                <CheckIcon sx={{ color: 'green' }} />
              </ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItem>
          ))}
        </List>
      </Dialog.Content>

      <Dialog.Content>
        <Grid container spacing={1}>
          <Grid item sm={12} lg={6}>
            <Button fullWidth disabled={disable} onClick={() => [importFile(), handleClose()]}>
              Confirmar {timer <= 0 ? '' : timer}
            </Button>{' '}
          </Grid>
          {downloadLayout && (
            <Grid item sm={12} lg={6}>
              <Button fullWidth onClick={() => downloadLayout()} color='secondary'>
                Baixar layout
              </Button>
            </Grid>
          )}
        </Grid>
      </Dialog.Content>
    </Dialog>
  );
};
