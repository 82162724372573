import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { mailServices } from 'services/mail';
import XLSX from 'xlsx';

export const ObjectBlockedReport = () => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadNonComplianceReport = async () => {
    setIsLoading(true);
    toast.info('Gerando o relatório de objetos bloqueados...');
    try {
      const { data } = await mailServices.listMailObjectBlockedReport();

      const now = new Date();
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          mySheet: worksheet,
        },
        SheetNames: ['mySheet'],
      });
      XLSX.writeFile(workbook, `RelatorioObjetosBloqueados${now}.xlsx`);

      toast.success('Relatório gerado com sucesso.');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Erro ao gerar o relatório de custo');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <PageTitle>Relatório de custos</PageTitle>
      <Spacer size={15} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button loading={isLoading} fullWidth onClick={() => handleDownloadNonComplianceReport()}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
