import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Table, Select, CustomAutocomplete, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services';
import * as Yup from 'yup';

import { UpdateBalanceDialog } from './UpdateBalanceDialog';

export const validationSchema = Yup.object().shape({
  component_level_location_id: Yup.number().required('Campo obrigatório'),
  location: Yup.object().required('Campo obrigatório'),
});

const columns = [
  { label: 'Part-Number' },

  { label: 'Quantidade' },

  { label: <VisibilityIcon />, align: 'center' },
];

export const UpdateBalance = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [component, setComponent] = useState({});

  const [componentLevelLocationId, setComponentLevelLocationId] = useState(undefined);
  const [levelLocationId, setLocationId] = useState(undefined);

  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [levelLocations, , loadingLevelLocations] = useQuery(
    () => componentService.listComponentLevelLocations(),
    [],
  );

  const levelLocationsOptions = (levelLocations || []).map((location) => ({
    value: location.id,
    label: location.description,
  }));

  const [locations, , loadingLocations] = useQuery(
    () => componentService.listByComponentLevelLocationId(componentLevelLocationId),
    [componentLevelLocationId],
  );

  const locationsOptions = (locations || []).map((location) => ({
    value: location.id,
    label: location.location_name,
  }));

  const [components, , loadingComponents, reloadComponents] = useQuery(
    () =>
      componentService.listComponentsByLevelLocationIdAndLocationId(
        componentLevelLocationId,
        levelLocationId,
      ),
    [componentLevelLocationId, levelLocationId],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setComponent(item);
  };

  const filteredComponentes = (components || []).filter((pns) => {
    const lowerDescription = pns.description?.toLowerCase();
    const upperDescription = pns.description?.toUpperCase();
    const lowerPn = pns?.part_number?.toLowerCase();
    const upperPn = pns?.part_number?.toUpperCase();

    return (
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerPn?.includes(search) ||
      upperPn?.includes(search)
    );
  });

  return (
    <>
      <PageTitle>Atualizar saldo de estoque</PageTitle>
      <Formik
        initialValues={{ component_level_location_id: undefined, location: undefined }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    size='small'
                    fullWidth
                    name='component_level_location_id'
                    variant='outlined'
                    label='Tipo de estoque'
                    emptyOption='Selecione'
                    loading={loadingLevelLocations}
                    options={levelLocationsOptions}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <CustomAutocomplete
                    name='location'
                    label='Local de estoque'
                    filteredValues={locationsOptions}
                  />
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    fullWidth
                    endAdornment={<SearchIcon />}
                    onChange={handleSearchChange}
                  />
                </Grid>
                {useEffect(() => {
                  setComponentLevelLocationId(props.values?.component_level_location_id);
                  setLocationId(props.values.location?.value);
                }, [props.values])}
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    emptyMessage='Nenhum registro encontrado.'
                    striped
                    maxHeight
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {filteredComponentes?.map((component) => (
                      <TableRow key={component?.id}>
                        <TableCell>{component?.part_number}</TableCell>
                        {/* <TableCell>{component?.description ?? '---'}</TableCell> */}
                        <TableCell>{component?.quantity}</TableCell>
                        {/* <TableCell>{component?.infnfe ?? '---'}</TableCell> */}
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, component)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <UpdateBalanceDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  component={component}
                  reloadComponents={reloadComponents}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
