import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/testeImageUpload.svg';
import { Button, Select } from 'components';
import { Formik, Form, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { api } from 'services/api';
import { pllService } from 'services/pll';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const data = XLSX.utils.json_to_sheet(
  [
    { S: 1, h: 2, e: 3, e_1: 4, t: 5, J: 6, S_1: 7 },
    { S: 2, h: 3, e: 4, e_1: 5, t: 6, J: 7, S_1: 8 },
  ],
  { skipHeader: 1 },
);

export const ImportServices = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const [importServices, setImportServices] = useState();
  const [isWarningDialog, setIsWarningDialog] = useState(false);

  const formikRef = useRef();

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  useEffect(() => {
    const noProductsTypeFound =
      !additionalServicesProductsData?.length && !loadingAdditionalServiceProducts;

    if (noProductsTypeFound) {
      setIsWarningDialog(true);
    }
  }, [additionalServicesProductsData, loadingAdditionalServiceProducts]);

  const [servicesOrders, setServicesOrders] = useState([]);

  const [serviceOrdersData, , loadingServiceOrdersData, refetchServiceOrdersData] = useQuery(
    serviceOrderService.listServiceOrderAccessories(),
    [],
  );
  const [serviceOrderProcesses, , loadingServiceOrderProcess, refetchServiceOrderProcess] =
    useQuery(serviceOrderService.listProcesses, []);

  const bounceList = [
    {
      value: true,
      label: 'Sim',
    },
    {
      value: false,
      label: 'Não',
    },
  ];

  const serviceOrderProcessesOptions = (serviceOrderProcesses || []).map((serviceOrderProcess) => ({
    value: serviceOrderProcess.id,
    label: serviceOrderProcess.id + '-' + serviceOrderProcess.description,
  }));

  const [subInsuranceCompanies, , loadingSubInsuranceCompanies] = useQuery(
    pllService.listSubInsuranceCompanies,
    [],
  );

  const additionalServiceProductOptions = (additionalServicesProductsData || []).map(
    (additionalServiceProduct) => ({
      value: additionalServiceProduct.id,
      label: additionalServiceProduct.id + '-' + additionalServiceProduct.description,
    }),
  );

  const subInsuranceCompaniesOptions = (subInsuranceCompanies || []).map((subInsuranceCompany) => ({
    value: subInsuranceCompany.id,
    label: subInsuranceCompany.id + '' + '-' + subInsuranceCompany.name,
  }));

  const [importing, setImporting] = useState(false);

  useEffect(() => {
    setServicesOrders(serviceOrdersData);
  }, [serviceOrdersData]);

  const classes = useStyles();

  const handleDownloadTagsClick = async (services) => {
    const { data } = await serviceOrderService.downloadOpeningTags({ services });
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `services.pdf`);
  };

  const handleDownloadLayoutServiceOrders = async () => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'Objeto IN',
          b: 'Service Order',
          c: 'Modelo/Código',
          d: 'Part Number',
          e: 'Serial',
          f: 'IMEI1',
          g: 'IMEI2',
          h: 'Complaint Description',
          i: 'Garantia',
          j: 'Abertura OS',
          k: 'Nome Cliente',
          l: 'E-mail',
          m: 'Cidade',
          n: 'CEP',
          o: 'Estado',
          p: 'Activation Date',
          q: 'CPF/CNPJ',
          r: 'Bounce',
          s: 'Código EAN (não obrigatório)',
          t: 'Chave NF (não obrigatório)',
          u: 'Info Adicional (não obrigatório)',
          v: 'Bairro',
          w: 'Rua',
          x: 'Número',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'LayoutImportServices.xlsx');
  };

  const [resales, , loadingResales] = useQuery(pllService.listResales, []);

  const resalesOptions = (resales || []).map((resale) => ({
    value: resale.id,
    label: resale.id + '-' + resale.name,
  }));

  const inputFileRef = useRef(null);

  const toISOFormat = (dateTimeString) => {
    // Primeiro, dividimos a data completa em duas partes:
    const [date, time] = dateTimeString?.split(' ');

    if (!time) {
      toast.error('A coluna de data de abertura precisa estar no formato dia/mês/ano 00:00');
    }

    // Dividimos a data em dia, mês e ano:
    const [DD, MM, YYYY] = date.split('/');

    // Dividimos o tempo em hora e minutos:
    const [HH, mm] = time.split(':');

    // Retornamos a data formatada em um padrão compatível com ISO:
    return `${YYYY}-${MM}-${DD}T${HH}:${mm}`;
  };

  const handleFileChange = async (e) => {
    setImporting(true);
    try {
      const file = e.target.files[0];
      const reader = new FileReader({ type: 'application/pdf;charset=latin1' });

      reader.readAsText(file, 'iso-8859-1');

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      reader.onload = async (event) => {
        const result = [event.target.result];

        console.log('onload', result);
        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getService = arr.map(function (obj) {
          if (
            obj.split(';')[0] === null ||
            obj.split(';')[0] === undefined ||
            obj.split(';')[0] === '' ||
            obj.split(';')[0] === 'Objeto IN' ||
            obj.split(';')[1] === null ||
            obj.split(';')[1] === undefined ||
            obj.split(';')[1] === '' ||
            obj.split(';')[2] === null ||
            obj.split(';')[2] === undefined ||
            obj.split(';')[2] === '' ||
            obj.split(';')[3] === null ||
            obj.split(';')[3] === undefined ||
            obj.split(';')[3] === '' ||
            obj.split(';')[4] === null ||
            obj.split(';')[4] === undefined ||
            obj.split(';')[4] === '' ||
            obj.split(';')[5] === null ||
            obj.split(';')[5] === undefined ||
            obj.split(';')[5] === '' ||
            obj.split(';')[6] === null ||
            obj.split(';')[6] === undefined ||
            obj.split(';')[6] === '' ||
            obj.split(';')[7] === null ||
            obj.split(';')[7] === undefined ||
            obj.split(';')[7] === '' ||
            obj.split(';')[8] === null ||
            obj.split(';')[8] === undefined ||
            obj.split(';')[8] === '' ||
            obj.split(';')[9] === null ||
            obj.split(';')[9] === undefined ||
            obj.split(';')[9] === '' ||
            obj.split(';')[10] === null ||
            obj.split(';')[10] === undefined ||
            obj.split(';')[10] === '' ||
            obj.split(';')[11] === null ||
            obj.split(';')[11] === undefined ||
            obj.split(';')[11] === '' ||
            obj.split(';')[12] === null ||
            obj.split(';')[12] === undefined ||
            obj.split(';')[12] === '' ||
            obj.split(';')[13] === null ||
            obj.split(';')[13] === undefined ||
            obj.split(';')[13] === '' ||
            obj.split(';')[14] === null ||
            obj.split(';')[14] === undefined ||
            obj.split(';')[14] === '' ||
            obj.split(';')[15] === null ||
            obj.split(';')[15] === undefined ||
            obj.split(';')[15] === '' ||
            obj.split(';')[16] === null ||
            obj.split(';')[16] === undefined ||
            obj.split(';')[16] === '' ||
            obj.split(';')[17] === null ||
            obj.split(';')[17] === undefined ||
            obj.split(';')[17] === '' ||
            obj.split(';')[18] === null ||
            obj.split(';')[18] === undefined ||
            obj.split(';')[18] === ''
          ) {
            return false;
          } else {
            const data = {
              object_in: obj.split(';')[0],
              number: obj.split(';')[1],
              model: obj.split(';')[2],
              service_part_number: obj.split(';')[3],
              serial_number: obj.split(';')[4],
              imei: obj.split(';')[5],
              imei2: obj.split(';')[6],
              device_defect: obj.split(';')[7],
              warranty_type: obj.split(';')[8],
              create_date: obj.split(';')[9],
              name: obj.split(';')[10],
              phone_number: obj.split(';')[11],
              email: obj.split(';')[12],
              district: obj.split(';')[13],
              street: obj.split(';')[14],
              street_number: obj.split(';')[15],
              city: obj.split(';')[16],
              zipcode: obj.split(';')[17],
              state: obj.split(';')[18],
              purchase_date: obj.split(';')[19],
              cgc: obj.split(';')[20],
              insurance_wty: obj.split(';')[21],
              bounce: obj.split(';')[22],
              ean: obj.split(';')[23],
              nf_key: obj.split(';')[24],
              color: obj.split(';')[25],
            };

            return data;
          }
        });

        const filteredServices = getService.filter((services) => {
          if (services.number == undefined || services.number == null || services.number == '') {
            return false;
          }
          return services;
        });

        let arrayFile = [];

        const serviceFilter = Object.assign(filteredServices).map(function (key) {
          console.log('Key: ', key);

          // console.log('Data Padrão: ', new Date());

          // console.log('Data Planilha: ', key.create_date);

          // console.log('Data Planilha Att: ', new Date(key.create_date));

          // console.log('Data To Iso: ', toISOFormat(key.create_date));

          // console.log('Data New Correta: ', new Date(toISOFormat(key.create_date)));

          const createDate = new Date(toISOFormat(key.create_date));

          // console.log('formikRef?.current?.values: ', formikRef?.current?.values);

          // console.log('Purchase Date: ', key.purchase_date);

          const [day, month, year] = key.purchase_date?.split('/');

          const date = new Date(+year, month - 1, +day);

          // console.log('date Purchase: ', date);

          // console.log('Create DATE: ', createDate);

          const service = {
            object_in: key.object_in,
            number: key.number,
            service_part_number: key.service_part_number,
            bounce: key.bounce === 'No' ? false : true,
            nf_key: key.nf_key?.replace('Nfe', ''),
            color: key.color,
            district: key.district,
            street: key.street,
            street_number: key.street_number,
            ean: key.ean,
            model: key.model,
            mail_object_id: undefined,
            serial_number: key.serial_number,
            purchase_date: date,
            fabrication_date: date,
            device_defect: key.device_defect,
            device_accessory: key.device_accessory,
            create_date: createDate,
            status_description: key.status_description,
            warranty_type: key.warranty_type,
            imei: key.imei,
            imei2: key.imei2,
            special_comment: key.special_comment,
            name: key.name,
            email: key.email,
            phone_number: key.phone_number,
            city: key.city,
            zipcode: key.zipcode,
            state: key.state,
            cgc: key.cgc?.replace('cgc_', ''),
            insurance_wty: key.insurance_wty,
            product_id: formikRef?.current?.values?.product_id,
            process_type_id: formikRef?.current?.values?.process_type_id,
            sub_bpo_id: formikRef?.current?.values?.sub_bpo_id,
            resale_id: formikRef?.current?.values?.resale_id,
            has_nf: formikRef?.current?.values?.has_nf,
          };

          return arrayFile.push(service);
        });

        await Promise.all(
          serviceFilter.filter((services) => {
            return services;
          }),
        ).then((response) => {});

        const createServiceOrder = await serviceOrderService
          .createServiceFile({
            serviceOrder: arrayFile,
          })
          .then((response) => {
            console.log('Response: ', response);
            if (response.data.length !== 0) {
              handleDownloadTagsClick(response.data);
              toast.success(`${response.data.length} services importadas com sucesso`);

              response?.data?.map(async (item, index) => {
                if (item.process_type_id === 1) {
                  setTimeout(async () => {
                    console.log('Entrou no TimeOut');
                    await serviceOrderService.invoiceEmissionEntrance(item?.number);
                    toast.success(
                      `Nota de entrada da ordem de serviço ${item?.number} gerada com sucesso.`,
                    );
                  }, 10000 * index);
                }
              });
            } else {
              toast.error('Ocorreu um erro ao realizar a importação das services');
            }
          })
          .catch((err) => {
            console.log('err: ', err);
            toast.error(err?.response?.data?.message || 'Erro ao criar OS');
          });

        console.log('Funkão: ', createServiceOrder);
      };

      const serviceOrderProcessesOptions = (serviceOrderProcesses || []).map(
        (serviceOrderProcess) => ({
          value: serviceOrderProcess.id,
          label: serviceOrderProcess.id + '-' + serviceOrderProcess.description,
        }),
      );

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
    } finally {
      setImporting(false);
    }
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Importar services</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{
          number: '',
          product: '',
          process_type_id: null,
          bpo: '',
          resale: '',
          has_nf: null,
          user_id: user?.id,
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Paper className={classes.gridPaper} variant='outlined'>
                    <Grid className={classes.contextPaper} container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          onClick={handleImportClick}
                          type=''
                          disabled={importing || !props.isValid || !props.dirty}
                          startIcon={<FileUploadIcon />}
                        >
                          Importar planilha
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          variant='outlined'
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadLayoutServiceOrders}
                          disabled={importing}
                        >
                          Baixar planilha
                        </Button>
                      </Grid>

                      {importing ? (
                        <Grid xs={6} lg={12} className={classes.loading}>
                          <Box sx={{ width: '90%' }}>
                            <LinearProgress color='secondary' />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                          <UploadImg className={classes.imgTracker} />
                        </Grid>
                      )}
                      <Grid item xs={12} lg={2}>
                        <Field
                          name='process_type_id'
                          label='Processo'
                          variant='outlined'
                          size='small'
                          component={Select}
                          loading={loadingServiceOrderProcess}
                          options={serviceOrderProcessesOptions}
                          helperText='É necessário selecionar uma opção para processo'
                        />
                      </Grid>

                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          name='product_id'
                          label='Tipo de Produto'
                          variant='outlined'
                          options={additionalServiceProductOptions}
                          loading={loadingServiceOrderProcess}
                          component={Select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          fullWidth
                          name='sub_bpo_id'
                          variant='outlined'
                          label='Sub s'
                          loading={loadingSubInsuranceCompanies}
                          options={subInsuranceCompaniesOptions}
                          component={Select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          fullWidth
                          name='resale_id'
                          variant='outlined'
                          label='Revendas'
                          loading={loadingResales}
                          options={resalesOptions}
                          component={Select}
                        />
                      </Grid>
                      <Grid item xs={12} lg={2}>
                        <Field
                          size='small'
                          fullWidth
                          name='has_nf'
                          variant='outlined'
                          label='Possui Chave NF'
                          options={bounceList}
                          component={Select}
                          helperText='É necessário selecionar uma opção para Chave de NF'
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <WarningIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Máximo de importação por vez - 1.000
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Esperar carregamento completo da importação.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
