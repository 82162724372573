import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, FormatDate, Table, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { serviceOrderService } from 'services';
import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  data: yup.string().required(),
});

const columns = [
  { label: 'Ordem de serviço' },
  { label: 'Status' },
  { label: 'Modelo' },
  { label: 'IMEI' },
  { label: 'Cliente' },
  { label: 'CPF/CNPJ' },
  { label: 'Data de abertura' },
  { label: 'Ação' },
];

export const AdvancedConsult = () => {
  const [service, setService] = useState();
  const [search, setSearch] = useState('');
  const [byInvoice, setByInvoice] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigateToConsultService = (serviceOrder, row) => {
    browserHistory.push({
      pathname: '/service-order/consult',
      state: { service: serviceOrder },
    });
  };

  const isByInvoice = () => {
    if (byInvoice) {
      setByInvoice(false);
    } else {
      setByInvoice(true);
    }
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      let isNote = '';
      {
        byInvoice ? (isNote = '0') : (isNote = '1');
      }

      if (isNote == '1') {
        const { data } = await serviceOrderService.listServiceAdvancedConsult(values.data, isNote);

        setService(data);
      }

      if (isNote == '0') {
        const { data } = await serviceOrderService.listServiceAdvancedConsultInvoice(values.data);

        setService(data);
      }

      toast.success('Ordem de serviço consultada com sucesso.');

      resetForm();
    } catch (err) {
      toast.error(err.response.data.message);
    } finally {
      setSubmitting(false);
    }
  };

  console.log(service);

  return (
    <>
      <PageTitle>Consulta avançada de OS</PageTitle>
      <Formik
        initialValues={{ data: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    name='data'
                    size='small'
                    label='Modelo, IMEI, Nome, CPF / CNPJ ou Celular / Telefone / Sinistro'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>

                <Grid item xs={12} lg={3} style={{ fontSize: 15 }}>
                  <Field
                    component={Checkbox}
                    name='isInoivce'
                    label='Por nota'
                    onClick={isByInvoice}
                  />
                  <Typography variant='h7' gutterBottom>
                    Buscar por nota
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={400}
                    // loading={loadingBillingType}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {service?.map((item) => (
                      <TableRow key={item?.id}>
                        <TableCell>{item?.number}</TableCell>
                        <TableCell>{item?.status_description}</TableCell>
                        <TableCell>{item?.model}</TableCell>
                        <TableCell>{item?.imei}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.cgc}</TableCell>
                        <TableCell align='center'>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>{item?.create_date}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <Button
                            size='small'
                            fullWidth
                            onClick={() => navigateToConsultService(item?.number)}
                          >
                            CONSULTAR
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
