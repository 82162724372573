import { createContext, React, useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Table, FormatDate, Button, Select } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Form, Formik, Field } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { cashierService } from 'services/cashier';
import XLSX from 'xlsx';

import { SaleInformation } from './SaleContentDialog';
import { useStyles } from './styles';
const columns = [
  { label: 'Cliente', align: 'center' },
  { label: 'Responsável pela venda (Atendente)', align: 'center' },
  { label: 'Vendedor', align: 'center' },
  { label: 'Total da venda', align: 'center' },
  { label: 'Data da venda', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

const serviceTypeOptions = [
  {
    value: 1,
    label: 'Venda de acessórios',
  },
  {
    value: 2,
    label: 'Serviços',
  },
  {
    value: 3,
    label: 'Venda de acessórios (ATACADO)',
  },
];

export const SaleContext = createContext();

export const AcessorySold = () => {
  const classes = useStyles();

  const [accessorySales, setAccessorySales] = useState([]);
  const [serviceSales, setServiceSales] = useState([]);
  const [soldedWholesales, setSoldedWholesales] = useState([]);

  const [informationDialogContent, setInformationDialogContent] = useState([]);
  const [informationDialog, setInformationDialog] = useBoolean();

  const [serviceTypeSelect, setServiceTypeSelect] = useState();
  const [search, setSearch] = useState('');

  console.log('serviceTypeSelect: ', serviceTypeSelect);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [accessoriesData, , loadingAccessories, refetchComponents] = useQuery(
    cashierService.listSoldedAccessoriesNoWholesale,
    [],
  );

  const [servicesData, , loadingServices, refetchServices] = useQuery(
    cashierService.listSaleServices,
    [],
  );

  const [soldedWholesaleData, , loadingSoldedWholesaleData, refetchSoldedWholesale] = useQuery(
    cashierService.listSoldedAccessoriesWholesale,
    [],
  );

  useEffect(() => {
    setAccessorySales(accessoriesData);
  }, [accessoriesData]);

  useEffect(() => {
    setServiceSales(servicesData);
  }, [servicesData]);

  useEffect(() => {
    setSoldedWholesales(soldedWholesaleData);
  }, [soldedWholesaleData]);

  const handleOpenInformation = (e, item) => {
    setInformationDialog.toTrue();
    setInformationDialogContent(item);
  };

  const formattedAccessories = (accessoriesData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedServices = (servicesData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedSoldedWholesales = (soldedWholesaleData || []).map((b) => {
    return {
      ...b,
    };
  });

  useEffect(() => {}, [formattedAccessories, formattedServices, formattedSoldedWholesales]);

  const filteredAccessories = (formattedAccessories || []).filter((accessories) => {
    const lowerAttendantName = accessories?.accessory_sale?.attendant?.name?.toLowerCase();
    const upperAttendantName = accessories?.accessory_sale?.attendant?.name?.toUpperCase();

    const lowerClientName = accessories?.accessory_sale?.client?.name?.toLowerCase();
    const upperClientName = accessories?.accessory_sale?.client?.name?.toUpperCase();

    return (
      lowerAttendantName?.includes(search) ||
      upperAttendantName?.includes(search) ||
      lowerClientName?.includes(search) ||
      upperClientName?.includes(search)
    );
  });

  const filteredServices = (formattedServices || []).filter((services) => {
    const lowerAttendantName = services?.attendant?.name?.toLowerCase();
    const upperAttendantName = services?.attendant?.name?.toUpperCase();

    const lowerClientName = services?.client?.name?.toLowerCase();
    const upperClientName = services?.client?.name?.toUpperCase();

    return (
      lowerAttendantName?.includes(search) ||
      upperAttendantName?.includes(search) ||
      lowerClientName?.includes(search) ||
      upperClientName?.includes(search)
    );
  });

  const filteredSoldedWholesales = (formattedSoldedWholesales || []).filter((solded) => {
    const lowerAttendantName = solded?.accessory_sale?.attendant?.name?.toLowerCase();
    const upperAttendantName = solded?.accessory_sale?.attendant?.name?.toUpperCase();

    const lowerClientName = solded?.accessory_sale?.client?.name?.toLowerCase();
    const upperClientName = solded?.accessory_sale?.client?.name?.toUpperCase();

    return (
      lowerAttendantName?.includes(search) ||
      upperAttendantName?.includes(search) ||
      lowerClientName?.includes(search) ||
      upperClientName?.includes(search)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  useEffect(() => {}, [
    filteredAccessories,
    filteredServices,
    filteredSoldedWholesales,
    serviceTypeSelect,
  ]);

  const handleServiceType = (row) => {
    setServiceTypeSelect(row);
  };

  const handleDownloadCashier = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(accessoriesData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioCashier${now}.xlsx`);
  };

  const handleCloseInformation = () => {
    setInformationDialog.toFalse();
  };

  return (
    <>
      <SaleContext.Provider
        value={{
          serviceTypeSelect,
        }}
      >
        <Formik initialValues={{ serviceType: '' }}>
          <Form>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={12} md={4}>
                <Text
                  label='Pesquisar'
                  size='small'
                  variant='outlined'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <Field
                  variant='outlined'
                  name='serviceType'
                  label='Tipo de serviço'
                  size='small'
                  component={Select}
                  options={serviceTypeOptions}
                  onChange={handleServiceType}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button fullWidth onClick={handleDownloadCashier}>
                  Exportar relatório
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Table
                  headers={columns}
                  // actionsLabel={<VisibilityIcon />}
                  // onActionsClick={handleOpenInformation}
                  loading={
                    serviceTypeSelect === 1
                      ? loadingAccessories
                      : serviceTypeSelect === 3
                      ? loadingSoldedWholesaleData
                      : loadingServices
                  }
                  emptyMessage='Nenhum registro encontrado.'
                  striped
                  data={
                    serviceTypeSelect === 1
                      ? filteredAccessories
                      : serviceTypeSelect === 3
                      ? filteredSoldedWholesales
                      : filteredServices
                  }
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                >
                  {serviceTypeSelect == 1 ? (
                    filteredAccessories?.map((accessory) => (
                      <TableRow key={accessory?.accessory_sale?.id}>
                        {console.log('Solded: ', accessory?.accessory_sale)}
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.client?.name
                            ? accessory?.accessory_sale?.client?.name
                            : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.attendant?.name}
                        </TableCell>
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.seller?.name
                            ? accessory?.accessory_sale?.seller?.name
                            : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>
                          <FormatMoney>{accessory?.accessory_sale?.sale_total_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                            {accessory?.accessory_sale?.created_at}
                          </FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, accessory)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {serviceTypeSelect == 2 ? (
                    filteredServices?.map((service) => (
                      <TableRow key={service?.id}>
                        <TableCell align='center'>
                          {service?.client?.name ? service?.client?.name : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>{service?.attendant?.name} </TableCell>
                        <TableCell align='center'>
                          {service?.seller?.name ? service?.seller?.name : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>
                          <FormatMoney>{service?.sale_total_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                            {service?.created_at}
                          </FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, service)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}

                  {serviceTypeSelect == 3 ? (
                    filteredSoldedWholesales?.map((accessory) => (
                      <TableRow key={accessory?.accessory_sale?.id}>
                        {console.log('Solded: ', accessory?.accessory_sale)}
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.client?.name
                            ? accessory?.accessory_sale?.client?.name
                            : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.attendant?.name}
                        </TableCell>
                        <TableCell align='center'>
                          {accessory?.accessory_sale?.seller?.name
                            ? accessory?.accessory_sale?.seller?.name
                            : 'Não possui'}
                        </TableCell>
                        <TableCell align='center'>
                          <FormatMoney>{accessory?.accessory_sale?.sale_total_price}</FormatMoney>
                        </TableCell>
                        <TableCell align='center'>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>
                            {accessory?.accessory_sale?.created_at}
                          </FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, accessory)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <></>
                  )}
                </Table>
              </Grid>
            </Grid>
          </Form>
        </Formik>
        <SaleInformation
          open={informationDialog}
          handleClose={handleCloseInformation}
          sale={informationDialogContent}
        />
      </SaleContext.Provider>
    </>
  );
};
