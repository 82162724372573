import React, { useState, useEffect, useContext } from 'react';
import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Spacer, FormatMoney, Button, Table } from 'components';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';

import { addPnToCartAction, removePnFromCartAction, updateAmountPnAction } from './store/actions';
import { useStyles } from './styles';

import { PreBudgetContext } from '..';

const columns = [
  { label: 'Part-Number' },
  { label: 'Descrição' },
  { label: 'Processo' },
  { label: 'Valor' },
  { label: 'Ação', align: 'center', width: 180 },
];

const columnsAdded = [
  { label: 'PART-NUMBER' },
  { label: 'DESCRIÇÃO' },
  { label: 'PROCESSO' },
  { label: 'VALOR' },
  { label: 'QUANTIDADE' },
  { label: 'SUBTOTAL' },
  { label: '', align: 'center' },
];

export const PreBudget = ({ serviceOrder }) => {
  const classes = useStyles();

  const { state, dispatch, totalPreBudget, clone } = useContext(PreBudgetContext);

  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [pageAdded, setPageAdded] = useState(0);
  const [rowsPerPageAdded, setRowsPerPageAdded] = useState(10);

  const [salesComponentsData, , loadingSalesComponents, refetchSalesComponents] = useQuery(
    () => componentService.listSalesComponentsWhereProcess(serviceOrder.process_type_id),
    [serviceOrder],
  );

  console.log('salesComponentsData: ', salesComponentsData);

  const formattedSalesComponents = (salesComponentsData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredSalesComponents = (formattedSalesComponents || []).filter((pns) => {
    const lowerDescription = pns.description?.toLowerCase();
    const upperDescription = pns.description?.toUpperCase();
    const lowerPn = pns?.part_number?.toLowerCase();
    const upperPn = pns?.part_number?.toUpperCase();

    return (
      lowerDescription?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerPn?.includes(search) ||
      upperPn?.includes(search)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageAdded = (event, newPage) => {
    setPageAdded(newPage);
  };

  const handleChangeRowsPerPageAdded = (event) => {
    setRowsPerPageAdded(parseInt(event.target.value, 10));
    setPageAdded(0);
  };

  useEffect(() => {}, [formattedSalesComponents, filteredSalesComponents, refetchSalesComponents]);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} md={5} lg={5}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            endAdornment={<SearchIcon />}
            onChange={handleSearchChange}
            value={search}
          />
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Paper>
            <Typography align='center' variant='h6' gutterBottom>
              Seleção de Componentes
            </Typography>
            <Table
              headers={columns}
              emptyMessage='Nenhum registro encontrado.'
              striped
              loading={loadingSalesComponents}
              maxHeight={500}
              page={page}
              data={filteredSalesComponents}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredSalesComponents?.map((partNumber) => (
                <TableRow key={partNumber.id}>
                  <TableCell>{partNumber?.part_number}</TableCell>
                  <TableCell>{partNumber?.description}</TableCell>
                  <TableCell>{partNumber?.process?.description}</TableCell>
                  <TableCell>
                    <FormatMoney>{partNumber?.sell_price}</FormatMoney>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      onClick={() => addPnToCartAction(partNumber, dispatch)}
                      className={classes.buttonAddToCart}
                    >
                      Adicionar Item
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <Paper>
            <Typography align='center' variant='h6' gutterBottom>
              Pré-Orçamento - Componentes Adicionados
            </Typography>
            <Table
              headers={columnsAdded}
              emptyMessage='Nenhum registro encontrado.'
              striped
              data={state}
              maxHeight={500}
              page={pageAdded}
              rowsPerPage={rowsPerPageAdded}
              onChangePage={handleChangePageAdded}
              onChangeRowsPerPage={handleChangeRowsPerPageAdded}
              disableNumeration
            >
              {state?.map((pn) => (
                <TableRow key={pn.id}>
                  <TableCell>{pn?.part_number}</TableCell>
                  <TableCell className={classes.tableCell}>{pn?.description}</TableCell>
                  <TableCell>{pn?.process?.description}</TableCell>
                  <TableCell className={classes.tableCell}>
                    <FormatMoney>{pn?.sell_price}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Grid container direction='row'>
                      <Grid item xs={4}>
                        <IconButton
                          disabled
                          onClick={() =>
                            updateAmountPnAction(
                              {
                                amount: pn.amount - 1,
                                part_number_id: pn.id,
                              },
                              dispatch,
                            )
                          }
                        >
                          <FaMinusCircle className={classes.minusIcon} />
                        </IconButton>
                      </Grid>
                      <Grid className={classes.qntd} item xs={5}>
                        <Text
                          disabled
                          number='number'
                          variant='outlined'
                          value={pn.amount}
                          onChange={(e) =>
                            updateAmountPnAction(
                              {
                                amount: e.target.value == 0 ? 1 : Number(e.target.value),
                                part_number_id: pn.id,
                              },
                              dispatch,
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <IconButton
                          disabled
                          onClick={() =>
                            updateAmountPnAction(
                              {
                                amount: pn.amount + 1,
                                part_number_id: pn.id,
                              },
                              dispatch,
                            )
                          }
                        >
                          <FaPlusCircle className={classes.plusIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align='center' className={classes.subTotalTableCell}>
                    <FormatMoney>{pn?.sub_total}</FormatMoney>
                  </TableCell>
                  <TableCell className={classes.subTotalTableCell}>
                    <IconButton onClick={() => removePnFromCartAction(pn, dispatch)}>
                      <FaTrash size='18' />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.total}>TOTAL - COMPONENTES:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalPreBudget}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.totalAll}>TOTAL: </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalPreBudget}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
