import React from 'react';

import { Tabs } from 'components';

import { IssueAnalysisInvoiceNewKit } from './IssueAnalysisInvoiceBudget';

export const TableTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Emissão' disablePadding>
        <IssueAnalysisInvoiceNewKit />
      </Tabs.Content>
    </Tabs>
  );
};
