import React, { createContext, useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

import { Formik, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { serviceOrderService } from 'services/serviceOrder';

import { Actions } from './Actions';
import { ClientInfoNew } from './ClientInfoNew';
import { initialValues, validationSchema } from './formik';
import SoInfo from './SoInfo';
import { StepIconComponent } from './StepIcon';
import useStyles from './styles';

const steps = [
  {
    label: 'Dados da OS',
  },
  {
    label: 'Dados do cliente',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const ClientContext = createContext();

export const Opening = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [hasClient, setHasClient] = useState(false);
  const [clientExists, setClientExists] = useState(false);

  const handleDownloadTagsClick = async (service_order_number) => {
    const { data } = await serviceOrderService.DownloadOpeningTag(service_order_number);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${service_order_number}.pdf`);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await api.post('/service-orders', values);
      handleDownloadTagsClick(values?.number);
      toast.success('Ordem de serviço cadastrada com sucesso!');

      if (values?.process_type_id == 1 || values?.process_type_id == 3) {
        await serviceOrderService.invoiceEmissionEntrance(values?.number);
        resetForm();
        setActiveStep(0);
      }

      resetForm();
      setActiveStep(0);
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = useCallback((activeStep, formProps) => {
    const steps = {
      0: <SoInfo />,
      1: <ClientInfoNew FormProps={formProps} />,
    };

    return steps[activeStep];
  }, []);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <PageTitle>Abertura de OS</PageTitle>

      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <ClientContext.Provider
                value={{ clientExists, setClientExists, setHasClient, hasClient }}
              >
                {getStepContent(activeStep, props)}
                <Actions
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  stepsSize={steps.length}
                />
              </ClientContext.Provider>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

// import React, { useState } from 'react';
// import { toast } from 'react-toastify';

// import Step from '@material-ui/core/Step';
// import StepConnector from '@material-ui/core/StepConnector';
// import StepLabel from '@material-ui/core/StepLabel';
// import Stepper from '@material-ui/core/Stepper';
// import { withStyles } from '@material-ui/core/styles';

// import { Formik, Form } from 'formik';
// import { PageTitle } from 'pages/Layout/PageTitle';
// import { api } from 'services/api';
// import { serviceOrderService } from 'services/serviceOrder';

// import { Actions } from './Actions';
// import { ClientInfo } from './ClientInfo';
// import { initialValues, validationSchema } from './formik';
// import SoInfo from './SoInfo';
// import { StepIconComponent } from './StepIcon';
// import useStyles from './styles';

// const steps = [
//   {
//     label: 'Dados da OS',
//   },
//   {
//     label: 'Dados do cliente',
//   },
// ];

// const Connector = withStyles((theme) => ({
//   alternativeLabel: {
//     top: 24,
//     left: 'calc(-50% + 16px)',
//     right: 'calc(50% + 16px)',
//   },
//   active: {
//     '& $line': {
//       borderColor: theme.palette.primary.main,
//     },
//   },
//   completed: {
//     '& $line': {
//       borderColor: theme.palette.primary.main,
//     },
//   },
//   line: {
//     borderColor: '#c8c8cc',
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }))(StepConnector);

// export const Opening = () => {
//   const classes = useStyles();
//   const [activeStep, setActiveStep] = useState(0);

//   const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

//   const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

//   const handleDownloadTagsClick = async (service_order_number) => {
//     const { data } = await serviceOrderService.DownloadOpeningTag(service_order_number);
//     const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
//     saveAs(file, `${service_order_number}.pdf`);
//   };

//   const onSubmit = async (values, { setSubmitting, resetForm }) => {
//     try {
//       await api.post('/service-orders', values);
//       handleDownloadTagsClick(values?.number);
//       toast.success('Ordem de serviço cadastrada com sucesso!');

//       // const teste = await api.post('/service-orders/tiny/invoice/include', values);

//       // console.log(teste.data);

//       // setTimeout(() => toast.success('Nota de entrada cadastrada com sucesso'), 2000);

//       resetForm();
//       setActiveStep(0);
//     } catch (err) {
//       toast.error(err.response?.data?.message);
//     } finally {
//       setSubmitting(false);
//     }
//   };

//   const getStepContent = (activeStep) => {
//     const steps = {
//       0: <SoInfo />,
//       1: <ClientInfo />,
//     };

//     return steps[activeStep];
//   };

//   return (
//     <>
//       <PageTitle>Abertura de OS</PageTitle>

//       <div className={classes.root}>
//         <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
//           {steps.map((step) => (
//             <Step key={step.label}>
//               <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
//             </Step>
//           ))}
//         </Stepper>

//         <Formik
//           initialValues={initialValues}
//           onSubmit={onSubmit}
//           validationSchema={validationSchema}
//         >
//           {(props) => (
//             <Form onSubmit={props.handleSubmit}>
//               {getStepContent(activeStep)}
//               <Actions
//                 activeStep={activeStep}
//                 handleNext={handleNext}
//                 handleBack={handleBack}
//                 stepsSize={steps.length}
//               />
//             </Form>
//           )}
//         </Formik>
//       </div>
//     </>
//   );
// };
