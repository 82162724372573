import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, FormatMoney, Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';

import { OrderDialog } from './OrderDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Preço (un)' },
  { label: 'Tipo de Estoque' },
  { label: 'Local de Estoque' },
  { label: 'Quantidade', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

const normalizeValue = (value) => {
  if (typeof value === 'number') {
    return value.toString();
  }
  if (typeof value === 'string') {
    return value.replace(/[^0-9.,]/g, '').replace(',', '.');
  }
  return '';
};

export const Search = ({
  components,
  loadingComponents,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage,
  search,
  setSearch,
  startIndex,
  setStartIndex,
  refetchComponentsCashier,
}) => {
  const handleChangePage = (event, newPage) => {
    loadingComponents;
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target?.value, 10));
    setPage(0);
  };

  const classes = useStyles();
  const [isDetailsChangeOpen, setIsDetailsChangeState] = useBoolean();
  const [searchComponents, setSearchComponents] = useState({});

  const handleOpenInformation = (e, item) => {
    setIsDetailsChangeState.toTrue();
    setSearchComponents(item);
  };

  const handleCloseInformation = () => {
    setIsDetailsChangeState.toFalse();
  };

  const handleChange = (e) => {
    loadingComponents;
    setPage(0);
    setStartIndex(1);
    const v = e?.target?.value;
    setSearch(v);
  };

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12} md={8} lg={4}>
          <Text
            label='Consultar componente'
            size='small'
            variant='outlined'
            fullWidth
            onChange={handleChange}
            value={search}
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            disableNumeration
            headers={columns}
            striped
            emptyMessage='Nenhum registro encontrado.'
            data={components}
            loading={loadingComponents}
          >
            {/* totalCount={components?.[0]?.totalrow} */}
            {components?.map((component) => (
              <TableRow key={component.id}>
                <TableCell>{component.part_number?.toUpperCase()}</TableCell>
                <TableCell>
                  {component?.description?.toUpperCase()
                    ? component?.description?.toUpperCase()
                    : 'SEM DESCRIÇÃO'}
                </TableCell>
                <TableCell>
                  <FormatMoney>{component.purchase_price}</FormatMoney>
                </TableCell>
                <TableCell>{component.stock_type}</TableCell>
                <TableCell>{component.location_name}</TableCell>
                <TableCell align='center'>{component.quantity}</TableCell>
                <TableCell align='center'>
                  <IconButton color='primary' onClick={(e) => handleOpenInformation(e, component)}>
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[10, 50, 75, 100, 200]}
        component='div'
        count={components?.[0]?.totalrow}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Linhas por página:'
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : ''}`
        }
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <OrderDialog
        open={isDetailsChangeOpen}
        handleClose={handleCloseInformation}
        searchComponents={searchComponents}
      />
    </>
  );
};
