import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { Tabs } from 'components/Tabs';

import { InvoiceInfos } from './InfoTabs/Invoice-infos';
import { PartNumbers } from './InfoTabs/part-numbers-info';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export const BillingInformation = ({ open, handleClose, billingInfos }) => {
  if (!open) return null;

  return (
    <Dialog
      maxWidth='lg'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        Informações billing
      </DialogTitle>
      <Tabs>
        <Tabs.Content label='part-numbers'>
          <PartNumbers billingInfos={billingInfos} />
        </Tabs.Content>
        <Tabs.Content label='informações da nota'>
          <InvoiceInfos billingInfos={billingInfos} />
        </Tabs.Content>
      </Tabs>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
