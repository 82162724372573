import React, { useEffect } from 'react';

// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GraphicPerformanceBudget = () => {
  const [
    budgetWeekComponentsData,
    ,
    loadingbudgetWeekComponentsData,
    refetchBudgetWeekComponentsData,
  ] = useQuery(() => serviceOrderService.listAprovedWithPartsServiceBudgetWeek(), []);

  console.log('budgetWeekComponentsData', budgetWeekComponentsData);

  const segLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdseglabour);
  });

  const terLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdterlabour);
  });

  const quaLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdqualabour);
  });

  console.log('quaLabour', quaLabour);

  const quiLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdquilabour);
  });

  const sexLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdsexlabour);
  });

  const sabLabour = budgetWeekComponentsData?.map((weekLabour) => {
    return Number(weekLabour.qtdsablabour);
  });

  const data = [
    {
      name: 'Seg' + ' ' + segLabour,
      pv: segLabour,
    },
    {
      name: 'Ter' + ' ' + terLabour,
      pv: terLabour,
    },
    {
      name: 'Qua' + ' ' + quaLabour,
      pv: quaLabour,
    },
    {
      name: 'Qui' + ' ' + quiLabour,
      pv: quiLabour,
    },
    {
      name: 'Sex' + ' ' + sexLabour,
      pv: sexLabour,
    },
    {
      name: 'Sáb' + ' ' + sabLabour,
      pv: sabLabour,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchBudgetWeekComponentsData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchBudgetWeekComponentsData]);

  return (
    <ResponsiveContainer>
      <BarChart
        width={100}
        height={30}
        data={data}
        margin={{
          left: -25,
          bottom: -1,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' scale='band' />
        <YAxis />
        <Tooltip />

        <Bar barSize={25} dataKey='pv' stackId='a' fill='#8f34eb' />
      </BarChart>
    </ResponsiveContainer>
  );
};
