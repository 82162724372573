import { useQuery } from '@apollo/client';

const useGraphQL = (query, variables) => {
  const { data, loading, error } = useQuery(query, {
    variables,
  });

  return { data, loading, error };
};

export { useGraphQL };
