import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Pagination } from '@mui/material';
import Chip from '@mui/material/Chip';
import { FormatMoney } from 'components';

const columns = [
  { label: 'VOUCHER', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'SKU', align: 'center' },
  { label: 'Grade', align: 'center' },
];

function getGradeColor(gradeId) {
  switch (gradeId) {
    case 1:
      return {
        label: 'LIKE NEW',
        color: '#FFFFFF', // Branco para LIKE NEW
        textColor: '#000000',
        border: '1px solid #000000',
      };
    case 2:
      return { label: 'EXCELENTE', color: '#0000FF', textColor: '#FFFFFF' }; // Azul para EXCELENTE
    case 3:
      return { label: 'MUITO BOM', color: '#008000', textColor: '#FFFFFF' }; // Verde para MUITO BOM
    case 4:
      return { label: 'BOM', color: '#FFFF00', textColor: '#000000' }; // Amarelo para BOM+
    case 5:
      return { label: 'REGULAR', color: '#FF0000', textColor: '#FFFFFF' }; // Vermelho para BOM
    case 6:
      return { label: 'QUEBRADO', color: '#000000', textColor: '#FFFFFF' }; // Preto para QUEBRADO
    default:
      return { label: 'SEM GRADE', color: '#CCCCCC', textColor: '#000000' }; // Cor padrão (cinza)
  }
}

export function OutputProductTable({ data }) {
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = data.slice(startIndex, startIndex + rowsPerPage);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align} style={{ fontWeight: 'bold' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {(paginatedResults || []).map((item, index) => {
            const grade = getGradeColor(item?.gradeId);
            return (
              <TableRow key={index}>
                <TableCell align='center'>{item?.sinister}</TableCell>
                <TableCell align='center'>{item?.imei}</TableCell>
                <TableCell align='center'>{item?.productSku}</TableCell>
                <TableCell align='center'>
                  <Chip
                    label={grade.label}
                    style={{
                      backgroundColor: grade.color,
                      color: grade.textColor,
                      border: grade.border || 'none',
                    }}
                  />
                </TableCell>
                {/* <TableCell align='center'>
                  <FormatDate>{item.createdAt}</FormatDate>
                </TableCell> */}
                <TableCell align='center'>
                  <FormatMoney>{item.value}</FormatMoney>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(data.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='primary'
        />
      </Grid>
    </TableContainer>
  );
}
