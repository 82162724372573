import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import CheckboxMUI from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/styles/makeStyles';

import { Button, Checkbox, DatePicker, Select, Text, FormatDate, Table } from 'components';
import { Formik, Field, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { pllService } from 'services/pll';

import { AlterModelDialog } from './AlterModelDialog';
import { validationSchema, initialValues } from './formik';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: '5px',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export const CollectObject = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [totalColetado, setTotalColetado] = useState([]);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };
  const [mailObjectsData, , loadingMailObjects, objectrefresh] = useQuery(
    () =>
      api.get('/mail-objects', {
        params: {
          startIndex,
          rowsPerPage,
          search,
        },
      }),
    [startIndex, rowsPerPage, search],
  );

  const [totalObjectDaily, , loadingtotalDaily, totaldailyRefresh] = useQuery(
    () => api.get('/mail-objects/total-daily'),
    [],
  );

  useEffect(() => {
    if (totalObjectDaily) {
      const { totalcoleta } = totalObjectDaily[0];
      setTotalColetado(totalcoleta);
    }
  }, [totalObjectDaily]);

  // const [mailObjects, setMailObjects] = useState([]);

  const [objectTypes, , loadingTypeObjects] = useQuery(
    () => api.get('/mail-objects/object-types'),
    [],
  );

  // const filteredObjects = (mailObjects || []).filter((object) => {
  //   const loweredSearch = search.toLowerCase();

  //   return (
  //     object.code.toLowerCase().includes(loweredSearch) ||
  //     object.receipt_date.toLowerCase().includes(loweredSearch)
  //   );
  // });

  // const [insuranceCompanies, , loadingInsuranceCompanies] = useQuery(
  //   () => api.get('/mail-objects/insurance-companies'),
  //   [],
  // );

  const [subInsuranceCompanies, , loadingSubInsuranceCompanies, insuranceRefetch] = useQuery(
    () => pllService.listSubInsuranceCompanies(),
    [],
  );

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    // console.log('ITEM: ', item);
    setProduct(item);
  };

  // useEffect(() => {
  //   setMailObjects(mailObjectsData || []);
  // }, [mailObjectsData]);

  const loadingOptions = (objectTypes || []).map((objectType) => ({
    value: objectType.id,
    label: objectType.id + '-' + objectType.name,
  }));

  const insuranceCompaniesOptions = (subInsuranceCompanies || []).map((insuranceCompanie) => ({
    value: insuranceCompanie.id,
    label: insuranceCompanie.name,
  }));

  const inputReference = useRef(null);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/mail-objects', values);

      setMailObjects((prevState) => [...(prevState || []), data]);

      toast.success('Objeto cadastrado com sucesso!');

      objectrefresh();

      values.code = '';
      values.search_website_value = 0;

      // resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const columnsWarranty = [
    {
      value: 'GARANTIA',
      label: 'GARANTIA',
    },
    {
      value: 'FORA DE GARANTIA',
      label: 'FORA DE GARANTIA',
    },
  ];

  const columns = [
    { label: 'Tipo de Objeto' },
    { label: 'Objeto' },
    { label: 'Data de cadastro' },
    { label: 'Reenvio' },
    { label: 'Sinistro' },
    { label: 'Alterar Sinistro', align: 'center' },
  ];

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const [product, setProduct] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setStartIndex(1);
    setPage(0);
  };

  // console.log('mailObjectsData', mailObjectsData);

  return (
    <>
      <PageTitle>Coleta de objetos</PageTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.formContainer}>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Tipo do objeto'
                  name='object_type_id'
                  component={Select}
                  loading={loadingTypeObjects}
                  options={loadingOptions}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Tipo de Atendimento'
                  name='warranty_type'
                  component={Select}
                  options={columnsWarranty}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Site seguradora'
                  name='insurance_company_id'
                  component={Select}
                  loading={loadingSubInsuranceCompanies}
                  options={insuranceCompaniesOptions}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Sinistro'
                  name='search_website_value'
                  component={Text}
                  size='small'
                  mask='number'
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Código do objeto'
                  name='code'
                  component={Text}
                  size='small'
                  inputProps={{ maxLength: 15 }}
                  onInput={toInputUppercase}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  size='small'
                  fullWidth
                  name='fabrication_date'
                  variant='inline'
                  inputVariant='outlined'
                  label='Data de Recebimento'
                  component={DatePicker}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Field
                  variant='outlined'
                  label='Marcar como Reenvio'
                  name='resend'
                  size='small'
                  component={Checkbox}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Button color='primary' type='submit' fullWidth disabled={!dirty || !isValid}>
                  Salvar
                </Button>
              </Grid>
              <Grid item xs={3} md={4}>
                <Typography gutterBottom>Total recebido do dia: {totalColetado}</Typography>
              </Grid>
              <AlterModelDialog
                open={isDetailsOpen}
                handleClose={handleCloseInformation}
                product={product}
              />
            </Grid>
          </Form>
        )}
      </Formik>

      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar objeto'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            title='Lista de objetos'
            headers={columns}
            striped
            loading={loadingMailObjects}
            emptyMessage='Nenhum registro encontrado.'
            data={mailObjectsData?.data}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
          >
            {mailObjectsData?.data?.map((object) => (
              <TableRow key={object?.id}>
                <TableCell>{object?.object_type?.name}</TableCell>
                <TableCell>{object?.code}</TableCell>

                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.receipt_date}</FormatDate>
                </TableCell>
                <TableCell align='justify'>
                  <CheckboxMUI color='primary' checked={object?.resend} disabled />
                </TableCell>
                <TableCell>{object?.search_website_value}</TableCell>
                <TableCell align='center'>
                  <IconButton
                    color='primary'
                    onClick={(e) => handleOpenInformation(e, object)}
                    disabled={object?.search_website_value != 0 ? false : true}
                  >
                    <AddCircleIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50, 100, 200]}
            component='div'
            count={mailObjectsData?.totalRows}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Linhas por página:'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : ''}`
            }
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};
