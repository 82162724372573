import React from 'react';
import { toast } from 'react-toastify';

import Typography from '@material-ui/core/Typography';

import { Button, Dialog } from 'components';
import { invoiceService } from 'services';

export const ValidationDialog = ({ open, onClose, invoice, reloadInvoices }) => {
  console.log('Invoice: ', invoice);

  const confirmValidation = async () => {
    console.log('Confirm Validation: ', invoice?.number, true);

    try {
      await invoiceService.includedInvoiceValidation(invoice?.number, true);

      reloadInvoices();
      toast.success('Nota de análise validada com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível validar a nota de análise.');
    } finally {
      onClose();
    }
  };

  const refuseValidaion = async () => {
    console.log('Refuse Validation: ', invoice?.number, true);

    try {
      await invoiceService.includedInvoiceValidation(invoice?.number, false);

      reloadInvoices();
      toast.success('Nota de análise recusada com sucesso.');
    } catch (err) {
      toast.error(
        err.response?.data?.message ||
          'Não foi possível recusão a validação dessa nota de análise.',
      );
    } finally {
      onClose();
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='xs'>
        <Dialog.Title onClose={onClose}>Aviso</Dialog.Title>
        <Dialog.Content>
          <Typography>Tem certeza que deseja confirmar ?</Typography>
        </Dialog.Content>
        <Dialog.Actions>
          <Button onClick={refuseValidaion} type='submit' variant='text' color='default'>
            Rejeitar
          </Button>
          <Button onClick={confirmValidation} type='submit'>
            Validar
          </Button>
        </Dialog.Actions>
      </Dialog>
    </>
  );
};
