import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import cep from 'cep-promise';
import { Select, Text } from 'components';
import { Field } from 'formik';
import { useFormikContext } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { serviceOrderService } from 'services';
import { api } from 'services/api';

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const FormRegistration = () => {
  const { values, setFieldValue } = useFormikContext();

  const [loadingCep, setLoadingCep] = useState(false);

  const onBlurCep = async () => {
    setLoadingCep(true);

    const { state, city, neighborhood, street } = await cep(values?.zipcode);

    setFieldValue('state', state);
    setFieldValue('city', city);
    setFieldValue('district', neighborhood);
    setFieldValue('street', street);

    setLoadingCep(false);
  };

  const [states, , loadingStates] = useQuery(
    () => api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados'),
    [],
  );

  const ufsOptions = (states || []).map((state) => {
    return {
      value: state.sigla,
      label: state.sigla,
    };
  });

  // const isCPF = values.cgc?.length < 12;
  // const shouldDisableIeField = isCPF || !!values.exempt;

  const ClientName = () => {
    const { setFieldValue } = useFormikContext();

    const handleCgcBlur = async (value) => {
      try {
        const data = await serviceOrderService.getClientByCgc(value.replace(/\D/g, ''));

        //Pegando os campos que irão retornar caso o cliente ja esteja cadastrado
        const { cgc, name, email, addresses, phone_number, residential_phone_number } = data.data;

        //mapeando o Array do endereço
        const { zipcode, district, city, state, street, complement, street_number } = addresses[0];

        console.log('DOCS: ', data.data);
        console.log('NAME: ', name);

        setFieldValue('name', name);
        setFieldValue('email', email);
        setFieldValue('phone_number', phone_number);
        setFieldValue('residential_phone_number', residential_phone_number);
        setFieldValue('zipcode', zipcode);
        setFieldValue('district', district);
        setFieldValue('city', city);
        setFieldValue('state', state);
        setFieldValue('street', street);
        setFieldValue('complement', complement);
        setFieldValue('street_number', street_number);

        if (cgc === undefined || cgc === '') {
          toast.error('Informe um CPF/CNPJ válido para prosseguir');
        }
      } catch (err) {
        toast.error(err.response?.data?.message || 'Erro ao encontrar cliente.');
      } finally {
      }
    };

    return (
      <Field
        variant='outlined'
        label='CPF/CNPJ do cliente'
        name='cgc'
        size='small'
        component={Text}
        mask='document'
        onBlur={(e) => handleCgcBlur(e.target.value)}
      />
    );
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3}>
          <ClientName />
        </Grid>
        <Grid item xs={12} md={6} lg={9}>
          <Field
            size='small'
            name='name'
            label='Nome Completo'
            variant='outlined'
            component={Text}
            onInput={toInputUppercase}
          />
        </Grid>

        <Grid item xs={12} md={3}>
          <Field
            mask='zipcode'
            size='small'
            fullWidth
            name='zipcode'
            label='CEP'
            variant='outlined'
            component={Text}
            onBlur={onBlurCep}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Field
            size='small'
            fullWidth
            name='city'
            label='Cidade'
            variant='outlined'
            component={Text}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <Field
            name='state'
            label='UF'
            size='small'
            fullWidth
            variant='outlined'
            loading={loadingStates || loadingCep}
            options={ufsOptions}
            component={Select}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            size='small'
            fullWidth
            name='district'
            label='Bairro'
            variant='outlined'
            component={Text}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            size='small'
            fullWidth
            name='street'
            label='Rua'
            variant='outlined'
            component={Text}
            loading={loadingCep}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={4}>
          <Field
            size='small'
            fullWidth
            name='complement'
            label='Complemento'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <Field
            size='small'
            fullWidth
            name='street_number'
            label='Número'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={6}>
          <Field
            size='small'
            fullWidth
            name='email'
            label='Email'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Field
            mask='phone'
            size='small'
            fullWidth
            name='phone_number'
            label='Celular'
            variant='outlined'
            component={Text}
          />
        </Grid>
        <Grid item xs={12} md={3} lg={3}>
          <Field
            mask='phone'
            size='small'
            fullWidth
            name='residential_phone_number'
            label='Telefone fixo'
            variant='outlined'
            component={Text}
          />
        </Grid>
      </Grid>
    </>
  );
};
