import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as Work } from 'assets/images/work.svg';
import { Button, Text } from 'components';
import { Field, Formik, Form } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

import { AlterImeiConferenceDialog } from './AlterServiceGaiaDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

export const AlterConferenceGaia = () => {
  const classes = useStyles();

  const [serviceData, setServiceData] = useState([]);
  const [isDetailsOpen, setIsDetailsState] = useBoolean();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e) => {
    setIsDetailsState.toTrue();
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const { imei } = values;

    const { infnfe } = values;

    const { data } = await invoiceService.getReportConferenceForConsult(infnfe, imei);

    console.log(data);
    resetForm();
    console.log(data);
    setServiceData(data);

    handleOpenInformation();

    if (data.length > 0) {
      toast.success('Conferência para o imei informado consultada com sucesso');
      setSubmitting(false);
    } else {
      toast.error('Occoreu um erro ao realizar a consulta !');
      setSubmitting(false);
      handleCloseInformation();
      resetForm();
    }
  };

  return (
    <>
      <PageTitle>Editar Dados Conferência</PageTitle>
      <Formik
        initialValues={{ imei: '', infnfe: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    name='imei'
                    size='small'
                    label='Imei atual'
                    // inputProps={{ maxLength: 10, minlength: 1 }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={4}>
                  <Field
                    variant='outlined'
                    name='infnfe'
                    size='small'
                    label='Chave nota'
                    // inputProps={{ maxLength: 10, minlength: 1 }}
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>
                <AlterImeiConferenceDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  serviceOrder={serviceData}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Grid className={classes.containerImg} container>
        <Work className={classes.trashImage} />
      </Grid>
    </>
  );
};
