import React from 'react';

import { Tabs } from 'components';

import { TableAssigned } from './TableAssigned';
import { TableBudgetsApproved } from './TableBudgetsApproved';
import { TableBudgetsReproved } from './TableBudgetsReproved';
import { TableLaboratory } from './TableLaboratory';

export const GeneralTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='OS-DISPONÍVEIS' disablePadding>
        <TableAssigned />
      </Tabs.Content>

      <Tabs.Content label='LABORATÓRIO' disablePadding>
        <TableLaboratory />
      </Tabs.Content>

      {/* <Tabs.Content label='ORÇAMENTOS' disablePadding>
        <TableBudgets />
      </Tabs.Content> */}

      {/* <Tabs.Content label='OS ENCERRADAS' disablePadding>
        <TableFinisheds />
      </Tabs.Content> */}

      <Tabs.Content label='ORÇAMENTOS APROVADOS' disablePadding>
        <TableBudgetsApproved />
      </Tabs.Content>

      <Tabs.Content label='ORÇAMENTOS REPROVADOS' disablePadding>
        <TableBudgetsReproved />
      </Tabs.Content>
    </Tabs>
  );
};
