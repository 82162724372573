import * as React from 'react';

import CheckboxMUI from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import LabelBase from '../LabelBase';

const CheckboxComponent = ({
  field,
  label,
  name,
  description,
  checked,
  form,
  value,
  errorMessage: errorMessageProp,
  multiple,
  onChange,
  margin,
  disabled,
}) => {
  name = field?.name ?? name;

  const isChecked = React.useMemo(() => {
    if (multiple) {
      return (field?.value ?? []).includes(value);
    }
    return field?.value === true || checked;
  }, [checked, multiple, value, field]);

  const handleChange = React.useCallback(
    (e, checked) => {
      if (!multiple) {
        form?.setFieldValue(name, checked);
        onChange && onChange(e, checked);
        return;
      }

      const setValue = new Set(form.getFieldValue(name) ?? []);

      if (checked) {
        setValue.add(value);
      } else {
        setValue.delete(value);
      }

      form.setFieldValue(name, Array.from(setValue));
    },
    [form, multiple, name, onChange, value],
  );

  const errorMessage = errorMessageProp ?? form?.errors[field?.name];
  const hasError = !!errorMessage;
  return (
    <FormControlLabel
      control={
        <CheckboxMUI
          classes={{ root: margin === 'none' }}
          checked={isChecked}
          onChange={handleChange}
          name={name}
          color='primary'
          disabled={disabled || form?.isSubmitting}
        />
      }
      label={
        <LabelBase
          hasError={hasError}
          errorMessage={errorMessage}
          label={label}
          description={description}
        />
      }
    />
  );
};

export const Checkbox = React.memo(CheckboxComponent);
