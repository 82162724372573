import React, { useEffect, useMemo, useState } from 'react';

import { Tabs } from 'components/Tabs';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';

import { Components } from './Components';
import { RequestComponentProvider } from './context';
import { PendingRequest } from './PendingRequest';

export const RequestComponents = () => {
  const [components, setComponents] = useState([]);
  const [orderings, setOrderings] = useState([]);

  const [componentsData, , loadingComponents] = useQuery(
    () => api.get('/components?available=true'),
    [],
  );
  const [orderingsData, , loadingOrderings, refetchOrderings] = useQuery(
    () => api.get('/components/orderings'),
    [],
  );

  useEffect(() => {
    setComponents(componentsData);
  }, [componentsData]);

  useEffect(() => {
    setOrderings(orderingsData);
  }, [orderingsData]);

  const contextValue = useMemo(
    () => ({
      components,
      setComponents,
      loadingComponents,
      orderings,
      setOrderings,
      loadingOrderings,
      refetchOrderings,
    }),
    [components, loadingComponents, loadingOrderings, orderings, refetchOrderings],
  );

  return (
    <>
      <PageTitle>Requisição de Componentes</PageTitle>

      <RequestComponentProvider value={contextValue}>
        <Tabs>
          <Tabs.Content label='Componentes' disablePadding>
            <Components />
          </Tabs.Content>
          <Tabs.Content label='Requisições Pendentes' disablePadding>
            <PendingRequest />
          </Tabs.Content>
        </Tabs>
      </RequestComponentProvider>
    </>
  );
};
