import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Text, Table, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as yup from 'yup';

import { AlterSellerDialog as AlterSellerDialog } from './AlterSellerDialog';
import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  name: yup.string().required(),
});

const columns = [
  { label: 'Nome', width: 250 },
  { label: 'Endereço' },
  { label: 'CGC' },
  { label: 'Municipio' },
  { label: 'Estado' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const RegisterShippingCompany = () => {
  const userId = useContextSelector(AuthContext, (context) => context.user.id);
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [states, , loadingStates] = useQuery(
    () => api.get('https://servicodados.ibge.gov.br/api/v1/localidades/estados'),
    [],
  );

  const ufsOptions = (states || []).map((state) => {
    return {
      value: state.sigla,
      label: state.sigla,
    };
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [seller, setSeller] = useState({});
  const [sellers, setSellers] = useState([]);

  const [sellerData, , loadingSellerData, refetchSeller] = useQuery(
    () => accountService.getIsSeller(),
    [],
  );

  useEffect(() => {
    setSellers(sellerData);
  }, [sellerData]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setSeller(item);
  };

  const classes = useStyles();

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      setSubmitting(true);

      await accountService.setAsSeller(values.email, values.name, userId);

      toast.success('Usuário atribuído como vendedor com sucesso.');
      refetchSeller();
      resetForm();
    } catch (err) {
      toast.error(
        err.response?.data?.message || 'Erro ao realizar a atribuição do usuário como vendedor.',
      );
    } finally {
      resetForm();
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Registrar Transportadora</PageTitle>
      <Formik initialValues={{ email: '', name: '' }} onSubmit={onSubmitHandler}>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='name'
                    label='Nome transportadora'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='address'
                    label='Logradouro'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='cgc'
                    label='CNPJ/CPF'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='state'
                    label='UF'
                    component={Select}
                    options={ufsOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={5}>
                  <Field
                    variant='outlined'
                    size='small'
                    name='city'
                    label='Cidade'
                    component={Text}
                    o
                  />
                </Grid>

                <Grid item xs={12} lg={2}>
                  <Button fullWidth disabled={!props.isValid || !props.dirty} type='submit'>
                    Confirmar
                  </Button>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Table
                    headers={columns}
                    striped
                    loading={loadingSellerData}
                    // actionsLabel={<VisibilityIcon />}
                    emptyMessage='Nenhum registro encontrado'
                    // onActionsClick={handleOpenInformation}
                    data={sellerData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                    fullWidth
                  >
                    {sellers?.map((seller) => (
                      <TableRow key={seller.id}>
                        <TableCell>{seller.email}</TableCell>
                        <TableCell>{seller.name}</TableCell>
                        <TableCell>{seller.is_seller_active ? 'Ativo' : 'Inativo'}</TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, seller)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterSellerDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  seller={seller}
                  reloadSellers={refetchSeller}
                />
                {/* <Grid className={classes.containerImg} item xs={12} lg={4}>
                  <ImgRegisterAttendant className={classes.img} />
                </Grid> */}
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
