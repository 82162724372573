import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { FormatMoney, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { collaboratorsService } from 'services';

import { CardTemplate } from './card';
import { DialogAverage } from './dialogAverage';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const deviceStateOption = [
  { label: 'Excelente', value: 'Excelente' },
  { label: 'Bom', value: 'Bom' },
  { label: 'Defeito', value: 'Defeito' },
  { label: 'Danificado', value: 'Danificado' },
];

const deviceFactor = [
  { label: 'Flagship', value: 'Flagship' },
  { label: 'Others', value: 'Others' },
  { label: 'Old', value: 'Old' },
];

export const SearchPriceSamsung = () => {
  const classes = useStyles();
  const [isCardRender, setIsCardRender] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [productSearchData, setProductSearchData] = useState({});
  const [thisMyProduct, setThisMyProduct] = useState({});
  const [finishTemplate, setFinishTemplate] = useState(false);
  const [newSearch, setNewSearch] = useState(true);
  const [valuesData, setData] = useState({});
  const [offer, setOffer] = useState({});
  const [openDialogAveragePrice, setOpenDialogAveragePrice] = useState();

  console.log('offer', offer);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    const search = String(values.search_product).trim();
    setData(values);
    try {
      const { data } = await collaboratorsService.getRealTimeProductSearch(search);
      console.log('data', data);
      setProductSearchData(data);
      setIsCardRender(true);
      setDisableButton(true);
      setFinishTemplate(false);
      setNewSearch(false);

      toast.success('Pesquisa realizada com sucesso');
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Ocorreu um erro, tente novamente mais tarde');
    }
  };

  const handleDownloadTagsClickBudget = async () => {
    console.log('Entrei no PDF');
    const { data } = await collaboratorsService.getTradeInTerm();
    console.log('Data: ', data);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `Laudo.pdf`);
  };

  const resetForm = () => {
    location.reload();
  };

  const handleCloseAverageDialog = () => {
    setOpenDialogAveragePrice(false);
  };

  useEffect(() => {
    if (finishTemplate) {
      setOpenDialogAveragePrice(true);
    }
  }, [finishTemplate, setOpenDialogAveragePrice]);

  useEffect(() => {
    if (finishTemplate) {
      setOpenDialogAveragePrice(true);
    }
  }, [finishTemplate, setOpenDialogAveragePrice]);

  return (
    <>
      <PageTitle>Consultar preço</PageTitle>
      <Formik
        initialValues={{ search_product: '' }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form>
              <Paper title='Informações do aparelho' elevation={5} variant='elevation'>
                <Grid container spacing={3} className={classes.paper}>
                  {/* <Grid item xs={11} sm={11} md={5} lg={6}>
                    <Field
                      variant='outlined'
                      name='device_status'
                      label='Condição do produto'
                      size='small'
                      component={Select}
                      options={deviceStateOption}
                    ></Field>
                  </Grid> */}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Field
                      variant='outlined'
                      name='search_product'
                      label='Pesquisar produto'
                      size='small'
                      component={Text}
                    ></Field>
                  </Grid>
                  {/* <Grid item xs={11} sm={11} md={5} lg={6}>
                    <Field
                      variant='outlined'
                      name='device_factor'
                      label='Fator'
                      size='small'
                      component={Select}
                      options={deviceFactor}
                    ></Field>
                  </Grid> */}
                </Grid>
              </Paper>
              <Paper elevation={5} variant='elevation'>
                <Grid container spacing={3} className={classes.paper}>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      disabled={newSearch}
                      onClick={resetForm}
                      color='secondary'
                      variant='contained'
                      fullWidth
                    >
                      Nova pesquisa
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Button
                      disabled={!props.dirty || !props.isValid || !newSearch}
                      type='submit'
                      color='primary'
                      variant='contained'
                      fullWidth
                    >
                      Consultar preço
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
              <Spacer size={10} />
              {isCardRender ? <PageTitle>Qual o seu aparelho?</PageTitle> : <></>}
              {finishTemplate ? (
                <PageTitle>
                  Essas são as nossas ofertas para as seguintes condições do aparelho:
                </PageTitle>
              ) : (
                <></>
              )}
            </Form>
          </>
        )}
      </Formik>
      {isCardRender ? (
        <Grid container spacing={5} alignItems='center'>
          {productSearchData.map((item) => (
            <Grid key={item.id} item xs={11} sm={6} md={4} lg={3}>
              <CardTemplate
                image={item.image}
                title={item.title}
                description={item.description}
                allInformation={item}
                setThisMyProduct={setThisMyProduct}
                setFinishTemplate={setFinishTemplate}
                setIsCardRender={setIsCardRender}
                finishTemplate={finishTemplate}
                valuesData={valuesData}
                setOffer={setOffer}
                confirmOffer={false}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
      {finishTemplate ? (
        <>
          <DialogAverage
            open={openDialogAveragePrice}
            onClose={handleCloseAverageDialog}
            average={thisMyProduct?.average}
            productSearchData={productSearchData}
          />
          <Grid container spacing={5} alignItems='center'>
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <Paper className={classes.cardPaper} elevation={3} variant='elevation'>
                <Grid className={classes.condition}>Excelente</Grid>
                <Grid className={classes.offer}>
                  <FormatMoney>{offer.great_offer}</FormatMoney>
                </Grid>
              </Paper>
              <Spacer size={30} />
              <CardTemplate
                image={thisMyProduct.image}
                title={thisMyProduct.title}
                description={thisMyProduct.description}
                allInformation={thisMyProduct}
                finishTemplate={finishTemplate}
                confirmOffer={true}
                condition={'Excelente'}
                offerValue={offer.great_offer}
              />
            </Grid>
            {/* <Spacer size={80} /> */}
            {/* <Grid container spacing={5} alignItems='center'> */}
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <Paper className={classes.cardPaper} elevation={3} variant='elevation'>
                <Grid className={classes.condition}>Bom</Grid>
                <Grid className={classes.offer}>
                  Oferta: <FormatMoney>{offer.good_offer}</FormatMoney>
                </Grid>
              </Paper>
              <Spacer size={30} />
              <CardTemplate
                image={thisMyProduct.image}
                title={thisMyProduct.title}
                description={thisMyProduct.description}
                allInformation={thisMyProduct}
                finishTemplate={finishTemplate}
                confirmOffer={true}
                condition={'Bom'}
                offerValue={offer.good_offer}
              />
            </Grid>

            {/* </Grid> */}
            {/* <Spacer size={80} /> */}
            {/* <Grid container spacing={5} alignItems='center'> */}
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <Paper className={classes.cardPaper} elevation={3} variant='elevation'>
                <Grid className={classes.condition}>Defeito</Grid>
                <Grid className={classes.offer}>
                  Oferta: <FormatMoney>{offer.defect_offer}</FormatMoney>
                </Grid>
              </Paper>
              <Spacer size={30} />
              <CardTemplate
                image={thisMyProduct.image}
                title={thisMyProduct.title}
                description={thisMyProduct.description}
                allInformation={thisMyProduct}
                finishTemplate={finishTemplate}
                confirmOffer={true}
                condition={'Defeito'}
                offerValue={offer.defect_offer}
              />
            </Grid>
            {/* </Grid> */}
            {/* <Spacer size={80} /> */}
            {/* <Grid container spacing={5} alignItems='center'> */}
            <Grid item xs={11} sm={6} md={4} lg={3}>
              <Paper className={classes.cardPaper} elevation={3} variant='elevation'>
                <Grid className={classes.condition}>Danificado</Grid>
                <Grid className={classes.offer}>
                  Oferta: <FormatMoney>{offer.damaged_offer}</FormatMoney>
                </Grid>
              </Paper>
              <Spacer size={30} />
              <CardTemplate
                image={thisMyProduct.image}
                title={thisMyProduct.title}
                description={thisMyProduct.description}
                allInformation={thisMyProduct}
                finishTemplate={finishTemplate}
                confirmOffer={true}
                condition={'Danificado'}
                offerValue={offer.damaged_offer}
              />
            </Grid>
          </Grid>
          {/* </Grid> */}
        </>
      ) : (
        <></>
      )}
      {/* {!isCardRender && !finishTemplate ? (
        <Grid item xs={12} lg={3}>
          <div className={classes.containerLegend}>
            <WarningIcon color='warning' />
            <p variant='caption' className={classes.p}>
              Preencha todos os campos para habilitar o botão consultar
            </p>
          </div>
        </Grid>
      ) : (
        <></>
      )} */}
    </>
  );
};
