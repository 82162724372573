import { gql } from '@apollo/client';

export const LIST_RECEIVING_HEADER = gql`
  query {
    listReceivingNotFinalized {
      id
      carrierId
      driver
      plate
      document
      itemsCollected
      carrier {
        description
        __typename @skip(if: true)
      }
      receivingItems {
        sinister
        additional
        __typename @skip(if: true)
      }
      __typename @skip(if: true)
    }
  }
`;

export const CREATE_RECEIVING_HEADER = gql`
  mutation CreateReceivingHeader($data: CreateReceivingHeaderInput!) {
    createReceivingHeader(data: $data)
  }
`;

export const FINALIZE_RECEIVING = gql`
  mutation FinalizeReceiving($id: Int!) {
    finalizeReceiving(id: $id)
  }
`;

export const CREATE_RECEIVING_ITEM = gql`
  mutation CreateReceivingItem($voucher: String!, $headerId: Int!) {
    createReceivingItem(voucher: $voucher, headerId: $headerId)
  }
`;

export const LIST_LAST_TEN_RECEIVING_ITENS = gql`
  query ListLastTenReceivingItens($limit: Int!, $offset: Int!, $header_id: Int!) {
    listLastTenReceivingItens(limit: $limit, offset: $offset, header_id: $header_id) {
      totalCount
      __typename @skip(if: true)
      responses {
        id
        sinister
        __typename @skip(if: true)
      }
    }
  }
`;

export const REMOVE_RECEIVING_ITEM = gql`
  mutation RemoveReceivingItem($itemId: Int!) {
    removeReceivingItem(itemId: $itemId)
  }
`;
