import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/styles/makeStyles';

import { Button, Text, FormatDate, Table, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providerService } from 'services';
import { api } from 'services/api';
import { mailServices } from 'services/mail';

import { validationSchema, initialValues } from './formik';
import { useStyles2 } from './styles';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginBottom: '5px',
  },
  tableContainer: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const providerServiceOptions = [
  {
    value: '03247',
    label: 'SEDEX',
  },
  {
    value: '03301',
    label: 'PAC',
  },
];

export const Eticket = () => {
  const [search, setSearch] = useState('');

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const [eticketsData, , loadingEtickets, refetchEtickets] = useQuery(
    () => providerService.listEtickets(),
    [],
  );

  const [eticket, setEticket] = useState([]);

  const [objectTypes, , loadingTypeObjects] = useQuery(
    () => api.get('/mail-objects/object-types'),
    [],
  );

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const classes = useStyles();

  const class2 = useStyles2();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [mailContractsData, , loadingMailContracts] = useQuery(mailServices.listMailContracts, []);

  const mailContractOptions = (mailContractsData || []).map((mailContract) => {
    return {
      value: mailContract.contract_number,
      label:
        mailContract.contract_number +
        ' ' +
        mailContract.contract_method +
        ' ' +
        mailContract.additional_information,
    };
  });
  const filteredEtickets = (eticket || []).filter((object) => {
    const loweredSearch = search.toLowerCase();

    return (
      object?.collect_number?.toLowerCase()?.includes(loweredSearch) ||
      object?.client_id?.toLowerCase()?.includes(loweredSearch)
    );
  });

  useEffect(() => {
    setEticket(eticketsData);
  }, [eticketsData]);

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await providerService.createETicket(values);

      setEticket((prevState) => [...prevState, data]);

      toast.success('E-Ticket gerado com sucesso.');

      refetchEtickets();
      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const columns = [
    { label: 'E-ticket' },
    { label: 'Objeto' },
    { label: 'Status postagem' },
    { label: 'Service' },
    { label: 'Nome cliente' },
    { label: 'Data de emissão' },
    { label: 'Valido até' },
    { label: ' Última atualização' },
  ];

  return (
    <>
      <PageTitle>E-tickets</PageTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ handleSubmit, dirty, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2} className={classes.formContainer}>
              <Grid item xs={12} md={6}>
                <Field
                  variant='outlined'
                  label='Número da ordem de serviço'
                  name='service_number'
                  component={Text}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  variant='outlined'
                  label='Cartão de Postagem'
                  name='card'
                  component={Select}
                  options={mailContractOptions}
                  size='small'
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <Field
                  variant='outlined'
                  label='Tipo de Postagem'
                  name='serviceCode'
                  component={Select}
                  options={providerServiceOptions}
                  size='small'
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Button color='primary' type='submit' fullWidth disabled={!dirty || !isValid}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            title='Lista de objetos'
            headers={columns}
            loading={loadingEtickets}
            page={page}
            rowsPerPage={rowsPerPage}
            data={eticketsData}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
            // maxHeight={300}
            emptyMessage='Nenhum registro encontrado.'
            striped
          >
            {filteredEtickets?.map((object) => (
              <TableRow key={object?.id}>
                <TableCell>{object?.collect_number}</TableCell>
                <TableCell>{object?.collect_ticket ?? '-'}</TableCell>
                <TableCell className={`${class2?.statusCell} status-${object?.object_status}`}>
                  <Typography className={class2.statusDescription}>
                    {object?.object_status}
                  </Typography>
                </TableCell>

                <TableCell>{object?.number}</TableCell>

                <TableCell>{object?.name}</TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.created_at}</FormatDate>
                </TableCell>
                <TableCell>{object?.deadline}</TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.updated_at}</FormatDate>
                </TableCell>
                {/* <TableCell align='justify'>
                  <CheckboxMUI color='primary' checked={object?.resend} disabled />
                </TableCell> */}
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
