import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import WarningIcon from '@mui/icons-material/Warning';
import { Dialog, Text, Button, Spacer, Select } from 'components';
import { Formik, Field, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { serviceOrderService } from 'services';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  serial_new: yup.string().required(),
});

export const DialogConfirmShippingQa = ({ open, handleClose, service }) => {
  if (!open) return null;

  useEffect(() => {}, []);

  const navigateTo = () => {
    <BrowserRouter forceRefresh={true} />;
  };

  const [reportTypes, , loadingReportTypes] = useQuery(
    () => serviceOrderService.listReportType(),
    [],
  );
  const reportOptions = (reportTypes || []).map((att) => {
    return {
      value: att.description,
      label: att.description,
    };
  });

  console.log('batata', reportTypes);
  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await serviceOrderService.getByNumber(service);

      await serviceOrderService.updateServiceStatusIdQA(
        data?.id,
        values.description,
        values.imei1_out,
        values.imei2_out,
        values.serial_new,
      );

      toast.success('Status da ordem de serviço alterada para QA');

      setTimeout(() => {
        window.location.reload();
      }, 2000);

      navigateTo();

      // refetchServiceOrders();
    } catch (err) {
      toast.error(
        err?.response?.data?.message || 'Não foi possível alterar o status da ordem de serviço',
      );
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='md'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title onClose={handleClose}>Laudo para QA - Ordem de Serviço: {service}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            description: '',
            imei1_out: null,
            imei2_out: '',
            serial_new: null,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Laudo Técnico'
                      variant='outlined'
                      size='small'
                      options={reportOptions}
                      inputProps={{ maxLength: 100 }}
                      component={Select}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <div>
                    <WarningIcon color='warning' />
                    <p variant='caption'>Em caso de troca de IMEI, preencha os campos.</p>
                  </div>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name='imei1_out'
                      label='Imei1 OUT'
                      variant='outlined'
                      size='small'
                      inputProps={{ maxLength: 15, minlength: 15 }}
                      mask='number'
                      component={Text}
                    />
                  </Grid>
                  <Spacer size={8} />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Field
                      name='imei2_out'
                      label='Imei2 OUT'
                      variant='outlined'
                      mask='number'
                      size='small'
                      inputProps={{ maxLength: 15, minlength: 15 }}
                      component={Text}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <Field
                    name='serial_new'
                    label='Novo Serial'
                    variant='outlined'
                    size='small'
                    inputProps={{ maxLength: 15 }}
                    component={Text}
                  />
                </Grid>

                <Spacer size={8} />

                <Grid container>
                  <Button onClick={handleClose} color='primary' variant='text'>
                    Fechar
                  </Button>
                  <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
