import React, { useState, useEffect } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Text, Table, Button } from 'components';
import dayjs from 'dayjs';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import XLSX from 'xlsx';

import { BillingInformation } from './BillingInformation';
import { useStyles } from './styles';

const columns = [
  { label: 'Billing' },
  { label: 'Nº Nota Fiscal' },
  { label: 'Expira em' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const ConsultBilling = () => {
  const classes = useStyles();
  const [informationDialogContent, setInformationDialogContent] = useState([]);
  const [informationDialog, setInformationDialog] = useBoolean();
  const [billings, , loadingBillings] = useQuery(() => api.get('/invoices/billings'), []);

  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    const v = e.target.value;

    setSearch(v);
  };

  const setBilling = async (billing_id) => {
    const { data } = await api.get(`/components/billing/pn/${billing_id}`);
    console.log('Data: ', data);
    setInformationDialogContent(data);
  };

  const formatExpiration = (createdDate, expiresDate) => {
    const today = dayjs();
    const createdAt = dayjs(createdDate);
    const expiresAt = dayjs(expiresDate);

    const diffInDaysFloatHundred = expiresAt.diff(createdAt, 'day', true);
    const diffInDaysFloat = expiresAt.diff(today, 'day', true);

    const okColor = '#4CAF50';
    const warningColor = '#FA7E1E';
    const errorColor = '#FF0000';

    const roundedDaysHundred = Math.round(diffInDaysFloatHundred);
    const roundedDays = Math.round(diffInDaysFloat);

    const percent = roundedDaysHundred / 2;

    const daysFormat = `${roundedDays} dia(s)`;

    if (roundedDays > 15) {
      return {
        value: daysFormat,
        color: okColor,
      };
    }

    if (roundedDays < 15 && roundedDays > 0 && roundedDays >= 5) {
      return {
        value: daysFormat,
        color: warningColor,
      };
    }

    if (roundedDays < 5 && roundedDays > 0) {
      return {
        value: daysFormat,
        color: errorColor,
      };
    }

    if (roundedDays <= 0) {
      return {
        value: 'Expirado',
        color: errorColor,
      };
    }
    return { value: 'Expirado', color: errorColor };
  };

  const handleOpenInformation = (e, item) => {
    setInformationDialog.toTrue();
    setBilling(item?.id);
  };

  const handleCloseInformation = () => {
    setInformationDialog.toFalse();
  };

  const formattedComponents = (billings || []).map((b) => {
    return {
      ...b,
      expiration: formatExpiration(b.created_at, b.expires_at),
    };
  });

  const handleDownloadBillingRelatory = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(billings));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioBilling${now}.xlsx`);
  };

  useEffect(() => {}, [formattedComponents]);

  const filteredBillings = formattedComponents.filter((b) => {
    const lowerId = b.id?.toLowerCase();
    const lowerInvoiceNumber = b.invoice?.invoice_number?.toLowerCase();
    const lowerValue = b.expiration?.value?.toLowerCase();

    return (
      lowerId?.includes(search) ||
      lowerValue?.includes(search) ||
      lowerInvoiceNumber?.includes(search)
    );
  });

  useEffect(() => {
    console.log('Filtered Billings: ', filteredBillings);
  }, [filteredBillings]);

  return (
    <>
      <PageTitle>Consultar Billing</PageTitle>
      <Grid className={classes.container} spacing={2} container>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            fullWidth
            onChange={handleChange}
            value={search}
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button onClick={handleDownloadBillingRelatory} fullWidth>
            Exportar relatório
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={columns}
            // actionsLabel={<VisibilityIcon />}
            // onActionsClick={handleOpenInformation}
            loading={loadingBillings}
            emptyMessage='Nenhum registro encontrado.'
            striped
            data={filteredBillings}
            // maxHeight={400}
          >
            {filteredBillings?.map((billing) => {
              const { color, value } = billing.expiration;
              return (
                <TableRow key={billing.id}>
                  <TableCell>{billing.id}</TableCell>
                  <TableCell>{billing.invoice.invoice_number}</TableCell>
                  <TableCell>
                    <p className={classes.expirationCell} style={{ color }}>
                      {value}
                    </p>
                  </TableCell>
                  <TableCell align='center'>
                    <IconButton color='primary' onClick={(e) => handleOpenInformation(e, billing)}>
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Grid>
        <Grid item xs={6}>
          <Typography variant='overline' className={classes.title} gutterBottom>
            Legenda
          </Typography>
          <div>
            <section className={classes.sectionContainer}>
              <div className={classes.legend1} />
              <p className={classes.textLegend}> - Billing dentro do prazo</p>
            </section>
            <section className={classes.sectionContainer}>
              <div className={classes.legend2} />
              <p className={classes.textLegend}> - Billing com a metade do prazo</p>
            </section>
            <section className={classes.sectionContainer}></section>

            <section className={classes.sectionContainer}>
              <div className={classes.legend4} />
              <p className={classes.textLegend}> - Billing prestes a expirar</p>
            </section>
          </div>
        </Grid>
      </Grid>
      <BillingInformation
        open={informationDialog}
        handleClose={handleCloseInformation}
        billingInfos={informationDialogContent}
      />
    </>
  );
};
