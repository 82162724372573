import React from 'react';
import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';

import { Table, FormatDate, Text, Button } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { useQuery } from 'hooks/useQuery';
import { budgetService } from 'services/budget';
import XLSX from 'xlsx';

import { useStyles } from './styles';

const columns = [{ label: 'Service' }, { label: 'Valor orçamento' }, { label: 'Data', width: 80 }];

export const TableFinished = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1);

  const [budgets, , loadingBudgets, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsByFinished(startIndex, rowsPerPage, search.trim()),
    [startIndex, rowsPerPage, search],
  );

  console.log('budgets', budgets);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setStartIndex(1);
    setPage(0);
  };

  const handleDownloadBudgetFinishedRelatory = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(budgets));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioOrçamentoFinalizados${now}.xlsx`);
  };

  const handleSearchChange = (event) => {
    setPage(0);
    setStartIndex(1);
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <>
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            variant='outlined'
            name='search'
            size='small'
            endAdornment={<SearchIcon />}
            label='Pesquisar'
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button onClick={handleDownloadBudgetFinishedRelatory} fullWidth>
            Exportar relatório
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Table
            headers={columns}
            striped
            loading={loadingBudgets}
            emptyMessage='Nenhum registro encontrado.'
            data={budgets}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            disableNumeration
          >
            {budgets?.map((budget) => (
              <TableRow key={budget?.id}>
                <TableCell className={`${classes.statusCell} status-${budget.status_id}`}>
                  <Typography className={classes.serviceNumber}>{budget?.service}</Typography>
                  <Typography className={classes.status}>{budget?.status?.description}</Typography>
                </TableCell>
                <TableCell>
                  <FormatMoney>
                    {budget?.preconegociacao != null
                      ? budget?.preconegociacao
                      : budget?.valorfinalorcamento}
                  </FormatMoney>
                </TableCell>
                <TableCell>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{budget.dataorcamento}</FormatDate>
                </TableCell>
              </TableRow>
            ))}
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50, 100, 200]}
            component='div'
            count={budgets?.[0]?.totalrow}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage='Linhas por página:'
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : ''}`
            }
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
    </>
  );
};
