import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_GRADES } from 'services/graphql/query/grade';
import {
  CREATE_STOCK_ADDRESS,
  CREATE_STOCK_ADDRESS_BY_FILE,
  LIST_STOCK_LOCATIONS,
} from 'services/graphql/query/stock-address';

import { ImportLocations } from './components/ImportLocations';
import { StockLocationTable } from './components/StockLocationTable';
import { validationSchema } from './validation';

export function StockLocations() {
  const locations = useQuery(LIST_STOCK_LOCATIONS);
  const grades = useQuery(LIST_GRADES);
  const inputFileRef = useRef(null);
  const [importing, setImporting] = useState(false);

  const [submit] = useMutation(CREATE_STOCK_ADDRESS, {
    refetchQueries: [{ query: LIST_STOCK_LOCATIONS }],
    onCompleted: () => {
      toast.success('Local salvo com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar o local';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [submitByFile] = useMutation(CREATE_STOCK_ADDRESS_BY_FILE, {
    refetchQueries: [{ query: LIST_STOCK_LOCATIONS }],
    onCompleted: () => {
      toast.success('Locais salvos com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao salvar os locais';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const gradeOptions = (grades?.data?.listGrades || []).map((object) => ({
    value: object.id,
    label: object.description,
  }));

  async function onSubmit(values, { setSubmitting, resetForm }) {
    setSubmitting(true);
    try {
      values.maxAmount = parseInt(values.maxAmount);
      await submit({
        variables: {
          data: values,
        },
      });

      resetForm();
    } catch (error) {
      toast.error('Erro ao salvar o local');
    } finally {
      setSubmitting(false);
    }
  }

  const handleFileChange = (e) => {
    setImporting(true); // Começar o loading
    toast.info('Importando planilha...');
    try {
      const file = e.target.files[0];

      if (!file.name.match('.csv')) {
        toast.error('Adicione um arquivo do tipo CSV');
        setImporting(false); // Parar o loading em caso de erro
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const result = event.target.result;

          const lines = result
            .split('\n')
            .map((line) => line.trim())
            .filter((line) => line.length > 0);

          const [header, ...dataLines] = lines;

          const products = dataLines
            .map((line) => {
              console.log('line ==>', line);
              const values = line.split(';');
              console.log('values ==>', values);
              if (
                values[0] == null ||
                values[1] == null ||
                values[2] == null ||
                values[3] == null ||
                values[4] == null ||
                values[5] == null
              ) {
                return null;
              }

              return {
                street: values[0],
                block: values[1],
                floor: values[2],
                location: values[3],
                grade: values[4],
                maxAmount: values[5],
              };
            })
            .filter((item) => item !== null);
          console.log('products ==>', products);
          await submitByFile({
            variables: { data: products },
          });
        } catch (err) {
          toast.error('Erro ao importar, tente novamente!');
          setImporting(false); // Parar o loading em caso de erro
        }
      };
      reader.readAsText(file);
    } catch (err) {
      toast.error('Erro ao importar, tente novamente!');
      setImporting(false); // Parar o loading em caso de erro
    } finally {
      handleReset();
      setImporting(false);
    }
  };

  const handleReset = () => {
    inputFileRef.current.value = '';
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Locais do Estoque</PageTitle>

      <Formik
        initialValues={{
          street: 'R',
          block: 'BL',
          floor: 'AD',
          location: '',
          gradeId: '',
          maxAmount: 1,
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item sm={12} lg={1}>
                <Field
                  name='street'
                  label='Rua'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item sm={12} lg={1}>
                <Field
                  name='block'
                  label='Bloco'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item sm={12} lg={1}>
                <Field
                  name='floor'
                  label='Andar'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  inputProps={{ maxLength: 3 }}
                />
              </Grid>
              <Grid item sm={12} lg={1}>
                <Field
                  name='location'
                  label='Local'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                  inputProps={{ maxLength: 2 }}
                />
              </Grid>
              <Grid item sm={12} lg={1}>
                <Field
                  label='Grade do Bloco'
                  name='gradeId'
                  size='small'
                  options={gradeOptions}
                  component={Select}
                  loading={grades?.loading}
                />
              </Grid>
              <Grid item sm={12} lg={2}>
                <Field
                  label='Quantidade Máxima de Produtos'
                  name='maxAmount'
                  size='small'
                  variant='outlined'
                  options={gradeOptions}
                  component={Text}
                  inputProps={{ type: 'number' }}
                />
              </Grid>
              <Grid item sm={12} lg={2}>
                <Button type='submit' fullWidth>
                  Cadastrar endereço
                </Button>
              </Grid>
              <Grid item sm={12} lg={2}>
                <ImportLocations handleImportClick={handleImportClick} loading={importing} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Spacer size={64} />

      <StockLocationTable data={locations?.data} loading={locations?.loading} />
    </>
  );
}
