import React, { memo } from 'react';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';

import { Button } from 'components';
import { truncateText } from 'helpers/truncateText';

import { useStyles } from './styles';

const FileListComponent = ({ files, handleRemoveFile, handleClean, handleChooseFileClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ul className={classes.fileList}>
        {Array.from(files).map((file, index) => (
          <li className={classes.fileItem} key={file.name}>
            <DescriptionIcon className={classes.fileIcon} color='primary' />

            {truncateText(file.name || '', 40)}

            <IconButton
              className={classes.fileActionBtn}
              // onClick={(e) => handleRemoveFile(e, index)}
              size='small'
            >
              <CloseIcon color='action' />
            </IconButton>
          </li>
        ))}
      </ul>
      <div className={classes.actions} spacing={2}>
        {/* <Button variant='text' size='small' onClick={handleClean}>
          Limpar
        </Button> */}
        <Button size='small' onClick={handleChooseFileClick}>
          Escolher
        </Button>
      </div>
    </div>
  );
};

export const FileList = memo(FileListComponent);
