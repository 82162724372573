export const addPnToCartAction = (part_number, dispatch) => {
  dispatch({ type: '@ADD_PN_TO_CART', part_number });
};

export const removePnFromCartAction = (part_number, dispatch) => {
  dispatch({ type: '@REMOVE_PN_FROM_CART', part_number });
};

export const updateAmountPnAction = (amountAndPnId, dispatch) => {
  dispatch({ type: '@UPDATE_PN_AMOUNT', amountAndPnId });
};

export const cleanCartAction = (dispatch) => {
  dispatch({ type: '@CLEAN_CART', cartCleaned: [] });
};
