import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    borderRadius: 8,
  },
  title: {
    fontWeight: 700,
  },
  buttonsContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'end',
  },
}));
