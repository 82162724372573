import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation, useQuery } from '@apollo/client';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { Button, Text } from 'components';
import { Formik, Form } from 'formik';
import { LIST_GRADES } from 'services/graphql/query/grade';
import { LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR } from 'services/graphql/query/product';
import { STOCK_REPORT } from 'services/graphql/query/reports';
import { LIST_STATUS } from 'services/graphql/query/status';
import XLSX from 'xlsx';

import { WarehouseStockContext } from '..';

export function StockHeader() {
  const { search, handleSearchChange, setSelectedStatus, setSelectedGrade, setSelectedSku } =
    useContext(WarehouseStockContext);

  const [openStatus, setOpenStatus] = useState(false);
  const [openGrade, setOpenGrade] = useState(false);
  const [openSku, setOpenSku] = useState(false);

  const status = useQuery(LIST_STATUS);
  const grade = useQuery(LIST_GRADES);
  const sku = useQuery(LIST_PRODUCT_INCLUDED_BRAND_MODEL_STORAGE_COLOR);

  const [stockReport, { loading }] = useMutation(STOCK_REPORT, {
    onCompleted: (data) => {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data?.stockReport));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          warehouse: worksheet,
        },
        SheetNames: ['warehouse'],
      });
      XLSX.writeFile(workbook, `Relatório_Estoque.xlsx`);
      toast.success('Relatório gerado com sucesso');
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao gerar o relatório!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const statusOptions = (status?.data?.listStatus || []).map((item) => ({
    value: item?.id,
    label: item?.description,
  }));

  const gradeOptions = (grade?.data?.listGrades || []).map((item) => ({
    value: item?.id,
    label: item?.description,
  }));

  const skuOptions = (sku?.data?.listProduct || []).map((item) => ({
    value: item?.sku,
    label: item?.sku,
  }));

  async function handleDownloadReport() {
    await stockReport();
  }

  return (
    <Formik>
      {(props) => (
        <>
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={3}>
                <Text
                  label='Pesquisar'
                  size='small'
                  variant='outlined'
                  endAdornment={<SearchIcon />}
                  value={search}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item sm={12} lg={2}>
                <Autocomplete
                  size='small'
                  multiple
                  id='autocomplete-sku'
                  open={openSku}
                  onOpen={() => setOpenSku(true)}
                  onClose={() => setOpenSku(false)}
                  options={skuOptions}
                  loading={sku?.loading}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    const results = newValue.map((item) => item.value);
                    setSelectedSku(results);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Filtro de SKU'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {status?.loading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={2}>
                <Autocomplete
                  size='small'
                  multiple
                  id='autocomplete-status'
                  open={openStatus}
                  onOpen={() => setOpenStatus(true)}
                  onClose={() => setOpenStatus(false)}
                  options={statusOptions}
                  loading={status?.loading}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    const results = newValue.map((item) => item.value);
                    setSelectedStatus(results);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Filtro de status'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {status?.loading ? (
                              <CircularProgress color='inherit' size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={2}>
                <Autocomplete
                  size='small'
                  multiple
                  id='autocomplete-grade'
                  open={openGrade}
                  onOpen={() => setOpenGrade(true)}
                  onClose={() => setOpenGrade(false)}
                  options={gradeOptions}
                  loading={grade?.loading}
                  getOptionLabel={(option) => option.label}
                  onChange={(event, newValue) => {
                    const results = newValue.map((item) => item.value);
                    setSelectedGrade(results);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Filtro de Grade'
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {grade?.loading ? <CircularProgress color='inherit' size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} lg={3}>
                <Button fullWidth onClick={() => handleDownloadReport()} loading={loading}>
                  Baixar Relatório
                </Button>
              </Grid>
            </Grid>
          </Form>
        </>
      )}
    </Formik>
  );
}
