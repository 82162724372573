import React from 'react';

import { Spacer, Tabs } from 'components';

import { WaitingOracleInBound } from './components/WaitingOracleInBound';
import { WaitingOracleOutBound } from './components/WaitingOracleOutBound';

export function WaitingOracle() {
  return (
    <Tabs centered>
      <Tabs.Content label='Entrada no oracle' disablePadding>
        <Spacer size={50} />
        <WaitingOracleInBound />
      </Tabs.Content>
      <Tabs.Content label='Saída no oracle' disablePadding>
        <Spacer size={50} />
        <WaitingOracleOutBound />
      </Tabs.Content>
    </Tabs>
  );
}
