import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button, Dialog, FormatMoney, Table, Tabs } from 'components';
import { Form, Formik } from 'formik';

const columns = [
  { label: 'Description', align: 'center' },
  { label: 'Preço', align: 'center' },
  { label: 'Acessório incluso', align: 'center' },
];

export const ReflectInvoiceDialog = ({ open, handleClose, client = {}, invoice = {} }) => {
  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Produto: {invoice?.xprod}</Dialog.Title>
      <Dialog.Content>
        <Formik>
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Tabs gutterButtom>
                  {client ? (
                    <Tabs.Content label='Cliente'>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Nome:</Typography>
                          <Typography>{client?.name ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Cgc:</Typography>
                          <Typography>{client?.cgc ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>E-mail:</Typography>
                          <Typography>{client?.email ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Telefone:</Typography>
                          <Typography>{client?.phone_number ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Cidade:</Typography>
                          <Typography>{client?.addresses[0]?.city ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Estado:</Typography>
                          <Typography>{client?.addresses[0]?.state ?? '--'}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant='subtitle2'>Bairo:</Typography>
                          <Typography>{client?.addresses[0]?.district ?? '--'}</Typography>
                        </Grid>
                      </Grid>
                    </Tabs.Content>
                  ) : (
                    <></>
                  )}

                  <Tabs.Content label='Produto'>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Código do Produto:</Typography>
                        <Typography>{invoice?.cprod}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Descrição:</Typography>
                        <Typography>{invoice?.xprod}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>IMEI:</Typography>
                        <Typography>{invoice?.product?.imei}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>EAN:</Typography>
                        <Typography>{invoice?.product?.ean}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>MPC:</Typography>
                        <Typography>
                          {invoice?.product?.mpc ? invoice?.product?.mpc : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Lacre:</Typography>
                        <Typography>
                          {invoice?.product?.lacre ? invoice?.product?.lacre : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Observação:</Typography>
                        <Typography>
                          {invoice?.product?.observation ? invoice?.product?.observation : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2'>Produto Incluso:</Typography>
                        {!invoice?.product?.is_missing ? (
                          <CheckCircleIcon style={{ color: '#17C13E' }} />
                        ) : (
                          <CancelIcon style={{ color: '#D9534F' }} />
                        )}
                      </Grid>
                    </Grid>
                  </Tabs.Content>
                  <Tabs.Content label='Acessórios'>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Table headers={columns} striped disableNumeration>
                          {invoice?.accessories?.map((accessorie) => (
                            <TableRow key={accessorie?.id}>
                              <TableCell align='center'>
                                {accessorie?.accessories?.[0]?.description}
                              </TableCell>
                              <TableCell align='center'>
                                <FormatMoney>{accessorie?.accessories?.[0]?.price}</FormatMoney>
                              </TableCell>
                              <TableCell align='center'>
                                {!accessorie?.is_missing ? (
                                  <CheckCircleIcon style={{ color: '#17C13E' }} />
                                ) : (
                                  <CancelIcon style={{ color: '#D9534F' }} />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </Table>
                      </Grid>
                    </Grid>
                  </Tabs.Content>
                  <Tabs.Content label='Ordem de Serviço'>
                    {!invoice?.service_order ? (
                      <>
                        <Typography variant='h6'>
                          Produto não possuí ordem de serviço aberta
                        </Typography>
                      </>
                    ) : (
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>ORDEM DE SERVIÇO:</Typography>
                          <Typography>{invoice?.service_order?.number}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>OBJECT IN:</Typography>
                          <Typography>{invoice?.service_order?.object_in}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>PART-NUMBER:</Typography>
                          <Typography>{invoice?.service_order?.service_part_number}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>EAN:</Typography>
                          <Typography>{invoice?.service_order?.ean}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>MODELO:</Typography>
                          <Typography>{invoice?.service_order?.model}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>SERIAL:</Typography>
                          <Typography>{invoice?.service_order?.serial_number}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>IMEI:</Typography>
                          <Typography>{invoice?.service_order?.imei}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>IMEI 2:</Typography>
                          <Typography>{invoice?.service_order?.imei}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>DEFEITO:</Typography>
                          <Typography>{invoice?.service_order?.device_defect}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>TIPO DE PROCESSO:</Typography>
                          <Typography>
                            {invoice?.service_order?.process_type_id === 2
                              ? 'Varejo'
                              : 'Logística Reversa'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>Garantia:</Typography>
                          <Typography>
                            {invoice?.service_order?.warranty_type === 'In Warranty'
                              ? 'Em Garantia'
                              : 'Fora de Garantia'}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>COR DO PRODUTO:</Typography>
                          <Typography>{invoice?.service_order?.color_product}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant='subtitle2'>INFORMAÇÃO ADICIONAL:</Typography>
                          <Typography>{invoice?.service_order?.add_info}</Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Tabs.Content>
                </Tabs>
                <DialogActions>
                  <Button autoFocus onClick={handleClose} color='primary'>
                    Fechar
                  </Button>
                </DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
