import React from 'react';
import { useRouteMatch } from 'react-router';

import WarrantyValidation from 'pages/Private/Warranty/WarrantyValidation';

import { Route } from '../Route';

export const WarrantyRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/warranty-validation`} component={WarrantyValidation} />
    </>
  );
};
