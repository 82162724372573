import React, { useEffect, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { Spacer, Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services/serviceOrder';
import XLSX from 'xlsx';

export const PartsHoldingReport = () => {
  // const today = new Date();

  // const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  // const [outputData, setOutputData] = useState(new Date());

  const [partsHoldingData, setPartsHolding] = useState([]);

  const [partsHoldingReport, , loadingObject, refetchPartsHoldingDataDate] = useQuery(
    () => serviceOrderService.listPartsHoldingReport(),
    [],
  );

  useEffect(() => {
    setPartsHolding(partsHoldingReport);
  }, [partsHoldingReport]);

  const handleDownloadGeneralStockRelatory = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(partsHoldingData));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatorioAguardandoCMP${now}.xlsx`);

    refetchPartsHoldingDataDate();
  };

  return (
    <>
      <PageTitle>Relatório de aguardando CMP</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Button loading={loadingObject} fullWidth onClick={handleDownloadGeneralStockRelatory}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>
      <Spacer size={15} />
      {/* <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
        }}
      >
        {(props) => (
          <Form>
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='input_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de entrada'
                component={DatePicker}
              />
            </Grid>
            <Spacer size={15} />
            <Grid item xs={12} lg={6}>
              <Field
                size='small'
                name='output_data'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                label='Data de saída'
                component={DatePicker}
              />
            </Grid>

            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik> */}
    </>
  );
};
