import React, { createContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Spacer } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_SERVICES } from 'services/graphql/query/service-orders';

import { StockHeader } from './components/StockHeader';
import { StockTable } from './components/StockTable';

export const WarehouseStockContext = createContext();

export function WarehouseStockResume() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);

  const { data, loading, refetch } = useQuery(LIST_SERVICES, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      search: search,
      status: selectedStatus,
      grade: selectedGrade,
      sku: selectedSku,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
    setPage(0);
  };

  return (
    <WarehouseStockContext.Provider
      value={{
        data,
        loading,
        refetch,
        handleChangePage,
        handleChangeRowsPerPage,
        handleSearchChange,
        totalCount: data?.listServices?.totalCount,
        page,
        rowsPerPage,
        search,
        selectedStatus,
        setSelectedStatus,
        selectedGrade,
        setSelectedGrade,
        setSelectedSku,
      }}
    >
      <PageTitle>Estoque Resumido</PageTitle>

      <StockHeader />

      <Spacer size={54} />

      <StockTable />
    </WarehouseStockContext.Provider>
  );
}
