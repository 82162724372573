import React from 'react';

import { PageTitle } from 'pages/Layout/PageTitle';

import { DockForm } from './components/DockForm';

export function Dock() {
  return (
    <>
      <PageTitle>Doca</PageTitle>
      <DockForm />
    </>
  );
}
