import React, { useEffect } from 'react';

// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import Grid from '@material-ui/core/Grid';

import { FormatDate, FormatMoney } from 'components';
import { useQuery } from 'hooks/useQuery';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { xpcellService } from 'services';

import useStyles from './styles';

export const BudgetSacGraphic = () => {
  const widths = 900;
  const heights = 500;
  const classes = useStyles();

  const [sacData, , loadingSacData, refetch] = useQuery(
    () => xpcellService.listBudgetSacReport(),
    [],
  );

  const graphicOne = (sacData || []).map((datas, index) => ({
    nome: datas?.atendente,
    porcentagem: Number(datas?.porcentagem),
    Aprovados: datas?.qtdeAprovada,
    Total: datas?.qtdeOrcamentosAtendente,
    porcentagemString: String(datas?.porcentagem) + '%',
  }));

  const graphicTwo = (sacData || []).map((datas, index) => ({
    nome: datas?.atendente,
    valor: datas?.valor,
    valorString: 'R$ ' + new Intl.NumberFormat('pt-BR', 'BRL').format(datas?.valor),
  }));

  const renderCustomizedLabel = (props) => {
    console.log('Entrei no props');
    const { x, y, width, height, value } = props;
    const radius = 10;

    return (
      <g>
        <text
          className={classes.graphicText}
          x={x + width / 2}
          y={y - radius}
          fill='#000000'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {String(value)}
        </text>
      </g>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetch]);

  return (
    <Grid container spacing={4} className={classes.transform}>
      <Grid item xs={12} lg={12} className={classes.gaiaText}>
        <FormatDate format='dd/MM/yyyy'>{new Date()}</FormatDate>
      </Grid>
      {/* <Spacer size={24} /> */}
      <Grid item xs={12} lg={12} className={classes.gaiaText}>
        {`Total Aprovados: `}
        <FormatMoney>{sacData?.[0]?.valorTotalOrcamentos}</FormatMoney>
      </Grid>
      <Grid item xs={12} lg={12}>
        {' '}
        <p className={classes.gaia}>ATENDENTES SAC</p>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.graphicLine}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart width={widths} height={heights} data={graphicOne}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='nome' />
            <YAxis domain={[0, 'dataMax + 25']} />
            <Tooltip />
            <Legend />
            <Bar dataKey='Aprovados' fill='#0dab05' minPointSize={5}>
              <LabelList dataKey='porcentagemString' content={renderCustomizedLabel} />
            </Bar>
            <Bar dataKey='Total' fill='#de14cd' minPointSize={10}>
              <LabelList dataKey='Total' content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
      <Grid item xs={12} lg={6} className={classes.graphicLine}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart width={widths} height={heights} data={graphicTwo}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='nome' />
            <YAxis domain={[0, 'dataMax + 500']} />
            <Tooltip />
            <Legend />
            <Bar dataKey='valor' fill='#006aff' minPointSize={5}>
              <LabelList dataKey='valorString' content={renderCustomizedLabel} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
