import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Table } from 'components';
import { FormatDate } from 'components/FormatDate';
import { Formik, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { serviceOrderService } from 'services/serviceOrder';

import { useStyles } from './styles';

const columns = [
  { label: 'Service' },
  { label: 'Data de criação/atribuição' },
  { label: 'Status' },
  { label: 'SVC Type' },
];

export const TableFinisheds = () => {
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [serviceOrder, , loadingServiceOrders] = useQuery(
    () => serviceOrderService.getServiceOrderRh(),
    [],
  );

  const filteredServiceOrders = (serviceOrder || []).filter((serviceOrders) => {
    const loweredSearch = search.toLowerCase();

    return (
      serviceOrders.number?.toLowerCase().includes(loweredSearch) ||
      serviceOrders.created_at?.toLowerCase().includes(loweredSearch)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  return (
    <Formik initialValues={{ search_os: '' }}>
      <>
        <Grid className={classes.root} container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Field
              size='small'
              fullWidth
              endAdornment={<SearchIcon />}
              name='search_os'
              variant='outlined'
              label='Consultar OS'
              component={Text}
              onChange={handleSearchChange}
            />
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} lg={12}>
            <Table
              headers={columns}
              loading={loadingServiceOrders}
              emptyMessage='Nenhum registro encontrado.'
              striped
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredServiceOrders?.map((serviceOrder) => (
                <TableRow key={serviceOrder?.id}>
                  <TableCell>{serviceOrder?.number}</TableCell>
                  <TableCell>
                    <FormatDate format='dd/MM/yyyy HH:mm:ss'>{serviceOrder?.created_at}</FormatDate>
                  </TableCell>
                  <TableCell>{serviceOrder?.status_description}</TableCell>
                  <TableCell>{serviceOrder?.svc_type}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Grid>
        </Grid>
      </>
    </Formik>
  );
};
