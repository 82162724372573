import React from 'react';
import { useRouteMatch } from 'react-router';

import { BudgetSacGraphic } from 'pages/Private/Collaborators/BudgetSacGraphic';
import { PitziAgingReport } from 'pages/Private/Collaborators/PitziAgingReport';
import { SearchPriceSamsung } from 'pages/Private/Collaborators/SearchPriceSamsung';

import { Route } from '../Route';

export const CollaboratorsRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${url}/report-aging`} component={PitziAgingReport} />
      <Route isPrivate path={`${url}/graphic-budget-sac`} component={BudgetSacGraphic} />
      <Route isPrivate path={`${url}/search-price-samsung`} component={SearchPriceSamsung} />
    </>
  );
};
