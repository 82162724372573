import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation, useQuery } from '@apollo/client';
import { Button, Select, Spacer, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { LIST_ALL_ORDERS_FOR_EXPEDITION } from 'services/graphql/query/b2c';
import { MINUTE_TAG } from 'services/graphql/query/pdf';

import { WarehouseExpeditionTable } from './components/WarehouseExpeditionTable';

const pickingOptions = [
  { value: 'b2b', label: 'B2B' },
  { value: 'b2c', label: 'B2C' },
];

export const WarehouseExpeditionContext = createContext();

export function WarehouseExpedition() {
  const [selectedPicking, setSelectedPicking] = useState('');
  const [search, setSearch] = useState('');
  const [selectedItems, setSelectedItems] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  // const [isDialogOpen, setDialogOpen] = useState(false);

  const b2cForPicking = useQuery(LIST_ALL_ORDERS_FOR_EXPEDITION, {
    skip: !selectedPicking || selectedPicking !== 'b2c',
    fetchPolicy: 'network-only',
  });

  const [generateMinutePdf] = useMutation(MINUTE_TAG, {
    onCompleted: (data) => {
      const pdfBase64 = data?.minuteTag || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'danfe.pdf';
      link.click();
      toast.success('Minuta gerada com sucesso');
      b2cForPicking.refetch();
      setSelectedIds([]);
    },
    onError: (error) => {
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao gerar a minuta';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  // const handleValidationComplete = (validatedKeys) => {
  //   setInvoiceKeys(validatedKeys);
  //   generateMinutePdf({ variables: { anymarketIds: selectedIds } });
  // };

  const handleSubmit = () => {
    if (selectedIds.length > 0) {
      generateMinutePdf({ variables: { anymarketIds: selectedIds } });
    } else {
      toast.error('Selecione ao menos um item para gerar a minuta');
    }
  };

  return (
    <>
      <PageTitle>Expedição</PageTitle>

      <Formik initialValues={{ picking: '', search: '' }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                <Field
                  name='picking'
                  component={Select}
                  label='Tipo de Picking'
                  options={pickingOptions}
                  value={setSelectedPicking}
                  onChange={(e) => setSelectedPicking(e)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Field
                  label='Pesquisar'
                  name='search'
                  size='small'
                  variant='outlined'
                  endAdornment={<SearchIcon />}
                  value={search}
                  onChange={handleSearchChange}
                  component={Text}
                  disabled={!selectedPicking}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                <Button fullWidth disabled={selectedIds.length <= 0} onClick={handleSubmit}>
                  Gerar minuta dos itens selecionados
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Spacer size={42} />

      {selectedPicking && (
        <WarehouseExpeditionContext.Provider
          value={{
            b2cOrders: b2cForPicking?.data?.listAllOrdersForExpedition ?? [],
            b2cLoading: b2cForPicking?.loading ?? false,
            b2cRefetch: b2cForPicking.refetch,
            search: search,
            setSelectedIds,
            selectedIds,
            selectedItems,
            setSelectedItems,
          }}
        >
          {selectedPicking === 'b2c' && (
            <>
              <WarehouseExpeditionTable />
              {/* <WarehouseExpeditionConfirmInvoiceKey
                isOpen={isDialogOpen}
                handleClose={() => setDialogOpen(false)}
                selectedItems={(selectedItems || []).map((item) => ({
                  anymarketId: item?.anymarketId,
                  keys: item?.B2CInvoices?.key,
                  orderCode: item?.anymarketId,
                }))}
              /> */}
            </>
          )}
        </WarehouseExpeditionContext.Provider>
      )}
    </>
  );
}
