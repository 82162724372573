import React, { createContext, useState } from 'react';
import { toast } from 'react-toastify';

import { useMutation, useQuery } from '@apollo/client';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Dialog, Spacer } from 'components';
import { CREATE_B2C_BOX, FINISH_B2C_PICKING, LIST_B2C_BOXS } from 'services/graphql/query/b2c';

import { BoxCardList } from './components/BoxCardList';
import { IncludeItem } from './components/IncludeItem';

export const CreateBoxDialogContext = createContext();

export function CreateBoxDialog({ isOpen, handleClose, item }) {
  if (!isOpen) {
    return <></>;
  }

  const [inCollect, setInCollect] = useState(false);
  const [showIncludeForm, setShowIncludeForm] = useState(true);
  const [boxId, setBoxId] = useState();

  const { data, refetch } = useQuery(LIST_B2C_BOXS, {
    variables: { headerId: item.anymarketId },
    skip: !item,
    fetchPolicy: 'network-only',
    pollInterval: 1000,
  });

  const [createB2CBox] = useMutation(CREATE_B2C_BOX, {
    onCompleted: () => {
      toast.success('Box criada com sucesso');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao criar uma box';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [finishB2CPicking] = useMutation(FINISH_B2C_PICKING, {
    onCompleted: () => {
      toast.success('Pedido finalizado com sucesso, enviado para aguadando nota fiscal');
      handleClose();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao finalizado o pedido';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='md'>
      <Dialog.Title onClose={handleClose}>Montar Box</Dialog.Title>

      <Dialog.Content>
        {!inCollect && (
          <Button
            fullWidth
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => createB2CBox({ variables: { headerId: item.anymarketId } })}
          >
            Nova Box
          </Button>
        )}
        <CreateBoxDialogContext.Provider
          value={{
            data: data?.listB2CBoxs ?? [],
            refetch,
            onContinue: () => setInCollect(true),
            setBoxId,
            boxId,
            onBack: () => [setInCollect(false), setShowIncludeForm(true)],
            setShowIncludeForm,
            showIncludeForm,
          }}
        >
          {data?.listB2CBoxs.length > 0 && !inCollect && (
            <>
              <Spacer size={32} />

              <BoxCardList />

              <Spacer size={32} />

              <Button
                fullWidth
                color='secondary'
                startIcon={<CheckCircleOutlineIcon />}
                onClick={() => finishB2CPicking({ variables: { headerId: item.anymarketId } })}
              >
                Finalizar montagem
              </Button>
            </>
          )}

          {inCollect && <IncludeItem />}
        </CreateBoxDialogContext.Provider>
      </Dialog.Content>
    </Dialog>
  );
}
