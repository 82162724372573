import React from 'react';

import { Tabs } from 'components';

import { TableFinisheds } from './TableFisisheds';

export const GeneralTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Relação de services em RH' disablePadding>
        <TableFinisheds />
      </Tabs.Content>
    </Tabs>
  );
};
