import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Table } from 'components';
import { Formik, Form } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services/api';
import { budgetService } from 'services/budget';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'N° Service', align: 'center' },
  { label: 'Cliente ', align: 'center' },
  { label: 'Modelo ', align: 'center' },
  { label: 'Valor final orçamento ', align: 'center' },
  { label: 'N° nota SWAP ', align: 'center' },
  { label: 'N° Chave nota SWAP ', align: 'center' },
  { label: 'Objeto SWAP ', align: 'center' },
];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const FinalizedSwap = () => {
  const classes = useStyles();

  const [batchDataOnly, setBillingDataOnly] = useState([]);

  useEffect(() => {
    setBillingDataOnly(batchDataOnly);
  }, [batchDataOnly]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [pendingList, , loadingpendingList] = useQuery(() => budgetService.listSwapFinalized(), []);

  const [batchDataOnlys2, , loadingbatchDataOnly2, batchRefetch2] = useQuery(
    () => api.get('/pll/listbatch'),
    [],
  );

  console.log('batchDataOnlys', batchDataOnlys2);

  useEffect(() => {
    setBillingDataOnly(batchDataOnly);
  }, [batchDataOnly]);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await api.post('/pll/create-batchitems', values);

      setBillingDataOnly(batchDataOnly);

      batchRefetch();
      toast.success('Sinistro adicionado ao lote com sucesso');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.message || 'Erro ao adicionar sinistro.');
    } finally {
      setSubmitting(false);
    }
  };

  const batchsOptions = (pendingList || []).map((batch) => ({
    value: batch.number + ' ' + '-' + ' ' + batch.name,
    label: batch.number,
  }));

  return (
    <>
      <PageTitle>SWAP Finalizados </PageTitle>
      <Formik
        initialValues={{ service_number: '', nf_number: '', nf_key: '', object_code: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={9}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Nenhum serviço encontrado.'
                    maxHeight={300}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {pendingList?.map((batch) => (
                      <TableRow key={batch.id}>
                        <TableCell align='center'>{batch.number}</TableCell>
                        <TableCell align='center'>{batch.name}</TableCell>
                        <TableCell align='center'>{batch.model}</TableCell>
                        <TableCell align='center'>{batch.final_budget_price}</TableCell>
                        <TableCell align='center'>{batch.nf_tradein}</TableCell>
                        <TableCell align='center'>{batch.nf_key_tradein}</TableCell>
                        <TableCell align='center'>{batch.object_tradein}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
