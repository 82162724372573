import React, { useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { StockRequisitionContext } from '../..';
import { useStyles } from './styles';

export const ConfirmPurchase = () => {
  const classes = useStyles();

  const { state } = useContext(StockRequisitionContext);

  return (
    <>
      <Grid container style={{ width: '100%', padding: '10px' }} spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper className={classes.accessoriesInCart} variant='outlined'>
            <Typography variant='h6'>Resumo do pedido:</Typography>
            <Grid
              container
              style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}
              spacing={2}
            >
              <Grid item xs={3}>
                <p>
                  {state.length} Produto(s){state.length > 1 ? 's' : ''}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Divider variant='middle' />
              </Grid>
            </Grid>
            {state.map((accessory) => (
              <Grid container alignItems='center' key={accessory.id}>
                <Grid item className={classes.infoAccessoryGrid + ' ' + 'info_acessory_grid'}>
                  <Typography variant='body2'>{accessory.description}</Typography>
                  <Typography variant='body2'>PART-NUMBER: {accessory.part_number}</Typography>
                  <Typography variant='body2' className={classes.infoAccessory}>
                    <Typography variant='body2' className={classes.quantity}>
                      QUANTIDADE:
                      <Typography variant='body2'>{accessory.amount}</Typography>
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
