import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  part_numbers: yup.array().of(yup.string().nullable()).nullable(),
  courtesy_part_numbers: yup.array().of(yup.string().nullable()).nullable(),
  technical_report: yup.string(),
  part_numbers_amount: yup.array().of(yup.object().nullable()).nullable(),
});

export const initialValues = {
  indexes: [],
  part_numbers: [],
  courtesy_indexes: [],
  courtesy_part_numbers: [],
  part_numbers_amount: [],
};
