/* eslint-disable react/no-unknown-property */
import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// eslint-disable-next-line import-helpers/order-imports
import WarningIcon from '@mui/icons-material/Warning';

// import SearchIcon from '@material-ui/icons/Search';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/testeImageUpload.svg';
import { Button, Select } from 'components';
import { Formik, Form, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService, invoiceService, serviceOrderService } from 'services';
import { api } from 'services/api';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { validationSchema } from './validation';

const data = XLSX.utils.json_to_sheet(
  [
    { S: 1, h: 2, e: 3, e_1: 4, t: 5, J: 6, S_1: 7 },
    { S: 2, h: 3, e: 4, e_1: 5, t: 6, J: 7, S_1: 8 },
  ],
  { skipHeader: 1 },
);

export const ImportStock = () => {
  const [importStock, setImportStock] = useState();
  const [isWarningDialog, setIsWarningDialog] = useState(false);
  const [componentLevelLocationId, setComponentLevelLocationId] = useState();
  const [locationId, setLocationId] = useState();

  const formikRef = useRef();

  const [additionalServicesProductsData, , loadingAdditionalServiceProducts] = useQuery(
    () => api.get('/budgets/additional-service-products'),
    [],
  );

  useEffect(() => {
    const noProductsTypeFound =
      !additionalServicesProductsData?.length && !loadingAdditionalServiceProducts;

    if (noProductsTypeFound) {
      setIsWarningDialog(true);
    }
  }, [additionalServicesProductsData, loadingAdditionalServiceProducts]);

  const [servicesOrders, setServicesOrders] = useState([]);

  const [serviceOrdersData, , loadingServiceOrdersData, refetchServiceOrdersData] = useQuery(
    serviceOrderService.listServiceOrderAccessories(),
    [],
  );

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const [stockLocations, , loadingStockLocations, stockLocationsRefetch] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(componentLevelLocationId),
    [componentLevelLocationId],
  );

  const stocklocationOptions = (stockLocations || []).map((stock) => ({
    value: stock.id,
    label: stock.location_name,
  }));

  const levelLocationsOptions = (levelLocations || []).map((stock) => ({
    value: stock.id,
    label: stock.description,
  }));

  const [importing, setImporting] = useState(false);
  useEffect(() => {
    setServicesOrders(serviceOrdersData);
  }, [serviceOrdersData]);

  const classes = useStyles();

  const handleDownloadLayoutServiceOrders = async () => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'Empresa',
          b: 'Nome Fantasia',
          c: 'Cidade',
          d: 'Bairro',
          e: 'Telefone',
          f: 'Estado',
          g: 'Rua',
          h: 'Número',
          i: 'País',
          j: 'CEP',
          k: 'CNPJ',
          l: 'IE',
          m: 'Chave da nota',
          n: 'Número da nota',
          o: 'Número de Serial da nota',
          p: 'Data de emissão da nota',
          q: 'Propósito da nota',
          r: 'Data de recebimento da nota',
          s: 'Número da nota',
          t: 'Billing',
          u: 'Expira em',
          v: 'PONo',
          w: 'Preço de compra',
          x: 'Linha',
          y: 'Part Number',
          z: 'Quantidade',
          aa: 'ID de licalização do componente',
          ab: 'ncm',
          ac: 'cprod',
          ad: 'cean',
          ae: 'xprod',
          af: 'cest',
          ag: 'exipi',
          ah: 'cfop',
          ai: 'ucom',
          aj: 'qcom',
          ak: 'vuncom',
          al: 'vprod',
          am: 'ceantrib',
          an: 'utrib',
          ao: 'qtrib',
          ap: 'vuntrib',
          aq: 'indtot',
          ar: 'xped',
          as: 'nitemped',
          at: 'icms_orig',
          au: 'icms_cst',
          av: 'icms_modbc',
          aw: 'icms_vbc',
          ax: 'icms_picms',
          ay: 'icms_vicms',
          az: 'icms_modbcst',
          ba: 'icms_vbcst',
          bb: 'icms_picmsst',
          bc: 'icms_vicmsst',
          bd: 'ipicenq',
          be: 'ipitrib_cst',
          bf: 'ipitrib_vbc',
          bg: 'ipitrib_pipi',
          bh: 'ipitrib_vipi',
          bi: 'pisaliq_cst',
          bj: 'pisaliq_vbc',
          bk: 'pisaliq_ppis',
          bl: 'pisaliq_vpis',
          bm: 'pisnt_cst',
          bn: 'cofinsaliq_cst',
          bo: 'cofinsaliq_vbc',
          bp: 'cofinsaliq_pcofins',
          bq: 'cofinsaliq_vcofins',
          br: 'confinsnt_cst',
          bs: 'icms_tot',
          bt: 'ipi_tot',
          bu: 'Descrição',
          bv: 'infnfe',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'LayoutImportStock.xlsx');
  };

  const inputFileRef = useRef(null);

  const handleFileChange = async (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getStock = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == '' ||
            obj.split(';')[0] == 'company_name' ||
            obj.split(';')[0] == 'Empresa'
          ) {
            return false;
          } else {
            const dados = {
              company_name: obj.split(';')[0],
              nickname: obj.split(';')[1],
              city: obj.split(';')[2],
              district: obj.split(';')[3],
              phone: obj.split(';')[4],
              state: obj.split(';')[5],
              street: obj.split(';')[6],
              street_number: obj.split(';')[7],
              country: obj.split(';')[8],
              zipcode: obj.split(';')[9],
              cnpj: obj.split(';')[10],
              ie: obj.split(';')[11],
              invoice_key: obj.split(';')[12],
              invoice_number: obj.split(';')[13],
              serial_number: obj.split(';')[14],
              emission_date: obj.split(';')[15],
              input_purpose_id: obj.split(';')[16],
              receipt_date: obj.split(';')[17],
              invoiceNo: obj.split(';')[18],
              billing_id: obj.split(';')[19],
              expires_at: obj.split(';')[20],
              PONo: obj.split(';')[21],
              purchase_price: obj.split(';')[22],
              row: obj.split(';')[23],
              part_number: obj.split(';')[24],
              quantity: obj.split(';')[25],
              component_level_location: obj.split(';')[26],
              ncm: obj.split(';')[27],
              cprod: obj.split(';')[28],
              cean: obj.split(';')[29],
              xprod: obj.split(';')[30],
              cest: obj.split(';')[31],
              extipi: obj.split(';')[32],
              cfop: obj.split(';')[33],
              ucom: obj.split(';')[34],
              qcom: obj.split(';')[35],
              vuncom: obj.split(';')[36],
              vprod: obj.split(';')[37],
              ceantrib: obj.split(';')[38],
              utrib: obj.split(';')[39],
              qtrib: obj.split(';')[40],
              vuntrib: obj.split(';')[41],
              indtot: obj.split(';')[42],
              xped: obj.split(';')[43],
              nitemped: obj.split(';')[44],
              icms_orig: obj.split(';')[45],
              icms_cst: obj.split(';')[46],
              icms_modbc: obj.split(';')[47],
              icms_vbc: obj.split(';')[48],
              icms_picms: obj.split(';')[49],
              icms_vicms: obj.split(';')[50],
              icms_modbcst: obj.split(';')[51],
              icms_vbcst: obj.split(';')[52],
              icms_picmsst: obj.split(';')[53],
              icms_vicmsst: obj.split(';')[54],
              ipicenq: obj.split(';')[55],
              ipitrib_cst: obj.split(';')[56],
              ipitrib_vbc: obj.split(';')[57],
              ipitrib_pipi: obj.split(';')[58],
              ipitrib_vipi: obj.split(';')[59],
              pisaliq_cst: obj.split(';')[60],
              pisaliq_vbc: obj.split(';')[61],
              pisaliq_ppis: obj.split(';')[62],
              pisaliq_vpis: obj.split(';')[63],
              pisnt_cst: obj.split(';')[64],
              cofinsaliq_cst: obj.split(';')[65],
              cofinsaliq_vbc: obj.split(';')[66],
              cofinsaliq_pcofins: obj.split(';')[67],
              cofinsaliq_vcofins: obj.split(';')[68],
              confinsnt_cst: obj.split(';')[69],
              description: obj.split(';')[72],
              infnfe: obj.split(';')[73],
            };

            return dados;
          }
        });

        const filteredStock = getStock.filter((stocks) => {
          if (stocks.infnfe == undefined || stocks.infnfe == null || stocks.infnfe == '') {
            return false;
          }
          return stocks;
        });

        let arrayFile = [];

        const stockFilter = Object.assign(filteredStock).map(async function (key) {
          const stock = {
            company_name: key.company_name,
            nickname: key.nickname,
            city: key.city,
            district: key.district,
            phone: key.phone.replace('phone_', ''),
            state: key.state,
            street: key.street,
            street_number: key.street_number,
            country: key.country,
            zipcode: key.zipcode,
            cnpj: key.cnpj.replace('cnpj_', ''),
            ie: key.ie.replace('ie_', ''),
            invoice_key: key.invoice_key.replace('Nfe', ''),
            invoice_number: key.invoice_number,
            serial_number: key.serial_number,
            emission_date: key.emission_date,
            input_purpose_id: key.input_purpose_id,
            receipt_date: key.receipt_date,
            invoiceNo: key.invoiceNo,
            billing_id: key.billing_id.replace('billing_', ''),
            expires_at: key.expires_at,
            PONo: key.PONo,
            purchase_price: parseFloat(key.purchase_price).toFixed(2),
            row: key.row,
            part_number: key.part_number,
            quantity: key.quantity,
            component_level_location_id: componentLevelLocationId,
            location_id: locationId,
            ncm: key.ncm,
            cprod: key.cprod,
            cean: key.cean,
            xprod: key.xprod,
            cest: key.cest,
            extipi: key.extipi,
            cfop: key.cfop,
            ucom: key.ucom,
            qcom: key.qcom,
            vuncom: key.vuncom,
            vprod: key.vprod,
            ceantrib: key.ceantrib,
            utrib: key.utrib,
            qtrib: key.qtrib,
            vuntrib: key.vuntrib,
            indtot: key.indtot,
            xped: key.xped,
            nitemped: key.nitemped,
            icms_orig: key.icms_orig,
            icms_cst: key.icms_cst,
            icms_modbc: key.icms_modbc,
            icms_vbc: key.icms_vbc,
            icms_picms: key.icms_picms,
            icms_vicms: key.icms_vicms,
            icms_modbcst: key.icms_modbcst,
            icms_vbcst: key.icms_vbcst,
            icms_picmsst: key.icms_picmsst,
            icms_vicmsst: key.icms_vicmsst,
            ipicenq: key.ipicenq,
            ipitrib_cst: key.ipitrib_cst,
            ipitrib_vbc: key.ipitrib_vbc,
            ipitrib_pipi: key.ipitrib_pipi,
            ipitrib_vipi: key.ipitrib_vipi,
            pisaliq_cst: key.pisaliq_cst,
            pisaliq_vbc: key.pisaliq_vbc,
            pisaliq_ppis: key.pisaliq_ppis,
            pisaliq_vpis: key.pisaliq_vpis,
            pisnt_cst: key.pisnt_cst,
            cofinsaliq_cst: key.cofinsaliq_cst,
            cofinsaliq_vbc: key.cofinsaliq_vbc,
            cofinsaliq_pcofins: key.cofinsaliq_pcofins,
            cofinsaliq_vcofins: key.cofinsaliq_vcofins,
            confinsnt_cst: key.confinsnt_cst,
            description: key.description,
            infnfe: key.infnfe.replace('Nfe', ''),
          };

          return arrayFile.push(stock);
        });

        await invoiceService
          .createStockByFile({
            stockComponents: arrayFile,
          })
          .then((response) => {
            toast.success(`${response.data.length} estoque importado com sucesso`);
            setImporting(false);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.message ||
                'Ocorreu um erro ao realizar a importação das services',
            );
            setImporting(false);
          })
          .finally(() => {
            setImporting(false);
          });
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
      setImporting(false);
    }
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Importar Estoque de Componentes por Planilha</PageTitle>
      <Formik
        initialValues={{
          component_level_location_id: '',
          location_id: '',
          user_id: 'ade35a94-85df-4896-a0e9-2ea556d929f5',
        }}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12}>
                  <Paper className={classes.gridPaper} variant='outlined'>
                    <Grid className={classes.contextPaper} container spacing={2}>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          onClick={handleImportClick}
                          type=''
                          disabled={importing || !props.dirty || !props.isValid}
                          startIcon={<FileUploadIcon />}
                        >
                          Importar planilha
                        </Button>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <Button
                          fullWidth
                          variant='outlined'
                          startIcon={<DownloadIcon />}
                          onClick={handleDownloadLayoutServiceOrders}
                          disabled={importing}
                        >
                          Baixar planilha
                        </Button>
                      </Grid>

                      {importing ? (
                        <Grid xs={6} lg={12} className={classes.loading}>
                          <Box sx={{ width: '90%' }}>
                            <LinearProgress color='secondary' />
                          </Box>
                        </Grid>
                      ) : (
                        <Grid className={classes.gridImages} xs={6} lg={12}>
                          {' '}
                          <UploadImg className={classes.imgTracker} />
                        </Grid>
                      )}
                      <Grid item xs={6} md={6} lg={6}>
                        <Field
                          size='small'
                          fullWidth
                          name='component_level_location_id'
                          variant='outlined'
                          label='Tipo de estoque'
                          emptyOption='Selecione'
                          onChange={(e) => setComponentLevelLocationId(e)}
                          loading={loadingLevelLocations}
                          options={levelLocationsOptions}
                          component={Select}
                          helperText='Selecione o tipo de estoque'
                        />
                      </Grid>

                      <Grid item xs={6} md={6} lg={6}>
                        <Field
                          name='location_id'
                          label='Local de Estoque'
                          variant='outlined'
                          size='small'
                          component={Select}
                          onChange={(e) => setLocationId(e)}
                          loading={loadingStockLocations}
                          options={stocklocationOptions}
                          helperText='Selecione o local de estoque'
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <WarningIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Máximo de importação por vez - 500
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <div className={classes.containerLegend}>
                    <PriorityHighIcon color='warning' />
                    <p variant='caption' className={classes.p}>
                      Esperar carregamento completo da importação.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
