import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    imgContainer: {
      display: 'block',
      width: '20%',
      maxWidth: '600px',
      margin: 'auto',
      paddingTop: '60px',
    },
    container: {
      paddingTop: '10px',
    },
    btnRelease: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };
});
