import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Select, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { componentService } from 'services';
import { api } from 'services/api';

import { validationSchema } from './validation';
const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};
export const Register = ({ setComponents }) => {
  const [name, setName] = useState('');
  const [hasBilling, setHasBilling] = useState(false);
  const [locations, , loadingLocations] = useQuery(() => api.get('/components/locations'), []);
  const [componentLevelLocation, setComponentLevelLocation] = useState();

  const [levelLocations, , loadingLevelLocations] = useQuery(
    componentService.listComponentLevelLocations,
    [],
  );

  const [stockLocations, , loadingStockLocations] = useQuery(
    () => componentService.listStockLocationsByLevelLocation(componentLevelLocation),
    [componentLevelLocation],
  );

  const disableBillingIfIsOrder = () => {
    if (hasBilling) {
      setHasBilling(false);
    } else {
      setHasBilling(true);
    }
  };

  const upperValue = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      values.part_number = values.part_number.trim();
      const { data } = await api.post('/components', values);

      toast.success('Componente cadastrado com sucesso!');

      setComponents((prevState) => [data, ...prevState]);
      resetForm();
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const locationsOptions = (locations || []).map((purpose) => ({
    value: purpose.id,
    label: purpose.description,
  }));

  const levelLocationsOptions = (levelLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.description,
  }));

  const stockLocationsOptions = (stockLocations || []).map((levelLocation) => ({
    value: levelLocation.id,
    label: levelLocation.location_name,
  }));

  return (
    <>
      <Formik
        initialValues={{
          part_number: '',
          description: '',
          component_location_id: '',
          quantity: 0,
          purchase_price: 0,
          billing_id: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={6} md={4} lg={2}>
                <Field
                  variant='outlined'
                  label='Part Number'
                  name='part_number'
                  size='small'
                  component={Text}
                  inputProps={{ maxLength: 50 }}
                  onInput={toInputUppercase}
                  onChange={(e) => {
                    upperValue(e);
                  }}
                />
              </Grid>
              {/* <Grid item xs={6} md={4} lg={2}>
                <Field
                  variant='outlined'
                  label={hasBilling ? 'Encomenda' : 'Billing'}
                  name='billing_id'
                  disabled={hasBilling}
                  size='small'
                  mask='number'
                  component={Text}
                />
              </Grid> */}
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  variant='outlined'
                  fullWidth
                  mask='number'
                  label='Quantidade'
                  name='quantity'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Field
                  size='small'
                  name='purchase_price'
                  mask='money'
                  variant='outlined'
                  label='Preço de compra'
                  component={Text}
                  inputProps={{
                    min: 1,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field
                  variant='outlined'
                  label='Tipo de estoque'
                  name='component_level_location_id'
                  size='small'
                  component={Select}
                  location={loadingLevelLocations}
                  options={levelLocationsOptions}
                  onChange={(e) => setComponentLevelLocation(e)}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={3}>
                <Field
                  variant='outlined'
                  label='Local de estoque'
                  name='location_id'
                  size='small'
                  component={Select}
                  location={loadingStockLocations}
                  options={stockLocationsOptions}
                />
              </Grid>
              {/* <Grid item xs={12} md={4} lg={4}>
                <Field
                  variant='outlined'
                  label='Local'
                  name='component_location_id'
                  size='small'
                  component={Text}
                />
              </Grid> */}

              <Grid item xs={12} md={8} lg={4}>
                <Field
                  variant='outlined'
                  label='Descrição'
                  name='description'
                  size='small'
                  component={Text}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  onInput={toInputUppercase}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <div style={{ display: 'flex', margin: 'auto', alignItems: 'center' }}>
                  <Checkbox color='primary' onClick={disableBillingIfIsOrder} />
                  <Typography style={{ fontSize: '15px' }}>Componente para encomenda</Typography>
                </div>
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Button
                  type='submit'
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                  fullWidth
                  onClick={props.handleSubmit}
                >
                  Cadastrar
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
