import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  gridSendBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  photoImgContainer: {
    position: 'relative',
  },
  photoImg: {
    width: '100%',
    height: '100%',
  },
  photoTag: {
    position: 'absolute',
    top: '1rem',
    left: '1rem',
    backgroundColor: '#fff',
  },
}));
