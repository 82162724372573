import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { useLocation } from 'react-router';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button } from 'components';
import { Text } from 'components/Form/Text';
import { Formik, Form, useFormikContext, Field } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { browserHistory } from 'routes/browserHistory';
import { api, budgetService, serviceOrderService } from 'services';
import XLSX from 'xlsx';

import { useStyles } from './styles';
import { OsTabs } from './Tabs';
import { serviceValidation } from './validation';

export const ServiceOrderContext = createContext();

const budgetStatusesOptions = {
  3: 'Autorizado',
  8: 'Reprovado',
  10: 'Reprovado',
  15: 'Autorizado',
  16: 'Autorizado',
  17: 'Autorizado',
  18: 'Autorizado',
};

export const Consult = () => {
  const [serviceData, setServiceData] = useState([]);
  const [logs, setLogs] = useState([]);
  const [logsConference, setLogsConference] = useState([]);
  const [pcpButtonEnable, setPcpButtonEnable] = useState(true);

  const classes = useStyles();

  const [services, , loadingallServices] = useQuery(
    () => api.get('/service-orders/list/allServices'),
    [],
  );

  // const state = useLocation();

  const handleDownloadAllService = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(services));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    await XLSX.writeFile(workbook, `RelatórioTotalServices${now}.xlsx`);
  };

  const FieldService = () => {
    const { state } = useLocation();

    console.log('STATE: ', state);

    const toInputUppercase = (e) => {
      const { value } = e.target;
      e.target.value = value ? value.toUpperCase() : '';
    };

    const { setFieldValue } = useFormikContext();

    useEffect(() => {
      if (state) {
        if (state.service_order_number) {
          setFieldValue('service', state.service_order_number);
        } else if (state.service) {
          setFieldValue('service', state.service);
        }
      }
    }, [state, setFieldValue]);

    return (
      <Grid item xs={12} md={4} lg={3}>
        <Field
          size='small'
          variant='outlined'
          name='service'
          label='Service'
          fullWidth
          component={Text}
          onInput={toInputUppercase}
        />
      </Grid>
    );
  };

  const navigateTo = (service_number) => {
    browserHistory.push({
      pathname: '/pcp/general',
      state: {
        service: service_number,
      },
    });
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const { service } = values;

      const { data } = await serviceOrderService.getServiceOrderByNumberWithGaiaAndGspnData(
        service,
      );

      const { data: dataLogsConferences } = await api.get(
        `/logs/conference/cronologic/${data.serviceFromGaia.number}`,
      );

      setLogsConference(dataLogsConferences);

      const { data: dataLogss } = await api.get(`/logs/cronologic/${data.serviceFromGaia.number}`);

      setLogs(dataLogss);

      console.log('AQUI O LOG CONFERENCE', dataLogsConferences);
      console.log('AQUI O LOG', dataLogss);
      setPcpButtonEnable(data?.serviceFromGaia?.status_id === 10 ? false : true);

      setServiceData(data);

      toast.success('Service consultada com sucesso');
    } catch (error) {
      toast.error(error.response?.data?.message || 'Erro ao consultar');
    } finally {
      setSubmitting(false);
    }
  };

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () => budgetService.listBudgetsServicesByServiceId(serviceData?.serviceFromGaia?.id),
    [serviceData],
  );

  return (
    <ServiceOrderContext.Provider
      value={{ serviceData, setServiceData, setLogs, logs, setLogsConference, logsConference }}
    >
      <PageTitle>Consultar OS</PageTitle>
      <Formik
        initialValues={{ service: '' }}
        validationSchema={serviceValidation}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid className={classes.formContainer} container spacing={2}>
              <FieldService />
              <Grid item xs={12} md={4} lg={2}>
                <Button
                  color='primary'
                  type='submit'
                  disabled={!props.isValid || !props.dirty}
                  fullWidth
                >
                  PESQUISAR
                </Button>
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Button
                  color='primary'
                  onClick={handleDownloadAllService}
                  fullWidth
                  loading={loadingallServices}
                >
                  RELATORIO DE SERVICES
                </Button>
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <Button
                  color='primary'
                  onClick={() => {
                    navigateTo(serviceData?.serviceFromGaia?.number);
                  }}
                  fullWidth
                  disabled={pcpButtonEnable}
                  loading={loadingallServices}
                >
                  PCP
                </Button>
              </Grid>
              {budget ? (
                <Grid>
                  <Typography variant='h6' color='secondary'>
                    Status do Orçamento: {budgetStatusesOptions[budget?.status_id] ?? 'Pendente'}
                  </Typography>

                  <Typography variant='h6' color='secondary'>
                    Cliente:
                    {budget?.service_order?.client?.name}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </Form>
        )}
      </Formik>
      <OsTabs service={serviceData} />
    </ServiceOrderContext.Provider>
  );
};
