import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { useMutation } from '@apollo/client';
import { ReactComponent as Trash } from 'assets/images/sorting.svg';
import { Button, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { GENERATE_INVOICE_TAG } from 'services/graphql/query/pdf';

export function ReprintInvoice() {
  const [generatePdf] = useMutation(GENERATE_INVOICE_TAG, {
    onCompleted: (data) => {
      const pdfBase64 = data?.invoiceTag || '';
      const pdfUrl = `data:application/pdf;base64,${pdfBase64}`;
      const link = document.createElement('a');
      link.href = pdfUrl;
      link.download = 'danfe.pdf';
      link.click();
      toast.success('Etiqueta gerada com sucesso');
    },
    onError: (error) => {
      toast.error('Erro ao gerar a etiqueta!');
      console.error('Erro ao gerar a etiqueta:', error);
    },
  });

  async function handleSubmit(values, { setSubmitting, resetForm }) {
    setSubmitting(true);

    try {
      const anymarketId = values.anymarketId;
      await generatePdf({ variables: { anymarketId } });
      resetForm();
    } catch (error) {
      toast.error('Erro ao gerar a etiqueta!');
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <PageTitle>Reimpressão de Danfe</PageTitle>

      <Formik initialValues={{ anymarketId: '' }} onSubmit={handleSubmit}>
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} xl={2}>
                <Field
                  name='anymarketId'
                  label='ID ANYMARKET'
                  size='small'
                  variant='outlined'
                  fullWidth
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} xl={3}>
                <Button
                  type='submit'
                  fullWidth
                  disabled={!props.isValid || !props.dirty}
                  loading={props.isSubmitting}
                >
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>

      <Grid style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }} container>
        <Trash style={{ height: 250, marginTop: 50, position: 'relative', margin: 'auto 0' }} />
      </Grid>
    </>
  );
}
