import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  mail_contract_id: Yup.number().required('Campo obrigatório'),
  mail_method_id: Yup.number().required('Campo obrigatório'),
  mail_prefix_id: Yup.number().required('Campo obrigatório'),
  initial_range: Yup.string().length(8).matches(/^\d+$/).required('Campo obrigatório'),
  final_range: Yup.string().length(8).matches(/^\d+$/).required('Campo obrigatório'),
  post_card: Yup.string().required('Campo obrigatório'),
});
