import React, { useContext, useState } from 'react';

import Grid from '@material-ui/core/Grid';

import { ReactComponent as ChangeLogImage } from 'assets/images/change-log.svg';
import { Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { invoiceService } from 'services';

import { ServiceOrderContext } from '../..';
import { useStyles } from './styles';

const columns = [
  { label: 'SeqNo' },
  { label: 'Part Number', align: 'center' },
  { label: 'Billing', align: 'center' },
];

const columns2 = [
  { label: 'Valor Cobrado', align: 'center' },
  { label: 'Valor Minimo possível', align: 'center' },
];

const invoicesOptions = {
  NotaRemessaEntrada: 1,
  NotaRemessaSaida: 2,
  NotaDeVenda: 3,
  NotaDeServico: 4,
  NotaDeAnalise: 5,
  NotaDeReparo: 6,
};

const columnsSalesComponent = [
  { label: 'Part-number', align: 'center' },
  { label: 'Descrição', align: 'center' },
  { label: 'Preço de venda componente', align: 'center' },
  { label: 'Valor minimo permitido', align: 'center' },
];

export const OsConference = () => {
  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [budgets, setBudget] = useState({});
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  const [budget, , loadingBudget, refetchBudgets] = useQuery(
    () =>
      invoiceService.getReportConferenceForConsult(serviceFromGaia?.nf_key, serviceFromGaia?.imei),
    [],
  );

  console.log('aaaa', budget);

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setBudget(item);
  };

  const api = {
    nf_servico: 'AQUI VAI A NOTA DE SERVIÇO',
    nf_venda: 'AQUI VAI A NOTA DE VENDA',
    motivo_recusa_nf: 'Negado',
    motivo_reprovado: 'Negado',
  };

  // useEffect(() => {
  //   serviceFromGaia;
  // }, [serviceFromGaia]);

  const dataOrc = new Date(budget?.created_at).toLocaleString();

  return (
    <Formik>
      <Form>
        {!budget ? (
          <>
            <div className={classes.container}>
              <h1 className={classes.title}>Ordem de serviço informada não possui conferência.</h1>
              <ChangeLogImage className={classes.changeLogImage} />
            </div>
          </>
        ) : (
          <>
            <Grid className={classes.formContainer} container spacing={2}>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='IMEI'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.imei ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='MPC'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.mpc ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='LACRE'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.lacre ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='EAN'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.ean ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='N° Nota'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.numero_nota ?? '-'}
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='IMEI'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.imei ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='DATA EMISSÃO'
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.data_emissao ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='DATA RECEBIMENTO'
                  fullWidth
                  component={Text}
                  mask='date'
                  inputProps={{ editable: false }}
                  value={budget[0]?.data_recebimento_posto ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='CÓDIGO PRODUTO :'
                  fullWidth
                  component={Text}
                  mask='date'
                  inputProps={{ editable: false }}
                  value={budget[0]?.cprod ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='CONFERIDO POR :'
                  fullWidth
                  component={Text}
                  mask='date'
                  inputProps={{ editable: false }}
                  value={budget[0]?.nome_conferidor ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='OBSERVAÇÃO'
                  multiline
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.observacao ?? '-'}
                  disabled
                />
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Field
                  variant='outlined'
                  size='small'
                  label='ACESSÓRIOS QUE VIERAM'
                  multiline
                  fullWidth
                  component={Text}
                  inputProps={{ editable: false }}
                  value={budget[0]?.acessories_checked ?? '-'}
                  disabled
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} lg={6}>
              <Table
                headers={columns}
                loading={loadingService}
                actionsLabel={<VisibilityIcon />}
                emptyMessage='Nenhum registro encontrado.'
                onActionsClick={handleOpenInformation}
                striped
                maxHeight
                data={service}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                disableNumeration
              >
                {service?.results.map((serviceOrder) => {
                  return (
                    <TableRow key={serviceOrder?.SeqNo}>
                      {console.log('Service Ordenado: ', budget)}
                      <TableCell>{serviceOrder?.SeqNo}</TableCell>
                      <TableCell align='center'>{serviceOrder?.PartCode}</TableCell>
                      <TableCell align='center'>{serviceOrder?.InvoiceNo}</TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Grid> */}
            {/* <LinkInformationDialog
              open={isDetailsOpen}
              handleClose={handleCloseInformation}
              budget={budget}
              reloadStatuses={refetchBudgets}
            /> */}
          </>
        )}
      </Form>
    </Formik>
  );
};
