/* eslint-disable no-restricted-imports */
import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import { Card, CardContent, Typography, Grid } from '@material-ui/core';

import { useMutation } from '@apollo/client';
import { Button } from 'components';
import { FINISH_B2C_BOX } from 'services/graphql/query/b2c';

import { CreateBoxDialogContext } from '../..';

export function BoxCardList() {
  const { data, onContinue, refetch, setBoxId, setShowIncludeForm } =
    useContext(CreateBoxDialogContext);

  const [finishB2CBox] = useMutation(FINISH_B2C_BOX, {
    onCompleted: () => {
      toast.success('Box finalizada com sucesso');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message ||
        error.networkError?.message ||
        'Erro ao finalizar a box';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  return (
    <>
      <Typography variant='h6'>BOXs geradas</Typography>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Typography variant='h6'>BOX #{item.boxNumber}</Typography>
                <Typography variant='subtitle1'>ID ANYMARKET: {item.anymarketId}</Typography>
                {/* <Typography variant='subtitle1'>
                  CÓDIGO PEDIDO: {item.b2cOrders.orderCode}
                </Typography> */}
                <Typography variant='subtitle1'>
                  MARKETPLACE: {item.b2cOrders.marketplace}
                </Typography>
                <Typography variant='subtitle1'>CLIENTE: {item.b2cOrders.client.name}</Typography>
                <Typography variant='subtitle1'>
                  ITENS COLETADOS: {item._count.B2CBoxItens}
                </Typography>
                <Typography variant='subtitle1'>
                  STATUS: {item.finalized ? 'Encerrada' : 'Aberta'}
                </Typography>
                {item.finalized ? (
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <Button
                        fullWidth
                        onClick={() => [setBoxId(item.id), setShowIncludeForm(false), onContinue()]}
                      >
                        Ver itens
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <Button fullWidth onClick={() => [setBoxId(item.id), onContinue()]}>
                        Continuar Coleta
                      </Button>
                    </Grid>
                    <Grid item sm={6}>
                      <Button
                        fullWidth
                        onClick={() => finishB2CBox({ variables: { boxId: item.id } })}
                        color='secondary'
                      >
                        Fechar BOX
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
