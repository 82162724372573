import React from 'react';
import { useRouteMatch } from 'react-router';

import { StockRequisitionPending } from 'pages/Private/Production/AnalysisStockRequest';
import { AttachReport } from 'pages/Private/Production/AttachReport';
import { CheckKit } from 'pages/Private/Production/CheckKit';
import { ConvertManyXML } from 'pages/Private/Production/ConvertManyXML';
import { PostalCards } from 'pages/Private/Production/PostalCards';
import { PreBudgetTec } from 'pages/Private/Production/PreBudgetTec';
import { QA } from 'pages/Private/Production/QA';
import { ReportOs } from 'pages/Private/Production/ReportOs';
import { AwaitingParts } from 'pages/Private/Production/ServiceTypeWithNoOrder copy';
import { SortingAssurance } from 'pages/Private/Production/Sorting';
import { StockRequisition } from 'pages/Private/Production/StockRequisition';
import { ValidateAnalysisGrade } from 'pages/Private/Production/ValidateAnalysisGrade';
import { ViewAttachReport } from 'pages/Private/Production/ViewAttachReport';
import { Warranty } from 'pages/Private/Production/Warranty';

import { Route } from '../Route';

export const ProductionRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Route isPrivate path={`${path}/warranty`} component={Warranty} />
      <Route isPrivate path={`${path}/check-kit`} component={CheckKit} />
      <Route isPrivate path={`${path}/postal-cards`} component={PostalCards} />

      <Route isPrivate path={`${path}/report-os`} component={ReportOs} />
      <Route isPrivate path={`${path}/validate-analysis-grade`} component={ValidateAnalysisGrade} />
      <Route isPrivate path={`${path}/pre-budget-tec`} component={PreBudgetTec} />
      <Route isPrivate path={`${path}/quality-assurance`} component={QA} />
      <Route isPrivate path={`${path}/sorting-assurance`} component={SortingAssurance} />

      <Route isPrivate path={`${path}/attach-report-os`} component={AttachReport} />

      <Route isPrivate path={`${path}/convert-many-xml`} component={ConvertManyXML} />
      <Route isPrivate path={`${path}/stock-requisition`} component={StockRequisition} />
      <Route
        isPrivate
        path={`${path}/stock-requisition-pending`}
        component={StockRequisitionPending}
      />

      <Route isPrivate path={`${path}/awaiting-parts`} component={AwaitingParts} />
      <Route isPrivate path={`${path}/attach-report-view-os`} component={ViewAttachReport} />
    </>
  );
};
