import { makeStyles } from '@material-ui/core/styles';

export const useStyles2 = makeStyles((theme) => {
  return {
    root: {
      marginTop: '5px',
    },
    divContaiver: {
      fontSize: '16px',
    },
    container: {
      display: 'flex',
    },
    tableButton: {
      fontSize: 11,
      height: 30,
    },
    captions: {
      width: 'fit-content',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: 20,

      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    statusCell: {
      '&.status-Coletado': {
        color: '#FAA307',
      },
      '&.status-Entregue': {
        color: '#9ef01a',

        '&.status-Aguardando Objeto na Agência': {
          color: '#E85D04',
        },
      },
    },
    serviceNumber: {
      fontSize: 16,
      fontWeight: 700,
    },
    statusDescription: {
      fontSize: 14,
      fontWeight: 700,
    },
    gridLegend: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    divLegend: {
      fontSize: '16px',
    },
    sectionContext: {
      display: 'flex',
    },
    legend1: {
      background: '#9ef01a',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend2: {
      background: '#70e000',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend3: {
      background: '#38b000',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend4: {
      background: '#f72585',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend5: {
      background: '#7209b7',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend6: {
      background: '#4361ee',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend7: {
      background: '#e6c619',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend8: {
      background: '#FFBA08',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend9: {
      background: '#FAA307',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend10: {
      background: '#F48C06',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend11: {
      background: '#E85D04',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend12: {
      background: '#F95738',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    legend13: {
      background: '#D00000',
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      border: 'none',
      marginBottom: '5px',
    },
    infoLegend: {
      marginLeft: '3px',
    },
    legend: {
      fontWeight: 'bold',
    },
  };
});
