import React from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { Dialog, FormatMoney, Mask, Tabs, Table, Text, Spacer } from 'components';
import { Formik, Form, Field } from 'formik';

import { useStyles } from './styles';

const columnsComponents = [
  { label: 'Part Number' },
  { label: 'Processo' },
  { label: 'Valor' },
  { label: 'Quantidade' },
  { label: 'Sub-Total' },
];

const columnsAdditionalServices = [
  { label: 'Descrição' },
  { label: 'Serviço Adicional' },
  { label: 'Valor' },
  { label: 'Quantidade' },
  { label: 'Sub-Total' },
];

export const PreBudgetTecInformation = ({ open, handleClose, preBudgetTecDetails }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog maxWidth='lg' onClose={handleClose} open={open}>
        <Dialog.Title onClose={handleClose}>Detalhes do pré orçamento técnico</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <p className={classes.detailKey}>Nome do Técnico:</p>
              <p className={classes.detailValue}>{preBudgetTecDetails?.user?.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className={classes.detailKey}>E-mail:</p>
              <p className={classes.detailValue}>{preBudgetTecDetails?.user?.email}</p>
            </Grid>
            <Grid item xs={4}>
              <p className={classes.detailKey}>Telefone:</p>
              <p className={classes.detailValue}>
                <Mask type='phone'>
                  {preBudgetTecDetails?.user?.phone
                    ? preBudgetTecDetails?.user?.phone
                    : 'Sem telefone cadastrado'}
                </Mask>
              </p>
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={1} />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <p className={classes.detailKey}>Nome do cliente:</p>
              <p className={classes.detailValue}>{preBudgetTecDetails?.client?.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className={classes.detailKey}>E-mail:</p>
              <p className={classes.detailValue}>{preBudgetTecDetails?.client?.email}</p>
            </Grid>
            <Grid item xs={4}>
              <p className={classes.detailKey}>Telefone:</p>
              <p className={classes.detailValue}>
                <Mask type='phone'>
                  {preBudgetTecDetails?.client?.phone_number
                    ? preBudgetTecDetails?.client?.residential_phone_number
                    : 'Sem telefone cadastrado'}
                </Mask>
              </p>
            </Grid>
          </Grid>

          <Spacer axis='vertical' size={15} />
          <Divider variant='fullWidth' />

          <Tabs gutterBottom>
            <Tabs.Content label='Componentes' disablePadding>
              <Table
                headers={columnsComponents}
                emptyMessage='Nenhum registro encontrado.'
                disableNumeration
                striped
                maxHeight={500}
              >
                {preBudgetTecDetails?.solded_sales_components?.map((saleComponent) => {
                  return (
                    <TableRow key={saleComponent?.id}>
                      <TableCell>{saleComponent?.part_number}</TableCell>
                      <TableCell>{saleComponent?.description}</TableCell>
                      <TableCell>
                        <FormatMoney>{saleComponent?.sell_price}</FormatMoney>
                      </TableCell>
                      <TableCell>{saleComponent?.amount}</TableCell>
                      <TableCell>
                        <FormatMoney>{saleComponent?.sell_price_total_quantity}</FormatMoney>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Tabs.Content>

            <Tabs.Content label='Serviços Adicionais' disablePadding>
              <Table
                headers={columnsAdditionalServices}
                emptyMessage='Nenhum registro encontrado.'
                disableNumeration
                striped
                maxHeight={500}
              >
                {preBudgetTecDetails?.solded_sales_additional_services?.map((additionalService) => {
                  return (
                    <TableRow key={additionalService?.id}>
                      <TableCell>{additionalService?.description}</TableCell>
                      <TableCell>
                        {additionalService?.additional_service_product?.description}
                      </TableCell>
                      <TableCell>
                        <FormatMoney>{additionalService?.service_price}</FormatMoney>
                      </TableCell>
                      <TableCell>{additionalService?.amount}</TableCell>
                      <TableCell>
                        <FormatMoney>{additionalService?.sell_price_total_quantity}</FormatMoney>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </Table>
            </Tabs.Content>
          </Tabs>

          <Spacer axis='vertical' size={15} />
          <Divider variant='fullWidth' />

          <Formik>
            <Form>
              <Dialog.Title>Orçamento final pelo técnico</Dialog.Title>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Field
                    variant='outlined'
                    name='total_items'
                    label='Total de Componentes'
                    size='small'
                    mask='money'
                    component={Text}
                    value={preBudgetTecDetails.total_components_price}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    variant='outlined'
                    name='total_items'
                    label='Total de Serviços Adicionais'
                    size='small'
                    mask='money'
                    component={Text}
                    value={preBudgetTecDetails.total_services_price}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    variant='outlined'
                    name='total_items'
                    label='Total de itens gerais'
                    size='small'
                    mask='money'
                    component={Text}
                    value={preBudgetTecDetails.total_items}
                    disabled
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    variant='outlined'
                    name='total_items_tec'
                    label='Total Final Pelo Técnico'
                    size='small'
                    mask='money'
                    component={Text}
                    value={preBudgetTecDetails.total_items_tec}
                    disabled
                  />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
