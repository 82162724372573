import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    imgContainer: {
      display: 'block',
      width: '100%',
      maxWidth: '320px',
      margin: 'auto',
      paddingTop: '60px',
    },
  };
});
