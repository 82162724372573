import React, { useContext } from 'react';

import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Spacer } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Table } from 'components/Table';

import { SaleContext } from '../..';

export const SaleContent = ({ sale }) => {
  console.log('Sale: ', sale);
  const { serviceTypeSelect } = useContext(SaleContext);

  const columns = [
    { label: 'Part Number', align: 'center' },
    { label: 'Quantidade vendida', align: 'center' },
    { label: 'Tipo de estoque', align: 'center' },

    { label: 'Preço de venda', align: 'center' },
    { label: 'Subtotal', align: 'center' },
  ];

  const columnsService = [
    { label: 'Descrição', align: 'center' },
    { label: 'Quantidade vendida', align: 'center' },
    { label: 'Descrição do Produto', align: 'center' },

    { label: 'Preço de venda', align: 'center' },
    { label: 'Subtotal', align: 'center' },
  ];

  const useStyles = makeStyles((theme) => {
    return {
      root: {
        color: 'green',
      },

      saleContent: {
        justifyContent: 'flex-end',
      },
    };
  });

  const classes = useStyles();

  return (
    <div>
      <Table
        headers={
          serviceTypeSelect === 1 ? columns : serviceTypeSelect === 3 ? columns : columnsService
        }
        disableNumeration
      >
        {serviceTypeSelect == 1 ? (
          sale?.solded_accessories?.map((accessory) => (
            <TableRow align='center' key={accessory?.part_number}>
              <TableCell align='center'>{accessory?.part_number}</TableCell>
              <TableCell align='center'>{sale?.amount}</TableCell>
              <TableCell align='center'>
                {accessory?.component_level_location?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{accessory?.component_acessory_price}</FormatMoney>
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{sale?.sell_price_total_quantity}</FormatMoney>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}

        {serviceTypeSelect == 2 ? (
          sale?.solded_services?.map((service) => (
            <TableRow align='center' key={service?.description}>
              {console.log('O que tem Sales: ', service)}
              <TableCell align='center'>{service?.description}</TableCell>
              <TableCell align='center'>{service?.amount}</TableCell>
              <TableCell align='center'>{service?.product?.description}</TableCell>
              <TableCell align='center'>
                <FormatMoney>{service?.labor_price}</FormatMoney>
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{service?.sell_price_total_quantity}</FormatMoney>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}

        {serviceTypeSelect == 3 ? (
          sale?.solded_accessories?.map((accessory) => (
            <TableRow align='center' key={accessory?.part_number}>
              {console.log('Caiu errado: ')}
              <TableCell align='center'>{accessory?.part_number}</TableCell>
              <TableCell align='center'>{sale?.amount}</TableCell>
              <TableCell align='center'>
                {accessory?.component_level_location?.description}
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{accessory?.component_acessory_price_wholesale}</FormatMoney>
              </TableCell>
              <TableCell align='center'>
                <FormatMoney>{sale?.sell_price_total_quantity}</FormatMoney>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
      </Table>
      <Typography variant='h6' className={classes.saleContent}>
        Valor:
        <span className={classes.root}>
          <FormatMoney>
            {parseFloat(sale.sale_total_price + (sale.sale_total_price * sale.discount) / 100)}
          </FormatMoney>
        </span>
      </Typography>
      <Typography variant='h6' className={classes.saleContent}>
        Porcentagem de Desconto:
        <span className={classes.root}>{sale.discount != null ? parseInt(sale.discount) : 0}%</span>
      </Typography>
      <Typography variant='h6' className={classes.saleContent}>
        Total da venda:
        <span className={classes.root}>
          <FormatMoney>{sale.sale_total_price}</FormatMoney>
        </span>
      </Typography>

      {sale?.coupon_url ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Spacer axis='vertical' size={24} />
            <Divider variant='fullWidth' />
            <Spacer axis='vertical' size={24} />
            <Typography variant='h6'>Link para reimpressão do cupom:</Typography>
            <Spacer axis='vertical' size={24} />
            <Button onClick={() => window.open(sale?.coupon_url, '_blank')}>
              {sale?.coupon_url}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
};
