import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { ReactComponent as AcessoryImg } from 'assets/images/addAcessory.svg';
import { Text, Button, Table, FormatDate } from 'components';
import { Formik, Form, Field } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';
import * as Yup from 'yup';

import { AlterAccessoryDialog } from './AlterAccessoryDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Nome' },
  { label: 'Data de cadastro' },
  { label: <VisibilityIcon />, align: 'center' },
];

const rows = [];

const toInputUppercase = (e) => {
  e.target.value = ('' + e.target.value).toUpperCase();
};

export const validationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
});

export const RegisterAcessory = () => {
  const userRole = useContextSelector(AuthContext, (context) => context.user.role.id);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [name, setName] = useState('');
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [accessory, setAccessory] = useState({});

  const classes = useStyles();

  const [accessories, setAccessories] = useState([]);

  const [accessoriesData, , accessoriesDataLoading, accessoriesRefetch] = useQuery(
    serviceOrderService.listServiceOrderAccessories,
    [],
  );

  useEffect(() => {
    setAccessories(accessoriesData);
  }, [accessoriesData]);

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    console.log('ITEM: ', item);
    setAccessory(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      const { data } = await serviceOrderService.createServiceOrderAccessories(values);

      setAccessories((prevState) => [...prevState, data]);

      accessoriesRefetch();
      toast.success('Acessório cadastrado com sucesso!');

      resetForm();
    } catch (err) {
      console.error(err);
      toast.error('Erro ao cadastrar acessório');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Acessórios</PageTitle>
      <Formik
        initialValues={{ description: '' }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Field
                    label='Digite o acessório'
                    name='description'
                    component={Text}
                    variant='outlined'
                    fullWidth
                    size='small'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onInput={toInputUppercase}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Cadastrar
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Table
                    headers={columns}
                    striped
                    emptyMessage='Sem acessórios cadastrados.'
                    loading={accessoriesDataLoading}
                    data={accessoriesData}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    disableNumeration
                  >
                    {accessories?.map((accessory) => (
                      <TableRow key={accessory.id}>
                        <TableCell>{accessory.description}</TableCell>
                        <TableCell>
                          <FormatDate>{accessory.created_at}</FormatDate>
                        </TableCell>
                        <TableCell align='center'>
                          <IconButton
                            color='primary'
                            onClick={(e) => handleOpenInformation(e, accessory)}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
                <AlterAccessoryDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  accessory={accessory}
                  reloadAccessories={accessoriesRefetch}
                />
                <Grid className={classes.imgContainer} item xs={12} lg={6}>
                  <AcessoryImg className={classes.img} />
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};
