import { React, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button } from 'components/Button';
import { Text } from 'components/Form/Text';
import { Field, Form, Formik } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { warrantyService } from 'services/warranty';

import { IssueAnalysisInvoiceNewKitDialog } from './IssueAnalysisInvoiceNewKitDialog';
import { useStyles } from './styles';
import { validationSchema } from './validationSchema';

export const IssueAnalysisInvoiceNewKit = () => {
  const classes = useStyles();

  useEffect(() => {}, []);

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [details, setDetails] = useState({});
  const [dataValues, setDataValues] = useState();

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (item, values) => {
    setIsDetailsState.toTrue();
    console.log('Item: ', item);
    console.log('Values: ', values);
    setDetails(item);
    setDataValues(values);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('Values: ', values);
      const { data } = await warrantyService.createBudgetInvoiceEmissionsExibithionNewKit(values);

      console.log('Teste de Informações: ', data);

      handleOpenInformation(data, values);

      resetForm();
      setSubmitting(true);
      // toast.success('Nota de orçamento emitida com sucesso');
    } catch (err) {
      console.log(err);
      console.error(err);
      toast.error(err.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          service_number: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid className={classes.root} container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Field
                    label='Service'
                    name='service_number'
                    size='small'
                    variant='outlined'
                    inputProps={{ maxLength: 30 }}
                    fullWidth
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Button type='submit' fullWidth>
                    EMITIR
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <IssueAnalysisInvoiceNewKitDialog
        open={isDetailsOpen}
        handleClose={handleCloseInformation}
        data={details}
        values={dataValues}
      />
    </>
  );
};
