/* eslint-disable no-restricted-imports */
import React, { createContext, useState } from 'react';

import { useQuery } from '@apollo/client';
import { PageTitle } from 'pages/Layout/PageTitle';
import { FIND_ALL_SERVICE_FOR_STOCK_ALOCATION } from 'services/graphql/query/service-orders';

import { WaitingAllocationDialog } from './components/WaitingAllocationDialog';
import { WaitingAllocationTable } from './components/WaitingAllocationTable';

export const WaitingAllocationContext = createContext();

export function WaitingAllocation() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState('');

  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const { data, loading, refetch } = useQuery(FIND_ALL_SERVICE_FOR_STOCK_ALOCATION, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      search: search,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  function handleOpenDialog(value) {
    setSelectedData(value);
    setIsOpenDialog(true);
  }

  function handleCloseDialog() {
    setIsOpenDialog(false);
  }

  return (
    <>
      <WaitingAllocationContext.Provider
        value={{
          data,
          loading,
          refetch,
          handleChangePage,
          handleChangeRowsPerPage,
          handleSearchChange,
          handleOpenDialog,
          page,
          rowsPerPage,
          totalCount: data?.findAllServiceForStockAlocation?.totalCount ?? 0,
          search,
          setSearch,
        }}
      >
        <PageTitle>Aguardando Alocação</PageTitle>

        <WaitingAllocationTable />
        <WaitingAllocationDialog
          isOpen={isOpenDialog}
          handleClose={handleCloseDialog}
          selectedData={selectedData}
        />
      </WaitingAllocationContext.Provider>
    </>
  );
}
