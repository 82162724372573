import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
    },
    actionIcon: {
      width: 18,
      height: 18,
    },
    checkIcon: {
      color: '#4CAF50',
    },
    closeIcon: {
      color: '#DC3F53',
    },
  };
});
