import { toast } from 'react-toastify';

import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { GRAPHQL_API_URL } from 'settings';

const token = localStorage.getItem('@Gaia:token');
const user = localStorage.getItem('@Gaia:user');

const uploadLink = createUploadLink({
  uri: GRAPHQL_API_URL,
  headers: {
    Authorization: token ? `Bearer ${token}` : '',
    user,
  },
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      console.log('message ==>', message);
      if (message === 'Context creation failed: Usuário não autenticado') {
        toast.error('Usuário não autenticado');
      }
    });
  }
});

const graphql_api = new ApolloClient({
  link: ApolloLink.from([errorLink, uploadLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      // errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      // errorPolicy: 'ignore',
    },
    mutate: {
      fetchPolicy: 'network-only',
      // errorPolicy: 'ignore',
    },
  },
});

export { graphql_api };
