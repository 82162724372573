import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button, Radio } from 'components';
import { Formik, Field, Form } from 'formik';
import { budgetService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  sell_price: yup.number().required(),
});

export const RegisterServiceProductsDialog = ({
  open,
  handleClose,
  serviceOrderProduct = {},
  reloadServiceProducts,
}) => {
  if (!open) return null;
  const classes = useStyles();

  console.log('Service Order: ', serviceOrderProduct);

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('values: ', values);
      await budgetService.updateSalesComponentePrice(
        serviceOrderProduct.id,
        values.sell_price,
        values.is_sales_active,
        values.min_price,
      );

      reloadServiceProducts();
      toast.success('Preço do componente alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o preço do componente.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Part Number:
        {serviceOrderProduct.part_number}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            sell_price: serviceOrderProduct.sell_price,
            is_sales_active: true,
            min_price: serviceOrderProduct.origin_value,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container>
                  <Grid item xs={12}>
                    <Field
                      name='sell_price'
                      label='Valor'
                      variant='outlined'
                      size='small'
                      mask='money'
                      emptyOption='Selecione'
                      inputProps={{
                        min: 1,
                      }}
                      component={Text}
                    />
                  </Grid>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Grid item xs={12}>
                    <Field
                      name='min_price'
                      label='Valor Minimo'
                      variant='outlined'
                      size='small'
                      mask='money'
                      emptyOption='Selecione'
                      inputProps={{
                        min: 1,
                      }}
                      component={Text}
                    />
                  </Grid>

                  <Grid item xs={2} lg={2}>
                    <Field
                      color='primary'
                      name='is_sales_active'
                      value={false}
                      fullWidth
                      component={Radio}
                      label='Inativar'
                    />
                  </Grid>
                </Grid>

                <div className={classes.buttonsContainer} container>
                  <Button onClick={handleClose} color='primary' variant='text'>
                    Fechar
                  </Button>
                  <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                    Confirmar
                  </Button>{' '}
                </div>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
