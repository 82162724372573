import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  devText: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontWeight: 500,
  },
}));
