import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Text } from 'components';
import { Field } from 'formik';

export const ComplainClient = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={4}>
        <Typography variant='h6' gutterBottom>
          Reclamação
        </Typography>
        <Field
          component={Text}
          variant='outlined'
          placeholder='Declare aqui a reclamação do cliente'
          name='reclamation'
          size='small'
          multiline
          rows={10}
          inputProps={{ maxLength: 100 }}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Typography variant='h6' gutterBottom>
          Condição do Aparelho
        </Typography>
        <Field
          component={Text}
          variant='outlined'
          placeholder='Declare aqui as condições do aparelho'
          name='condition'
          size='small'
          multiline
          rows={10}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <Typography variant='h6' gutterBottom>
          Observação
        </Typography>
        <Field
          component={Text}
          variant='outlined'
          placeholder='Declare aqui as observações'
          name='observation'
          size='small'
          multiline
          rows={10}
        />
      </Grid>
    </Grid>
  );
};
