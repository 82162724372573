import React, { useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Text, Spacer, Table, Button } from 'components';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService } from 'services/component';
import XLSX from 'xlsx';

const columns = [
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Quantidade', align: 'center' },
  { label: 'Local' },
  { label: 'Tipo de Estoque' },
];

export const ConsultStock = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [startIndex, setStartIndex] = useState(1);
  const [isLoading, setIsloading] = useState(false);

  const [components, , loadingComponents] = useQuery(
    () => componentService.listGroupComponent(startIndex, rowsPerPage, search.trim()),
    [startIndex, rowsPerPage, search],
  );

  const handleChangePage = (event, newPage) => {
    loadingComponents;
    setPage(newPage);
    setStartIndex(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDownloadGeneralStockRelatory = async () => {
    setIsloading(true);
    try {
      const { data } = await componentService.listGroupComponent(
        1,
        components[0]?.totalrow,
        search.trim(),
      );

      const now = new Date();
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = {
        Sheets: {
          mySheet: worksheet,
        },
        SheetNames: ['mySheet'],
      };
      XLSX.writeFile(workbook, `RelatórioEstoqueGeral${now.toISOString()}.xlsx`);
    } catch (error) {
      toast.error('Falha ao gerar xlsx');
    } finally {
      setIsloading(false);
    }
  };

  const handleSearchChange = (e) => {
    loadingComponents;
    setPage(0);
    setStartIndex(1);
    const v = e?.target?.value;
    setSearch(v);
  };

  return (
    <>
      <PageTitle>Consultar estoque</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Button fullWidth onClick={handleDownloadGeneralStockRelatory} disabled={isLoading}>
            exportar relatório
          </Button>
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Table
            disableNumeration
            headers={columns}
            striped
            emptyMessage='Nenhum registro encontrado.'
            data={components}
            loading={loadingComponents}
          >
            {components?.map((component) => (
              <TableRow key={component.part_number}>
                <TableCell>{component.part_number}</TableCell>
                <TableCell>
                  {component.description ? component.description : 'SEM DESCRIÇÃO'}
                </TableCell>
                <TableCell align='center'>{component.quantity}</TableCell>
                <TableCell>{component.location_name ?? 'NENHUM'}</TableCell>
                <TableCell>{component.stock_type ?? 'Outros'}</TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
      <TablePagination
        rowsPerPageOptions={[10, 50, 75, 100, 200]}
        component='div'
        count={components?.[0]?.totalrow}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage='Linhas por página:'
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} de ${count !== -1 ? count : ''}`
        }
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};
