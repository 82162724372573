import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import BlockIcon from '@mui/icons-material/Block';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Button, FormatDate, Select, Spacer, Table, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { mailServices } from 'services/mail';
import * as yup from 'yup';

const columns = [
  { label: 'Tipo', align: 'center' },
  { label: 'Objeto', align: 'center' },
  { label: 'Sinistro', align: 'center' },
  { label: 'Data de cadastro', align: 'center' },
  { label: 'Bloqueio', align: 'center' },
];

const validationSchema = yup.object().shape({
  object: yup.string().required(),
  reason: yup.number().required(),
});

export const BlockObject = () => {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);
  const [mailObjects, setMailObjects] = useState([]);

  const [reasonList, , loadingReasonList, refreshReasonList] = useQuery(
    mailServices.listObjectBlockingReason,
    [],
  );

  const [mailObjectsData, , loadingMailObjects, objectrefresh] = useQuery(
    () => mailServices.listMailObjectBlocked(),
    [],
  );

  const reasonOptions = (reasonList || []).map((value) => ({
    label: value.reason,
    value: value.id,
  }));

  const filteredObjects = (mailObjects || []).filter((object) => {
    const loweredSearch = search.toLowerCase();

    return (
      object.code.toLowerCase().includes(loweredSearch) ||
      object.receipt_date.toLowerCase().includes(loweredSearch)
    );
  });

  useEffect(() => {
    setMailObjects(mailObjectsData);
  }, [mailObjectsData]);

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await mailServices.createMailObjectBlocked(values);
      resetForm();
      objectrefresh();
      toast.success('Objeto bloqueado com sucesso,');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao bloquear o objeto');
    } finally {
      setSubmitting(false);
    }
  };

  const handleUnlockObject = async ({ values, setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      await mailServices.unlockMailObjectBlocked(values);
      resetForm();
      objectrefresh();
      toast.success('Objeto desbloqueado com sucesso,');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao debloquear o objeto');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageTitle>Bloqueio de objetos</PageTitle>
      <Formik
        initialValues={{ object: '', reason: '' }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form>
              <Grid container spacing={2}>
                <Grid item sx={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='object'
                    label='Objeto'
                    size='small'
                    component={Text}
                    onChange={handleSearchChange}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    variant='outlined'
                    name='reason'
                    label='Motivo'
                    size='small'
                    component={Select}
                    options={reasonOptions}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    color='secondary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    type='submit'
                    fullWidth
                  >
                    Bloquear objeto
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    color='primary'
                    variant='contained'
                    disabled={!props.isValid || !props.dirty}
                    onClick={() => handleUnlockObject(props)}
                    fullWidth
                  >
                    Desbloquear objeto
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
      <Spacer size={30} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar objeto'
            size='small'
            fullWidth
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
            inputProps={{ maxLength: 15 }}
          />
        </Grid>

        <Grid item xs={12}>
          <Table
            title='Lista de objetos'
            headers={columns}
            loading={loadingMailObjects}
            // maxHeight={300}
            emptyMessage='Nenhum registro encontrado.'
            striped
            page={page}
            rowsPerPage={rowsPerPage}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          >
            {filteredObjects?.map((object) => (
              <TableRow key={object?.id}>
                <TableCell align='center'>{object?.name}</TableCell>
                <TableCell align='center'>{object?.code}</TableCell>
                <TableCell align='center'>{object?.search_website_value}</TableCell>
                <TableCell align='center'>
                  <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.receipt_date}</FormatDate>
                </TableCell>
                <TableCell align='center'>
                  {object?.blocked ? (
                    <BlockIcon sx={{ color: 'red' }} />
                  ) : (
                    <TaskAltIcon sx={{ color: 'green' }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
