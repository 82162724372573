import React from 'react';

import Grid from '@material-ui/core/Grid';

import { Dialog, Text, Button } from 'components';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
});

export const OrderDialog = ({ open, handleClose, serviceOrder = {} }) => {
  if (!open) return null;
  const classes = useStyles();

  console.log('Service Order: ', serviceOrder);

  return (
    <Dialog
      maxWidth='xs'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Alteração
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            description: serviceOrder.description,
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      name='description'
                      label='Alterar local'
                      variant='outlined'
                      size='small'
                      emptyOption='Selecione'
                      component={Text}
                    />
                  </Grid>

                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary' variant='text'>
                      Fechar
                    </Button>
                    <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
