import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useQuery } from '@apollo/client';
import { TablePagination } from '@mui/material';
import { Typography } from '@mui/material';
import { FormatDate, Table } from 'components';
import { LIST_LOGS } from 'services/graphql/query/logs';

const columns = [
  { label: 'Descrição', align: 'center' },
  { label: 'Status atual', align: 'center' },
  // { label: 'Status anterior', align: 'center' },
  { label: 'Data', align: 'center' },
];

export function LogsDetails({ sinister }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const logs = useQuery(LIST_LOGS, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <>
      <Typography variant='h6' gutterBottom>
        Logs:
      </Typography>

      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={logs?.loading}
        disableNumeration
      >
        {logs?.data?.listLogs?.responses?.map((item) => {
          return (
            <TableRow key={item?.id}>
              <TableCell align='center'>{item?.description}</TableCell>
              <TableCell align='center'>{item?.status?.description}</TableCell>
              {/* <TableCell align='center'>{item?.previous_status?.description}</TableCell> */}
              <TableCell align='center'>
                <FormatDate>{item?.createdAt}</FormatDate>
              </TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={logs?.data?.listLogs?.totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
