import React, { useState } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { useQuery } from '@apollo/client';
import { Chip, Box, Typography } from '@mui/material';
import { TablePagination } from '@mui/material';
import { FormatDate, Table } from 'components';
import {
  LIST_FUNTIONAL_SCREENING_DEFECTS,
  LIST_FUNTIONAL_SCREENING_RESPONSES,
} from 'services/graphql/query/functional-screening';

const columns = [
  { label: 'Pergunta', align: 'center' },
  { label: 'Resposta', align: 'center' },
  { label: 'Data', align: 'center' },
  { label: 'Usuário', align: 'center' },
];

export function FuntionalScreeningDetails({ sinister }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const funtionalResponses = useQuery(LIST_FUNTIONAL_SCREENING_RESPONSES, {
    variables: {
      limit: rowsPerPage,
      offset: page * rowsPerPage,
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  const defectsResponses = useQuery(LIST_FUNTIONAL_SCREENING_DEFECTS, {
    variables: {
      sinister: sinister,
    },
    fetchPolicy: 'network-only',
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  return (
    <>
      <Box mb={3}>
        <Typography variant='h6' gutterBottom>
          Defeitos Adicionais:
        </Typography>
        {defectsResponses?.data?.listFuncionalScreeningDefects?.map((item) => (
          <Chip
            key={item?.id}
            label={item?.defect?.description}
            color='primary'
            variant='outlined'
            style={{ marginRight: 8, marginBottom: 8 }}
          />
        ))}
      </Box>

      <Typography variant='h6' gutterBottom>
        Respostas Triagem Funcional:
      </Typography>
      <Table
        headers={columns}
        striped
        emptyMessage='Nenhum produto encontrado.'
        loading={funtionalResponses?.loading}
        disableNumeration
      >
        {funtionalResponses?.data?.listFuncionalScreeningReponses?.responses?.map((item) => {
          return (
            <TableRow key={item?.id}>
              <TableCell align='center'>{item?.question}</TableCell>
              <TableCell align='center'>{item?.response ? 'SIM' : 'NÃO'}</TableCell>
              <TableCell align='center'>
                <FormatDate>{item?.createdAt}</FormatDate>
              </TableCell>
              <TableCell align='center'>{item?.user?.name}</TableCell>
            </TableRow>
          );
        })}
      </Table>

      <TablePagination
        component='div'
        count={funtionalResponses?.data?.listFuncionalScreeningReponses?.totalCount}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
