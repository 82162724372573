import React from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { Dialog, Select, Text } from 'components';
import { Form, Field, Formik } from 'formik';
import { useQuery } from 'hooks/useQuery';
import { invoiceService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  days: yup.number().min(7),
});

export const AlterBillingExpirationDialog = ({
  open,
  handleClose,
  billing = {},
  reloadBilling,
}) => {
  const classes = useStyles();

  const [billingTypeOnly, , loadingBillingTypeOnly] = useQuery(
    () => invoiceService.listBillingTypeOnly(),
    [],
  );

  const billingOptionsOnly = (billingTypeOnly || []).map((billing) => ({
    value: billing.id,
    label: billing.tipoBilling,
  }));

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      console.log('values: ', values);
      await invoiceService.updateConference(values.days, values.id_tipoBilling);

      reloadBilling();
      toast.success('Nome do acessório alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o nome do acessório.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  console.log('Billing: ', billing);

  return (
    <Dialog maxWidth='xs' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Billing</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            days: Number(billing?.days ?? 0),
            id_tipoBilling: billing?.id_tipoBilling,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Field
                      name='id_tipoBilling'
                      label='Tipo de Billing'
                      variant='outlined'
                      size='small'
                      loading={loadingBillingTypeOnly}
                      options={billingOptionsOnly}
                      component={Select}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <Field
                      name='days'
                      min={30}
                      label='Prazo para expirar'
                      variant='outlined'
                      size='small'
                      // mask='number'
                      component={Text}
                    />
                  </Grid>
                </Grid>
                <Grid className={classes.buttonsContainer} container>
                  <Button onClick={handleClose} color='primary'>
                    Fechar
                  </Button>
                  <Button
                    disabled={!props.dirty || !props.isValid}
                    variant='contained'
                    color='primary'
                  >
                    Confirmar
                  </Button>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
