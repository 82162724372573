import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Alert from '@mui/material/Alert';
import { Checkbox, Dialog, Table } from 'components';
import { Field, Form, Formik } from 'formik';
import { warrantyService } from 'services/warranty';

const columns = [{ label: 'Mensagens do sistema ' }];

export const DebitDialog = ({ open, handleClose, service, messages }) => {
  const [disableBills, setHasBilling] = useState(false);

  useEffect(() => {}, []);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await warrantyService.warrantyDebit(service, values.disableBill);

      toast.success('Garantia autorizada com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message);
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog maxWidth='md' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Garantia - Ordem de Serviço: {service}</Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{ service_number: service, billOrNot: false }}
          onSubmit={onSubmitHandler}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item xs={12} lg={12} style={{ fontSize: 18 }}>
                      <Field component={Checkbox} name='disableBill' label='EMITIR NOTA' />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <Table
                        headers={columns}
                        striped
                        maxHeight
                        disableNumeration
                        emptyMessage='Nenhum registro encontrado.'
                        loading={props.isSubmitting}
                      >
                        {messages?.map((logMessage) => (
                          <TableRow key={logMessage.type}>
                            <TableCell>
                              <Alert severity={logMessage.type == 1 ? 'success' : 'error'}>
                                <strong>{logMessage.message}</strong>
                              </Alert>
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Grid>
                  </Grid>
                  <Grid style={{ margin: '8px', display: 'flex', justifyContent: 'end' }} container>
                    <Button onClick={handleClose} color='primary'>
                      Cancelar
                    </Button>
                    <Button type='submit'>Debitar</Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
