import React from 'react';
import { toast } from 'react-toastify';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Button, Dialog, Spacer, Table, Text } from 'components';
import { Form, Formik, FieldArray, Field } from 'formik';
import { invoiceService } from 'services';
import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  serial_number: Yup.string().length(24).matches(/^\d+$/).required(),
  invoice_number: Yup.string().required(),
});

export const UpdateRequestTagDialog = ({ open, handleClose, itemRequest, cubagemRequest }) => {
  const columns = [{ label: 'Chave nota de saída' }, { label: 'Ordem de serviço' }];

  const handleDownloadTagsClick = async (infnfe) => {
    const { data } = await invoiceService.downloadConferenceInvoicePackingListDevol(
      itemRequest[0]?.nota,
      cubagemRequest,
    );
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${infnfe}.pdf`);
  };

  const onSubmitHandler = (values) => {
    console.log('Values Dialog: ', values);
    handleDownloadTagsClick(itemRequest[0]?.nnf)
      .then((response) => {
        console.log('Response: ', response);
        handleClose();
        setTimeout(() => {
          toast.success('Reimpressão realizada com sucesso.');
        }, 2000);
      })

      .catch((err) => {
        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc?.decode(arr);

        const parsedResult = JSON?.parse(result);

        toast.error(parsedResult?.message);
      });
  };

  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>
        Número de nota de entrada: {itemRequest[0]?.nnf}
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            invoice_number: undefined,
            serial_number: undefined,
            infnfe: undefined,
            arrayOfKeys: Array?.from({ length: itemRequest?.length }, () => ({ name: undefined })),
          }}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12}>
                    <Typography variant='h6'>Dados para validação de NF</Typography>
                  </Grid>

                  <Grid item xs={12} lg={4}>
                    <Field
                      variant='outlined'
                      name='service_order_number'
                      size='small'
                      label='Service'
                      component={Text}
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Table
                      headers={columns}
                      striped
                      disableNumeration
                      data={itemRequest}
                      size='small'
                      emptyMessage='Nenhum registro encontrado.'
                    >
                      {itemRequest?.map((row, index) => (
                        <TableRow key={row?.id}>
                          <TableCell>
                            <FieldArray
                              fullWidth
                              name='arrayOfKeys'
                              render={() => (
                                <Grid sx={12} key={index}>
                                  <Field
                                    color='primary'
                                    variant='outlined'
                                    placeholder='Digite a chave da nota de saída'
                                    size='small'
                                    name={`arrayOfKeys[${index}].name`}
                                    component={Text}
                                    fullWidth
                                  />
                                </Grid>
                              )}
                            />
                          </TableCell>
                          <TableCell>{row?.number}</TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </Grid>
                </Grid>

                <Spacer size={32} />

                <DialogActions>
                  {console.log('Propis: ', props.values)}
                  {console.log('ItemRequest: ', itemRequest[0]?.chave_saida)}
                  <Button
                    onClick={(e) => onSubmitHandler()}
                    color='primary'
                    disabled={
                      !props.values.arrayOfKeys?.every(
                        (num) => String(num?.name) == String(itemRequest[0]?.chave_saida),
                      )
                    }
                    fullWidth
                  >
                    VALIDAR ROMANEIO
                  </Button>
                  <Button onClick={handleClose} color='secondary' fullWidth>
                    Fechar
                  </Button>
                </DialogActions>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
