import React from 'react';

import { Tabs } from 'components';

import Complaint from './Complaint/index';
import GeralObservation from './GeralObservation';
import InfoAdictional from './InfoAdicional/index';
import Remark from './Remark';

export const OsTabs = () => {
  return (
    <Tabs>
      <Tabs.Content label='Informações adicionais'>
        <InfoAdictional />
      </Tabs.Content>
      <Tabs.Content label='Cliente'>
        <Complaint />
      </Tabs.Content>
      <Tabs.Content label='Observação Geral'>
        <GeralObservation />
      </Tabs.Content>
      <Tabs.Content label='Remark'>
        <Remark />
      </Tabs.Content>
    </Tabs>
  );
};
