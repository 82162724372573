import React, { useContext } from 'react';
import { FaMinusCircle, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import { Text } from 'components/Form/Text';
import { FormatMoney } from 'components/FormatMoney';
import { Table } from 'components/Table';

import { BudgetCartContext } from '../../index';
import { removeComponentFromCartAction, updateAmountComponentAction } from '../store/actions';
import { useStyles } from './styles';

const columns = [
  { label: 'PART-NUMBER', align: 'center' },
  { label: 'PROCESSO', align: 'center' },
  { label: 'VALOR', align: 'center' },
  { label: 'QUANTIDADE', align: 'center' },
  { label: 'SUBTOTAL', align: 'center' },
  { label: '', align: 'center' },
];

export const CartItems = () => {
  const classes = useStyles();

  const { state, dispatch, totalCart } = useContext(BudgetCartContext);

  return (
    <>
      <Grid
        container
        alignContent='center'
        className={classes.gridContainer}
        justifyContent='center'
      >
        <Grid item xs={12} md={12} lg={12}>
          <Table
            maxHeight={500}
            emptyMessage='Nenhum acessório no carrinho.'
            striped
            disableNumeration
            headers={columns}
          >
            {state?.map((components) => (
              <TableRow key={components?.id}>
                <TableCell align='center' className={classes.tableCell}>
                  {components?.part_number}
                </TableCell>
                <TableCell align='center' className={classes.tableCell}>
                  {components?.description}
                </TableCell>
                <TableCell align='center' className={classes.tableCell}>
                  <FormatMoney>{components?.sell_price}</FormatMoney>
                </TableCell>
                <TableCell align='center' className={classes.tableCell}>
                  <Grid container direction='row'>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() =>
                          updateAmountComponentAction(
                            { amount: components.amount - 1, component_id: components.id },
                            dispatch,
                          )
                        }
                      >
                        <FaMinusCircle className={classes.minusIcon} />
                      </IconButton>
                    </Grid>
                    <Grid className={classes.qntd} item xs={4}>
                      <Text
                        number='number'
                        variant='outlined'
                        value={components.amount}
                        onChange={(e) =>
                          updateAmountComponentAction(
                            {
                              amount: e.target.value == 0 ? 1 : Number(e.target.value),
                              component_id: components.id,
                            },
                            dispatch,
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton
                        onClick={() =>
                          updateAmountComponentAction(
                            { amount: components.amount + 1, component_id: components.id },
                            dispatch,
                          )
                        }
                      >
                        <FaPlusCircle className={classes.plusIcon} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell align='center' className={classes.subTotalTableCell}>
                  <FormatMoney>{components?.sub_total}</FormatMoney>
                </TableCell>
                <TableCell className={classes.subTotalTableCell}>
                  <IconButton onClick={() => removeComponentFromCartAction(components, dispatch)}>
                    <FaTrash size='18' />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid container justifyContent='flex-end' className={classes.totalGrid}>
          <Grid item>
            <Typography className={classes.total}>TOTAL:</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.totalPrice}>
              <FormatMoney>{totalCart}</FormatMoney>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
