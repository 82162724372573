import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Checkbox, FormatMoney, Table } from 'components';
import { useFormikContext } from 'formik';

import { useStyles } from './style';

import { PreBudgetContext } from '..';

const columns = [
  {
    label: '#',
    width: 80,
    align: 'center',
  },
  {
    label: 'Part Number',
  },
  {
    label: 'Descrição',
  },
  {
    label: 'Quantidade',
  },
  {
    label: 'Valor minimo (un)',
  },
  {
    label: 'Valor de venda (un)',
  },

  { label: 'Cortesia', align: 'center' },
];

export const Budget = () => {
  const classes = useStyles();

  const { values, setFieldValue } = useFormikContext();

  const { state } = useContext(PreBudgetContext);

  const {
    state: { preBudgetComponents, service_order_id },
  } = useLocation();

  console.log('State: ', state);

  // console.log('Fucking Values: ', values);

  useEffect(() => {
    const minComponentsPrice = state
      ?.filter((saleComponent) => values.part_numbers.includes(saleComponent.part_number))
      .reduce((acc, component) => (acc += component.origin_value * parseInt(component.amount)), 0);

    const componentsTotalPrice = state
      ?.filter((saleComponent) => values.part_numbers.includes(saleComponent.part_number))
      .reduce((acc, component) => (acc += component.sell_price * parseInt(component.amount)), 0);

    const componentsTotalPriceCourtesy = state
      ?.filter((saleComponent) => values.courtesy_part_numbers.includes(saleComponent.part_number))
      .reduce((acc, component) => (acc += component.sell_price * parseInt(component.amount)), 0);

    if (componentsTotalPrice != null && componentsTotalPriceCourtesy != null) {
      setFieldValue('components_total_price', componentsTotalPrice - componentsTotalPriceCourtesy);
      setFieldValue('min_components_value', minComponentsPrice - componentsTotalPriceCourtesy);
    } else {
      setFieldValue('components_total_price', 0);
      setFieldValue('min_components_value', 0);
    }

    console.log('part_numbers_ ', values?.part_numbers);
    console.log('part_numbers_amount: ', values?.part_numbers_amount);
  }, [setFieldValue, preBudgetComponents, values, service_order_id, state]);

  const handleCheckboxClick = (index, partNumber, amount) => {
    const { part_numbers, indexes, part_numbers_amount } = values;

    const isSelected = part_numbers?.includes(partNumber);
    const isSelectedIndex = indexes?.includes(index);

    const filteredPartNumbers = part_numbers.indexOf(partNumber);
    const filteredIndexes = indexes.indexOf(index);

    const filteredPartNumbersAmount = part_numbers_amount.filter(
      (cPartNumber) => cPartNumber?.partNumber !== partNumber,
    );

    console.log('What returns: ', filteredPartNumbersAmount);

    if (isSelected && isSelectedIndex) {
      if (filteredPartNumbers > -1) {
        part_numbers.splice(filteredPartNumbers, 1);
        // part_numbers_amount.splice(filteredPartNumbersAmount, 1);
      }

      if (filteredIndexes > -1) {
        indexes.splice(filteredIndexes, 1);
      }

      return (
        setFieldValue('part_numbers', part_numbers),
        setFieldValue('indexes', indexes),
        setFieldValue('part_numbers_amount', filteredPartNumbersAmount)
      );
    }

    setFieldValue('part_numbers', [...part_numbers, partNumber]);
    setFieldValue('part_numbers_amount', [...part_numbers_amount, { partNumber, amount }]);
    setFieldValue('indexes', [...indexes, index]);
  };

  const isCheckboxChecked = (index, partNumber) =>
    values.part_numbers.includes(partNumber) && values.indexes.includes(index);

  const handleCheckboxClickCourtesy = (index, partNumber) => {
    const { courtesy_part_numbers, part_numbers, courtesy_indexes, indexes } = values;

    const isSelected =
      courtesy_part_numbers.includes(partNumber) && courtesy_indexes.includes(index);

    const filteredPartNumbers = courtesy_part_numbers.indexOf(partNumber);
    const filteredIndexes = courtesy_indexes.indexOf(index);

    if (isSelected) {
      if (filteredPartNumbers > -1) {
        courtesy_part_numbers.splice(filteredPartNumbers, 1);
      }

      if (filteredIndexes > -1) {
        courtesy_indexes.splice(filteredIndexes, 1);
      }

      const componentsTotalPriceCourtesy = state
        ?.filter((saleComponent) =>
          values.courtesy_part_numbers.includes(saleComponent.part_number),
        )
        .reduce((acc, component) => (acc += component.sell_price * parseInt(component.amount)), 0);

      setFieldValue('components_total_price', componentsTotalPriceCourtesy);

      const minComponentsPrice = state
        ?.filter((saleComponent) =>
          values.courtesy_part_numbers.includes(saleComponent.part_number),
        )
        .reduce(
          (acc, component) => (acc += component.origin_value * parseInt(component.amount)),
          0,
        );

      setFieldValue('min_components_value', minComponentsPrice);

      setFieldValue('components_total_price', componentsTotalPriceCourtesy);

      return (
        setFieldValue('courtesy_part_numbers', courtesy_part_numbers),
        setFieldValue('courtesy_indexes', courtesy_indexes)
      );
    }

    const partNumberIsSelected = part_numbers.includes(partNumber);
    const indexesIsSelected = indexes.includes(index);

    if (partNumberIsSelected && indexesIsSelected) {
      setFieldValue('courtesy_part_numbers', [...courtesy_part_numbers, partNumber]);
      setFieldValue('courtesy_indexes', [...courtesy_indexes, index]);
    }

    if (!isSelected) {
      const componentsTotalPriceCourtesy = state
        ?.filter((saleComponent) =>
          values.courtesy_part_numbers.includes(saleComponent.part_number),
        )
        .reduce((acc, component) => (acc -= component.sell_price * parseInt(component.amount)), 0);

      const minComponentsPrice = state
        ?.filter((saleComponent) =>
          values.courtesy_part_numbers.includes(saleComponent.part_number),
        )
        .reduce(
          (acc, component) => (acc += component.origin_value * parseInt(component.amount)),
          0,
        );

      setFieldValue('min_components_value', minComponentsPrice);
      setFieldValue('components_total_price', componentsTotalPriceCourtesy);
    }
  };

  const isCheckboxCheckedCourtesy = (index, partNumber) =>
    values.courtesy_part_numbers.includes(partNumber) && values.courtesy_indexes.includes(index);

  return state ? (
    <>
      <Typography variant='h6' gutterBottom>
        Peças
      </Typography>
      <Grid className={classes.gridContainer} container spacing={2}>
        <Grid item xs={12}>
          <Table headers={columns} striped disableNumeration loading={!state} maxHeight={400}>
            {state?.map((component, index) => (
              <TableRow key={index}>
                {console.log('O que vem no componente: ', component)}
                <TableCell size='small' align='center'>
                  <Checkbox
                    color='primary'
                    onChange={() =>
                      handleCheckboxClick(index, component.part_number, component.amount)
                    }
                    checked={isCheckboxChecked(index, component.part_number)}
                  />
                </TableCell>
                <TableCell>{component.part_number}</TableCell>
                <TableCell>{component.description}</TableCell>
                <TableCell>{component.amount}</TableCell>
                <TableCell>
                  <FormatMoney>{component.origin_value}</FormatMoney>
                </TableCell>
                <TableCell>
                  <FormatMoney>{component.sell_price}</FormatMoney>
                </TableCell>
                <TableCell size='small' align='center'>
                  <Checkbox
                    color='primary'
                    onChange={() => handleCheckboxClickCourtesy(index, component.part_number)}
                    checked={isCheckboxCheckedCourtesy(index, component.part_number)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <Grid item xs={3}>
          <div className={classes.containerLegend}>
            <ErrorOutlineIcon />
            <p variant='caption' className={classes.p}>
              Selecione cortesia se necessário
            </p>
          </div>
        </Grid>
      </Grid>
      <Grid style={{ display: 'flex', justifyContent: 'end' }}>
        <Typography>
          Total em peças: <FormatMoney>{values.components_total_price}</FormatMoney>
        </Typography>
      </Grid>
    </>
  ) : (
    <Grid container direction='column' justifyContent='center' alignItems='center' spacing={10}>
      <Grid item xs={12}>
        <Typography>Esse orçamento não possui part-numbers, Mandar para o sac.</Typography>
      </Grid>
    </Grid>
  );
};
