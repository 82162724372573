import React from 'react';

import Grid from '@material-ui/core/Grid';

import deliveryImg from 'assets/images/mail-delivery.svg';
import { Button, Select } from 'components';
import { Field, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';

import { useStyles } from './styles';
import { TableWaiting } from './Table';

export const Revenue = () => {
  const classes = useStyles();
  return (
    <>
      <PageTitle dev>Minuta</PageTitle>
      <Formik initialValues={{ number_os: '' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TableWaiting />
          </Grid>

          <Grid item xs={12} lg={2}>
            <Button color='primary' fullWidth>
              Gerar Minuta
            </Button>
          </Grid>

          <Grid item xs={12} lg={4}>
            <Field
              options={[
                { value: 1, label: 'Motorola' },
                { value: 2, label: 'Motorola' },
                { value: 2, label: 'PLL Next' },
              ]}
              variant='outlined'
              fullWidth
              size='small'
              label='Contrato Correio'
              name='method'
              component={Select}
            />
          </Grid>
          <Grid className={classes.gridDeliveryImg} item xs={12} lg={6}>
            <img className={classes.deliveryImg} src={deliveryImg} />
          </Grid>
        </Grid>
      </Formik>
    </>
  );
};
