import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';

import { useMutation } from '@apollo/client';
import { Button, Text } from 'components';
import { Formik, Form } from 'formik';
import { ORACLE_REPORT } from 'services/graphql/query/reports';
import { CONFIRM_ORACLE } from 'services/graphql/query/service-orders';
import XLSX from 'xlsx';

import { WaitingOracleContext } from './WaitingOracleInBound';

export function WaitingOracleHeader() {
  const { search, handleSearchChange, getSelectedRow, getSelectedAll, refetch, handleResetStates } =
    useContext(WaitingOracleContext);

  const [submit] = useMutation(CONFIRM_ORACLE, {
    onCompleted: () => {
      toast.success('Dados salvos com sucesso');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Error: ${errorMessage}`);
    },
  });

  const [downloadOracleReport, downloadOracleReportOptions] = useMutation(ORACLE_REPORT, {
    onCompleted: (data) => {
      const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data?.oracleReport));
      const workbook = (XLSX.WorkBook = {
        Sheets: {
          warehouse: worksheet,
        },
        SheetNames: ['warehouse'],
      });
      XLSX.writeFile(workbook, `Relatório_Aguardando_Oracle.xlsx`);
      toast.success('Relatório baixado com sucesso');
      refetch();
    },
    onError: (error) => {
      console.log('error', error);
      const errorMessage =
        error.graphQLErrors?.[0]?.message || error.networkError?.message || 'Erro ao salvar!';

      toast.error(`Erro ao baixar o relatório`);
    },
  });

  async function handleConfirmOracle() {
    try {
      await submit({ variables: { data: getSelectedRow } });
      handleResetStates();
    } catch (error) {
      console.log('error ==>', error);
      toast.error('Erro ao salvar o oracle');
    }
  }

  return (
    <>
      <Formik>
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                  <Text
                    label='Pesquisar'
                    size='small'
                    variant='outlined'
                    endAdornment={<SearchIcon />}
                    value={search}
                    onChange={handleSearchChange}
                  />
                </Grid>{' '}
                <Grid item xs={12} lg={3}>
                  <Button
                    fullWidth
                    onClick={() => handleConfirmOracle()}
                    disabled={getSelectedRow?.length <= 0 && !getSelectedAll}
                  >
                    Confirmar Oracle
                  </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Button
                    variant='outlined'
                    fullWidth
                    onClick={async () => await downloadOracleReport()}
                    disabled={downloadOracleReportOptions.loading}
                    loading={downloadOracleReportOptions.loading}
                  >
                    Baixar relatório
                  </Button>
                </Grid>
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
