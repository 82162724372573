import React from 'react';
import { useRouteMatch } from 'react-router';

import { AcessorySale } from 'pages/Private/Cashier/AcessorySale';
import { ConsultNoteNoFiscal } from 'pages/Private/Cashier/ConsultCouponNoFiscal';
import { ConsultNote } from 'pages/Private/Cashier/ConsultNote';
import { CustomersPersonalData } from 'pages/Private/Cashier/CustomersPersonalData';
import { IssuanceOfAnalysisNote } from 'pages/Private/Cashier/IssuanceOfAnalysisNote';
import { Labour } from 'pages/Private/Cashier/Labour';
import { RegisterClient } from 'pages/Private/Cashier/RegisterClient';
import { Report } from 'pages/Private/Cashier/Report';
import { LabourWithNoOrder } from 'pages/Private/Cashier/ServiceTypeWithNoOrder';
import { ViewVoucher } from 'pages/Private/Cashier/ViewVoucher';

import { Route } from '../Route';

export const CashierRoutes = () => {
  const { url } = useRouteMatch();

  return (
    <div>
      <Route isPrivate path={`${url}/acessory-sale`} component={AcessorySale} />
      <Route
        isPrivate
        path={`${url}/issuance-of-analysis-note`}
        component={IssuanceOfAnalysisNote}
      />
      <Route isPrivate path={`${url}/report-cashier`} component={Report} />
      <Route isPrivate path={`${url}/consult-note`} component={ConsultNote} />
      <Route isPrivate path={`${url}/consult-note-no-fiscal`} component={ConsultNoteNoFiscal} />
      <Route isPrivate path={`${url}/customers-personal-data`} component={CustomersPersonalData} />
      <Route isPrivate path={`${url}/labour`} component={Labour} />
      <Route isPrivate path={`${url}/view-voucher`} component={ViewVoucher} />
      <Route isPrivate path={`${url}/register-client`} component={RegisterClient} />
      <Route
        isPrivate
        path={`${url}/register-service-price-no-order`}
        component={LabourWithNoOrder}
      />
    </div>
  );
};
