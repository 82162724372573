import React, { useEffect } from 'react';

// import { ModalUpdatePassword } from 'pages/Layout/ModalUpdatePassword';
import { useQuery } from 'hooks/useQuery';
import { ResponsiveContainer, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { serviceOrderService } from 'services';

export const GraphiRepairInspectorNonCompliance = () => {
  const [agingNonComplianceData, , loadingNonCompliance, refetchAgingNonComplianceData] = useQuery(
    () => serviceOrderService.listRepairNonCompliance(),
    [],
  );

  const totalqa = agingNonComplianceData?.map((age) => {
    return age?.totalqa ?? 0;
  });

  const reparador = agingNonComplianceData?.map((age) => {
    return age?.reparador ?? '';
  });

  const dataNonCompliance = [
    {
      name: (reparador?.[0] ?? '') + ' ' + (totalqa?.[0] ?? ''),
      pv: totalqa?.[0] ?? 0,
    },
    {
      name: (reparador?.[1] ?? '') + ' ' + (totalqa?.[1] ?? ''),
      pv: totalqa?.[1] ?? 0,
    },
    {
      name: (reparador?.[2] ?? '') + ' ' + (totalqa?.[2] ?? ''),
      pv: totalqa?.[2] ?? 0,
    },
    {
      name: (reparador?.[3] ?? '') + ' ' + (totalqa?.[3] ?? ''),
      pv: totalqa?.[3] ?? 0,
    },
    {
      name: (reparador?.[4] ?? '') + ' ' + (totalqa?.[4] ?? ''),
      pv: totalqa?.[4] ?? 0,
    },
    {
      name: (reparador?.[5] ?? '') + ' ' + (totalqa?.[5] ?? ''),
      pv: totalqa?.[5] ?? 0,
    },
    {
      name: (reparador?.[6] ?? '') + ' ' + (totalqa?.[6] ?? ''),
      pv: totalqa?.[6] ?? 0,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      refetchAgingNonComplianceData();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [refetchAgingNonComplianceData]);

  console.log('batatinha', dataNonCompliance);
  return (
    <ResponsiveContainer width='100%' height='90%'>
      <BarChart
        width={80}
        height={30}
        data={dataNonCompliance}
        margin={{
          left: -25,
          bottom: -5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />

        <YAxis />
        <Tooltip />

        <Bar barSize={30} dataKey='pv' stackId='a' fill='#E23712' />
      </BarChart>
    </ResponsiveContainer>
  );
};
