import React, { useEffect } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { FormatMoney } from 'components/FormatMoney';
import { Table } from 'components/Table';

export const PartNumbers = ({ billingInfos }) => {
  useEffect(() => {
    console.log(billingInfos);
  }, [billingInfos]);

  const columns = [
    { label: 'Part Number', align: 'center' },
    { label: 'Quantidade estoque', align: 'center' },
    { label: 'Tipo de estoque', align: 'center' },
    { label: 'Local', align: 'center' },
    { label: 'Preço de compra', align: 'center' },
  ];

  const rows = [
    {
      part_number: 1,
      quantity: 2,
      invoice: '2813213AS',
      local: 'Prateleira A',
    },
  ];

  return (
    <Table headers={columns} disableNumeration>
      {billingInfos?.map((billingInfo) => (
        <TableRow align='center' key={billingInfo?.part_number}>
          <TableCell align='center'>{billingInfo?.part_number}</TableCell>
          <TableCell align='center'>{billingInfo?.quantity}</TableCell>
          <TableCell align='center'>{billingInfo?.component_level_location?.description}</TableCell>
          <TableCell align='center'>{billingInfo?.component_location?.description}</TableCell>
          <TableCell align='center'>
            <FormatMoney>{billingInfo?.purchase_price}</FormatMoney>
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );
};
