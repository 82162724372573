/* eslint-disable prettier/prettier */
import React, { createContext, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import SvgLess from '@material-ui/icons/ExpandLess';
import SvgMore from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  Button,
  Checkbox,
  FormatMoney,
  Spacer,
  Text,
  CustomAutocomplete,
  FormatDate,
} from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { componentService, invoiceService, serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';
import XLSX from 'xlsx';

import { useStyles } from './styles';




export const ConferenceContext = createContext();
export const initialConferenceState = [];

export const ConferenceInvoiceRepair = () => {
  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const currencies = [
    {
      value: '{CABO,CARREGADOR,FONE,CHAVE,CHIP}',
      label: 'CABO,CARREGADOR,FONE,CHAVE,CHIP',
    },

    {
      value: '{CABO,CARREGADOR,FONE,CHAVE}',
      label: 'CABO,CARREGADOR,FONE,CHAVE',
    },
    {
      value: '{CABO,CARREGADOR,FONE}',
      label: 'CABO,CARREGADOR,FONE',
    },

    {
      value: '{CABO,CARREGADOR,CHAVE}',
      label: 'CABO,CARREGADOR,CHAVE',
    },

    {
      value: '{CABO,FONE,CHAVE}',
      label: 'CABO,FONE,CHAVE',
    },

    {
      value: '{CARREGADOR,FONE}',
      label: 'CARREGADOR,FONE',
    },

    {
      value: '{CABO,CARREGADOR}',
      label: 'CABO,CARREGADOR',
    },

    {
      value: '{CABO}',
      label: 'CABO',
    },

    {
      value: '{CARREGADOR}',
      label: 'CARREGADOR',
    },


    {
      value: '{FONE}',
      label: 'FONE',
    },

    {
      value: '{CHAVE EXTRATORA}',
      label: 'CHAVE EXTRATORA',
    },
  ];

  const columns = [
    { label: 'N° Nota' },
    { label: 'Quantidade de itens' },
    { label: 'Data importação' },
    { label: 'Data de recebimento' },
    { label: 'Nome Cliente' },
  ];
  const [search, setSearch] = useState('');
  const [message, setMessage] = useState('');

  const [triedOne, setHasTriedOne] = useState(false);
  const [triedTwo, setHasTriedTwo] = useState(false);
  const [triedThree, setHasTriedThree] = useState(false);

  console.log('triedOne: ', triedOne);
  console.log('triedTwo: ', triedTwo);
  console.log('triedThree: ', triedThree);

  const setTriedOne = () => {
    if (triedOne) {
      setHasTriedOne(false);
    } else {
      setHasTriedOne(true);
    }
  };

  const setTriedTwo = () => {
    if (triedTwo) {
      setHasTriedTwo(false);
    } else {
      setHasTriedTwo(true);
    }
  };

  const setTriedThree = () => {
    if (triedThree) {
      setHasTriedThree(false);
    } else {
      setHasTriedThree(true);
    }
  };

  const [updated, setUpdated] = useState(message);

  const handleChange = (event) => {
    console.log('Chegou algo? ', event);
    setMessage(event);
  };

  const classes = useStyles();
  const handleClick = () => {
    console.log('Message: ', message);
    setUpdated(message);
  };

  const [todoData, , loadingTodo, refetchTodo] = useQuery(
    () => invoiceService.listtodoConference(),
    [],
  );

  const filteredObjects = (todoData || []).filter((object) => {
    const loweredSearch = search.toLowerCase();

    return (
      object.nnf.toLowerCase().includes(loweredSearch) ||
      object.created_at.toLowerCase().includes(loweredSearch) ||
      object.total.toLowerCase().includes(loweredSearch)
    );
  });

  const [basicAccessories, , loadingBasicAccessories, refetchBasicAccessories] = useQuery(
    () => componentService.listSalesComponentsWhereProcess(2),
    [],
  );



  const accessoriesOptions = (basicAccessories || []).map((role) => ({
    value: role?.id,
    label: role?.description,
  }));

  const formikRef = useRef();

  const [infnfe, setInfnfe] = useState();

  const [keyNote, setKeyNote] = useState();

  const [expand, setExpand] = useState([]);

  const [posts, setPosts] = useState([]);

  const [pns, setPns] = useState();
  const [checkedPns, setCheckedPns] = useState([]);
  const [registeredPns, setRegisteredPns] = useState([]);

  const [checkedAccessories, setCheckedAccessories] = useState([]);
  const [registeredAccessories, setRegisteredAccessories] = useState([]);

  const [newAccessorieChecked, setNewAccessorieChecked] = useState([]);
  const [acessorieCame, setNewAccessorieCame] = useState([]);
  const [newAccessorie, setNewAccessorie] = useState([]);
  const [missingNewAccessorieChecked, setMissingNewAccessorieChecked] = useState([]);

  const [missingPns, setMissingPns] = useState([]);
  const [missingAccessories, setMissingAccessories] = useState([]);

  checkedPns?.every((item) => console.log('Item: ', item.imei));

  console.log('newAccessorie: ', newAccessorie);

  const formattedPns = (pns || []).map((b) => {
    return {
      ...b,
    };
  });

  const [colors, , loadingColors] = useQuery(() => serviceOrderService.listColorsProduct(), []);

  const colorsOptions = (colors || []).map((colors) => ({
    value: colors.description,
    label: colors.description,
  }));

  const filteredPns = formattedPns.filter((partNumber) => {
    const upperXprod = partNumber.xprod?.toUpperCase();
    const lowerXprod = partNumber.xprod?.toLowerCase();

    const upperCprod = partNumber.cprod?.toUpperCase();
    const lowerCprod = partNumber.cprod?.toLowerCase();

    return (
      upperXprod?.includes(search) ||
      lowerXprod?.includes(search) ||
      upperCprod?.includes(search) ||
      lowerCprod?.includes(search)
    );
  });

  const formattedAccessories = (basicAccessories || []).map((b) => {
    return {
      ...b,
    };
  });

  const [serviceOrderAccessories, , loadingServiceOrderAccessories] = useQuery(
    () => serviceOrderService.listServiceOrderAccessories(),
    [],
  );

  const filteredAccessories = formattedAccessories.filter((item) => {
    const upperPartNumber = item?.part_number?.toUpperCase();
    const lowerPartNumber = item?.part_number?.toLowerCase();

    const upperDescription = item?.description?.toUpperCase();
    const lowerDescription = item?.description?.toLowerCase();

    return (
      upperPartNumber?.includes(search) ||
      lowerPartNumber?.includes(search) ||
      upperDescription?.includes(search) ||
      lowerDescription?.includes(search)
    );
  });

  let total = 0;
  let totalMissing = 0;

  newAccessorieChecked?.map((totalPrice) => {
    total += totalPrice.sell_price;
  });

  missingNewAccessorieChecked?.map((totalPrice) => {
    totalMissing += totalPrice.sell_price;
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const handleDownloadTagsClick = async (services) => {
    const { data } = await serviceOrderService.downloadOpeningTags({ services });
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `services.pdf`);
  };

  const onSubmitHandler = async (values, { setSubmitting }) => {
    const arrayData = [];
    const arrayRegister = [];

    setInfnfe(values.infnfe);

    try {
      const { data } = await invoiceService.listInvoicesRepairXmls(values.infnfe);

      data?.map((addObj) => {
        addObj.isExpanded = false;

        arrayData.push(addObj);

        arrayRegister.push({
          infnfe: addObj.infnfe,
          prod_id: addObj.id,
          imei: undefined,
          ean: addObj.cean,
          mpc: addObj?.infCpl ?? undefined,
          lacre: undefined,
          observation: undefined,
          accessories_id: undefined,
          bounce: undefined,
          warranty_type: undefined,
          colors: undefined,
          process: undefined,
          modeldesc: undefined,
          device_defect: undefined,
          serial:undefined
        });
      });

      setKeyNote(data[0].infnfe);
      setPns(arrayData);
      setRegisteredPns(arrayRegister);
      handleDoesNotExist(arrayData);
      setSubmitting(true);
      values.infnfe = values.nnf;

      toast.success(`NFe's encontrada com sucesso.`);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao encontrar OS');
    } finally {
      handleSubmit(false);
    }
  };

  const handleChecked = (id) => {
    const indexObject = checkedPns.findIndex((item) => {
      return item.prod_id === id;
    });

    const indexObjectMiss = missingPns.findIndex((item) => {
      return item.prod_id === id;
    });

    const indexObjectRegister = registeredPns.filter((item) => {
      return item.prod_id === id;
    });

    if (indexObject > -1) {
      setCheckedPns(checkedPns.filter((_, item) => item !== indexObject));
      setMissingPns((oldState) => [...oldState, indexObjectRegister?.[0]]);
    } else {
      setCheckedPns((oldState) => [...oldState, indexObjectRegister?.[0]]);
      setMissingPns(missingPns.filter((_, item) => item !== indexObjectMiss));
    }
  };

  const handleIsCheckedPns = (id) => {
    const indexObject = checkedPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObject > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleFillInfoImei = (id, imei) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].imei = imei;
    }
  };


  const handleFillInfoSerial = (id, serial) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].serial = serial;
    }
  };

  const handleFillInfoMpc = (id, mpc) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].mpc = mpc;
    }
  };

  const handleFillInfoLacre = (id, lacre) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].lacre = lacre;
    }
  };

  const handleFillInfoFilial = (id, lacre) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].lacre = lacre;
    }
  };

  const handleFillAcessorieCame = (id, accessories_id) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].accessories_id = accessories_id;
    }
  };

  const handleFillColors = (id, colors) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].colors = colors;
    }
  };

  const handleFillProcess = (id, process) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].process = process;
    }
  };

  const handleFillInfoObservation = (id, observation) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].observation = observation;
    }
  };

  const handleFillInfoBounce = (id, bounce) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].bounce = bounce;
    }
  };

  const handleFillInfoColor = (id, color) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.color === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].color = color;
    }
  };

  const handleFillInfoWarrantyType = (id, warranty_type) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].warranty_type = warranty_type;
    }
  };

  const handleFillInfoDeviceDefect = (id, device_defect) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].device_defect = device_defect;
    }
  };

  const handleFillInfoModel = (id, modeldesc) => {
    const indexObjectRegister = registeredPns.findIndex((item) => {
      return item.prod_id === id;
    });

    if (indexObjectRegister > -1) {
      registeredPns[indexObjectRegister].modeldesc = modeldesc;
    }
  };

  const handleExpand = (id) => {
    if (expand.includes(id)) setExpand(expand.filter((item) => item !== id));
    else setExpand((oldState) => [...oldState, id]);
  };

  const handleAddAccessory = async (product, accessory) => {
    const { data } = await componentService.listSalesComponentsById(accessory.value);

    const accessoryList = {
      prod_id: product,
      accessory_id: data.id,
      part_number: data.part_number,
      sell_price: data.sell_price,
      description: data.description,
      infnfe,
      sales_component_id: data.id,
    };

    setNewAccessorie((oldState) => [...oldState, accessoryList]);
  };

  const handledeleteacessory = async (product, accessory) => {
    const { data } = await componentService.listSalesComponentsById(accessory.value);

    const accessoryList2 = {
      prod_id: '',
      accessory_id: '',
      part_number: '',
      sell_price: '',
      description: '',
      infnfe: '',
      sales_component_id: '',
    };

    setNewAccessorie((accessoryList2) => [...'', '']);
  };

  const handleCheckedNewCheckedAccessory = (product, accessory) => {
    const indexObject = newAccessorieChecked.findIndex((item) => {
      return item.prod_id === product && item.accessory_id === accessory;
    });

    const indexObjectMiss = missingNewAccessorieChecked.findIndex((item) => {
      return item.prod_id === product && item.accessory_id === accessory;
    });

    const indexObjectRegister = newAccessorie.filter((item) => {
      return item.prod_id === product && item.accessory_id === accessory;
    });

    if (indexObject > -1) {
      setNewAccessorieChecked(newAccessorieChecked.filter((_, item) => item !== indexObject));
      setMissingNewAccessorieChecked((oldState) => [...oldState, indexObjectRegister?.[0]]);
    } else {
      setNewAccessorieChecked((oldState) => [...oldState, indexObjectRegister?.[0]]);
      setMissingNewAccessorieChecked(
        missingNewAccessorieChecked.filter((_, item) => item !== indexObjectMiss),
      );
    }
  };

  const handleIsCheckedNewAccessory = (product, accessory) => {
    const indexObject = newAccessorieChecked.findIndex((item) => {
      return item.prod_id === product && item.accessory_id === accessory;
    });

    if (indexObject > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleDoesNotExist = (filledPns) => {
    const fillAllPns = [];
    const fillAllAccessories = [];

    filledPns?.map((fill) => {
      fillAllPns.push({
        infnfe: fill.infnfe,
        prod_id: fill.id,
        imei: undefined,
        ean: fill.cean,
        mpc: fill?.infCpl ?? undefined,
        lacre: undefined,
        observation: undefined,
        accessories_id: undefined,
        bounce: undefined,
        warranty_type: undefined,
        colors: undefined,
        process: undefined,
        modeldesc: undefined,
        device_defect: undefined,
        serial:undefined
      });

      basicAccessories?.map((fillAccessories) => {
        fillAllAccessories.push({
          prod_id: fill.id,
          accessory_id: fillAccessories.id,
          price: fillAccessories.price,
          infnfe: fill.infnfe,
        });
      });
    });

    setRegisteredAccessories(fillAllAccessories);
    setMissingPns(fillAllPns);
    setMissingAccessories(fillAllAccessories);
  };

  const handleSheetDownload = async (data) => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(data));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `conferencia${now}.xlsx`);
  };

  const [proccessData, , loadingProccess, refetchProcess] = useQuery(
    () => serviceOrderService.listProcesses(),
    [],
  );

  const processOptions = (proccessData || []).map((colors) => ({
    value: colors.id,
    label: colors.description,
  }));

  const serviceOrderAccessoriesOptions = (serviceOrderAccessories || []).map((accessory) => ({
    value: accessory.description,
    label: accessory.description,
  }));
  const handleDownloadConference = async (data) => {
    setTimeout(async () => {
      const { data } = await invoiceService.getReportConference(keyNote);

      handleSheetDownload(data);
    }, 2000);
  };

  const handleDownloadTags = async (infnfe) => {
    const { data } = await invoiceService.downloadConferenceInvoiceTags(infnfe);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${infnfe}.pdf`);
  };

  const handleApproved = async () => {
    try {
      const productsData = await invoiceService.createConferenceProduct(checkedPns, missingPns);
      await invoiceService.createConferenceAccessories(
        newAccessorieChecked,
        missingNewAccessorieChecked,
      );

      setTimeout(async () => {
        const { data } = await invoiceService.getReportConference(keyNote);

        handleDownloadConference(data);
        handleDownloadTags(keyNote);
        handleDownloadTagsClick(productsData?.data?.services);

        toast.success(`${productsData?.data?.services?.length} services importadas com sucesso`);
      }, 2000);

      refreshPage();

      setExpand([]);
      setPns();
      setCheckedPns([]);
      setRegisteredPns([]);
      setCheckedAccessories([]);
      setMissingPns([]);
      setMissingAccessories([]);

      toast.success('Conferência de produtos realizada com sucesso');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar a conferência de produtos.');
    } finally {
    }
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value).toUpperCase();
  };

  const handleDelete = (postIndex) => {
    setPosts((prevPosts) => prevPosts.filter((_, index) => index !== postIndex));
  };

  const refreshPage = () => {
    setTimeout(function () {
      window.location.reload();
    }, 10000);
  };



  console.log('registeredPns: ', registeredPns);

  return (
    <>
      <PageTitle>Conferência de nota de Reparo</PageTitle>
      <Formik innerRef={formikRef} initialValues={{ infnfe: '' }} onSubmit={onSubmitHandler} >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={4}>
                <Field
                  label='N° Nota'
                  name='infnfe'
                  variant='outlined'
                  size='small'
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button
                  disabled={props.isSubmitting || !props.values.infnfe}
                  type='submit'
                  fullWidth
                >
                  Pesquisar
                </Button>
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button
                  disabled={props.isSubmitting || !props.values.infnfe}
                  onClick={handleDownloadConference}
                  fullWidth
                  color='secondary'
                >
                  Baixar Relatório
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            {pns == undefined ? (
              <>
                <Typography variant='h6'>Notas aguardando conferência </Typography>
                <Grid container spacing={2} className={classes.formContainer}>
                  <Grid item xs={12} lg={12}>
                    <Text
                      label='Pesquisar NF'
                      size='small'
                      fullWidth
                      name='searched'
                      variant='outlined'
                      endAdornment={<SearchIcon />}
                      value={search}
                      onChange={handleSearchChange}
                      inputProps={{ maxLength: 15 }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Table
                    emptyMessage='Nenhum registro encontrado.'
                    headers={columns}
                    loading={loadingTodo}
                    striped
                    maxHeight={500}
                  >
                    {filteredObjects?.map((object) => (
                      <TableRow key={object?.id}>
                        {console.log('object: ', object)}
                        <TableCell>{object?.nnf}</TableCell>
                        <TableCell>{object?.infnfe}</TableCell>
                        <TableCell>{object?.total}</TableCell>
                        <TableCell>
                          <FormatDate format='dd/MM/yyyy HH:mm:ss'>{object?.created_at}</FormatDate>
                        </TableCell>
                        <TableCell>{object?.company_name}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </Grid>
              </>
            ) : (
              <></>
            )}

            {pns !== undefined ? (
              <>
                <Typography variant='h6' gutterBottom>
                  Conferência de Itens
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={5}>
                    <Text
                      label='Pesquisar'
                      size='small'
                      variant='outlined'
                      endAdornment={<SearchIcon />}
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper>
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell width={80}>Possui</TableCell>
                            <TableCell>Part-Number</TableCell>
                            <TableCell>Descrição</TableCell>
                            <TableCell>IMEI</TableCell>
                            <TableCell>SERIAL</TableCell>
                            <TableCell>MPC</TableCell>
                            <TableCell>Lacre</TableCell>
                            <TableCell>EAN</TableCell>
                            <TableCell>Filial de Origem</TableCell>
                            <TableCell>Bounce</TableCell>
                            <TableCell>Tipo de Garantia</TableCell>
                            <TableCell>Defeito</TableCell>
                            <TableCell>Modelo</TableCell>
                            <TableCell>Cor</TableCell>
                            <TableCell>Processo</TableCell>
                            <TableCell>Expandir</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredPns?.map((prod, indexx) => (
                            <React.Fragment key={prod.id}>
                              <TableRow key={prod.id}>
                                <TableCell size='small' align='center'>
                                  <Checkbox
                                    color='primary'
                                    onChange={() => handleChecked(prod.id)}
                                    checked={handleIsCheckedPns(prod.id)}
                                  />
                                </TableCell>
                                <TableCell>{prod.cprod}</TableCell>
                                <TableCell>{prod.xprod}</TableCell>
                                <TableCell>
                                  <Text
                                    label='IMEI'
                                    size='small'
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={(e) => handleFillInfoImei(prod.id, e.target.value)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Text
                                    label='SERIAL'
                                    size='small'
                                     onInput={toInputUppercase}
                                    inputProps={{ maxLength: 12, minlength: 10 }}

                                    onBlur={(e) => handleFillInfoSerial(prod.id, e.target.value)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Text
                                    label='MPC'
                                    size='small'
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={(e) => handleFillInfoMpc(prod.id, e.target.value)}
                                    value={prod?.infCpl ?? null}
                                    disabled={prod?.infCpl !== null || prod?.infCpl !== ''}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Text
                                    label='Lacre'
                                    size='small'
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={(e) => handleFillInfoLacre(prod.id, e.target.value)}
                                  />
                                </TableCell>
                                <TableCell>{prod.cean}</TableCell>
                                <TableCell>
                                  <Text
                                    label='Filial'
                                    size='small'
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={(e) => handleFillInfoFilial(prod.id, e.target.value)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl variant='standard' sx={{ m: 1, minWidth: 75 }}>
                                    <InputLabel id='demo-simple-select-standard-label'>
                                      Bounce
                                    </InputLabel>
                                    <Select
                                      labelId='demo-simple-select-standard-label'
                                      id='demo-simple-select-standard'
                                      onChange={(e) =>
                                        handleFillInfoBounce(prod.id, e.target.value)
                                      }
                                      label='bounce'
                                    >
                                      <MenuItem value={false}>Não</MenuItem>
                                      <MenuItem value={true}>Sim</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant='standard' sx={{ m: 1, minWidth: 145 }}>
                                    <InputLabel id='demo-simple-select-standard-label'>
                                      Tipo de Garantia
                                    </InputLabel>
                                    <Select
                                      labelId='demo-simple-select-standard-label'
                                      id='demo-simple-select-standard'
                                      onChange={(e) =>
                                        handleFillInfoWarrantyType(prod.id, e.target.value)
                                      }
                                      label='warranty_type'
                                    >
                                      <MenuItem value='In Warranty'>Em Garantia</MenuItem>
                                      <MenuItem value='Out of Warranty'>Fora de Garantia</MenuItem>
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <Text
                                    label='Defeito'
                                    size='small'
                                    name='defect'
                                    inputProps={{ maxLength: 500 }}
                                    onBlur={(e) =>
                                      handleFillInfoDeviceDefect(prod.id, e.target.value)
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <Text
                                    label='Modelo'
                                    size='small'
                                    inputProps={{ maxLength: 15 }}
                                    onBlur={(e) => handleFillInfoModel(prod.id, e.target.value)}
                                  />
                                </TableCell>
                                <TableCell>
                                  <FormControl variant='standard' sx={{ m: 1, minWidth: 75 }}>
                                    <InputLabel id='demo-simple-select-standard-label'>
                                      Cor
                                    </InputLabel>
                                    <Select
                                      labelId='demo-simple-select-standard-label'
                                      id='demo-simple-select-standard'
                                      onChange={(e) =>
                                        handleFillColors(prod.id, e.target.value)
                                      }
                                      label='color'
                                    >
                                      {colorsOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <FormControl variant='standard' sx={{ m: 1, minWidth: 90 }}>
                                    <InputLabel id='demo-simple-select-standard-label'>
                                      Processo
                                    </InputLabel>
                                    <Select
                                      labelId='demo-simple-select-standard-label'
                                      id='demo-simple-select-standard'
                                      onChange={(e) =>
                                        handleFillProcess(prod.id, e.target.value)
                                      }
                                      label='process'
                                    >
                                      {processOptions.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                    </Select>
                                  </FormControl>
                                </TableCell>
                                <TableCell>
                                  <IconButton onClick={() => handleExpand(prod.id)}>
                                    {expand.includes(prod.id) ? <SvgMore /> : <SvgLess />}
                                  </IconButton>
                                </TableCell>
                              </TableRow>

                              {expand.includes(prod.id) ? (
                                <TableRow>
                                  <TableCell colSpan={9}>
                                    <Table size='small'>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell colSpan={12}>
                                            <CustomAutocomplete
                                              key={prod.id}
                                              name='accessorie_filter'
                                              label='Digite a descrição do acessório...'
                                              filteredValues={accessoriesOptions}
                                            />
                                            <Button
                                              fullWidth
                                              onClick={() =>
                                                handleAddAccessory(
                                                  prod.id,
                                                  props.values.accessorie_filter,
                                                )
                                              }
                                            >
                                              Adicionar acessório
                                            </Button>
                                            <Spacer size={8} />
                                            {/* <Field
                                              multiple
                                              name='accessories_id'
                                              label='Acessórios que vieram'
                                              emptyOption='Limpar'
                                              size='small'
                                              fullWidth
                                              options={serviceOrderAccessoriesOptions}
                                              component={Select}
                                              onBlur={(e) => [
                                                handleFillAcessorieCame(prod.id, e.target.value),
                                              ]}
                                            /> */}
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell width={80}>Possui Accessório</TableCell>
                                          <TableCell>Part Number</TableCell>
                                          <TableCell>Descrição</TableCell>
                                          <TableCell>Preço</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {newAccessorie?.map((accessory, postIndex) =>
                                          accessory.prod_id === prod.id ? (
                                            <TableRow key={accessory.id}>
                                              <TableCell size='small' align='center'>
                                                <Checkbox
                                                  color='primary'
                                                  onChange={() =>
                                                    handleCheckedNewCheckedAccessory(
                                                      prod.id,
                                                      accessory?.accessory_id,
                                                    )
                                                  }
                                                  checked={handleIsCheckedNewAccessory(
                                                    prod.id,
                                                    accessory?.accessory_id,
                                                  )}
                                                />
                                              </TableCell>
                                              <TableCell>{accessory?.part_number}</TableCell>
                                              <TableCell>{accessory?.description}</TableCell>
                                              <TableCell>
                                                <FormatMoney>{accessory?.sell_price}</FormatMoney>
                                              </TableCell>
                                              <TableCell>
                                                <Button
                                                  color={'secondary'}
                                                  fullWidth
                                                  onClick={() =>
                                                    handledeleteacessory(
                                                      prod.id,
                                                      props.values.accessorie_filter,
                                                    )
                                                  }
                                                >
                                                  REMOVER
                                                </Button>
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            <></>
                                          ),
                                        )}

                                        <TableRow>
                                          <TableCell colSpan={9}>
                                            <TextField
                                              id='outlined-select-currency'
                                              select
                                              label='Acessórios que vieram'
                                              fullWidth
                                              helperText='Por favor selecione os acessórios que vieram'
                                              onBlur={(e) =>
                                                handleFillAcessorieCame(prod.id, e.target.value)
                                              }
                                              value={registeredPns[indexx]?.accessories_id}
                                            >
                                              {currencies.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                  {option.label}
                                                </MenuItem>
                                              ))}
                                            </TextField>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell colSpan={9}>
                                            <Text
                                              size='small'
                                              variant='outlined'
                                              label='Descrição de danos ou observações...'
                                              multiline
                                              rows={4}
                                              onBlur={(e) =>
                                                handleFillInfoObservation(prod.id, e.target.value)
                                              }
                                              value={registeredPns?.[indexx]?.observation}
                                            />
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <></>
                              )}
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  <Grid item xs={5} md={5} lg={5}>
                    <Typography variant='h6'>
                      Total Selecionados: <FormatMoney>{total}</FormatMoney>
                    </Typography>
                  </Grid>
                  <Grid item xs={5} md={5} lg={5}>
                    <Typography variant='h6'>
                      Total não selecionados: <FormatMoney>{totalMissing}</FormatMoney>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button
                      fullWidth
                      onClick={handleApproved}
                      disabled={
                        !checkedPns?.every((item) => item.imei) ||
                        !missingPns?.every((item) => item.imei)
                      }
                    >
                      Confirmar Conferência
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};
