/* eslint-disable no-restricted-imports */
import React, { useContext, useState } from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  CircularProgress,
} from '@material-ui/core';

import { Pagination } from '@mui/material';
import { Button, FormatDate } from 'components';

import { PickingContext } from '../..';
import { AvailableProductDialog } from '../AvailableProductDialog';
import { ConferenceDialog } from '../ConferenceDialog';
import { CreateBoxDialog } from '../CreateBoxDialog';

const columns = [
  { label: 'ID ANYMARKET', align: 'center' },
  { label: 'CÓDIGO PEDIDO', align: 'center' },
  { label: 'MARKETPLACE', align: 'center' },
  { label: 'CLIENTE', align: 'center' },
  { label: 'DATA PEDIDO', align: 'center' },
  { label: 'STATUS', align: 'center' },
  { label: 'SKU REQUISITADO', align: 'center' },
  { label: 'PRODUTO REQUISITADO', align: 'center' },
  { label: 'VOUCHER', align: 'center' },
  { label: 'IMEI', align: 'center' },
  { label: 'LOCAL', align: 'center' },
  { label: 'DATA RECEBIMENTO', align: 'center' },
  { label: 'AÇÕES', align: 'center' },
];

export function PickingB2CTable() {
  const { b2cOrders, b2cLoading, b2cRefetch, search } = useContext(PickingContext);

  const [isOpenAvailableProductDialog, setIsOpenAvailableProductDialog] = useState(false);
  const [isOpenConferenceDialog, setIsOpenConferenceDialog] = useState(false);
  const [isOpenCreateBoxDialog, setIsOpenCreateBoxDialog] = useState(false);

  const [selectItem, setSelectItem] = useState();

  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  function handleOpenAvailableProductDialog(item) {
    setSelectItem(item);
    setIsOpenAvailableProductDialog(true);
  }

  function handleCloseAvailableProductDialog() {
    setIsOpenAvailableProductDialog(false);
    b2cRefetch();
  }

  function handleOpenConferenceDialog(item) {
    setSelectItem(item);
    setIsOpenConferenceDialog(true);
  }

  function handleCloseConferenceDialog() {
    setIsOpenConferenceDialog(false);
    b2cRefetch();
  }

  function handleOpenCreateBoxDialog(item) {
    setSelectItem(item);
    setIsOpenCreateBoxDialog(true);
  }

  function handleCloseCreateBoxDialog() {
    setIsOpenCreateBoxDialog(false);
    b2cRefetch();
  }

  if (b2cLoading) {
    return (
      <Grid
        container
        direction='column'
        alignItems='center'
        justifyContent='center'
        style={{ minHeight: '70vh' }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  const filteredOrders = b2cOrders.filter(
    (order) =>
      order.anymarketId.toLowerCase().includes(search.toLowerCase()) ||
      order.orderCode.toLowerCase().includes(search.toLowerCase()) ||
      order.client.toLowerCase().includes(search.toLowerCase()),
  );

  const startIndex = (page - 1) * rowsPerPage;
  const paginatedResults = filteredOrders.slice(startIndex, startIndex + rowsPerPage);

  const getActionButton = (activeStep, item) => {
    const steps = {
      1: (
        <Button onClick={() => handleOpenAvailableProductDialog(item)}>
          Verificar disponibilidade
        </Button>
      ),
      2: (
        <Button color='secondary' onClick={() => handleOpenConferenceDialog(item)}>
          Conferência
        </Button>
      ),
      3: (
        <Button variant='outlined' onClick={() => handleOpenCreateBoxDialog(item)}>
          Montar BOX
        </Button>
      ),
    };

    return steps[activeStep];
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column.align} style={{ fontWeight: 'bold' }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(paginatedResults || []).map((item, index) => (
            <>
              <TableRow key={index}>
                <TableCell align='center'>{item.anymarketId}</TableCell>
                <TableCell align='center'>{item.orderCode}</TableCell>
                <TableCell align='center'>{item.marketplace}</TableCell>
                <TableCell align='center'>{item.client}</TableCell>
                <TableCell align='center'>
                  <FormatDate>{item.requestDate}</FormatDate>
                </TableCell>
                <TableCell align='center' style={{ fontWeight: 'bold' }}>
                  {item.status}
                </TableCell>
                <TableCell align='center'>{item.requestSku}</TableCell>
                <TableCell align='center'>{item.requestProduct}</TableCell>
                <TableCell align='center'>{item.suggestionSinister}</TableCell>
                <TableCell align='center'>{item.suggestionImei}</TableCell>
                <TableCell align='center'>{item.suggestionLocation}</TableCell>
                <TableCell align='center'>
                  {item.suggestionCreatedAt ? (
                    <FormatDate>{item.suggestioncreatedat}</FormatDate>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align='center'> {getActionButton(item.statusId, item)}</TableCell>
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>

      <Grid container justifyContent='center' style={{ marginTop: 20 }}>
        <Pagination
          count={Math.ceil(b2cOrders.length / rowsPerPage)}
          page={page}
          onChange={handlePageChange}
          color='secondary'
        />
      </Grid>

      <AvailableProductDialog
        isOpen={isOpenAvailableProductDialog}
        handleClose={handleCloseAvailableProductDialog}
        item={selectItem}
      />

      <ConferenceDialog
        isOpen={isOpenConferenceDialog}
        handleClose={handleCloseConferenceDialog}
        headerId={selectItem?.anymarketId}
      />

      <CreateBoxDialog
        isOpen={isOpenCreateBoxDialog}
        handleClose={handleCloseCreateBoxDialog}
        item={selectItem}
      />
    </TableContainer>
  );
}
