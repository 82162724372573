import React, { Fragment, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Button, Select, Text } from 'components';
import { SimpleTable } from 'components/SimpleTable';
import { Field, Formik, Form, useFormikContext } from 'formik';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { warehouseService } from 'services';
import { mailServices } from 'services/mail';

import { RegisterSigepDialog } from './RegisterSigepDialog';
import { useStyles } from './styles';
import { validationSchema } from './validation';

const columns = [
  { label: 'Contrato', width: 190 },
  { label: 'Prefixo', align: 'center' },
  { label: 'Posição do objeto', width: 190, align: 'center' },
  { label: 'Cartão postagem', align: 'center' },
  { label: <VisibilityIcon />, align: 'center' },
];

export function WarehouseRegisterSigep() {
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const classes = useStyles();
  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [sigep, setSigep] = useState({});

  const [mailContractsData, , loadingMailContracts] = useQuery(mailServices.listMailContracts, []);

  const [mailPrefixesData, , loadingMailPrefixes] = useQuery(mailServices.listMailPrefixes, []);

  const [sigepRangeObjects, , loadingSigepRangeObjects, refetchSigepObjects] = useQuery(
    () => warehouseService.listSigepRangeObjects(page, rowsPerPage, search),
    [page, rowsPerPage, search],
  );

  console.log('sigepRangeObjects ==>', sigepRangeObjects);

  const mailContractOptions = (mailContractsData || []).map((mailContract) => {
    return {
      value: mailContract.id,
      label:
        mailContract.contract_number +
        ' ' +
        mailContract.contract_method +
        ' ' +
        mailContract.additional_information,
    };
  });

  const mailPrefixOptions = (mailPrefixesData || []).map((mailPrefix) => {
    return {
      value: mailPrefix.id,
      label: mailPrefix.initials,
    };
  });

  const handleCloseInformation = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformation = (e, item) => {
    setIsDetailsState.toTrue();
    setSigep(item);
  };

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await warehouseService.createSigepRange(values);
      resetForm();

      refetchSigepObjects();
      toast.success('Sigep Range cadastrado');
    } catch (error) {
      console.log(error);
      toast.error('error');
    } finally {
      setSubmitting(false);
    }
  };

  const HandleReset = () => {
    const { resetForm } = useFormikContext();

    return (
      <Button fullWidth type='reset' variant='outlined' onClick={resetForm}>
        Limpar
      </Button>
    );
  };

  return (
    <>
      <PageTitle>Warehouse Range dos Correios</PageTitle>
      <Formik
        initialValues={{
          mail_contract_id: 0,
          mail_prefix_id: 0,
          initial_range: undefined,
          final_range: undefined,
          post_card: '',
        }}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {(props) => (
          <>
            <Form onSubmit={props.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <Typography variant='h6' gutterBottom>
                    Informações iniciais
                  </Typography>
                  <Field
                    size='small'
                    fullWidth
                    name='mail_contract_id'
                    variant='outlined'
                    label='Contrato'
                    options={mailContractOptions}
                    loading={loadingMailContracts}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.containerTitle} variant='h6' gutterBottom>
                    Dados do Range
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={3}>
                  <Field
                    size='small'
                    fullWidth
                    name='mail_prefix_id'
                    variant='outlined'
                    label='Prefixo'
                    options={mailPrefixOptions}
                    loading={loadingMailPrefixes}
                    component={Select}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    defaultValue={1}
                    label='Faixa Inicial'
                    name='initial_range'
                    inputProps={{ minLength: 8, maxLength: 8 }}
                    mask='number'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <Field
                    size='small'
                    variant='outlined'
                    fullWidth
                    label='Faixa Final'
                    name='final_range'
                    inputProps={{ minLength: 8, maxLength: 8 }}
                    mask='number'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.containerTitle} variant='h6' gutterBottom>
                    Cartão
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Field
                    size='small'
                    variant='outlined'
                    label='Cartão Postagem'
                    name='post_card'
                    component={Text}
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={2}>
                  <Button
                    fullWidth
                    color='primary'
                    type='submit'
                    disabled={!props.dirty || !props.isValid}
                  >
                    Cadastrar
                  </Button>
                </Grid>
                <Grid item xs={6} md={6} lg={2}>
                  <HandleReset />
                </Grid>
                <Grid item xs={12}>
                  <SimpleTable
                    headers={columns}
                    totalCount={sigepRangeObjects?.totalCount ?? 1}
                    isLoading={loadingSigepRangeObjects}
                    handleChangePage={handleChangePage}
                    page={page}
                  >
                    {sigepRangeObjects?.results?.map((row, index) => (
                      <Fragment key={index}>
                        <TableRow>
                          <TableCell>{row?.mailContract?.contract_method}</TableCell>
                          <TableCell align='center'>{row?.mailPrefix?.initials}</TableCell>
                          <TableCell align='center'>{row?.range_position_number}</TableCell>
                          <TableCell align='center'>{row?.post_card}</TableCell>
                          <TableCell align='center'>
                            <IconButton
                              color='primary'
                              onClick={(e) => handleOpenInformation(e, row)}
                            >
                              <AddCircleIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </SimpleTable>
                </Grid>
                <RegisterSigepDialog
                  open={isDetailsOpen}
                  handleClose={handleCloseInformation}
                  mailContractOptions={mailContractOptions}
                  mailPrefixOptions={mailPrefixOptions}
                  sigep={sigep}
                  reloadSigep={refetchSigepObjects}
                />
              </Grid>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
