import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';

import { Table, Text, Button, FormatDate, DatePicker, Dialog } from 'components';
import { FormatMoney } from 'components/FormatMoney';
import { Formik, Form, Field } from 'formik';
import { normalizeValue } from 'helpers/normalizeValue';
import { useQuery } from 'hooks/useQuery';
import { browserHistory } from 'routes/browserHistory';
import { budgetService, serviceOrderService } from 'services';
import XLSX from 'xlsx';

import { useStyles } from './styles';

const columns = [
  { label: 'Processo' },
  { label: 'Informação adicional' },
  { label: 'Sinistro' },
  { label: 'Service' },
  { label: 'Data Orçamento' },
  { label: 'Nome Cliente' },
  { label: 'Técnico' },
  { label: 'Status Orçamento' },
  { label: 'Valor Total' },
  { label: 'Valor Negociação' },
  { label: 'Ações', align: 'center', width: 170 },
];

export const TableSacSeg = () => {
  TableSacSeg.onload = async (event) => {
    () => setInputData(props.values.output_data);
  };
  const classes = useStyles();
  const [service, setService] = useState();
  const [sinister, setSinister] = useState('');
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [inputData, setInputData] = useState(new Date());
  const [outputData, setOutputData] = useState(new Date());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const downloadArchive = async (values) => {
    try {
      const { data } = await serviceOrderService.getReportFile(values);
      setService(values);
      setSinister(data[0].report_insurance);
      handleClickOpen();
      toast.success('Consulta realizada com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao consultar laudo da service');
    }
  };

  const [budgetsDataDate, , loadingBudgetsDate, refetchBudgetsDataDate] = useQuery(
    () => budgetService.listPreBudgetsAnalyzedFromTechnicianInPcpDateSeg(inputData, outputData),
    [inputData, outputData],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const cancelPreBudget = async (service_order_number, cancel_budget_reason_id) => {
    try {
      await budgetService.cancelPreBudget(service_order_number, cancel_budget_reason_id);
      refetchPreBudgetsData();
      toast.success('Pré-orçamento cancelado com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Falha ao cancelar o pré-orçamento.');
    } finally {
    }
  };

  const handleDownloadBudgetSac = async () => {
    const now = new Date();
    const worksheet = (XLSX.WorkSheet = XLSX.utils.json_to_sheet(budgetsDataDate));
    const workbook = (XLSX.WorkBook = {
      Sheets: {
        mySheet: worksheet,
      },
      SheetNames: ['mySheet'],
    });
    XLSX.writeFile(workbook, `RelatórioOrçamentoFinalizados${now}.xlsx`);
  };

  const navigateTo = (budget, service_order) => {
    browserHistory.push({
      pathname: '/conference/additional-services',
      state: {
        budget_id: budget,
        service_order: service_order,
      },
    });
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  const filteredComponents = useMemo(() => {
    const searchLower = search.toLowerCase();
    const normalizedSearch = normalizeValue(searchLower);

    return budgetsDataDate?.filter((budget) => {
      const normalizedTotalPrice = normalizeValue(budget?.components_total_price);
      const normalizedNegociationPrice = normalizeValue(budget?.negociation_price);
      return (
        (budget.description && budget.description.toLowerCase().includes(searchLower)) ||
        (budget.insurance && budget.insurance.toLowerCase().includes(searchLower)) ||
        (normalizedTotalPrice && normalizedTotalPrice.includes(normalizedSearch)) ||
        (normalizedNegociationPrice && normalizedNegociationPrice.includes(normalizedSearch)) ||
        (budget.number && budget.number.toLowerCase().includes(searchLower)) ||
        (budget.created_at && String(budget.created_at).toLowerCase().includes(searchLower)) ||
        (budget.client && String(budget.client).toLowerCase().includes(searchLower)) ||
        (budget.status_orc && String(budget.status_orc).toLowerCase().includes(searchLower)) ||
        (budget.name && String(budget.name).toLowerCase().includes(searchLower))
      );
    });
  }, [budgetsDataDate, search]);

  const today = new Date();

  return (
    <>
      <Formik
        initialValues={{
          input_data: new Date(new Date().setDate(today.getDate() - 30)),
          output_data: new Date(),
          search_os: '',
        }}
      >
        {(props) => (
          <Form>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Field
                  size='small'
                  variant='outlined'
                  name='search_os'
                  fullWidth
                  endAdornment={<SearchIcon />}
                  label='Pesquisar'
                  component={Text}
                  onChange={handleSearchChange}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='input_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de entrada'
                  component={DatePicker}
                  OnChange={() => setInputData(props.values.input_data)}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Field
                  size='small'
                  name='output_data'
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  label='Data de saída'
                  component={DatePicker}
                  OnChange={() => setInputData(props.values.output_data)}
                />
              </Grid>{' '}
              <Grid item xs={12} lg={3}>
                <Button onClick={handleDownloadBudgetSac} fullWidth>
                  Exportar relatório
                </Button>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Table
                  headers={columns}
                  loading={loadingBudgetsDate}
                  stripped
                  maxHeight
                  emptyMessage='Nenhum registro encontrado.'
                  page={page}
                  data={filteredComponents}
                  rowsPerPage={rowsPerPage}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  disableNumeration
                >
                  {useEffect(() => {
                    setInputData(props.values.input_data);
                    setOutputData(props.values.output_data);
                    refetchBudgetsDataDate();
                  }, [props.values.input_data, props.values.output_data])}

                  {filteredComponents?.map((budget) => (
                    <TableRow key={budget.id}>
                      <TableCell>{budget.description}</TableCell>
                      <TableCell>{budget.insurance}</TableCell>
                      <TableCell>{budget.search_website_value}</TableCell>
                      <TableCell>{budget.number}</TableCell>
                      <TableCell>
                        <FormatDate>{budget.created_at}</FormatDate>
                      </TableCell>
                      <TableCell>{budget.client}</TableCell>
                      <TableCell>{budget.name}</TableCell>
                      <TableCell>{budget.status_orc}</TableCell>
                      <TableCell>
                        <FormatMoney>{budget?.components_total_price}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <FormatMoney>{budget?.negociation_price}</FormatMoney>
                      </TableCell>
                      <TableCell>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Button
                            className={classes.tableButton}
                            onClick={() => navigateTo(budget?.id, budget?.number)}
                            size='small'
                          >
                            Orçar
                          </Button>

                          <Button
                            className={classes.tableButton}
                            onClick={() => downloadArchive(budget?.number)}
                            size='small'
                            color='secondary'
                            variant='text'
                          >
                            Baixar Laudo
                          </Button>
                          <Button
                            className={classes.tableButton}
                            onClick={() => cancelPreBudget(budget.id)}
                            size='small'
                            variant='text'
                          >
                            Cancelar
                          </Button>
                        </div>

                        <Grid container spacing={2}>
                          {service ? (
                            <>
                              <Dialog onClose={handleClose} maxWidth='md' open={open}>
                                <Dialog.Title>Visualizar Anexo</Dialog.Title>
                                <Dialog.Content>
                                  {/* <DownloadPDF fileName='laudo' url={sinister} /> */}
                                  <a
                                    href={sinister}
                                    target='_blank'
                                    // download
                                    style={{ textDecoration: 'none', color: 'black' }}
                                    rel='noreferrer'
                                  >
                                    Clique aqui para abrir seu comprovante
                                  </a>
                                </Dialog.Content>
                                <Dialog.Actions>
                                  <Button onClick={handleClose} variant='text'>
                                    Fechar
                                  </Button>
                                </Dialog.Actions>
                              </Dialog>
                            </>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
};
