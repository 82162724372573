/* eslint-disable react/no-unknown-property */
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
// eslint-disable-next-line import-helpers/order-imports
import WarningIcon from '@mui/icons-material/Warning';

// import SearchIcon from '@material-ui/icons/Search';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { ReactComponent as UploadImg } from 'assets/images/testeImageUpload.svg';
import { Button } from 'components';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';
import XLSX from 'xlsx';

import { useStyles } from './styles';

const data = XLSX.utils.json_to_sheet(
  [
    { S: 1, h: 2, e: 3, e_1: 4, t: 5, J: 6, S_1: 7 },
    { S: 2, h: 3, e: 4, e_1: 5, t: 6, J: 7, S_1: 8 },
  ],
  { skipHeader: 1 },
);

export const importStockUpdate = () => {
  const [importStock, setImportStock] = useState();
  const [isWarningDialog, setIsWarningDialog] = useState(false);

  const formikRef = useRef();

  const [importing, setImporting] = useState(false);

  const classes = useStyles();

  const handleDownloadLayoutServiceOrders = async () => {
    const wb = XLSX.utils.book_new();

    const ws = XLSX.utils.json_to_sheet(
      [
        {
          a: 'Preço de compra',
          b: 'Part-Number',
          c: 'Valor total do produto',
          d: 'Valor unitário tributado',
          e: 'icms_vbc',
          f: 'icms_picms',
          g: 'icms_vicms',
          h: 'icms_vbcst',
          i: 'icms_picmsst',
          j: 'icms_vicmsst',
          k: 'ipitrib_vbc',
          l: 'ipitrib_pipi',
          m: 'ipitrib_vipi',
          n: 'pisaliq_vbc',
          o: 'pisaliq_ppis',
          p: 'pisaliq_vpis',
          q: 'cofinsaliq_vbc',
          r: 'cofinsaliq_pcofins',
          s: 'cofinsaliq_vcofins',
          t: 'pmvast',
          t: 'infnfe',
        },
      ],
      { skipHeader: 1 },
    );

    XLSX.utils.book_append_sheet(wb, ws, 'No Header');

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'LayoutImportStock.xlsx');
  };

  const inputFileRef = useRef(null);

  const handleFileChange = async (e) => {
    try {
      setImporting(true);

      const file = e.target.files[0];

      if (file.name.match('.xlsx')) {
        toast.error('Adicione um arquivo do tipo CSV');
        return;
      }

      const reader = new FileReader();

      reader.onload = async (event) => {
        const result = [event.target.result];

        const bufferString = result.toString();

        const buffer1 = bufferString.replace(/\t|/g, '');

        const buffer2 = buffer1.replace(/[\\"]/g, '');

        const arr = buffer2.split('\r\n');

        const getStock = arr.map(function (obj) {
          if (
            obj.split(';')[0] == null ||
            obj.split(';')[0] == undefined ||
            obj.split(';')[0] == ''
          ) {
            return false;
          } else {
            const dados = {
              purchase_price: obj.split(';')[0],
              part_number: obj.split(';')[1],
              vuncom: obj.split(';')[2],
              vprod: obj.split(';')[3],
              vuntrib: obj.split(';')[4],

              icms_vbc: obj.split(';')[5],
              icms_picms: obj.split(';')[6],
              icms_vicms: obj.split(';')[7],
              icms_vbcst: obj.split(';')[8],
              icms_picmsst: obj.split(';')[9],
              icms_vicmsst: obj.split(';')[10],

              ipitrib_vbc: obj.split(';')[11],
              ipitrib_pipi: obj.split(';')[12],
              ipitrib_vipi: obj.split(';')[13],

              pisaliq_vbc: obj.split(';')[14],
              pisaliq_ppis: obj.split(';')[15],
              pisaliq_vpis: obj.split(';')[16],

              cofinsaliq_vbc: obj.split(';')[17],
              cofinsaliq_pcofins: obj.split(';')[18],
              cofinsaliq_vcofins: obj.split(';')[19],

              pmvast: obj.split(';')[20],

              infnfe: obj.split(';')[21],
            };

            return dados;
          }
        });

        const filteredStock = getStock.filter((stocks) => {
          if (stocks.infnfe == undefined || stocks.infnfe == null || stocks.infnfe == '') {
            return false;
          }
          return stocks;
        });

        let arrayFile = [];

        const stockFilter = Object.assign(filteredStock).map(async function (key) {
          const stock = {
            purchase_price: key.purchase_price,
            part_number: key.part_number,
            vuncom: key.vuncom,
            vprod: key.vprod,
            vuntrib: key.vuntrib,

            icms_vbc: key.icms_vbc,
            icms_picms: key.icms_picms,
            icms_vicms: key.icms_vicms,

            icms_vbcst: key.icms_vbcst,
            icms_picmsst: key.icms_picmsst,
            icms_vicmsst: key.icms_vicmsst,

            ipitrib_vbc: key.ipitrib_vbc,
            ipitrib_pipi: key.ipitrib_pipi,
            ipitrib_vipi: key.ipitrib_vipi,

            pisaliq_vbc: key.pisaliq_vbc,
            pisaliq_ppis: key.pisaliq_ppis,
            pisaliq_vpis: key.pisaliq_vpis,

            cofinsaliq_vbc: key.cofinsaliq_vbc,
            cofinsaliq_pcofins: key.cofinsaliq_pcofins,
            cofinsaliq_vcofins: key.cofinsaliq_vcofins,

            pmvast: key.pmvast,

            infnfe: key.infnfe.replace('Nfe', ''),
          };

          return arrayFile.push(stock);
        });

        await invoiceService
          .updateStockByFile({
            stockComponents: arrayFile,
          })
          .then((response) => {
            toast.success(`${response.data.length} estoque atualizado com sucesso`);
            setImporting(false);
          })
          .catch((err) => {
            toast.error(
              err?.response?.data?.message ||
                'Ocorreu um erro ao realizar a atualização dos componentes',
            );
            setImporting(false);
          })
          .finally(() => {
            setImporting(false);
          });
      };

      reader.readAsText(file);
    } catch (err) {
      console.log(err);
      toast.error(err.response?.data?.message);
      setImporting(false);
    }
  };

  const handleImportClick = () => {
    inputFileRef.current.click();
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='csv'
        onChange={handleFileChange}
        accept='text/csv'
        hidden
      />

      <PageTitle>Importar Atualização de Componentes por Planilha</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <Paper className={classes.gridPaper} variant='outlined'>
            <Grid className={classes.contextPaper} container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Button
                  fullWidth
                  onClick={handleImportClick}
                  type=''
                  // disabled={importing || !props.dirty || !props.isValid}
                  startIcon={<FileUploadIcon />}
                >
                  Importar planilha
                </Button>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Button
                  fullWidth
                  variant='outlined'
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadLayoutServiceOrders}
                  disabled={importing}
                >
                  Baixar planilha
                </Button>
              </Grid>

              {importing ? (
                <Grid xs={6} lg={12} className={classes.loading}>
                  <Box sx={{ width: '90%' }}>
                    <LinearProgress color='secondary' />
                  </Box>
                </Grid>
              ) : (
                <Grid className={classes.gridImages} xs={6} lg={12}>
                  {' '}
                  <UploadImg className={classes.imgTracker} />
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <div className={classes.containerLegend}>
            <WarningIcon color='warning' />
            <p variant='caption' className={classes.p}>
              Máximo de importação por vez - 10000
            </p>
          </div>
        </Grid>
        <Grid item xs={12} lg={3}>
          <div className={classes.containerLegend}>
            <PriorityHighIcon color='warning' />
            <p variant='caption' className={classes.p}>
              Esperar carregamento completo da importação.
            </p>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
