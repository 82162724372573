import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  number: yup.string(),
  has_nf: yup
    .boolean()
    .required('É necessário selecionar uma opção para Chave de NF')
    .nullable('Selecione uma opção para Chave de NF'),
  process_type_id: yup
    .number()
    .required('É necessário selecionar uma opção para o processo')
    .nullable('Selecione uma opção para o processo.'),
});
