import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import { invoiceService } from 'services';

import { useStyles } from './styles';

export function ReprintTagConferenceInvoiceRepair() {
  const classes = useStyles();

  const handleDownloadTagsClick = async (infnfe) => {
    console.log('infnfe: ', infnfe);
    const { data } = await invoiceService.downloadConferenceInvoiceTags(infnfe);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${infnfe}.pdf`);
  };

  const onSubmitHandler = (values, { setSubmitting }) => {
    setSubmitting(true);
    console.log(values);
    handleDownloadTagsClick(values.infnfe)
      .then((response) => {
        console.log('Response: ', response);

        setTimeout(() => {
          setSubmitting(false);
          toast.success('Reimpressão realizada com sucesso.');
        }, 2000);
      })
      .catch((err) => {
        setSubmitting(false);

        const enc = new TextDecoder('utf-8');
        const arr = new Uint8Array(err?.response?.data);
        const result = enc?.decode(arr);

        const parsedResult = JSON?.parse(result);

        toast.error(parsedResult?.message);
      });
  };

  return (
    <>
      <PageTitle>Reimpressão de TAG de conferência</PageTitle>
      <Formik initialValues={{ infnfe: '' }} onSubmit={onSubmitHandler}>
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={3}>
                <Field
                  label='N° Nota entrada'
                  name='infnfe'
                  variant='outlined'
                  size='small'
                  component={Text}
                />
              </Grid>

              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Reimprimir
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <img className={classes.imgContainer} src={checkKitImg} />
    </>
  );
}
