import React, { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import clsx from 'clsx';
import { Text, Table } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { useContextSelector } from 'use-context-selector';

import { RequestComponentContext } from '../context';
import { ConfirmDialog } from './ConfirmDialog';
import { useStyles } from './styles';

const columns = [
  { label: 'Técnico' },
  { label: 'Part Number' },
  { label: 'Descrição' },
  { label: 'Local' },
  { label: 'Quantidade' },
  { label: 'Ações', align: 'center' },
];

export const PendingRequest = () => {
  const classes = useStyles();

  const [search, setSearch] = useState('');
  const [confirmDialog, setConfirmDialog] = useBoolean();
  const [dialogProps, setDialogProps] = useState({});
  const [selectedOrdering, setSelectedOrdering] = useState({});

  const orderings = useContextSelector(RequestComponentContext, (context) => context.orderings);
  const loadingOrderings = useContextSelector(
    RequestComponentContext,
    (context) => context.loadingOrderings,
  );

  const filteredOrderings = (orderings || []).filter((order) => {
    const { user, component, quantity } = order;
    const lowerDesc = component.description.toLowerCase();
    const lowerCompDesc = component.component_location.description.toLowerCase();
    const lowerPartNumber = component.part_number.toLowerCase();
    const lowerUserName = user.name.toLowerCase();

    return (
      lowerDesc.includes(search) ||
      quantity == search ||
      lowerPartNumber.includes(search) ||
      lowerUserName.includes(search) ||
      lowerCompDesc.includes(search)
    );
  });

  const handleChange = (e) => {
    const v = e.target.value;

    setSearch(v);
  };

  const handleActionClick = (dialogProps, ordering) => {
    setDialogProps(dialogProps);
    setSelectedOrdering(ordering);
    setConfirmDialog.toTrue();
  };

  return (
    <>
      <ConfirmDialog
        open={confirmDialog}
        onClose={setConfirmDialog.toFalse}
        dialogProps={dialogProps}
        selectedOrdering={selectedOrdering}
      />
      <Grid className={classes.root} container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Consultar componente'
            size='small'
            variant='outlined'
            fullWidth
            onChange={handleChange}
            value={search}
            endAdornment={<SearchIcon />}
          />
        </Grid>
        <Grid item xs={12}>
          <Table
            headers={columns}
            emptyMessage='Nenhum registro encontrado.'
            loading={loadingOrderings}
          >
            {filteredOrderings?.map((ordering) => {
              const { id, user, component, quantity } = ordering;
              return (
                <TableRow key={id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{component.part_number}</TableCell>
                  <TableCell>{component.description}</TableCell>
                  <TableCell>{component.component_location.description}</TableCell>
                  <TableCell align='center'>{quantity}</TableCell>
                  <TableCell>
                    <div className={classes.actions}>
                      <IconButton
                        onClick={() =>
                          handleActionClick(
                            {
                              title: 'Tem certeza que deseja cancelar a requisição?',
                              accept: false,
                            },
                            ordering,
                          )
                        }
                      >
                        <CloseIcon className={clsx(classes.actionIcon, classes.closeIcon)} />
                      </IconButton>
                      <IconButton
                        onClick={() =>
                          handleActionClick(
                            {
                              title: 'Tem certeza que deseja aceitar a requisição?',
                              accept: true,
                            },
                            ordering,
                          )
                        }
                      >
                        <CheckIcon className={clsx(classes.actionIcon, classes.checkIcon)} />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </Table>
        </Grid>
      </Grid>
    </>
  );
};
