import { api } from 'services/api';
import { externalApi } from 'services/externalApi';

export class CollaboratorsService {
  async listPitziAgingReport(input_date, output_date) {
    return api.get(`/external-collaborators/report/aging-report/${input_date}/${output_date}`);
  }

  async listPitziAgingReportApple(input_date, output_date) {
    return externalApi.get(
      `/external-collaborators/report/aging-report/${input_date}/${output_date}`,
    );
  }

  async getRealTimeProductSearch(search) {
    return await api.get(`/external-collaborators/rapid/${search}`);
  }

  async calcTradeIn(tradInInformation) {
    return await api.put(`/external-collaborators/tradIn`, { tradInInformation });
  }

  async createTradeIn(allInformation, condition, offerValue) {
    return await api.post(`/external-collaborators/tradIn`, {
      allInformation,
      condition,
      offerValue,
    });
  }
}

export const collaboratorsService = new CollaboratorsService();
