import React, { useContext } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Tabs } from 'components/Tabs';

import { ClientInfo } from './clientContent';
import { SaleContent } from './saleContent';

import { SaleContext } from '..';

export const SaleInformation = ({ open, handleClose, sale }) => {
  const { serviceTypeSelect } = useContext(SaleContext);

  if (!open) return null;

  console.log('oxe: ', sale);

  return (
    <Dialog
      maxWidth='lg'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <DialogTitle id='customized-dialog-title' onClose={handleClose}>
        Informações da venda
      </DialogTitle>
      <Tabs>
        <Tabs.Content label='Cliente'>
          <ClientInfo
            clientInfo={
              serviceTypeSelect == 1
                ? sale?.accessory_sale?.client
                : serviceTypeSelect == 3
                ? sale?.accessory_sale?.client
                : sale?.client
            }
          />
        </Tabs.Content>
        <Tabs.Content label='Detalhes da venda'>
          <SaleContent
            sale={
              serviceTypeSelect == 1
                ? {
                    solded_accessories: sale?.accessory_sale?.solded_accessories,
                    sale_total_price: sale?.accessory_sale?.sale_total_price,
                    discount: sale?.accessory_sale?.discount,
                    amount: sale?.amount,
                    sell_price_total_quantity: sale?.sell_price_total_quantity,
                    coupon_url: sale?.accessory_sale?.coupon_url,
                  }
                : serviceTypeSelect == 3
                ? {
                    solded_accessories: sale?.accessory_sale?.solded_accessories,
                    sale_total_price: sale?.accessory_sale?.sale_total_price,
                    discount: sale?.accessory_sale?.discount,
                    amount: sale?.amount,
                    sell_price_total_quantity: sale?.sell_price_total_quantity,
                    coupon_url: sale?.accessory_sale?.coupon_url,
                  }
                : {
                    solded_services: sale?.solded_services,
                    sale_total_price: sale?.sale_total_price,
                    discount: sale?.discount,
                  }
            }
          />
        </Tabs.Content>
      </Tabs>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color='primary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
