import React from 'react';

import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import { Button, Dialog, Tabs } from 'components';
import { Form, Formik } from 'formik';

export const DialogMoreInformation = ({
  open,
  allInformation,
  handleClose,
  handleMyProduct,
  finishTemplate,
}) => {
  const notAtributes = [
    'on_sale',
    'tax',
    'shipping',
    'store_review_count',
    'original_price',
    'store_reviews_page_url',
  ];

  const especificationColumns = Object.keys(allInformation.product_attributes || {});
  const especificationValues = Object.values(allInformation.product_attributes || {});

  const offerColums = Object.keys(allInformation.offer || {});
  const offerValues = Object.values(allInformation.offer || {});

  const partialOfferColumns = [];
  const partialOfferValues = [];

  offerColums.map((item, index) => {
    if (!notAtributes.includes(item)) {
      partialOfferValues.push(offerValues[index]);
      switch (item) {
        case 'store_name':
          partialOfferColumns.push('Loja');
          break;
        case 'store_rating':
          partialOfferColumns.push('Avaliação');
          break;
        case 'offer_page_url':
          partialOfferColumns.push('Link');
          break;
        case 'store_review_count':
          partialOfferColumns.push('Quantidade de avaliações da loja');
          break;
        case 'store_reviews_page_url':
          partialOfferColumns.push('Link da loja');
          break;
        case 'price':
          partialOfferColumns.push('Preço');
          break;
        case 'shipping':
          partialOfferColumns.push('Envio');
          break;
        case 'tax':
          partialOfferColumns.push('Taxa');
          break;
        case 'on_sale':
          partialOfferColumns.push('À venda');
          break;
        case 'original_price':
          partialOfferColumns.push('Preço original');
          break;
        case 'product_condition':
          partialOfferColumns.push('Condição do produto');
          break;
        default:
          break;
      }
    }
  });

  console.log('partialOfferValues', partialOfferValues);
  console.log('partialOfferColumns', partialOfferColumns);

  return (
    <Dialog maxWidth='lg' onClose={handleClose} open={open}>
      <Dialog.Title onClose={handleClose}>Produto: {allInformation.title}</Dialog.Title>
      <Dialog.Content>
        <Formik>
          {(props) => (
            <>
              <Form>
                <Tabs>
                  <Tabs.Content label='Especificações'>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                        <TableBody>
                          {especificationColumns.map((column, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography gutterBottom variant='h6'>
                                  {column}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {' '}
                                <Typography variant='body2' color='text.secondary'>
                                  {especificationValues[index]}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Tabs.Content>
                  <Tabs.Content label='Descrição'>
                    <Typography>{allInformation.description}</Typography>
                  </Tabs.Content>
                  <Tabs.Content label='Imagens'>
                    <Grid container spacing={2}>
                      {allInformation.product_photos.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                          <img
                            src={item}
                            alt={`Product Photo ${index + 1}`}
                            style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Tabs.Content>
                  <Tabs.Content label='Valores'>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
                        <TableBody>
                          {partialOfferColumns.map((column, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                <Typography gutterBottom variant='h6'>
                                  {column}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {' '}
                                <Typography variant='body2' color='text.secondary'>
                                  {column == 'Link' ? (
                                    <Link
                                      href={partialOfferValues[index]}
                                      underline='always'
                                      target='_blank'
                                    >
                                      {partialOfferValues[index]}
                                    </Link>
                                  ) : (
                                    partialOfferValues[index]
                                  )}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Tabs.Content>
                </Tabs>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
      <DialogActions>
        <Button fullWidth color='secondary' onClick={handleClose}>
          Fechar
        </Button>
        <Button fullWidth color='primary' onClick={handleMyProduct} disabled={finishTemplate}>
          Esse é o meu aparelho!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
