import { gql } from '@apollo/client';

export const LIST_PROVIDERS = gql`
  query {
    listProviders {
      id
      description
    }
  }
`;
