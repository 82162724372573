import React, { createContext, useCallback, useContext, useState } from 'react';
import { toast } from 'react-toastify';

import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { withStyles } from '@material-ui/core/styles';

import { Dialog } from 'components';
import { Form, Formik } from 'formik';
import { cashierService } from 'services/cashier';

import { Actions } from './Actions';
import { CartWithItems } from './CartWithItems';
import { ClientInfo } from './ClientInfo';
import { validationSchema } from './ClientInfo/validation';
import { ConfirmPurchase } from './ConfirmPurchase';
import { StepIconComponent } from './StepIcon';

import { CartContext } from '..';

import { cleanCartAction } from './store/actions';

const steps = [
  {
    label: 'Carrinho',
  },
  {
    label: 'Dados do cliente',
  },
  {
    label: 'Confirmar',
  },
];

const Connector = withStyles((theme) => ({
  alternativeLabel: {
    top: 24,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  completed: {
    '& $line': {
      borderColor: theme.palette.primary.main,
    },
  },
  line: {
    borderColor: '#c8c8cc',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}))(StepConnector);

export const ClientContext = createContext();

export const Cart = ({ open, onClose, selectedComponent = {} }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [hasClient, setHasClient] = useState(false);
  const [clientExists, setClientExists] = useState(false);

  const {
    serviceTypeSelect,
    state,
    totalCart,
    dispatch,
    refetchComponentsCashier,
    refetchLabors,
    refetchComponentsCashierWholesale,
  } = useContext(CartContext);

  const handleDownloadTagsClick = async (id, client_id, accessories) => {
    console.log('Aqui chegou: ', accessories);
    const { data } = await cashierService.downloadNoFiscalCoupon(id, client_id, accessories);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${id}.pdf`);
    console.log(data);
  };

  const handleDownloadTagsClickService = async (id, client_id, labors) => {
    console.log('Labors Service: ', labors);
    const { data } = await cashierService.downloadNoFiscalService(id, client_id, labors);
    const file = new Blob([data], { type: 'application/pdf;charset=utf-8' });
    saveAs(file, `${id}.pdf`);
    console.log(data);
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    function open(url) {
      const win = window.open(url, '_blank');
      if (win != null) {
        win.focus();
      }
    }

    try {
      const {
        client_id,
        payment_type,
        payment_method,
        payment_method2,
        payment_method3,
        installments,
        installments2,
        installments3,
        installment_price,
        installment_price2,
        installment_price3,
        discount,
        money_received,
        money_change,
        attendance_id,
        seller_id,
        payment_code,
        emission_coupon,
      } = values;

      if (serviceTypeSelect == 1) {
        const accessories = state.map(({ id, amount }) => {
          console.log('serviceTypeSelect: ', serviceTypeSelect);
          return { amount, accessory_id: id, serviceTypeSelect: serviceTypeSelect };
        });

        const accessorySale = await cashierService.saleAccessories({
          client_id,
          accessories,
          payment_type,
          payment_method,
          payment_method2,
          payment_method3,
          installments,
          installments2,
          installments3,
          installment_price: parseFloat(installment_price).toFixed(2),
          installment_price2: parseFloat(installment_price2).toFixed(2),
          installment_price3: parseFloat(installment_price3).toFixed(2),
          discount,
          money_received,
          money_change,
          attendance_id,
          seller_id,
          payment_code,
          emission_coupon,
        });

        refetchComponentsCashier();

        if (emission_coupon == 1) {
          open(accessorySale?.data?.coupon_url);
        }

        if (emission_coupon == 2) {
          console.log('Accessories: ', accessories[0]);
          const { accessory_id, amount } = accessories[0];
          console.log('accessory_id: ', accessory_id);
          console.log('amount: ', amount);
          handleDownloadTagsClick(accessorySale?.data?.id, client_id, accessories);
        }
      }

      if (serviceTypeSelect == 2) {
        const labors = state.map(({ id, amount }) => {
          return { amount, labor_id: id };
        });

        const serviceSales = await cashierService.saleServices({
          client_id,
          labors,
          payment_type,
          payment_method,
          payment_method2,
          payment_method3,
          installments,
          installments2,
          installments3,
          installment_price: parseFloat(installment_price).toFixed(2),
          installment_price2: parseFloat(installment_price2).toFixed(2),
          installment_price3: parseFloat(installment_price3).toFixed(2),
          discount,
          money_received,
          money_change,
          attendance_id,
          seller_id,
          payment_code,
          emission_coupon,
        });

        console.log('Service Sales: ', serviceSales);

        refetchLabors();

        if (emission_coupon == 2) {
          console.log('Labors: ', labors[0]);
          const { labor_id, amount } = labors[0];
          console.log('labor_id: ', labor_id);
          console.log('amount: ', amount);
          handleDownloadTagsClickService(serviceSales?.data?.id, client_id, labors);
        }

        if (emission_coupon == 3) {
          open(serviceSales?.data?.service_sale_url);
        }
      }

      if (serviceTypeSelect == 3) {
        const accessories = state.map(({ id, amount }) => {
          console.log('serviceTypeSelect: ', serviceTypeSelect);
          return { amount, accessory_id: id, serviceTypeSelect: serviceTypeSelect };
        });

        const accessorySale = await cashierService.saleAccessories({
          client_id,
          accessories,
          payment_type,
          payment_method,
          payment_method2,
          payment_method3,
          installments,
          installments2,
          installments3,
          installment_price: parseFloat(installment_price).toFixed(2),
          installment_price2: parseFloat(installment_price2).toFixed(2),
          installment_price3: parseFloat(installment_price3).toFixed(2),
          discount,
          money_received,
          money_change,
          attendance_id,
          seller_id,
          payment_code,
          emission_coupon,
        });

        refetchComponentsCashierWholesale();

        if (emission_coupon == 1) {
          open(accessorySale?.data?.coupon_url);
        }

        if (emission_coupon == 2) {
          console.log('Accessories: ', accessories[0]);
          const { accessory_id, amount } = accessories[0];
          console.log('accessory_id: ', accessory_id);
          console.log('amount: ', amount);
          handleDownloadTagsClick(accessorySale?.data?.id, client_id, accessories);
        }
      }

      cleanCartAction(dispatch);
      resetForm();
      setActiveStep(0);
      onClose();
      toast.success('Venda concluída com sucesso.');
    } catch (err) {
      toast.error(err.response?.data?.message || 'Erro ao realizar a venda.');
    } finally {
      setSubmitting(false);
    }
  };

  const getStepContent = useCallback((activeStep, formProps) => {
    const steps = {
      0: <CartWithItems FormProps={formProps} />,
      1: <ClientInfo FormProps={formProps} />,
      2: <ConfirmPurchase FormProps={formProps} />,
    };

    return steps[activeStep];
  }, []);

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);

  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth='lg'>
        <div>
          <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
            {steps.map((step) => (
              <Step key={step.label}>
                <StepLabel StepIconComponent={StepIconComponent}>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <Formik
          initialValues={{
            cgc: '',
            name: '',
            zipcode: '',
            city: '',
            state: '',
            district: '',
            street: '',
            street_number: '',
            complement: '',
            residential_phone_number: '',
            phone_number: '',
            payment_type: 1,
            payment_method: 11,
            payment_method2: 11,
            payment_method3: 11,
            installments: 1,
            installments2: 1,
            installments3: 1,
            total_price_card: 0,
            total_price_card2: 0,
            total_price_card3: 0,
            discount: 0,
            money_received: 0,
            money_change: 0,
            attendance_id: '',
            seller_id: '',
            payment_code: '',
            total: totalCart,
            emission_coupon: 1,
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <ClientContext.Provider
                value={{ clientExists, setClientExists, setHasClient, hasClient }}
              >
                {getStepContent(activeStep, props)}
                <Actions
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                  stepsSize={steps.length}
                />
              </ClientContext.Provider>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
