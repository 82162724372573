import React, { useContext } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ServiceOrderContext } from 'pages/Private/ServiceOrder/Consult';

export default function TableGeneral() {
  const { serviceData } = useContext(ServiceOrderContext);
  const { serviceFromGaia } = serviceData;

  return (
    <TableContainer elevation={2}>
      <Table size='medium' aria-label='customized table'>
        <TableHead>
          <TableRow>
            <TableCell align='justify'>Acessórios</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {serviceFromGaia?.accessories.map((accessory) => {
            return (
              <TableRow key={accessory?.id}>
                <TableCell align='justify'>{accessory?.description}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
