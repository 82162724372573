import React, { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import jwtDecode from 'jwt-decode';
import { browserHistory } from 'routes/browserHistory';
import { api } from 'services/api';
import { createContext } from 'use-context-selector';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    const token = localStorage.getItem('@Gaia:token');
    const user = localStorage.getItem('@Gaia:user');

    if (token && user) {
      api.defaults.headers['Authorization'] = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
      };
    }

    return {};
  });

  const signIn = useCallback(async ({ login, password }) => {
    try {
      const { data } = await api.get('authentication/eyJhY3RpdmVfZGlyZWN0b3J5IjoiZ2FpYSJ9');

      const { active } = data;

      let token, refresh_token, user;

      if (active === true) {
        const response = await api.post('sessions/AD', { login, password });

        token = response.data?.token;
        refresh_token = response.data?.refresh_token;
        user = response.data?.user;
      } else {
        const response = await api.post('sessions', {
          login,
          password,
        });

        token = response.data?.token;
        refresh_token = response.data?.refresh_token;
        user = response.data?.user;
      }

      localStorage.setItem('@Gaia:token', token);
      localStorage.setItem('@Gaia:refresh_token', refresh_token);
      localStorage.setItem('@Gaia:user', JSON.stringify(user));

      api.defaults.headers['Authorization'] = `Bearer ${token}`;

      const decodedToken = jwtDecode(token);

      localStorage.setItem('@Gaia:expiresIn', decodedToken.exp);

      setAuthData({ token, user });

      browserHistory.replace('/');
      toast.success('Login realizado com sucesso.');
    } catch (err) {
      toast.error(
        err?.response?.data.message.indexOf(
          `Internal server error - Invalid password for user ${login}`,
        ) !== -1
          ? 'Não foi possível localizar um usuário com as credenciais informada.'
          : err?.response?.data.message || 'Usuário ou senha inválidos.',
      );
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Gaia:token');
    localStorage.removeItem('@Gaia:user');
    localStorage.removeItem('@Gaia:expiresIn');

    setAuthData({});
    browserHistory.push('/');
  }, []);

  useEffect(() => {
    // Check the expiration time of the token every minute
    const checkTokenExpiration = setInterval(() => {
      const token = localStorage.getItem('@Gaia:token');
      const decodedToken = localStorage.getItem('@Gaia:expiresIn');

      if (token) {
        if (decodedToken < Date.now() / 1000) {
          localStorage.removeItem('@Gaia:token');
          localStorage.removeItem('@Gaia:user');
          localStorage.removeItem('@Gaia:expiresIn');
          localStorage.removeItem('@Gaia:refresh_token');

          setAuthData({});
          browserHistory.push('/');

          toast.warning('Sua sessão expirou. Faça login novamente.');
        } else {
          // toast.warning(`Você ainda está ai?`);
        }
      }
    }, 60);

    return () => {
      clearInterval(checkTokenExpiration);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user: authData.user,
        signIn,
        signOut,
        isAuthenticated: !!authData.user,
        expiresIn: authData.expiresIn,
        setAuthData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
