import React, { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import checkKitImg from 'assets/images/check-kit.svg';
import { Button, Checkbox, Spacer, Table, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { serviceOrderService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { useStyles } from './styles';

const columns = [
  {
    label: 'Possui',
    width: 80,
  },
  { label: 'Description' },
];

export function ReleaseQA() {
  const classes = useStyles();

  const user = useContextSelector(AuthContext, (context) => context.user.id);

  const formikRef = useRef();

  const [qualityAssurance, setQualityAssurance] = useState();
  const [service, setService] = useState();

  const onSubmitHandler = async (values, { setSubmitting }) => {
    try {
      const { data } = await serviceOrderService.getManyQualityAssurance(values.service_number);

      console.log('QA > ', data);

      setQualityAssurance(data);
      setSubmitting(true);

      toast.success('QA encontrado com sucesso.');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao realziar a liberação de QA');
    } finally {
      setSubmitting(false);
    }
  };

  const onSubmitValidation = async ({ setSubmitting, resetForm }) => {
    console.log('FDO: ', service);
    try {
      await serviceOrderService.updateQualityAssurance(service);

      setQualityAssurance(undefined);

      formikRef.current.setFieldValue('service_number', '');
      toast.success('Liberação de QA efeutada com sucesso.');

      setSubmitting(true);
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message);
    }
  };

  return (
    <>
      <PageTitle>Liberar QA</PageTitle>
      <Formik
        innerRef={formikRef}
        initialValues={{ service_number: '' }}
        onSubmit={onSubmitHandler}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={3}>
                <Field
                  label='Service'
                  name='service_number'
                  variant='outlined'
                  size='small'
                  component={Text}
                />
              </Grid>
              <Grid item xs={12} lg={2}>
                <Button disabled={props.isSubmitting} type='submit' fullWidth>
                  Pesquisar
                </Button>
              </Grid>
            </Grid>
            <Spacer size={24} />

            {qualityAssurance ? (
              <>
                <Typography variant='h6' gutterBottom>
                  QA: Defeitos
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Table
                      headers={columns}
                      striped
                      disableNumeration
                      data={qualityAssurance}
                      maxHeight={500}
                    >
                      <TableRow key={qualityAssurance.id}>
                        <TableCell size='small' align='center'>
                          <Checkbox color='primary' checked disabled />
                        </TableCell>
                        <TableCell>{qualityAssurance.quality_assurance_defect}</TableCell>
                        {setService(qualityAssurance.service_order_id)}
                      </TableRow>
                    </Table>
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Button fullWidth onClick={onSubmitValidation}>
                      Aprovar
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <img className={classes.imgContainer} src={checkKitImg} />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
