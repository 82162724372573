import React from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Dialog, Button, Text } from 'components';
import { Formik, Field, Form } from 'formik';
import { serviceOrderService } from 'services';
import * as yup from 'yup';

import { useStyles } from './styles';

const validationSchema = yup.object().shape({
  description: yup.string().required(),
  initials: yup.string().required(),
});

export const RegisterProcessDialog = ({
  open,
  handleClose,
  serviceOrder = {},
  reloadComponents,
}) => {
  if (!open) return null;
  const classes = useStyles();

  console.log('Service Order Process: ', serviceOrder);

  const onSubmitHandler = async (values, { setSubmitting, resetForm }) => {
    try {
      await serviceOrderService.updateProcess(serviceOrder.id, values.initials, values.description);

      reloadComponents();
      toast.success('Processo alterado com sucesso.');
      resetForm();
    } catch (err) {
      toast.error(err.response?.data?.message || 'Não foi possível alterar o processo.');
    } finally {
      handleClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      maxWidth='sm'
      scroll='paper'
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <Dialog.Title className={classes.title} onClose={handleClose}>
        Alterar
      </Dialog.Title>
      <Dialog.Content>
        <Formik
          initialValues={{
            description: serviceOrder.description,
            initials: serviceOrder.initials,
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          {(props) => (
            <>
              <Form onSubmit={props.handleSubmit}>
                <Grid className={classes.root} container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      name='initials'
                      label='Sigla'
                      variant='outlined'
                      size='small'
                      emptyOption='Selecione'
                      component={Text}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      name='description'
                      label='Serviço'
                      variant='outlined'
                      size='small'
                      emptyOption='Selecione'
                      component={Text}
                    />
                  </Grid>
                  <Grid className={classes.buttonsContainer} container>
                    <Button onClick={handleClose} color='primary' variant='text'>
                      Fechar
                    </Button>
                    <Button disabled={!props.dirty || !props.isValid} color='primary' type='submit'>
                      Confirmar
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};
